import React, {useEffect, useState} from 'react';
import {noop} from 'lodash';
import {PanelContainer} from '../Panel.container';

export const PanelComponent = (props) => {
    const {
        as: Component = null,
        panelData = null,
        onScrollPanel = noop,
        panelNumber = null,
        hashData,
        ...other
    } = props;

    const [panelId, setPanelId] = useState();
    const [hasExtraClass,setHasExtraClass] = useState(false);
    const handleSetPanel = (id) => setPanelId(id);
    const handleToogleClass = () => {
        setHasExtraClass((prev) => !prev);
    };

    useEffect(() => {
        onScrollPanel();
        if (hashData && hashData[panelNumber + 1]) {
            handleSetPanel(hashData[panelNumber + 1]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (panelData &&
           <>
               <Component {...other}
                          panelData={panelData}
                          onAddPanel={handleSetPanel}
                          onToogleClass={handleToogleClass}
                          isCloseAdditionalPanel={!!panelId}
                          hasExtraClass={hasExtraClass}
                          onScrollPanel={onScrollPanel}/>
               {panelId && <PanelContainer {...other}
                                           as={Component}
                                           onScrollPanel={onScrollPanel}
                                           panelNumber={panelNumber + 1}
                                           hashData={hashData}
                                           panelId={panelId}/>}
           </>
        );
};