import { gql } from '@apollo/client';

export const CreateTask = gql`
    mutation CreateTask($taskData: CreateTaskInput!) {
        createTask(taskData: $taskData) {
            id
            title
            alias
            tags {
                id
                subject {
                    color
                    id
                }
                title
            }
            source
            questions {
                id
                type
                question
                answer
                options {
                    id
                    isCorrect
                    order
                    text
                }
                explanation
                title
            }
            galleries {
                id
                raw 
                source
                summary
                timestamps {
                    id
                    timestamp
                    title
                    description
                    }
                type
                }
            workspaceId
            summary
        }
    }
`;
