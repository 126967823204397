import React from 'react';
import { VimeoInput } from './VimeoInput';

export const VimeoTab = ({setCover, task, ...other}) => {
    const getCover = (video, sourceText) => {
        if (video) {
            return {
                type: 'VIMEO',
                source: video,
                id: task.cover?.id,
                raw: sourceText || '',
                timestamps: [],
                title: video,
            }
        }
    }

    const onVideoDataChange = ({  urlName, sourceText }) => {
        const newCoverData = getCover( urlName, sourceText );
        setCover(newCoverData);
    };

    return (
        <div className="vimeo-tab">
             <VimeoInput
                    onVideoDataChange={onVideoDataChange}
                    {...other}
                />
        </div>
    );
};