import {useMutation} from '@apollo/client';
import {DeleteWorkspace} from '../graphQL/mutations/DeleteWorkspace';

export const useDeleteWorkspace = (mutation = DeleteWorkspace) => {
    const [deleteWorkspace, {data, error, loading}] = useMutation(mutation);

    return {
        deleteWorkspace,
        data,
        error,
        loading
    }
}