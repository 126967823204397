import {useCallback} from 'react';
import {useLazyQuery} from '@apollo/client';
import {ConvertToText} from '../graphQL/queries/ConvertToText';
import {useOwnerData} from '../../common';

export function useConvertAudioToText(query = ConvertToText) {
    const [getConvertedText, {data, loading, error}] = useLazyQuery(query);
    const getOwnerData =  useOwnerData()

    const getText = useCallback(async (url) => {
        const ownerData = await getOwnerData();
        const input = data?.variables?.input;
        const owner = ownerData?.ownerId ? ownerData : {ownerId: ownerData?.customerId};

        const result = await getConvertedText({
            variables: {
                input: url,
                owner
            }
        });
        return result?.data;
    }, [getConvertedText]);

    return {
        data,
        loading,
        error,
        getText
    };
}
