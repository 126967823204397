import React, {useState} from 'react';
import {RequestsCounterComponent} from '../RequestsCounter.component';
// import {getDatabaseRef} from '../../../../context/firebase/firebaseRequests';
// import {getConvertedObjectToArray} from '../../../common';

export const RequestsCounterContainer = (props) => {
    const {
        as: Component = RequestsCounterComponent
    } = props;

    // eslint-disable-next-line no-unused-vars
    const [counter, setCounter] = useState(0);

    // useEffect( () => {
    //     (async () => {
    //         const db = getDatabaseRef();

    //         db('paymentRequests').on('value', snapshot => {
    //             const data = getConvertedObjectToArray(snapshot.val());
    //             const list = data && data.filter(item => !item.doneDate);
    //             setCounter(list?.length || 0);
    //         });
    //     })();
    // }, []);

    return <Component counter={counter}/>
}
