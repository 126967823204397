import './scss/index.scss';

import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { Modal, Upload } from 'antd';
import { Svg } from '../Svg';
import { noop } from 'lodash';

export const UploadImgComponent = (props) => {
    const { formatMessage } = useIntl();

    const {
        onSetImages = noop,
        onRemove = noop,
        savedImages = [],
        limitImages = 6,
        maxCount = 6,
        isWide = false,
        index,
        title = formatMessage({ id: 'form_cover_title' }),
    } = props;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewVisible, setPreviewVisible] = useState();
    const [previewImage, setPreviewImage] = useState();
    const [previewTitle, setPreviewTitle] = useState();
    const [fileList, setFileList] = useState();
    const [error, setError] = useState(false);

    const onChange = ({ fileList }) => {
        if (!error) {
            const isUploaded = !!fileList?.length && !fileList.find((file) => file.status !== 'done');
            setFileList(fileList);
            onSetImages(fileList.length > 0 ? fileList : [], index);
        }
    };
    const handleRemove = (data,v) => {
        setFileList((prev) => {
            const clone = [...prev];
            const index = clone.findIndex((item) => item.url === data.url);
            clone.splice(index, 1);
            return clone;
        });
        onRemove(data);
    }

    const componentClassNames = classnames('upload-img-component', {
        'upload-img-component_wide': isWide
    });
    const handlePreview = async (file) => {
        // if (!file.url && !file.preview) {
        //   file.preview = await getBase64(file.originFileObj);
        // }
        setPreviewImage(file.url || file.preview);
        // setPreviewOpen(true);
        setPreviewTitle(file.name || file?.url?.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleCancel = () => setPreviewOpen(false);

    const inBeforeUpload = (file) => {
        const maxSize = 5 * 1024 * 1024;
        const isValid = file?.size < maxSize;
        setError(!isValid);
        return isValid;
    }

    useEffect(() => {
        if (savedImages && !fileList) {
            setFileList(savedImages);
        }
    }, [savedImages]);

    if (!fileList) {
        return null;
    }

    return (
        <div className={componentClassNames}>
            <Upload
                action="https://back.unschooler.me/file/upload"
                name="file"
                listType="picture-card"
                defaultFileList={fileList}
                maxCount={maxCount}
                beforeUpload={inBeforeUpload}
                onChange={onChange}
                onRemove={handleRemove}
                // onPreview={handlePreview}
                showUploadList={{
                    // showPreviewIcon: true,
                    showRemoveIcon: true,
                }}>

                {fileList?.length < parseInt(limitImages) && (
                    <div className="upload-img-component__notes">
                        <span className='upload-img-component__notes__inline'>
                            <Svg name=':icon/cover' className='icon_24' />
                        </span>
                        <p className="upload-img-component__notes__text">{title} max 5mb</p>
                    </div>
                )}
            </Upload>
           

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
            {error && (
                <div className="error">
                    {formatMessage({ id: 'form_cover_error' })}
                </div>
            )}
        </div>
    )
}
