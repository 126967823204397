import { useLazyQuery } from '@apollo/client';
import {GetWorkspaces} from '../graphQL/queries/GetWorkspaces';

export const useGetWorkspaces = (query = GetWorkspaces) => {
    const [getWorkspaces, { data, loading, error }] = useLazyQuery(query);
    return {
        workspaces: data?.getWorkspaces,
        loading,
        error,
        getWorkspaces
    };
}