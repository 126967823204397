import "./scss/index.scss";
import React from "react";
import { useIntl } from "react-intl";
import { Button, Form, Input, Radio, Popconfirm } from 'antd';
import { cloneDeep } from "lodash";
import { DeleteFilled } from '@ant-design/icons';

import { WysiwygComponent, getType } from '../../common';
import { QuestionExplanationComponent } from '../QuestionExplanation.component';
import { QuestionOption } from './QuestionOption';
import { AddNewContentContainer } from "../../ai";
import { AssessmentButton } from "../../ai/AssessmentButton.component/AssessmentButton.component";
import { MultiSelectButton } from "../../ai/MultiSelectButton.component/MultiSelectButton.component";
import { SingleSelectButton } from "../../ai/SingleSelectButton.component/SingleSelectButton.component";
const { TextArea } = Input;

export const EditTaskQuestion = ({
    user,
    getTabContentClassNames,
    onRemoveQuestion,
    questionsErrors,
    setSortMode,
    formTask,
    activeTask,
    setFormTask,
    form,
    onChange,
    onChangeContent,
    activeQuestion,
    remove,
    handleFormSubmitOnBlur,
    isCollapsed,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const TYPES_QUESTION = [
        {
            label: formatMessage({ id: 'no_interaction' }),
            value: 'DEFAULT',
        },
        {
            label: formatMessage({ id: 'multi_select' }),
            value: 'MULTI_SELECT',
        },
        {
            label: formatMessage({ id: 'single_select' }),
            value: 'SINGLE_SELECT',
        },
        {
            label: formatMessage({ id: 'ai_assignment' }),
            value: 'TEXT',
        },
    ];
    const getInitialValue = (name, index, value) => formTask?.[name]?.[index] ? {} : { initialValue: value };
    const getContent = (key, field) => {
        const questions =
            //  form.getFieldValue('questions')  ||
            formTask?.questions;
        return questions?.[key]?.[field] || ''
    };
    let index = formTask?.questions?.findIndex((q) => q?.id === activeQuestion?.id);

    const handleReplaceQuestion = async (newQuestion, questionId,) => {
        const questions = [...form.getFieldValue('questions')];
        let question = questions.find((el) => el.id === questionId);

        Object.keys(newQuestion).forEach((key) => {
            if (key in newQuestion) {
                question[key] = newQuestion[key];
            }
        });
        form.setFieldsValue({ questions: questions });
        handleFormSubmitOnBlur();
    };

    return (
        <Form.List name='questions'>
            {(questions, { add, remove }) => {
                return (
                    <div className='questions'>
                        {questions.map((question, i) => (
                            i === index &&
                            <div key={question.key} className='question'>
                                {!isCollapsed && <div className='question__top edit-task-form-tab__content_active'>
                                    <Form.Item
                                        name={[question.name, 'title',]}
                                        label={formatMessage({ id: 'form_step_prompt' })}
                                        className='edit-task-component__question-type'
                                    >
                                        <TextArea
                                            autoComplete='off'
                                            placeholder={formatMessage({ id: 'form_title_prompt' })}
                                        />
                                    </Form.Item>
                                </div>}
                                <div className='edit-task-form-tab__content_bottom'>
                                    <AddNewContentContainer
                                        {...other}
                                        task={formTask}
                                        question={formTask?.questions[i]}
                                        user={user}
                                        userIsAllowGenerateContent={true}
                                        handleReplaceQuestion={handleReplaceQuestion}
                                    />
                                    <Form.Item
                                        name={[question.name, 'question',]}
                                        // label={formatMessage({id: 'form_question_label',})}
                                        rules={[
                                            {
                                                required: false,
                                                message: formatMessage({ id: 'form_field_is_required', }),
                                            },
                                        ]}
                                        className='Answer__info'
                                    >
                                        <WysiwygComponent
                                            onChangeContent={(content) => {
                                                const questions = [...form.getFieldValue('questions')];
                                                let questionNew = cloneDeep(questions[i]);
                                                if (questions[i]) { questionNew.question = content; }
                                                questions[i] = questionNew;
                                                form.setFieldsValue({ questions: questions });
                                                onChangeContent();
                                            }}
                                            initialState={getContent(question.key, 'question')}
                                            placeholder={formatMessage({ id: 'form_question_label', })}
                                        />

                                    </Form.Item>

                                    <hr />
                                    <Form.Item
                                        name={[question.name, 'type',]}
                                        {...getInitialValue('questions', question.name, 'SINGLE_SELECT')}
                                        className='edit-task-component__question-type'
                                    >
                                        <Radio.Group
                                            // buttonStyle="solid"
                                            className='radio_title'
                                            onChange={onChange}
                                        >
                                            {TYPES_QUESTION.map(type => (
                                                <Radio.Button type="tertiary" value={type.value} key={type.value} >
                                                    <span className="feature">
                                                        {getType(type?.value)}
                                                        {type.label}
                                                    </span>

                                                </Radio.Button>
                                            ))}
                                        </Radio.Group>
                                    </Form.Item>


                                    {formTask?.questions && formTask.questions[i] && formTask.questions[i].type === 'TEXT' && (
                                        <Form.Item
                                            name={[question.name, 'answer',]}
                                            // label={formatMessage({ id: 'form_text_challenge' })}
                                            className='Answer__info'
                                        >
                                            <AssessmentButton
                                                question={formTask.questions[i]}
                                                formatMessage={formatMessage}
                                                userIsAllowGenerate={true}
                                                source={formTask.source || ''}
                                                handleReplaceQuestion={handleReplaceQuestion}
                                                regenerate={formTask.questions[i].answer}
                                                {...other}
                                            />

                                            <WysiwygComponent
                                                onChangeContent={(content) => {
                                                    const questions = [...form.getFieldValue('questions')];
                                                    let questionNew = cloneDeep(questions[i]);
                                                    if (questions[i]) { questionNew.answer = content; }
                                                    questions[i] = questionNew;
                                                    form.setFieldsValue({ questions: questions });
                                                    onChangeContent();
                                                }}
                                                initialState={getContent(question.key, 'answer')}
                                                placeholder={formatMessage(
                                                    { id: 'form_text_question_answer_placeholder', }
                                                )}
                                            />

                                        </Form.Item>
                                    )}
                                    {formTask?.questions && formTask.questions[i] && formTask.questions[i].type === 'SINGLE_SELECT' &&
                                        <>
                                            <SingleSelectButton
                                                question={formTask.questions[i]}
                                                userIsAllowGenerate={true}
                                                handleReplaceQuestion={handleReplaceQuestion}
                                                goal={formTask.title}
                                                header={formTask.questions[i].title}
                                                regenerate={formTask.questions[i].options?.length > 1}
                                                {...other}
                                            />
                                            <Form.List
                                                name={[question.name, 'options',]}
                                            >
                                                {(options, { add, remove, }) => (
                                                    <div className='options'>
                                                        {options.map(
                                                            (option, i) => (
                                                                <QuestionOption
                                                                    option={option}
                                                                    remove={remove}
                                                                    key={`option_${i}`}
                                                                    onChangeContent={onChangeContent}
                                                                />
                                                            )
                                                        )}

                                                        {!!questionsErrors?.[i] && (
                                                            <div className='ant-form-item-explain'>
                                                                <div
                                                                    className='ant-form-item-explain-error'
                                                                    role='alert'
                                                                >
                                                                    {questionsErrors?.[i]}
                                                                </div>
                                                            </div>
                                                        )}
                                                        <Button
                                                            onClick={() => {
                                                                add({
                                                                    isCorrect: 'checked',
                                                                    text: 'Next',
                                                                })
                                                                onChangeContent();
                                                            }}
                                                            type="text"
                                                            size="small"
                                                            className="link-btn_light"
                                                        >
                                                            + {formatMessage({ id: 'form_add_new_answer', })}
                                                        </Button>
                                                    </div>
                                                )}
                                            </Form.List>
                                            <hr />
                                        </>
                                    }
                                    {formTask?.questions && formTask.questions[i] && formTask.questions[i].type === 'MULTI_SELECT' &&
                                        <>
                                            <MultiSelectButton
                                                question={formTask.questions[i]}
                                                userIsAllowGenerate={true}
                                                handleReplaceQuestion={handleReplaceQuestion}
                                                goal={formTask.title}
                                                regenerate={formTask.questions[i].options?.length > 1}
                                                header={formTask.questions[i].title}
                                                {...other}
                                            />
                                            <Form.List
                                                name={[question.name, 'options',]}
                                            >
                                                {(options, { add, remove, }) => (
                                                    <div className='options'>
                                                        {options.map(
                                                            (option, i) => (
                                                                <QuestionOption
                                                                    option={option}
                                                                    remove={remove}
                                                                    key={`option_${i}`}
                                                                    onChangeContent={onChangeContent}
                                                                />
                                                            )
                                                        )}

                                                        {!!questionsErrors?.[i] && (
                                                            <div className='ant-form-item-explain'>
                                                                <div
                                                                    className='ant-form-item-explain-error'
                                                                    role='alert'
                                                                >
                                                                    {questionsErrors?.[i]}
                                                                </div>
                                                            </div>
                                                        )}
                                                        <Button
                                                            onClick={() => {
                                                                add({
                                                                    isCorrect: 'checked',
                                                                    text: 'Next',
                                                                })
                                                                onChangeContent();
                                                            }}
                                                            type="text"
                                                            size="small"
                                                            className="link-btn_light"
                                                        >
                                                            + {formatMessage({ id: 'form_add_new_answer', })}
                                                        </Button>
                                                    </div>
                                                )}
                                            </Form.List>
                                            <hr />
                                        </>
                                    }
                                    <QuestionExplanationComponent
                                        Form={Form}
                                        form={form}
                                        question={question}
                                        task={activeTask}
                                        onChangeExplanation={form.setFieldsValue}
                                    />
                                    <hr />
                                </div>

                                <div className='question__header'>
                                    <Form.Item>
                                        <Button
                                            className="btn-left  link-btn_light"
                                            type="text"
                                            size="small"
                                            onClick={() => {
                                                const currentQuestionData = form.getFieldValue(['questions', question.name]);
                                                const newQuestion = cloneDeep(currentQuestionData);

                                                delete newQuestion.key;
                                                delete newQuestion.id;
                                                delete newQuestion.name;
                                                delete newQuestion.fieldKey;
                                                delete newQuestion.options;
                                                delete newQuestion.explanation;
                                                newQuestion.type = 'DEFAULT';

                                                // appley the currentindex + 1 to the newQuestion
                                                newQuestion.order = (currentQuestionData.order || 0) + 1;
                                                add(newQuestion, index + 1);

                                                //change the order of the other questions
                                                for (let i = index + 2; i < questions.length + 1; i++) {
                                                    const questionToUpdate = form.getFieldValue(['questions', i]);
                                                    if (questionToUpdate) {
                                                        questionToUpdate.order = (questionToUpdate.order || 0) + 1;
                                                        form.setFields([
                                                            {
                                                                name: ['questions', i, 'order'],
                                                                value: questionToUpdate.order,
                                                            },
                                                        ]);
                                                    }
                                                }
                                                handleFormSubmitOnBlur();
                                            }}
                                            disabled={formTask?.questions?.length > 9}
                                        >
                                            <span> {formatMessage({ id: "question_add" })} </span>
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Popconfirm
                                            title={formatMessage({ id: 'confirm_delete' })}
                                            onConfirm={() => {
                                                remove(question.name);
                                                onRemoveQuestion();
                                            }}
                                            okText='OK'
                                            icon={null}
                                            cancelText={formatMessage({ id: 'payment_request_modal_cancel' })}
                                        >
                                            <Button
                                                icon={<DeleteFilled />}
                                                size='small'
                                                type='link'
                                            >
                                                {formatMessage({ id: 'form_remove_question' })}
                                            </Button>
                                        </Popconfirm>
                                    </Form.Item>
                                </div>
                            </div>
                        )
                        )}
                    </div>
                )
            }
            }
        </Form.List>
    );
};
