import './scss/index.scss';
import React, { useRef } from 'react';
import { Button } from 'antd';
import { AchievementsProgress } from '../';


import { ConfettiStatic, SharingAchivementComponent } from '../../common'

export const AchievementPreviewComponent = ({
    achievements,
    formatMessage,
    localePrefix,
    triggerDescription,
    triggerMessage,
    onClose,
    share,
}) => {
    const componentRef = useRef(null);
    const sharingMessage = formatMessage({ id: 'achievement.sharing.message' }, { url: window.location.href });
    return (
        <>
           {share && <SharingAchivementComponent
                componentRef={componentRef}
                sharingMessage={sharingMessage}
                formatMessage={formatMessage}
            />}
            <div className="achievement-modal-content" ref={componentRef}>
                <div className="achievement_cards">
                    {achievements.map((achievement) => (
                        <AchievementsProgress
                            key={achievement?.id}
                            achievement={achievement}
                        />
                    ))}
                </div>

                <h1>{triggerMessage}</h1>
                <div className="achievement-modal-body">
                    <p>{triggerDescription}</p>
                </div>

            </div>
            <div className="wrapper_content-section wrapper_textcenter">
                <Button type="primary" block onClick={onClose}>{formatMessage({ id: 'continue' })}</Button>
            </div>
        </>
    );
};