import React, {useMemo} from 'react';
import {CareersContainer} from '../Components/careers/Careers.container';
import {MetaComponent} from '../Components/common';
import {COVER_PICTURES} from '../Components/constant';
import {useLocalePrefix} from '../Components/lang';

export const Careers = (props) => {
    const {user, ...other} = props;
    const [localePrefix] = useLocalePrefix();

    const metaData = useMemo(() => {
        const title =
            'Try any profession with short tutorials and online-courses';
        const description =
            'Share your favourite YouTube tutorial on Unschooler → Participate in the contest → Gain the most likes for a tutorial → Earn €50. 🔥 A new winner is announced every week!';
        const url = `https://unschooler.me${localePrefix}/tutorials`;
        const image = COVER_PICTURES.MAIN;

        return {
            meta: {
                meta_title: title,
                meta_description: description,
            },
            og: {
                title,
                image,
                description,
                url,
            },
            twitter: {
                tw_title: title,
                tw_url: url,
                tw_description: description,
            },
        };
    }, []);

    

    return (
        <>
            <MetaComponent
                meta={metaData.meta}    
                og={metaData.og}
                twitter={metaData.twitter}
            />

            <CareersContainer user={user} {...other} />
        </>
    );
};
