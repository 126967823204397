import './scss/index.scss';
import React from 'react';

export const BgProfileComponent = ({specialization}) => {

    return (
        <>
            <div
                className='profile_cover'
                style={{
                    backgroundImage: `url('https://storage.googleapis.com/cdn.unschooler.me/web_app/profile_cover/${
                        specialization ? specialization.title.toLowerCase() : `tech`
                    }_trans.png')`,
                }}
            ></div>
        </>
    );
};
