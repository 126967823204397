
import React from 'react';
import {useHistory} from 'react-router-dom';
import classnames from 'classnames';
import {Button} from 'antd';
import {Svg} from '../Svg';

export const TitleComponent = (props) => {
    const {title, back, description} = props;
    const history = useHistory();

    if (!title && !description) return null;

    const componentClassNames = classnames('title-component', {
        'title-component_with-button': back,
    });

    return (
        <div className={componentClassNames}>
            {back && (
                <Button
                    type='text'
                    className='title-component__back-btn'
                    onClick={() => history.goBack()}
                >
                    <Svg name=':icon/arrow-left' className='icon_24' />
                </Button>
            )}
            {!!title && (
                <h1 className=' title-component__title '>{title}</h1>
            )}
            {!!description && (
                <div className='title-component__description'>
                    {description}
                </div>
            )}
        </div>
    );
};
