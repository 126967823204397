import { gql, useLazyQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

export function useGetUsers(query = GET_USERS) {
    const [queryUsers, { data, loading, error }] = useLazyQuery(query, {
        fetchPolicy: 'network-only',
    });

    const getUsers = useCallback(
        async (ids, workspaceId, isSuperUser) => {
            const data = {
                ids,
                ...(isSuperUser && workspaceId && {
                    workspaceId,
                    isSuperUser
                })
            }

            const result = await queryUsers({
                variables: data
            });

            return result.data?.users;
        },
        [queryUsers]
    );

    return useMemo( 
        () => ({
            users: data?.users,
            loading,
            error,
            getUsers,
        }),
        [data?.users, loading, error, getUsers]
    );
}

const GET_USERS = gql`
    query Users($ids: [Int!]!, $workspaceId: Int, $isSuperUser: Boolean) {
        users(ids: $ids, workspaceId: $workspaceId, isSuperUser: $isSuperUser) {
            id
            alias
            name
            email
            description
            picture
            role {
                id
                title
                description
            }
            subscriptionUser {
                type
                startDate
                endDate
                isCanceled
            }
            results {
                id
                taskId
                userId
                description
            }
        }
    }
`;

export const GET_USERS_RESULTS = gql`
    query Users($ids: [Int!]!, $workspaceId: Int, $isSuperUser: Boolean) {
        users(ids: $ids, workspaceId: $workspaceId, isSuperUser: $isSuperUser) {
            id
            name
            picture
            email
            results {
                id
                taskId
                modifyDate
                createDate
                state
            }
            events {
                id
                title
            }
            createDate
            lastVisit
            name
            phone
            picture
            tasks {
                id
            }
        }
    }
`;
