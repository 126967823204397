export const convertTextToWebVTT = (text, totalDuration) => {
    const lines = text.match(/(?:\S+\s+){1,4}\S+/g) || [];
    const totalSeconds = parseFloat(totalDuration);
    const segmentDuration = totalSeconds / lines.length;

    let vttString = 'WEBVTT\n\n';
    let startTime = 0;

    lines.forEach((line, index) => {
        const endTime = startTime + segmentDuration ;
        vttString += `${formatTime(startTime)} --> ${formatTime(endTime)}\n${line.trim()}\n\n`;
        startTime = endTime;
    });

    return vttString;
}

export const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const s = (seconds % 60).toFixed(3).toString().padStart(6, '0');
    return `${h}:${m}:${s}`;
}

export const createVTTBlob = (vttString) => {   
    return new Blob([vttString], { type: 'text/vtt' });
}

export const createVTTURL = (vttBlob) => {
    return URL.createObjectURL(vttBlob);
}