import React, {useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {noop} from 'lodash';
import {PanelContainer} from '../Panel.container';

const getNewKeysList = (list, i) => list.slice(0, i);

export const PanelsContainer = (props) => {
    const {
        activePanelId = null,
        location,
        dbPropertyKey,
        onClearSource = noop,
        ...other
    } = props;

    const history = useHistory();
    const [hashData, setHashData] = useState();
    const {hash, pathname} = location;
    const panelRef = useRef();
    const [panelId, setPanelId] = useState(activePanelId);

    const handleRemovePanels = () => {
        setPanelId(null);
        onClearSource(null);
    };

    const onSetHashData = () => {
        const data = hash.split('&');
        if (data.length > 1 && data[0] === `#${dbPropertyKey}`) {
            setHashData(data);
        }
    };

    const handleUpdateHash = (key, i, isRemove) => {
        if (hashData) {
            if (i === hashData.length -1) {
                setHashData(null);
            }
            return;
        }
        const keysList = hash.split('&');
        if (!hash || keysList[0] !== `#${dbPropertyKey}`) {
            history.push(`#${dbPropertyKey}&${key}`)
        } else {
            let newHash;
            if (isRemove) {
                const newList = getNewKeysList(keysList, i);
                newHash = newList.length > 1 ? newList.join('&') : pathname;
            } else {
                if (i > keysList.length) {
                    keysList.push(key);
                    newHash = keysList.join('&');
                } else {
                    const newList = getNewKeysList(keysList, i);
                    newList.push(key);
                    newHash = newList.join('&');
                }
            }
            history.push(newHash);
        }
    };

    const handleScrollPanel = () => {
        if (panelRef.current) {
            const x = panelRef.current.scrollLeft;
            panelRef.current.scroll({
                left: x + 600,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        if (activePanelId) {
            setPanelId(activePanelId);
        }
    }, [activePanelId]);

    useEffect(() => {
        if (!!hash) {
            onSetHashData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hashData) {
            setPanelId(hashData[1]);
        }
    }, [hashData]);

    return (panelId && <div className="panel-block" ref={panelRef}>
            <PanelContainer {...other}
                            dbPropertyKey={dbPropertyKey}
                            onScrollPanel={handleScrollPanel}
                            onUpdateHash={handleUpdateHash}
                            onRemovePanels={handleRemovePanels}
                            panelNumber={1}
                            location={location}
                            hashData={hashData}
                            panelId={panelId}/>
        </div>
    );
};