import {gql} from "@apollo/client";

export const SubscribeEvent = gql`
    mutation SubscribeEvent($type: UserSubscriptionType!, $eventId: Int!, $promocodeId: String, $invitedEmail: String, $studentId: Int, $end: String, $workspaceId: Int, $role: UserSubscriptionRole) {
        userSubscription: subscribeEvent(type: $type, eventId: $eventId, promocodeId: $promocodeId, invitedEmail: $invitedEmail, studentId: $studentId, end: $end, workspaceId: $workspaceId, role: $role) {
            id
            studentId
            student {
                id
                email
                alias
                googleUid
                name
                picture
            }
            invitedEmail
            studentId
            start
            end
            workspaceId
            role
        }
    }
`;
