import React from 'react';
import {StripeConnectedAccountComponent} from '../StripeConnectedAccount.component';
import {useCreateConnectedAccount, useGetConnectedAccount} from '../hooks';

export const StripeConnectedAccountContainer = (props) => {
    const {createConnectedAccount, loading} = useCreateConnectedAccount();
    const {data, loading: loadingAccount} = useGetConnectedAccount();
    const {stripeAccountIsActivated = false} = data?.getConnectedAccount || {};

    const handleConnect = async () => {
        try {
            const resp = await createConnectedAccount({
                variables: {
                    location: window.location.href
                }
            });
            const url = resp?.data?.createConnectedAccount?.url;
            if (url) {
                window.location.href = url;
            }
        } catch (e) {
            console.warn(e);
        }
    };

    return <StripeConnectedAccountComponent {...props}
                                            isActivated={stripeAccountIsActivated}
                                            loading={loading || loadingAccount}
                                            onConnect={handleConnect}/>
};
