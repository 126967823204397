import {useLazyQuery} from "@apollo/client";
import {GetEventSimple} from "../graphQL/queries/GetEventSimple";

export default function useGetEventSimple(query = GetEventSimple) {
    const [getEventSimple, { data, loading }] = useLazyQuery(query);

    return {
        event: data?.event,
        getEventSimple,
        loading
    }
}
