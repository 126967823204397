import './scss/index.scss';
import React from 'react';

export const SuperTitleComponent = (props) => {
    const {title} = props;

    if (!title) return null;

    return <h2 className='supertitle'>{title}</h2>;
};
