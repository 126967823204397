import "./scss/index.scss";
import React, { forwardRef } from "react";
import { CertificateContainer } from "./Certificate.container/Certificate.container";

export const CertificatePdf = forwardRef((props, ref) => {
    const { certificate } = props;

    return (
        <article className="page" ref={ref}>
            <CertificateContainer
                certificate={certificate}
            />
        </article>
    )
});
