import React, { useEffect, useState, useMemo, useContext } from 'react';
import { CareersComponent } from '../Careers.component';
import { useGetSpecs, useMapSkills } from '../../common/graphQL';
import { useGetCareers } from '../../common/graphQL/careers/useGetCareers';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const CareersContainer = (props) => {
    const { user, loadingUser } = useContext(FirebaseContext);
    const { specs, loading: loadingSpec, } = useGetSpecs();
    const { mapSkills, loading: loadingMap, getMapSkills } = useMapSkills();
    const { careers, loading: loadingCareers, getCareers } = useGetCareers();
    const [currentSpec, setCurrentSpec] = useState();

    const loading = useMemo(
        () => loadingMap || loadingCareers || loadingSpec,
        [loadingCareers, loadingMap, loadingSpec]
    );

    useEffect(() => {
        if (!loadingUser && currentSpec?.id) {
            (async () => {
                await getCareers({
                    variables: { specId: currentSpec?.id, ...(user?.id && {userId: user.id}) },
                });
                await getMapSkills({
                    variables: { specId: currentSpec?.id },
                });
            })();
        }
    }, [currentSpec?.id, getCareers, getMapSkills, loadingUser]);

    const handleSayMeSpec = (spec) => {
        setCurrentSpec(spec);
    };

    return (
        <CareersComponent
            skills={mapSkills}
            user={user}
            handleSayMeSpec={handleSayMeSpec}
            spec={currentSpec}
            careersBySpec={careers}
            specs={specs}
            loading={loading}
        />
    );
};
