import { useMutation, gql } from "@apollo/client";
import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

export const CreatePromo = () => {
    const history = useHistory();
    const [form, onChange] = useState({
        name: "",
        firstPromoMail: ""
    });

    const [createPromo, { loading, data }] = useMutation(CREATE_PROMO);

    const handleCreatePromo = () => {
        createPromo({
            variables: {
                content: {
                    ...form
                }
            }
        });
    }

    useEffect(() => {
        if(data) {
            history.push("/admin/promoes")
        }
    }, [data, history]);

    return(
        <div className="AdminNavigation">
            <Form>
                <Form.Item label="Название">
                    <Input onChange={(e) => onChange(prev => ({ ...prev, name: e.target.value }))} value={form.name} />
                </Form.Item>
                <Form.Item label="Template Id для первого письма">
                    <Input onChange={(e) => onChange(prev => ({ ...prev, firstPromoMail: e.target.value }))} value={form.firstPromoMail} />
                </Form.Item>
                {/* <Form.Item label="Template Id для второго письма">
                    <Input onChange={(e) => onChange(prev => ({ ...prev, lateEmail: e.target.value }))} value={form.lateEmail} />
                </Form.Item> */}
                <Form.Item>
                    <Button loading={loading} onClick={handleCreatePromo}>Создать</Button>
                </Form.Item>
            </Form>
        </div>
    );


}

const CREATE_PROMO = gql`
    mutation createPromo($content: createPromoInput) {
        createPromo(content: $content) {
            id
        }
    }
`;
