import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

export function useGetRatingUser() {
    const [page, setPage] = useState(0);
    const [queryGetRatingUser, { data, loading, error }] = useLazyQuery(GET_RATING_USER);

    async function moreRating() {
        await queryGetRatingUser({
            variables: {
                page: page + 1
            }
        })
        setPage(prev => prev + 1);
    }

    useEffect(() => {
        (async () => await queryGetRatingUser())();
    }, []);


    return {
        loading,
        error,
        userRating: data?.userRating,
        moreRating
    }
}

const GET_RATING_USER = gql`
    query GetRatingUser($page: Int) {
        userRating(page: $page) {
            subject {
                id
                title
            }
            users  {
                coins
                subjectId
                user {
                    id
                    name
                    googleUid
                    picture
                    canTeach
                    achievements {
                        title
                        active
                        icon
                        defIcon
                    }
                }
            }
        }
    }
`;