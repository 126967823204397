import './scss/index.scss';
import React from 'react';
import {Svg} from '../Svg';

export const SuperTextComponent = (props) => {
    const {icon, description} = props;

    if (!description) return null;

    return (
        <div
         className='supertext'
        >
            {icon && (
                <Svg
                    name={`:icon/${icon}`}
                    className='icon_32 supertext_svg'
                    viewBox={`0 0 48 48`}
                    width={'32px'}
                />
            )}

            {description}
        </div>
    );
};
