import {gql} from '@apollo/client';

export const AddCustomDomain = gql`
    mutation AddCustomDomain($domain: String!, $workspaceId: Int!, $environment: Environment) {
        addCustomDomain(domain: $domain, workspaceId: $workspaceId, environment: $environment) {
            domain {
                name
                bindingType
                certificateId
            }
            records {
                a
                cname
                txt
            }
            status
        }
    }
`;
