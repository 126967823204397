import React, { useMemo } from 'react';

import { Profile, MyProfile } from '../Components/profile';
import { useGetUrlParams, LoaderComponent } from '../Components/common';
import { useLocalePrefix } from '../Components/lang';

export const ProfilePage = (props) => {
    const {
        user,
        loading,
        ...other
    } = props;

    const { id, checkUrl } = useGetUrlParams();
    const [localePrefix] = useLocalePrefix();

    const isNotOwner = useMemo(() => {
        if (id && id !== user?.id) {
            return true
        } else {
            return false
        }
    }, [id, user?.id]);

    if (loading || !user) return <LoaderComponent type="full" />;
    const Component = isNotOwner ? Profile : MyProfile;

    return <Component
        {...other}
        viewer={user}
        id={id || user?.id}
        localePrefix={localePrefix}
        onCheckUrl={checkUrl}
    />
}