import {useCallback} from 'react';
import {gql, useMutation} from "@apollo/client";

const DEFAULT_SUBJECT_ID = 52;

export const useCreateTags = () => {
    const [mutationCreateTags, {loading, error}] = useMutation(CREATE_TAGS);

    const createTags = useCallback(async (tags: [string], subjectId: number = DEFAULT_SUBJECT_ID) => {
        const resp = await mutationCreateTags({
            variables: {
                tagsData: tags.map((tag) =>({title: tag, subjectId}))
            }
        });
        return resp?.data?.createTags;
    }, [mutationCreateTags]);

    return {
        loading,
        error,
        createTags
    }
}

const CREATE_TAGS = gql`
    mutation CreateTags($tagsData: [CreateTagInput!]!) {
        createTags(tagsData: $tagsData) {
            id
        }
    }
`;