import React, { useState, useContext, useRef } from 'react';
import { useIntl } from 'react-intl';

import { useGetLangs } from "../../common/graphQL";
import { FirebaseContext } from "../../../context/firebase/firebaseContext";
import { useGetCommonAi } from "../../ai/hook";
import { TranslateComponent } from '../Translate.component/Translate.component';

export const TranslateFieldContainer = (props) => {
    const {
        fieldValue,
        editor,
        onChangeContent,
        ...other } = props;

    const { getCommonAi } = useGetCommonAi();
    const { langs } = useGetLangs();
    const { lang } = useContext(FirebaseContext);
    const [isOpen, setIsOpen] = useState(false);
    const [currentlang, setCurrentlang] = useState(lang);
    const [error, setError] = useState();
    const [inProgressTranslate, setInProgressTranslate] = useState(false);
    const { formatMessage } = useIntl();
    const handleLangChange = async (id) => {
        setCurrentlang(langs.find(el => el.id === id))
    };

    const handleTranslate = async (lang) => {
        setInProgressTranslate(formatMessage({ id: 'loader_translate_field' }));
        
        const translatedValueJSON = await getCommonAi(`Return the only json_object with translated value: {text: "${fieldValue}" } in ${lang.title} language without other words, dont recommend google translate. `, 'translation');
        const translatedValue = JSON.parse(translatedValueJSON);

        editor.commands.setContent(translatedValue?.text);
        if (onChangeContent) {
            onChangeContent(translatedValue?.text);
        }
        setInProgressTranslate('');
    }

    return (
        <TranslateComponent
            handleLangChange={handleLangChange}
            handleTranslate={handleTranslate}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            currentlang={currentlang}
            langs={langs}
            inProgressTranslate={inProgressTranslate}
            userIsAllow={true}
            {...other}
        />

    )
}
