
import { useTrackCredits } from '../../common/hooks/useTrackCredits';
import { useGenerateAiAvatar } from '../hook';

export const useGenerateAiAvatars = () => {
    const { trackCredits } = useTrackCredits()
    const { generateAiAvatar, loading } = useGenerateAiAvatar();

    const generateAiAvatarsForVideo =
        async (chunks, avatarPicture, setError, userId, taskId) => {

            const aiAvatarPromises = chunks.map(async (chunk, index) => {
                if (!chunk?.avatar) {
                    try {
                        const resp = await generateAiAvatar({
                            variables: {
                                audio: chunk?.audio,
                                source: avatarPicture,
                                userId,
                                taskId
                            }
                        });
                
                        const { url, duration } = resp?.data?.aiAvatarGenerate;
                        
                        if (url) {
                            const convertedDuration = Number((duration / 60).toFixed(2));
                            trackCredits(convertedDuration, 'did');
                            return { ...chunk, aiAvatarUrl: url };
                        }


                    } catch (error) {
                        console.error('Error generating audio:', error);
                        return chunk;
                    }
                } else {
                    return chunk;
                }
            });

            try {
                const updatedChunks = await Promise.all(aiAvatarPromises);
                return updatedChunks;
            } catch (error) {
                setError(error);
            }
        };

    return { generateAiAvatarsForVideo };
};

