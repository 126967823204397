import React, {useEffect, useRef, useState} from 'react';
import {isEmpty} from 'lodash';
import {RecipientComponent} from '../Recipient.component';
import {getEventsData, getGroupedPayments, updatePayments} from '../util';
import {getConvertedObjectToArray, isFinished} from '../../common';

export const RecipientContainer = (props) => {
    const {
        as: Component = RecipientComponent,
        uid,
        recipientData,
        userData,
        ...other
    } = props;

    const [finishedList, setFinishedList] = useState();
    const [unFinishedList, setUnFinishedList] = useState();
    const [userPayments, setUserPayments] = useState();
    const [userRequests, setUserRequests] = useState();
    const loadedRef = useRef();

    const checkPayments = async (data) => {
        let finished = [];
        const unFinished = [];
        const toFinished = [];

        data?.forEach((item) => {
            if (item?.payment?.wasProcessed) {
                finished.push(item);
            } else {
                if (isFinished(item?.payment?.dateProcessing)) {
                    toFinished.push(item);
                } else {
                    unFinished.push(item);
                }
            }
        });
        if (!isEmpty(toFinished)) {
            try {
                await updatePayments(toFinished, userData, uid);
                finished = finished.concat(toFinished);
            } catch (e) {
                console.warn(e);
            }
        }

        await setFinishedList(getGroupedPayments(finished));
        await setUnFinishedList(getGroupedPayments(unFinished));
    };

    useEffect(() => {
        (async () => {
            setUserPayments(userData?.payments && getConvertedObjectToArray(userData?.payments).sort((a, b) => b.requestDate - a.requestDate));
            if (!loadedRef.current && userData !== undefined && recipientData) {
                const allData = await getEventsData(recipientData);
                await checkPayments(allData);
                loadedRef.current = true;
            }
        })();
    }, [userData, recipientData])

    return (
        <Component {...other}
                   userData={userData}
                   finishedList={finishedList}
                   unFinishedList={unFinishedList}
                   userPayments={userPayments}
                   balance={userData?.balance}/>
    )
}