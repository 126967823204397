import './scss/index.scss';

import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { noop } from 'lodash';
import { Button, Form, Input } from 'antd';

import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { useTrackClick } from '..';
import translate from '../../../lang/translate';
import { ResetPassComponent } from '../ResetPass.component/ResetPass.component'

export const AuthFormComponent = (props) => {
    const {
        token,
        source,
        invites,
        onCancelLogin = noop,
        onLoginUser = noop,
        onLoading = noop,
        type,
        signIn,
        userData,
        isReadOnlyEmail = false
    } = props;
    const { loginUserEmailPass, error } = useContext(FirebaseContext);
    const trackClick = useTrackClick();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const [isValidEmail, setIsValidEmail] = useState(true);
    const { isUnschoolerCustomer = false, userEmail } = userData || {};
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        onLoading(true);
        trackClick(
            'click-login',
            translate('auth_button'),
            `https://unschooler.me${history.location.pathname}`
        );

        try {
            const {email} = values;
            const invite = invites?.find((inv) => inv.email === email);
            const userData = await loginUserEmailPass(history, { token, source }, {...values, ...(!!invite && {inviteId: invite.id})}, isUnschoolerCustomer || signIn);
            onLoginUser(userData);
            userData && onCancelLogin(false);
            const isRedirectToManePage = history.location.pathname.endsWith('/reset-password');

            if (isRedirectToManePage) {
                history.push('/');
            }
        } catch (error) {
            onLoading(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.warn('Failed:', errorInfo);
    };

    return (
        <Form
            name="basic"
            form={form}
            initialValues={{
                remember: true,
                ...(userEmail && {
                    email: userEmail
                })
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
        >
            <Form.Item
                label={type !== 'compact' && "Email"}
                name="email"
                rules={[
                    {
                        required: true,
                        message: formatMessage({id: 'enter_email_warning'}),
                    },
                    {
                        validator: (_, value) => {
                            const rx = /\S+@\S+\.\S+/;
                            const isValid = value === '' || rx.test(value);
                            if (isValid) {
                                setIsValidEmail(true);
                            }
                            return isValidEmail ? Promise.resolve() : Promise.reject(new Error(formatMessage({id: 'wrong_email_warning'})));
                        }
                    }
                ]}
            >
                <Input placeholder={formatMessage({id: 'email_input_text'})}
                       type="email"
                       disabled={isReadOnlyEmail} />
            </Form.Item>

            <Form.Item
                label={type !== 'compact' && "Password"}
                name={"password"}
                rules={[
                    {
                        required: true,
                        message: formatMessage({id: 'enter_password_warning'}),
                    },
                ]}
            >
                <Input.Password placeholder={formatMessage({id: 'password_input_text'})} />
            </Form.Item>

            <div className=" error">
                {error}
            </div>
            {!token &&
                <div className='reset-pass wrapper_inner-section'>
                    <ResetPassComponent form={form}
                                        onCancelLogin={onCancelLogin}
                                        onSetIsValidEmail={setIsValidEmail}/>
                </div>
            }

            <Form.Item>
                <Button type="primary" htmlType="submit" block='true' >
                    {formatMessage({id: 'login_button'})}
                </Button>
            </Form.Item>
        </Form>
    );
};
