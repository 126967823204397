import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GetElevenLabsVoices } from '../graphQL/queries/GetElevenLabsVoices';

const EMOJI = (gender, age) => {
    if (gender === 'male') {
        if (age === 'old') {
            return '👴';
        } else if (age === 'middle aged' || age === 'middle-aged') {
            return '👨';
        } else if (age === 'young') {
            return '👶';
        }
    } else if (gender === 'female') {
        if (age === 'old') {
            return '👵';
        } else if (age === 'middle aged' || age === 'middle-aged') {
            return '👩';
        } else if (age === 'young') {
            return '👶';
        }
    } else {
        return '';
    }
    return '';
}

const LANGS = (accent, name) => {
    if (accent === 'irish') {
        return '🇮🇪';
    } else if (accent === 'british') {
        return '🇬🇧';
    } else if (accent === 'australian') {
        return '🇦🇺';
    } else if (accent === 'swedish') {
        return '🇸🇪';
    } else if (accent === 'italian') {
        return '🇮🇹';
    } else if (accent === 'italian') {
        return '🇮🇹';
    } else if (name === 'Michael') {
        return '🇫🇷';
    } else {
        return '';
    }
}

export const useGetVoices = (query = GetElevenLabsVoices) => {
    const { data } = useQuery(query);

    return useMemo(() => {
        if (data?.getElevenLabsVoices) {
            return data?.getElevenLabsVoices?.map((item) => ({
                label: EMOJI(item.labels.gender, item.labels.age) + ' ' + item.name + ' ' + LANGS(item.labels.accent),
                value: item.value,
                model: item.model,
                example: item.example
            }))
        }
        return null;
    }, [data]);
}