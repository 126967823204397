import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { TaskInfoComponent } from './';
import { useTrackClick, getTitle } from '../common';
import { useLocalePrefix } from '../lang';

export const TaskLink = (props) => {
    const {
        task,
        actionHidden,
        index,
        user,
        viewer,
        handleParticipate,
        taskUrl,
        workspace,
        onSetTheme,
        isEmbed,
        isExpanded,
        ...other
    } = props;
    const trackClick = useTrackClick();
    const [localePrefix] = useLocalePrefix();
    const taskLink = isEmbed ? `${localePrefix}/embed/tutorials/${taskUrl}` : `${localePrefix}/tutorials/${taskUrl}`;

    return (
        <Link
            className='answer-component_card'
            to={{ pathname: taskLink, state: { workspace } }}
            onClick={() =>
                trackClick(
                    'click-task',
                    task.id,
                    `https://tasks${localePrefix}/tutorials/${taskUrl}`
                )
            }
        >
            <TaskInfoComponent
                task={task}
                onSetTheme={onSetTheme}
                actionHidden={actionHidden}
                index={index}
                user={user}
                viewer={viewer}
                handleParticipate={handleParticipate}
                {...other}
            />
            {isExpanded && task?.questions?.length > 0 && task?.questions.map((question) => (
                question.question && (
                    <Link
                        to={`${localePrefix}/tutorials/${task.alias}-${task.id}?step=${question.id}`}
                        className='task-component__question task-component__question_link block-el'
                        key={question.id}
                    >
                        {getTitle(question.question)}
                    </Link>
                )
            ))}
        </Link>
    );
};
