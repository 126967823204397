import {useLazyQuery, gql} from '@apollo/client';
import {useCallback} from 'react';
import {useOwnerData, useGetLangId} from '../../common';

export const useAiChatResponse = (query = AI_RESPONSE) => {
    const [getChatResp, { data: jsonData, loading, error }] = useLazyQuery(query, { fetchPolicy: "no-cache" });
    const getOwnerData =  useOwnerData();
    const langId = useGetLangId();

    let data;
    try {
        data = jsonData?.requestChatApi && JSON.parse(jsonData?.requestChatApi);
    } catch (e) {
        console.warn(e);
    }

    const getChatResponse = useCallback(async (data) => {
        const ownerData = await getOwnerData();
        const input = data?.variables?.input;
        const owner = ownerData?.ownerId ? ownerData : {ownerId: ownerData?.customerId};

        const mergedData = {
            variables: {
                input: {
                    ...input,
                    owner,
                    ...(!!langId && {langId})
                }
            }
        }

        return getChatResp(mergedData);
    }, [getChatResp, getOwnerData, langId]);

    return {
        getChatResponse,
        loading, error,
        data
    }
};

const AI_RESPONSE = gql`
    query Query($input: chatApiInput!) {
        requestChatApi(input: $input)
    }
`;
