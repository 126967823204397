import {useCallback} from 'react';
import {useAiChatResponse} from '../graphQL/useAiChatResponse';

export const useGetExplainVideo = () => {
    const {getChatResponse, error} = useAiChatResponse();

    const getExplainVideo = useCallback(
        async (context, ask, piece) => {
            const settings = {
                args: {
                    context: context,
                    ask: ask,
                    piece: piece,
                },
                type: 'explainVideo',
            };
            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });
            const responseJSON = JSON.parse(response?.data?.requestChatApi);

            if (responseJSON?.choices[0]) {
                let responseTrimmed =
                    responseJSON.choices[0].message.content.trim();
                return responseTrimmed;
            }
        },
        [getChatResponse]
    );

    return {getExplainVideo};
};
