import {getSubjects} from './getSubjects';
import {getTeacherSubjects} from './getTeacherSubjects';
import {getCacheManager} from './getCacheManager';

const CACHE_KEY = 'cache-teacher-subjects-';
const {hasCache, getCache, setCache} = getCacheManager();

export const getFilteredTeacherSubjects = async (id) => {
    const key = CACHE_KEY+id;
    const isCache = hasCache(key);
    if (!isCache) {
        const allSubjects = await getSubjects();
        const teacherSubjects = await getTeacherSubjects(id);
        const data = teacherSubjects && allSubjects.filter((subject) => teacherSubjects.includes(subject.id));
        setCache(key, data);
    }
    return getCache(key);
}