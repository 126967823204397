import {useCallback} from 'react';
import {useMutation} from '@apollo/client';
import {DeleteCustomDomain} from '../graphQL/mutations/DeleteCustomDomain';

export const useDeleteCustomDomain = (mutation = DeleteCustomDomain) => {
    const [deleteDomain, { data, error, loading }] = useMutation(mutation);

    const deleteCustomDomain = useCallback(async (domain, workspaceId) => {
        const resp = await deleteDomain({
            variables: {
                domain,
                workspaceId,
                environment: process.env.REACT_APP_ENVIRONMENT
            }
        });
        return resp;
    }, [deleteDomain])

    return {
        data,
        error, loading,
        deleteCustomDomain
    };
}