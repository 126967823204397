import React from 'react';
import { Button } from 'antd';
import { Svg } from '../../common';

export const InvitedUser = ({ invite, handleRemoveParticipant }) => {
    return (
        <div className='wrapper_inner-section '>
            <small className='text_secondary inline-el'>
                {invite.invitedEmail}
            </small>
            <Button
              className='inline-el link-btn_light'
                type='text'
                onClick={() =>
                    handleRemoveParticipant(invite.id)
                }
            >
                <Svg
                    name=':icon/cross'
                    className='btn-icon icon_16'
                />
            </Button>
        </div>
    );
};
