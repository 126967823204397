import {gql} from "@apollo/client";

export const GetTaskEdit = gql`
    query GetTaskEdit($taskId: Int, $alias: String) {
        task(id: $taskId, alias: $alias) {
            id
            creatorId
            tags {
                id
                title
            }
            alias
            title
            coverId
            cover {
                source
                type
                summary
                timestamps {
                    timestamp
                    title
                    description
                }
            }
            description
            isFree
            isDraft
            nextTaskId
            questions { 
                id
                question
                explanation
                answer
                options {
                    id
                    order
                    text
                    isCorrect           
                }
                order
                type
                title
            }
            taskInEvents {
                eventUid
            }
            source
            summary
            isAutogenerated
        }
    }
`;
