import { useCallback, useContext } from 'react';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { useDataPermissions } from '../../common/';

export const useIsPermit = () => {
    const { user } = useContext(FirebaseContext);
    const dataPermissions = useDataPermissions();

    const checkPermit = useCallback((workspace, featureType) => {
        const userRole = workspace?.members?.find(member => member.userId === user.id)?.permission;
        if (!user?.id || !userRole) {
            return false;
        }
        if (user?.role?.id === 1) {
            return true;
        } else {
            const rolePermissions = dataPermissions.find(obj => obj.key === featureType);
            return rolePermissions[userRole] || false;
        }
    }, [user?.id]);

    const getRolePermissions = useCallback((workspace) => {
        return workspace?.members?.find(member => member.userId === user.id)?.permission;
    }, []);
    return { checkPermit, getRolePermissions };
};
