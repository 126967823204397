import {useMutation} from '@apollo/client';
import {RemoveCertificate} from '../graphQL/mutations/RemoveCertificate';

export const useRemoveCertificate = (mutation = RemoveCertificate) => {
    const [removeCertificate, { data, error, loading }] = useMutation(mutation);

    return {
        removeCertificate,
        data,
        error,
        loading
    };
}