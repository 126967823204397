import {useMutation} from '@apollo/client';
import {DeleteWorkspaceMember} from '../graphQL/mutations/DeleteWorkspaceMember';

export const useDeleteWorkspaceMember = (mutation = DeleteWorkspaceMember) => {
    const [deleteWorkspaceMember, {data, error, loading}] = useMutation(mutation);

    return {
        deleteWorkspaceMember,
        data,
        error,
        loading
    }
}