
import './scss/index.scss';
import React, { useState, useContext } from 'react';
import { Button, Input } from 'antd';

import { useAiAudioTokens, useGetAudio } from '../../../ai';
import { VoiceSelector } from './VoiceSelector'
import { LoaderComponent } from '../../../common';
import { useTextToAudio } from '../../../audio/hook';
import { useTrackCredits } from '../../../common/hooks';
import { AudioPlayerComponent } from '../../../audio';

const { TextArea } = Input;

export const AudioGenerator = ({
    getDefaultValue,
    onChangeField,
    index,
    field,
    setError,
    formatMessage,
    setChunks,
    chunk,
    chunks,
    handleSetAudio,
    ...other
}) => {
    let initialValue = getDefaultValue(field.key, 'description')

    const [voiceIsChanged, setVoiceIsChanged] = useState();
    const [voiceMode, setVoiceMode] = useState();
    const [inProgress, setInProgress] = useState();
    const { trackAudioTokens } = useAiAudioTokens()
    const { trackCredits } = useTrackCredits()
    const { getTextToAudio } = useGetAudio();
    const { getAudioUrl, loading } = useTextToAudio();

    const generateAudioForChunk = async () => {
        setInProgress(formatMessage({ id: 'progress_audio' }))
        let audioTokens = voiceIsChanged?.length || initialValue.length || 0;
        await trackAudioTokens(audioTokens);

        try {
            if (voiceMode?.model === 'eleven' || voiceMode?.model === 'custom-voice') {
                let { data } = await getAudioUrl({
                    variables: {
                        text: voiceIsChanged || initialValue,
                        voiceId: voiceMode?.value,
                    }
                })
                trackCredits(voiceIsChanged?.length || initialValue.length, 'elevenLabs');
                setInProgress('')
                handleSetAudio(data.textToAudio.url, index)

            } else {
                let response = await getTextToAudio(voiceIsChanged || initialValue, voiceMode?.value);
                // setAttempt(attempt - 1)
                setInProgress('')
                handleSetAudio(response, index)
            }

            setVoiceMode(null)
            setVoiceIsChanged(null)
        } catch (error) {
            console.error('Error generating audio:', error);
            setInProgress('')
        }
        setInProgress('')
    };

    return (
        <div >
            <TextArea
                autoComplete='off'
                placeholder='1-3 words title'
                // disabled='true'
                defaultValue={initialValue}
                onChange={(e) => {
                    setVoiceIsChanged(e.target.value)
                    onChangeField(index, 'description', e.target.value)
                }}
                autoSize={{ minRows: 1, maxRows: 8 }}
                maxLength={1000}
                showCount
            />

            <div className='edit-task-component__question-type'>
                <VoiceSelector
                    setVoiceMode={setVoiceMode}
                    voiceMode={voiceMode}
                    {...other}
                />

                <Button
                    disabled={!voiceMode || inProgress}
                    size="small" type="secondary"
                    onClick={() => {
                        setVoiceIsChanged()
                        generateAudioForChunk()
                    }}>
                    {formatMessage({ id: 'regenerate' })}
                </Button>
                {/* <small className='text_secondary'>{attempt} times</small> */}
            </div>
            {inProgress ?
                <LoaderComponent type="inline" />
                :
                <AudioPlayerComponent audioUrl={chunk?.audio} isCreator={true}{...other} />
            }
        </div>
    );
};
