import moment from 'moment';

export const getConvertedSchedule = (data) => {
    const {start, end} = data;
    const format = 'HH:mm';
    let s = moment(start, format);
    const e = moment(end, format);
    const schedule = [];

    while (s < e) {
        schedule.push(s.format(format));
        s = moment(s, format).add(0.5, 'hour')
    }
    return schedule;
}