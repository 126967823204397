export const trackPartneroSignup = (customerKey, name, email) => {
  if (window.po) {
    window.po('customers', 'signup', {
      data: {
        key: customerKey,
        name: name,
        email: email
      }
    });
  }
}

export const c = (transactionKey, customerKey, amount, amount_units) => {
  if (window.po) {
    window.po('transactions', 'create', {
      data: {
        key: transactionKey, //'transaction_123'
        amount: amount, //99.99
        amount_units: 'usd',
        customer: {
          key: customerKey //'customer_123456'
        }
      }
    });
  }
}
