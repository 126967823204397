import { useCallback, useContext } from 'react';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { useIsPermit } from '../../roles/hook/useCheckPermission';
// const HOST_EXCLUDES = ['localhost', 'www', 'unschooler', 'me', 'ai'];

export const useIsAllow = () => {
    const { user } = useContext(FirebaseContext);
    // const hostnameList = window.location.hostname.split('.');
    // const hostname = hostnameList.find((item) => !HOST_EXCLUDES.includes(item));

    const { checkPermit } = useIsPermit()
    
    const callBack = useCallback((subscriptionType = 'START', workspace) => {
        const createCourses = checkPermit(workspace, 'createCourses') ;
        const editOthersCourses = checkPermit(workspace, 'editOthersCourses');
        if (createCourses || editOthersCourses) {
            return true;
        }

        if (!user?.subscriptionUser) {
            return false;
        }
        const { endDate } = user?.subscriptionUser;
        const date = endDate && new Date(endDate);
        const finishDate = date && date.setDate(date.getDate() + 1);
        const isExpired = finishDate && finishDate < Date.now();

        const subscriptionAsANumber = (subscription) => {
            switch (subscription) {
                case "START":
                    return 1;
                case "PREMIUM":
                    return 2;
                case "TIER_1":
                    return 3;
                case "CREATOR":
                    return 3;
                case "TIER_2": 
                    return 3;
                case "TIER_3":
                    return 4;
                case "PRO":
                    return 4;
                case "UNIVERSITY":
                    return 5;
                default:
                    return 0;
            }
        }

        const isHigherSubscription = subscriptionAsANumber(user.subscriptionUser.type) >= subscriptionAsANumber(subscriptionType);
        const isAllow = 
        // user?.role?.id === 1 || 
        (!isExpired && isHigherSubscription) || createCourses || editOthersCourses;
        // const isAllow = isHigherSubscription;
        return isAllow;
    }, [user?.id, user?.subscriptionUser]);

    return callBack;
};
