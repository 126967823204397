import React from 'react';
import {UserComponent} from '../User.component';
import {useGetSimpleUser} from '../../../common/graphQL';

export const UserContainer = () => {
    const {
        user,
        loading,
        error,
        getSimpleUser
    } = useGetSimpleUser();

    return <UserComponent user={user}
                          loading={loading}
                          error={error}
                          onGetUser={getSimpleUser}/>
}