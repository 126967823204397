import {gql} from "@apollo/client";

export const AiTokensStatistics = gql`
    query getAiTokens($input: AiTokensInput) {
        getAiTokens(input: $input) {
            totalCredits
            creditsData {
                model
                credits
            }
            isCanceled
            planIsNotExist
            from
            to
        }  
    }
`;
