import React, {useState, useEffect} from 'react';
import {noop} from 'lodash';
import {PanelComponent} from '../Panel.component';

export const PanelContainer = (props) => {
    const {
        panelId,
        dbPropertyKey,
        skipPanelData = false,
        onRemovePanels = noop,
        onUpdateHash = noop,
        panelNumber = null,
        ...other
    } = props;

    const [panelData, setPanelData] = useState(null);
    const onSetPanelData = (data) => setPanelData(data);
    const getPanelData = async (id = panelId) => {
        onUpdateHash(panelId, panelNumber);
    };

    const handleRemovePanel = () => {
        onRemovePanels();
        setPanelData(null);
        if (panelNumber) {
            onUpdateHash(panelId, panelNumber, true);
        }
    };

    useEffect(() => {
        if (!skipPanelData && panelId) {
            (async () => await getPanelData())();
        } else {
            onSetPanelData(skipPanelData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelId]);

    return (panelData && <PanelComponent {...other}
                                         panelData={panelData}
                                         onRemovePanel={handleRemovePanel}
                                         onUpdateHash={onUpdateHash}
                                         panelNumber={panelNumber}
                                         dbPropertyKey={dbPropertyKey}/>);
};
