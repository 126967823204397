import {gql, useQuery} from '@apollo/client';

export function useGetLevels() {
    const {data, loading, error} = useQuery(GET_LEVELS);

    return {
        levels: data?.levels,
        loading,
        error
    };
}

const GET_LEVELS = gql`
    query getLevels {
        levels {
            id
            levelUid
            title
            order
        }
    }
`;