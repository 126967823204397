import React, { useEffect } from 'react';
import { AiTokensComponent } from '../AiTokens.component';
import { useAiTokensStatistics } from '../hooks';

export const AiTokensContainer = (props) => {
    const { user, ...other } = props;
    const { getAiTokensStatistics, loading, data } = useAiTokensStatistics();
    const { planIsNotExist, totalCredits, from } = data?.getAiTokens || {};

    useEffect(() => {
        if (user?.id) {
            (async () => {
                await getAiTokensStatistics({ ownerId: user.id });
            })()
        }
    }, [getAiTokensStatistics, user]);

    return <AiTokensComponent isLoading={loading}
        planIsNotExist={planIsNotExist}
        totalCredits={totalCredits}
        from={from}
        user={user}
        {...other} />
}