import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {CheckoutComponent} from '../Checkout.component';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {useTrackClick} from '../../common';
import {useLocalePrefix} from '../../lang';
import {gql, useMutation} from '@apollo/client';

export const CheckoutContainer = ({data}) => {
    const trackClick = useTrackClick();
    const {user, loading: userLoading} = useContext(FirebaseContext);
    const history = useHistory();
    const [localePrefix] = useLocalePrefix();
    const [usePromocode, promocodeData] = useMutation(USE_PROMOCODE);

    const {
        event,
        eventId,
        isSubscription,
        giftBox,
        guide,
        isSpecialEvent,
        isTestSkills
    } = data;

    const [product, setProduct] = useState(event || giftBox || guide);
    const {total, forTeacher, forStudent} = (isSubscription ? event?.priceSubscription : event?.price) || {};
    
    const onEventsSucces = async (payload, studentData) => {
        if (event.usedPromocode) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            await usePromocode({
                variables: {
                    content: {
                        text: event.promocode.text,
                        eventId: eventId,
                    },
                },
            });
        }
    };

    const handleApplyCode = (data) => {
        setProduct((p) => {
            return {
                ...p,
                promocode: data,
                usedPromocode: true,
            };
        });
    };

    const handleSuccess = async (payload, studentData, giftBayer) => {
        await onEventsSucces(payload, studentData);

        trackClick(
            'checkout-success',
            {eventId: event?.id},
            `https://unschooler.me${localePrefix}/success/`
        );
        history.push(`${localePrefix}/success/`, {product, payload});
    };

    return (
        <CheckoutComponent
            product={product}
            eventId={eventId}
            user={user}
            userLoading={userLoading}
            onSuccess={handleSuccess}
            onApplyCode={handleApplyCode}
            isSubscription={isSubscription}
            isSpecialEvent={isSpecialEvent}
            isTestSkills={isTestSkills}
        />
    );
};

const USE_PROMOCODE = gql`
    mutation UsePromocode($content: usePromocodeInput!) {
        usePromocode(content: $content) {
            id
        }
    }
`;
