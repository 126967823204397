import { gql, useMutation } from "@apollo/client";

export const useCheckActivation = () => {
    const [checkActivationMutation, { data, loading, error }] = useMutation(CHECK_SUBSCIPTION);

    const checkActivation =  async (sourceData) => {
        const result = await checkActivationMutation({
            variables: { sourceData }
        });
        return result.data?.checkActivation;
    }

    return {
        loading,
        error,
        checkActivation
    }
}

const CHECK_SUBSCIPTION = gql`
    mutation CheckActivation($sourceData: CheckActivationInput!) {
        checkActivation(sourceData: $sourceData) {
            isActivated
        }
    }
`;