import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'antd';
import { noop, cloneDeep } from 'lodash';

import { AIAskComponent } from '../AIAsk.component/AIAsk.component';
import { AIPopoverComponent } from '../AIPopover.component';
import { useGetTeacher } from '../../common/graphQL';
import { LoaderTypeComponent, AvatarCustom , useTrackClick} from '../../common';
import {
    useGetImagesDalle,
    useGetVideo,
    useGetFeedback,
    useGetCommonAi,
    useGetAssistant
} from "../";

export const AiAssistantContainer = ({
    task,
    inProgressHighlight,
    question,
    userIsAllowGenerateContent,
    user,
    // maybe this one?
    onUpdateResult = noop,
    onUpdateResultDescription,

    setInProgressHighlight,
    handleScrollClick,
    handleReplaceQuestion,
    handleEditTask,
    setHighlightedNode,
    setHighlightedRequest,
    highlightedRequest,
    highlightedNode,
    instructorId,
    ...other
}) => {
    const { getTeacher, loading: loadingGetTeacher, teacher } = useGetTeacher();
    const [teacherState, setTeacherState] = useState(teacher);
    const { getVideo } = useGetVideo();
    const { getCommonAi } = useGetCommonAi();
    const { getFeedback } = useGetFeedback();
    const { getAiImage } = useGetImagesDalle();
    const { onAssistantRequest } = useGetAssistant();
    let goal = task?.title?.substring(0, 150);

    const checkMentor = async () => {
        if (instructorId) {
            try {
                const resp = await getTeacher(instructorId);
                if (resp) {
                    setTeacherState(resp);
                }
            } catch (err) {
                console.warn("Failed to fetch teacher:", err);
            }
        }
    };
    const getRequestString = (request, title) => {
        if (request) {
            return request;
        } else {
            return title?.substring(0, 100);
        }
    };
    const getFinalString = (description, htmlFromDescription) => {
        if (userIsAllowGenerateContent || !user?.id) {
            if (highlightedRequest) {
                let newString = '';
                if (highlightedNode) {
                    newString = highlightedNode?.replace(highlightedRequest, `${highlightedRequest}`) + `\n\n${htmlFromDescription}`;
                    return description?.replace(highlightedNode, newString);
                } else {
                    newString = `${description?.replace(highlightedRequest, `${highlightedRequest}`)}\n\n${htmlFromDescription}`;
                    return newString;
                }
            } else {
                return description + htmlFromDescription;
            }
        } else {
            return htmlFromDescription;
        }
    };

    const [value, setValue] = useState();
    const [isVisible, setIsVisible] = useState();
    const { useForm } = Form;
    const [form] = useForm();
    const { getFieldsValue, setFieldsValue } = form;
    const handleChange = (e) => {
        const val = e.target.value;
        setValue(val);
        setIsVisible(!!val);
    };
 
    const handleCleanHighlight = () => {
        setHighlightedNode();
        setHighlightedRequest();
    };
    const trackClick = useTrackClick();


    const findEntity = (questionId) => {
        return questionId
            ? task?.questions?.find((el) => el.id === questionId)
            : { title: task.title, question: task.description };
    };
    const handlePrepareContextAI = async (questionId, newDescription, entityFind, htmlFromDescription) => {
        if (userIsAllowGenerateContent || !user?.id) {
            if (questionId) {
                let newQuestion = cloneDeep(entityFind);
                newQuestion.question = newDescription;
                await handleReplaceQuestion(newQuestion, questionId, null, htmlFromDescription);
            } else {
                await handleEditTask(newDescription);
            }
            handleScrollClick()
        } else {
            await onUpdateResultDescription(newDescription)
        }
        handleCleanHighlight()
    }
    const handleContextAI = async (questionId, type, request) => {
        trackClick(
            "click-ai-bot-example",
            "example",
            "https://unschooler.me/"
        );
        setInProgressHighlight(true);
        let entityFind = findEntity(questionId);
        const requestString = getRequestString(request, entityFind?.title) || '';
        const responseTrimmed = await getCommonAi(requestString, type);

        if (responseTrimmed) {
            let htmlFromDescription = `\n\`\`\`\n${responseTrimmed}\n\`\`\`\n`;
            let newDescription = getFinalString(entityFind.question, htmlFromDescription);
            handlePrepareContextAI(questionId, newDescription, entityFind, htmlFromDescription)
            setInProgressHighlight(false);
        }
    };
    const handleVideo = async (questionId, request) => {
        trackClick(
            "click-ai-bot-video",
            "video",
            "https://unschooler.me/"
        );
        setInProgressHighlight(true);
        let questionFind = questionId
            ? task?.questions?.find((el) => el.id === questionId)
            : { title: task.title, question: task.description };

        const requestString = getRequestString(request, questionFind.title);
        let entityFind = findEntity(questionId);
        let iframeHtml = await getVideo(requestString);
        
        if (iframeHtml) {
            const htmlFromDescription = `\n\n${iframeHtml}\n\n`;
            let newDescription = getFinalString(questionFind.question, htmlFromDescription);
            
            handlePrepareContextAI(questionId, newDescription, entityFind, htmlFromDescription)
            setInProgressHighlight(false);
        }
    };
    const handleImage = async (questionId, request) => {
        trackClick(
            "click-ai-bot-image",
            "image",
            ""
        );
        setInProgressHighlight(true);
        let entityFind = findEntity(questionId);
        const requestString = getRequestString(request, entityFind.title);
        const urlFile = await getAiImage(requestString, goal);

        if (urlFile) {
            const htmlFromDescription = `\n<div className="image-snippet"><img src="${urlFile}" alt=${requestString?.substring(0, 20)}} /></div>\n\n`;
            let newDescription = getFinalString(entityFind.question, htmlFromDescription);
            handlePrepareContextAI(questionId, newDescription, entityFind, htmlFromDescription)
            setInProgressHighlight(false);
        }
    }
    const handleFeedbackAi = async (e, question, assistantId) => {
        setInProgressHighlight(true);
        let entityFind = findEntity(question?.id);
        let htmlPrevious = entityFind.question;
        let responseTrimmed = '';

        if (assistantId) {
            let requestString = `${e.ask}. The context is: ${htmlPrevious.substring(0, 15000)}`;
            const resp = await onAssistantRequest(requestString, assistantId);
            responseTrimmed = resp[0].text?.value.replace(/【\d+:\d+†source】/g, '');
        } else {
            responseTrimmed = await getFeedback(
                e?.ask,
                htmlPrevious.substring(0, 15000),
                '',
                entityFind.title
            );
        }

        if (responseTrimmed) {
            const htmlFromDescription = `\n\n#### ${e.ask}\n${responseTrimmed}`;
            let newDescription = getFinalString(entityFind.question, htmlFromDescription);
            handlePrepareContextAI(question?.id, newDescription, entityFind, htmlFromDescription)
        }
        setInProgressHighlight(false);
    };


    const cleanInput = () => {
        setFieldsValue({ ask: '' });
        setValue('');
    };
    const handleSendForm = async (formData) => {
        handleFeedbackAi(formData, question, teacherState?.assistantId);
        cleanInput();
    }
    const handleFocus = (e) => {
        const keyCode = e.keyCode || e.which;
        const isSubmit = !e.shiftKey && keyCode === 13;
        if (!inProgressHighlight && isSubmit) {
            const formData = getFieldsValue();
            handleSendForm(formData);
        }
    };

    const videoRef = useRef(null);

    useEffect(() => {
        if (!teacher) {
            (async () => await checkMentor())();
        }
    }, [instructorId]);

    useEffect(() => {
        if (videoRef.current) {
            if (inProgressHighlight) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [inProgressHighlight]);

    return (
        <div className="ai-bot_wrapper ">
            {teacherState?.assistantId && (
                <div
                    className='ai-bot-component_picture'
                    onMouseOver={() => videoRef.current.play()}
                    onMouseOut={() => {
                        if (!inProgressHighlight) {
                            videoRef.current.pause();
                        }
                    }}
                >
                    <video
                        width="90"
                        height="90"
                        loop
                        muted
                        ref={videoRef}
                    >
                        <source src="https://cdn.unschooler.me/web_app/ai-mentors/ai_avatars/1578_animated.mp4" type="video/mp4" />
                        <AvatarCustom user={teacherState} size={100} />
                    </video>
                </div>
            )}

            <div className='ai-bot-component_wrapper '>
                <AIAskComponent
                    user={user}
                    task={task}
                    assistant={teacherState}
                    handleChange={handleChange}
                    handleSendForm={handleSendForm}
                    handleFocus={handleFocus}
                    form={form}
                    isVisible={isVisible}
                    value={value}
                    question={question}
                    {...other}
                />
                {inProgressHighlight ? (
                    <LoaderTypeComponent block={true} />
                ) :
                    <AIPopoverComponent
                        entityId={question?.id || null}
                        task={task}
                        value={value}
                        cleanInput={cleanInput}
                        handleImage={handleImage}
                        handleVideo={handleVideo}
                        handleContextAI={handleContextAI}
                        inProgress={inProgressHighlight}
                        highlightedRequest={highlightedRequest}
                        handleCleanHighlight={handleCleanHighlight}
                        {...other}
                    />
                }
            </div>
        </div>
    )
}