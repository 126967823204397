import {gql} from "@apollo/client";

export const CreateInvites = gql`
    mutation CreateInvites($input: [InviteInput]) {
        createInvites(input: $input) {
            id
            permission
            email
            workspaceId
            userId
            isActivated
            createdAt
        } 
    }
`;
