import { Node } from '@tiptap/core'

export const Iframe = Node.create({
    name: 'iframe',
    group: 'block',
    atom: true,

    addOptions() {
        return {
            allowFullscreen: true,
            HTMLAttributes: {
                className: 'iframe-wrapper',
            },
        }
    },

    addAttributes() {
        return {
            src: {
                default: null,
            },
            frameborder: {
                default: 0,
            },
            allowfullscreen: {
                default: this.options.allowFullscreen,
                parseHTML: () => this.options.allowFullscreen,
            },
            className: {
                default: 'tiptap-iframe'
            },
            width: {
                default: null, // Default width can be set here
            },
            height: {
                default: null, // Default height can be set here
            },
        }
    },

    parseHTML() {
        return [{
            tag: 'iframe',
        }]
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', this.options.HTMLAttributes, ['iframe', HTMLAttributes]]
    },

    addCommands() {
        return {
            setIframe: (options) => ({ tr, dispatch }) => {
                const { selection } = tr
                const node = this.type.create(options)
    
                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }
    
                return true
            },
        }
    },
})