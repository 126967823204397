import {useCallback, useContext} from 'react';
import {useMutation} from '@apollo/client';
import {CreateIntegration} from '../graphQL/mutations/CreateIntegration';
import {GetUserIntegrations} from '../graphQL/queries/GetUserIntegrations';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const useCreateIntegration = (mutation = CreateIntegration) => {
    const {user, workspace} = useContext(FirebaseContext);
    const {id: userId} = user || {};
    const {id: workspaceId} = workspace || {};
    const [createIntegrationFn, {data, loading, error}] = useMutation(mutation);
    const createIntegration = useCallback(async (data) => {
        return await createIntegrationFn({
            variables: {
                input: data
            },
            refetchQueries: () => [{query: GetUserIntegrations, variables: {userId, workspaceId}}]
        })
    }, [createIntegrationFn, userId]);
    return {data, createIntegration, loading, error};
}
