import {gql} from '@apollo/client';

export const GetWorkspace = gql`
    query GetWorkspace($id: Int, $host: String, $customDomain: String) {
        getWorkspace(id: $id, host: $host, customDomain: $customDomain) {
            id
            firebaseId
            host
            customDomain
            ownerId
            title
            logo
            cover
            favicon
            sharing
            loginCover
            description
            currencyCode
            address
            contactEmail
            copyright
            customPolicy
            customTerms
            customScript
            externalScripts
            stripeProductId
            hideTitle
            inviteOnly
            loginOnly
            status
            isDisabled
            eventsLists {
                id
                title
                workspaceId
                courses
                tags
                order
            }
            members {
                id
                permission
                workspaceId
                userId
            }
            resend {
                id
                domain {
                    id
                    name
                    status
                    records
                    resendId
                }
                email
                workspaceId
            }
            invites {
                id
                email
                userId
                workspaceId
                isActivated
                createdAt
            }
            createdAt
            updatedAt
            paypalAccountId
            activePaymentSystem
        }
    }
`;
