import './scss/index.scss';

import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { noop } from 'lodash';

export const UploadMp3Component = (props) => {
    const { formatMessage } = useIntl();

    const {
        onUploadSuccess = noop,
        onRemove = noop,
        savedFiles = [],
        maxCount = 1,
        isWide = false,
        title = formatMessage({ id: 'upload_mp3_file' }),
    } = props;
    const [fileList, setFileList] = useState(savedFiles);
    const [error, setError] = useState(false);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        if (!error) {
            const isUploaded = !!newFileList.length && !newFileList.find((file) => file.status !== 'done');
            if (isUploaded) {
                onUploadSuccess(newFileList);
            }
        }
    };

    const handleRemove = (file) => {
        const newFileList = fileList.filter((f) => f.uid !== file.uid);
        setFileList(newFileList);
        onRemove(file);
    };

    const inBeforeUpload = (file) => {
        const isMp3 = file.type === 'audio/mpeg';
        const isValidSize = file.size / 1024 / 1024 < 50; // 10MB limit
        const isValid = isMp3 && isValidSize;
        setError(!isValid);
        return isValid;
    };

    const componentClassNames = classnames('upload-mp3-component', {
        'upload-mp3-component_wide': isWide,
    });

    return (
        <div className={componentClassNames}>
            <Upload
                action="https://back.unschooler.me/file/upload"
                name="file"
                fileList={fileList}
                maxCount={maxCount}
                beforeUpload={inBeforeUpload}
                onChange={onChange}
                onRemove={handleRemove}
                showUploadList={{ showRemoveIcon: true, }}>
                {fileList.length < maxCount && (
                    <Button type='secondary' icon={<UploadOutlined />}>{title}</Button>
                )}
            </Upload>
            {error && (
                <div className="error">
                    {formatMessage({ id: 'upload_mp3_error' })}
                </div>
            )}
        </div>
    );
};