import {gql} from '@apollo/client';

export const TextToAudio = gql`
    query TextToAudio($text: String!, $voiceId: String, $saveData: SaveAudioDataInput) {
        textToAudio(text: $text, voiceId: $voiceId, saveData: $saveData) {
            url
            error
        }
    }
`;
