import './scss/index.scss';

import React from 'react';
import {Form, Checkbox, Button} from 'antd';
import {TagComponent} from '../../common';

export const CorrectSkillsComponent = ({allSkills, onSubmit}) => {
    const {useForm} = Form;
    const [form] = useForm();

    return (
        <Form
            name='create-event'
            layout='vertical'
            form={form}
            initialValues={{remember: true}}
            onFinish={onSubmit}
        >
            <div className='cards wrapper_outer-section correct-skills-component'>
                {allSkills?.map((subjectObj) => (
                    <div className='' key={subjectObj.subject.id}>
                 
                        <h4>{subjectObj?.subject?.title}</h4>
                        {subjectObj.tags.map((tagObj) => (
                            <div key={tagObj.tag.tag.id} className='skills_tag'>
                                <Form.Item
                                    name={tagObj.tag.tag.id}
                                    valuePropName='checked'
                                    initialValue={!!tagObj?.count}
                                >
                                    <Checkbox className='checkbox_focus' />
                                    <Checkbox>
                                        <TagComponent
                                            key={tagObj.tag.tag?.id}
                                            tag={{...tagObj.tag.tag, color: subjectObj.subject.color}}
                                            countResults={tagObj?.count}
                                        />
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <Form.Item>
                <Button type='primary' htmlType='submit' size='large' block>
                    Save skills
                </Button>
            </Form.Item>
        </Form>
    );
};
