import {useCallback} from 'react';
import {gql, useMutation} from "@apollo/client";

const DEFAULT_SUBJECT_ID = 52;

export const useCreateTag = () => {
    const [mutationCreateTag, {loading, error}] = useMutation(CREATE_TAG);

    const createTag = useCallback(async (title: string, subjectId: number = DEFAULT_SUBJECT_ID) => {
        const resp = await mutationCreateTag({
            variables: {
                tagData: {
                    title,
                    subjectId
                }
            }
        });
        return resp?.data?.createTag;
    }, [mutationCreateTag]);

    return {
        loading,
        error,
        createTag
    }
}

const CREATE_TAG = gql`
    mutation CreateTag($tagData: CreateTagInput!) {
        createTag(tagData: $tagData) {
            id
            title
        }
    }
`;