import React from "react";
import { Button } from "antd";
import { useIntl } from 'react-intl';
import { Svg, useProgress } from "../../common";
import { useGetContentQuiz } from "../hook";

export const MultiSelectButton = ({
  question,
  userIsAllowGenerate,
  handleReplaceQuestion,
  goal,
  task,
  regenerate,
  size = 'small',
}) => {
  const { inProgress, handleChangeProgress } = useProgress();
  const { formatMessage } = useIntl();
  const { getContentQuizAi } = useGetContentQuiz();

  const handleClick = async () => {
    handleChangeProgress({
      message: formatMessage({ id: 'loader_nextstepai_async' }),
      key: 'assessment',
    });
    const newQuestion = await getContentQuizAi(question, task, goal);
    if (newQuestion) {
      await handleReplaceQuestion({...newQuestion, question: `${question?.question}\n\n${newQuestion.question}`}, question.id, "⛳️");
    }
    handleChangeProgress({ key: 'assessment' });
  };

  return (
    <Button
      className="btn-left"
      disabled={inProgress || !userIsAllowGenerate}
      type={regenerate ? 'secondary' : 'tertiary'}
      size={size}
      onClick={handleClick}
    >
      <Svg name=':icon/logo-full_star' className='icon_16' />
      {regenerate ? formatMessage({ id: "regenerate" }) : formatMessage({ id: "generate" })}
      {' '}
      {formatMessage({ id: "learning_check" })}
    </Button>
  );
};