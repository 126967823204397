import './scss/index.scss';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Button } from 'antd'
import { Svg } from '../../common'
import { getTitle } from '../../common/utils';

export const Question = ({ question, index, showTaskView, activeId, task, handleDeleteQuestion }) => {
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        borderRadius: '8px',

        // change background colour if dragging
        background: isDragging ?
            task?.tags?.[0]?.subject?.color :
            activeId === question?.id && (task?.tags?.[0]?.subject?.color || '#999999') + '1c',

        // styles we need to apply on draggables
        ...draggableStyle
    });

    return (
        <Draggable draggableId={question?.id ? `${question?.id}` : question?.title} index={index}>
            {(provided, snapshot) => {
                return (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className='task-component_header answer-component_card__item_draggale'
                        style={getItemStyle(
                            snapshot.isDragging,
                            {
                                ...provided.draggableProps.style,
                                // left: "auto !important",
                                // top: "auto !important",
                            }
                        )}
                    
                    >
                        <div
                            // className='answer-component_card__item_draggale'
                            className='task-component_header'
                            type='text'
                            block="true"
                            size='small'
                            onClick={() => question?.id ? showTaskView(task, question?.id) : null}
                        >
                            <Svg name=':icon/dnd' className=' icon_16 drag_icon show-on-hover' />

                            <span className='answer-component_card btn-left'>
                                {getTitle(question?.question ? question?.question.replace(/#/g, '') : question?.title.replace(/#/g, ''))}
                            </span>

                        </div>
                        <Button
                            type="text"
                            size="small"
                            className="btn-left link-btn_light btn_up show-on-hover"
                            onClick={() => handleDeleteQuestion(task, index)}
                        >
                            <Svg name=':icon/cross' className='icon_check icon_16' />
                        </Button>

                    </div>
                )
            }}
        </Draggable>
    );
};