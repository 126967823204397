import './scss/index.scss';
import React, { useState } from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';

export const FaqComponent = ({ faq }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFaq = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <div className='wrapper_content faq_component'>
            <h2>FAQ:</h2>
            {faq.map((item, index) => (
                <div className='wrapper_content-section'>
                    <div className=''>
                        <hr />

                        <div className='faq_link ' onClick={() => toggleFaq(index)}>
                            <h3 className='faq_link-title'>{item.title}</h3>
                            <span className='faq_link-icon'>
                                {openIndex === index ? '-' : '+'}
                            </span>
                        </div>

                        {openIndex === index && (
                            <p className='step_description'>
                                {item.description}
                            </p>
                        )}

                    </div>
                </div>
            ))}
        </div>
    );
};
