import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { getTitle } from "../common";
import classNames from 'classnames';
import { AnswerComponent } from "../result/Answer.component";
import { SuggestionsGeneratorContainer } from "../suggestions/SuggestionsGenerator.container";
import { useLocalePrefix } from '../lang';

export const StudentResultsCard = ({ answer, prepareDataForSuggestions, ...other }) => {
    const [localePrefix] = useLocalePrefix();
    const cardStatusClass = classNames({
        'card_done': answer.status === 'CORRECT',
        'card_not_done': answer.status === 'INCORRECT',
        'card_default': !['CORRECT', 'INCORRECT'].includes(answer.status),
    });

    return (
        <Card
            hoverable
            className={classNames('suggestions-container', 'result__card', cardStatusClass)}
            bordered={false}
        >
            <SuggestionsGeneratorContainer
                rawdata={[prepareDataForSuggestions(answer)]}
                type='suggestionsLessonResult'
                {...other}
            />

            <h3>
                {answer.question ? (
                    <Link className="link-h3" to={`${localePrefix}/tutorials/${answer.task?.alias}-${answer.task?.id}?step=${answer.question?.id}`}>
                        Lesson: {getTitle(answer.question?.question?.substring(0, 50) || answer.question?.title?.substring(0, 50))}
                    </Link>
                ) : (
                    <Link className="link-h3" to={`${localePrefix}/tutorials/${answer.task?.alias}-${answer.task?.id}`}>
                        Module: {answer.task?.title}
                    </Link>
                )}
            </h3>

            <AnswerComponent
                key={answer?.result?.id}
                answer={answer}
            />
            <div className='gradient-overlay'></div>

        </Card>
    );
};