import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useLocalePrefix } from '../../lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AvatarCustom } from '../AvatarCustom';

export const ProfileLink = ({
    type,
    classType,
    isLink = true,
    isEllipsis = false,
    style,
    size,
    user,
    isMe = false,
}) => {
    const [localePrefix] = useLocalePrefix();
    const pathName = useMemo(() => {
        const idPart = user?.id ? '-' + user?.id : '';
        return idPart;
    }, [user?.id]);

    const ProfileName = classnames('profile-name inline-el', {
        'ellipsis-text': isEllipsis,
        'hidden-name': type === 'hidden-name',
        'text_inverse': type === 'inverse',
    });

    const profileLinkClassNames = classnames('profile-link link-secondary', {
        'hidden-name_link': type === 'hidden-name',
        'profile-avatar_shear': classType === 'shear',
    });

    return (
        <>
            {isLink && (
                <Link className='link-btn ant-btn ant-btn-text link-bt_text'
                    style={style}
                    // className={profileLinkClassNames}
                    to={{ pathname: isMe ? `${localePrefix}/profile` : `${localePrefix}/profile/${pathName}` }}
                >
                    <AvatarCustom user={user} size={size}/>
                    <h4 className={ProfileName}>{user?.name}</h4>
                </Link>
            )}

            {!isLink && (
                <span className={profileLinkClassNames}
                    style={style}>
                    <AvatarCustom user={user} size={size}/>
                    <h4 className={ProfileName}>{user?.name}</h4>
                </span>
            )}
        </>
    );
};
