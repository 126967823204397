import './scss/index.scss';

import React from 'react';
import {RequestItemComponent} from '../RequestItem.component';
import {getDate} from '../../../reports';

type Props = {
    requests: Array,
    finished: Array
}

export const RequestsComponent = (props: Props) => {
    const {
        requests,
        finished,
        ...other
    } = props;

    return (
        <div className="requests-component">
            {!!requests.length && (
                <div className="requests-component-box">
                    <table className="data-table">
                        <caption className="data-table__caption">Таблица запросов (ожидают оплаты)</caption>
                        <thead>
                        <tr>
                            <th className="data-table__col">Payoneer email</th>
                            <th className="data-table__col">Сумма</th>
                            <th className="data-table__col">ID пользователя</th>
                            <th className="data-table__col">Дата запроса</th>
                            <th className="data-table__col">Оплата</th>
                        </tr>
                        </thead>
                        <tbody>
                        {requests.map((item) => <RequestItemComponent key={item.id}
                                                                      {...other}
                                                                      item={item}/>)}
                        </tbody>
                    </table>
                </div>
            )}

            {!!finished.length && (
                <div className="requests-component-box">
                    <table className="data-table">
                        <caption className="data-table__caption">Таблица обработаных выплат</caption>
                        <thead>
                        <tr>
                            <th className="data-table__col">Payoneer email</th>
                            <th className="data-table__col">Сумма</th>
                            <th className="data-table__col">ID пользователя</th>
                            <th className="data-table__col">Дата запроса</th>
                            <th className="data-table__col">Дата выплаты</th>
                            <th className="data-table__col">ID платежа</th>
                        </tr>
                        </thead>
                        <tbody>
                        {finished.map((item) => (
                            <tr key={item.id} className="data-table__row">
                                <td className="data-table__col">{item.email}</td>
                                <td className="data-table__col">${item.amount}</td>
                                <td className="data-table__col">{item.uid}</td>
                                <td className="data-table__col">{getDate(item.requestDate)}</td>
                                <td className="data-table__col">{getDate(item.doneDate)}</td>
                                <td className="data-table__col">{item.paymentId}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}
