import React, {useContext, useEffect, useCallback,useMemo} from 'react';
import {StripeWorkspaceEnrollComponent} from '../StripeWorkspaceEnroll.component';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {useGetConnectedAccount, useGetStripeProduct} from '../hooks';
import {useGetUserSubscriptions} from '../../user';

export const StripeWorkspaceEnrollContainer = (props) => {
    const {user, workspace} = useContext(FirebaseContext);
    const {id: userId} = user;
    const {id: workspaceId, stripeProductId, ownerId} = workspace || {};

    const {
        getStripeProduct,
        data: stripeProductData,
        loading: stripeProductLoading
    } = useGetStripeProduct();
    const {
        data: stripeAccountData,
        loading: stripeAccountLoading
    } = useGetConnectedAccount(ownerId);
    const {
        getUserSubscriptions,
        data: userSubscriptionsData,
        loading: userSubscriptionsLoading
    } = useGetUserSubscriptions();

    const stripeAccountId = useMemo(() => {
        const {stripeAccountId, stripeAccountIsActivated} = stripeAccountData?.getConnectedAccount || {};
        if (!stripeAccountIsActivated) {
            return null;
        }
        return stripeAccountId;
    }, [stripeAccountData]);

    const stripeProduct = useMemo(() => (
        !!stripeProductData?.getStripeProduct ? [stripeProductData?.getStripeProduct] : null
    ), [stripeProductData]);

    const subscription = useMemo(() => (
        userSubscriptionsData?.getUserSubscriptions?.[0]
    ), [userSubscriptionsData]);

    const onGetStripeProduct = useCallback(async () => {
        await getStripeProduct({
            variables: {
                input: {
                    product: stripeProductId
                }
            }
        });
    }, [stripeProductId, getStripeProduct]);

    const onGetUserSubscriptions = useCallback(async (userId) => {
        await getUserSubscriptions({
           variables: {
               input: {
                   studentId: userId,
                   ...(workspaceId && {workspaceId})
               }
           }
        });
    }, [getUserSubscriptions, workspaceId])

    useEffect(() => {
        if (!!stripeProductId) {
            (async () => await onGetStripeProduct())();
        }
    }, [stripeProductId, onGetStripeProduct]);

    useEffect(() => {
        if (userId) {
            (async () => await onGetUserSubscriptions(userId))();
        }
    }, [userId, onGetUserSubscriptions]);

    return <StripeWorkspaceEnrollComponent {...props}
                                           loading={stripeProductLoading || stripeAccountLoading || userSubscriptionsLoading}
                                           stripeAccountId={stripeAccountId}
                                           workspaceStripeProduct={stripeProduct}
                                           subscription={subscription}/>;
}