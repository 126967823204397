import './scss/index.scss';
import React from 'react';
import { Form, Input, Button } from 'antd';
import { useIntl } from 'react-intl';
import { Svg } from '../../common';
const { TextArea } = Input;

export const AIAskComponent = ({
    form,
    handleChange,
    handleFocus,
    handleSendForm,
    isVisible,
    value,
    inProgress,
    placeholder,
    lines = 2,
    ...other
}) => {
    const { formatMessage } = useIntl();

    return (
        <div className='ai-bot_wrapper_form'>
            <Form
                onFinish={handleSendForm}
                form={form}
                layout='vertical'
            >
                <Form.Item
                    name='ask'
                    onChange={handleChange}
                    onKeyDown={handleFocus}
                >
                    <div className='ai-bot-component__wrapper'>
                        <TextArea
                            placeholder={placeholder || formatMessage({ id: 'ask_ai' })}
                            className='ai-bot-component__field'
                            value={value}
                            autoSize={{
                                minRows: lines,
                                maxRows: 16,
                            }}
                            disabled={ inProgress}

                        />
                        {
                            isVisible && (
                                <Button
                                    className='ai-bot-component__buttons'
                                    htmlType='submit'
                                    disabled={ inProgress}
                                    type='text'
                                    size='small'
                                >
                                    <Svg
                                        name=':icon/send'
                                        className='icon_24'
                                    />
                                </Button>
                            )
                        }
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};
