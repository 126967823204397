import {useCallback} from 'react';
import {useAiChatResponse} from '../graphQL/useAiChatResponse';

export const useGetChunksRaw = () => {
    const {getChatResponse, error} = useAiChatResponse();

    const getChunksRaw = useCallback(
        async (steptitle, source, curriculum, goal) => {
            const settings = {
                args: {
                    stepTitle: steptitle,
                    source: source,
                    curriculum: curriculum,
                    goal: goal,
                },
                type: 'chunksRaw',
            };          
            const resp = await getChatResponse({
                variables: {
                    input: settings,
                },
            });
            const responseJSON = resp?.data
                ? JSON.parse(resp?.data?.requestChatApi)
                : null;
            if (responseJSON?.choices[0]) {
                let responseTrimmed = responseJSON.choices[0].message.content;
                return responseTrimmed;
            }
        },
        [getChatResponse]
    );

    return {getChunksRaw, error};
};
