import React, {useEffect, useState} from "react";
import { Route, Redirect } from "react-router-dom";
import {useLocalePrefix} from '../lang';

export const ProtectedAuthRoute = ({ user, component: Component, ...rest }) => {
    const [userstate, setUser] = useState(user);
    const [localePrefix] = useLocalePrefix();

    useEffect(() => {
        setUser(user)
    }, [user])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userstate) return <Redirect to={`${localePrefix}/`} />;
        return <Component {...props} user={userstate} />;
      }}
    />
  );
};

export const ProtectedRoleRoute = ({ user, component: Component, ...rest }) => {

    const [userstate, setUser] = useState(user);

    useEffect(() => {
        setUser(user)
    }, [user])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userstate?.role?.id === 1) return <Component {...props} />;
        // return <Redirect to={`${localePrefix}/forbidden`}/>;
      }}
    />
  );
};
