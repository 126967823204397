/* eslint-disable react-hooks/exhaustive-deps */
import './scss/index.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty, omitBy, noop } from 'lodash';
import { Button, Form, Input, InputNumber, Switch, Alert, Popconfirm } from 'antd';
import classnames from 'classnames';
import { SourceSelector } from '../../ai/AICourseObjectives.component/SourceSelector';

import {
    Svg,
    TagSelectContainer,
    useGetTags,
} from '../../common';

import { InfoMessagesComponent } from '../../notification/InfoMessages.component';
import { Loader } from '../../Loader';
import { SortQuestionsComponent } from '../SortQuestions.component';
import { useEditTask } from '../hooks/useEditTask';
import { useDeleteTask } from '../hooks/useDeleteTask';
import { EditTaskDescription } from './EditTaskDescription';
import { EditTaskQuestion } from './EditTaskQuestion';
import { useReducedCaptions } from '../../ai/hook';
import { UploadAttachmentComponent } from '../UploadAttachment.component';
import { ITEMS_SOURCE_TASK } from '../../constant';
import { calculateHours } from '../../common/utils';
const { TextArea } = Input;

export const EditTaskLight = (props) => {
    const { formatMessage } = useIntl();
    const {
        user,
        activeQuestionId,
        activeTask,
        handleGetEvent,
        setActiveTask,
        event,
        setEvent,
        activeid,
        handleTaskView,
        onSaveProgress = noop,
        isChanged,
        setIsChanged,
        onUpdateEvent,
        isOwner,
        ...other
    } = props;
    const getTags = useGetTags();
    const { id: uId, role } = user || {};
    const { editTask } = useEditTask();
    const { deleteTask } = useDeleteTask();
    const isEditMode = useMemo(() => !!activeTask?.id, [activeTask?.id]);
    const [form] = Form.useForm();

    const [formTask, setFormTask] = useState(activeTask);
    const [question, setQuestion] = useState();
    const [questionsErrors, setQuestionsErrors] = useState();
    const [sortMode, setSortMode] = useState();
    const [isForceUpdate, setIsForceUpdate] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [sourceList, setSourceList] = useState([]);
    const [galleries, setGalleries] = useState();
    const [isCollapsed, setIsCollapsed] = useState(true); // Add state for collapse

    const formInitialValue = useMemo(() => formTask ? formTask : { isFree: false }, [formTask]);
    const getReducedCaptions = useReducedCaptions();
    const MAX_SUMMARY = 60000;

    //change functions
    const handleSortQuestions = (data) => {
        form.setFieldsValue({ questions: data });
        setFormTask((prev) => ({ ...prev, questions: data }));
        setSortMode(false);
    };
    const onRemoveQuestion = async () => {
        const questions = form.getFieldValue('questions').map((q, i) => {
            q.order = i;
            return q;
        });

        handleSortQuestions(questions);
        await handleFormSubmitOnBlur();
        await onUpdateEvent();
    };
    const isValidQuestions = (questions) => {
        if (!questions?.length) {
            setQuestionsErrors(null);
            return true;
        }

        let isValid = true;
        const errors = {};

        questions?.forEach((q, i) => {
            if (q.type === 'DEFAULT') {
                return;
            }
            if (q.type !== 'TEXT' && !q.options) {
                errors[i] = formatMessage({ id: 'form_question_is_required' });
                isValid = false;
                return;
            }

            if (q.type === 'SINGLE_SELECT') {
                if (!q.options.find((q) => q?.isCorrect)) {
                    errors[i] = formatMessage({ id: 'form_one_is_right', });
                    isValid = false;
                }
            }

            if (q.type === 'MULTI_SELECT') {
                if (!q.options.find((q) => !!q?.isCorrect)) {
                    errors[i] = formatMessage({ id: 'form_min_one_is_right' });
                    isValid = false;
                }
            }
        });

        if (errors) {
            setQuestionsErrors(errors);
        }
        return isValid;
    }

    const saveSource = (sourceList) => {
        const {
            questions,
            tagIds,
            isDraft,
            isFree,
            isAutogenerated,
            source,
            summary,
            coverSummary,
            ...taskData
        } = form.getFieldsValue();

        onSaveProgress(true);
        const promises = [];
        const summarisedSource = []
        const rawSource = []
        let ytpublic = null;
        sourceList?.forEach((el) => {
            if (el.key === 'youtube' || el.key === 'vimeo' || el.key === 'video') {
                const videoPromise = new Promise(async (resolve, reject) => {
                    try {
                        const shortenedSummary = await getReducedCaptions(el.raw, '', MAX_SUMMARY)
                        resolve({ ...el, summary: shortenedSummary });
                    } catch (error) {
                        reject(error)
                    }
                });
                promises.push(videoPromise);
            } else if (el.type === 'TEXT') {
                const textPromise = new Promise(async (resolve, reject) => {
                    try {
                        const shortenedSummary = await getReducedCaptions(el.raw, '', MAX_SUMMARY)
                        resolve({ ...el, summary: shortenedSummary });
                    } catch (error) {
                        reject(error)
                    }
                });
                promises.push(textPromise);

            } else if (el.key === 'yt_public') {
                ytpublic = true
            }
        });
        const finalPromise = Promise.allSettled(promises);

        finalPromise.then(async (results) => {
            results?.forEach(res => {
                summarisedSource.push(res?.value?.summary);
                rawSource.push(res.value.raw);
            });

            if (results?.length === promises?.length) {
                if (activeTask?.id && form.getFieldsValue()) {
                    if (!isValidQuestions(questions)) {
                        onSaveProgress(false);
                        return;
                    }
                    let coverData = activeTask.cover;
                    const convertedTagIds = await getTags(tagIds || formTask.tags.map((tag) => tag?.id.toString()));
                    const convertedQuestions = {
                        questions: !questions?.length
                            ? []
                            : questions.map((q) => ({
                                ...q,
                                // video: undefined,
                                // ...getCoverByLink(q.video),
                                options:
                                    q.options &&
                                    q.options.map((o) => ({
                                        ...o,
                                        isCorrect: !!o.isCorrect,
                                    })),
                            })),
                    };

                    const rawSourceString = ((source || '') + rawSource?.join('\n'))
                    const summarisedSourceString = (summary || '') + summarisedSource?.join('\n')

                    let taskFromForm = {
                        ...omitBy(taskData, isEmpty),
                        ...(coverData && { cover: coverData }),
                        coverImage: undefined,
                        video: undefined,
                        timestamps: undefined,
                        isFree: !!isFree,
                        isDraft,
                        ...taskData.duration && { duration: taskData.duration },
                        // TODO: add youtube
                        isAutogenerated: ytpublic ? true : isAutogenerated,
                        source: rawSourceString < 30000 ? rawSourceString : (source || '') + summarisedSource?.join('\n'),
                        summary: summarisedSourceString?.substring(0, 30000),
                        description: !!taskData.description
                            ? taskData.description
                            : null,
                        ...convertedQuestions,
                        tagIds: convertedTagIds,
                    }
        
                    let editedTask = await editTask({
                        variables: {
                            taskId: activeTask?.id,
                            taskData: taskFromForm,
                            isOwner: isOwner
                        },
                    });
    
                    updateStates();
                    if (editedTask) { updateEventState(editedTask) }
    
                } else {
                    updateStates();
                }
            }
        })
    }
    //edit functions
    const handleFormSubmitOnBlur = async () => {
        const {
            questions,
            tagIds,
            isDraft,
            isFree,
            isAutogenerated,
            source,
            summary,
            coverSummary,
            ...taskData
        } = form.getFieldsValue();

        onSaveProgress(true);
        // let isEqualed = checkObjectIsEqual(form.getFieldsValue(), formTask);
        if (activeTask && form.getFieldsValue()) {
            // if (!isEqualed) {
            if (!isValidQuestions(questions)) {
                onSaveProgress(false);
                return;
            }
            let coverData = activeTask.cover;
            const convertedTagIds = await getTags(tagIds || formTask.tags.map((tag) => tag?.id.toString()));
            const convertedQuestions = {
                questions: !questions?.length
                    ? []
                    : questions.map((q) => ({
                        ...q,
                        // video: undefined,
                        // ...getCoverByLink(q.video),
                        options:
                            q.options &&
                            q.options.map((o) => ({
                                ...o,
                                isCorrect: !!o.isCorrect,
                            })),
                    })),
            };

            if (activeTask?.id) {
                let taskFromForm = {
                    ...omitBy(taskData, isEmpty),
                    ...(coverData && { cover: coverData }),
                    coverImage: undefined,
                    video: undefined,
                    timestamps: undefined,
                    isFree: !!isFree,
                    isDraft,
                    isAutogenerated: isAutogenerated,
                    source: source,
                    summary: summary,
                    ...taskData.duration && { duration: taskData.duration },
                    description: !!taskData.description
                        ? taskData.description
                        : null,
                    ...convertedQuestions,
                    tagIds: convertedTagIds,
                    ...(galleries && { galleries })
                }

                let editedTask = await editTask({
                    variables: {
                        taskId: activeTask?.id,
                        taskData: taskFromForm,
                        isOwner: isOwner
                    },
                });

                if (editedTask) {
                    updateEventState(editedTask)
                }
            }
        }
        updateStates();
    }

    const updateCover = (editedTask) => {
        updateEventState(editedTask)
        updateStates()
    }

    const updateEventState = (editedTask) => {
        const eventCopy = { ...event };
        const eventTasks = [...event.tasks];
        const taskIndex = eventTasks.findIndex((t) => t.id === activeTask?.id);
        eventTasks[taskIndex] = editedTask.data.editTask;
        eventCopy.tasks = eventTasks;
        setEvent(eventCopy);
        setActiveTask(editedTask.data.editTask);
    }

    const updateStates = () => {
        onSaveProgress(false);
        setIsChanged(false);
        setSourceList([])
        onUpdateEvent();
    }

    const handleChange = () => {
        setIsChanged(true);
    }
    const handleEditVideoTask = async (coverData, description) => {
        let tagIdsArray = [];
        activeTask?.tags?.forEach((tag) => tagIdsArray.push(tag.id));

        let editedTask = await editTask({
            variables: {
                taskId: activeTask?.id,
                taskData: {
                    tagIds: tagIdsArray,
                    description: description,
                    ...coverData,
                },
                isOwner: isOwner,
            },
        });
        updateCover(editedTask)
    };
    const onChange = async () => {
        let questionsClone = [];
        let valuesNew = (values) => {
            values?.questions?.forEach((q) => {
                if (q.type === 'SINGLE_SELECT') {
                    if (!q.options?.length) {
                        q.options.push({
                            isCorrect: true,
                            order: 0,
                            text: "Next",
                        })
                    }
                }
                if (q.type === 'MULTI_SELECT') {
                    if (!q.options?.length) {
                        q.options.push({
                            isCorrect: true,
                            order: 0,
                            text: "New option",
                        })
                    }
                }
                questionsClone.push(q)
            });
            form.setFieldsValue({ questions: questionsClone });
            handleChange();
            return values;
        };

        // await handleFormSubmitOnBlur();
        setFormTask({ ...activeTask, ...valuesNew(form.getFieldsValue()), questions: questionsClone });
        handleChange();
    };
    const handleDelete = async () => {
        try {
            await deleteTask({ variables: { taskId: activeTask?.id } });
            await onUpdateEvent();
            const eventCopy = { ...event };
            const eventTasks = [...event.tasks];
            const taskIndex = eventTasks.findIndex((t) => t.id === activeTask?.id);
            eventTasks.splice(taskIndex, 1);
            eventCopy.tasks = eventTasks;
            // setEvent(eventCopy);
            setActiveTask(eventTasks[taskIndex + 1]);
        } catch (e) {
            console.warn(e);
        }
    };

    const handleLoadFile = (fileData) => {
        setGalleries((prev) => {
            const gal = !!prev ? [...prev] : [];
            const { url } = fileData || {};

            if (url) {
                const title = url.split('/').pop().slice(4).split('.').shift();
                gal.push({
                    title,
                    source: url,
                    type: 'FILE'
                });
            }
            return gal;
        });
    };

    const handleDeleteFile = (i) => {
        setGalleries((prev) => {
            const clone = [...prev];
            delete clone[i];
            handleChange();
            return clone.filter((c) => !!c.source);
        });
    };

    useEffect(() => {
        setQuestion(activeQuestionId ? activeTask?.questions?.find(q => q.id === activeQuestionId) : null)
        setFormTask(activeTask);
    }, [activeQuestionId, activeTask, activeTask?.id])

    useEffect(() => {
        if (activeTask) {
            // const {id: roleId } = role;
            const { id: roleId = 2 } = role || {};
            const { creatorId } = activeTask;
            if (creatorId === uId || roleId === 1) {
                (async () => {
                    const tagIds = activeTask?.tags?.map((tag) => tag.id.toString());

                    // if (cover?.summary) {
                    //     video.coverSummary = cover?.summary;
                    // }

                    setFormTask({ ...activeTask, tagIds, });
                })();
            }
        }
        setGalleries(activeTask?.galleries);
    }, [activeTask]);
    useEffect(() => {
        if (formInitialValue) {
            form.setFieldsValue(formInitialValue);
        }
    }, [formInitialValue, form]);

    useEffect(() => {
        if (formInitialValue?.id) {
            (async () => {
                await setIsForceUpdate(true);
                setTimeout(() => {
                    setIsForceUpdate(false);
                }, 10);
            })();
        }
    }, [formInitialValue?.id, formInitialValue]);

    const getTabContentClassNames = (id) =>
        classnames('edit-task-component__tab-content ', {
            'edit-task-component__tab-content_active': id === activeid,
        });
    const getClassNamesTabContent = (index) => classnames('edit-task-form-tab__content flex-grow_1', {
        'edit-task-form-tab__content_active': activeTab === index,
        'visually-hidden': activeTab !== index
    });

    const getButtonClassNames = (index) => classnames('edit-task-form-toggle', {
        'edit-task-form-toggle_active': activeTab === index && !isCollapsed
    });

    if (isForceUpdate || (isEditMode && activeTask && !formTask)) return <Loader />;

    return (
        <div className='edit-task-component'>
            {questionsErrors && Object.keys(questionsErrors).map((key) => {
                return (
                    <Alert
                        message={questionsErrors[key]}
                        key={key}
                        type="error"
                        showIcon
                        action={
                            <Button onClick={() =>
                                handleTaskView(activeTask, formTask?.questions?.[Number(key)]?.id)
                            } size="small" type="text">
                                Fix
                            </Button>
                        }
                    />
                )
            }
            )}

            <Form
                initialValues={formInitialValue}
                form={form}
                layout='vertical'
                name='basic'
                // onBlur={handleFormSubmitOnBlur}
                onInput={handleChange}
                requiredMark='hidden'
                scrollToFirstError={true}
                className="min-h-full flex_direction-column"
            >
                <div className="edit-task-form__tabs">
                    <div>

                        <Button
                            type='text'
                            size='small'
                            className={getButtonClassNames(0)}
                            onClick={() => {
                                if (activeTab === 0) {
                                    setIsCollapsed(!isCollapsed);
                                } else {
                                    setActiveTab(0);
                                    setIsCollapsed(false);
                                }
                            }}
                        >
                            {formatMessage({ id: "form_content_tab" })}
                        </Button>
                        <Button
                            className={getButtonClassNames(1)}
                            type='text'
                            size='small'
                            onClick={() => {
                                if (activeTab === 1) {
                                    setIsCollapsed(!isCollapsed);
                                } else {
                                    setActiveTab(1);
                                    setIsCollapsed(false);
                                }
                            }}
                        >
                            {formatMessage({ id: "form_display_tab" })}
                        </Button>
                    </div>
                    <Button
                        type='text'
                        className='faq_link-icon'
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        {isCollapsed ? '+' : '-'}
                    </Button>

                </div>
                <div className="edit-task-form-tab__content flex-grow_1">
                    <div className="edit-task-component__wrapper min-h-full">
                        {!isCollapsed && (
                            <>
                                <div className={getClassNamesTabContent(0)}>
                                    <h3>{formatMessage({ id: 'form_lesson_tab' })}</h3>
                                    <p className='text_secondary wrapper_inner-section'>The module content is generated from this source. Update the source to regenerate the course material.</p>

                                    <Form.Item name='source'>
                                        <TextArea
                                            autoComplete='off'
                                            maxLength={30000}
                                            showCount
                                            placeholder={formatMessage({ id: 'form_text_data_placeholder' })}
                                        />
                                    </Form.Item>
                                    {formTask?.source?.length >= 30100 &&
                                        <p className='error'>The 30,000 character limit has been reached: shorten the existing source to add another file.</p>
                                    }
                                    <SourceSelector
                                        setSourceList={setSourceList}
                                        saveSource={saveSource}
                                        sourceList={sourceList}
                                        itemsProp={ITEMS_SOURCE_TASK}
                                        user={user}
                                        isDisable={(!formTask?.source || formTask?.source?.length < 30000) ? false : true}
                                        {...other}
                                    />

                                    {formTask?.summary &&
                                        <Form.Item name='summary' label={formatMessage({ id: 'form_text_learning', })}>
                                            <TextArea
                                                autoComplete='off'
                                                placeholder={formatMessage({ id: 'form_text_learning_placeholder' })}
                                                autoSize={{
                                                    minRows: 2,
                                                    maxRows: 3,
                                                }}
                                            />
                                        </Form.Item>
                                    }
                                </div>
                                <div className={getClassNamesTabContent(1)}>
                                    <h3>{formatMessage({ id: 'form_task_edit_add_tag_placeholder' })}</h3>
                                    <TagSelectContainer
                                        initialTagsProp={activeTask?.tags}
                                        title={activeTask?.title}
                                        onChange={handleChange}
                                        form={form}
                                    />

                                    <Form.Item
                                        name='isAutogenerated'
                                        label={formatMessage({
                                            id: 'form_is_autogenerate',
                                        })}
                                        className="edit-task-component__is-free form-item_row-direction flex-container_between"
                                        valuePropName='checked'
                                    >
                                        {/* <InfoMessagesComponent type='isAuto' /> */}
                                        <Switch defaultChecked={formInitialValue?.isAutogenerated}
                                            onChange={(flag) => {
                                                form.setFieldsValue({ isAutogenerated: flag });
                                                handleChange();
                                            }} />
                                    </Form.Item>
                                    <Form.Item
                                        name='isFree'
                                        label={formatMessage({
                                            id: 'form_is_free_label',
                                        })}
                                        className="edit-task-component__is-free form-item_row-direction flex-container_between"
                                        valuePropName='checked'
                                    >
                                        <InfoMessagesComponent type='isFree' />
                                        <Switch defaultChecked={formInitialValue?.isFree}
                                            onChange={(flag) => {
                                                form.setFieldsValue({ isFree: flag });
                                                handleChange();
                                            }} />
                                    </Form.Item>
                                    <Form.Item
                                        name='isDraft'
                                        label={formatMessage({
                                            id: 'form_is_draft',
                                        })}
                                        className="edit-task-component__is-free form-item_row-direction flex-container_between"
                                        valuePropName='checked'
                                    >
                                        <InfoMessagesComponent type='isDraft' />
                                        <Switch defaultChecked={formInitialValue?.isDraft}
                                            onChange={(flag) => {
                                                form.setFieldsValue({ isDraft: flag });
                                                handleChange();
                                            }} />
                                    </Form.Item>
                                    <Form.Item
                                        name='duration'
                                        label={formatMessage({ id: 'custom_duration_task' })}
                                        className='form-item_row-direction flex-container_between'
                                        extra={formTask.duration && calculateHours(formTask.duration, formatMessage)}
                                        rules={[
                                            {
                                                required: false,
                                                type: 'number',
                                                message: formatMessage({ id: 'custom_duration_task' }),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            placeholder={30}
                                        />
                                    </Form.Item>
                                    <hr />
                                    <div className='wrapper_content-section'>
                                        <Button
                                            className=' block-el'
                                            // size='small'
                                            type='link'
                                            onClick={() => {
                                                const taskIndex = event.tasks.findIndex(t => t.id === formTask?.id);
                                                if (taskIndex !== -1) {
                                                    setEvent(p => ({
                                                        ...p,
                                                        tasks: [
                                                            ...p.tasks.slice(0, taskIndex),
                                                            ...p.tasks.slice(taskIndex + 1)
                                                        ]
                                                    }));
                                                }
                                                setActiveTask(event.tasks[taskIndex - 1]);
                                            }}>
                                            {formatMessage({ id: 'form_unbundle_task' })}
                                        </Button>
                                        <hr />
                                        {formTask && (
                                            <Popconfirm
                                                title={formatMessage({ id: 'confirm_delete' })}
                                                onConfirm={() => handleDelete(formTask?.id)}
                                                okText='OK'
                                                cancelText={formatMessage({ id: 'payment_request_modal_cancel' })}
                                                icon={null}
                                                okButtonProps={{ danger: true, className: 'button_confirm' }}

                                            >
                                                <Button
                                                    className='edit-task-component__remove block-el'
                                                    type='link'
                                                >
                                                    <Svg name=':icon/delete' className='icon_16' />
                                                    {formatMessage({ id: 'form_remove_task' })}
                                                </Button>
                                            </Popconfirm>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className={getTabContentClassNames(!question?.id ? activeTask?.id : null)}>
                            <EditTaskDescription
                                user={user}
                                formTask={formTask}
                                onChange={onChange}
                                onChangeContent={handleChange}
                                form={form}
                                formInitialValue={formInitialValue}
                                updateEvent={updateCover}
                                handleEditVideoTask={handleEditVideoTask}
                                onAfterSetIsEditMode={updateStates}
                                event={event}
                                {...other}
                            />
                            <UploadAttachmentComponent galleries={galleries}
                                onLoadFile={handleLoadFile}
                                onDeleteFile={handleDeleteFile} />
                        </div>

                        <div className={getTabContentClassNames(question?.id)}>
                            <EditTaskQuestion
                                activeQuestion={question}
                                onRemoveQuestion={onRemoveQuestion}
                                questionsErrors={questionsErrors}
                                setSortMode={setSortMode}
                                user={user}
                                activeTask={activeTask}
                                formTask={formTask}
                                form={form}
                                onChange={onChange}
                                onChangeContent={handleChange}
                                remove={onRemoveQuestion}
                                handleFormSubmitOnBlur={handleFormSubmitOnBlur}
                                event={event}
                                setFormTask={setFormTask}
                                isCollapsed={isCollapsed || activeTab === 1}

                            />
                        </div>
                    </div>
                </div>
            </Form >
            {
                isChanged && (
                    <div className="edit-task-component__stiky">
                        <Button type="primary"
                            onClick={handleFormSubmitOnBlur}>
                            {formatMessage({ id: 'save_changes_button' })}
                        </Button>
                    </div>
                )
            }
        </div >
    );
};
