import {useMutation} from '@apollo/client';
import {DeleteWorkspaceEventsList} from '../graphQL/mutations/DeleteWorkspaceEventsList';

export const useDeleteEventsList = (mutation = DeleteWorkspaceEventsList) => {
    const [deleteEventsList, {data, error, loading}] = useMutation(mutation);

    return {
        deleteEventsList,
        data,
        error,
        loading
    }
}