import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { Svg, useProgress } from '../../common';
import { useGetCourseDescription, useGetImagesDalle } from '../../ai';
import { useEditEvent } from '../../event/hooks/useEditEvent';
import { jsonrepair } from 'jsonrepair';

export const AiPublishSalesGeneratorComponent = ({ event, setEvent }) => {
    const { formatMessage } = useIntl();
    const {inProgress, handleChangeProgress} = useProgress();
    const { editEvent } = useEditEvent();
    const [error, setError] = useState()
    const { getCourseDecription } = useGetCourseDescription();
    const { getAiImage } = useGetImagesDalle();

    const saveNewDataToEvent = async (newData) => {
        const { equipment, description, short_description, objectives, cover } = newData;
        const arrayToString = (data) => Array.isArray(data) ? data.join('\n') : data;

        const newDEvent = {
            description: event?.description || description,
            result: event?.result || short_description,
            program: event?.program || arrayToString(objectives),
            equipment: event?.equipment || arrayToString(equipment),
            ...(cover ? {
                galleriesInEvent: [{
                    gallery: {
                        type: 'PHOTO',
                        source: cover,
                    }
                }]
            } : {}),
            isDraft: false,

        };
        try {
            const editedEvent = (
                await editEvent({
                    variables: {
                        eventId: event.id,
                        eventData: newDEvent,
                        isOwner: true
                    },
                })
            ).data?.event;
            setEvent({ ...event, ...newDEvent })

        } catch (e) {
            console.warn(e);
        }
    }

    const generateCurriculumString = (event) => {
        let curriculumString = `Title: ${event?.title}\n`;
        curriculumString += `Prompt: ${event?.issue?.substring(0, 400)}\n`;
        curriculumString += `Curriculum:\n`;
        if (event.type === "COURSE") {
            event.tasks?.forEach((task) => {
                curriculumString += `- ${task.title}\n`;
                task?.questions?.forEach((question) => {
                    const questionText = question.question ? question.question : question.title;
                    const answerText = question.answer ? question.answer : '';
                    curriculumString += `  - ${questionText?.substring(0, 400)}\n${answerText?.substring(0, 200)}`;
                });
            });
        } else if (event.type === "BUNDLE") {
            event.relatedEvents.forEach((el) => {
                curriculumString += `- ${el.event.title}\n`;
            });
        }
        return curriculumString;
    };

    const handleGenerateCourseDescription = async () => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_course_generating' }),
            color: event?.tags?.[0]?.subject?.color,
            key: 'publish-sale-generation'
        });

        if (!event.description || !event.result || !event.program || !event.equipment) {
            const curriculumString = generateCurriculumString(event);

            let json = await getCourseDecription(curriculumString);
            if (!json) {
                setError(error);
                handleChangeProgress({
                    key: 'publish-sale-generation'
                });
            } else {
                let obj;
                try {
                    const repaired = jsonrepair(json);
                    obj = JSON.parse(repaired);
                } catch (e) {
                    console.warn(e);
                    setError(e.toString());
                }

                if (obj) {
                    const isVisual = event?.galleriesInEvent?.length > 0 && event.galleriesInEvent?.find(gallery => gallery?.gallery?.type === "PHOTO" || gallery?.gallery?.type === "VIDEO")?.gallery?.source
                    if (!isVisual) {
                        const ill = await getAiImage(`Create a minimalistic 3D visualisation with dark red, blue, green for this topic: ${event.title} `);
                        await saveNewDataToEvent({ ...obj, cover: ill });
                    } else {
                        await saveNewDataToEvent({ ...obj });
                    }
                    handleChangeProgress({
                        key: 'publish-sale-generation'
                    });
                }
            }
        } else {
            saveNewDataToEvent({})
            handleChangeProgress({
                key: 'publish-sale-generation'
            });
        }
    }

    return (
        <Button
            type="secondary"
            onClick={() => handleGenerateCourseDescription()}
            disabled={inProgress}
            className=' btn-left'
            block
        >
            <Svg name=':icon/coins' className='icon_24 margin_right' />
            3.{' '}
            {formatMessage({ id: 'publish_button' })}
        </Button>
    )
}