import {gql} from '@apollo/client';

export const GetWorkspaces = gql`
    query GetWorkspaces($ownerId: Int!) {
        getWorkspaces(ownerId: $ownerId) {
            id
            firebaseId
            ownerId
            title
            host
            customDomain
            logo
            cover
            description
            status
            eventsLists {
                id
                title
                workspaceId
                courses
                tags
                order
            }
            members {
                id
                permission
                workspaceId
                userId
            }
        }
    }
`;
