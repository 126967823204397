import './scss/index.scss';
import React from 'react';
export const TagStringComponent = ({ tag, countResults }) => {
    return (
        <>
            {tag?.title}️
            {countResults?.done > 0 ? (
                <>
                    <small
                        style={{
                            fontSize: '12px',
                            verticalAlign: 'middle',
                            opacity: 0.4,
                        }}
                    >
                        &nbsp;
                        {/* ★ */}
                        {countResults?.done}/{countResults.all}&nbsp;
                    </small>
                    {/* ∙ */}
                </>
            ) : null}
        </>
    );
};
