import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useHistory } from 'react-router';
import { find } from 'lodash';

import { FullListProfessionsComponent } from './FullListProfessions.component';
import { getProfessions, getModifiedProfessions, getPopularProfAndSkills } from './getCareersFromSkills';
import { useGetPreference } from '../../common/graphQL/preference/useGetPreference';
import { useIsAllow } from '../../common';
import { useLocalePrefix } from '../../lang';
import { useGetTags } from '../../careers/hook/useGetTags';

export const FullListProfessionsContainer = ({
    skills,
    user,
    careersBySpec,
    ...other
}) => {
    const { getLazyTags } = useGetTags();
    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd("START");

    const [professionsList, setProfessionsList] = useState();
    const [plainUserSkills, setPlainUserSkills] = useState();
    const [prof, setProf] = useState();
    const [skillsProf, setSkillsProf] = useState();
    const { preference, getPreference } = useGetPreference({
        fetchPolicy: 'no-cache',
    });

    const history = useHistory();
    const allProfessions = useRef();
    const limit = 5;
    const [localePrefix] = useLocalePrefix();

    const getProfessionAndSkills = () => {
        const { subjects } = skills;
        let plainSkills = [];

        subjects?.forEach((subjectObject) => {
            if (subjectObject.countResults > 0) {
                let ard = subjectObject.tags.filter((tag) => tag.countResults > 0);
                plainSkills.push(...ard);
            }
        });

        setPlainUserSkills(plainSkills);
        let professionsList = plainSkills && getProfessions(plainSkills, careersBySpec.careers);

        if (professionsList) {
            let orderedCareers = getPopularProfAndSkills(
                professionsList,
                limit
            );
            allProfessions.current = professionsList;
            setProfessionsList(orderedCareers);
            getSkillsFromJsonForJsonProfession(orderedCareers[0], plainSkills);
            setProf(orderedCareers[0]);
        }
    };

    const getSkillsFromJsonForJsonProfession = async (profession, plainSkills) => {
        const tags = await getLazyTags(profession?.tags);
        let finalSkillsArray = [];
        profession?.tags?.forEach((tagId) => {
            let skill = tags?.find((el) => el.id === tagId); // take current skills
            let fd = find(plainSkills, ['id', tagId]);

            let subjectIsInArray = finalSkillsArray.find(subject => subject?.id === skill?.subject?.id)

            if (!subjectIsInArray) {
                finalSkillsArray.push(
                    {
                        id: skill?.subject?.id,
                        alias: skill?.subject?.alias,
                        title: skill?.subject?.title,
                        color: skill?.subject?.color,
                        icon: skill?.subject?.icon,
                        tags: [
                            {
                                id: skill?.id,
                                alias: skill?.alias,
                                title: skill?.title,
                                icon: skill?.icon,
                                countResults: fd?.countResults || 0,
                            }
                        ],
                        countResults: 0
                    },
                )

            } else {
                let tagIsInSubject = subjectIsInArray.tags?.find(tag => tag?.id === skill?.id)
                if (!tagIsInSubject) {
                    subjectIsInArray.tags?.push(
                        {
                            id: skill.id,
                            alias: skill.alias,
                            title: skill.title,
                            icon: skill.icon,
                            countResults: fd?.countResults || 0,
                        }
                    )
                } else {
                    let tagsArray = [...subjectIsInArray.tags];
                    let index = tagsArray?.findIndex(tag => tag?.id === skill?.id);
                    tagsArray[index].countResults += fd?.countResults;
                    subjectIsInArray.tags = tagsArray;
                }
            }
        });
        setSkillsProf(finalSkillsArray);
    };

    const handleSetProfId = (profession) => {
        if (profession) {
            setProf(profession);
            getSkillsFromJsonForJsonProfession(profession, plainUserSkills);
        }
    };
    const handleShowAll = () => {
        if (isAllow) {
            if (professionsList?.length > limit) {
                setProfessionsList(allProfessions.current.slice(0, limit));
            } else {
                setProfessionsList(allProfessions.current);
            }
        } else {
            history.push(`${localePrefix}/payments`);
        }
    };

    useEffect(() => {
        if (skills && careersBySpec) {
            getProfessionAndSkills();
        }

    }, [skills, careersBySpec])

    useEffect(() => {
        if (user?.id) {
            (async () => await getPreference(user?.id))();
        }
    }, [user?.id]);

    return (
        <>
            <FullListProfessionsComponent
                professionsList={professionsList}
                prof={prof}
                skillsProf={skillsProf}
                handleSetProfId={handleSetProfId}
                handleShowAll={handleShowAll}
                limit={limit}
                user={user}
                {...other}
            />
        </>
    );
};
