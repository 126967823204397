import './scss/index.scss';
import React, { useRef, useState, useCallback } from 'react';
import { Input } from 'antd';
import { useIntl } from 'react-intl';
import { LoaderComponent, useGetCaptions } from '../../common';
import { VimeoPlayer } from '../VideoIframe/VimeoPlayer';
import { noop } from 'lodash';

const { TextArea } = Input;

export const VimeoInput = ({ onVideoDataChange = noop, inProgress, setInProgress = noop, task, ...other }) => {
    const { formatMessage } = useIntl();
    const inputRef = useRef();
    const { captions, getCaptions } = useGetCaptions();
    const [videoSrc, setVideoSrc] = useState('');
    const [showError, setShowError] = useState(false);
    const onChange = useCallback(async () => {
        setInProgress(true);
        setShowError(null)

        const val = inputRef.current.input.value;
        const id = val.split('/').find((item) => Number.isInteger(parseInt(item)));
        if (id) {
            try {
                const respCaptions = await getCaptions('VIMEO', null, { source: val });
                if (respCaptions?.error) { setShowError(respCaptions?.error.message); }
                setVideoSrc(val);
                onVideoDataChange({ urlName: val, sourceText: respCaptions });
            } catch (error) {
                setShowError(error?.message || null)
            }
        }

        setInProgress(false);
    }, [setInProgress, onVideoDataChange]);

    return (
        <div className='video_uploader'>
            <div className='video_uploader__input'>

                <Input
                    onChange={onChange}
                    ref={inputRef}
                    placeholder={formatMessage({ id: "paste_link" }) + " https://vimeo.com/123456789"}
                    className="inline-el wrapper_inner-section"
                />
                {inProgress && <LoaderComponent type="absolute" />}
            </div>

            {videoSrc && (
                <div className='video_uploader__video'>
                    {showError && <p className='error'>Check if captions are enabled, format is https://vimeo.com/123456789 and video accessible via this link</p>}
                    <VimeoPlayer
                        {...other}
                        videoSrc={videoSrc}
                    />
                    <TextArea
                        defaultValue={captions}
                        placeholder={formatMessage({ id: "form_text_data_placeholder" })}
                        className="ai-bot-component__field_counter"
                        maxLength={30000}
                        showCount
                        autoSize={{ minRows: 2, maxRows: 4 }}
                    />
                </div>
            )}
        </div>
    );
};