import React from 'react';
import { SuggestionCardComponent } from '../SuggestionCard.component';
import { Row, Col } from 'antd';

export const SuggestionsComponent = ({ suggestions, ...other }) => {

  return (
    <Row gutter={[16, 16]}>
    {suggestions?.map((suggestion, index) => (
      <Col xs={24} sm={12} md={12} key={index}>
        <SuggestionCardComponent suggestion={suggestion} {...other} />
      </Col>
    ))}
  </Row>
  );
};