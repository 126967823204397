import React, { useContext } from 'react';

import { EditUserComponent } from '../EditUser.component';
import {LoaderComponent} from '../../common';
import { useEditUser } from '../../common/graphQL';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';

export const EditUserContainer = (props) => {
    const {
        as: Component = EditUserComponent,
        ...other
    } = props;

    const { editUser } = useEditUser();

    const { user, updateUserData } = useContext(FirebaseContext);
    const {id} = user;

    const handleEdit = async (data) => {
        const resp = await editUser(data);
        await updateUserData(user.id);
    };

    if (!id) {
        return <LoaderComponent/>
    }

    return <Component user={user}
        onEdit={handleEdit} {...other}/>
}