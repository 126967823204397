import {filter, includes} from 'lodash';

const getFilteredSource = (source, data, prop) => {
    return filter(source, (item) => {
        return includes(item[prop], data);
    });
};

// const getFilteredSourceAsObject = (source, data, prop) => {
//     return filter(source, (item) => {
//         return item[prop] === data;
//     });
// };

export const getSubjectsSource = (source, level) => {
    const dataByLevel = level ? getFilteredSource(source, level, 'levelList') : source;
    // const dataByInterval = intervalId ? getFilteredSourceAsObject(dataByLevel, intervalId, 'period') : dataByLevel;
    return dataByLevel;
}
