import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {MetaComponent} from '../../common';
import {CommunityComponent} from '../Community.component';
import {useGetSubject, useGetTag} from '../hooks';
import {Loader} from '../../Loader';
import {COVER_PICTURES} from '../../constant';

export const CommunityContainer = (props) => {
    const {...other} = props;
    const {user} = useContext(FirebaseContext);
    const {pathname} = useLocation();

    const {id, by} = useParams();
    const {subject, loading: loadingGetSubject, getSubject} = useGetSubject();
    const {tag, loading: loadingGetTag, getTag} = useGetTag();
    const [selectedProfId, setSelectedProfId] = useState();

    const metaData = useMemo(() => {
        const source = subject || tag;

        if (!source) {
            return null;
        }
        const {title} = source;
        const metaTitle = `${title} ${
            props.subject ? 'subject' : 'skill'
        } on Unschooler: make projects and start learning for free.`;
        const description = `Learn Online Everything About ${title} ${
            props.subject ? 'subject' : 'skill'
        }. Free basic course for beginners, tons of practice,
         live mentor's sessions and community in one place.`;
        const url = `https://unschooler.me${pathname}`;

        return {
            meta: {
                meta_title: metaTitle,
                meta_description: description,
            },
            og: {
                title: metaTitle,
                image: COVER_PICTURES.COMMUNITIES,
                description,
                url,
            },
            twitter: {
                tw_title: metaTitle,
                tw_url: url,
                tw_description: description,
            },
        };
    }, [pathname, subject, tag]);

    useEffect(() => {
        (async () => {
            if (by === 'tag') {
                const idParams = id.split('-');
                const tagId = idParams.find((p) => !isNaN(Number(p)));
                await getTag({variables: {tagId: Number(tagId)}});
            } else {
                await getSubject({variables: {subjectId: Number(id)}});
            }
        })();
    }, [by, getSubject, getTag, id]);

    if (loadingGetSubject || loadingGetTag) {
        return <Loader />;
    }

    return (
        <>
            {metaData && (
                <MetaComponent
                    meta={metaData.meta}
                    og={metaData.og}
                    twitter={metaData.twitter}
                />
            )}
            <CommunityComponent
                {...other}
                user={user}
                subject={subject}
                tag={tag}                
            />
        </>
    );
};
