import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Button, Input, InputNumber, Modal, Progress} from 'antd';

export const PaymentRequestComponent = (props) => {
    const {
        progress = 0,
        balance,
        minBalance,
        disabled,
        onRequest,
        email
    } = props;

    const {formatMessage} = useIntl();
    const [request, setRequest] = useState();
    const emailRef = useRef();
    const amountRef = useRef(balance);

    const handleRequest = () => {
        onRequest(emailRef.current, amountRef.current);
        setRequest(false);
    };

    const handleChangeEmail = (e) => {
        emailRef.current = e.target.value;
    };

    const handleChangeAmount = (val) => {
        amountRef.current = val;
    };

    useEffect(() => {
        emailRef.current = email;
    }, [email]);

    return (
        <div className="payment-request-component">
            <div className="payment-request-component__text">
                {formatMessage({id: 'payment_request_min_sum'}, {minBalance})}
            </div>
            <div className="payment-request-component__progress">
                <Progress
                    strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                    }}
                    percent={progress}
                />
            </div>
            <Button type="primary"
                    disabled={disabled}
                    onClick={() => setRequest(true)}>
                <span>
                    {formatMessage({id: 'payment_request_button_label'})}
                </span>
            </Button>
            {!disabled && (
                <Modal title={formatMessage({id: 'payment_request_modal_title'})}
                       cancelText={formatMessage({id: 'payment_request_modal_cancel'})}
                       centered
                       okText={formatMessage({id: 'payment_request_modal_ok_text'})}
                       className="payment-request-component__modal"
                       visible={request}
                       onOk={handleRequest}
                       onCancel={() => setRequest(false)}>
                    <div className="payment-request-component__row">
                        <div className="payment-request-component__text">
                            <p>
                                {formatMessage({id: 'payment_request_modal_payoneer_note'})} <a href="https://www.payoneer.com/"
                                                                                    target="_blank">Payoneer</a>.
                            </p>
                            <p>
                                {formatMessage({id: 'payment_request_modal_note'})}
                            </p>
                        </div>{email}
                        <Input type="email"
                               onChange={handleChangeEmail}
                               defaultValue={email}/>
                    </div>
                    <div className="payment-request-component__row">
                        <div className="payment-request-component__text">
                            {formatMessage({id: 'payment_request_modal_min_sum'}, {minBalance})}
                        </div>
                        <InputNumber min={minBalance}
                                     max={balance}
                                     defaultValue={balance}
                                     precision={2}
                                     onChange={handleChangeAmount}/>
                    </div>

                </Modal>
            )}
        </div>
    );
}
