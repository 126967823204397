import React, { useState, useContext, useEffect } from 'react';
import { PuppeteerComponent } from '../Puppeteer.component';

import { FirebaseContext } from '../../../context/firebase/firebaseContext';

export const PuppeteerContainer = ({ taskId, url, chunksData, width = 1920, height = 1080, mode, isMobile, task}) => {
    const { user } = useContext(FirebaseContext);
    const { id: userId } = user || {};
    const {alias} = task || {};

    const [isLoading, setIsloading] = useState();
    const [videolinks, setVideolinks] = useState();
    const [error, setError] = useState();
    const [modeFile, setModeFile] = useState();

    const handleGenerate = async () => {
        setError(false);
        setIsloading(true);

        const chunks = chunksData.map(({ audio, ill, diagram }) => ({ audio, ...(ill && { ill }), ...(diagram && { diagram }) }));
        const data = {
            id: taskId,
            url,
            chunks,
            width: width,
            height: height,
            userId,
            alias
        };

        // const resp = await fetch('http://localhost:4500/puppeteer', {
        const resp = await fetch('https://puppeteer.unschooler.me/puppeteer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data)
        });

        if (resp.ok) {
            const file = await resp.json();
            const {error: respError} = file;

            if (respError) {
                setVideolinks(null);
                setError(true);
            } else {
                let fds = { url: file.url, name: modeFile }
                let files = videolinks?.length ? [...videolinks, fds] : [fds]

                setVideolinks(files)
            }

            setModeFile(null)
        } else {
            console.error('Network response was not ok.');
        }

        setIsloading(false);
    };

    useEffect(() => {
        setModeFile(`${width}x${height}_${mode}.mp4`)
    }, [width, height, mode, isMobile])

    return <PuppeteerComponent
        isLoading={isLoading}
        onGenerate={handleGenerate}
        videolinks={videolinks}
        error={error}
        width={width}
        height={height}
        mode={mode}
        mobile={isMobile}
        modeFile={modeFile}
    />
}
