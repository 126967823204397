import {useLazyQuery} from '@apollo/client';
import {VIMEO_SUBTITLES} from '../graphQL/queries/GetVimeoCaptions';

export const useVimeoCaptions = (query = VIMEO_SUBTITLES) => {
    const [getVimeoCaptions, { data, loading, error }] = useLazyQuery(query);

    return {
        data,
        getVimeoCaptions,
        loading,
        error
    }
}
