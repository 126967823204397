import {Button} from "antd";
import CopyToClipboard from "react-copy-to-clipboard";

export default function DevComponent(props) {
    const {
        token,
        onReplace
    } = props;

    return(
        <div className="AdminNavigation">
            <div style={{marginBottom: '30px'}}>
                <CopyToClipboard text={token}>
                    <Button loading={token === ""}>Скопировать Bearer Token</Button>
                </CopyToClipboard>
            </div>
            <Button onClick={onReplace}>Заменить даты записи у подписчиков в занятиях</Button>
        </div>
    );
}
