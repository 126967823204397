import {useContext, useCallback} from 'react';
import {useLazyQuery} from '@apollo/client';
import {GetEventFull} from '../graphQL/queries/GetEventFull';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export function useGetEvent(query = GetEventFull) {
    const [getEventData, { data, loading }] = useLazyQuery(query);
    const {workspace} = useContext(FirebaseContext);

    const getEvent = useCallback((v, isSuperUser) => {
        const {id} = workspace || {};
        const {variables} = v;
        const r = id ? {...v, variables: {...variables, workspaceId: id, ...(isSuperUser && {isSuperUser})}} : v;
        return getEventData(r);
    }, [workspace, getEventData]);

    return {
        event: data?.event,
        getEvent,
        loading
    }
}
