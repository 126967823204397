import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import { noop } from 'lodash';
import { Spin } from 'antd';

import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { Loader } from '../../Loader';
import { useLocalePrefix } from '../../lang';
import { getMetaData } from '../../common/getEventCommonData';

import {
    MetaComponent,
    useGetUrlParams,
    useHostNameUrl
} from '../../common';
import { openNotification } from '../../notification/Notification.component';
import { useEditEvent } from '../../event/hooks/useEditEvent';
import { useGetEvent } from '../../event/hooks/useGetEvent';
import { useGetEventFull } from '../../event/hooks/useGetEventFull';
import { useSubscribeEvent } from '../../event/hooks/useSubscribeEvent';
import { useUnsubscribeEvent } from '../../event/hooks/useUnsubscribeEvent';
import { EventComponent } from '../../event/Event.component';
import { BundleComponent } from '../../bundle/Bundle.component';
import { useIsPermit } from '../../roles/hook/useCheckPermission';
import {useCreateWorkspaceMember} from '../../workspace';
import { useTaskDripLogic } from '../../task/hooks/useTaskDripLogic';
import { useGetResultsByUserid } from "../../result";

const GET_AUTH_URL = gql`
    query GetAuthUrl($source: String) {
        getAuthUrl(source: $source)
    }
`;

const ADD_EVENT_CALENDAR = gql`
    mutation AddEventCalendar($eventId: Int!) {
        addEventCalendar(eventId: $eventId)
    }
`;

export const EventContainer = (props) => {
    const { onChange = noop, hostname, workspace, ...other } = props;
    const history = useHistory();
    const [localePrefix] = useLocalePrefix();
    const { editEvent } = useEditEvent();
    const { alias, id } = useGetUrlParams();
    const { user, loginUser } = useContext(FirebaseContext);
    const { formatMessage } = useIntl();
    const { getEvent, loading: loadingGetEvent } = useGetEvent();
    const { getEvent: getEventFull, loading: loadingGetEventFull } = useGetEventFull();
    const [getUrl] = useLazyQuery(GET_AUTH_URL);
    const {createWorkspaceMember} = useCreateWorkspaceMember();
    const { resultsByUserid, getResultsByUserid } = useGetResultsByUserid();
  
    // const [plannedDate, setPlannedDate] = useState(null);
    // const [addedGoogleCalendar, setAddedGoogleCalendar] = useState(false);
    // const [reviewMode, setReviewMode] = useState();
    // const [reviews, setReviews] = useState();

    const [event, setEvent] = useState(null);
    const [call, setCall] = useState(true);
    const [loading, setLoading] = useState(false);
    const [askAddEventCalendar, setAskAddEventCalendar] = useState();
    const { checkPermit } = useIsPermit()
    const editOthersCourses = checkPermit(workspace, 'editOthersCourses')

    const [addEventCalendar] = useMutation(ADD_EVENT_CALENDAR);
    const workspaceUrl = useHostNameUrl(workspace);
    const instructorSubscriptions = event?.userSubscriptions?.filter(subscription => subscription.role === 'INSTRUCTOR');

    const eventColor = useMemo(() => (
        event?.tags?.[0]?.subject?.color || event?.subjects?.[0]?.color || ''
    ), [event]);

    const metaData = useMemo(() => getMetaData(event, hostname, workspace, localePrefix, workspaceUrl, formatMessage), [event, formatMessage, hostname, localePrefix, workspace, workspaceUrl]);
    const { subscribeEvent } = useSubscribeEvent();
    const { unsubscribeEvent } = useUnsubscribeEvent();
    const { tagsRenew,  } = useTaskDripLogic(event, null, null, resultsByUserid, false, [null]);

    const handleRemoveParticipant = async (userId) => {
        try {
            await unsubscribeEvent({
                variables: {
                    eventId: event.id,
                    userId: userId,
                },
            });         
            await onGetEvent();
        } catch (e) {
            console.warn(e);
        }
        onChange();
    };

    const handleAddEventCalendar = async () => {
        if (!user.isAllowGoogleCalendar) {
            setAskAddEventCalendar();
            setLoading(true);
            const url = await getUrl({
                variables: {
                    source: history.location.pathname,
                },
            });
            await window.open(url.data.getAuthUrl, '_blank');
            setLoading(false);
        } else {
            setAskAddEventCalendar();
            setLoading(true);
            await addEventCalendar({
                variables: {
                    eventId: event.id,
                },
            });
            setLoading(false);
            openNotification('topLeft', {
                title: <strong>Занятие успешно добавлено в календарь</strong>,
                duration: 4,
            });
        }
    };

    const handleDraft = async () => {
        const newDEvent = {
            isDraft: true,
        };
        try {
            const editedEvent = (
                await editEvent({
                    variables: {
                        eventId: event.id,
                        eventData: newDEvent,
                        isOwner: true
                    },
                })
            ).data?.event;
            setEvent({ ...event, isDraft: true, });

        } catch (e) {
            console.warn(e);
        }
    };

    const onGetEvent = useCallback(async () => {
        const input = {};
        if (id) {
            input.id = id;
        } else {
            input.alias = alias;
        }

        try {
            if (editOthersCourses) {
                const event = await getEventFull({
                    variables: {
                        ...input,
                    },
                    fetchPolicy: 'network-only',
                });
                setEvent(event.data?.event);
            } else {
                const event = await getEvent({
                    variables: {
                        ...input,
                    },
                    fetchPolicy: 'network-only',
                });
                setEvent(event.data?.event);
            }

        } catch (e) {
            console.warn(e);
        }
    }, [id, alias, editOthersCourses, getEventFull, getEvent]);

    useEffect(() => {
        (async () => await onGetEvent())();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!user?.id) {
            (async () => await getResultsByUserid({
                variables: { filter: { userId: user?.id } },
                fetchPolicy: 'network-only'
            }))();
        }
    }, [user?.id]);

    if (loadingGetEventFull || loadingGetEvent || !event) return <Loader />;
    const handleConfirmPaid = () => {
        // const { participantId } = participant;
        // const newData = { ...participant, isPaid: true };
    };

    const handleCheckUserData = async () => user?.id || (await loginUser());

    const joinToWorkspace = async () => {
        const isUserInWorkspace = workspace?.members?.length && workspace.members.some(member => member.id === user?.id);
        if (!isUserInWorkspace) {
            await createWorkspaceMember({
                variables: {
                    input: {
                        userId: user?.id,
                        permission: 'User',
                        workspaceId: workspace?.id
                    }
                }
            });
        }
    };

    const subscribeInvited = async () => {
        try {
            const {id: workspaceId} = workspace || {};
            const subscribeEventResult = await subscribeEvent({
                variables: {
                    eventId: event.id,
                    type: 'FULL',
                    ...(workspaceId && {workspaceId})
                },
            });

            setEvent((prev) => ({
                ...prev,
                userSubscriptions: [
                    ...prev.userSubscriptions,
                    subscribeEventResult.data?.userSubscription,
                ],
                countUserSubscriptions: prev.countUserSubscriptions + 1,
                isSubscribe: true,
            }));
            await onGetEvent();
            if (workspace?.id) {
                await joinToWorkspace();
            }
        } catch (e) {
            if (e.message === 'You are already subscribed') {
                setEvent((prev) => ({
                    ...prev,
                    isSubscribe: true,
                }));
            }
        }
    };

    const handleParticipateCallBack = async (data) => {
        setLoading(true);
        const invitedSubscription = event?.userSubscriptions?.find((subs) => subs.invitedEmail === user.email);
        if (!!invitedSubscription) {
            await subscribeInvited(invitedSubscription);
            await onGetEvent();
        } else {
            try {
                const { workspaceId: globalWorkspaceId, end, studentId } = data || {};
                const isWorkspaceSubscription = !!globalWorkspaceId && studentId === user?.id;
                const {id: workspaceId} = workspace || {};
                await subscribeEvent({
                    variables: {
                        eventId: event.id,
                        type: 'FULL',
                        ...(isWorkspaceSubscription && { end, workspaceId: globalWorkspaceId }),
                        ...(!isWorkspaceSubscription && workspaceId && {workspaceId})
                    },
                });
                await onGetEvent();
                if (workspace?.id) {
                    await joinToWorkspace();
                }
            } catch (e) {
                if (e.message === 'You are already subscribed') {
                    setEvent((prev) => ({
                        ...prev,
                        isSubscribe: true,
                    }));
                }
            }
        }
        setLoading(false);
    };

    const handleCheckout = async (isSubscription = false) => {
        const userData = await handleCheckUserData();
        if (userData === 1) return;
        let invitedUser = false;

        event?.userSubscriptions?.forEach(subscription => {
            if (subscription.invitedEmail === user.email) {
                invitedUser = true;
                subscribeInvited(subscription);
            }
        });
        if (!invitedUser) {
            history.push(`${localePrefix}/checkout/`, {
                event: event,
                eventId: event.id,
                isSubscription: isSubscription,
            });
        }
    };

    return (
        <>
            {metaData && (
                <MetaComponent
                    meta={metaData.meta}
                    og={metaData.og}
                    twitter={metaData.twitter}
                    canonical={metaData.canonical}
                />
            )}

            <Spin spinning={loading}>
                {event?.type === 'BUNDLE' ? (
                    <BundleComponent
                        {...other}
                        bundle={event}
                        user={user}
                        onChange={onChange}
                        onConfirmPaid={handleConfirmPaid}
                        setLoading={setLoading}
                        hostname={hostname}
                        workspace={workspace}
                        setEvent={setEvent}
                        onParticipate={handleParticipateCallBack}
                        handleRemoveParticipant={handleRemoveParticipant}
                        onCheckout={handleCheckout}
                        subscribeEvent={subscribeEvent}
                        subscribeInvited={subscribeInvited}
                        handleDraft={handleDraft}
                        loading={loading}
                        editOthersCourses={editOthersCourses}
                        instructorSubscriptions={instructorSubscriptions}
                    />
                ) : (
                    <div className='page page_event'>
                        <EventComponent
                            {...other}
                            event={event}
                            tagsRenew={tagsRenew}
                            call={call}
                            setupCall={() => setCall((prev) => !prev)}
                            onParticipate={handleParticipateCallBack}
                            user={user}
                            onChange={onChange}
                            handleRemoveParticipant={handleRemoveParticipant}
                            onConfirmPaid={handleConfirmPaid}
                            onCheckout={handleCheckout}
                            // askPhone={askPhone}
                            // onCancelAskPhone={setAskPhone}
                            // askAddEventCalendar={askAddEventCalendar}
                            // setAskAddEventCalendar={setAskAddEventCalendar}
                            // reviewMode={reviewMode}
                            // reviews={getConvertedObjectToArray(reviews)}
                            // addedGoogleCalendar={addedGoogleCalendar}
                            // plannedDate={plannedDate}
                            addEventCalendar={handleAddEventCalendar}
                            setLoading={setLoading}
                            tasks={event?.tasks}
                            eventColor={eventColor}
                            subscribeEvent={subscribeEvent}
                            setEvent={setEvent}
                            hostname={hostname}
                            workspace={workspace}
                            subscribeInvited={subscribeInvited}
                            handleDraft={handleDraft}
                            editOthersCourses={editOthersCourses}
                            onGetEvent={onGetEvent}
                            instructorSubscriptions={instructorSubscriptions}
                        />
                    </div>
                )}
            </Spin>
        </>
    );
};