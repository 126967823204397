import {useLazyQuery} from '@apollo/client';
import {GetCertificate} from '../graphQL/queries/GetCertificate';

export const useGetCertificate = (query = GetCertificate) => {
    const [getCertificate, { data, loading, error }] = useLazyQuery(query);
    return {
        getCertificate,
        loading, error,
        data
    }
}