import {useCallback} from 'react';
import {useMutation} from '@apollo/client';
import {EditWorkspaceEventsList} from '../graphQL/mutations/EditWorkspaceEventsList';

export const useEditEventsList = (mutation = EditWorkspaceEventsList) => {
    const [editEventsListFn, {data, error, loading}] = useMutation(mutation);

    const editEventsList = useCallback(async (data, id) => (editEventsListFn({
        variables: {
            input: data,
            id
        }
    })), [editEventsListFn]);

    return {
        editEventsList,
        data,
        error,
        loading
    }
}