import {useContext, useEffect, useMemo} from 'react';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

const DEFAULT = 'usd';

export const useCurrencyCode = () => {
    const {currencyCode, setCurrencyCode, workspace} = useContext(FirebaseContext);

    const loading = useMemo(() => !currencyCode, [currencyCode]);

    useEffect(() => {
        if (!currencyCode) {
            setCurrencyCode(workspace?.currencyCode || DEFAULT);
        }
    }, [setCurrencyCode, workspace]);

    return useMemo(() => (
        {currencyCode: currencyCode || DEFAULT, changeCurrencyCode: setCurrencyCode, loading}
    ), [currencyCode, setCurrencyCode, loading]);
}