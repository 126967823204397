import "./scss/index.scss"
import React, { useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { noop } from "lodash";
import { ReactMarkdownComponent } from "../../task/ReactMarkdown.component/ReactMarkdown.component";
import { Tabs, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faClock, faSignal, faDollarSign, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';

import { getSavingCost } from '../../common/utils'
import { EnrollContainer } from '../../enroll';
import { FooterContainer } from '../../footer';
import { useLocalePrefix } from "../../lang";
import { useIsAllow, LoginContainer, useTrackClick, Svg, getReadingTime, PaidComponent } from "../../common";
import { AiPublishSalesGeneratorComponent } from '../../ai';
import { EventCardContainer } from '../../event';
import { MentorBlockContainer } from '../../event/MentorBlock.container';
import { ObjectivesComponent } from '../../event/Objectives.component/Objectives.component';
import { StudentsTable } from '../../rating/StudentsTable';

const { TabPane } = Tabs;
const iconMap = { faList, faClock, faSignal, faDollarSign, faMoneyBillWave };

export const BundleComponent = (props) => {
    const {
        bundle,
        user,
        teacher,
        levelsList,
        handleRemoveParticipant = noop,
        onParticipate = noop,
        onCheckout = noop,
        currencyStorageCode,
        isEmbed,
        hostname,
        workspace,
        handleDraft,
        loading,
        editOthersCourses,
        ...other
    } = props;

    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();
    const trackClick = useTrackClick();
    const isSubscriptionRef = useRef();
    const [showLogin, setShowLogin] = useState();

    const [returnBack, setReturnBack] = useState();
    const isWhiteLabeled = hostname || isEmbed

    const useIsAllowd = useIsAllow();
    const isPaidEditor = useIsAllowd('PREMIUM', workspace, bundle?.creator?.id);
    const isEditor = (user?.id === bundle?.creator?.id && isPaidEditor) || editOthersCourses || user?.role?.id === 1;
    const handleParticipate = (data) => {
        const { workspaceId, studentId } = data || {};
        const isWorkspaceSubscription = !!workspaceId && studentId === user?.id;

        trackClick(
            "click-checkout",
            bundle.id,
            `${window.location.origin}${localePrefix}/online-courses/${bundle.id}`
        );

        if (!user?.id) {
            handleLogin();
        } else {
            if (bundle?.price && !isWorkspaceSubscription) {
                onCheckout(data?.isMonthMode);
                return;
            }
            onParticipate(data);
        }
    };
    const handleLogin = (options) => {
        trackClick(
            "click-login",
            bundle.id,
            `${window.location.origin}${localePrefix}/online-courses/${bundle.id}`
        );

        const { afterLoginActions } = options || {};

        if (afterLoginActions) {
            setReturnBack(afterLoginActions);
        }
        setShowLogin(true);
    };
    const handleLoginUser = () => {
        if (returnBack) {
            for (const key in returnBack) {
                window.localStorage.setItem(key, returnBack[key]);
            }
            return;
        }
        if (bundle?.price) {
            onCheckout(isSubscriptionRef.current);
        }
    };

    const isVisual = bundle?.galleriesInEvent?.length > 0 && bundle.galleriesInEvent?.find(gallery => gallery?.gallery?.type === "PHOTO" || gallery?.gallery?.type === "VIDEO")?.gallery?.source
    const savings = getSavingCost(bundle);
    const readingTime = `${bundle.relatedEvents?.length * 4} ${formatMessage({ id: 'hours' })}`

    const courseInfoSections = [
        {
            title: formatMessage({ id: 'courses' }), //Courses
            detail: bundle.relatedEvents?.length,
            icon: "faList",
        },
        {
            title: formatMessage({ id: 'time_to_complete' }),//Time to complete
            detail: bundle?.duration || readingTime,
            icon: "faClock",

        },
        // {
        //     title: formatMessage({ id: 'skill_level' }),//Skill level
        //     // TODO: Need gtranslation
        //     detail: 'beginner',//Beginner
        //     icon: "faSignal",
        // },

        ...(savings.percent > 0 ? [{
            title: formatMessage({ id: 'you_save' }),//You save
            detail: (<>{savings.percent}% <span style={{ textDecoration: 'line-through' }}>${savings.totalCostOfRelatedEvents}</span></>),
            icon: "faMoneyBillWave",
        }] : []),
    ];

    return (
        <>
            <div className='cover-image'
                style={{
                    backgroundImage: `linear-gradient(var(--color-reverse-quaternary), var(--color-primary)), url(${isVisual || "https://cdn.unschooler.me/web_app/tech_pictures/bundles_default.png"})`,
                }}>
                <div className="course-title">
                    <h1 className="supertitle">{bundle?.title}</h1>
                    <div className="course-info">
                        {courseInfoSections.map((section, index) => (
                            <div key={index} className={`course-info__section ${section.sectionClass}`}>
                                <FontAwesomeIcon icon={iconMap[section.icon]} className="margin_right" size="2x" />
                                <div className="course-info__text">
                                    <p className="course-info__title">{section.title}</p>
                                    <h2 className="course-info__detail">{section.detail}</h2>
                                </div>
                            </div>
                        ))}
                    </div>


                </div>
            </div>

            <div className='page page_event'>
                <div className="flex-container_center ">
                    <div className='flex-collumn flex-left-column_center'>
                        {/* static info */}
                        {bundle.program && (
                            <div className="supertext">
                                {bundle.program}
                            </div>
                        )}
                        <div className="event-component__box event-description ">
                            <Tabs defaultActiveKey="1">
                                <TabPane tab={formatMessage({ id: 'about_tab' })} key="1">
                                    {[...bundle.relatedEvents]?.sort((a, b) => a.order - b.order).map(ev =>
                                        <EventCardContainer
                                            event={ev.event}
                                            user={user}
                                            type="list-curriculum"
                                            key={ev.event.id}
                                            {...other}
                                        />
                                    )}

                                    {bundle.program && (
                                        <div className="event-description  wrapper_outer-section">
                                            <h2 className="event-component__row__title">
                                                {formatMessage({ id: "learning_objectives_label" })}
                                            </h2>
                                            <ObjectivesComponent event={bundle} />
                                        </div>
                                    )}
                                    {bundle.description && (
                                        <div className="event-description wrapper_outer-section">
                                            <div className="Answer__info">
                                                <h2>{formatMessage({ id: 'overview_title' })}</h2>
                                                <ReactMarkdownComponent children={bundle.description} />
                                            </div>
                                        </div>

                                    )}

                                    {bundle.equipment && (
                                        <div className="event-description  wrapper_outer-section">
                                            <h2 className="event-component__row__title">
                                                {formatMessage({ id: "prerequisites_and_equipment_label" })}
                                            </h2>
                                            <ReactMarkdownComponent children={bundle.equipment} />
                                        </div>
                                    )}
                                    <MentorBlockContainer
                                        user={user}
                                        workspace={workspace}
                                        event={bundle}
                                        {...other}
                                    />
                                </TabPane>
                                {isEditor && (
                                    <TabPane tab={`${formatMessage({ id: "students" })} ${bundle.countAllUserSubscriptions}`} key="2">
                                        <StudentsTable
                                            userList={bundle.userSubscriptions}
                                            event={bundle}
                                            workspace={workspace}
                                            user={user}
                                            {...other}
                                        />
                                    </TabPane>
                                )}
                                {/* <TabPane tab={`${formatMessage({ id: 'reviews_tab' })} (soon)`} key="3" disabled >
                                    <ReviewTabContainer event={bundle} />
                                    <h3>{formatMessage({ id: 'soon_title' })}  </h3>
                                    <div className=" wrapper_image_card" style={{ width: 800 }} >
                                        <img className='picture_absolute' width='800' src="https://cdn.unschooler.me/17095703674716cd.png" alt="Event Gallery" />
                                    </div>
                                </TabPane> */}
                            </Tabs>

                        </div>
                    </div>

                    <div className="flex-right-column sticky_enroll">
                        <div className="event_actions_vertical ">
                            {isEditor &&
                                <div className="wrapper_content-section">
                                    <div>
                                        <Link
                                            to={{
                                                pathname: isEditor
                                                    ? `${localePrefix}/create-bundle/${bundle.id}`
                                                    : `${localePrefix}/payments/`,
                                                state: {
                                                    bundle,
                                                    workspace: workspace,
                                                },
                                            }}
                                            className=' ant-btn ant-btn-secondary btn-left wrapper_inner-section block-el'
                                        >
                                            <Svg
                                                name=':icon/edit'
                                                className='icon_24 margin_right'
                                            />2.{' '}
                                            {formatMessage(
                                                isEditor
                                                    ? { id: 'event_change', }
                                                    : { id: 'event_upgrade' }
                                            )}
                                            {!isEditor && <PaidComponent />}
                                        </Link>
                                    </div>

                                    {bundle.isDraft
                                        ? <AiPublishSalesGeneratorComponent event={bundle} {...other} />
                                        : <Button onClick={() => handleDraft()} type="secondary" className="btn-left" block>
                                            <Svg name=':icon/cross' className='icon_24 margin_right' />
                                            {formatMessage({ id: 'remove_button' })}
                                        </Button>
                                    }
                                </div>
                            }
                        </div>
                        <div >
                            {!bundle.isDraft && (
                                <EnrollContainer
                                    event={bundle}
                                    handleParticipate={handleParticipate}
                                    user={user}
                                    handleRemoveParticipant={handleRemoveParticipant}
                                    isEditor={isEditor}
                                    onLogin={handleLogin}
                                    workspace={workspace}
                                    reading={readingTime}
                                    {...other}
                                />
                            )}
                            <hr />
                        </div>
                    </div>
                </div>

                <LoginContainer
                    showLogin={showLogin}
                    setCancelLogin={setShowLogin}
                    onLoginUser={handleLoginUser}
                />

                {
                    !isWhiteLabeled &&
                    <FooterContainer />
                }
            </div>
        </>
    );
};
