import './scss/index.scss';
import '../LandingQuote.component/scss/index.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SuperTitleComponent } from '../../common/SuperTitle.component/SuperTitle.component';
import { SuperTextComponent } from '../../common/SuperText.component/SuperText.component';

export const LandingTextCards = ({ imgSrc, title, description, icon }) => {
    return (
        <>
            {imgSrc && (
                <div className='wrapper_stickers'>
                    <LazyLoadImage className="stickers"
                        src={imgSrc}
                        alt={title} />
                </div>
            )}
            <div className='wrapper_quote'>
                <div className='quote'>
                    <SuperTitleComponent title={title} />

             
                </div>
                <div className='quote'>
                    <SuperTextComponent description={description} icon={icon} />
          
                </div>
            </div>
        </>
    );
};
