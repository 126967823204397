import React, {useContext} from 'react';
import {noop} from 'lodash';
import {Button} from 'antd';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {Svg} from '../../common';
import { EditTask } from '../../task/EditTask/EditTask';

export const PanelEditTaskContainer = (props) => {
    const {
        theme,
        onEditTask = noop,
        onClose = noop,
        ...other
    } = props;
    const {user} = useContext(FirebaseContext);

    const onSubmitCallback = () => {
        onEditTask(false);
    };

    return (
        <div className='panel-block__container wrapper-form'>
            <div className='panel-block__item'>
                <div className='panel-block__item__close_absolute'>
                    <Button
                        type='text'
                        onClick={onClose}
                        size='24'
                        className='inline-el panel-block__item__btn'
                    >
                        <Svg name=':icon/cross' className='icon_24' />
                    </Button>
                </div>

                <EditTask
                    {...other}
                    theme={theme}
                    onSubmitCallback={onSubmitCallback}
                    user={user}
                />
            </div>
        </div>
    );
};
