import {useCallback} from 'react';
import {useLazyQuery} from '@apollo/client';
import {GetPaypalAccount} from '../graphQL/queries/GetPaypalAccount';

export const useGetPaypalAccount = (query = GetPaypalAccount ) => {
    const [getPaypalAccount, {data, loading, error}] = useLazyQuery(query, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-only'
    });

    return {
        data,
        getPaypalAccount,
        loading,
        error
    }
}