import React from 'react';
import {RecipientContainer} from '../Recipient.container';
import {PayerContainer} from '../Payer.container';

export const ReportsPaidComponent = (props) => {
    const {
        ...other
    } = props;

    return (
        <div className="reports-paid-component">
            <RecipientContainer {...other}/>
            <PayerContainer {...other}/>
        </div>
    )
}
