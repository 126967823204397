import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Tab} from '../common/Tab';
import {LoginContainer, useTrackClick} from '../common';
import {TasksListContainer} from './TasksList.container';
import {useLocalePrefix} from '../lang';

export const TasksListTab = (props) => {
    const {
        as: Component = TasksListContainer,
        activeTab,
        tabs,
        subject,
        tag,
        isMe,
        viewer,

        ...other
    } = props;

    const [tab, setActiveTab] = useState(activeTab);
    const trackClick = useTrackClick();
    const [localePrefix] = useLocalePrefix();
    const [showLogin, setShowLogin] = useState();
    const history = useHistory();

    const handleLoginUser = (userData) => {
        if (userData?.id) {
            history.push(`${localePrefix}/edit-task/`);
        }
    };

    const handleTab = (string) => {
        string !== tab ? setActiveTab(string) : console.log('current');
    };

    const handleClick = (e) => {
        trackClick(
            'click-add-task',
            'careers',
            `https://unschooler.me${localePrefix}/tutorials`
        );

        if (!viewer?.id) {
            e.preventDefault();
            setShowLogin(true);
        }
    };

    return (
        <>
            {tabs?.length > 0 && (
                <Tab
                    {...other}
                    items={tabs}
                    handleClick={handleTab}
                    activeTab={tab}
                    type="big"
                />
            )}

            <Component
                {...other}
                activeTab={tab}
                classNames='wrapper_list'
                subject={subject}
                tag={tag}
                isMe={isMe}

            />

            <LoginContainer
                showLogin={showLogin}
                setCancelLogin={setShowLogin}
                onLoginUser={handleLoginUser}
            />
        </>
    );
};
