import React from 'react';
import { useIntl } from 'react-intl';
import { ProgramsSubjectIconComponent } from '../program/ProgramsSubjectIcon.component';

export const TaskProgressComponent = ({
    backgroundColor,
    progressColor,
    progressPercentage,
    completedCount,
    allCount,
    tagsRenew,
    specIndex,
    event
}) => {
    const { formatMessage } = useIntl();

    return (
        <div className="task-progress-component flex-grow_1">
            <div className='wrapper_progress' style={{ backgroundColor }}>
                <small className='progress_label'>
                    {formatMessage({ id: 'form_is_steps' })}: {completedCount}/{allCount} —
                    <strong> {tagsRenew[0]?.title}</strong>
                </small>
                <div className='progress_short' style={{ backgroundColor: progressColor, width: `${progressPercentage}%` }}>
                    <div className='progress_short' style={{ backgroundColor: progressColor, width: `${progressPercentage}%` }}></div>
                </div>
            </div>
            {event?.badge && tagsRenew?.length > 0 &&
                <div className=' wrapper_progress_achevement_progress'>
                    <ProgramsSubjectIconComponent
                        source={tagsRenew[0].src}
                        width={50}
                        achievement={{ ...tagsRenew[0], color: progressColor }}
                        specIndex={specIndex}
                        event={event}
                        colorfull={completedCount === allCount}
                    />
                </div>
            }
        </div>
    );
};
