import './scss/index.scss';
import React from 'react';
import { FullListProfessionsContainer } from '../../profile/FullListProfessions/FullListProfessions.container';

export const ProfessionProfileComponent = (props) => {
    const {
        user,
        skills,
        localePrefix,
        ...other
    } = props;
    return (
        <div className='wrapper_skills-map'>
            <FullListProfessionsContainer
                skills={skills}
                user={user}
                {...other}
            />
        </div>
    );
};
