import "./scss/index.scss";
import React from "react";
import { useIntl } from "react-intl";
import { Button, Form, Input, Switch } from 'antd';
import { MinusCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const QuestionOption = ({
    option,
    remove,
    onChangeContent
}) => {
    const { formatMessage } = useIntl();
    return (
        <div key={option.key} className='edit-task-component__question__option__body'>
            <div className="edit-task-component__question__option__input">
                <Form.Item
                    name={[
                        option.name,
                        'isCorrect',
                    ]}
                    // label={formatMessage({ id: 'form_field_is_right', }
                    // )}

               
                    className='edit-task-component__question__switcher'
                    valuePropName='checked'
                >
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={() => onChangeContent()}
                        defaultChecked />
                </Form.Item>
                <Form.Item
                    name={[option.name, 'text',]}
                    className='grow_input'
                    rules={[
                        {
                            required: true,
                            message:
                                formatMessage({ id: 'form_field_is_required', }),
                        },
                    ]}
                >
                    <Input autoComplete='off' />
                </Form.Item>
            </div>

            <Button
                type='link'
                onClick={() => {
                    remove(option.name);
                    onChangeContent();
                }}
                icon={<MinusCircleOutlined />}
                className='edit-task-component__question__remove'
                size='middle'
            />
        </div>
    )
}
