import {useEffect, useContext} from 'react';
import {useLazyQuery} from '@apollo/client';
import {GetConnectedAccount} from '../graphQL/quries/GetConnectedAccount';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const useGetConnectedAccount = (userId) => {
    const [getConnectedAccount, {data, error, loading}] = useLazyQuery(GetConnectedAccount, {
        fetchPolicy: 'no-cache',
    });
    const {workspace} = useContext(FirebaseContext);
    useEffect(() => {
        (async () => {
            const {ownerId} = workspace || {};
            const stripeOwnerId = ownerId || userId;
            const settings = !!stripeOwnerId ? {
                variables: {userId : stripeOwnerId}
            } : '';
            await getConnectedAccount(settings);
        })();
    }, [userId, workspace, getConnectedAccount]);
    return {getConnectedAccount, data, loading, error};
}
