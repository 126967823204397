import { useLazyQuery } from "@apollo/client";
import { GetSubject } from "../graphQL/queries/GetSubject";

export function useGetSubject(query = GetSubject) {
    const [getSubject, { data, loading, error }] = useLazyQuery(query);
    return {
        subject: data?.subject,
        loading,
        error,
        getSubject
    };
}