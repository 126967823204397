import { useState } from 'react';
import { useVimeoCaptions } from '../../vimeo/hook';
// import { useGetVideoCaptions } from '../../youtube/hooks';
import { useConvertAudioToText } from '../../ai';

export const useGetCaptions = (coverType, videoSrc, taskCover) => {
    const { getVimeoCaptions } = useVimeoCaptions();
    // TODO: Youtube review
    // const { getVideoCaptions } = useGetVideoCaptions();
    const { getText } = useConvertAudioToText();

    const [captionsSummary, setCaptionsSummary] = useState();
    const [captions, setCaptions] = useState();
    const [captionsLoading, setCaptionsLoading] = useState(false);

    const getCaptions = async (coverType, videoSrc, taskCover, audioSource) => {
        setCaptionsLoading(true);
        let sourceText = '';

        switch (coverType) {
            case 'VIDEO':
                if (!taskCover?.raw) {
                    const resp = await getText(audioSource || taskCover?.source?.replace('mp4', 'mp3'));
                    const { requestConvertApi } = resp || {};
                    if (requestConvertApi) {
                        const apiResponse = JSON.parse(requestConvertApi);
                        const segments = apiResponse.segments;
                        const subtitleText = segments.map((segment) => {
                            const formatTime = (time) => {
                                const date = new Date(time * 1000);
                                const hours = date.getUTCHours().toString().padStart(2, '0');
                                const minutes = date.getUTCMinutes().toString().padStart(2, '0');
                                const seconds = date.getUTCSeconds().toString().padStart(2, '0');
                                const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');
                                return `${hours}:${minutes}:${seconds},${milliseconds}`;
                            };

                            const start = formatTime(segment.start);
                            const end = formatTime(segment.end);
                            return `${start} --> ${end}\n${segment.text}\n`;
                        }).join('\n');

                        // setCaptions(subtitleText);
                        sourceText = apiResponse.text;
                        setCaptions(apiResponse.text);
                        setCaptionsSummary(apiResponse.text)
                    }
                } else {
                    sourceText = taskCover?.raw;
                    setCaptions(sourceText);
                }
                break;

            case 'YOUTUBE':
                // TODO: Youtube review
                // const youtubeCaptions = await getVideoCaptions({ variables: { id: videoSrc } });
                //  let stringFromCaptions= youtubeCaptions?.data?.getVideoCaptions?.captions;
                //  sourceText = stringFromCaptions?.map((el) => el?.text)?.join(' ');
                // setCaptions(sourceText);
                setCaptions('');
                break;

            case 'VIMEO':
                const id = taskCover?.source?.split('/').find((item) => Number.isInteger(parseInt(item)));
                const vimeoCaptions = await getVimeoCaptions({ variables: { id } });
                sourceText = vimeoCaptions?.data?.getVimeoCaptions?.captions;
                setCaptions(sourceText);
                break;

            default:
                setCaptions('');
                break;
        }

        setCaptionsLoading(false);
        return sourceText
    };

    return { captions, captionsLoading, getCaptions, captionsSummary };
};