import '../scss/index.scss';

import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {PayPalScriptProvider, PayPalButtons} from '@paypal/react-paypal-js';
import classnames from 'classnames';
import {LoginContainer, LoaderComponent} from '../../common';

export const PaypalComponent = (props) => {
    const {isLoginMode, isLoading, isLoadingAccount, price, currencyCode, clientId, onCreateOrder, onApprove} = props;
    const {formatMessage} = useIntl();
    const [showLogin, setShowLogin] = useState();

    const options = {
        clientId,
        currency: currencyCode,
        intent: "capture"
    };

    const handleClick = () => {
        if (isLoginMode) {
            setShowLogin(true);
        }
    };

    const componentClassNames = classnames('paypal-component__content', {
        'paypal-component__content_disabled': isLoginMode
    });

    if (isLoadingAccount) {
        return <LoaderComponent type="block"/>
    }

    return (
        <div className="paypal-component">
            <div className="paypal-component__wrapper"
                 onClick={handleClick}>
                <small>
                    {
                        formatMessage({ id: 'purchase_title' })
                    }
                </small>
                <div className="event_price">
                    <div className="inline-el">
                        {price}
                    </div>
                </div>
                <div className={componentClassNames}>
                    <PayPalScriptProvider options={options}>
                        <PayPalButtons style={{layout: "vertical"}}
                                       createOrder={onCreateOrder}
                                       onApprove={onApprove}/>
                    </PayPalScriptProvider>
                </div>
            </div>
            {isLoginMode && (
                <LoginContainer showLogin={showLogin}
                                setCancelLogin={setShowLogin}/>
            )}
            {isLoading && <LoaderComponent/>}
        </div>
    )
}