import React from 'react';
import {Form, Select} from 'antd';
import { useIntl } from 'react-intl';

export const WorkspaceSelectComponent = (props) => {
    const {isDisabled, options, loading, getFormItemClass} = props || {};
const {formatMessage} = useIntl();
    return (
        <Form.Item
            name="workspaceIds"
            label="Schools"
            className={` ${getFormItemClass('workspaceIds')}`}
        >
            <Select
                showSearch
                autoClearSearchValue
                loading={loading}
                placeholder={formatMessage({id: "select_school"})}
                disabled={isDisabled}
                mode="multiple"
                filterOption={false}
                className='select_minor'
                options={options}
                />
        </Form.Item>
    )
}