import React, {useEffect, useState} from "react";
import {Button, Input, Table} from "antd";
import {Link} from "react-router-dom";
import {CSVLink} from "react-csv";
import {useLocalePrefix} from '../../lang';
import {gql, useMutation, useQuery} from "@apollo/client";
import {Loader} from "../../Loader";

const columns = [
    {
      title: 'Name',
      dataIndex: ['user', 'name'],
      render: (text, record) => <a href={window.location.origin + "/profile/" + record.user.id}>{text}</a>,
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
    },
    {
        title: "Event",
        dataIndex: ["event" , "title"],
        render: (text, record) => <a href={window.location.origin + "/online-courses/" + record.event.id}>{text}</a>,
    },
    {
        title: "Mentor",
        dataIndex: ["event" , "mentorName"],
        render: (text, record) => <a href={window.location.origin + "/profile/" + record.event.mentorId}>{text}</a>,
    }
];

export const Promo = (props) => {
    const [promo, setPromo] = useState({});
    const [event, setEvent] = useState("");
    const [users, setUsers] = useState();
    const [usersCsv, setUsersCsv] = useState([]);
    const [localePrefix] = useLocalePrefix();

    const [addEventInPromo, { data, loading }] = useMutation(ADD_EVENT_IN_PROMO);
    const [updatePromo, promoData] = useMutation(UPDATE_PROMO);

    const getPromoData = useQuery(GET_PROMO, {
        variables: {
            promo_id: props.match.params.id
        },
        fetchPolicy: "network-only"
    });

    const getUsersPromo = useQuery(GET_USERS_PROMO, {
        variables: {
            promo_id: props.match.params.id
        }
    });

    const handleAddEvent = () => {
        addEventInPromo({
            variables: {
                content: {
                    event_id: event,
                    promo_id: props.match.params.id
                }
            }
        });
    }

    const handleUpdatePromo = () => {
        updatePromo({
            variables: {
                content: {
                    promo_id: promo.id,
                    name: promo.name,
                    firstPromoMail: promo.firstPromoMail
                }
            }
        });
    }

    useEffect(() => {
        if(data) {
            setPromo((prev) => ({
                ...prev,
                events: data.addEventInPromo.events
            }));
            setEvent("");
        }

    }, [data])

    useEffect(() => {
        if(getPromoData.data) {
            setPromo(getPromoData.data.promo)
        }
    }, [getPromoData.data])

    useEffect(() => {
        if(getUsersPromo.data) {
            let users = getUsersPromo.data.getUsersPromo;
            let csv = [];
            setUsers(users)
            for(let i = 0; i < users.length; i++) {
                let user = users[i].user;
                let event = users[i].event;
                csv.push([user.id, user.name, user.email, event.id, event.mentorName, event?.title])
            }

            setUsersCsv(csv);
        }
    }, [getUsersPromo.data])

    if(getPromoData.loading || getUsersPromo.loading) {
        return <Loader />
    }

    return (
        <div className="AdminNavigation">
            <h1>{promo.name}</h1>
            <div>
                <div>
                    <p>Название</p>
                    <Input className="RowForm" value={promo.name} onChange={(e) =>setPromo((p) => ({ ...p, name: e.target.value })) }  />
                </div>
                <div>
                    <p>Шаблон письма участиника промокомпании</p>
                    <Input className="RowForm" value={promo.firstPromoMail} onChange={(e) =>setPromo((p) => ({ ...p, firstPromoMail: e.target.value })) }  />
                </div>
                <Button className="RowForm" loading={promoData.loading} onClick={handleUpdatePromo}>
                    Сохранить
                </Button>
            </div>
            <p>События: </p>
            <div>
                {
                    promo.events && promo.events.map((event) => (
                        <div className="CellPromo">
                            <Link to={`${localePrefix}/events/${event}`}>eventId - {event}</Link>
                            <Button disabled size="small" style={{
                                marginLeft: 12
                            }}>Удалить</Button>
                        </div>
                    ))
                }
            </div>
            <Input style={{ marginTop: 16 }} value={event}  onChange={(e) => setEvent(e.target.value) }  />
            <Button style={{ marginTop: 16 }} loading={loading} onClick={handleAddEvent}>
                Добавить событие
            </Button>

            <div style={{ marginTop: 16 }}>
                <Table columns={columns} dataSource={users} pagination={false} />
            </div>
            <div>
                <CSVLink data={usersCsv} filename={promo.id}>Download CSV</CSVLink>
            </div>
        </div>
    );
};

const ADD_EVENT_IN_PROMO = gql`
    mutation addEventInPromo($content: addEventInPromoInput) {
        addEventInPromo(content: $content) {
            events
        }
    }
`;

const UPDATE_PROMO = gql`
    mutation updatePromo($content: updatePromoInput) {
        updatePromo(content: $content) {
            name
            id
        }
    }
`;

const GET_PROMO = gql`
    query GetPromo($promo_id: String!) {
        promo(promo_id: $promo_id) {
            name
            id
            firstPromoMail
            events
        }
    }
`
const GET_USERS_PROMO = gql`
    query Promo($promo_id: String!) {
        getUsersPromo(promo_id: $promo_id) {
            event {
                id
                title
                mentorName
                mentorId
            },
            user {
                uid
                name
                email
            }
        }
    }
`
