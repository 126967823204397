
import { useCallback } from 'react';
import { useAiChatResponse } from '../graphQL/useAiChatResponse';

export const useGetResultAi = () => {
    const { getChatResponse, error } = useAiChatResponse();

    const getResultAi = useCallback(
        async (assessment, answer, formatMessage) => {
            let indexOfGreenApple = assessment.indexOf("🍏") || "🍏";

            const settings = {
                args: {
                    assessment: assessment?.substring(0, 300) + assessment?.substring(indexOfGreenApple, indexOfGreenApple + 800),
                    answer: `${answer}`,
                },
                type: 'tutorialAssessment',
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = JSON.parse(response?.data?.requestChatApi);
            if (responseJSON?.choices[0]) {
                let responseTrimmed = responseJSON.choices[0].message.content.trim();
                return `\n\n### ${formatMessage({ id: 'feedback_label' })}:\n${responseTrimmed}`;
            }
        },
        [getChatResponse]
    );

    return { getResultAi, error };
};
