import {useEffect} from 'react';

export const useCustomScript = () => {
    useEffect(() => {
        return () => {
            const tag = document.getElementById('custom-script');
            if (!!tag) {
                tag.remove();
            }
        }
    }, []);

    return (scriptData) => {
        const {customScript, externalScripts} = scriptData || {};
        const externalTags = document.querySelectorAll('[data-external=true]');
        const tag = document.getElementById('custom-script');

        if (!!externalTags?.length) {
            externalTags.forEach((tag) => tag.remove());
        }

        if (!!tag) {
            tag.remove();
        }

        if (!!externalScripts?.length) {
            externalScripts.forEach((url, index) => {
                const externalScript = document.getElementById(`external-script_${index}`);

                if (!externalScript) {
                    const scriptTag = document.createElement('script');
                    scriptTag.async = true;
                    scriptTag.setAttribute('id', `external-script_${index}`);
                    scriptTag.setAttribute('data-external', true);
                    scriptTag.src = url;
                    document.head.appendChild(scriptTag);
                }
            })
        }

        if (customScript) {
            const scriptTag = document.createElement('script');
            scriptTag.defer = true;
            scriptTag.setAttribute('id', 'custom-script');
            scriptTag.text = JSON.parse(customScript);
            document.head.appendChild(scriptTag);
        }
    };
}