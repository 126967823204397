import {useMutation} from '@apollo/client';
import {EditWorkspaceMember} from '../graphQL/mutations/EditWorkspaceMember';

export const useEditWorkspaceMember = (mutation = EditWorkspaceMember) => {
    const [editWorkspaceMember, {data, error, loading}] = useMutation(mutation);

    return {
        editWorkspaceMember,
        data,
        error,
        loading
    }
}