import './scss/index.scss';
import React from "react";
import { Svg } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(fas);

export const ProgramsSubjectIconComponent = (props) => {
    const {
        width = 30,
        achievement,
        hideIcon ,
        colorfull
    } = props;
    const color = colorfull ? (achievement?.color || achievement?.subject?.color) : 'var(--color-primary)';
    return (
        <div className="program-subject__item " style={{ width: width }}>
            {!hideIcon && (
                <FontAwesomeIcon
                    className={`program-subject__item_icon ${colorfull ? 'program-subject__item_icon_active' : ''}`}
                    icon={['fas', achievement?.defIcon || 'graduation-cap']}

                />
            )}
            <Svg name={`:icon/achievement`} className="achievement-svg-icon"
                fill={color}
                width={width}
            />
        </div>
    );
};
