import {gql, useLazyQuery} from '@apollo/client';

export const useMapSkillsCareer = (options = {}, query = GET_MAP_SKILLS_CAREER) => {
    const [getMapSkillsCareer, {data, loading, error}] = useLazyQuery(query, options);

    return {
        getMapSkillsCareer,
        mapSkillsCareer: data?.mapSkillsCareer,
        loading,
        error
    };
};

const GET_MAP_SKILLS_CAREER = gql`
    query MapSkillsCareer($specId: Int!) {
        mapSkillsCareer(specId: $specId) {
            id
            specId
            title
            description
            salaryMin
            salaryMax
            counter
            tagsInCareer {
                id
                alias
                subject {
                    id
                    alias
                    subjectUid
                    title
                    color
                    icon
                    countResults
                }
                title
                icon
                defIcon
                countResults
            }
        }
    }
`;