import {useContext, useCallback} from 'react';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import LOCALES from '../../../constants/longLocales.json';

export const useDateData = () => {
    const {locale} = useContext(FirebaseContext);
    const loc = LOCALES[locale] || LOCALES['en'];

    return useCallback((d) => {
        if (!d) return null;

        const date = new Date(d);
        return {
            dayNumber: date.getDay(),
            day: date.toLocaleDateString(loc, {weekday: 'long'}).toLowerCase(),
            date: date.toLocaleDateString(loc, {month: 'long', day: 'numeric'}).toLowerCase(),
            time: date.toLocaleTimeString(loc, {hour: 'numeric', minute: 'numeric'}).toLowerCase()
        }
    }, [loc]);
}
