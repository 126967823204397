import './scss/index.scss';
import React, { useEffect, useState, useContext } from 'react';
import QRCode from 'qrcode.react';
import { useIntl } from 'react-intl';

import { getDegColor, getReadingTime } from '../../common';
import { useGetGoals } from '../../common/graphQL/goals/useGetGoals';
import { useUpdatePreference } from '../../common/graphQL';
import { useGetPreference } from '../../common/graphQL/preference/useGetPreference';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { EmbedContext } from '../../../context/embed';

import { useLocalePrefix } from '../../lang';
import { CertificateLogo } from './CertificateLogo';

export const CertificateComponent = (props) => {
    const {
        task,
        certificate,
        gotAchievement,
        taskResult,
        tagsRenew,
        ...other
    } = props;
    const { workspace, user } = useContext(FirebaseContext);
    const { updatePreference } = useUpdatePreference();
    const { preference, getPreference } = useGetPreference({ fetchPolicy: 'no-cache', });
    const { goals, getGoals } = useGetGoals({ fetchPolicy: 'no-cache', });
    const [tasksToLearnSt, setTasksToLearn] = useState(task);
    const { isEmbed } = useContext(EmbedContext) || {};
    const URL = isEmbed || '';
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();
    const reading = getReadingTime(certificate?.event?.tasks, formatMessage);
    const updatePrefInUser = async (pref, goalsDB) => {
        let goalNew = goalsDB.filter(goal => goal.title === gotAchievement.certificate.title);
        const goalIds = pref?.goals.map(goal => goal.id);

        updatePreference({
            goals: !goalIds.includes(goalNew) ? goalIds.push(goalNew.id) : goalIds
        })
    }
    const color = !!tasksToLearnSt?.length ? tasksToLearnSt[0]?.tags[0]?.subject.color : '';
    const date = certificate?.createDate ? new Date(certificate.createDate) : new Date();
    const formattedDayMonth = date ? new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' }).format(date) : '';
    const formattedYear = date ? new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date) : '';

    useEffect(() => {
        if (gotAchievement) {
            (async () => {
                const goals = await getGoals(1);
                const pref = await getPreference(user?.id)
                await updatePrefInUser(pref, goals);
            })()
        }
    }, [getGoals, getPreference, gotAchievement, user?.id]);

    return (
        <div className="certificate">
            <div className="certificate-content" >
            <div class="border"></div>
                {!certificate?.isAppliedForCurrentUser ? (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <div>
                            <p className='certificate-title' style={{ textTransform: 'uppercase' }}>
                                {certificate?.title}
                            </p>
                            <div className='wrapper_content-section'>
                                <h2 className='course-title'>{formatMessage({ id: 'certificate_description_date' })}</h2>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <div style={{ textAlign: 'left' }}>
                                {workspace?.logo &&
                                    <CertificateLogo workspace={workspace} />
                                }
                            </div>

                        </div>
                    </div>
                ) : (

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <div>
                            <p className='certificate-title' style={{ textTransform: 'uppercase' }}>
                                {certificate?.title}
                            </p>
                            <div className='wrapper_content-section'>
                                <div className='wrapper_content-section'>
                                    <span className='certificate-description'>
                                        {formatMessage({ id: 'certificate_issued_for' })}
                                    </span>
                                    <h2 className='course-title wrapper_content-section' >
                                        {certificate?.student?.name}
                                    </h2 >

                                <p className='certificate-description '>
                                    {certificate?.description || formatMessage({ id: 'certificate_description' })}
                                </p>
                                </div>
                                <h2 className='course-title'>{certificate?.event?.title?.length > 100 ? certificate?.event?.title?.substring(0, 100) + '...' : certificate?.event?.title}</h2>
                                <p className='certificate-description '>
                                    {formatMessage({ id: 'certificate_duration' })}: {certificate?.event?.duration || reading} 
                                </p>

                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%' }}>
                            <div style={{ textAlign: 'left' }}>
                                {workspace &&
                                    <>
                                        <CertificateLogo workspace={workspace} />
                                        <p className='certificate-description '>{workspace?.title || ''}</p>
                                    </>
                                }

                            </div>
                            <div style={{ textAlign: 'right' }}>
                                {/* <h3 className="certificate-sign" style={{ fontWeight: 'bold', fontFamily: 'Caramel, sans-serif' }}>{certificate?.event?.creator?.name}</h3> */}
                                <h3 className="certificate-sign" style={{ fontWeight: 'bold', fontFamily: 'Caramel, sans-serif' }}>{workspace?.title || certificate?.event?.creator?.name}</h3>
                                <p className='certificate-description '>{certificate?.label || formatMessage({ id: 'certificate_label' })}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="certificate-badge_wrapper" >
                <div className="certificate-badge" >
                    <div className="flag"></div>
                    {certificate?.isAppliedForCurrentUser ? (
                        <div className="certificate-date">
                            <p className='certificate-title certificate-description'>{certificate?.userCertificateId || '4245'}</p>
                            <hr className='hr_small' />
                            <p className='certificate-description ' >{formattedDayMonth}</p>
                            <h2 >{formattedYear}</h2>
                            <QRCode
                                value={`https://${window.location.hostname}/${localePrefix}/${URL}user-certificate/${certificate?.userCertificateId}`}
                                size={80}
                                bgColor={"#00000000"}
                                fgColor={"#000000"}
                                level="L"
                            />
                        </div>
                    ) : (
                        <div className="certificate-date">
                            <p>
                                {formatMessage({ id: 'form_is_steps' })}: {taskResult.filter(result => result.stateResult === "COMPLETED" || result.stateResult === "NOT_COMPLETED").length} / {taskResult.length} {formatMessage({ id: 'lesson_title' })}
                            </p>
                        </div>
                    )}
                </div>
                <img
                    className={certificate?.active ? 'certificate_img certificate_img_active' : 'certificate_img'}
                    alt='subject logo'
                    src={certificate?.icon || 'https://cdn.unschooler.me/web_app/achievement/cert/cert_1.png'}
                    style={{
                        color: color,
                        filter: `hue-rotate(${getDegColor(color)}deg)`,
                    }}
                />
            </div>
        </div>
    );
};