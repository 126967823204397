import { useLazyQuery } from '@apollo/client';
import {GetFilteredWorkspaces} from '../graphQL/queries/GetFilteredWorkspaces';

export const useGetFilteredWorkspaces = (query = GetFilteredWorkspaces) => {
    const [getWorkspaces, { data, loading, error }] = useLazyQuery(query);
    return {
        data,
        loading,
        error,
        getWorkspaces
    };
}