import {useLazyQuery} from '@apollo/client';
import {GetVideoCaptions} from '../graphQL/queries/GetVideoCaptions';

export function useGetVideoCaptions(query = GetVideoCaptions) {
    const [getVideoCaptions, {data, loading, error}] = useLazyQuery(query);
    return {
        data,
        loading,
        error,
        getVideoCaptions
    };
}