import { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { checkAnswerStatus } from "../../common";

export const useTaskDripLogic = (event, task, resultState, resultsByUserid, isOwner, dependencies = []) => {
    const checkTaskIsCompleted = (taskI) => {
        return taskI?.stateResult === 'CORRECT' || taskI?.stateResult === 'INCORRECT';
    }
    return useMemo(() => {
        let lastAvailableId = 0;
        let questionCountAbsolute = 0;

        if (event?.isSubscribe && event?.drip === 'JOINDATE') {
            const currentDate = new Date();
            const subscriptionStartDate = new Date(event?.userSubscription?.start);
            const daysDifference = Math.floor((currentDate - subscriptionStartDate) / (1000 * 60 * 60 * 24));
            lastAvailableId = Math.floor(daysDifference / event?.dripFrequency);
        }

        const tagsRenew = [];
        let tasksArray = [];
        let progressCounter = { all: 0, done: 0, incorrect: 0 };

        if (event?.tasks) {
            event?.tasks.forEach((taskEl, i) => {
                if ((taskEl.isDraft && !isOwner)) {
                    return;
                }
                let taskClone = taskEl.id === task?.id ? cloneDeep(task) : cloneDeep(taskEl);
                let addIncorrect = 0;

                let questns = [];
                taskClone?.questions?.forEach((q) => {
                    const answer = resultState?.answers?.find((el) => el?.questionId === q.id);
                    let questn = { ...q };

                    // Drip feeding logic
                    if (event?.isSubscribe && event?.drip === 'JOINDATE') {
                        if (event?.dripGranularity === 'TASK' && lastAvailableId < i) {
                            taskClone.isHidden = true;
                            questn.isHidden = true;
                        }
                        if (event?.dripGranularity === 'QUESTION' && lastAvailableId < questionCountAbsolute) {
                            questn.isHidden = true;
                        }
                    }

                    questionCountAbsolute++;
                    if (answer) {
                        questn.result = answer;
                        questn.stateResult = checkAnswerStatus(resultState, q);
                        questns.push(questn);

                        if (questn.stateResult === "INCORRECT") {
                            addIncorrect += 1;
                        }
                    } else {
                        const applyDripByAnswer = event?.drip === 'ANSWER' && event?.isSubscribe
                            && !taskClone.stateResult;

                        if (applyDripByAnswer) {
                            if (!checkTaskIsCompleted(tasksArray[i - 1]) && i > 0) {
                                taskClone.isHidden = true;
                            }
                            const previousQuestn = questns[questns?.length - 1];
                            if (previousQuestn && !previousQuestn?.stateResult) {
                                questn.isHidden = true;
                            }
                        }

                        questns.push(questn);
                    }
                });

                taskClone.questions = questns;
                tasksArray.push(taskClone);

                const resultEl = resultsByUserid?.find((el) => el.task?.id === taskEl?.id);
                progressCounter.all += taskEl?.questions?.length;
                progressCounter.done += resultEl?.answers?.length || 0;
                progressCounter.incorrect += addIncorrect;

                taskClone?.tags?.forEach((tag) => {
                    const tagInTags = tagsRenew?.find((el) => el?.id === tag.id);
                    if (tagInTags) {
                        tagInTags.counter.all += taskEl?.questions?.length;
                        tagInTags.counter.done += resultEl?.answers?.length || 0;
                        tagInTags.counter.incorrect += addIncorrect;
                  
                    } else {
                        tagsRenew.push({
                            id: tag.id,
                            title: tag.title,
                            color: tag.subject?.color,
                            icon: tag.icon,
                            defIcon: tag.defIcon,
                            subject: { ...tag.subject },
                            counter: {
                                all: taskEl?.questions?.length,
                                done: resultEl?.answers?.length || 0,
                                incorrect: addIncorrect
                            }
                        });
          
                    }
                });
            });
        } else {
            // Handling synthetic array for tests
            let taskClone = cloneDeep(task);
            taskClone?.questions?.forEach((q) => {
                const answer = resultState?.answers?.find((el) => el?.questionId === q.id);
                if (answer) {
                    q.result = answer;
                }
            });
            tasksArray.push(taskClone);
        }
        return { taskResult: tasksArray, tagsRenew, progressCounter };

    }, [event, task, resultState?.answers?.length, resultsByUserid, ...dependencies]);
};