import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLocalePrefix } from '../../lang';

import { MetaComponent, useHostNameUrl } from '../../common';
import { useGetUsers } from "../../common/graphQL";
import { WorkspaceComponent } from '../Workspace.component';
import { useEditEvent } from '../../event/hooks/useEditEvent';
import { useGetUserSubscriptions } from '../../user';

export const WorkspaceContainer = (props) => {
    const { workspace, user, onUpdateWorkspace, ...other } = props;
    const [actualWorkspace, setActualWorkspace] = useState(workspace);
    const { pathname } = useLocation();
    const { getUsers, users, loading: membersLoading } = useGetUsers();
    const [isFakeLoading, setIsFakeLoading] = useState();
    const workspaceUrl = useHostNameUrl(workspace);
    const [localePrefix] = useLocalePrefix();
    const { editEvent } = useEditEvent();
    const { getUserSubscriptions, data: userSubscriptionsData } = useGetUserSubscriptions();
    
    const { subscriptionActive } = useMemo(() => {
        const subscription = userSubscriptionsData?.getUserSubscriptions?.[0];
        const currentDate = new Date();
        const subscriptionEndDate = new Date(subscription?.end);
        const isActive = subscriptionEndDate > currentDate;

        return {
            subscriptionActive: isActive,

        };
    }, [userSubscriptionsData]);

    useEffect(() => {
        if (!!workspace?.members?.length) {
            const ids = workspace?.members.map(member => parseInt(member.userId));

            if (user?.id) {
                (async () => {
                    await getUsers(ids)
                })();
            }
        }

    }, [getUsers, user?.id, setActualWorkspace, workspace]);

    useEffect(() => {
        if (user?.id && workspace?.id) {
            (async () =>
                await getUserSubscriptions({
                    variables: {
                        input: {
                            studentId: user?.id,
                            workspaceId: workspace?.id
                        }
                    }
                })

            )()
        }
    }, [user?.id, workspace?.id]);

    useEffect(() => {
        setActualWorkspace(workspace)
    }, [workspace?.eventsLists, workspace?.members])

    const metaData = useMemo(() => {
        const title = workspace?.title;
        const metaTitle = `${title}`;
        const description = `Learn Online on ${title}`;
        const url = `${workspaceUrl}${localePrefix}`;

        return {
            meta: {
                meta_title: metaTitle,
                meta_description: description,
                meta_author: workspace.title,
                meta_icon: workspace.favicon || workspace.logo,
            },
            og: {
                title: metaTitle,
                image: workspace?.sharing || workspace?.cover,
                description,
                url,
            },
            canonical: url
        };
    }, [pathname, workspace]);

    const handleAddAll = async (selectedEvents) => {
        let d = 0;
        selectedEvents?.forEach(event => {
            handleSaveEventWorkspace(event);
            d++;
        })
        if (selectedEvents?.length === d) {
            setIsFakeLoading(true)
        }
        onUpdateWorkspace()
    };

    const handleSaveEventWorkspace = async (event) => {
        const newData = {
            workspaceIds: [...event.workspaceIds, workspace.id],
        };

        await editEvent({
            variables: {
                eventId: event.id,
                eventData: newData,
            },
        })
    }

    useEffect(() => {
        setTimeout(() => {
            setIsFakeLoading(false);
        }, 1000);
    }, [isFakeLoading])
    return (
        <>
            {metaData && (
                <MetaComponent
                    meta={metaData.meta}
                    og={metaData.og}
                    twitter={metaData.twitter}
                    canonical={metaData.canonical}
                />
            )}

            <WorkspaceComponent
                {...other}
                user={user}
                workspace={actualWorkspace || workspace}
                setActualWorkspace={setActualWorkspace}
                members={users}
                membersLoading={membersLoading}
                isFakeLoading={isFakeLoading}
                setIsFakeLoading={setIsFakeLoading}
                onSendSelected={handleAddAll}
                onUpdateWorkspace={onUpdateWorkspace}
                subscriptionActive={subscriptionActive}
            />
        </>
    );
};
