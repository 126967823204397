export const getSavingCost = (event) => {
    if (event.type !== "BUNDLE" || !Array.isArray(event.relatedEvents)) {
        return { percent: 0, totalCostOfRelatedEvents: 0 };
    }
    const totalCostOfRelatedEvents = event.relatedEvents.reduce((acc, ev) => {
        const eventPrice = ev.event.price?.forStudent || ev.event.priceSubscription?.forStudent || 0;
        return acc + eventPrice;
    }, 0);
    const bundlePrice = event.price?.forStudent || event?.priceSubscription?.forStudent || 0;

    if (totalCostOfRelatedEvents === 0 || totalCostOfRelatedEvents < bundlePrice) {
        return { percent: 0, totalCostOfRelatedEvents: 0 };
    }
    const percent = (1 - (bundlePrice / totalCostOfRelatedEvents)) * 100;
    return { percent: Math.round(percent), totalCostOfRelatedEvents: Math.floor(totalCostOfRelatedEvents) };
};