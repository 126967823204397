import './scss/index.scss';
import React, { useContext } from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';

import { AIAskContainer } from '../../ai';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { LoaderTypeComponent, Svg } from '../../common';
import { ReactMarkdownComponent } from "../../task/ReactMarkdown.component/ReactMarkdown.component";

export const YoutubeCaptionsComponent = ({
    timestampsList,
    handleAddTimestamp,
    handleTimeStampClick,
    task,
    inProgressVideo,
    handleDeleteTimestamp,
    handleSummaryClick,
    showSummary,
    summary,
    findVideosToCover,
    ...other
}) => {
    const disableBtn = inProgressVideo ? true : false;
    const { user } = useContext(FirebaseContext);
    const { formatMessage } = useIntl();

    const converterTime = (offset) => {
        let fullSeconds = Math.floor(parseInt(offset) / 1000);
        let hours = Math.floor(fullSeconds / 3600);
        let minutes = Math.floor((fullSeconds % 3600) / 60);
        let seconds = Math.floor(fullSeconds % 60);

        let time = (hours && hours + ':') + minutes + ':' + seconds;
        return time;
    };
    const inverseTimestamps = () => {
        let timestampsListClone = [...timestampsList];
        let sortedObjects = timestampsListClone?.sort(function (a, b) {
            let numberA = Number(a?.timestamp);
            let numberB = Number(b?.timestamp);
            return numberA - numberB;
        });
        return sortedObjects;
    };

    const isCreator = user?.id === task?.creator?.id;
    return (
        <div className='youtube-captions-component'>
            <div className='wrapper_inner-section flex-container flex-container_between'>
                <div>
                    <Button
                        className='ai-bot-component__button'
                        onClick={handleAddTimestamp}
                        size='small'
                        type='text'
                        disabled={disableBtn}
                    >
                        ✦ {formatMessage({ id: 'bookmark' })}
                    </Button>

                    <Button
                        // className='ai-bot-component__button'
                        onClick={handleSummaryClick}
                        type='text'
                        size='small'
                    >
                        ✦ {showSummary ? formatMessage({ id: 'summary_hide' }) : formatMessage({ id: 'summary' })}
                    </Button>

                </div>
            </div>
            {showSummary && (
                <div className="Answer__info">
                    <hr />
                    <ReactMarkdownComponent children={summary} />
                </div>
            )}

            {/* <AIAskContainer
                task={task}
                inProgressHighlight={inProgressVideo}
                placeholder={formatMessage({ id: 'ask_video_placeholder' })}
                {...other}
            /> */}

            <div className=''>
                {inProgressVideo && <LoaderTypeComponent />}
                {timestampsList &&
                    inverseTimestamps()
                        .reverse()
                        .map((timestamp, i) => (
                            <div
                                key={i}
                                className='btn_timestamp'
                                onClick={() =>
                                    handleTimeStampClick(timestamp.timestamp)
                                }
                            >
                                <div className='flex-container flex-container_between'>
                                    <div>
                                        <span className='margin_right'>
                                            {converterTime(timestamp.timestamp)}
                                        </span>
                                        <strong>
                                            {timestamp?.title.trim() ||
                                                timestamp?.timestamp.trim()}
                                        </strong>
                                    </div>
                                    {isCreator && (
                                        <Button
                                            className='ai-bot-component__button'
                                            onClick={(e) =>
                                                handleDeleteTimestamp(
                                                    e,
                                                    timestamp
                                                )
                                            }
                                            size='small'
                                            type='text'
                                        >
                                            <Svg
                                                name=':icon/cross'
                                                className='icon_16 '
                                            />
                                        </Button>
                                    )}
                                </div>
                                <p>{timestamp?.description}</p>
                            </div>
                        ))}
            </div>
        </div>
    );
};
