import { gql } from "@apollo/client";

export const GetTags = gql`
query GetTags($subjectId: Int) {
  tags(subjectId: $subjectId) {
    id
    subject {
      icon
      color
      title
      subjectUid
      id
    }
    title
    icon
    defIcon
    countResults
  }
}`;