import {useCallback} from 'react';
import {useAiChatResponse} from '../graphQL/useAiChatResponse';

export const useGetRelevantContent = () => {
    const {getChatResponse, error} = useAiChatResponse();

    const getRelevantContent = useCallback(
        async (items, queryString, goal) => {
            const settings = {
                args: {
                    items: JSON.stringify(items),
                    query: queryString,
                    goal: goal || "",
                },
                type: "bestVideo",
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });
            const responseJSON = response?.data
            ? JSON.parse(response?.data?.requestChatApi)
            : null;

            if (responseJSON?.choices[0]) {
                let responseTrimmed = responseJSON.choices[0].message.content.trim();  
                return responseTrimmed;
            }
        },
        [getChatResponse]
    );

    return {getRelevantContent, error};
};
