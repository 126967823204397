import './scss/index.scss'
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Table, Avatar } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getGalleriesInEvent } from '../getGalleriesInEvent';
import classNames from 'classnames';

import { useLocalePrefix } from '../../lang';
import { useTrackClick, Svg } from '../../common';
import { StudentsTable } from '../../rating/StudentsTable';

export const EventListComponent = (props) => {
    const {
        cardColor,
        tagColor,
        event,
        eventID,
        imgUrl,
        tagData,
        currentDate,
        isLive,
        filteredList,
        participants,
        user,
        handlePublish,
        deg,
        draft,
        isNotAllow,
        workspace,
        openFirtsLesson,
        ...other
    } = props;

    const trackClick = useTrackClick();
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const { title: tagTitle, subject: tagSubject, color: tagColorData } = tagData || {};
    const finalTagColor = tagSubject?.color || tagColorData;
    let isBundle = event?.type === "BUNDLE";

    let LinkClick = (isNotAllow) ? `${localePrefix}/payments` : `${localePrefix}/${isBundle ? "bundles" : 'online-courses'}/${event.alias}-${event.id}`;
    if (openFirtsLesson) { LinkClick = `${localePrefix}/tutorials/${event?.tasks?.[0]?.id}` }

    let setEventDifficulty = () => {
        let difficulty = 0;
        event.tasks?.forEach((task) => { difficulty = task.difficultyId + difficulty })

        let avg = difficulty / event?.tasks?.length;
        if (avg >= 1 && avg < 1.6) {
            return { level: 'easy', color: '#000000' };
        } else if (avg > 1.6 && avg < 2.4) {
            return { level: 'medium', color: '#000000' };
        } else if (avg > 2.5) {
            return { level: 'hard', color: '#000000' };
        } else {
            return { level: 'easy', color: '#000000' };
        }
    }
    let dif = setEventDifficulty();
    let filter = isNotAllow ? `grayscale(1)` : !imgUrl ? `hue-rotate(${deg}deg)` : ``
    let progress = { allCount: event?.tasks?.length, count: 0 };

    event?.tasks && event?.tasks?.forEach(task => {
        if (task.stateResult === ('CORRECT' || "INCORRECT")) {
            progress.count = progress.count + 1
        }
    })
    let DropDown = classNames('icon_12 link-btn_light btn_down', {
        btn_dropdown: !isCollapsed,
    });

    const columns = [
        {
            title: 'Event Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
            render: (text, record) => (
                <Link
                    className='event-card-component__title margin_right'
                    onClick={() =>
                        trackClick(
                            isLive ? 'click-event-card_profi' : 'click-event-card',
                            record.event.id,
                            record.LinkClick
                        )
                    }
                    to={{ pathname: record.LinkClick, state: { workspace: record.workspace } }}
                >
                    <Avatar src={record.imgUrl || getGalleriesInEvent(record.event?.id)} size={30} className='margin_right' style={{
                        color: record.tagColor?.color,
                        filter: record.filter,
                    }} />
                    <span className='text_secondary'>
                        {record.isBundle && `${formatMessage({ id: 'bundle' })}:`}
                    </span>
                    {text}
                </Link>
            ),
        },
        {
            title: 'Students',
            dataIndex: 'userSubscriptions',
            key: 'userSubscriptions',
            sorter: (a, b) => a.userSubscriptions.length - b.userSubscriptions.length,
            render: (text, record) => (
                <>
                    {!!record.event.userSubscriptions?.length ?
                        <Button
                            type="text"
                            onClick={() => { setIsCollapsed(!isCollapsed) }}
                        >
                            <span className=' margin_right'>{formatMessage({ id: 'students' })}: {record.event.userSubscriptions?.length}</span>
                            <Svg name=':icon/arrow_down' className={DropDown} />
                        </Button>
                        :
                        <h3 className=' margin_right'>{formatMessage({ id: 'students' })}: {record.event.userSubscriptions?.length}
                            <Svg name={isCollapsed ? ':icon/arrow_down' : ':icon/arrow_up'} className='icon_12' />
                        </h3>
                    }
                </>
            ),
        },
        {
            title: 'Image',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            render: (text, record) => (
                <div className='wrapper_image_card' style={{
                    color: record.tagColor?.color,
                    filter: record.filter,
                }}>
                    <LazyLoadImage
                        className='picture_absolute'
                        src={text || getGalleriesInEvent(record.event?.id)}
                        alt={record.event?.title}
                    />
                </div>
            ),
        },
    ];

    const data = [
        {
            key: eventID,
            title: event.title,
            userSubscriptions: event.userSubscriptions,
            imgUrl: imgUrl,
            event: event,
            tagColor: tagColor,
            filter: filter,
            isBundle: isBundle,
            LinkClick: LinkClick,
            workspace: workspace,
        }
    ];

    return (
        // <div className={`event-card-component  ${isBundle ? 'stacked-border_list' : ''}`}>
        //     <div className='event-card-component__top'>
        //         <div className='wrapper_image_card' style={{
        //             color: tagColor?.color,
        //             filter: filter,
        //         }}>
        //             <LazyLoadImage
        //                 className='picture_absolute'
        //                 src={imgUrl || getGalleriesInEvent(event?.id)}
        //                 alt={event?.title}
        //             />
        //         </div>

        //         <div className='wrapper_action-horizontal '>
        //             <Link className='event-card-component__title margin_right'
        //                 onClick={() =>
        //                     trackClick(
        //                         isLive ? 'click-event-card_profi' : 'click-event-card',
        //                         event.id,
        //                         LinkClick
        //                     )
        //                 }
        //                 to={{ pathname: LinkClick, state: { workspace } }}>
        //                 <Avatar src={imgUrl || getGalleriesInEvent(event?.id)} size={30} className='margin_right' style={{
        //                     color: tagColor?.color,
        //                     filter: filter,
        //                 }} />
        //                 <span className='text_secondary'>
        //                     {isBundle && `${formatMessage({ id: 'bundle' })}:`} 
        //                 </span>
        //                 {event.title}

        //             </Link>
        //             {!!event.userSubscriptions?.length ?
        //                 <Button
        //                     type="text"
        //                     onClick={() => { setIsCollapsed(!isCollapsed) }}
        //                 >
        //                     <span className=' margin_right'>{formatMessage({ id: 'students' })}: {event.userSubscriptions?.length}</span>
        //                     <Svg name=':icon/arrow_down' className={DropDown} />
        //                 </Button>
        //                 :
        //                 <h3 className=' margin_right'>{formatMessage({ id: 'students' })}: {event.userSubscriptions?.length}
        //                     <Svg name={isCollapsed ? ':icon/arrow_down' : ':icon/arrow_up'} className='icon_12' />
        //                 </h3>
        //             }

        //         </div>
        //     </div>


        //     {!!event?.userSubscriptions?.length && !isNotAllow && !isCollapsed && (
        //         <>
        //             <hr className='hr_small' />
        //             <StudentsTable
        //                 userList={event.userSubscriptions}
        //                 event={event}
        //                 workspace={workspace}
        //             />
        //         </>
        //     )}
        // </div >
        <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            expandedRowRender={record => (
                <StudentsTable
                    userList={record.event.userSubscriptions}
                    event={record.event}
                    workspace={record.workspace}
                />
            )}
        />
    )
};
