import './scss/index.scss';
import React from 'react';
import { Select, Spin, Form } from 'antd';
import { useIntl } from 'react-intl';
import {noop} from 'lodash';

export const TagSelectComponent = ({
    tagsPrediction,
    loadingGetTagsPrediction,
    isEditAllow,
    tagsList,
    maxTagsLimit,
    form,
    name,
    label,
    isRequired = true,
    onChange = noop
}) => {
    // const tagColor = tag?.subject?.color || tag?.color || color;
    const { formatMessage } = useIntl();

    return (
        <Form.Item
            name={name || 'tagIds'}
            label={label}
            rules={[
                {
                    required: isRequired,
                    message: formatMessage({
                        id: 'form_theme_error_message',
                    }),
                },
                () => ({
                    validator(_, value) {
                        const isEmpty = !value;
                        const isValid =
                            !isEmpty &&
                            value.length <= maxTagsLimit;

                        if (isEmpty || isValid) {
                            if (
                                form
                                    .getFieldsError()
                                    .filter(
                                        ({ errors }) =>
                                            errors.length
                                    ).length
                            ) {
                                form.validateFields();
                            }
                            return Promise.resolve();
                        } else {
                            return Promise.reject(
                                new Error(
                                    formatMessage(
                                        {
                                            id: 'form_task_max_tags_limit_error',
                                        },
                                        { count: maxTagsLimit }
                                    )
                                )
                            );
                        }
                    },
                }),
            ]}
        >
            <Select
                showSearch
                autoClearSearchValue
                loading={loadingGetTagsPrediction}
                placeholder={formatMessage({ id: 'form_theme_placeholder', })}
                // mode={isEditAllow ? 'tags' : 'multiple'}
                mode="multiple"
                filterOption={false}
                onSearch={(value) => {
                    tagsPrediction({
                        variables: {
                            filter: {
                                title: value,
                            },
                        },
                    });
                }}
                notFoundContent={
                    loadingGetTagsPrediction && tagsList?.length ? (
                        <Spin size='small' />
                    ) : null
                }
                maxTagCount={20}
                onChange={onChange}
                className='select_minor'
            >
                {tagsList?.map((d) => (
                    <Select.Option key={d.id}>
                        {d.title}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>

    );
};
