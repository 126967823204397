import { Button } from 'antd';

export const AIAvatarPlaceholderComponent = ({  handleToggle }) => {
    return (
        <div className="ai-avatar-placeholder">
            <div >
                <div
                    className="circle-button"
                    type="primary"
                    shape="circle"
                >
                    <div className="circle-content">
                        <div className="circle-text">
                            <Button
                                type="text"
                                onClick={handleToggle}
                            >
                                Enable AI avatar for AI credits
                            </Button>
                        </div>
                        <video
                            className="circle-video"
                            src="https://cdn.unschooler.me/web_app/tech_pictures/videos/test.mp4"
                            autoPlay
                            loop
                            muted
                        ></video>
                    </div>
                </div>
            </div>
        </div>
    );
}