import { noop, sortBy } from 'lodash';

export const getChunksFromData = (cover, setError = noop) => {
    let transformedData = cover?.timestamps?.map(item => {
        try {
            let descObj = JSON.parse(item?.description);
            return {
                description: descObj.description,
                title: item.title,
                id: item.id,
                timestamp: item.timestamp,
                example: descObj.example,
                ill: descObj.ill,
                images: descObj.images,
                audio: descObj.audio,
                code: descObj.code,
                main_idea: descObj.main_idea,
                diagram: descObj.diagram,
                ...(descObj?.aiAvatarUrl && {aiAvatarUrl: descObj?.aiAvatarUrl})
            };
        } catch (error) {
            console.log(error)
            setError(error)
        }

    });

    if (transformedData) {
        transformedData = sortBy(transformedData, function (o) { return parseInt(o?.timestamp); });
        return transformedData;
    }

}