import { gql } from '@apollo/client';

export const EditUserCertificate = gql`
    mutation EditUserCertificate($id: Int!, $userName: String!) {
        editUserCertificate(id: $id, userName: $userName) {
            id
            userName
            userId
            certificateId
            createdDate
        }
    }
`;