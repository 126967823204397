import React, {useEffect, useState} from 'react';
import {Loader} from '../Components/Loader';
import {ProgramsContainer} from '../Components/program';
import {getStorageManager} from '../Components/common';

import {ProgramContext} from '../context/program/ProgramContect';

const STORAGE_PREF_KEY = 'preferences-data';

export const Program = (props) => {
    const {user, isTasks, ...other} = props;
    const {name} = user;
    const [themeData, setThemeData] = useState({});
    const [preferences, setPreferences] = useState({});
    const [loading, setLoading] = useState(true);
    const {removeStorageData, getStorageData} = getStorageManager();

    useEffect(() => {
        if (name) {
            const storageData = getStorageData(STORAGE_PREF_KEY, sessionStorage);
            if (storageData) {
                removeStorageData(STORAGE_PREF_KEY, sessionStorage)
            }
            setPreferences(storageData);
        }
        setLoading(false);
    }, [name]);

    if (loading) return <Loader />;

    const programContextData = {
        themeData,
        onSetThemeData: setThemeData,
    };

    return (
        <ProgramContext.Provider value={programContextData}>
            <ProgramsContainer {...other} preferences={preferences}/>
        </ProgramContext.Provider>
    )
};
