import {gql, useLazyQuery} from '@apollo/client';

export const useMapSkills = (options = {}, query = GET_MAP_SKILLS) => {
    const [getMapSkills, {data, loading, error}] = useLazyQuery(query, {
        ...options,
        fetchPolicy: 'cache-first'
    });

    return {
        getMapSkills,
        mapSkills: data?.mapSkills,
        loading,
        error
    };
};

const GET_MAP_SKILLS = gql`
    query MapSkills($specId: Int!) {
        mapSkills(specId: $specId) {
            subjects {
                id
                alias
                subjectUid
                title
                color
                icon
                tags {
                    id
                    alias
                    title
                    icon
                    defIcon
                    countResults
                    subject {
                        id
                    }
                }
                countResults
            }
            tagsWithCounter {
                tagId
#                tag {
#                    id
#                    alias
#                    title
#                    icon
#                    defIcon
#                    countResults
#                }
                counter
            }
        }
    }
`;