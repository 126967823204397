import {gql} from "@apollo/client";

export const VIMEO_VIDEOS = gql`
    query GetVimeoVideos($q: String!, $vimeoFolder: String!) {
        getVimeoVideos(q: $q, vimeoFolder: $vimeoFolder) {
            id
            url
            title
            description
            publishTime
            thumbnail
        }
    }
`;