import React from 'react';
import {YoutubeStorageChannelIdComponent} from '../YoutubeStorageChannelId.component';

export const YoutubeStorageChannelIdContainer = (props) => {
    const handleSetChannelId = (channelId) => {
        window.localStorage.setItem('channelId', channelId);
    };

    return <YoutubeStorageChannelIdComponent {...props}
                                             onSetChannelId={handleSetChannelId}/>
}