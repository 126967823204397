
import './scss/index.scss';
import React, { useState, } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon
} from 'react-share';

import { toJpeg } from 'html-to-image';
import { openNotification } from '../../notification/Notification.component';
import { Svg } from '../';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

export const SharingAchivementComponent = ({
    componentRef,
    sharingMessage,
    formatMessage
}) => {
    const PROD_UPLOAD_URL = 'https://back.unschooler.me/file/upload';

    const [shareUrl, setShareUrl] = useState(null);
    const [inProgress, setInProgress] = useState(false);

    const handleCaptureAndShare = async () => {
        if (componentRef.current === null) {
            return;
        }

        try {
            setInProgress(true);

            const dataUrl = await toJpeg(componentRef.current, { quality: 0.95 });
            const blob = await (await fetch(dataUrl)).blob();
            const file = new File([blob], 'achievement-preview.jpeg', { type: 'image/jpeg' });
            const imageUrl = await uploadImage(file);
            setShareUrl(imageUrl);
        } catch (error) {
            console.error('Oops, something went wrong!', error);
        } finally {
            setInProgress(false);
        }
    };

    const uploadImage = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const resp = await fetch(PROD_UPLOAD_URL, {
                method: 'POST',
                body: formData,
            });

            const { url } = await resp.json();
            return url;
        } catch (err) {
            console.error('Error uploading image:', err);
            throw new Error('Failed to upload image');
        }
    };


    const handleDownload = async () => {
        try {
            const response = await fetch(shareUrl);
            const blob = await response.blob();
            const handle = await window.showSaveFilePicker({
                suggestedName: 'achievement.jpeg',
                types: [{
                    description: 'JPEG Image',
                    accept: {'image/jpeg': ['.jpeg', '.jpg']},
                }],
            });

            const writable = await handle.createWritable();
            await writable.write(blob);
            await writable.close();
            
        } catch (err) {
            const link = document.createElement('a');
            link.href = shareUrl;
            link.download = 'achievement.jpeg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleCopy = () => {
        openNotification('topLeft', {
            title: formatMessage({
                id: 'notif_copy_link_title',
            }),
            icon: '💬',
            duration: 3,
        });
    };

    return (
        <div>
            {!shareUrl ? <Button type="default" onClick={handleCaptureAndShare}>
                <Svg
                    name=':icon/share'
                    className='icon_16'
                />
                Share</Button>

                :
                <div className='wrapper_inner-section'>
                    {shareUrl && (
                        <div className="flex-container">

                            <LazyLoadImage
                                src={shareUrl}
                                alt="Achievement Preview"
                                width={200}
                                
                            />
                            <div>
                                <CopyToClipboard text={sharingMessage} onCopy={handleCopy}>
                                    <p className='copy-textbtn-record btn-left wrapper_inner-section'>{sharingMessage}
                                        <FontAwesomeIcon
                                            icon={faCopy}
                                            className='icon_16'
                                        />

                                    </p>
                                </CopyToClipboard>



                                <Button
                                    size='small'
                                    type="secondary"
                                    icon={<DownloadOutlined />}
                                    onClick={handleDownload}
                                    className='wrapper_inner-section'
                                >

                                    Download Sharing Picture
                                </Button>

                            <div className="social-share-buttons">
                                <FacebookShareButton
                                    url={window.location.href}
                                    quote={sharingMessage}
                                    hashtag="#AchievementUnlocked"
                                >
                                    <FacebookIcon size={40} round />
                                </FacebookShareButton>
                                <TwitterShareButton
                                    title={sharingMessage}
                                    hashtags={["AchievementUnlocked"]}
                                    url={window.location.href}
                                >
                                    <TwitterIcon size={40} round />
                                </TwitterShareButton>
                                <LinkedinShareButton
                                    url={window.location.href}
                                    title={sharingMessage}
                                    summary={sharingMessage}
                                >
                                    <LinkedinIcon size={40} round />
                                </LinkedinShareButton>
                            </div>
                            </div>
                        </div>
                    )}

                </div>
            }
            {inProgress && <div className='typing_loader inline-el'></div>}
        </div>
    );
};