// import {fetchData} from '../../../context/firebase/firebaseRequests';
import {getCacheManager} from './getCacheManager';
import {getConvertedObjectToArray} from "../../common/utils";

const SUBJECTS_KEY = 'subjects';
const {hasCache, getCache, setCache} = getCacheManager();

export const getSubjects = async () => {
    const isCache = hasCache(SUBJECTS_KEY);
    // if (!isCache) {
    //     const dbData = await fetchData(SUBJECTS_KEY);
    //     setCache(SUBJECTS_KEY, getConvertedObjectToArray(dbData.val()));
    // }
    return getCache(SUBJECTS_KEY);
}