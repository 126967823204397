import { TYPES_VOICE_ELEVENLABS, TYPES_VOICE } from '../../constant';
export const getModelFromAudioId = (defaultValue) => {
    const allVoices = [...TYPES_VOICE_ELEVENLABS, ...TYPES_VOICE];
    const foundVoice = allVoices.find(voice => voice.value === defaultValue);

    if (foundVoice) {
        return foundVoice;
    } else {
        return { value: defaultValue, model: 'custom-voice' };
    }
}
