import {cloneDeep} from 'lodash';

const USER_PAYMENTS_PATH = 'userPayments/';
const PAYMENTS_PATH = 'payments/';

export const updatePayerPayments = async (list, userData, uid) => {
    return new Promise(resolve => {
        const count = list.length - 1;
        let spent = userData?.spent || 0;

        list?.forEach(async (item, i) => {
            const userCost = parseFloat(item.payment.userCost);
            const clone = cloneDeep(item.payment);
            delete clone.id;
            clone.wasAddedToSpent = true;
            const updatedPayment = {};
            updatedPayment[PAYMENTS_PATH + item.payment.id] = clone;
            try {
                spent = spent + userCost;
            } catch (e) {
                console.warn(e);
            }
            if (i === count) {
                const userBalanceUpdate = {};
                userBalanceUpdate[`${USER_PAYMENTS_PATH}${uid}/spent`] = spent;
                resolve();
            }
        });
    });
}
