import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { checkTaskIconWithString } from '../../../common/utils';
import { getTitle } from '../../../common/utils';
import { AnswerComponent } from '../../../result/Answer.component/Answer.component';

export const ResultPreviewComponent = ({ result, localePrefix, options, event }) => {
    const { formatMessage } = useIntl();
    const task = event ? event?.tasks.find(el => el.id === result?.task?.id) : result?.task;
    return (
        <div>
            <div>
                <h3>
                    <Link className="link-h3" to={`${localePrefix}/tutorials/${task?.alias}-${task?.id}`}>
                        {result.task.title}
                    </Link>
                </h3>
                <small>{checkTaskIconWithString(result.state, formatMessage)} </small>
                
                {new Date(result.createDate).toLocaleString('en-GB', options)}
                <p>
                    {result.description && ` Notes: ${result.description}`}
                </p>
            </div>

            <ul>
                {result.answers?.map((answer, answerIndex) => (
                    <div key={answerIndex} className="wrapper_content-section">
                        <Link className="link-secondary" to={`${localePrefix}/tutorials/${task?.alias}-${task?.id}?step=${answer?.question?.id}`}>
                            {getTitle(answer.question?.question?.replace(/#/g, ''))}
                        </Link>
                        <AnswerComponent
                            key={answer?.result?.id}
                            answer={answer}
                            student={result.user}
                        />
                    </div>
                ))}
            </ul>

            <hr />
        </div>
    );
};