import { gql, useLazyQuery } from "@apollo/client";

export function useGetFilter() {
    const [getFilter, {data, loading, error}] = useLazyQuery(GET_FILTER, {
        fetchPolicy: "no-cache"
    });

    return {
        getFilter,
        filter: data?.filter,
        loading,
        error
    };
}

const GET_FILTER = gql`
    query GetFilter {
        filter {
            level {
                id
                levelUid
                title
            }
            subjects {
                id
                title
                subjectUid
            }
        }
    }
`;