import {useMutation} from '@apollo/client';
import {CreateCertificate} from '../graphQL/mutations/CreateCertificate';

export const useCreateCertificate = (mutation = CreateCertificate) => {
    const [createCertificate, { data, error, loading }] = useMutation(mutation);

    return {
        createCertificate,
        data,
        error,
        loading
    };
}