import './scss/index.scss'
import React from 'react';
import { Link } from 'react-router-dom';
import { Svg, CircleIndexComponent, TagComponent } from '../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import { CertificateLogo } from './Certificate.component/CertificateLogo';

export const CertificateCard = ({
    achievement,
    user,
    event,
    workspace,
    isEmbed = '',
    localePrefix }) => {

    const certificateLink = `${localePrefix}/${isEmbed}certificate/${achievement.id}`

    return (
        <Link
            className='answer-component Answer_container card-promo certificate-card'
            to={{ pathname: certificateLink, state: { workspace } }}
            style={{ backgroundColor: !!event?.tags?.length && event?.tags[0]?.color + '1c' }}
        >
            <div className='answer-component_card'>
                <div className='task_listcontent'>
                    <div className='task-component__info'>
                        <div className='task-component_header'>
                            <CircleIndexComponent icon={<FontAwesomeIcon icon={faCertificate} className="certificate-info-component__svg" style={{ width: '20px' }} />} />
                            <div className='task-component__text'>
                                <h4 className='Answer__title'>{achievement?.title}</h4>
                                <p className='Answer__title'> {achievement?.description}</p>
                                <div className='question-actions'>
                                    <div className='task-component__tags'>
                                        {event?.tags &&
                                            event.tags?.map((tag) => (
                                                <TagComponent
                                                    key={tag.id}
                                                    tag={tag}
                                                    countResults={tag?.countResults &&
                                                        { all: 20, done: tag?.countResults || 0, }}

                                                />

                                            ))}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="certificate-badge " >
                        <div className="flag_small" />
                        <div className='certificate-badge_logo'>
                            <CertificateLogo workspace={workspace} />
                        </div>
                    </div>
                </div>
            </div>
        </Link>

    );
};



{/* <h3>Certificate of Achievement</h3>
<p>Congratulations {user.name}, you have achieved: {achievement.name}</p>
<div className="certificate-details">
    <p>Date Issued: {achievement.dateIssued}</p>
    <p>Issued by: {achievement.issuer}</p>
</div> */}

