import './scss/index.scss';
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { openNotification } from '../../notification/Notification.component';
import { useIntl } from 'react-intl';
import { useSendEmail, useHtmlEmail } from '../../resend';
import { Svg, EmailPreviewComponent } from '../../common';
const { TextArea } = Input;

export const CustomMessageFormContainer = (props) => {
    const { user, workspace, emails } = props;
    const [form] = Form.useForm();
    const [recipients, setRecipients] = useState(emails);
    const [messageContent, setMessageContent] = useState('');
    const [inProgress, setInProgress] = useState();
    const { formatMessage } = useIntl();
    const { sendEmail } = useSendEmail();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { getCustomHtml } = useHtmlEmail();
    useEffect(() => { setRecipients(emails); }, [emails]);

    const { title } = workspace || {};
    const emailHtml = getCustomHtml({ title, content: messageContent, user });

    const handleSendCustomMessage = async () => {
        setInProgress('Message is sending');
        if (recipients.length && messageContent) {
            const resp = await sendEmail(recipients, emailHtml, `Message from ${title}`);
            const { error } = resp;

            if (!error) {
                openNotification('topLeft', {
                    title: formatMessage({ id: 'notif_message_sent' }),
                    description: `Message sent to: ${recipients?.join(', ')}`,
                    icon: '💬',
                    duration: 10,
                });
                form.resetFields();
                setRecipients([]);
                setMessageContent('');
            } else {
                openNotification('topLeft', {
                    title: formatMessage({ id: 'notif_error' }),
                    description: error?.message,
                    icon: '❌',
                    duration: 10,
                });
            }
        }
        setInProgress();
        setIsModalVisible(false);
    };

    const handleInputChange = (e, index) => {
        const newRecipients = [...recipients];
        newRecipients[index] = e.target.value;
        setRecipients(newRecipients);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        form.setFieldsValue({ recipients });
    }, [recipients, form]);

    return (
        <>
            <Button
                className='inline-el margin-right'
                type='secondary'
                size="small"
                onClick={showModal}
            >
                <Svg
                    fill='#ff5f49'
                    name=':icon/email'
                    className='btn-icon icon_16'
                />
                {formatMessage({ id: "message_btn" })}
            </Button>
            <Modal
                title={formatMessage({ id: "message_title" })}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    onFinish={handleSendCustomMessage}
                    form={form}
                    initialValues={{ recipients: emails }}
                >
                    <Form.List name="recipients">
                        {(fields, { add, remove }) => (
                            <div className='events-list-edit-component__list'>
                                {fields.map((field, index) => (
                                    <div
                                        key={index}
                                        className='edit-task-component__question__option__body'
                                    >
                                        <Form.Item
                                            key={field.key}
                                            validateTrigger={['onChange', 'onBlur']}
                                            className='grow_input'
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: formatMessage({ id: 'enter_title_warning' }),
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={formatMessage({ id: 'title_input_text' })}
                                                onChange={(e) => handleInputChange(e, index)}
                                                value={recipients[index]}
                                            />
                                        </Form.Item>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => {
                                                    remove(field.name);
                                                    setRecipients(prevRecipients => prevRecipients.filter((_, i) => i !== field.name));
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="link"
                                        onClick={() => add()}
                                        size="small"
                                    >
                                        <Svg
                                            fill='#ff5f49'
                                            name=':icon/add'
                                            className='btn-icon icon_16'
                                        />
                                    </Button>
                                </Form.Item>
                            </div>
                        )}
                    </Form.List>
                    <Form.Item
                        name="messageContent"
                        rules={[{ required: true, message: formatMessage({ id: "enter_title_warning" }) }]}
                    >
                        <TextArea
                            rows={4}
                            placeholder={formatMessage({ id: "custom_message_placeholder" })}
                            onChange={(e) => setMessageContent(e.target.value)}
                        />
                    </Form.Item>

                    <EmailPreviewComponent
                        list={recipients?.join(', ')}
                        emailHtml={emailHtml}
                        workspace={workspace}
                        formatMessage={formatMessage}
                    />
                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={inProgress}>
                            {formatMessage({ id: "message_title" })}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};