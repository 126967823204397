import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {useOwnerData} from '../../common';
import {AiAudioTokens} from '../graphQL/mutations/AiAudioTokens';

export const useAiAudioTokens = (mutation = AiAudioTokens) => {
    const [trackTokens, {loading, error, data}] = useMutation(mutation);
    const getOwnerData =  useOwnerData();

    const trackAudioTokens = useCallback(async (tokens) => {
        const ownerData = await getOwnerData();
        const owner = ownerData?.ownerId ? ownerData : {ownerId: ownerData?.customerId};
        const resp = await trackTokens({
            variables: {
                input: {
                    owner,
                    tokens
                }
            }
        });
        return resp;
    }, [trackTokens]);

    return {
        loading,
        error,
        trackAudioTokens,
        data
    }
}