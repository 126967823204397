/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";
import {noop} from 'lodash';
import {Loader} from "../../Loader";
import {getSortedData, getSubjectsSource} from "../utils";
import {ProgramsThemeComponent} from "../ProgramsTheme.component";

export const ProgramsThemesContainer = (props) => {
    const {
        selectedLevel,
        subject,
        subjects,
        selectedSubjects,
        intervalId,
        update,
        onAfterRender = noop,
        isAdditional = true,
        ...other} = props;

    const [themes, setThemes] = useState(null);
    const loading = useRef(false);

    const getThemesData = () => {
        loading.current = true;
        const dataThemes = subject?.themes || [];
        const data = isAdditional ? dataThemes : getSubjectsSource(dataThemes, selectedLevel, intervalId);
        setThemes(getSortedData(data, 'order'));
        loading.current = false;
        onAfterRender();
    };

    useEffect(() => {
        if (!loading.current) {
            getThemesData();
        }
    }, [subjects]);

    useEffect(() => {
        if (!loading.current && update) {
            getThemesData();
        }
    }, [update]);

    if (loading.current) {
        return <Loader/>;
    }

    return (themes && themes.map((theme, key) => {
        return (
            <ProgramsThemeComponent key={key}
                                    {...other}
                                    theme={theme}/>
        )
    }));
};
