import {Node} from '@tiptap/core'

export const Video = Node.create({
    name: 'video',
    group: 'block',
    content: "block+",
    draggable: true,
    atom: true,

    addOptions() {
        return {
            HTMLAttributes: {
                className: 'tiptap-video-wrapper',
            }
        }
    },

    addAttributes() {
        return {
            src: {
                default: null,
            },
            className: {
                default: 'tiptap-video'
            },
            controls: {
                default: true
            },
            controlsList: {
                default: 'nodownload'
            },
            width: {
                default: ''
            }
        }
    },

    parseHTML() {
        return [{
            tag: 'video',
        }]
    },

    renderHTML({HTMLAttributes}) {
        return ['div', this.options.HTMLAttributes, ['video', HTMLAttributes]]
    },

    addCommands() {
        return {
            setVideo: (options) => ({tr, dispatch}) => {
                const {width} = options;
                const {selection} = tr;
                const node = this.type.create(options);
                if (width) {
                    node.attrs.width = width;
                }
                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node);
                }
                return true;
            }
        }
    },
})