import {useCallback, useContext} from 'react';
import {useLazyQuery} from '@apollo/client';
import {AiTokensStatistics} from '../graphQL/quries/AiTokensStatistics';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const useAiTokensStatistics = (query = AiTokensStatistics) => {
    const {user} = useContext(FirebaseContext);
    const {id} = user || {};
    const [getTokensData, {data, error, loading}] = useLazyQuery(query, {
        fetchPolicy: "no-cache"
    });

    const getAiTokensStatistics = useCallback(async (args) => {
        const {ownerId, customerId, customerIds, workspaceId, from, to} = args || {};
        const resp = await getTokensData({
            variables: {
                input: {
                    ...(ownerId && {ownerId}),
                    ...(customerId && {customerId}),
                    ...(customerIds && {customerIds}),
                    ...(workspaceId && {workspaceId}),
                    ...(from && {from}),
                    ...(to && {to})
                }
            }
        });

        return resp;
    }, [getTokensData])

    return {getAiTokensStatistics, data, loading, error};
}
