import React, {
    useContext,
    useEffect,
    useState,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation, Link } from 'react-router-dom';

import {
    MetaComponent,
    useGetUrlParams,
    useHostNameUrl,
    useQueryParams,
} from '../Components/common';

import { useTaskDripLogic } from '../Components/task/hooks/useTaskDripLogic';
import { CertificateContainer, CertificationActions, useGetUserCertificate } from '../Components/certificates/';
import { Loader } from '../Components/Loader';
import { Thread } from '../Components/task/';
import { useLocalePrefix } from '../Components/lang';
import { FirebaseContext } from '../context/firebase/firebaseContext';
import { HeaderTutorial } from '../Components/headers/HeaderTutorial/HeaderTutorial';
import { useGetCertificate } from '../Components/certificates/hook';

export const Certificate = (props) => {
    const {
        match,
        inProgress,
        isEmbed = '',
        hostname,
        workspace,
        ...other } = props;

    const { user, loadingUser } = useContext(FirebaseContext);
    const { isSingleMode, isSingleLesson } = useQueryParams();
    const { getCertificate, loading: getLoading } = useGetCertificate();
    const { getUserCertificate } = useGetUserCertificate();
    const [certificate, setCertificate] = useState(null);
    const [userCertificate, setUserCertificate] = useState(null);

    const getData = async () => {
        const resp = await getCertificate({
            variables: {
                ...(id && { id }),
            },
            fetchPolicy: 'network-only'
        });
        if (resp?.data?.getCertificate) {
            setCertificate(resp.data.getCertificate);
        }
    };

    const { id, checkUrl } = useGetUrlParams(isEmbed);
    const workspaceUrl = useHostNameUrl(workspace);
    const { formatMessage } = useIntl();
    const [localePrefix] = useLocalePrefix();

    useEffect(() => {
        if (id) {
            (async () => {
                await getData();
            })()
        }
    }, [id]);

    useEffect(() => {
        if (certificate?.isAppliedForCurrentUser && user?.id) {
            (async () => {
                const resp = await getUserCertificate({
                    variables: {
                        certificateId: certificate?.id,
                        userId: user?.id
                    }
                });
                if (resp?.data?.getUserCertificate) {
                    setUserCertificate(resp.data.getUserCertificate);
                }
            })()
        }
    }, [certificate, user?.id]);

    const metaData = useMemo(() => {
        if (!certificate) { return null; }
        const { title, icon, event, id } = certificate;

        if (hostname || workspace) {
            const metaTitle = `${title} | ${workspace?.title}`;
            const description = `Learn course ${title}, created by ${event?.creator?.name} on ${workspace?.title}`;
            const url = `${workspaceUrl}${localePrefix}certificate/${id}`;
            const coverImage = icon;

            return {
                meta: {
                    meta_title: metaTitle,
                    meta_description: description,
                    meta_author: event?.creator?.name,
                    meta_icon: workspace?.logo,
                },
                og: {
                    type: 'article',
                    title: metaTitle,
                    image: coverImage,
                    description: description,
                    url: url,
                },
                canonical: url
            };
        } else {
            const metaTitle = `${title} | ${formatMessage({ id: 'certificate_title', })}`;
            const description = `Learn course "${title}", created by ${event?.creator?.name}: pass quizzes, make projects with a community and collect skills for the future.`;
            const url = `https://unschooler.me${localePrefix}/certificate/${id}/`;
            const coverImage = icon || workspace?.cover;

            return {
                meta: {
                    meta_title: metaTitle,
                    meta_description: description,
                },
                og: {
                    type: 'article',
                    title: metaTitle,
                    image: coverImage,
                    description: description,
                    url: url,
                },
            };
        }
    }, [certificate?.id]);

    const { taskResult, tagsRenew } = useTaskDripLogic(certificate?.event, null, null, null);
    if (loadingUser || !certificate) { return <Loader />; }
    const creatorOrAdmin = user?.role?.id === 1 || user?.id === certificate?.creatorId;

    return (
        <div className='certificate-page'>
            {metaData && (<MetaComponent meta={metaData.meta} og={metaData.og} />)}
            <div className='tasks-page page page_event'>
                <div className="task-article-component">
                    {(!isSingleMode && !isSingleLesson) &&
                        <div className="flex-right-column">
                            <Thread
                                event={{ ...certificate?.event, certificate: certificate }}
                                user={user}
                                taskResult={taskResult}
                                tagsRenew={tagsRenew}
                                {...other}
                            />
                        </div>
                    }
                    <div className="flex-column answer-results__form">
                        <section className="certificate-container">
                            {creatorOrAdmin &&
                                <HeaderTutorial
                                    type={"small"}
                                    event={certificate?.event}
                                    customComponent={CertificationActions}
                                    certificate={certificate}
                                    workspace={workspace}
                                    userIsAllow={creatorOrAdmin}
                                    user={user}
                                    {...other} />}

                            <CertificateContainer
                                certificate={{
                                    ...certificate,
                                    createDate: userCertificate?.createdDate,
                                    student: {
                                        id: certificate?.userId,
                                        name: userCertificate?.userName,
                                        picture: userCertificate?.user?.picture
                                    },
                                    userCertificateId: userCertificate?.id
                                }}
                                event={certificate?.event}
                                taskResult={taskResult}
                                tagsRenew={tagsRenew}
                            />

                            <div className='wrapper_outer-section'>
                                {certificate?.isAppliedForCurrentUser &&
                                    <Link className='link-btn-tertiary ant-btn ant-btn-tertiary block-el'
                                        to={{
                                            pathname: `${localePrefix}/${isEmbed}user-certificate/${userCertificate?.id}`,
                                            state: { workspace: workspace }
                                        }}>
                                        <span>{formatMessage({ id: 'event_btn_friend' })}</span>
                                    </Link>
                                }
                            </div>
                        </section>
                    </div>

                </div>
            </div>
        </div>
    );
};
