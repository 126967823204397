import { gql } from "@apollo/client";

export const GetUserIntegrations = gql`
    query GetUserIntegrations($userId: Int!, $workspaceId: Int!) {
        getUserIntegrations(userId: $userId, workspaceId: $workspaceId) {
            id
            userId
            integrations {
                id
                userId
                workspaceId
                partneroProgramId
                partneroApiKey
                userIntegrationId
            }
        }
    }
`;