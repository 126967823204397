const TIME_BEFORE_START = 10 * 60000;
const LIVE_PERIOD = 120 * 60000;

export const getIsLive = (item, now) => (item - TIME_BEFORE_START) <= now && (item + LIVE_PERIOD) >= now;

const getActualEvent = (data, now) => {
    const actualEvents = data.filter(item => item.type === 'WEEKLY' || new Date(item.date).getTime() > now);
    const nowDate = new Date(now);
    const nowDay = nowDate.getDay();
    const nowHours = nowDate.getHours();
    const event = actualEvents.find(ev => {
        const evDate = new Date(ev.dateUnix);
        const evDay = evDate.getDay();
        const evHours = evDate.getHours();
        return evDay > nowDay || evDay === nowDay && (evHours > nowHours || getIsLive(ev, now));
    });

    return event || actualEvents?.[0];
};

const getDate = (data) => {
    if (!data) return null;

    const {dateUnix, type} = data;
    const evDate = new Date(dateUnix);
    const evDay = evDate.getDay();
    const evHours = evDate.getHours();
    const evMinutes = evDate.getMinutes();
    const targetDate = new Date();
    const targetDay = targetDate.getDay();
    const delta = evDay - targetDay;
    const deltaDays = delta >= 0 ? delta : 7 + delta;
    targetDate.setDate(targetDate.getDate() + deltaDays);
    targetDate.setHours(evHours, evMinutes, 0);

    return {dateUnix: new Date(targetDate).valueOf(), type};
};

export const getCurrentEventDate = (data) => {
    if (!data) return null;

    const now = Date.now();
    const actualEvent = getActualEvent(data, now);
    const eventDate = getDate(actualEvent, now) || data[0];

    return {data: eventDate, isLive: getIsLive(eventDate, now)};
};
