import { gql, useMutation } from "@apollo/client";

export function useUpdatePreference() {
    const [mutateUpdatePreference, { data, loading, error }] = useMutation(UPDATE_PREFERENCE);
    
    async function updatePreference(preferenceData) {
        const result = await mutateUpdatePreference({
            variables: {
                preferenceData
            }
        });
        return result.data?.updatePreference;
    }

    return {
        preference: data?.updatePreference,
        loading,
        error,
        updatePreference
    }
}

const UPDATE_PREFERENCE = gql`
    mutation UpdatePreference($preferenceData: updatePreferenceInput!) {
        updatePreference(preferenceData: $preferenceData) {
            userId
        }
    }
`;