import {useCallback} from 'react';
import {useMutation} from '@apollo/client';
import {EditUserCertificate} from '../graphQL/mutations/EditUserCertificate';
import {GetUserCertificates} from '../graphQL/queries/GetUserCertificates';

export const useEditUserCertificate = (mutation = EditUserCertificate) => {
    const [editUserCertificateFn, { data, error, loading }] = useMutation(mutation);

    const editUserCertificate = useCallback(async (data, certificateId) => {
        await editUserCertificateFn({
            variables: {
                ...data
            },
            refetchQueries: () => [{query: GetUserCertificates, variables: {certificateId}}]
        })
    });

    return {
        editUserCertificate,
        data,
        error,
        loading
    };
}