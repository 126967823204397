import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { MetaComponent, TitleComponent } from '../Components/common';

export const TermsMentor = (props) => {
    const { formatMessage } = useIntl();

    const metaMemo = useMemo(() => {
        const title = formatMessage({ id: 'meta_title_terms' });
        const description = formatMessage({ id: 'meta_description_terms' });

        const meta = {
            meta_title: title,
            meta_description: description,
        };
        const og = {
            title: title,
            description: description,
        };

        const twitter = {
            tw_title: title,
            tw_description: description,
        };

        return { meta, og, twitter };
    }, []);

    return (
        <>
            <MetaComponent
                meta={metaMemo.meta}
                og={metaMemo.og}
                twitter={metaMemo.twitter}
            />

            <div className='page'>
                <TitleComponent title='Terms' back={true} />
                <div className='page-content'>
                    <h2>Public Offer</h2>
                    <ol>
                        <li>
                            This Public Offer is an official offer made by Unschooler OÜ, registry code 16264540, hereinafter referred to as the Platform, regarding the conclusion of a contract for the provision of services using the Unschooler Platform located at www.unschooler.me (hereinafter referred to as the Platform), and expresses the intention of Unschooler OÜ to conclude a services contract under the terms of this Public Offer with any legally capable person who will accept this Public Offer.
                        </li>
                        <li>
                            If you (hereinafter referred to as the User) are less than 18 years of age, or if you have not reached the age of majority according to the legislation of your country, we kindly ask you to forward this Public Offer to your parents or legal representatives for review.
                        </li>
                        <li>
                            By using and acquiring access to the Platform's resources, you confirm that you are a fully capable person, have understood, agree to, and accept all terms and conditions of this Public Offer, and that you have read and accepted all terms and conditions of the Privacy Policy and Rules as published on the Platform.
                        </li>
                        <li>
                            If you do not agree with any of the clauses of the Public Offer, Privacy Policy, and/or Rules, we kindly ask you not to use this Platform, not to create an account, and not to pay a fee for using the Platform's resources.
                        </li>
                        <li>
                            The Platform reserves the right to unilaterally amend and/or supplement this Public Offer, as well as the Privacy Policy and Rules, at any time.
                        </li>
                        <li>
                            Changes shall take effect after publication on the Platform. Actual use of the Platform's resources after the publication of documents with changes shall constitute your agreement to the changes made.
                        </li>
                        <li>
                            By accepting this Public Offer, you undertake to periodically review the current version of the Public Offer and Privacy Policy.
                        </li>
                    </ol>
                    <h2>1. Acceptance and Subject of the Contract</h2>
                    <ol>
                        <li>
                            Unschooler OÜ, registry code 16264540 (hereinafter referred to as the Platform), hereby concludes this Services Contract for the provision of access to and maintenance of the Unschooler interactive platform, located at www.unschooler.me (hereinafter referred to as the Platform).
                        </li>
                        <li>
                            Acceptance of the Public Offer shall mean full and unconditional acceptance of the Public Offer by performing actions specified in clauses 2.2-2.5 of this Contract. Should the User not agree with any of the provisions of this Public Offer, they shall not be entitled to use the Services.
                        </li>
                        <li>
                            The User shall be a natural or legal person who has accepted the terms and conditions of this Public Offer.
                        </li>
                        <li>
                            The Platform accepts no responsibility for the accuracy of information about themselves provided by Tutors.
                        </li>
                        <li>
                            This Contract shall be considered concluded in a simple written form from the moment the User passes the registration procedure and/or makes a payment on the Platform. The conclusion of this Contract shall be made by acceptance of the terms thereof by the User, not otherwise than by joining the proposed Contract in full without any reservations or exceptions.
                        </li>
                    </ol>
                    <h2>2. Students and Creator Role and Responsibilities</h2>
                    <ol>
                        <li>
                            Definition of a Creator: A "Creator" is a user who is authorized to create and sell courses to Users via the Unschooler Platform. Creators are granted access to a whitelabeled school page with a custom domain, which serves as their personal learning management platform.
                        </li>
                        <li>
                            Course Creation and Sales: Creators may develop their own courses and utilize the Platform's integration with Stripe to facilitate sales transactions. Personal accounts will be provided for financial management and tracking of sales.
                        </li>
                        <li>
                            Whitelabeled School Page: Each Creator will be provided with a whitelabeled school page, which can be personalized with their branding and used as a learning management system. This page can be linked to a custom domain, providing Creators with a unique online presence.
                        </li>
                        <li>
                            Account Integrity and Usage: Creators must use their accounts in a manner that upholds the integrity and purpose of the Platform. Misuse of account features, including the creation of multiple accounts for abusive purposes, is strictly prohibited.
                            s strictly prohibited.
                        </li>
                    </ol>
                    <h2>3. Course Creation Services</h2>
                    <ol>
                        <li>
                            The Service shall be rendered using the Platform's software and consist of providing access to AI-powered tutorials, tests, and courses. Some parts of these courses can be created by various AI models such as GPT-3.5 and GPT-4 from OpenAI. These educational materials are stored on our platform, allowing Creators to share them with students who visit the www.unschooler.me website or any other pages with a custom domain and accept the terms and conditions of this Public Offer (hereinafter referred to as the User).
                        </li>
                        <li>
                            The Platform's services are limited to facilitating access to AI-generated educational content and promoting communication between Users, Creators, and the AI models. The Platform shall ensure easy access to the materials.
                        </li>
                        <li>
                            The Platform shall not be responsible for the quality of the generated AI content. However, if a User is not satisfied with the service, we agree to refund the money for the paid subscription for the last month.
                        </li>
                        <li>
                            All content is not publicly available by default, but Users can publish, share, and distribute links at their discretion.
                        </li>
                        <li>
                            The Platform's services are dedicated to generating educational content for personal and professional development. Users are encouraged to create courses for their own needs or for others, provided the content does not violate the rights of third parties. It is strictly prohibited to use this website for purposes it is not intended for, including data generation, harmful content creation, product feature testing, or any other misuse of the trial period.
                        </li>
                        <li>
                            It is strictly prohibited to generate and publish malicious content on the platform, including but not limited to: any information that constitutes a violation of copyrights of third parties, slander, insults, or propaganda of hatred, discrimination, terror, or violence. The Platform reserves the right to moderate and delete published content if it contains any form of malicious material.
                        </li>
                        <li>
                            It is strictly prohibited to create multiple accounts or free trial subscriptions to abuse the system. The Platform reserves the right to delete any account that is suspected of abusing the system.
                        </li>
                        <li>
                            A User who violates these rules will be banned without a guarantee of a refund of the subscription fee.
                        </li>
                    </ol>
                    <h2>4. Course Publishing and Sales Services</h2>
                    <ol>
                        <li>
                            Course Setup and Pricing: Creators can sell courses on the Unschooler platform. Each course may be priced from $2 to $1000. Courses become available for sale ("live") once the Creator has set the price.
                        </li>
                        <li>
                            Selling Access: Creators can sell access to individual courses or entire schools through a one-time purchase or a subscription model. Creators must include or adhere to the default terms and conditions, privacy policy, contact information, cancellation, and refund policies for their schools or courses. Failure to do so will prevent the sale of courses on the platform.
                        </li>
                        <li>
                            Moderation and Approval:
                            <ul>
                                <li>All paid courses undergo an initial automatic moderation process. This ensures that courses comply with copyright laws and platform content guidelines before they become available for sale.</li>
                                <li>After a course with a set price is published, it initially enters a state "In Moderation". In this state, the “Buy” button for the course is disabled, preventing any transactions.</li>
                                <li>Moderators at Unschooler manually review these courses. They check the sources and content of the paid courses to ensure compliance with copyright and platform guidelines.</li>
                                <li>Once a course passes this manual review, the moderator updates its status to "Approved". At this point, the “Buy” button becomes enabled, allowing students to purchase the course.</li>
                            </ul>
                        </li>
                        <li>
                            Student Access: Students access courses by creating an account on Unschooler and completing the payment process. Access to courses is granted immediately after payment, enabling students to start learning without delay.
                        </li>
                        <li>
                            Financial Transactions and Management: Course purchase    and management: Course purchase payments are processed through Stripe, directly into the Creator’s Stripe account. Creators manage their earnings, including issuing refunds and canceling subscriptions as needed.
                        </li>
                        <li>
                            Intellectual Property: Creators must ensure their courses do not infringe on intellectual property rights and comply with all applicable laws and platform policies.
                        </li>
                        <li>
                            Data Privacy and Security: Creators are required to follow all relevant data protection laws, ensuring the privacy and security of student data.
                        </li>
                        <li>
                            Dispute Resolution: Creators agree to engage in the platform's dispute resolution process for complaints or refunds, aiming for amicable resolutions.
                        </li>
                    </ol>
                    <h2>5. Rights and Obligations of the Parties</h2>
                    <ol>
                        <li>
                            The Platform hereby undertakes to:
                            <ol>
                                <li>Monitor resource operation quality.</li>
                                <li>Not disclose confidential information and data provided by the User in connection with this Contract's performance, nor disclose or make such facts or information public (except for information that is public or provided by the User during registration) to any third party without the User's prior written consent.</li>
                                <li>Immediately notify the User about any force majeure circumstances that may affect the services' volume and quality, using the e-mail specified by the User upon registration.</li>
                                <li>Enable Users to independently publish information about themselves. The Platform reserves the right to moderate User Profiles for rule violations.</li>
                            </ol>
                        </li>
                        <li>
                            The Platform has the right to:
                            <ol>
                                <li>Request timely payment for Services from the User in accordance with this Contract.</li>
                                <li>Use the email address, phone number, and other data provided by the User during registration to send information and advertising materials, including updates related to this Contract.</li>
                                <li>Process the User's personal data for fulfilling this Contract, by registering and/or using the Services, Users consent to their personal data being processed by the Platform or third parties.</li>
                                <li>Refuse service provision and extend it if impossible for objective or subjective reasons.</li>
                            </ol>
                        </li>
                        <li>
                            The User hereby undertakes to:
                            <ol>
                                <li>Provide identification data (enter a username and password on the authorization page) or log in via a social network to start using the Platform.</li>
                                <li>Pay for the membership Services as detailed in section 5.</li>
                                <li>Ensure an uninterrupted Internet connection and the availability of necessary hardware and software meeting the minimum system requirements.</li>
                                <li>Not publish any information on the Platform that violates copyrights, slanders, insults, or propagates hatred, discrimination, terror, or violence.</li>
                            </ol>
                        </li>
                        <li>
                            The User has the right to:
                            <ol>
                                <li>Require the Platform to fulfill its obligations under this Contract timely and with the quality described in the Services' description, unless stated otherwise in this Contract.</li>
                                <li>Request deletion of personal data (tutorials, courses, personal authorization data) according to the Privacy Policy.</li>
                                <li>Create AI-powered tutorials, tests, and courses for personal or educational purposes.</li>
                                <li>Receive a video recording of a missed class to the email specified upon registration, provided the class was paid for.</li>
                                <li>Terminate this Contract if disagreeing with changes made according to this Agreement.</li>
                                <li>Request a refund for the last month after canceling a subscription if dissatisfied with the service. Refunds are limited to one request via email. Premium Membership is automatically canceled upon refund.</li>
                                <li>Contact the Platform's technical support with recommendations, comments, and suggestions through the feedback forms on the Website.</li>
                            </ol>
                        </li>
                    </ol>
                    <h2>6. Compliance and Penalties</h2>
                    <ol>
                        <li>
                            Intellectual Property Protection:
                            <ol>
                                <li>The design and software code of the Platform constitute intellectual property.</li>
                                <li>Actions and/or inaction of the User resulting in violation of the Platform's or third parties' rights to intellectual property objects shall entail liability in accordance with the law. In such cases, the Platform is entitled to delete the User's profile from the Platform without prior notification.</li>
                                <li>AI-generated content that was generated without an additional source is the property of the Unschooler company. Unschooler grants commercial usage rights of the content exclusively to users who have paid for a subscription at the time of generation (this does not extend to refunds and free trials).</li>
                                <li>AI-generated content that was generated with an additional source from the Creator can be used by the creator only after paying for a subscription and does not apply to refunds and free trials.</li>
                                <li>Creators are responsible for ensuring that the content of their courses does not infringe on any third-party intellectual property rights. The Platform emphasizes the importance of original and legally compliant content.</li>
                            </ol>
                        </li>
                        <li>The Platform can recommend YouTube videos as additional material. Courses with these materials must be used for free according to the third-party rules.</li>
                        <li>Creators must not sell courses to access the school with any courses that violate intellectual property law, including YouTube videos embedded in the paid courses.</li>
                        <li>
                            Penalties for Non-Compliance: In the event that a Creator sells content that violates intellectual property laws or the Platform's terms, the following actions will be taken: Immediate suspension of the Creator's account, permanent banning of the Creator from the Platform without a refund, and deletion of all content associated with the Creator that is found to be in violation.
                        </li>
                        <li>IP Infringement Report Process: Third parties who believe their intellectual property rights have been infringed can submit an <a href="http://unschooler.me/tutorials/ip-infringement-report-323541">Infringement Report</a>.</li>

                    </ol>

                    <h2>7. Cost of Services and Payment Procedure</h2>
                    <ol>
                        <li>The payment system introduces two premium membership options, granting access to features as described on the unschooler.me/payments page.</li>
                        <li>Subscriptions will automatically renew until the User cancels their plan via the unschooler.me/payments page.</li>
                        <li>Following membership cancellation, the User will retain access to membership features until the date of their next scheduled payment. However, if the User obtained the membership for free through a promo code, access to membership features will be terminated immediately upon cancellation.</li>
                        <li>In the event of dissatisfaction with the quality of service, the User may request a refund for their last payment by emailing us. Please note that refunds can only be requested once per User.</li>
                    </ol>

                    <h2>8. Liability of the Parties</h2>
                    <ol>
                        <li>All disputes or disagreements shall be resolved through negotiations. The wronged Party shall send a written claim (request) to the other Party by e-mail.</li>
                        <li>If, within fifteen (15) calendar days, the Party who submitted the claim does not receive a response, and also if, within fifteen (15) calendar days from the date of receipt of the first response to the claim, the Parties fail to come to an agreement, the wronged Party has the right to appeal to court at the Platform's location to defend its rights.</li>
                        <li>The applicable substantive and procedural law is the law of Estonia.</li>
                    </ol>

                    <h2>9. Addresses, Details of the Parties</h2>
                    <ul>
                        <li>The Parties unconditionally agree to consider the information specified by the User during authorization to be the User's details.</li>
                        <li>Unschooler OÜ</li>
                        <li>Harju maakond, Tallinn, Kesklinna linnaosa, Ahtri tn 12, 10151</li>
                        <li>Email: info@unschooler.me</li>
                    </ul>
                </div>
            </div>
        </>
    );
};
