import { useMutation } from '@apollo/client';
import {CreateConnectedAccount} from '../graphQL/mutations/CreateConnectedAccount';

export function useCreateConnectedAccount(mutation = CreateConnectedAccount) {
    const [createConnectedAccount, { data, error, loading }] = useMutation(mutation);

    return {
        data,
        error, loading,
        createConnectedAccount
    };
}
