import {gql, useLazyQuery} from '@apollo/client';

export const useGetCareers = (options = {}, query = GET_CAREERS) => {
    const [getCareers, {data, loading, error}] = useLazyQuery(query, options);

    return {
        getCareers,
        careers: data?.careers,
        loading,
        error
    };
};

const GET_CAREERS = gql`
    query Careers($specId: Int!, $userId: Int) {
        careers(specId: $specId, userId: $userId) {
            careers {
                id
                specId
                title
                description
                salaryMin
                salaryMax
                counter
                
            }
            tags {
                id
                alias
                title
                icon
                defIcon
                countResults
            }
        }
    }
`;
