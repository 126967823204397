import {useLazyQuery} from '@apollo/client';
import {TextToAudio} from '../graphQL/queries/TextToAudio';

export const useTextToAudio = (query = TextToAudio) => {
    const [getAudioUrl, { data, loading, error }] = useLazyQuery(query);
    return {
        getAudioUrl,
        loading, error,
        data
    }
}
