import { gql } from '@apollo/client';

export const CreateCertificate = gql`
    mutation CreateCertificate($input: CreateCertificateInput) {
        createCertificate(input: $input) {
            id
            title
            description
            label
            icon
            type
            creatorId
            eventId
            event {
                id
            }
            users {
                id
                userName
            }
        }
    }
`;