import {useCallback} from 'react';
import {useMutation} from '@apollo/client';
import {EditWorkspace} from '../graphQL/mutations/EditWorkspace';

export const useEditWorkspace = (mutation = EditWorkspace) => {
    const [editWorkspaceFn, {data, error, loading}] = useMutation(mutation);

    const editWorkspace = useCallback(async (data, id) => (editWorkspaceFn({
        variables: {
            input: data,
            id
        }
    })), [editWorkspaceFn]);

    return {
        editWorkspace,
        workspace: data?.editWorkspace,
        error,
        loading
    }
}