import React, {useContext, useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {PaypalAccountComponent} from '../PaypalAccount.component';
import {openNotification} from '../../notification/Notification.component';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {useCreatePaypalAccount, useGetPaypalAccount, useRemovePaypalAccount} from '../hooks';


export const PaypalAccountContainer = () => {
    const {workspace, onUpdateWorkspace, user} = useContext(FirebaseContext);
    const {formatMessage} = useIntl();
    const {id: workspaceId, paypalAccountId} = workspace || {};
    const {id: userId} = user || {};
    const {data: creatAccountData, loading: createAccountLoading, createPaypalAccount} = useCreatePaypalAccount();
    const {data: getAccountData, loading: getAccountLoading, getPaypalAccount} = useGetPaypalAccount();
    const {loading: removeAccountLoading, removePaypalAccount} = useRemovePaypalAccount();
    const {id, clientId, workspaceIds} = getAccountData?.getPaypalAccount || {};
    const {workspaceIds: createdWorkspaceIds} = creatAccountData?.createPaypalAccount || {};

    const isMultipleConnection = useMemo(() => (
        workspaceIds?.length > 1 || createdWorkspaceIds?.length > 1
    ), [workspaceIds, createdWorkspaceIds]);

    const handleAddAccount = async (clientId, secret) => {
        const resp = await createPaypalAccount({
            variables: {
                workspaceId,
                userId,
                secret,
                clientId
            }
        });

        const {workspaceIds} = resp?.data?.createPaypalAccount || {};

        openNotification('topLeft', {
            title: formatMessage({id: 'Create PayPal account'}),
            description: formatMessage({id: workspaceIds?.[0] === workspaceId
                    ? 'paypal_create_account_success_create'
                    : 'paypal_create_account_success_connect'}),
            icon: `👩‍🚀`,
            duration: 3,
        });
        await onUpdateWorkspace();
    };

    const handleRemoveAccount = async () => {
        const wIds = workspaceIds?.filter((wId) => wId !== workspaceId);
        await removePaypalAccount({
            variables: {
                id,
                workspaceId,
                ...(wIds?.length ? {workspaceIds: wIds} : {isRemove: true})
            }
        });

        openNotification('topLeft', {
            title: formatMessage({id: 'paypal_remove_account_title'}),
            description: formatMessage({id: wIds?.length ? 'paypal_remove_account_disconnect_message' : 'paypal_remove_account_delete_message'}),
            icon: `👩‍🚀`,
            duration: 3,
        });
        await onUpdateWorkspace();
    };

    useEffect(() => {
        if (!!paypalAccountId) {
            (async () => await getPaypalAccount({
                variables: {
                    id: paypalAccountId
                }
            }))();
        }
    }, [paypalAccountId]);

    return (
        <PaypalAccountComponent isCreateMode={!paypalAccountId}
                                createAccountLoading={createAccountLoading}
                                getAccountLoading={getAccountLoading}
                                removeAccountLoading={removeAccountLoading}
                                isMultipleConnection={isMultipleConnection}
                                paypalAccountId={clientId}
                                onAddAccount={handleAddAccount}
                                onRemoveAccount={handleRemoveAccount}/>
    )
}