import React, {useMemo} from 'react';
import {noop} from 'lodash';
import classnames from 'classnames';
import {DropdownComponent} from '../../common';

const EXCLUDED = [];

export const LangSwitcherComponent = (props) => {
    const {lang, langs, onChange = noop, classNames = '', optionLabel = 'code', ...other} = props;

    const langsData = useMemo(() => !langs ? null : langs?.filter((l) => !EXCLUDED.includes(l.code)), [langs]);

    if (!langs) {
        return null;
    }

    const switcherClassNames = classnames('lang-switchet-component', classNames);

    return (
        <div className={switcherClassNames}>
            <DropdownComponent
                {...other}
                options={langsData}
                optionKey="id"
                optionLabel={optionLabel}
                currentValue={lang?.id || 2}
                onChange={onChange}
            />
        </div>
    );
};
