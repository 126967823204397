import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import translate from '../../../lang/translate';
import { Svg } from '../../common';
import { useLocalePrefix } from '../../lang';

export const AddTaskButtonComponent = (props) => {
    const {
        hasExtraClass = false,
        isShortLabel = false,
        type = "secondary",
        size = "medium",
        block,
        eventId,
        disabled = false
    } = props;

    const history = useHistory();
    const [localePrefix] = useLocalePrefix();

    return (
        <Button
            type={type}
            size={size}
            onClick={() => history.push(`${localePrefix}/edit-task/`, { eventId: eventId, })}
            className={'btn-left ' + hasExtraClass }
            disabled={disabled}
        >
            <span>{translate('form_add_task_to_event')}</span>
        </Button>
    );
};
