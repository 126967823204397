import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { MetaComponent } from '../Components/common';
import { EventsContainer } from '../Components/events/Events.container/Events.container';

export const Events = (props) => {
    const { formatMessage } = useIntl();

    const metaMemo = useMemo(() => {
        const title = formatMessage({ id: 'meta_title_courses' });
        const description = formatMessage({ id: 'meta_description_courses' });

        const meta = {
            meta_title: title,
            meta_description: description,
        };
        const og = {
            title: title,
            description: description,
        };

        const twitter = {
            tw_title: title,
            tw_description: description,
        };

        return { meta, og, twitter };
    }, []);


    return (
        <>
            <MetaComponent
                meta={metaMemo.meta}
                og={metaMemo.og}
                twitter={metaMemo.twitter}
            />
  
            <EventsContainer props={props} />
        </>
    );
};
