import {gql} from "@apollo/client";

export const EditQuestion = gql`
    mutation EditQuestion($input: QuestionEditInput!) {
        editQuestion(input: $input) {
            id
            title
            question
            audioQuestion
            explanation
            answer
            options {
                id
                text
                isCorrect
                order
            }
            cover {
                id
                type
                source
            }
            order
            type
        }
    }
`;