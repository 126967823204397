import React from 'react';
import {Modal} from 'antd';
import { useIntl } from 'react-intl';

export const EmailVerificationComponent = (props) => {
    const {email, showModal, onConfirm, onCancel, messageId,} = props;
    const { formatMessage } = useIntl();
    return (
        <Modal title="Email verification"
               visible={showModal}
               okText="Verify email"
               onOk={onConfirm}
               onCancel={onCancel}>
            <p>
                {formatMessage({id: messageId})} <strong>{email}</strong>
            </p>
        </Modal>
    )
}