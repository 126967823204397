/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react';
import {ProgramsSubjectIconComponent} from '../ProgramsSubjectIcon.component';
import {ProgramsSubjectHeaderComponent} from '../ProgramsSubjectHeader.component';
import {ProgramIntervalComponent} from '../ProgramInterval.component';
import {getSelectedSubjects} from '../utils';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {getCacheManager} from '../../common/utils';

const PROGRESS_CACHE_PREFIX = 'cache-progress-';

export const ProgramsSubjectsContainer = (props) => {
    const {
        selectedLevel,
        subjects,
        selectedSubjects,
        ...other
    } = props;

    const {user} = useContext(FirebaseContext);
    const [progressData, setProgressData] = useState(null);
    const {hasCache, getCache, setCache, removeCache} = getCacheManager();
    const [subjectsThemes, setSubjectsThemes] = useState(null);
    const cacheKey = PROGRESS_CACHE_PREFIX + user.id;

    const onSetSubjects = () => {
        let subjectsData = selectedSubjects.length ? getSelectedSubjects(subjects, selectedSubjects) : subjects;
        onCollectData(subjectsData);
    };

    const onCollectData = async (subjectsData) => {
        const subjectsThemes = [];

        setSubjectsThemes(subjectsThemes)
    };


    const handleUpdateProgress = (data) => {
        removeCache(`PROGRESS_PREFIX${user.id}`);
        onUpdateProgress();
    };

    const onUpdateProgress = async () => {
        const isCache = hasCache(cacheKey);

        setProgressData(getCache(cacheKey));
    };

    useEffect(onSetSubjects, [selectedSubjects]);
    useEffect(() => {
        (async () => await onUpdateProgress())();
    }, []);

    return (
        <div className='scroll-container'>
            {/* <h1 className='supertitle'>Моя программа</h1> */}
            <div className='program-subject program-subject_icon'>
                {subjectsThemes &&
                    subjectsThemes.map((subject, key) => (
                        <ProgramsSubjectIconComponent
                            key={key}
                            subject={subject}
                            {...other}
                        />
                    ))}
            </div>
            <div className='program-subject program-subject_header'>
                {subjectsThemes &&
                    subjectsThemes.map((subject, key) => (
                        <ProgramsSubjectHeaderComponent
                            key={key}
                            subject={subject}
                            progressData={progressData}
                            {...other}
                        />
                    ))}
            </div>
                    <ProgramIntervalComponent
                        selectedLevel={selectedLevel}
                        progressData={progressData}
                        onUpdateProgress={handleUpdateProgress}
                        subjects={subjects}
                        subjectsThemes={subjectsThemes}
                        selectedSubjects={selectedSubjects}
                        {...other}
                    />
        </div>
    );
};
