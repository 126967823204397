// import {fetchData} from '../../../context/firebase/firebaseRequests';
import {getCacheManager} from './getCacheManager';

const CACHE_KEY = 'cache-teacher-';
const {hasCache, getCache, setCache} = getCacheManager();

export const getIsTeacher = async (id) => {
    const key = CACHE_KEY + id;
    const isCache = hasCache(key);
    if (!isCache) {
        // const dbData = await fetchData(`users/${id}/canTeach`);
        // setCache(key, dbData.val());
    }
    return getCache(key);
}