import React, { useContext, useMemo, useEffect, useState } from 'react';

import { EnrollComponent } from '../Enroll.component';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { useGetConnectedAccount, useCreateConnectedSession } from '../../stripe';
import { useCurrencyCode } from '../../common';
import { useConfirmEmailToken, useGetUserSubscriptions } from '../../user';

export const EnrollContainer = ({ event = {}, onLogin, subscribeInvited, ...other }) => {
    const { creator = {} } = event;
    const { id } = creator;
    const { data = {} } = useGetConnectedAccount(id);
    const { user, updateUserData, workspace } = useContext(FirebaseContext);
    const { id: userId, emailVerified } = user || {};
    const { createConnectedSession, loading } = useCreateConnectedSession();
    const { currencyCode } = useCurrencyCode();
    const { confirmEmailToken } = useConfirmEmailToken();
    const { stripeAccountId, stripeAccountIsActivated } = useMemo(() => (data?.getConnectedAccount || {}), [data]);

    const { getUserSubscriptions, data: userSubscriptionsData } = useGetUserSubscriptions();
    const [paymentError, setPaymentError] = useState();
    const workspaceSubscription = useMemo(() => (userSubscriptionsData?.getUserSubscriptions?.[0]), [userSubscriptionsData]);

    const isNotVerified = useMemo(() => {
        if (!userId) {
            return false;
        }
        return !emailVerified;
    }, [userId, emailVerified]);

    const handleCheckUser = async () => {
        const { id, email, emailVerified } = user;

        if (!emailVerified) {
        const invitedSubscription = event.userSubscriptions?.find(subscription => subscription.invitedEmail === email);
        if (!!invitedSubscription) {

            const resp = await confirmEmailToken({
                variables: {
                    token: 'invitedUser'
                }
            });

            if (resp?.data?.confirmEmailToken) {
                await updateUserData(id);
            }
        }
        }

        if (workspace) {
            await getUserSubscriptions({
                variables: {
                    input: {
                        studentId: id,
                        workspaceId: workspace.id
                    }
                }
            });
        }
    };

    const onCheckout = async () => {
        const w = window;
        const location = !!w.document.referrer ? w.document.referrer : w.location.href;

        try {
            const resp = await createConnectedSession({
                variables: {
                    input: {
                        stripeAccountId,
                        location,
                        eventId: event?.id,
                        currencyCode,
                        ...(workspace?.id && {workspaceId: workspace?.id})
                    }
                }
            });

            const { url, error } = resp.data.createConnectedSession;

            setPaymentError(!!error);

            if (url) {
                try {
                    if (w.document === w.parent.document) {
                        window.location.href = url;
                    }
                } catch (err) {
                    window.parent.location.href = url;
                }
            }
        } catch (err) {
            console.warn(err);
        }
    }

    const handleJoin = async () => {
        if (userId) {
            const invitedSubscription = event.userSubscriptions?.find(subscription => subscription.invitedEmail === user.email);
            if (invitedSubscription) {
                subscribeInvited(invitedSubscription);
            } else {
                onCheckout()
            }
        } else {
            onLogin({ afterLoginActions: { onEnrollAfterLogin: true } });
        }
    }

    useEffect(() => {
        if (user?.id) {
            (async () => await handleCheckUser())()
        }
    }, [user?.id]);

    useEffect(() => {
        if (stripeAccountId && stripeAccountIsActivated) {
            const returnBack = window.localStorage.getItem('onEnrollAfterLogin');
            if (returnBack) {
                (async () => {
                    await handleJoin();
                    window.localStorage.removeItem('onEnrollAfterLogin');
                })()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripeAccountId, stripeAccountIsActivated]);

    return <EnrollComponent {...other}
        user={user}
        event={event}
        paymentError={paymentError}
        workspaceSubscription={workspaceSubscription}
        loading={loading}
        isStripeConnected={stripeAccountIsActivated}
        isNotVerified={isNotVerified}
        stripeAccountId={stripeAccountId}
        onLogin={onLogin}
        onJoin={handleJoin} />
}
