import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from 'antd';

import { SketchP5Component } from '../../graph/SketchP5Component';
import { useTrackCredits } from '../../common';

import { VoiceSelector } from './VideoEditor.component/VoiceSelector'
import classnames from 'classnames';
import { AiAvatarComponent } from '../../ai/AiAvatar.component';
import { AIAvatarPlaceholderComponent } from '../../ai/AIAvatarPlaceholder.component/';
import { getAiAvatarFromEvent } from '../../event/util/getTeachersFromEvent';
import { useGetTeacher } from '../graphQL/teacher/useGetTeacher';

export const VideoCustomPlaceholder = ({ inProgress,
    handleRequest,
    user,
    userIsAllowGenerateContent,
    error,
    customTitle,
    event,
    buttonLabel = 'video_btn',
    onTeacherChange,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const containerClass = classnames({
        'videoContainerStyle video_preview': true,
        'fullscreen': other.type === 'fullscreen'
    });

    const aiAvatar = getAiAvatarFromEvent(event, user);
    const { teacher } = useGetTeacher(aiAvatar?.studentId || user?.id);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [isEnabled, setIsEnabled] = useState(false);
    const { isLimitReached } = useTrackCredits();

    const handleToggle = () => {
        if (isLimitReached) {
            window.location.href = 'https://unschooler.me/credits';
            return;
        } else {
            setIsEnabled(prevIsEnabled => {
                const newIsEnabled = !prevIsEnabled;
                onTeacherChange(newIsEnabled ? selectedTeacher : null);
                return newIsEnabled;
            });
        }
    };

    useEffect(() => {
        if (teacher) {
            setSelectedTeacher(teacher);
        }
    }, [teacher]);

    return (
        <div className={containerClass}>
            <div className='sliderContainerStyle'>
                <div className="slides_empty">
                    <SketchP5Component sketchIndex={2} />
                    <h2 className='slides_empty_message'>{customTitle}</h2>
           
                    {onTeacherChange && <div className='wrapper_inner-section'>
                        {isEnabled ? (
                            <AiAvatarComponent
                                source={selectedTeacher?.picture}
                                selectedTeacher={selectedTeacher}
                                type='AI'
                                event={event}
                                onGenerate={() => handleRequest(null)}
                                handleToggle={handleToggle}
                                {...other}
                            />
                        ) : (
                            <AIAvatarPlaceholderComponent
                                loading={inProgress}
                                isLimitReached={isLimitReached}
                                handleToggle={handleToggle}
                            />
                        )}

                    </div>}
                    <div className='wrapper_inner-section'>
                        <VoiceSelector {...other} />
                    </div>

                    {!inProgress &&
                        <Button
                            className="wrapper_content-section"
                            onClick={() => handleRequest(null)}
                            disabled={!userIsAllowGenerateContent}
                            type="tertiary">{formatMessage({ id: buttonLabel })}
                        </Button>}

                    {error && <small className='slides_empty_message'>{error}
                        <Link to={'/payments'}> {formatMessage({ id: 'payment_button' })}</Link>
                    </small>}

                </div>
            </div>
        </div>
    );
}