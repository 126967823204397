const STRIPE_PERCENT = 0.03;
const STRIPE_CONSTANT = 0;

const getPaidCost = (cost, count, commission) => {
    const paymentCost = 100 * (cost * (1 - (commission) / 100) / count - STRIPE_CONSTANT) * (1 - STRIPE_PERCENT);
    return Math.ceil(paymentCost) / 100;
};

export const getMentorCost = async (cost, count, comission) => {
    if (!cost) { return null; }
    const denominator = count || 1;
    return getPaidCost(cost, denominator, comission);
}