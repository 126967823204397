import React, {useState, useMemo, useEffect, useContext} from 'react';

import { JoinComponent } from '../Join.component/Join.component';
import { useLocalePrefix } from '../../lang';
import {useActivateInvite} from '../hooks';
// import {useConfirmEmailToken} from '../../user';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const JoinContainer = (props) => {
    const { workspace, history, onUpdateWorkspace, ...other } = props;
    const {user, updateUser} = useContext(FirebaseContext);
    const [showJoinForm, setShowJoinForm] = useState(false);
    const [isNewUser, setIsNewUser] = useState();
    // const [showAccessDenied, setShowAccessDenied] = useState(false);
    const [isSighUp, setIsSighUp] = useState()
    const [localePrefix] = useLocalePrefix();
    const {activateInvite} = useActivateInvite();
    // const {confirmEmailToken} = useConfirmEmailToken();

    const redirectUrl = history?.location?.pathname?.includes('/invite') ? `${localePrefix}/` : history?.location?.pathname;
    const handleJoinWorkspace = async (user = {}) => {
        const {id, email, emailVerified} = user;
        if (!user?.id) {
            setShowJoinForm(true);
        }
        else {
            const memberOfWorkspace = workspace?.members?.find((member) => member.id === id);
            if (memberOfWorkspace) {
                history.push(redirectUrl)
            } else {
                const invite = workspace?.invites?.find((invite) => invite.email === email);
                if (workspace?.inviteOnly && !invite) {
                    setShowJoinForm(false)
                } else {
                    if (!!invite) {
                        await activateInvite({
                            variables: {
                                id: invite.id
                            }
                        });
                    }

                    await onUpdateWorkspace();

                    // if (
                    //     !emailVerified && 
                    //     invite) {
                    //     await confirmEmailToken({
                    //         variables: {
                    //             token: 'invitedUser'
                    //         }
                    //     });
                    //     await updateUser();
                    // }
                    history.push(redirectUrl)
                }
            }
        }
    }

    const handleLoginUser = async (userData) => {
        if (userData?.id) {
            await setIsNewUser(false);
            await handleJoinWorkspace(userData);
            setShowJoinForm(false);
        } else {
            setIsNewUser(true);
        }
    };

    const showAccessDenied = useMemo(() => {
        let result = false;
        if (user?.id) {
            const invite = !!workspace?.invites?.find((invite) => invite.email === user.email);
            result = workspace?.inviteOnly && !invite;
        }
        return result;
    }, [user, workspace]);

    useEffect(() => {
        if (isNewUser && user?.id) {
            (async () => await handleJoinWorkspace(user))()
        }
    }, [user, isNewUser])

    return showAccessDenied ?
        <div>
            Access is by invitation only. Contact the school's owner to get an invite.
        </div>
        :
        <JoinComponent
            user={user}
            handleJoinWorkspace={handleJoinWorkspace}
            showJoinForm={showJoinForm}
            onLoginUser={handleLoginUser}
            setShowJoinForm={setShowJoinForm}
            isSighUp={isSighUp}
            setIsSighUp={setIsSighUp}
            {...other}
        />

}
