import {gql} from "@apollo/client";

export const CreateWorkspaceMember = gql`
    mutation CreateWorkspaceMember($input: MemberInput!) {
        createWorkspaceMember(input: $input) {
            id
            permission
            workspaceId
            userId
        } 
    }
`;
