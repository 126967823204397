import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { openNotification } from '../notification/Notification.component';
import {
    PaidComponent,
    Svg,
    LoginContainer,
    useTrackClick,
    useIsAllow,
} from '../common';
import { EmbedCopyComponent } from '../common/EmbedCopy.component/EmbedCopy.component';
import { TranslateContainer } from './Translate.container/Translate.container';
import { TaskToPrint } from './TaskToPrint/TaskToPrint';
import { FirebaseContext } from '../../context/firebase/firebaseContext';
import { useLocalePrefix } from '../lang';
import { ProfileLink } from '../common';
import { useHistory } from 'react-router-dom';

export const TaskActions = (props) => {
    const { task, location, type, isStickToTop, workspace, hostname = '', isEmbed,  stepId, ...other } = props;
    const { user, loginUser } = useContext(FirebaseContext);
    const [localePrefix] = useLocalePrefix();
    const trackClick = useTrackClick();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [showLogin, setShowLogin] = useState();
    const useIsAllowd = useIsAllow();
    const isPaidEditor = useIsAllowd("PREMIUM", workspace, task?.creator?.id) ;
    const isEditor = user?.id === task?.creator?.id || isPaidEditor;
    const URL = `https://${window.location.hostname}`;

    const EDITURL = task?.taskInEvents[0]?.eventId
        ? `${localePrefix}/create-event/${task?.taskInEvents[0]?.eventId}`
        : `${localePrefix}/edit-task/${task?.id}`;

    return (
        <div className='Answer__actions Answer__actions_horizontal '>
            <div
                className='Answer__actions_top'
                onClick={(e) => e.preventDefault()}
            >
                <>
                    <CopyToClipboard
                        text={`${URL}${localePrefix}/tutorials/${task?.alias}-${task?.id}`}
                        onCopy={() => {
                            openNotification('topLeft', {
                                title: formatMessage({
                                    id: 'notif_copy_link_title',
                                }),
                                description: formatMessage({ id: 'notif_copy_link_description' }),
                                icon: '🤘',
                                duration: 3,
                            });
                        }}
                    >
                        <Button
                            type='text'
                            className='link-btn_light answer__actions_bottom_btn btn_toggle block-e'
                            onClick={() =>
                                trackClick(
                                    'click-share',
                                    `${URL}${localePrefix}/tutorials/${task?.id}`,
                                    `${URL}${localePrefix}/tutorials/${task?.id}`
                                )
                            }
                        >
                            <Svg
                                name=':icon/share'
                                className='icon_20'
                            />
                        </Button>
                    </CopyToClipboard>
                    {isEditor && (
                        <>
                            <EmbedCopyComponent
                                workspace={workspace}
                                url={`${URL}${localePrefix}/embed/tutorials/${task?.alias}-${task?.id}${history.location.search}`}
                                title={task?.title}
                                searchIsExist={history.location.search}
                                userIsAllow={isPaidEditor}
                            />
                            {task &&<TaskToPrint task={task} workspace={workspace} {...other} isAllowCreator={isPaidEditor} />}
                            {task &&<TranslateContainer task={task}{...other} userIsAllow={isPaidEditor} />}
                        </>
                    )}

                    {user?.id !== task?.creator?.id &&
                        <ProfileLink
                            user={task?.creator}
                            onClick={() =>
                                trackClick(
                                    "click-profile",
                                    task?.creator.id,
                                    `${URL}${localePrefix}/profile/${task?.creator.id}`
                                )
                            }
                            isLink={false}

                        />
                    }
                    <div>

                        <Link
                            to={{
                                pathname: isPaidEditor ? `${EDITURL}` : `${localePrefix}/payments`,
                                state: {
                                    taskProp: task,
                                    isEdit: true,
                                    workspace: workspace,
                                    questionPropId: stepId,
                                    tabProp: 1,
                                },
                            }}
                            disabled={!isPaidEditor}
                            className=' ant-btn link-btn-tertiary ant-btn ant-btn-tertiary ant-btn-sm'
                        >
                            <Svg
                                name=':icon/edit'
                                className='icon_16'
                            />
                            <span className='smallLabel hide-on-mobile'>
                                {formatMessage({ id: 'edit' })}
                            </span>
                        </Link>
                        {!isPaidEditor && <PaidComponent />}
                    </div>

                </>
            </div>
            <LoginContainer
                showLogin={showLogin}
                setCancelLogin={setShowLogin}
            />
        </div>

    );
};
