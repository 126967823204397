import React from 'react';
import { DeleteMemberComponent } from '../DeleteMember.component/DeleteMember.component';
import {useDeleteWorkspaceMember} from '../hooks';

export const DeleteMemberContainer = ({ memberId, memberGroup, workspace, onUpdateWorkspace }) => {
    const {deleteWorkspaceMember, loading} = useDeleteWorkspaceMember();
    const {members = []} = workspace || {};

    const handleDeleteUser = async (id) => {
        const member = members.find((m) => m.userId === id);

        if (!!member) {
            await deleteWorkspaceMember({
                variables: {
                    id: member.id
                }
            });
            onUpdateWorkspace();
        }
    };

    return (
        <DeleteMemberComponent
            memberId={memberId}
            memberGroup={memberGroup}
            loading={loading}
            handleDeleteUser={handleDeleteUser}
        />
    );
};
