import React from 'react';
import {useIntl} from 'react-intl';
import {isEmpty} from 'lodash';
import {EventDataComponent} from '../EventData.component';
import {PaymentRequestContainer} from '../PaymentRequest.container';
import {RequestsListComponent} from '../RequestsList.component';

type Props = {
    finishedList?: Array,
    unFinishedList?: Array,
    userPayments?: Array,
    balance?: number
};

export const RecipientComponent = (props: Props) => {
    const {
        finishedList,
        unFinishedList,
        userPayments,
        balance = 0,
        ...other
    } = props;

    const {formatMessage} = useIntl();

    return (
        <div className="recipient-component">
            <h2 className="recipient-component__title">
                {formatMessage({id: 'payment_recipient_title'})}
            </h2>
            <div className="recipient-component__box">
                <div className="recipient-component__balance">
                    {formatMessage({id: 'payment_recipient_balance'})} ${balance}
                </div>
                <PaymentRequestContainer {...other}
                                         balance={balance}/>
            </div>
            {userPayments && <RequestsListComponent userPayments={userPayments}/>}
            <div className="recipient-component__row">
                <h3 className="recipient-component__row__title">
                    {formatMessage({id: 'payment_recipient_events_title'})}
                </h3>
                {finishedList && !isEmpty(finishedList)
                    ? (
                        <table className="data-table">
                            <tbody>
                            {finishedList.map(item => <EventDataComponent key={item.eventId}
                                                                          data={item}/>)}
                            </tbody>
                        </table>
                    )
                    : (
                        <div className="recipient-component__empty">
                            {formatMessage({id: 'payment_recipient_no_events'})}
                        </div>
                    )}
            </div>
            {unFinishedList && !isEmpty(unFinishedList) && (
                <div className="recipient-component__row">
                    <h3 className="recipient-component__row__title">
                        {formatMessage({id: 'payment_recipient_events_not_finished'})}
                    </h3>
                    <div className="recipient-component__description">
                        {formatMessage({id: 'payment_recipient_note'})}
                    </div>
                    <table className="data-table">
                        <tbody>
                        {unFinishedList.map(item => <EventDataComponent key={item.eventId}
                                                                      data={item}/>)}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}