export function calculateTaskProgress(task, event, result) {
    const progressColor =
        event?.subjects?.[0]?.color ||
        task?.tags?.[0]?.subject?.color ||
        '';
    const progressPercentage = result?.answers?.length
        ? (result.answers.length / task?.questions?.length) * 100
        : 0;
    const completedCount = result?.answers?.length || 0;
    const allCount = task?.questions?.length || 0;
    // const backgroundColor = event?.subjects?.[0]?.color + 30 || task?.tags?.[0]?.subject?.color + 30 || '';

    return {
        progressColor,
        progressPercentage,
        completedCount,
        allCount,
    };
}