import './scss/index.scss';
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Modal, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { openNotification } from '../../notification/Notification.component';
import { useIntl } from 'react-intl';
import { useLocalePrefix } from '../../lang';
import { useSendEmail, useHtmlEmail } from '../../resend';
import { EmailPreviewComponent } from '../../common';
import { useCreateInvites } from '../hooks';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';

const { Option } = Select;

export const InvitesFormContainer = () => {
    const {user, workspace, onUpdateWorkspace} = useContext(FirebaseContext);

    const [invites, setInvites] = useState([]);
    const [inProgress, setInProgress] = useState();
    const [errors, setErrors] = useState({});
    const [form] = Form.useForm();
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();
    const { sendEmail } = useSendEmail();
    const { getHtmlInviteEmail } = useHtmlEmail();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { createInvites } = useCreateInvites();

    const { id: workspaceId, title, host, customDomain, status, invites: existedInvites } = workspace || {};
    const list = invites?.filter(invite => invite.email).map((inv) => inv?.email).join(', ');
    const inviteUrl = customDomain && status
        ? `https://${customDomain}${localePrefix}/invite`
        : `https://${host}.unschooler.me${localePrefix}/invite`;

    const emailHtml = getHtmlInviteEmail({
        ownerName: user?.name,
        schoolTitle: title,
        inviteUrl
    });

    const handleSendInvites = async () => {
        setInProgress('Invites are sending');
        if (!!invites?.length) {
            const reapInvites = await createInvites({
                variables: {
                    input: invites
                }
            });

            const invitesList = reapInvites?.data?.createInvites;
            const newInvites = invitesList?.filter((inv) => !inv.isActivated)?.map((inv) => inv.email);
            const oldInvites = invitesList?.filter((inv) => inv.isActivated)?.map((inv) => inv.email);

            let error;
            if (newInvites?.length) {
                const resp = await sendEmail(newInvites, emailHtml, `${formatMessage({ id: 'email_inviteschool_title' })}: ${title}`);
                const { error: sendEmailError } = resp;
                error = sendEmailError;
            }

            if (!error) {
                if (newInvites?.length) {
                    openNotification('topLeft', {
                        title: formatMessage({ id: 'notif_invite' }),
                        description: `${formatMessage({ id: 'email_invitecourse_notif_desc' })}: ${newInvites.join(', ')}`,
                        icon: '💬',
                        duration: 10,
                    });
                }

                if (oldInvites?.length) {
                    openNotification('topLeft', {
                        title: 'Repeated invitations (invitations not sent)',
                        description: `The user(s) is already a member(s) of the school: ${oldInvites.join(', ')}`,
                        icon: '💬',
                        duration: 10,
                    });
                }

                form.setFieldsValue({
                    memberList: []
                });
            } else {
                openNotification('topLeft', {
                    title: formatMessage({ id: 'notif_invite' }),
                    description: error?.message,
                    icon: '❌',
                    duration: 10,
                });
            }
        }
        setInProgress();
        setIsModalVisible(false);
        onUpdateWorkspace();
    };

    const updateInvites = () => {
        const membersForm = form.getFieldsValue('memberList');
        if (!!membersForm.memberList?.length) {
            let invites = [];
            membersForm?.memberList?.forEach((member, index) => {
                if (member.email) {
                    const isExistInvite = !!existedInvites.find((inv) => inv.email === member.email);
                    if (!isExistInvite) {
                        if (errors?.[index]) {
                            setErrors((prev) => {
                                const clone = { ...prev };
                                clone[index] = false;
                                return clone;
                            });
                        }
                        invites.push({ email: member.email, permission: member.permission, workspaceId });
                    } else {
                        setErrors((prev) => {
                            const clone = {...prev};
                            clone[index] = 'A user with this email has already been invited';

                            return clone;
                        });
                    }
                }
            });
            setInvites(invites);
        }
    };

    const handleInputChange = (e, index) => {
        form.setFieldsValue({
            memberList: [
                ...form.getFieldValue('memberList').slice(0, index),
                {
                    ...form.getFieldValue('memberList')[index],
                    email: e.target.value
                },
                ...form.getFieldValue('memberList').slice(index + 1)
            ]
        });
        updateInvites();
    };

    const handleSelectChange = (value, index) => {
        form.setFieldsValue({
            memberList: [
                ...form.getFieldValue('memberList').slice(0, index),
                {
                    ...form.getFieldValue('memberList')[index],
                    permission: value
                },
                ...form.getFieldValue('memberList').slice(index + 1)
            ]
        });
        updateInvites();
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button
                type="link"
                size="small"
                className='inline-el wrapper_inner-section'
                onClick={showModal}
            >
                + {formatMessage({ id: 'form_workspace_invite' })}
            </Button>
            <Modal
                title={formatMessage({ id: 'form_workspace_invite' })}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    onFinish={handleSendInvites}
                    form={form}
                    initialValues={{ memberList: [{}, {}, {}] }} // Initialize with 3 empty fields
                >
                    <Form.List name="memberList">
                        {(eventsLists, { add, remove }) => (
                            <div>
                                <ul className='events-list-edit-component__list'>
                                    {eventsLists.map(
                                        (field, index) => (
                                            <li key={field.key} className="events-list-edit-component__list__item">
                                                <div className="edit-task-component__question__option__body">
                                                    <Form.Item
                                                        rules={[{
                                                            required: true,
                                                            message: formatMessage({ id: 'enter_title_warning' })
                                                        }]}
                                                        className='grow_input'
                                                    >
                                                        <Input
                                                            placeholder={formatMessage({ id: 'title_input_text' })}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[index, "permission"]}
                                                        valuePropName="checked"
                                                        initialValue="User"
                                                    >
                                                        <Select
                                                            defaultValue="User"
                                                            onChange={(value) => handleSelectChange(value, index)}
                                                        >
                                                            <Option value="User">Student</Option>
                                                            <Option value="Admin">Admin</Option>
                                                            <Option value="Creator">Creator</Option>
                                                            <Option value="Instructor">Instructor</Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => remove(field.name)}
                                                    />
                                                </div>
                                                {errors?.[index] && (
                                                    <div className="error">
                                                        {errors?.[index]}
                                                    </div>
                                                )}
                                            </li>
                                        )
                                    )}
                                </ul>
                                <Button
                                    onClick={add}
                                    type="link"
                                    size="small"
                                    className='inline-el wrapper_inner-section'
                                >
                                    + {formatMessage({ id: 'form_workspace_invite' })}
                                </Button>
                            </div>
                        )}
                    </Form.List>

                    {!!invites?.length &&
                        <>
                            <EmailPreviewComponent
                                list={list}
                                emailHtml={emailHtml}
                                workspace={workspace}
                                formatMessage={formatMessage}
                            />
                            <Button
                                type="primary"
                                htmlType="submit"
                                className='inline-el'
                                disabled={!!inProgress}
                            >
                                {formatMessage({ id: 'form_workspace_send_invite' })}
                            </Button>
                        </>
                    }
                </Form>
            </Modal>
        </>
    );
};