import React, { useCallback, useContext, useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { getGalleriesInEvent } from '../getGalleriesInEvent';
import { ProgressBarComponent } from '../../profile/ProgressBar.component/ProgressBar.component';
import { getSavingCost } from '../../common/utils'
import { Svg } from '../../common'
import { EmbedContext } from '../../../context/embed';
import { useLocalePrefix } from '../../lang';
import { ProfileLink, useTrackClick, TagComponent, useConvertedPrice } from '../../common';
import { FirebaseContext } from "../../../context/firebase/firebaseContext";
import { useIsPermit } from '../../roles/hook/useCheckPermission';

export const EventCardComponent = (props) => {
    const {
        tagColor,
        event,
        imgUrl,
        tagData,
        isLive,
        user,
        handlePublish,
        deg,
        draft,
        isNotAllow,
        workspace,
        subscriptionActive,
    } = props;

    const { currencyCode } = useContext(FirebaseContext);
    const { isEmbed } = useContext(EmbedContext) || {};
    const trackClick = useTrackClick();
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();
    const { getPrice } = useConvertedPrice();
    const [priceData, setPriceData] = useState();
    const { price, stripeProduct } = event;
    const iAmParticipant = event.isSubscribe;
    const bundlePriceRef = useRef(0); 
    const { title: tagTitle, subject: tagSubject, color: tagColorData } = tagData || {};
    
    const finalTagColor = tagSubject?.color || tagColorData;

    const { checkPermit } = useIsPermit()
    const editOthersCourses = checkPermit(workspace, 'editOthersCourses')

    const typeLink = event.type === "BUNDLE" ? 'bundles' : 'online-courses';

    const URL = isEmbed || '';
    let LinkClick = (isNotAllow) ? `${localePrefix}/payments` : `${localePrefix}/${URL}${typeLink}/${event.alias}-${event.id}`;

    const getPriceData = useCallback(async () => {
        if (stripeProduct?.length) {
            const { prices } = stripeProduct[0];
            const pricesList = prices?.map((p) => Number(p.price));

            const minPrice = Math.min(...pricesList);
            const minPriceItem = prices.find((p) => Number(p.price) === minPrice);
            bundlePriceRef.current = minPriceItem.price / 100; // Update useRef value
            const priceData = await getPrice(minPriceItem.price / 100, minPriceItem.currency);
            setPriceData(`from ${priceData}`);
        } else if (price) {
            const priceSourse = price?.forStudent || price?.total;
            bundlePriceRef.current = priceSourse; // Update useRef value
            const priceData = await getPrice(priceSourse);
            setPriceData(priceData);
        }
    }, [price, stripeProduct, getPrice]);

    // if (openFirtsLesson && event?.tasks[0]) { LinkClick = `${localePrefix}/tutorials/${event?.tasks[0]?.id}` }
    // let setEventDifficulty = () => {
    //     let difficulty = 0;
    //     event.tasks?.forEach((task) => { difficulty = task.difficultyId + difficulty })
    //
    //     let avg = difficulty / event?.tasks?.length;
    //     if (avg >= 1 && avg < 1.6) {
    //         return { level: 'easy', color: '#000000' };
    //     } else if (avg > 1.6 && avg < 2.4) {
    //         return { level: 'medium', color: '#000000' };
    //     } else if (avg > 2.5) {
    //         return { level: 'hard', color: '#000000' };
    //     } else {
    //         return { level: 'easy', color: '#000000' };
    //     }
    // }

    // let dif = setEventDifficulty();

    let filter = isNotAllow ? `grayscale(1)` : !imgUrl ? `hue-rotate(${deg}deg)` : ``
    let progress = { allCount: event?.tasks?.length, count: 0 };
    event?.tasks && event?.tasks?.forEach(task => {
        if (task.stateResult === ('CORRECT' || "INCORRECT")) {
            progress.count = progress.count + 1
        }
    })
    const savings = getSavingCost(event);

    useEffect(() => {
        (async () => await getPriceData())();
    }, [currencyCode]);

    return (
        <Link className={`event-card-component ${event?.type === "BUNDLE" ? 'stacked-border' : ''}`}
            onClick={() =>
                trackClick(
                    isLive ? 'click-event-card_profi' : 'click-event-card',
                    event.id,
                    `https://unschooler.me${localePrefix}/online-courses/${event.alias}-${event.id}`
                )
            }
            to={{ pathname: LinkClick, state: { workspace } }}
        >
            <div className='event-card-component__top'>
                <div className='event-card-component__header'>
                    {/* <div className="ribbon-wrapper" style={{ backgroundColor: dif && dif?.color }}>
                        {dif?.level}
                        <div className="ribbon-corner" style={{ color: dif && dif?.color }}></div>
                    </div> */}

                    {(user?.id === event?.creator?.id || editOthersCourses) ?
                        <Button type="secondary" size="small" onClick={(e) => {
                            e.stopPropagation();
                            handlePublish(e);
                        }}>{draft ? formatMessage({ id: 'form_is_draft' }) : formatMessage({ id: 'published' })}
                        </Button>
                        :
                        <div/>
                    }

                    <h5 >{event?.type === "BUNDLE" ? formatMessage({ id: 'bundle' }) : formatMessage({ id: 'course_title' })}</h5>
                </div>

                <div className='wrapper_image_card'
                    style={{
                        color: tagColor?.color,
                        filter: filter,
                        backgroundImage: `url(${imgUrl || getGalleriesInEvent(event?.id)})`,
                        backgroundSize: 'cover',
                        backgroundPosition: '50%'
                    }}>
                    {event?.type === "BUNDLE" && savings?.percent > 0 &&
                        <div className="saveLabel" >
                            <span>
                                <Svg name={`:icon/coins`} className='icon_16 margin_right' />
                                Save: {savings?.percent}% <span style={{ textDecoration: 'line-through' }}>${savings.totalCostOfRelatedEvents}</span>
                            </span>
                        </div>
                    }
                </div>

                <div className=''>
                    <h2 className='event-card-component__title'>
                        {event?.title}
                    </h2>
                </div>
            </div>

            <div className='event-card-component__footer'>
                {event?.type !== "BUNDLE" ? (
                    <>
                        {iAmParticipant && (
                            <ProgressBarComponent
                                event={event}
                                color={finalTagColor}
                                size='small'
                                description={formatMessage({ id: 'lessons_you_learned' })}
                            />
                        )}
                        <div className='event-card-component__tags'>
                            {tagTitle && (
                                <TagComponent
                                    tag={{ title: tagTitle, color: finalTagColor }}
                                    colorful={true}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <h4>{formatMessage({ id: 'courses' })}: {event.relatedEvents?.length}</h4>
                )}
                <div className='price_wrapper'>
                    {!subscriptionActive && !iAmParticipant && (
                        <h3 className='money-component margin_right'>
                            {priceData}
                        </h3>
                    )}
                    {!iAmParticipant ? (
                        <Button type="secondary" size="small">
                            {formatMessage({ id: 'event_card_button_join_room' })}
                        </Button>
                    ) :
                        <Button type="text" size="small">
                            {formatMessage({ id: 'event_card_button_joined' })}
                        </Button>
                    }
                </div>
            </div>

            {/* <ParticipantsIntroComponent
                    userSubscriptions={event.userSubscriptions}
                    countUserSubscriptions={event.countAllUserSubscriptions}
                    isLink={false}
                    eventId={event.id}
                /> */}
        </Link >
    )
};
