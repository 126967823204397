import React, { useEffect, useState, useRef } from 'react';
import { VideoGeneratorButton } from '../common/VideoIframe/VideoGeneratorButton';
import { useGetTask } from '../task/hooks/useGetTask';
import { getStorageManager } from '../common/utils';
import { useGetCurrculum } from '../ai/hook';
import { EventCurriculumItemComponent } from '../event/EventCurriculumItem.component';

const STORAGE_KEY = 'try';

export const LandingVideoDemo = ({ taskId, handleLogin, ...other }) => {
    const progressRef = useRef([]);
    const { getStorageData, setStorageData } = getStorageManager();
    const { task, getTask } = useGetTask();

    const [inProgress, setInProgress] = useState();
    const [isCount, setIsCount] = useState();
    const [event, setEvent] = useState();
    const [eventLoading, setEventLoading] = useState();
    const { getCurriculumGen } = useGetCurrculum();

    // actions
    const getCurriculum = async (ask) => {
        // trackClick(`click-ai-tutorial`, '', `https://unschooler.me/`);
        setEventLoading(true);
        const curriculum = await getCurriculumGen(ask);
        if (curriculum) {
            setEventLoading(false);
            setEvent(curriculum);
        }
    };

    const handleChangeProgress = (val) => {
        const { message, key } = val;

        const activeItem = progressRef?.current?.find(item => item.key === key);

        if (message) {
            if (!activeItem) {
                progressRef?.current.push(val);
                setInProgress(val.message);
            }
        } else {
            const index = progressRef?.current.findIndex(item => item.key === key);
            progressRef?.current.splice(index, 1);
            setInProgress(progressRef?.current?.[0]?.message || '');
        }
    };

    useEffect(() => {
        (async () => {
            await getTask({
                variables: {
                    taskId: taskId
                }
            });
            setIsCount(getStorageData(STORAGE_KEY) || 0);
        })();
    }, []);

    const saveToStorage = (ask) => {
        getCurriculum(ask);
        setStorageData(STORAGE_KEY, isCount + 1);
        setIsCount(isCount + 1);
    };

    return (
        <div className='video-demo'>
            <div className=''>
                {task &&
                    <VideoGeneratorButton
                        task={task}
                        setInProgress={handleChangeProgress}
                        inProgress={inProgress}
                        isLanding={true}
                        isAllow={isCount < 2 ? true : false}
                        saveToStorage={saveToStorage}
                        handleLogin={handleLogin}
                        coverFromTask={task?.cover}
                        {...other}
                    />
                }
            </div>
            {event &&
                <EventCurriculumItemComponent
                    event={event}
                    subject={event?.subject}
                    setEventLoading={setEventLoading}
                    eventLoading={eventLoading}
                    setEvent={setEvent}
                    {...other}
                />
            }
        </div>
    );
};
