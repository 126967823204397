import { useCallback } from "react";
import { useAiDallie } from "../graphQL/useAiDallie";

export const useGetImagesDalle = () => {
    const { getDallieResponse } = useAiDallie();

    const getAiImage = useCallback(
        async (partOfRequest) => {
            const settings = {
                args: {
                    partOfRequest: partOfRequest
                },
                type: 'imageDalli',
            };

            const response = await getDallieResponse({
                variables: {
                    input: settings,
                },
            });

            const url = response?.data ? response?.data?.requestDallie : null;

            if (url) {
                return url;
            }
        },
        [getDallieResponse]
    );

    return { getAiImage };

};
