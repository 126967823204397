import { gql } from '@apollo/client';

export const GetUserCertificates = gql`
    query GetUserCertificates($userId: Int, $certificateId: Int) {
        getUserCertificates(userId: $userId, certificateId: $certificateId) {
            id
            userName
            userId
            certificateId
            createdDate
        }
    }
`;