import './scss/index.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { TagStringComponent } from './TagString.component';
import { useLocalePrefix } from '../../lang';

export const TagComponent = ({
    tag,
    countResults,
    colorful,
    link,
}) => {
    const tagColor = tag?.subject?.color || tag?.color;

    const [localePrefix] = useLocalePrefix();
    const styleSpan = classnames('tag', {
        'wrapper__tag-link': link,
        'wrapper__tag-link_color_progress': countResults?.done || colorful, //fill the progress bar with color
        'wrapper__tag-link_color': colorful, //fill entirely with color
    });

    const linkUrl = link ? `${localePrefix}/tutorials/tag/${tag?.id}` : null;
    const progress = (countResults?.done / (countResults?.all / 100)) || 0;

    return (
        <>
            <span
                className={styleSpan}
                style={{
                    color: tagColor && tagColor !== '#fff' ? tagColor : '#666',
                    width: progress ? progress + '%' : '100%',
                }}
            >
                {link ? (
                    <Link
                        to={{ pathname: linkUrl, state: { tag }, }}
                        className='tag-link'
                    >
                        <TagStringComponent
                            tag={tag}
                            countResults={countResults}
                        />
                    </Link>
                ) : (
                    <span className='tag-link'>
                        <TagStringComponent
                            tag={tag}
                            countResults={countResults}
                        />
                    </span>
                )}

            </span>
        </>
    );
};
