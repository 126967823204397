import React, { useEffect, useState } from 'react';
import { noop } from 'lodash';

import { EventsListComponent } from '../../events';
import useGetEventSimple from '../../event/hooks/useGetEventSimple';
import useGetEventStudents from '../../event/hooks/useGetEventStudents';
import { EventsListActionsContainer } from '../EventsListActions.container';
import {AddEventSectionContainer} from '../../workspace/AddEventSection.container/AddEventSection.container';

export const SimpleEventsListContainer = (props) => {
    const {
        as: Component = EventsListComponent,
        eventsList,
        index,
        workspaceId,
        eventsLists,
        typeProp,
        workspace,
        setAllEvents = noop,
        createCourses,
        ...other
    } = props;

    const { getEventSimple, loading } = useGetEventSimple();
    const { getEventStudents, loading: loadingRes } = useGetEventStudents();
    const [events, setEvents] = useState();
    const [editMode, setEditMode] = useState(false);
    const [type, setType] = useState(typeProp);

    const handleCancelEdit = () => setEditMode(false);

    const actionsComponent = createCourses ? () => (
        <EventsListActionsContainer
            index={index}
            workspaceId={workspaceId}
            eventsLists={eventsLists}
            type={type}
            setType={setType}
            onEditList={setEditMode}
            {...other}
        />) : null;

    useEffect(() => {
        (async () => {
            if (eventsList) {
                const events = [];

                for (const eventId of eventsList) {
                    if (!!createCourses) {
                        const dbEvent = (
                            await getEventStudents({
                                variables: {
                                    id: eventId,
                                },
                            })
                        ).data?.event;
                        if (dbEvent) {
                            events.push(dbEvent);
                        }
                    } else {
                        const dbEvent = (
                            await getEventSimple({
                                variables: {
                                    id: eventId,
                                },
                            })
                        ).data?.event;
                        if (dbEvent) {
                            events.push(dbEvent);
                        }
                    }
                }
                setAllEvents((prev) => [...prev, ...events]);
                if (!!events.length) {
                    setEvents(events);
                }
            }
        })();
    }, [eventsList, getEventSimple]);

    return (
        <>
            {editMode && <AddEventSectionContainer targetList={eventsLists?.[index]}
                                                   targetListEvents={events}
                                                   onCancelEdit={handleCancelEdit}/>}
            {!editMode && (
                <Component {...other}
                           events={events}
                           className='events-list-component'
                           asActions={actionsComponent}
                           type={type}
                           isLoading={loadingRes || loading}
                           isAllow={createCourses}
                />
            )}
        </>
    );
};
