import React from 'react';
import {useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router-dom';

import {CheckoutContainer} from '../Components/checkout';
import {FooterContainer} from '../Components/footer';
import {TitleComponent} from '../Components/common';
import {useLocalePrefix} from '../Components/lang';

export const Checkout = () => {
    const {formatMessage} = useIntl();
    const history = useHistory();
    const {state} = useLocation();
    const [localePrefix] = useLocalePrefix();
    const {giftBox, guide, event, isSubscription} = state || {};

    if (!state) {
        history.push(`${localePrefix}/`);
        return null;
    }

    const isAsync = event?.type === 'ASYNC';
    const titleId = isAsync
        ? 'checkout_enroll_title'
        : guide
        ? 'checkout_guide_title'
        : giftBox
        ? 'chekout_title_gift'
        : isSubscription
        ? 'checkout_subscription'
        : 'checkout_weekly_subscription';

    return (
        <div className='page'>
            <TitleComponent title={formatMessage({id: titleId})} back={true} />
            <div className='flex-container'>
                <CheckoutContainer data={state} />
            </div>
            <FooterContainer />
        </div>
    );
};
