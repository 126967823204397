import { openNotification } from '../../../notification/Notification.component.js';
import React, { useState } from 'react';

const URL = 'https://back.unschooler.me/file/upload';
const URL_DEV = 'http://localhost:4000/file/upload';

export const addImageHelper = async (data, editor, setError) => {
    const { file } = data;
    if (setError) {
        setError(false);
    }

    try {
        const data = new FormData();
        data.append('file', file);

        const resp = await fetch(URL, {
            method: 'POST',
            body: data
        });
        const respData = await resp.json();

        if (respData.url) {
            editor.chain().focus().setImage({ src: respData.url }).run();
        }
        return respData.url;
    } catch (err) {
        console.warn(err);
        openNotification('topLeft', {
            title: 'Error uploading image',
            description: 'The image is too large, please use an image no larger than 5MB.',
            icon: `👩‍🚀`,
            duration: 10,
        });
        if (setError) {
            setError(true);
        }
    }
};

export const addLinkHelper = (editor) => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
        return
    }

    // empty
    if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink()
            .run()
        return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
        .run()
};

export const addYoutubeVideoHelper = (editor) => {
    // TODO: Need translation
    const url = prompt('Enter YouTube URL')

    if (url) {
        editor.commands.setYoutubeVideo({
            src: url,
            width: 'auto',
            height: 'auto',
        })
    }
};

export const useAddEmbedHelper = (editor) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');
    const [iframeWidth, setIframeWidth] = useState('560px');
    const [iframeHeight, setIframeHeight] = useState('315px');

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (iframeUrl) {
            editor.commands.setIframe({
                src: iframeUrl,
                width: iframeWidth,
                height: iframeHeight,
                className: 'embed-tiptap-iframe'
            });
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return { isModalVisible, iframeUrl, iframeWidth, iframeHeight, showModal, handleOk, handleCancel, setIframeUrl, setIframeWidth, setIframeHeight };
};

export const useAddVideoHelper = (editor) => {
    const [videoUrl, setVideoUrl] = useState('');
    const [videoWidth, setVideoWidth] = useState('');

    const handleOk = () => {
        if (videoUrl) {
            editor.commands.setVideo({
                src: videoUrl,
                ...(!!videoWidth && {width: videoWidth})
            });
        }
    };
    return { videoUrl, videoWidth, setVideoUrl, setVideoWidth, handleOk };
};