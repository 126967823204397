import {useLazyQuery} from '@apollo/client';
import {GetUserIntegrations} from '../graphQL/queries/GetUserIntegrations';

export const useGetUserIntegrations = (query = GetUserIntegrations ) => {
    const [getUserIntegrations, {data, loading, error}] = useLazyQuery(query);

    return {
        data,
        getUserIntegrations,
        loading,
        error
    }
}
