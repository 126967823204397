/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { EventsListComponent } from '../EventsList.component';
import { useAnalytics, Tab } from '../../common';
import { useLangId } from '../../lang';
import { useGetEvents } from '../../events/hooks/useGetEvents';
import { useGetUserWithEvents } from '../../common/graphQL';

export const EventsListContainer = (props) => {
    const {
        as: Component = EventsListComponent,
        subjectIds = [],
        user,
        tabs,
        activeTab,
        coursesIds,
        ...other
    } = props;

    const LIMIT = 20;
    const langId = useLangId();
    const { eventTrack } = useAnalytics();
    const { formatMessage } = useIntl();
    const { getEvents, loading: eventsLoading } = useGetEvents();
    const { getUser, loading: userEventsLoading } = useGetUserWithEvents();

    const [events, setEvents] = useState([]);
    const [isShowMore, setIsShowMore] = useState(false);
    const [offset, setOffset] = useState(0);
    const [tab, setTab] = useState(activeTab || (tabs?.length && tabs[0]));

    const handleTab = (tabID) => { setTab(tabID) };
    const handleEventClick = (e, event) => {
        const eventData = e;
        eventData.clickText = event?.title;
        eventData.clickId = 'click-event-card';
        eventData.clickUrl = `https://unschooler.me/online-courses/${event.id}`;
        eventTrack('click-event-card', eventData);
    };

    const fetchMoreEvents = async () => {
        const eventsList = await getEventsByTab(
            activeTab,
            offset + 1,
        );
        setOffset((prev) => prev + LIMIT);
        setIsShowMore(eventsList?.length === LIMIT);
        setEvents((prev) => [...prev, ...eventsList]);

    };

    const getEventsByTab = useCallback(
        async (tab, offset = 0) => {
            if (tab === formatMessage({ id: "tab_events_user" })) {
                const { id } = user;
                const resp = await getUser(id, null, null, LIMIT, offset);
                return resp?.events;
            }

            if (coursesIds?.length) {
                const resp = await getEvents({
                    variables: {
                        filter: {
                            ids: coursesIds,
                            isDraft: false,
                        },
                        limit: LIMIT,
                        offset
                    },
                    fetchPolicy: "network-only"
                });

                return resp?.data?.events;
            }
            if (tab === formatMessage({ id: "tab_event_drafts" })) {
                const resp = await getEvents({
                    variables: {
                        filter: {
                            ids: coursesIds,
                            isDraft: true,
                        },
                        limit: LIMIT,
                        offset
                    },
                    fetchPolicy: "network-only"
                });

                return resp?.data?.events;
            }

            if (tab === formatMessage({ id: "tab_event_by_subjectIds" })) {
                const resp = await getEvents({
                    variables: {
                        filter: {
                            type: "ASYNC",
                            langId: langId,
                            subjectIds,
                            // isDraft: false,
                        },
                        limit: LIMIT,
                        offset
                    },
                    fetchPolicy: "network-only"
                });
                let eventsList = resp?.data?.events

                if (!eventsList?.length) {
                    const resp = await getEvents({
                        variables: {
                            filter: {
                                type: "ASYNC",
                                langId: 2,
                                subjectIds: [],
                            },
                            limit: LIMIT
                        },
                        fetchPolicy: "network-only"
                    })
                    return resp?.data?.events;
                }
                return eventsList;
            }
        },
        [tab, activeTab, coursesIds]
    );

    useEffect(() => {
        const initFetch = async () => {
            const eventsList = await getEventsByTab(tab);
            if (eventsList) {
                setEvents(() => [...eventsList]);
                setOffset((prev) => prev + LIMIT);
                setIsShowMore(eventsList?.length === LIMIT);
            }
        };
        initFetch();
    }, [langId, coursesIds, user?.id, tab]);

    const isLoading = useMemo(() => userEventsLoading || eventsLoading, [userEventsLoading, eventsLoading]);

    return (
        <>
            {!!tabs?.length &&
                <Tab
                    items={tabs}
                    activeTab={tab}
                    handleClick={handleTab}
                    type="big"
                    {...other}
                />
            }
            <Component
                {...other}
                isLoading={isLoading}
                events={events}
                isShowMore={isShowMore}
                onShowMore={fetchMoreEvents}
                onClick={handleEventClick}
                user={user}
            />
        </>
    )


}
