import React from 'react';
import {useIntl} from 'react-intl';
import {EventDataPayerComponent} from '../EventDataPayer.component';

type Props = {
    list?: Array
};

export const PayerComponent = (props: Props) => {
    const {list} = props;
    const {formatMessage} = useIntl();

    return (
        <div className="payer-component">
            <div className="payer-component__row">
                <h3 className="payer-component__row__title">
                    {formatMessage({id: 'payment_request_payer_title'})}
                </h3>
                {list
                    ? (
                        <table className="data-table">
                            <tbody>
                            {list.map(item => <EventDataPayerComponent key={item.payment.id}
                                                                       data={item}/>)}
                            </tbody>
                        </table>
                    )
                    : (
                        <div className="recipient-component__empty">
                            {formatMessage({id: 'payment_request_payer_note'})}
                        </div>
                    )}
            </div>

        </div>
    )
}