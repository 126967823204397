import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Loader } from "../../Loader";

import { Link } from "react-router-dom";

export const Promoes = () => {

    const { loading, data } = useQuery(GET_PROMOES, {
        fetchPolicy: "network-only"
    });

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="AdminNavigation">
            <p>Все промокомпании:</p>
            <ul>
                {
                    data.promoes.map((promo) => (
                        <li>
                            <Link 
                                className='nav-link' 
                                to={'/admin/promo/' + promo.id}
                            >
                                    {promo.name}
                            </Link>
                        </li>
                    ))
                }
            </ul>
            <Link to="/admin/promo/create">Добавить промокомпанию</Link>
        </div>
    );
}

const GET_PROMOES = gql`
    query GetPromoes {
        promoes {
            id
            name
        }
    }
`;