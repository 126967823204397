import './scss/index.scss';

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { noop } from 'lodash';
import { Button, Form, Input } from 'antd';
import { useIntl } from "react-intl";

import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { useTrackClick } from '..';
import translate from '../../../lang/translate';

export const AuthFormSignUpComponent = (props) => {
    const {
        onCancelLogin = noop,
        onLoginUser = noop,
        onLoading = noop,
        invites,
        type,
        userData,
        isReadOnlyEmail = false
    } = props;
    const { signUpUserEmailPass, error } = useContext(FirebaseContext);
    const trackClick = useTrackClick();
    const history = useHistory();
    const { userEmail } = userData || {};
    const [form] = Form.useForm();
    const { formatMessage } = useIntl();

    const onFinish = async (values) => {
        onLoading(true);
        trackClick(
            'click-login',
            translate('auth_button'),
            `https://unschooler.me${history.location.pathname}`
        );

        try {
            const {email} = values;
            const invite = invites.find((inv) => inv.email === email);
            const resp = await signUpUserEmailPass({...values, ...(invite && {inviteId: invite.id})});
            const {code: errorCode} = resp || {};
            if (!errorCode) {
                onLoginUser(resp);
                resp && onCancelLogin(false);
            }
        } catch (err) {
            onLoading(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.warn('Failed:', errorInfo);
    };

    return (
        <Form
            name="basic"
            form={form}
            initialValues={{
                remember: true,
                ...(userEmail && {
                    email: userEmail
                })
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
        >
            <Form.Item
                label={type !== 'compact' && formatMessage({ id: "email_input_text" })}
                name="email"
                rules={[
                    {
                        required: true,
                        message: formatMessage({ id: "enter_email_warning" }),
                    },
                ]}
            >
                <Input placeholder={formatMessage({ id: "email_input_text" })} disabled={isReadOnlyEmail} />
            </Form.Item>

            <Form.Item
                label={type !== 'compact' && formatMessage({ id: "password_input_text" })}
                name="password"
                rules={[
                    {
                        required: true,
                        message: formatMessage({ id: "enter_password_warning" }),
                    },
                ]}
            >
                <Input.Password placeholder={formatMessage({ id: "password_input_text" })} />
            </Form.Item>

            <div className=" error">
                {error}
            </div>

            <Form.Item>
                <Button type="primary" htmlType="submit" block='true' >
                    {formatMessage({ id: "signup" })}
                </Button>
            </Form.Item>
        </Form>
    );
};
