import { gql } from '@apollo/client';

export const GetTaskCover = gql`
    query GetTask($taskId: Int, $alias: String) {
        task(id: $taskId, alias: $alias) {
            id
            coverId
            cover {
                id
                type
                raw
                source
                summary
                timestamps {
                    timestamp
                    title
                    description
                    id
                }
            }
        }
    }
`;
