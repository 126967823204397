import { noop } from 'lodash';
import React from 'react';

export const EventListItemComponent = (props) => {
    const {
        event,
        toggleEventSelection = noop,
    } = props;

    return (
        <div
            className='event-card-component'
            onClick={() => toggleEventSelection(event.id)}
        >
            <div className='event-card-component__top'>
                <div className=''>
                    <h4 className='event-card-component__title'>
                        {event.title}
                    </h4>
                </div>
            </div>
        </div>
    )
};
