import React from 'react';
import {noop} from 'lodash';
import {Button} from 'antd';

import {TaskCardComponent} from '../TaskCard.component';

export const TasksCardsComponent = (props) => {
    const {
        onDeleteTask = noop,
        tasks,
        isMore = false,
        fetchMoreTasks,
        classNames,
        handleAddTask,
        user,
        size,
        ...other
    } = props;

    return (
        <div>
            <div className='cards wrapper_inner-section'>
                {tasks.map(
                    (task) =>
                        task?.id && (
                            <TaskCardComponent
                                key={task.id}
                                task={task}
                                user={user}
                                size={size}
                                topLevel={true}
                                {...other}
                            />
                        )
                )}
            </div>
            {isMore && (
                <div className='task-list-component__more-button wrapper_outer-section'>
                    <Button type='secondary' onClick={fetchMoreTasks} block>
                        Load more…
                    </Button>
                </div>
            )}
        </div>
    );
};
