import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Button} from 'antd';
import {LoaderComponent} from '../../common';

export const AddCustomDomainComponent = (props) => {
    const {addLoading, deleteLoading, isAddDomainMode, isVerified, addData, isDelete, onAddDomain, onDeleteDomain} = props;
    const {formatMessage} = useIntl();
    const {domain, records, status} = addData || {};

    const recordsData = useMemo(() => {
        if (records) {
            const isCnameType = typeof records.cname === 'boolean';
            if (isCnameType) {
                return [
                    {title: 'CNAME', value: records['cname']},
                    {title: 'TXT', value: records['txt']}
                ];
            } else {
                return [
                    {title: 'A', value: records['a']},
                    {title: 'TXT', value: records['txt']}
                ];
            }
        }
    }, [records]);

    return (
        <div className="custom-domain-component">
            {!isVerified && (
                <div>
                    <Button type="tertiary"
                            disabled={addLoading || status === 'NEW_DOMAIN_ADDED'}
                            onClick={onAddDomain}>
                        {addLoading && (
                            <LoaderComponent type="inline"/>
                        )}
                        <span>
                        {formatMessage({id: 'custom_domain_add_btn'})}
                    </span>
                    </Button>
                    {!isAddDomainMode && (
                        <hr/>
                    )}
                </div>
            )}
            {!isAddDomainMode && (
                <Button type="link"
                        disabled={deleteLoading || isDelete}
                        onClick={onDeleteDomain}>
                    {deleteLoading && (
                        <LoaderComponent type="inline"/>
                    )}
                    <span>
                        {formatMessage({id: 'custom_domain_remove_btn'})}
                    </span>
                </Button>
            )}
            {!!recordsData && (
                <div className="custom-domain-component__records">
                    <hr/>
                    <h3>Records:</h3>
                    {recordsData.map((r) => (
                        <div key={r.title}
                             className="mb-m-l">
                            <strong>{r.title}:</strong> <small>{r.value ? '✅' : '❌'}</small>
                        </div>
                    ))}
                    {status && (
                        <div>
                            {formatMessage({id: status === 'NOT_VALID_RECORDS' ? 'custom_domain_wrong_records' : 'custom_domain_success_message'})}
                        </div>
                    )}
                </div>
            )}
            {isDelete && (
                <div className="mt-m-l">
                    {formatMessage({id: 'custom_domain_remove_message'})}
                </div>
            )}
        </div>
    )
}
