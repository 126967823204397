import { useLazyQuery } from "@apollo/client";
import { GetTag } from "../graphQL/queries/GetTag";

export function useGetTag(query = GetTag) {
    const [getTag, { data, loading, error }] = useLazyQuery(query);
    return {
        tag: data?.tag,
        loading,
        error,
        getTag
    };
}