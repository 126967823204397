import './../scss/index.scss';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs } from './BreadCrumbs';
import { HeaderWorkspaceStudent } from './HeaderWorkspaceStudent';

export const HeaderWorkspace = ({ user, currentTitle, workspace, isEditMode, onUpdateWorkspace, ...other }) => {
    const location = useLocation();
    const isInvitePage = location.pathname.includes('/invite');

    if (isEditMode) {
        return null;
    }

    return (
        <header className='header_transparent'>
            <BreadCrumbs currentTitle={currentTitle} user={user} workspace={workspace} {...other} />
            <HeaderWorkspaceStudent
                user={user}
                workspace={workspace}
                onUpdateWorkspace={onUpdateWorkspace}
                isInvitePage={isInvitePage} {...other} />
        </header>

    );
};
