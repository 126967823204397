import React, {
    useContext,
    useEffect,
    useState,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import {
    MetaComponent,
    useGetUrlParams,
    useHostNameUrl,
} from '../Components/common';

import { UserCertificateComponent, useGetUserCertificate } from '../Components/certificates';
import { Loader } from '../Components/Loader';
import { useLocalePrefix } from '../Components/lang';
import { FirebaseContext } from '../context/firebase/firebaseContext';
import { COVER_PICTURES } from '../Components/constant';

export const UserCertificate = (props) => {
    const {
        match,
        inProgress,
        isEmbed = '',
        hostname,
        workspace,
        ...other } = props;

    const { user, loadingUser } = useContext(FirebaseContext);
    const { getUserCertificate } = useGetUserCertificate();
    const [certificate, setCertificate] = useState(null);

    const getData = async () => {
        const resp = await getUserCertificate({
            variables: {
                id: parseInt(id)
            },
            fetchPolicy: 'network-only'
        });

        if (resp?.data?.getUserCertificate) {
            setCertificate(resp.data.getUserCertificate);
        }
    };

    const { pathname } = useLocation();
    const { id, checkUrl } = useGetUrlParams(isEmbed);
    const workspaceUrl = useHostNameUrl(workspace);
    const { formatMessage } = useIntl();
    const [localePrefix] = useLocalePrefix();

    useEffect(() => {
        if (id) {
            (async () => {
                await getData();
            })()
        }
    }, [id]);

    const metaData = useMemo(() => {
        if (!certificate) { return null; }
        const { title, icon, event, id } = certificate?.certificate;

        if (hostname || workspace) {
            const metaTitle = `${title} | ${workspace?.title}`;
            const description = `Certificate in course ${title}, created by ${event?.creator?.name} on ${workspace?.title}`;
            const url = `${workspaceUrl}${localePrefix}user-certificate/${id}`;
            const coverImage = icon;

            return {
                meta: {
                    meta_title: metaTitle,
                    meta_description: description,
                    meta_author: event?.creator?.name,
                    meta_icon: workspace?.logo,
                },
                og: {
                    type: 'article',
                    title: metaTitle,
                    image: coverImage,
                    description: description,
                    url: url,
                },
                canonical: url
            };
        } else {
            const metaTitle = `${title} | ${formatMessage({ id: 'certificate_title' })}`;
            const description = `Certificate issued for ${certificate?.userName} in course ${title}, created by ${event?.creator?.name}.`;
            const url = `https://unschooler.me${localePrefix}/user-certificate/${id}/`;

            const coverImage = icon || COVER_PICTURES.CERT;

            return {
                meta: {
                    meta_title: metaTitle,
                    meta_description: description
                },
                og: {
                    type: 'article',
                    title: metaTitle,
                    image: coverImage,
                    description: description,
                    url: url,
                },
            };
        }
    }, [certificate]);

    if (loadingUser || !certificate) {
        return <Loader />;
    }

    return (
        <>
            {metaData && (
                <MetaComponent meta={metaData.meta} og={metaData.og} />
            )}

            <div className='page'>
                <UserCertificateComponent
                    certificate={{
                        ...certificate,
                        createDate: certificate?.createdDate,
                        student: {
                            id: certificate?.userId,
                            name: certificate?.userName,
                            picture: certificate.user?.picture
                        },
                        id: certificate?.id
                    }}
                    student={{ name: certificate?.userName, ...user }}
                    workspace={workspace}
                    user={user}
                />
            </div>
        </ >
    );
};
