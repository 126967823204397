import { useCallback } from 'react';
import { useGetSummaryLongForm } from './useGetSummaryLongForm';

const MAX_INPUT_LENGTH = 40000;
const MAX_INPUT_FOR_SUMMARY = 10000
export const useReducedCaptions = () => {
    const { getSummaryLongForm } = useGetSummaryLongForm();

    const getCaptions = useCallback(async (captions, string, maxLength = MAX_INPUT_LENGTH) => {
        const iterations = Math.ceil(captions.length / maxLength);
        const captionsList = [];
        for (let i = 0; i < iterations; i++) {
            const start = i * maxLength;
            const end = (i + 1) * maxLength;
            const caption = captions?.substring(start, end);
            captionsList.push(caption);
        }

        const promises = await Promise.all(captionsList.map(data => getSummaryLongForm(data, string)));
        return promises.join(' ');
    }, [getSummaryLongForm]);

    const getReducedCaptions = useCallback(async (captions, string, maxLength = MAX_INPUT_LENGTH) => {
        //generate captions summary splitting by MAX characters
        const result = await getCaptions(captions, string, maxLength);
        if (result.length > 30000) {
            return await getReducedCaptions(result, string, MAX_INPUT_FOR_SUMMARY);
        } else {
            return result
        }
    }, [getCaptions]);

    return getReducedCaptions
}