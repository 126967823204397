import React, {useContext, useEffect} from 'react';

import {YoutubeComponent} from '../Youtube.component';
import {useGetAuthUrlYoutube} from '../hooks';
// import {setData} from '../../../context/firebase/firebaseRequests';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const YoutubeContainer = (props) => {
    const {as: Component = YoutubeComponent, id} = props;
    const {user, pref, fetchPref} = useContext(FirebaseContext);

    const {getAuthUrlYoutube} = useGetAuthUrlYoutube();

    const handleGetUrl = async (userData) => {
        const userId = userData?.id || id;
        const date = new Date();
        const data = {
            testWithLogin: !!userData,
            date: date.toLocaleString(),
        };

        const resp = await getAuthUrlYoutube();
        // await setData('youtubeTestActions', userId, data);

        if (resp?.data?.url) {
            window.location = resp?.data?.url;
        }
    };

    useEffect(() => {
        if (user?.id && !pref ) {
            (async () => await fetchPref())();
        }
    }, [user?.id]);
    return <Component {...props} onGetUrl={handleGetUrl} pref={pref}/>;
};
