import {useLazyQuery} from '@apollo/client';
import {GetVideoList} from '../graphQL/queries/GetVideoList';

export function useGetVideoListByString(query = GetVideoList) {
    const [getVideoList, {data, loading, error}] = useLazyQuery(query);
    return {
        data,
        loading,
        error,
        getVideoList
    };
}