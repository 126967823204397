import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';

import { useUnsubscribeEvent } from '../event/hooks/useUnsubscribeEvent';
import { StudentsTableComponent } from './StudentsTable.component/StudentsTable.component';
import { Svg } from '../common';
import {useLocalePrefix} from '../lang';


import { SuggestionsComponent } from '../suggestions/Suggestions.component';
import { InvitesFormCourseContainer } from '../event/InvitesFormCourse.container/InvitesFormCourse.container';
import { StudentsResultsCards } from './StudentsResultsCards';
import { StudentProgressHeatmap } from './StudentProgressHeatmap/StudentProgressHeatmap';
import { checkAnswer } from '../common/utils';
// import { LessonRadialChart } from './LessonRadialChart';
import { AnalyseLessonDistributionContainer } from './AnalyseLessonDistribution.container';
import {EditCertificateComponent, useGetUserCertificates} from '../certificates';

export const StudentsTableTabContainer = ({ userList, event, ...other }) => {
  const studentData = [];

  const [suggestions, setSuggestions] = useState([]);
  const { unsubscribeEvent } = useUnsubscribeEvent();
  const {getUserCertificates, data: userCertificatesData} = useGetUserCertificates();
  const [participants, setParticipants] = useState(userList);
  const [editCert, setEditCert] = useState();
  const { formatMessage } = useIntl();
  const [localePrefix] = useLocalePrefix();

  const handleRemoveParticipant = async (userId) => {
    try {
      const userSubscription = (
        await unsubscribeEvent({
          variables: {
            eventId: event.id,
            userId: userId,
          },
        })
      ).data?.userSubscription;

      setParticipants((prev) => (prev.filter((e) => e.id !== userSubscription.id)));
    } catch (e) {
      console.warn(e);
    }

  };
  const eventTasks = event?.tasks?.map(task => task.id);

  const options = { day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
  participants?.forEach((subscription, i) => {
    const answersEvent = subscription?.student?.results?.filter(result =>
      eventTasks?.includes(result.taskId)
    ).map(result => ({
      ...result,
      task: event.tasks?.find(task => task.id === result.taskId)
    }

    ));

    const completedTasksNumber = subscription?.student?.results?.filter(result =>
      eventTasks?.includes(result?.taskId)
      // && result.state !== "INCORRECT"
    ).length;
    const progress = Math.floor((completedTasksNumber / eventTasks?.length) * 100);
    studentData.push({
      user: {
        name: subscription.student?.name,
        id: subscription.student?.id,
        picture: subscription.student?.picture,
        email: subscription.student?.email,
      },
      key: subscription.student?.id !== 10 ? i : subscription.student?.id,
      lastVisit: subscription.student?.lastVisit ? new Date(subscription.student?.lastVisit).toLocaleString('en-GB', options) : null,
      startDate: new Date(subscription.start).toLocaleString('en-GB', options),
      role: subscription?.role?.toLowerCase() || '',
      progress: {
        all: completedTasksNumber,
        progress: progress,
        answers: answersEvent,
        userId: subscription.student?.id,
      },
      results: subscription.student?.results?.length,
      answers: subscription.student?.results,
      email: subscription.student?.email || subscription.invitedEmail,
      delete: subscription.student,
    });

  })

  const additionalColumns = useMemo(() => {
    const columns =   [
      {
        title: formatMessage({ id: "table_role" }),
        dataIndex: 'role',
        sorter: (a, b) => {
          const roleA = a.role || '';
          const roleB = b.role || '';
          return roleA.localeCompare(roleB);
        },
        render: (role) => role || '',
      },
      {
        title: '',
        // key: 'action',
        dataIndex: 'delete',
        render: (user) => (
            <Button
                className='inline-el'
                type='text'
                onClick={() =>
                    handleRemoveParticipant(user.id)
                }
            >
              <Svg
                  fill='#ff5f49'
                  name=':icon/cross'
                  className='btn-icon icon_16'
              />
            </Button>
        ),
      },
    ];

    if (userCertificatesData?.getUserCertificates?.length) {
      const certificateColumn = {
        title: 'Certificate',
        render: (data) => {
          const userId = data.user.id;
          const cert = userCertificatesData.getUserCertificates.find((c) => c.userId === userId);

          return !cert ? null : (
              <Button type="text" onClick={() => setEditCert(cert)}>
                <span>{formatMessage({id: 'edit_certificate_btn'})}</span>
              </Button>
          )
        }
      }
      columns.unshift(certificateColumn);
    }

    return columns;
  }, [userCertificatesData?.getUserCertificates]);

  const allAnswers = useMemo(() => {
    let allAnswers = []
    const allResults = studentData?.flatMap(student => {
      student.progress?.answers?.forEach(el => {

        // fix bug
        // if (el?.description?.trim() !== '') {
        //     console.log(el?.description, 'el?.description')
        //     allAnswers.push({
        //         user: student.user,
        //         result: el,
        //         createDate: el.modifyDate,
        //         task: el.task,
        //     })
        // }

        el.answers?.forEach(answer => {
          const question = el.task?.questions?.find(question => question.id === answer.questionId);
          allAnswers.push({
            ...answer,
            student: student.user,
            result: el,
            status: checkAnswer(answer, question),
            question: question,
            task: el.task,
          });
        });
      })
    }
    );

    return allAnswers
      .sort((a, b) => new Date(b.createDate) - new Date(a.createDate))

  }, [studentData]);

  let questions = event?.tasks?.flatMap(task => task?.questions || [])

  const startedData = useMemo(() => {
    const data = studentData.map(student => ({
      name: student.user.name,
      studentId: student.user.id,
      questions: Array(questions?.length)?.fill(null)
    }));

    allAnswers.forEach(answer => {
      const index = questions.findIndex(question => question.id == answer.questionId);
      const indexStudent = data.findIndex(student => student.studentId == answer?.student?.id);

      if (answer.status === 'CORRECT') {
        data[indexStudent].questions[index]++;
      } else if (answer.status === 'INCORRECT') {
        data[indexStudent].questions[index] = 0;
      }
    });

    return data;
  }, [studentData, questions, allAnswers]);

  useEffect(() => {
    setParticipants(userList)
  }, [userList]);

    useEffect(() => {
        const {certificate} = event || {};
        if (certificate) {
            (async () => await getUserCertificates({
                    variables: {
                        certificateId: certificate.id
                    }
                }
            ))();
        }
    }, [event?.id]);

  return (
    <div>
      <AnalyseLessonDistributionContainer
        lessons={questions}
        setSuggestions={setSuggestions} />
      {/* <LessonRadialChart lessons={questions} /> */}
      {!!suggestions?.length &&
        <div className="wrapper_outer-section">
          <SuggestionsComponent suggestions={suggestions} event={event} />
          <hr />
        </div>
      }
      <StudentsResultsCards
        studentData={studentData}
        setSuggestions={setSuggestions}
        event={event}
      />
      {!!startedData?.length &&
        <StudentProgressHeatmap startedData={startedData} />
      }
      <hr />
      <InvitesFormCourseContainer
        {...other}
        event={event}
      />
      <div className="wrapper_outer-section">

        <StudentsTableComponent
          userList={userList}
          event={event}
          studentData={studentData}
          handleRemoveParticipant={handleRemoveParticipant}
          additionalColumns={additionalColumns}
          {...other}
        />
      </div>
      <EditCertificateComponent editCert={editCert}
                                setEditCert={setEditCert}/>
    </div>
  );
};

