import { gql } from "@apollo/client";

export const GetSpecs = gql`
  query Specs {
      specs {
          id
          title
          description
          person
          icon
          color
          counter
          subjects {
              id
              title
          }
      }
  }
`;