import {useCallback, useContext} from 'react';
import {useIntl} from 'react-intl';
import {useMutation} from '@apollo/client';
import {ConfirmEmail} from '../graphQL/mutations/ConfirmEmail';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {useHtmlEmail} from '../../resend';
import {openNotification} from '../../notification/Notification.component';

export const useConfirmEmail = (mutation = ConfirmEmail) => {
    const [confirmEmailCallback, {data, error, loading}] = useMutation(mutation);
    const {user, workspace} = useContext(FirebaseContext);
    const {getHtmlConfirmEmail} = useHtmlEmail();
    const { formatMessage } = useIntl();

    const confirmEmail = useCallback(async () => {
        const {id, email} = user;
        const {resend = {}, title: appName = 'Unschooler'} = workspace || {};
        const {email: resendEmail, domain = {}} = resend;
        const {status} = domain;
        const from = status === 'verified' ? `${appName} <${resendEmail}>` : `${appName} <info@unschooler.me>`;

        const location = window.location;
        const confirmEmailUrl = `${location.origin}/email-verification?token=`;
        const content = getHtmlConfirmEmail({confirmEmailUrl, email, appName});

        const resp = await confirmEmailCallback({
            variables: {
                id,
                email,
                from,
                content,
                subject: formatMessage({id: 'email_confirmation_text'})
            }
        });

        const confirmEmailResp = resp?.data?.confirmEmail;

        if (confirmEmailResp) {
            openNotification('topLeft', {
                title: `${formatMessage({id: 'verification_sentage_title'})} ${email}`,
                icon: '✉️',
                duration: 10,
            });
        } else {
            openNotification('topLeft', {
                title: formatMessage({id: 'wrong_alert_title'}),
                icon: '✉️',
                duration: 10,
            });
        }
        return resp;
    }, [workspace, confirmEmailCallback, user]);

    return {
        data,
        error, loading,
        confirmEmail
    };
}