import { gql } from "@apollo/client";

export const GetTags = gql`
query GetTags($subjectId: Int, $tagIds: [Int]) {
  tags(subjectId: $subjectId, tagIds: $tagIds) {
    id
    subject {
      icon
      color
      title
      subjectUid
      id
    }
    title
    icon
    defIcon
    countResults
  }
}`;