import React, { useContext, useEffect } from "react";
import { FirebaseContext } from "./context/firebase/firebaseContext";

import { Main } from "./Components/Main";
import { BrowserRouter } from "react-router-dom";
import { I18nProvider } from "./lang";
import {addPartnero} from './Components/common';

export default function App() {
    const { user, fetchUser, loadingUser, locale, workspace, updateUserData, workspaceLoading, onUpdateWorkspace } = useContext(FirebaseContext);
    useEffect(() => {
        addPartnero();
        (async () => fetchUser())();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

return (
        <BrowserRouter className="App">
            <I18nProvider locale={locale}>
                {/* {workspace?.id && (
                    <div className="error-message">
                        Service may be unstable until 15:00 CET as we are deploying a new version.
                    </div>
                )} */}
                <Main user={user}
                      loading={loadingUser}
                      workspace={workspace}
                      workspaceLoading={workspaceLoading}
                      updateUserData={updateUserData}
                      onUpdateWorkspace={onUpdateWorkspace}/>
            </I18nProvider>
        </BrowserRouter>
    );
}
