import React, {useEffect, useContext} from 'react';
import {IntegrationsComponent} from '../Integrations.component';
import {useGetUserIntegrations} from '../hooks';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const IntegrationsContainer = () => {
    const {user, workspace} = useContext(FirebaseContext);
    const {id: userId} = user || {};
    const {id: workspaceId} = workspace || {};
    const {getUserIntegrations, data, loading} = useGetUserIntegrations();

    useEffect(() => {
        if (userId && workspaceId) {
            (async () => {
                await getUserIntegrations({
                    variables: {
                        userId,
                        workspaceId
                    }
                })
            })()
        }
    }, [userId, workspaceId]);

    return <IntegrationsComponent integration={data?.getUserIntegrations?.integrations?.[0]}
                                  loadingIntegration={loading}/>
}