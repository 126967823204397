import './scss/index.scss'
import React from 'react';
import {useIntl} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGlobe, faUserClock, faMobileAlt, faSeedling, faVideo, faBookReader,
    faRobot, faComments, faBook,
    faClock, faUsers, faDumbbell, faCheckCircle, faLayerGroup
} from '@fortawesome/free-solid-svg-icons';

const iconMap = {
    faGlobe, faUserClock, faMobileAlt, faSeedling, faVideo, faBookReader,
    faRobot, faComments, faClock, faUsers, faDumbbell, faCheckCircle, faLayerGroup, faBook
};

export const IncludesComponent = ({ features, includes }) => {
    const { formatMessage } = useIntl();

    return (
        <div className="booking__promo ">
            <h3>{formatMessage({ id: 'includes_courseIncludes' })}</h3>
            <ul className="wrapper_list feature-list">
                {includes.map((feature, index) => (
                    <li key={`feature-item_${index}`} className="feature">
                        <FontAwesomeIcon icon={iconMap[feature.icon]}  size="lg"/>
                        <span>{feature.text}</span>
                    </li>
                ))}
            </ul>
            <hr />

            <ul className="wrapper_list feature-list">
                {features.map((feature, index) => (
                    <li key={index} className="feature">
                        <FontAwesomeIcon icon={iconMap[feature.icon]}  size="lg" />
                        <span>{feature.text}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}
