import { findIndex, orderBy } from 'lodash';
import PROFESSIONS_SKILLS from '../../graph/data/professions_skills_new.json';

export const getProfessions = (allSkills, careers) => {
    let professionsList = [];
    allSkills?.forEach((tag) => {
        let numberId = parseInt(tag.id);

        let array = PROFESSIONS_SKILLS.filter((el) => el[1].includes(numberId));

        array.length &&
            array?.forEach((prof) => {
                let profExist = findIndex(professionsList, ['id', prof[0]]);
                let careerIsInThisList = careers.find((career) =>
                    career.id === parseInt(prof[0])
                );

                if (profExist >= 0) {
                    professionsList[profExist].count =
                        professionsList[profExist].count + 1;
                } else {
                    if (careerIsInThisList) {
                        professionsList.push({
                            id: prof[0],
                            tags: prof[1],
                            count: 1,
                            title: careerIsInThisList.title,
                        });
                    }
                }

            });
    });
    return professionsList;
};

export const getModifiedProfessions = (allSkills, careers) => {
    let cloneCareers = [...careers];
    
    
    
    
    allSkills?.forEach((tag) => {
        let numberId = parseInt(tag.id);
        let array = PROFESSIONS_SKILLS.filter((el) => el[1].includes(numberId));

        array.length &&
            array?.forEach((prof) => {
                let careerIsInThisList = cloneCareers.find((career) =>
                    career.id === parseInt(prof[0])
                );

                if (careerIsInThisList) {
                    careerIsInThisList.counter = careerIsInThisList.counter + 1 ;
                    careerIsInThisList.tags = prof[1];

                }
            });
    });
};

export const getPopularProfAndSkills = (professionsList, limit) => {
    let orderedArray = orderBy(
        professionsList,
        [
            (el) => {
                return el.count / (el.tags.length / 100);
            },
        ],
        ['desc']
    );

    return orderedArray.slice(0, limit)
};

