import React from "react";
import { LoaderTypeComponent } from "../../common";

export const LoaderComponent = (props) => {
    const { type = "full", label } = props;

    return (
        <div className={`loader-component loader-component_${type}`}>
            <LoaderTypeComponent label=" " />
            {label && (
                <small className="text_secondary">{label}</small>
            )}
        </div>
    );
};
