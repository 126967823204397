import React, {useRef} from 'react';
import {Input, Button} from 'antd';
import {LoaderComponent} from '../../../common';

export const UserComponent = ({user, loading, error, onGetUser}) => {
    const fieldIdRef = useRef();
    const fieldEmailRef = useRef();

    return <div className="user-component">
        <h2>User data</h2>
        <div className="flex-container mb-m-l">
            <Input ref={fieldIdRef}
                   placeholder="User ID"
                   style={{maxWidth: '200px'}}/>
            <Button type="secondary"
                    onClick={() => {
                        if (!!fieldIdRef.current.input.value) {
                            onGetUser({id: parseInt(fieldIdRef.current.input.value, 10)});
                        }
                    }}>
                <span>
                    {loading && <LoaderComponent type="inline"/>}
                    Get user data
                </span>
            </Button>
        </div>
        <div className="mb-m-l">
            or
        </div>
        <div className="flex-container">
            <Input ref={fieldEmailRef}
                   placeholder="User Email"
                   style={{maxWidth: '200px'}}/>
            <Button type="secondary"
                    onClick={() => {
                        if (!!fieldEmailRef.current.input.value) {
                            onGetUser({email: fieldEmailRef.current.input.value.trim()});
                        }
                    }}>
                <span>
                    {loading && <LoaderComponent type="inline"/>}
                    Get user data
                </span>
            </Button>
        </div>
        {error && (
            <div className="error">
                Something went wrong.
            </div>
        )}
        {!!user && (
            <div>
                <hr/>
                <ul className="user-component__data">
                    <li>Id: <strong>{user?.id}</strong></li>
                    <li>Email: <strong>{user?.email}</strong></li>
                    <li>Subscription type: <strong>{user?.subscriptionUser?.type}</strong></li>
                    <li>Subscription start: <strong>{user?.subscriptionUser?.startDate}</strong></li>
                    <li>Subscription end: <strong>{user?.subscriptionUser?.endDate}</strong></li>
                    <li>Subscription isCanceled: <strong>{user?.subscriptionUser?.isCanceled.toString()}</strong></li>
                </ul>
            </div>
        )}
    </div>
}