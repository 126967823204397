import React, {useCallback, useMemo} from 'react';
import {StripeUserSubscriptionComponent} from '../StripeUserSubscription.component';
import {getDateData} from '../../common';
import {useUpdateStripeSubscription} from '../hooks';

export const StripeUserSubscriptionContainer = ({userSubscription = {}, stripeAccountId, ...other}) => {
    const {end, isCanceled: canceled, stripeSubscriptionId} = userSubscription;
    const {updateStripeSubscription, data, loading} = useUpdateStripeSubscription();
    const endDateData = getDateData(end);
    const isCanceled = useMemo(() => (
        data ? data.updateStripeSubscription.canceled : canceled
    ), [canceled, data]);

    const handleUpdate = useCallback(async () => {
        await updateStripeSubscription({
            variables: {
                subscriptionId: stripeSubscriptionId,
                isCancel: !isCanceled,
                ...(!!stripeAccountId && {stripeAccount: stripeAccountId})
            }
        });
    }, [stripeSubscriptionId, stripeAccountId, isCanceled]);

    return <StripeUserSubscriptionComponent {...other}
                                            endDateData={endDateData}
                                            isCanceled={isCanceled}
                                            isSubscription={!!stripeSubscriptionId}
                                            loading={loading}
                                            onUpdate={handleUpdate}/>
}