import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useHtmlEmail = () => {
    const { formatMessage } = useIntl();

    const getHtmlInviteEmail = useCallback((data) => {
        const { ownerName, schoolTitle, inviteUrl } = data || {};
        return `<div style="margin:0px auto; max-width:600px; font-family:sans-serif; font-size:14px; line-height: 2;">
    <table align="middle" border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
        <tbody>
            <tr>
                <td>${formatMessage({ id: 'hello' })},</td>
            </tr>
            <tr>
                <td>${formatMessage({ id: 'email_inviteschool_title' })} ${schoolTitle}, ${ownerName}: <a href="${inviteUrl}" target="_blank">${inviteUrl}</a></td>
            </tr>
            <tr>
                <td>${formatMessage({ id: 'email_invite_login' })}:</td>
            </tr>
            <tr>
                <td style="padding-top: 20px;">
                    <a href="${inviteUrl}" style="display: inline-block; background:#000000; font-family: sans-serif; font-weight: normal; line-height: 1.2; text-decoration: none; padding: 12px 16px; border-radius: 4px; color: #ffffff; font-size:16px;" target="_blank">
                        ${formatMessage({ id: 'event_enroll_course' })}
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>`
    }, [formatMessage]);

    const getHtmlInviteToCourseEmail = useCallback((data) => {
        const { ownerName, inviteUrl, eventTitle, content } = data || {};
        return `<div style="margin:0px auto; max-width:600px; font-family:sans-serif; font-size:14px; line-height: 2;">
    <table align="middle" border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
        <tbody>
            <tr>                
                <td>
                 ${content ? content : `${formatMessage({ id: 'hello' })},<br>${formatMessage({ id: 'email_invitecourse_title' })} ${eventTitle}, ${ownerName}: <a href="${inviteUrl}" target="_blank">${inviteUrl}</a>`}
                 </td>
            </tr>
            <tr>
                <td>${formatMessage({ id: 'email_invite_login' })}:</td>
            </tr>
            <tr>
                <td style="padding-top: 20px;">
                    <a href="${inviteUrl}" style="display: inline-block; background:#000000; font-family: sans-serif; font-weight: normal; line-height: 1.2; text-decoration: none; padding: 12px 16px; border-radius: 4px; color: #ffffff; font-size:16px;" target="_blank">
                    ${formatMessage({ id: 'event_preenroll' })}
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>`
    }, [formatMessage]);

    const getHtmlResetPasswordEmail = useCallback((data) => {
        const { resetPasswordUrl, email, appName } = data || {};

        return `<div style="margin:0px auto; max-width:600px; font-family:sans-serif; font-size:14px; line-height: 2;">
    <table align="middle" border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
        <tbody>
            <tr>
                <td>${formatMessage({ id: 'hello' })},</td>
            </tr>
            <tr>
                <td>${formatMessage({ id: 'reset_link_instruction' }, { appName, email })}</td>
            </tr>
            <tr>
                <td style="padding-top: 20px; padding-bottom: 40px;">
                    <a href="${resetPasswordUrl}" style="display: inline-block; background:#000000; font-family: sans-serif; font-weight: normal; line-height: 1.2; text-decoration: none; padding: 12px 16px; border-radius: 4px; color: #ffffff; font-size:16px;" target="_blank">
                        ${formatMessage({ id: 'reset_password_button' })}
                    </a>
                </td>
            </tr>
            <tr>
                <td>${formatMessage({ id: 'no_reset_notification' })}</td>
            </tr>
            <tr>
                <td>${formatMessage({ id: 'thanks_team_part1' })}</td>
            </tr>  
            <tr>
                <td>${formatMessage({ id: 'thanks_team_part2' })}</td>
            </tr>          
        </tbody>
    </table>
</div>`
    }, [formatMessage]);

    const getHtmlConfirmEmail = useCallback((data) => {
        const { confirmEmailUrl, email, appName } = data || {};

        return `<div style="margin:0px auto; max-width:600px; font-family:sans-serif; font-size:14px; line-height: 2;">
    <table align="middle" border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
        <tbody>
            <tr>
                <td>${formatMessage({ id: 'hello' })},</td>
            </tr>
            <tr>
                <td>Click this link to verify your email.</td>
            </tr>
            <tr>
                <td style="padding-top: 20px; padding-bottom: 40px;">
                    <a href="${confirmEmailUrl}" style="display: inline-block; background:#000000; font-family: sans-serif; font-weight: normal; line-height: 1.2; text-decoration: none; padding: 12px 16px; border-radius: 4px; color: #ffffff; font-size:16px;" target="_blank">
                        Verify email
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>`
    }, [formatMessage]);

    const getCustomHtml = useCallback((data) => {
        const { title, content, user } = data || {};
        return ` <html>
                <body>
                    <p>${content}</p>
                    <hr/>
                    <p>${user?.name ? user?.name : ''}, ${title}</p>
                </body>
            </html>`
    }, []);

    return { getHtmlInviteEmail, getHtmlInviteToCourseEmail, getHtmlResetPasswordEmail, getHtmlConfirmEmail, getCustomHtml }
}
