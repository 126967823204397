import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { openNotification } from '../Components/notification/Notification.component';
import { useLocalePrefix } from '../Components/lang';
import { useGetChannelId } from '../Components/youtube/hooks';
import { LoaderComponent } from '../Components/common';

export const YoutubeCallback = ({ user }) => {
    const { formatMessage } = useIntl();
    const { search } = useLocation();
    const history = useHistory();
    const [localePrefix] = useLocalePrefix();
    const { getChannelId } = useGetChannelId();
    const { id, alias } = user || {};
    const urlParams = new URLSearchParams(search);

    const onError = (err) => {
        openNotification('topLeft', {
            title: 'We cannot connect to your YouTube account',
            description: `Make sure you are not in incognito mode and are logged in Google.`,
            icon: `👩‍🚀`,
            duration: 10
        });
        console.warn(err);
    };

    const onShowMessage = () => {
        openNotification('topLeft', {
            title: formatMessage({ id: 'connecting_to_youtube_channel' }),
            description: 'YouTube channel was connected.',
            icon: `👩‍🚀`,
            duration: 10
        });
    };

    const handleGetChannelId = async (search, id) => {
        const token = urlParams.get('code');
        const workspaceUrl = urlParams.get('state');
        const error = urlParams.get('error');

        if (error) {
            onError('Access denied');
        }

        if (token) {
            try {
                const result = await getChannelId({
                    variables: { token }
                });
                const id = result?.data?.getChannelId?.id;
                window.localStorage.setItem('channelId', id);

                if (workspaceUrl) {
                    window.location.href = workspaceUrl + '/callback/youtube?' + new URLSearchParams({workspaceChannelId: id })
                } else {
                    onShowMessage();
                    history.push(`${localePrefix}/`, { channelId: id });
                }
            } catch (err) {
                onError(err);
            }
        }
    };

    const redirectToWorkspace = (channelId) => {
        window.localStorage.setItem('channelId', channelId);
        onShowMessage();
        history.push(`${localePrefix}/`, { channelId });
    }

    useEffect(() => {
        if (search && id) {
            const channelId = urlParams.get('workspaceChannelId');
            if (channelId) {
                redirectToWorkspace(channelId)
            } else {
                (async () => await handleGetChannelId(search, id))();
            }
        }
    }, [search, id]);

    return (
        <div className="page">
            <h1>Getting data from YouTube</h1>
            <p>
                We are receiving data from YouTube, this may take some time. Please wait while the data is loaded and
                processed.
            </p>
            <LoaderComponent type="full" />
        </div>
    );
};
