import {last} from 'lodash';

const getLastDate = (data) => {
    if(!data) return null;
    const lastItem = last(data);
    return lastItem.dateUnix;
}

export const getGroupedPayments = (data) => {
    const list = [];

    data?.forEach(item => {
        const firstItem = list.find(listItem => listItem?.eventId === item.event.id);
        if (firstItem) {
            firstItem.paymentsCount++;
            firstItem.paymentsList.push(item.payment);
        } else {
            list.push({
                eventId: item.event.id,
                participantsLimit: item.event?.participantsLimit,
                cost: item?.payment?.cost || parseFloat((item?.payment?.mentorAmount * parseInt(item.event?.participantsLimit || 1)).toFixed(2)),
                userCost: item?.payment?.userCost || parseFloat((item.payment.cost / parseInt(item.event?.participantsLimit || 1)).toFixed(2)),
                paymentsCount: 1,
                paymentsList: [item.payment],
                date: getLastDate(item.event?.timeTable),
                title: item?.event?.title,
                isGroup: item.event?.isGroup
            });
        }
    });
    return list;
}