import React, {useContext, useMemo} from 'react';

import {LoaderProgressComponent} from '../LoaderProgress.component';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const LoaderProgressContainer = () => {
    const {inProgress} = useContext(FirebaseContext);
    const isProgress = useMemo(() => {
        const {message} = inProgress || {};
        return !!message;
    }, [inProgress]);

    return (
        <>
            {!!isProgress && <LoaderProgressComponent {...inProgress}/>}
        </>
    )
}