import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { MetaComponent, TitleComponent } from '../Components/common';
import {ReactMarkdownComponent} from "../Components/task/ReactMarkdown.component/ReactMarkdown.component";
import {PermanentPrivacy} from '../Components/footer';

export const PolicyStudent = ({workspace = {}}) => {
    const { formatMessage } = useIntl();
    const {customPolicy} = workspace;
    const metaMemo = useMemo(() => {
        const title = formatMessage({ id: 'meta_title_policy' });
        const description = formatMessage({ id: 'meta_description_policy' });

        const meta = {
            meta_title: title,
            meta_description: description,
        };
        const og = {
            title: title,
            description: description,
        };

        const twitter = {
            tw_title: title,
            tw_description: description,
        };

        return { meta, og, twitter };
    }, []);

    return (
        <>
            <MetaComponent
                meta={metaMemo.meta}
                og={metaMemo.og}
                twitter={metaMemo.twitter}
            />

            <div className='page'>
                <TitleComponent title={formatMessage({ id: 'policy' })} back={true} />
                <div className='page-content'>
                    {customPolicy && (
                        <ReactMarkdownComponent children={customPolicy}/>
                    )}
                     <PermanentPrivacy />
                </div>
            </div>
        </>
    );
};
