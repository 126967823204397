import {Button, Form, Input, Radio, Slider, Switch, Tag} from "antd";
import {useEffect, useState} from "react";
import {Loader} from "../../Loader";
import {Link, useHistory} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useLocalePrefix} from '../../lang';

const initalPromocode = {
    count: 0,
    discountPercent: 0,
    discountSum: 0,
    enabled: false
}

export const Promocode = (props) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [event, setEvent] = useState("");
    const [user, setUser] = useState("");
    const [promocode, setPromocode] = useState(null);
    const [localePrefix] = useLocalePrefix();

    const promocodeData = useQuery(PROMOCODE_BY_ID, {
        variables: {
            id: props.match.params.id
        },
        fetchPolicy: "no-cache"
    });

    const [createPromocode, CreatePromocodeData] = useMutation(CREATE_PROMOCODE);
    const [updatePromocode, UpdatePromocodeData] = useMutation(UPDATE_PROMOCODE);

    function handleAddEvent() {
        setPromocode(p => ({
            ...p,
            events: [...(p.events || []), event]
        }));
        setEvent("");
    }

    function handleAddUser() {
        setPromocode(p => ({
            ...p,
            users: [...(p.users || []), user]
        }));
        setUser("");
    }

    async function removeEvent(i) {
        const events = promocode.events.filter((e, j) => i !== j)
        setPromocode((p) => ({
            ...p,
            events
        }));
    }

    async function removeUser(i) {
        const users = promocode.users.filter((e, j) => i !== j)
        setPromocode((p) => ({
            ...p,
            users
        }));
    }

    async function handleCreatePromocode() {
        await createPromocode({
            variables: {
                content: {
                    ...promocode,
                    count: Number(promocode.count),
                    discountSum: Number(promocode.discountSum)
                }
            }
        });
        history.push("/admin/promocodes")
    }

    async function handleUpdatePromocode() {
        await updatePromocode({
            variables: {
                content: {
                    ...promocode,
                    count: Number(promocode.count),
                    discountSum: Number(promocode.discountSum)
                }
            }
        });
    }

    useEffect(() => {
        form.setFieldsValue(promocode);
    }, [promocode, form])

    useEffect(() => {
        if (promocodeData.data)
            setPromocode(promocodeData.data.promocodeById);
        else
            setPromocode(initalPromocode);
    }, [promocodeData.data])

    if (promocodeData.loading) {
        return <Loader />;
    }

    const isCreatePromocode = !promocode.id;


    return (
        <div className="AdminNavigation">
            <h1>{isCreatePromocode ? 'Создание промокода' : 'Редактирование промокода'}</h1>
            <Form form={form} layout="vertical" onFinish={isCreatePromocode ? handleCreatePromocode : handleUpdatePromocode}>

                <Form.Item label="Статус промокода" name="enabled" rules={[{ required: true }]}>
                    <Switch checked={promocode.enabled} onChange={(e) => setPromocode((p) => ({ ...p, enabled: e }))} />
                </Form.Item>

                <Form.Item label="Промокод, который должен ввести user" name="text" rules={[{ required: true }]}>
                    <Input value={promocode.text} onChange={(e) => setPromocode((p) => ({ ...p, text: e.target.value }))} />
                </Form.Item>

                <Form.Item label="Где применим промокод" name="type" rules={[{ required: true }]}>
                    <Radio.Group onChange={(e) => setPromocode((p) => ({ ...p, type: e.target.value }))} value={promocode.type}>
                        <Radio value="event">Для занятий</Radio>
                        <Radio value="user">Для менторов</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="countable" label="Указатель того, может ли пользователь использовать один и тот же промокод несколько раз, на нескольких ивентах" rules={[{ required: true }]}>
                    <Radio.Group onChange={(e) => setPromocode((p) => ({ ...p, countable: e.target.value }))} value={promocode.countable}>
                        <Radio value="one">Одноразовый</Radio>
                        <Radio value="many">Многоразовый</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label="Сумма скидки" name="discountSum">
                    <Input value={promocode.discountSum} onChange={(e) => setPromocode((p) => ({ ...p, discountSum: e.target.value }))} />
                </Form.Item>

                <Form.Item label={"Размер скидки, " + promocode.discountPercent || 0 + "%"}>
                    <Slider min={0} max={100} step={1} value={promocode.discountPercent || 0} onChange={(e) => setPromocode((p) => ({ ...p, discountPercent: e }))} />
                </Form.Item>

                <Form.Item label="Количество промокодов.">
                    <Input value={promocode.count} type="number" onChange={(e) => setPromocode((p) => ({ ...p, count: e.target.value }))} />
                </Form.Item>

                <Form.Item label="Дата-время c которой промокод доступен для использования">
                    <Input
                        value={new Date(promocode.startDate || 0).toISOString().split(".")[0]}
                        type="datetime-local"
                        onChange={(e) => setPromocode((p) => ({ ...p, startDate: new Date(e.target.value).getTime() }))}
                    />
                </Form.Item>

                <Form.Item label="Дата-время до которой промокод доступен для использования.">
                    <Input
                        value={promocode.endDate && new Date(promocode.endDate).toISOString().split(".")[0]}
                        type="datetime-local"
                        onChange={(e) => setPromocode((p) => ({ ...p, endDate: new Date(e.target.value).getTime() }))}
                    />
                </Form.Item>

                <Form.Item label="События:">
                    <div>
                        {!promocode.events &&
                            <div>
                                Если занятия не указаны промокод дейсвует на все сразу
                            </div>
                        }
                        {
                            promocode.events && promocode.events.map((event, i) => (
                                <Tag
                                    key={event}
                                    closable
                                    onClose={() => removeEvent(i)}
                                >{event}</Tag>
                            ))
                        }
                    </div>
                    <div className="inline-el" style={{ marginTop: 16 }}>
                        <div className="inline-el input-parent">
                            <Input value={event} onChange={(e) => setEvent(e.target.value)} />
                        </div>
                        <div className="inline-el">
                            <Button onClick={handleAddEvent}>
                                Добавить событие
                            </Button>
                        </div>
                    </div>
                </Form.Item>

                <Form.Item label="Менторы:">
                    <div>
                        {!promocode.users &&
                            <div>
                                Если менторы не указаны промокод дейсвует на всех сразу
                            </div>
                        }
                        {
                            promocode.users && promocode.users.map((user, i) => (
                                <Tag
                                    key={user}
                                    closable
                                    onClose={() => removeUser(i)}
                                >{user}</Tag>
                            ))
                        }
                    </div>
                    <div className="inline-el" style={{ marginTop: 16 }}>
                        <div className="inline-el input-parent">
                            <Input value={user} onChange={(e) => setUser(e.target.value)} />
                        </div>
                        <div className="inline-el">
                            <Button onClick={handleAddUser}>
                                Добавить ментора
                            </Button>
                        </div>
                    </div>
                </Form.Item>

                <Form.Item>
                    <Button loading={CreatePromocodeData.loading || UpdatePromocodeData.loading} htmlType="submit">
                        {isCreatePromocode ? 'Создать' : 'Сохранить'}
                    </Button>
                </Form.Item>

            </Form>
            {!isCreatePromocode && <Link to={{ pathname: `${localePrefix}/admin/promocode-used-by`, state: { promocode }}}>Показать пользовталей использовавших промокод</Link>}
        </div>
    );
}

const CREATE_PROMOCODE = gql`
    mutation CreatePromocode($content: createPromocodeInput!) {
        createPromocode(content: $content) {
            id
        }
    }
`;

const UPDATE_PROMOCODE = gql`
    mutation UpdatePromocode($content: updatePromocodeInput!) {
        updatePromocode(content: $content) {
            id
            users
        }  
    }
`;

const PROMOCODE_BY_ID = gql`
    query PromocodeById($id: String!) {
        promocodeById(id: $id) {
            id
            text
            events
            users
            type
            count
            countable
            startDate
            endDate
            discountPercent
            discountSum
            enabled
        }
    }
`;
