import './scss/index.scss';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CircleIndexComponent } from '../../common/CircleIndex.component/CircleIndex.component';
import { SuperTextComponent } from '../../common/SuperText.component/SuperText.component';

export const StepCardComponent = (props) => {
    const { description, src, index, shear, color, title } = props;

    return (
        <div
            className={`step_wrapper ${index === 2 ? 'step_2' : index === 3 ? 'step_3' : ''}`}
            style={{ backgroundColor: color || "#2962FF" }}
        >
            <div className='step_picture'>
            <video
                    src={src}
                    className="media"
                    autoPlay
                    loop
                    muted
                    playsInline
                    poster={src}
                >
                    <img src={src} alt={description} className="media" />
                </video>

                {/* <LazyLoadImage src={src}
                    alt={description} /> */}
            </div>
            <div className='step_description'>
                <div>
                    <h2>{title}</h2>
                    <SuperTextComponent description={description} />
                </div>

                <CircleIndexComponent index={index} />
            </div>
        </div>
    );
};
