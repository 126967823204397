import { gql } from '@apollo/client';

export const GetCertificateSimple = gql`
    query GetCertificate($id: Int, $eventId: Int) {
        getCertificate(id: $id, eventId: $eventId) {
            id
            title
            description
            label
            icon
            type
            creatorId
            eventId
            users {
                id
                userName
            }
        }
    }
`;