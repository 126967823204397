import {gql} from '@apollo/client';

export const GetSkillsNew = gql`
    query ($userId: Int) {
        skillsNew(userId: $userId) {
            subject {
                title
                id
                color
                icon
            }
            tags {
                tag {
                    tag {
                        id
                        title
                    }
                    tags {
                        id
                        title
                    }
                }
                count
            }
            count
        }
    }
`;
