import '../scss/index.scss'
import React from "react";
import { FEATURESAPPSUMO, FEATURESAPPSUMOPLAN } from "../../constant.js";

export const PaymentPlansAppsumoComponent = ({
  user,
}) => {
  return (
    <div>
      <div>
        <h1>
          AppSumo Lifetime Plan {user.subscriptionUser.type.replace('_', ' ')}
        </h1>
        <p>Below are the features included in your plan:</p>
        <hr />
      </div>

      <div className="pricing-section">
        {FEATURESAPPSUMOPLAN[user?.subscriptionUser?.type].map((feature, i) => (
          <ul className="features_list" key={`appsumo_feature_${i}`}>
            <li className=" text-left">
              <span className='margin_right'>
                ✔
              </span>
              <strong>
                {feature?.link ?
                  <a href={feature?.link}>
                    {feature?.title}
                  </a>
                  :
                  feature?.title
                }
              </strong>
              <p>{feature?.description}</p>
            </li>
          </ul>
        ))}

        {FEATURESAPPSUMO.map((feature, i) => (
          <ul className="features_list" key={`appsumo_feature_second_${i}`}>
            <li className=" text-left">
              <span className='margin_right'>
                ✔
              </span>
              {feature?.link ?
                <a href={feature?.link}>
                  {feature?.title}
                </a>
                :
                feature?.title
              }
            </li>
          </ul>
        ))}

      </div>
      <hr />
      <p>
        <a className="link-btn-secondary ant-btn ant-btn-secondary ant-btn-sm" href="https://appsumo.com/account/products/ ">Manage subscription on AppSumo</a>
      </p>
    </div>
  );
};
