export const getStorageManager = () => {
    return {
        getStorageData: (key, storage = localStorage) => {
            const data = storage.getItem(key);
            return data && data !== 'undefined' ? JSON.parse(data) : null;
        },
        setStorageData: (key, data, storage = localStorage) => {
            return storage.setItem(key, JSON.stringify(data));
        },
        removeStorageData: (key, storage = localStorage) => {
            return storage.removeItem(key);
        },
        clearStorage: (storage = localStorage) => {
            return storage.clear();
        }
    };
};
