import './scss/index.scss';

import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { noop } from 'lodash';
import { Button, Upload } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { useIsAllow } from "../../common";

const PROD_UPLOAD_URL = 'https://back.unschooler.me/video/upload';
const DEV_UPLOAD_URL = 'http://localhost:4000/video/upload';

export const UploadVideoComponent = (props) => {
    const {
        onSetVideo = noop,
        savedImages = [],
    } = props;

    const { formatMessage } = useIntl();
    const [fileList, setFileList] = useState(savedImages);
    const [error, setError] = useState(false);

    const onChange = ({ fileList }) => {
        if (!error) {
            const { status } = fileList?.[0] || {};
            setFileList(fileList);
            if (status === 'done') {
                onSetVideo(fileList);
            }
        }
    };

    return (
        <div className="upload-video-component">
            <Upload action={PROD_UPLOAD_URL}
                name="file"
                // listType="picture-card"
                // fileList={fileList}
                onChange={onChange}
            >
            <Button type="secondary" icon={<UploadOutlined />}>
                {formatMessage({ id: 'upload_video' })}
            </Button>
            </Upload>

            {error && (
                <div className="error-message">
                    {formatMessage({ id: 'form_cover_error' })}
                </div>
            )}
        </div>
    )
}
