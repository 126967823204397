import React, { useState, useCallback } from 'react';

import { YoutubeCaptionsComponent } from '../YoutubeCaptions.component';
import { useGetSummary, useGetCommonAi, useGetExplainVideo, useReducedCaptions } from '../../ai/hook';

export const YoutubeCaptionsContainer = ({
    playerRef,
    inProgress,
    timestampsList,
    currentTimestamp,
    reloadPlayer,
    captions,
    videoSrc,
    handleEditVideoTask,
    task,
    user,
    // handleGenerateSummary,
    summary,
    ...other
}) => {

    const [timestamps, setTimestamps] = useState(timestampsList && [...timestampsList]);

    const { getSummary, error } = useGetSummary();
    const { getCommonAi, errorAi } = useGetCommonAi();
    const { getExplainVideo } = useGetExplainVideo();
    const [inProgressVideo, setInProgressVideo] = useState();

    const handleTimeStampClick = (timestamp) => {
        const string = Math.floor(parseInt(timestamp) / 1000);
        currentTimestamp.current = string;

        if (playerRef?.current?.seekTo) {
            playerRef?.current?.seekTo(string, true);
        } else {
            reloadPlayer(string);
        }
    };
    const getTime = () => {
        const playerInfo = playerRef?.current?.playerInfo || {};
        const { currentTime } = playerInfo;

        let time = Math.floor(currentTime * 1000);
        return time;
    };
    const handleAnswerTimeStamp = useCallback(
        async (e) => {
            setInProgressVideo(true);
            // setFieldsValue('');

            let time = getTime();
            if (captions) {
                let nextElementId = captions?.findIndex((el) => el.offset > time) || 1;
                let timeString = captions[nextElementId - 1]?.offset ? captions[nextElementId - 1]?.offset : 0;
                let stringArray;
                if (nextElementId < 5 || !nextElementId) {
                    stringArray = captions.slice(0, 10);
                } else {
                    stringArray = captions.slice(
                        nextElementId - 8,
                        nextElementId + 3
                    );
                }
                let stringContext = stringArray.map((el) => el?.text).join(' ');
                let title = window.YtPlayer?.videoTitle;

                const responseTrimmed = await getExplainVideo(
                    stringContext + 'Transcript from ' + title,
                    e?.ask?.trim(),
                    captions[nextElementId - 1]?.text
                );
                if (responseTrimmed) {
                    const captionIndex =
                        nextElementId - 1 >= 0 ? nextElementId - 1 : 0;
                    let newTimeStamp = {
                        timestamp: `${timeString}`,
                        title:
                            e.ask.trim() +
                            ': ' +
                            captions[captionIndex]?.text?.substring(0, 15) +
                            '…' || 'Explain',
                        description: responseTrimmed,
                    };

                    let timeStampsForSave = [...timestamps, newTimeStamp];
                    setTimestamps(timeStampsForSave);

                    let coverData = {
                        cover: {
                            type: 'YOUTUBE',
                            source: task.cover.source,
                            timestamps: timeStampsForSave,
                            id: task.cover.id,
                        },
                    };

                    user?.id === task?.creator?.id && handleEditVideoTask(coverData);
                    setInProgressVideo(false);
                }
            }
        },
        [timestamps, playerRef.current, captions]
    );
    const handleDeleteTimestamp = useCallback(
        async (e, timeStamp) => {
            e.preventDefault();

            let timeStampsDelete = timestamps.findIndex(
                (el) => el.timestamp === el.timestamp && el.title === timeStamp.title
            );
            //delete from timestamps element with timeStamp.id
            let timeStampsForSave = [...timestamps];

            timeStampsForSave.splice(timeStampsDelete, 1);
            setTimestamps(timeStampsForSave);

            let coverData = {
                cover: {
                    type: 'YOUTUBE',
                    source: task.cover.source,
                    timestamps: timeStampsForSave,
                    id: task.cover.id,
                },
            };

            user?.id === task?.creator?.id &&
                handleEditVideoTask(coverData);
        },
        [timestamps]
    );
    const handleGetTimeStamp = useCallback(async () => {
        setInProgressVideo(true);
        let time = getTime();
        let nextElementId = captions?.findIndex((el) => el.offset > time) || 1;

        let timeString = captions[nextElementId - 1]?.offset
            ? captions[nextElementId - 1]?.offset
            : 0;
        let stringRequest = '';
        let title = window.YtPlayer?.videoTitle;
        if (time) {
            let stringArray;

            if (nextElementId < 5 || !nextElementId) {
                stringArray = captions.slice(0, 10);
            } else {
                stringArray = captions.slice(
                    nextElementId - 2,
                    nextElementId + 10
                );
            }

            stringRequest = stringArray
                .map((el) => el?.text)
                .join(' ')
                .trim();
        } else {
            let stringArray = captions.slice(0, 30);
            stringRequest = `Title: ${title}. TransScript (can contain grammar errors): ${stringArray
                .map((el) => el?.text)
                .join(' ')}`;
        }

        const responseTrimmed = await getSummary(stringRequest);
        const titleTrimmed = await getCommonAi(stringRequest, 'title');

        if (responseTrimmed) {
            const captionIndex = nextElementId - 1 >= 0 ? nextElementId - 1 : 0;
            let newTimeStamp = {
                timestamp: `${timeString}`,
                title:
                    titleTrimmed.substring(0, 35) ||
                    captions[captionIndex]?.text?.substring(0, 15) + '…' ||
                    'Summary',
                description: responseTrimmed,
            };

            let timeStampsForSave = [...timestamps, newTimeStamp];
            setTimestamps(timeStampsForSave);

            let coverData = {
                cover: {
                    type: 'YOUTUBE',
                    source: videoSrc,
                    timestamps: timeStampsForSave,
                    id: task.cover.id,
                },
            };

            user?.id === task?.creator?.id && handleEditVideoTask(coverData);
            setInProgressVideo(false);
        }
    }, [timestamps, playerRef.current, captions]);

    return (
        <YoutubeCaptionsComponent
            handleAddTimestamp={() => handleGetTimeStamp()}
            handleRequests={handleAnswerTimeStamp}
            handleDeleteTimestamp={handleDeleteTimestamp}
            handleTimeStampClick={handleTimeStampClick}
            timestampsList={timestamps}
            task={task}
            inProgressVideo={inProgressVideo}
            summary={summary}
            {...other}
        />
    );
};
