import React from 'react';
import {useIntl} from 'react-intl';
import {getDate} from '../util';

type Props = {
    item: Object
};

export const RequestItemComponent = (props: Props) => {
    const {
        item
    } = props;
    const {formatMessage} = useIntl();

    return (
        <tr className="data-table__row">
            <td className="data-table__col">
                {formatMessage({id: 'payment_request_item_date_request'})} {getDate(item.requestDate)}
            </td>
            <td className="data-table__col">
                {item.email}
            </td>
            <td className="data-table__col">
                {`$${item.amount}`}
            </td>
            <td className="data-table__col">{item.doneDate
                ? `${formatMessage({id: 'payment_request_item_date_payment'})} ${getDate(item.doneDate)} `
                : formatMessage({id: 'payment_request_item_request_in_progress'})}</td>
        </tr>
    )
}