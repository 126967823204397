import './scss/index.scss';
import React, {useMemo} from 'react';
import { useIntl } from 'react-intl';

import { Svg } from '../Svg';

export const PassionNavComponent = ({
    specialization,
    spec,
    handleSpec,
    user,
    specs,
}) => {
    const { formatMessage } = useIntl();
    const specList = useMemo(() => specs.filter((spec) => spec?.title !== 'Custom'), [specs]);

    return (
        <div className='header_btn'>

            {specList?.map((sub) => {
                let specExist = spec?.find((el) => sub.title === el.title);

                return (
                    <div
                        key={sub.title}
                        // type="text"
                        className={
                            sub.title === specialization?.title
                                ? 'spec_btn inline-el spec_active'
                                : 'spec_btn inline-el'
                        }
                        onClick={() => handleSpec(sub)}
                    >
                        <Svg
                            name={sub?.icon}
                            size={24}
                            className='menu-icon '
                        />
                        {/* {user?.id && (
                            <small className='spec__number hide-on-mobile'>
                                {specExist && specExist.counter}
                            </small>
                        )} */}

                        <small className='spec__title'>{sub.title}</small>
                    </div>
                );

            })}
            {user?.id && (
                <div
                    className={
                        'all' === specialization
                            ? 'spec_btn inline-el spec_active'
                            : 'spec_btn inline-el'
                    }
                >
                    <small className='spec__number hide-on-mobile'>
                        {' '}
                        {formatMessage({
                            id: 'skills_my',
                        })}
                    </small>
                </div>
            )}

        </div>
    );
};
