import "./scss/index.scss";
import React, { useEffect } from "react";
import { Button } from "antd";
import { useIntl } from "react-intl";

import { useGetVideo, useGetCommonAi } from "../hook";
import { useGetImagesByString } from "../../search";
import { getTitle, useProgress } from '../../common';
import { NextStepButton } from "../NextStepButton.component/NextStepButton.component";
import { useGenerateCourse } from '../hook/useGenerateCourse';

const stepsLimit = 10;
export const AddNewContentContainer = (props) => {
    const {
        task,
        handleReplaceQuestion,
        question,
        user,
        onView,
        event,
        updateTask,
        userIsAllowGenerateContent,
        ...other
    } = props;

    const { getVideo } = useGetVideo();
    const { getCommonAi } = useGetCommonAi();
    const { getImagesList } = useGetImagesByString();
    const { formatMessage } = useIntl();
    const { inProgress, handleChangeProgress } = useProgress();

    const lessThan20Tasks = event?.tasks?.length <= 20;
    const userIsAllowGenerate = lessThan20Tasks && task?.questions?.length <= stepsLimit && userIsAllowGenerateContent;
    const userIsAllowCreateStep = userIsAllowGenerate || user?.id === task?.creator?.id;
    const { generateCourse } = useGenerateCourse();

    const getSimplifiedGoal = async (questionProps) => {
        return await getCommonAi(questionProps.title?.substring(0, 100) + task.title + event.title.substring(0, 50,), "searchRequest");
    }
    const getMostRelevantVideo = async (questionProps, autoload) => {
        handleChangeProgress({
            message: formatMessage({ id: "loader_video" }),
            key: `video_${questionProps.id}`
        });
        let queryString = await getSimplifiedGoal(questionProps);
        try {
            let iframeHtml = await getVideo(
                queryString,
                task?.title
            );
            if (iframeHtml) {
                if (!autoload) {
                    await handleReplaceQuestion(
                        { question: `${questionProps?.question || ''}\n\n${iframeHtml}` },
                        questionProps.id,
                    );
                    handleChangeProgress({ key: `video_${questionProps.id}` });
                } else {
                    handleChangeProgress({ key: `video_${questionProps.id}` });
                    return iframeHtml;
                }
            } else {
                handleChangeProgress({ key: `video_${questionProps.id}` });
            }
        }
        catch (err) {
            handleChangeProgress({ key: `video_${questionProps.id}` });
        }

    };
    const getMostRelevantImage = async (questionProps, autoload) => {
        handleChangeProgress({
            message: `Searching images…`,
            key: `image`
        });
        let header = question?.title || getTitle(question?.question)
        let string = `${header?.substring(0, 80)} AND infograpics`

        let imageJSON = await getImagesList({
            variables: {
                q: string,

            },
        });
        let imageArray = imageJSON?.data?.getSearch?.items;

        let html = '';
        imageArray?.forEach((el) => {
            let newHtml = `\n<div class='image-snippet'>
                       <img src='${el.link}' alt='${el.title}' >
                            <p><a href='${el.image.contextLink}'>${el.displayLink}</a></p></div>`
            html = html + newHtml;

        });
        handleChangeProgress({ key: `image` });

        if (html) {
            if (!autoload) {
                handleReplaceQuestion(
                    { question: `${questionProps?.question || ''}\n\n` + html },
                    questionProps.id,
                );
            }
            return html
        }
    };
    const autoReLoad = async (questionProps) => {
        handleChangeProgress(
            {
                message: formatMessage({ id: "loader_nextstepai_async" }),
                key: `${questionProps.id}_description`
            }
        );

        const resp = await generateCourse(event, task?.id);
        await updateTask({ fetchPolicy: "network-only" });
        handleChangeProgress({ key: `${questionProps.id}_description` });
        handleChangeProgress({ end: true });
    }

    useEffect(() => {
        if (!inProgress && userIsAllowGenerate && onView) { // start auto load??
            if (!!task?.questions?.length) {
                if (!question?.question && question.type === "TEXT") {
                    (async () => await autoReLoad(question))();
                }
            }
        }
    }, [question])

    return question?.id && userIsAllowCreateStep ? (
        <div className="wrapper__content_buttons">
            <div className='content_buttons'>
                <NextStepButton
                    question={question}
                    handleReplaceQuestion={handleReplaceQuestion}
                    userIsAllowGenerate={userIsAllowGenerate}
                    regenerate={question?.question}
                    task={task}
                />
                <Button className="btn-left"
                    disabled={inProgress || !userIsAllowGenerate}
                    type="tertiary"
                    size="small"
                    onClick={() => getMostRelevantImage(question)}>
                    {formatMessage({ id: "learning_info" })}
                </Button>
                <Button className="btn-left"
                    disabled={inProgress || !userIsAllowGenerate}
                    type="tertiary"
                    size="small"
                    onClick={() => getMostRelevantVideo(question)}>
                    {formatMessage({ id: "learning_video" })}
                </Button>
            </div>
        </div>
    ) : null
}
