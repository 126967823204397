
import './scss/index.scss';

import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { noop } from 'lodash';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { Button } from 'antd';

export const VideoAnyPlayer = ({
    video,
    allow = 'autoplay; fullscreen',
    inProgress,
    setInProgress = noop,
    task,
    user, captions,
    handleSummaryClick,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const videoClassNames = classnames('videoContainerStyle videoOnTop ', {});

    return (
        <div className={videoClassNames}>
            {video.source &&
                <div className='playerContainerStyle'>
                    <iframe
                        id='video-iframe'
                        title={video.source}
                        frameBorder='0'
                        allow={allow}
                        allowFullScreen={true}
                        className='videoIframeStyle'
                        src={video.source}
                        width='100%'
                    />

                </div>
            }
            {handleSummaryClick &&

                <Button
                    onClick={handleSummaryClick}
                    type='text'
                    size='small'
                >
                    ✦ {formatMessage({ id: 'summary' })}
                </Button>
            }

        </div>
    );
}
