import {useCallback} from 'react';
import {useMutation} from '@apollo/client';
import {VerifyResendDomain} from '../graphQL/mutations/VerifyResendDomain';

export const useVerifyResendDomain = (mutation = VerifyResendDomain) => {
    const [verify, { data, error, loading }] = useMutation(mutation);

    const verifyDomain = useCallback(async (id, workspaceId) => {
        const resp = await verify({
            variables: {
                id,
                workspaceId
            }
        });
        return resp;
    }, [verify])

    return {
        data,
        error, loading,
        verifyDomain
    };
}