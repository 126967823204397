import {GetResultsByUserid} from "../graphQL/queries/GetResultsByUserid";
import {useLazyQuery, useQuery} from "@apollo/client";

export function useGetResultsByUserid(query = GetResultsByUserid) {
    const [getResultsByUserid, { data, loading, error }] = useLazyQuery(query);
    return {
        resultsByUserid: data?.results,
        loading, error,
        getResultsByUserid
    };
}