import {useMutation, gql} from '@apollo/client';

export const useCalendar = () => {
    const [addToCalendar, calendarData] = useMutation(ADD_CALENDAR);

    return ({
        addToCalendar,
        calendarData
    })
}

const ADD_CALENDAR = gql`
    mutation AddCalendar($eventId: Int!, $source: String!) {
        addCalendar(eventId: $eventId, source: $source) {
            authUrl
            calendarData {
                count
                from
                to
            }
        }
    }
`;