import {useLazyQuery} from '@apollo/client';
import {AiTextToAudio} from './queries/AiTextToAudio';

export const useAiTextToAudio = (query = AiTextToAudio) => {
    const [getAudio, { data: jsonData, loading, error }] = useLazyQuery(query, {fetchPolicy: "no-cache"});

    let data;
    try {
        data = jsonData?.requestAudioApi ;
    } catch (e) {
        console.warn(e);
    }

    return {
        getAudio,
        loading, error,
        data
    }
}
