import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import {
    MetaComponent,
    useGetUrlParams,
    useHostNameUrl,
} from '../Components/common';
import { TaskArticleContainer } from '../Components/task/TaskArticle.container';
import { Loader } from '../Components/Loader';
import { useGetTask } from '../Components/task/hooks/useGetTask';
import { GetTaskSimple } from '../Components/task/graphQL/queries/GetTaskSimple';
import { GetEventForThread } from '../Components/event/graphQL/queries/GetEventForThread';
import { useGetEvent } from '../Components/event';
import { useGetEventsCounter } from '../Components/events';
import { useLocalePrefix } from '../Components/lang';
import { FirebaseContext } from '../context/firebase/firebaseContext';
import { COVER_PICTURES } from '../Components/constant';

export const TaskArticlePage = (props) => {
    const { match, path = 'tutorials', inProgress, isEmbed = '', hostname, workspace, ...other } = props;
    const { user, loadingUser } = useContext(FirebaseContext);
    const workspaceUrl = useHostNameUrl(workspace);
    const { pathname, search } = useLocation();
    const { id, checkUrl } = useGetUrlParams(isEmbed);
    const { event, getEvent, loading: loadingGetEvent } = useGetEvent(GetEventForThread);
    const { task, getTask } = useGetTask(GetTaskSimple);
    const {getEventsCounter, eventsCounter} = useGetEventsCounter();
    const { formatMessage } = useIntl();
    const [localePrefix] = useLocalePrefix();
    const [answers, setAnswers] = useState();

    const getEventCount = async () => {
        await getEventsCounter({
            variables: {
                filter: {
                    creatorId: user?.id
                }
            }
        });
    }

    const onGetEvent = useCallback(async () => {
        const eventId = task?.taskInEvents[0]?.eventId;
        if (!eventId) { return }
        try {
            await getEvent({
                variables: {
                    id: eventId
                },
                fetchPolicy: 'network-only',
            });
        } catch (e) {
            console.warn(e);
        }

    }, [task?.taskInEvents[0]?.eventId, task?.stateResult]);

    const updateTask = useCallback(
        async (opt = {}) => {
            await getTask({
                variables: {
                    taskId: id,
                },
                ...opt,
            });
        }, [id]
    );

    const metaData = useMemo(() => {
        if (!task) { return null; }
        const { title } = task;

        if (hostname || workspace) {
            const metaTitle = `${title} | ${workspace?.title}`;
            const description = `Learn course ${title}, created by ${task.creator.name} on ${workspace?.title}`;
            const url = `${workspaceUrl}${localePrefix}tutorials/${task.alias}-${task.id}`;

            const coverImage = task?.cover?.type === 'PHOTO' ? task.cover.source : workspace.cover;

            return {
                meta: {
                    meta_title: metaTitle,
                    meta_description: description,
                    meta_author: task.creator.name,
                    meta_icon: workspace.logo,
                },
                og: {
                    type: 'article',
                    title: metaTitle,
                    image: coverImage,
                    description: description,
                    url: url,
                },
                canonical: url
            };
        } else {
            const metaTitle = `${title} | ${formatMessage({ id: 'meta_task_title', })}`;
            const description = `Learn course "${title}", created by ${task.creator.name}: pass quizzes, make projects with a community and collect skills for the future.`;
            const url = `https://unschooler.me${localePrefix}/${path}/${task.id}/`;

            const coverImage =
                task?.cover?.type === 'PHOTO'
                    ? task.cover.source
                    : event ? event?.galleriesInEvent[0]?.gallery?.source
                        : COVER_PICTURES.MAIN;

            return {
                meta: {
                    meta_title: metaTitle,
                    meta_description: description,
                    // meta_icon: 
                },
                og: {
                    type: 'article',
                    title: metaTitle,
                    image: coverImage,
                    description: description,
                    url: url,
                },
            };
        }
    }, [task?.id]);

    useEffect(() => {
        (async () => await getEventCount())();
    }, [user?.id])

    useEffect(() => {
        if (task) {
            checkUrl(task, `${localePrefix}/${path}/`);
        }
    }, [task?.id]);

    useEffect(() => {
        if ((task?.taskInEvents[0]?.eventId) ) {
            (async () => onGetEvent())();
        }
    }, [task?.taskInEvents[0]?.eventId, task?.stateResult]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname, search]);

    useEffect(() => {
        if (id) {
            (async () => await updateTask({ fetchPolicy: 'network-only' }))();
        }
    }, [id]);

    const AnswerContainer = classnames('', {
        answer_done: task?.stateResult === 'CORRECT',
    });

    const handleSayMeAnswers = (answers) => {
        setAnswers(answers);
    };

    if (loadingUser || !task?.id) {
        return <Loader />;
    }

    // if (!showToOthers && !loadingUser) {
    //     return <Redirect to={`${localePrefix}/access-denied`} />;
    // }

    return (
        <div className={AnswerContainer}>
            {metaData && (
                <MetaComponent meta={metaData.meta} og={metaData.og} />
            )}
            <TaskArticleContainer
                {...other}
                updateTask={updateTask}
                task={task}
                key={match?.params?.id}
                answerPage={true}
                user={user}
                event={event}
                path={path}
                answers={answers}
                handleSayMeAnswers={handleSayMeAnswers}
                inProgress={inProgress}
                localePrefix={localePrefix}
                isEmbed={isEmbed}
                hostname={hostname}
                workspace={workspace}
                loadingData={loadingGetEvent}
                isFirst={eventsCounter <= 1}
            />
        </div>
    );
}
