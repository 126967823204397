import React from "react";
import { Button } from "antd";
import { useIntl } from 'react-intl';
import { Svg, useProgress } from "../../common";
import { useGetAssessmentQuestionAi } from "../hook/useGetAssessmentQuestionAi";

export const AssessmentButton = ({
  question,
  userIsAllowGenerate,
  source,
  handleReplaceQuestion,
  size = 'small',
  regenerate,
}) => {

  const { getAssessment } = useGetAssessmentQuestionAi();
  const { formatMessage } = useIntl();
  const { handleChangeProgress } = useProgress(); // Use useProgress here

  const handleClick = () => {
    getAssessment({
      questionProps: question,
      autoload: false,
      handleReplaceQuestion,
      source,
      handleChangeProgress,
    });
  };

  return (
    <>
      <Button
        className="btn-left"
        disabled={!userIsAllowGenerate}
        type={regenerate ? 'secondary' : 'tertiary'}
        size={size}
        onClick={handleClick}
      >
        <Svg name=':icon/logo-full_star' className='icon_16' />
        {regenerate ? formatMessage({ id: "regenerate" }) : formatMessage({ id: "generate" })}
        {' '}
        {formatMessage({ id: "learning_project" })}
      </Button>
    </>
  );
};