import "./scss/index.scss";
import React, {useEffect, useState} from "react";
import { useIntl } from "react-intl";
import { Form, Input } from 'antd';
import { WysiwygComponent, VideoGenerator } from '../../common';
import { TextModuleButton } from '../../ai/TextModuleButton.component/TextModuleButton.component';

export const EditTaskDescription = ({
    user,
    formTask,
    form,
    onChangeContent,
    formInitialValue,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const [initialState, setInitialState] = useState(formTask?.description );

    useEffect(() => {
        setInitialState(form.getFieldValue('description') || formTask?.description || '');
    }, [form.getFieldValue('description')])

    return (
        <div className='edit-task-form-tab__content_bottom'>
            <Form.Item
                name='title'
                rules={[
                    {
                        required: true,
                        message: formatMessage({ id: 'form_title_task_error_message', }),
                    },
                ]}
            >
                <Input
                    autoComplete='off'
                    placeholder={formatMessage({ id: 'form_title_task', })}
                    size="large"
                />
            </Form.Item>

            <VideoGenerator
                task={formTask}
                user={user}
                showAiForStudents={true}
                editOthersCourses={true}
                userIsAllowGenerateContent={true}
                {...other}
            />
            <TextModuleButton
                userIsAllowGenerate={true}
                task={formTask}
                handleSaveContent={(content) => {
                    form.setFieldsValue({ description: content });
                    onChangeContent();
                }}
                regenerate={formTask?.description}
            />
            <Form.Item
                name='description'
                className='wysiwyg-field Answer__info '
            >
                <WysiwygComponent
                    onChangeContent={(content) => {
                        form.setFieldsValue({ description: content });
                        onChangeContent();
                    }}
                    initialState={ initialState  }
                    placeholder={formatMessage({
                        id: 'form_description_task_placeholder',
                    })}
                />
            </Form.Item>
        </div>
    );
};
