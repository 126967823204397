import {gql} from '@apollo/client';

export const CreateEvent = gql`
    mutation CreateEvent($eventData: CreateEventInput!) {
        event: createEvent(eventData: $eventData) {
            id
            alias
            tasks {
                id
            }
        }
    }
`;
