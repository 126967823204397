import {useLazyQuery} from '@apollo/client';
import {GetIntegration} from '../graphQL/queries/GetIntegration';

export const useGetIntegration = (query = GetIntegration ) => {
    const [getIntegration, {data, loading, error}] = useLazyQuery(query);

    return {
        data,
        getIntegration,
        loading,
        error
    }
}
