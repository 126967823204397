import React from 'react';
import { Button } from 'antd';
import { Svg } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

export const FilesGalleryComponent = ({ galleriesList, onDeleteFile }) => {
    return (
        <ul className="wrapper_list">
            {galleriesList.map((gal, i) => (
                <li key={`gallery-item_${gal?.id || i}`} className="gallery-item">
                    <FontAwesomeIcon icon={faFile} className='margin_right btn_light' />
                    <a href={gal?.source} target="_blank" rel="noreferrer">
                        {gal?.title || gal?.source}
                    </a>
                    {onDeleteFile &&
                        <Button type="text" size="small" className='wrapper_inner-section btn_light' onClick={() => onDeleteFile(i)}>
                            <Svg name={`:icon/cross`} className='icon_16 margin_right' />
                        </Button>
                    }
                </li>
            ))}
        </ul>
    );
};
