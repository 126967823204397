import {useCallback} from 'react';
import {useLocation} from 'react-router-dom';

export const useTrimPrefixPath = () => {
    const {pathname} = useLocation() || {};

    return useCallback((codes) => {
        const p = pathname.split('/');
        let newPath = '';

        for(const s of p) {
            if (!!s && !codes?.includes(s)) {
                newPath = `${newPath}/${s}`;
            }
        }

        if (!newPath) {
            newPath = '/';
        }

        return newPath;
    }, [pathname]);
};
