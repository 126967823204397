import { gql } from "@apollo/client";

export const GetEventsSimple = gql`
    query GetEvents($filter: FilterEvents, $limit: Int, $offset: Int, $isOwner: Boolean) {
        events(filter: $filter, limit: $limit, offset: $offset, isOwner: $isOwner ) {
            id
            alias
            title
            countAllUserSubscriptions
            isDraft
            workspaceId
            workspaceIds
            type
            certificate {
                id
            }
        }
    }
`;
