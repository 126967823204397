import React, {useContext, useEffect} from 'react';
import {PaypalComponent} from '../Paypal.component';
import {useConvertedPrice} from '../../common';
import {useCheckPaymentOrder, useGetPaypalAccount} from '../hooks';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const PaypalContainer = (props) => {
    const {entityId, onGetEvent} = props;
    const {user, workspace} = useContext(FirebaseContext);
    const {getPrice, getCurrency, loading: loadingPrice} = useConvertedPrice();
    const {checkPaymentOrder, loading: loadingCheckOrder} = useCheckPaymentOrder();
    const {data: getAccountData, loading: getAccountLoading, getPaypalAccount} = useGetPaypalAccount();
    const {paypalAccountId} = workspace || {};
    const {id} = user || {};
    const {price} = props;
    const {forStudent: amount} = price || {};
    const currencyCode = getCurrency();
    const {clientId} = getAccountData?.getPaypalAccount || {};

    const handleCreateOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: amount,
                        currency_code: currencyCode
                    },
                    custom_id: `${id}_${entityId}_${Date.now()}`
                }
            ]
        });
    }

    const handleApprove = (data, actions) => {
        return actions.order.capture().then(async (details) => {
            const {status, links, purchase_units} = details || {};
            const link = links?.[0]?.href;

            const resp = await checkPaymentOrder({
                variables: {
                    clientId,
                    link,
                    status
                }
            });

            if (resp?.data?.checkPaypalOrder && onGetEvent) {
                await onGetEvent();
            }
        });
    };

    useEffect(() => {
        if (paypalAccountId) {
            (async () => {
                await getPaypalAccount({
                        variables: {
                            id: paypalAccountId
                        }
                    }
                )
            })();
        }
    }, [paypalAccountId]);

    if (loadingPrice) {
        return null;
    }

    return <PaypalComponent isLoginMode={!id}
                            isLoading={loadingCheckOrder}
                            isLoadingAccount={getAccountLoading}
                            price={getPrice(amount)}
                            currencyCode={currencyCode}
                            clientId={clientId}
                            onCreateOrder={handleCreateOrder}
                            onApprove={handleApprove}/>
}