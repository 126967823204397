import {useQuery} from '@apollo/client';
import {GetSpecs} from './queries/GetSpecs';

export function useGetSpecs(query = GetSpecs) {
    const {data, loading, error} = useQuery(query, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-only'});

    return {
        specs: data?.specs,
        loading,
        error
    };
}