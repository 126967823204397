import { useLazyQuery } from '@apollo/client';
import { GetTaskCover } from '../graphQL/queries/GetTaskCover';

export function useGetTaskCover(variables, query = GetTaskCover) {
    const [getTask, { data, loading, error }] = useLazyQuery(query);
    return {
        task: data?.task,
        loading, 
        error,
        getTask
    };
}