import { useMemo, useContext } from 'react';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';

export const useFromEmail = () => {
    const { workspace } = useContext(FirebaseContext);

    return useMemo(() => {
        const data = { from: 'unschooler.me <info@unschooler.me>' };
        const { email, domain } = workspace?.resend || {};
        const { status, name } = domain || {};

        if (status === 'verified' && email) {
            data.from = `${name} <${email}>`;
        }

        return data;
    }, [workspace]);
};