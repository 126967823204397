import React, {useState, useRef} from 'react';

import {TasksByOrderComponent} from '../TasksByOrder.component';
import {useGetTasksByOrder} from '../../task/hooks/useGetTasksByOrder';
import {useEditTaskOrder} from '../hooks';

export const TasksByOrderContainer = (props) => {
    const {
        as: Component = TasksByOrderComponent
    } = props;

    const {getTasks, error} = useGetTasksByOrder();
    const {editTaskOrder} = useEditTaskOrder();
    const [tasks, setTasks] = useState();
    const entityRef = useRef();

    const handleGetTasks = async (data) => {
        try {
            const resp = await getTasks({
                variables: {
                    input: data,
                    limit: 200
                }
            });

            setTasks(resp?.data?.tasksByOrder);
            entityRef.current = data;
        } catch (err) {
            console.warn(err);
        }
    };

    const handleSave = () => {
        const input = tasks.map((item) => ({
            order: item.order,
            taskId: item.task.id,
            ...entityRef.current
        }));
        editTaskOrder({variables: {input}});
    };

    return <Component tasks={tasks}
                      serverError={error}
                      onGetTasks={handleGetTasks}
                      onSave={handleSave}
                      onChangeTasks={setTasks}/>
}