export const getEventsData = async (source) => {
    const list = [];

    for(const item of source) {
        // const eventDb = await fetchEventById(item?.eventId);
        // const eventData = eventDb.val();

        // if (eventData) {
        //     eventData.id = item?.eventId;
        //     list.push({
        //         event: eventData,
        //         payment: item
        //     });
        // }
    }

    return list;
};
