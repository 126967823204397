import React from 'react';
import { Popover, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

export const InfoMessagesComponent = ({ type }) => {
    const { formatMessage } = useIntl();
    let content;
    switch (type) {
        case 'isAuto':
            content = (
                <div>
                    <p>{formatMessage({id: 'turnOn_info'})}</p>
                    <hr />
                    <p>{formatMessage({id: 'turnOff_info'})}</p>
                </div>
            );
            break;
        // case 'isDraft':
        //     content = (
        //         <div>
        //             <p>Turn on to make lesson not accessible on the school page. Students will see it by a direct link and cannot join or buy this course.</p>
        //             <hr />
        //             <p>Turn off to make lesson visible, allowing anyone to read the content.</p>
        //         </div>
        //     );
        //     break;
        case 'isFree':
            content = (
                <div>
                    <p>{formatMessage({id: 'turnOn_info1'})}</p>
                    <hr />
                    <p>{formatMessage({id: 'turnOff_info2'})}</p>
                </div>
            );
            break;


        default:
            content = (
                <div>
                    <p>{formatMessage({id: 'no_specification_default'})}</p>
                </div>
            );
    }
    return (
        <Popover content={content} trigger="hover">
            <Button type="text" icon={<InfoCircleOutlined />} />
        </Popover>
    );
};

