import {getLocaleFromUrl} from './getLocaleFromUrl';
import {LOCALES} from '../../../constants/locales';

let isInProgress = false;

const EXLUDES_LIST = ['/callback/youtube']

export const redirectToUserLocale = (langId, langs) => {
    if (isInProgress) return;
    isInProgress = true;

    const currentLang = langs.find((lang) => lang.id === langId);
    const {code} = currentLang;
    const isWrongCode = !LOCALES.includes(code);

    if (isWrongCode) {
        return;
    }

    const locale = getLocaleFromUrl();
    const pathName = window.location.pathname;
    let newPathName;

    if (locale === 'en') {
        newPathName = `/${code + pathName}`;
    } else {
        const regExp = new RegExp(`/${locale}`);
        newPathName = pathName.replace(regExp, code === 'en' ? '' : `/${code}`);
    }
    if (!EXLUDES_LIST.includes(pathName)) {
        window.location.href = newPathName;
    }
}