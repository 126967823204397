import React, { useState } from 'react';
import { RoleChangeComponent } from '../RoleChange.component/RoleChange.component';
import {useEditWorkspaceMember} from '../hooks';

export const RoleChangeContainer = ({ memberId, workspace, onUpdateWorkspace, permission}) => {
    const {members = []} = workspace || {};
    const {editWorkspaceMember, loading} = useEditWorkspaceMember();
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [newRole, setNewRole] = useState(permission);

    const handleChangePermission = async (id, permission) => {
        const member = members.find((m) => m.userId === id);
        if (!!member && member.permission !== permission) {
            await editWorkspaceMember({
                variables: {
                    id: member?.id,
                    permission
                }
            });
            await onUpdateWorkspace();
        }
    };

    const handleDropdownClick = (memberId) => {
        setDropdownVisible(dropdownVisible === memberId ? null : memberId);
    };

    const handleRoleSelect = async (memberId, role) => {
        setNewRole(role);
        setDropdownVisible(null);
        await handleChangePermission(memberId, role);
    };

    return (
        <RoleChangeComponent
            memberId={memberId}
            dropdownVisible={dropdownVisible}
            handleDropdownClick={handleDropdownClick}
            handleRoleSelect={handleRoleSelect}
            loading={loading}
            newRole={newRole}
        />
    );
};

