import {gql} from "@apollo/client";

export const EditEvent = gql`
    mutation EditEvent($eventId: Int!, $eventData: EditEventInput!, $isOwner: Boolean) {
        event: editEvent(eventId: $eventId, eventData: $eventData, isOwner: $isOwner) {
            id
            alias
        }
    }
`;
