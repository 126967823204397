import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Checkbox, Form, Input } from 'antd';
import { noop } from 'lodash';
import { ErrorComponent, LoaderComponent, Svg } from '../../common';
import SVG_ICON from './resourses/media/svg/stripe.svg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#fff',
            color: '#fff',
            fontWeight: 500,
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#ffffff99'
            },
        },
        invalid: {
            iconColor: '#ff4d4f',
            color: '#ff4d4f',
        },
    },
    hidePostalCode: true,
};

export const StripeComponent = (props) => {
    const {
        product,
        user,
        onSubmit = noop,
        loading,
        error,
        isGiftBox,
        userCost
    } = props;
    const { formatMessage } = useIntl();
    const { promocode } = product || {};
    const promoCodeUserCost = promocode?.userCost;

    const [disabled, setDisabled] = useState(true);
    const [phoneValid, setPhoneValid] = useState();
    const elements = useElements();
    const stripe = useStripe();
    const { useForm } = Form;
    const [form] = useForm();
    const { name, email, phone } = user || {};

    const handleChangeCard = (e) => {
        setDisabled(!e?.complete);
    };

    const handleFinish = (data) => {
        const { studentName, accept, text, ...other } = data;
        const paymentData = {
            billing_details: { ...other, ...(phone && { phone }) },
            amount: userCost,
            card: elements.getElement(CardElement),
        };

        onSubmit(paymentData, stripe, {
            accept,
            studentName
        }, { text, ...other });
    };

    return (
        <div className='stripe-component '>
            <Form
                onFinish={handleFinish}
                form={form}
                layout='vertical'
                autoComplete="off"
            >
                {!isGiftBox && (
                    <>
                        <Form.Item
                            label={formatMessage({ id: 'stripe_student_name' })}
                            name='studentName'
                            initialValue={user?.name}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({ id: 'stripe_error_message' })
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item noStyle name='name'
                            initialValue={name}>
                            <Input type='hidden' />
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({ id: 'stripe_student_email' })}
                            name='email'
                            type='email'
                            initialValue={email}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({ id: 'stripe_error_message' })
                                },
                                {
                                    type: 'email',
                                    message: formatMessage({ id: 'stripe_error_email_message' })
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        {!phone && (
                            <Form.Item
                                className="ant-phone"
                                label={formatMessage({ id: 'stripe_student_phone' })}
                                name='phone'
                                type='text'
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage({ id: 'stripe_error_message' })
                                    },
                                    () => ({
                                        validator() {
                                            if (phoneValid) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(
                                                    new Error(formatMessage({ id: 'stripe_error_phone_message' })
                                                    )
                                                );
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <PhoneInput
                                    countryCode={user?.countryCode}
                                    inputProps={{
                                        name: 'phone',
                                    }}
                                    isValid={(value, country) => {
                                        if (country) {
                                            const numberLengt =
                                                country?.format.split('.').length - 1;
                                            setPhoneValid(value.length === numberLengt);
                                        }
                                        return true;
                                    }}
                                    placeholder=''
                                    enableAreaCodes={false}
                                    disableDropdown={true}
                                    onChange={(phone) => form.setFieldsValue({ phone })}
                                />
                            </Form.Item>
                        )}
                    </>
                )}

                <Form.Item
                    name='accept'
                    valuePropName='checked'
                    rules={[
                        {
                            validator: (_, value) =>
                                value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(formatMessage({ id: 'stripe_accept_error' }))
                                    ),
                        },
                    ]}
                >
                    <Checkbox>
                        {formatMessage({ id: 'stripe_accept' })}{' '}
                        <a
                            href='https://docs.google.com/document/d/17HXB7e0GjMDbpmWnHPMWMlsoWlFh24ej/edit'
                            target='_blank'
                            rel='noreferrer'
                            title={formatMessage({ id: 'stripe_accept_terms' })}
                        >
                            {formatMessage({ id: 'stripe_accept_terms' })}
                        </a>{' '}
                        {formatMessage({ id: 'stripe_accept_terms_confirm' })}
                        <a
                            href='https://unschooler.fibery.io/Company/264?sharing-key=251663ac-cf1e-41cf-b740-3b5e053acdc2'
                            target='_blank'
                            rel='noreferrer'
                        >
                            {' '}
                            {formatMessage({ id: 'stripe_technical_requirements' })}
                        </a>
                        .
                    </Checkbox>
                </Form.Item>
                <h4> <span className="red">*</span> {formatMessage({ id: 'stripe_input_card_number' })}</h4>
                <div className='stripe-component__bg'>
                    <Form.Item
                    >
                        <CardElement
                            options={CARD_OPTIONS}
                            onChange={handleChangeCard}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                        // disabled={disabled || loading}
                        >
                            {formatMessage({ id: 'stripe_pay' })} ${promoCodeUserCost || userCost}
                        </Button>
                    </Form.Item>
                    <div className='stripe-component__powered flex-container_between flex-container '>
                        <a
                            className='stripe-component__powered__link'
                            href='https://stripe.com'
                            target='_blank'
                            rel='noreferrer'
                            title='Powered by stripe'
                        >
                            Powered by <img src={SVG_ICON} alt='stripe' />
                        </a>
                        <div className='block_el'>
                            <Svg name=':icon/visa' height="16px" width="96px"/>
                            <Svg name=':icon/master_card' height="16px" width="48px"/>
                        </div>
                    </div>
                </div>

                {error && <ErrorComponent message={error?.message} />}
            </Form>
            {loading && <LoaderComponent type='overlay' />}
        </div>
    );
};
