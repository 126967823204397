import {useMemo} from 'react';
import {useLocalePrefix} from '../../lang';

export const useHostNameUrl = (workspace) => {
    const [localePrefix] = useLocalePrefix();
    return useMemo(() => {
        if (!workspace) {
            return `https://unschooler.me${localePrefix}/`
        }
        const { host, customDomain } = workspace || {};
        const hostUrl = customDomain || `${host}.unschooler.me`;
        return `https://${hostUrl}${localePrefix}/`;
    }, [localePrefix, workspace]);
}