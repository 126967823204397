import React, {useState} from 'react';
import {SearchComponent} from '../Search.component';
import {useSearchEvents} from '../hooks';

export const SearchContainer = ({filter, onSearch}) => {
    const {loading, getSearchEvents} = useSearchEvents();
    const [list, setList] = useState([]);
    const [isSearch, setIsSearch] = useState(false);

    const handleSearch = async (val) => {
        const {search} = val;
        if (!!search) {
            const resp = await getSearchEvents({
                variables: {
                    input: {
                        q: search,
                        limit: 10,
                        offset: 0,
                        filter
                    }
                }
            });
            setList(resp?.data?.searchEvents || []);
            setIsSearch(search);
            onSearch(true);
        }
    }

    const handleCancel = () => {
        onSearch(false);
        setIsSearch(false);
        setList([]);
    }

    return <SearchComponent onSearch={handleSearch}
                            list={list}
                            loading={loading}
                            isSearch={isSearch}
                            onCancel={handleCancel}/>;
};
