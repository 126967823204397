import './scss/index.scss';
import React, {useState, useEffect} from 'react';
import {Button} from 'antd';
import {PlayCircleFilled, PauseCircleFilled} from '@ant-design/icons';
import {convertTextToWebVTT, createVTTBlob, createVTTURL} from './subtitlesAuto';

export const AudioPlayerButton = ({
  audioRef,
  isPlay,
  currentSlide,
  onNextSlide,
  formatMessage,
  handlePlayPause,
  isAvatar
}) => {
    const [vttURL, setVttURL] = useState('');
    const [audioDuration, setAudioDuration] = useState(audioRef?.current?.duration);

    useEffect(() => {
        const handleLoadedMetadata = () => {
            setAudioDuration(audioRef.current.duration);
        };

        const audioElement = audioRef.current;
        if (audioElement) {
            audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);
        }

        return () => {
            if (audioElement) {
                audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
            }
        };
    }, [audioRef]);

    useEffect(() => {
        if (audioDuration && currentSlide?.description) {
            const vttString = convertTextToWebVTT(currentSlide.description, audioDuration);
            const vttBlob = createVTTBlob(vttString);
            const vttURL = createVTTURL(vttBlob);
            setVttURL(vttURL);

            return () => {
                URL.revokeObjectURL(vttURL);
            };
        }
    }, [currentSlide, audioDuration]);

    useEffect(() => {
        if (isAvatar) {
            audioRef.current.muted = true;
        } else {
            audioRef.current.muted = false;
        }
    }, [isAvatar]);

    return (
        <div>
            <audio className="audio-player"
                   ref={audioRef}
                   src={currentSlide.audio}
                   onEnded={onNextSlide}
                   autoPlay={isPlay}>
                {vttURL && <track kind="subtitles" srcLang="en" src={vttURL} default />}
                {formatMessage({ id: 'audio_compatability_warning' })}
            </audio>

            <div id="subtitle-display" className="subtitle-display"></div>

            <Button icon={
                isPlay ? <PauseCircleFilled style={{fontSize: '30px'}}/> :
                    <PlayCircleFilled style={{fontSize: '30px'}}/>}
                    type="text"
                    onClick={() => handlePlayPause(audioRef)}/>
        </div>
    );
};
