import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {AuthButtonComponent, Svg, AuthFormComponent, LoaderComponent} from '../Components/common';
import {useCheckActivation, useCheckUser} from '../Components/common/graphQL';

export const Login = (props) => {
    const {user} = props;
    const history = useHistory();
    const {search} = useLocation();
    const [userData, setUserData] = useState();
    const {checkActivation} = useCheckActivation()
    const {checkUser} = useCheckUser()
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get('a');
    const source = searchParams.get('source');

    const getUserData = async () => {
        const resp = await checkUser(token);
        setUserData(resp);
    };

    const checkSubscription = async () => {
        const resp = await checkActivation({token, source, userId: user.id});
        if (resp.isActivated) {
            window.location.href = '/payments';
        }
    };

    useEffect(() => {
        if (user?.id) {
            if (token && source) {
                (async () => await checkSubscription())();
            } else {
                history.push('/');
            }
        } else {
            (async () => await getUserData())();
        }
    }, [user?.id, token, source])

    return (
        <div className=' create-event-page '>
            <div className='flex-container flex-container-stretch'>
                <div className='login_left'>
                    <header className='header_login'>
                        <div className='header__logo'>
                            <Svg
                                name=':icon/logo-full_star'
                                viewBox='0 0 160 30'
                                width='170px'
                                height='40px'
                                className='logo'
                            />
                            <span>
                                with
                            </span>

                            <Svg
                                name=':icon/logo_appsumo'
                                viewBox='0 0 200 30'
                                width='150px'
                                height='30px'
                                className='logo'
                            />
                        </div>
                    </header>
                    <div className='login_form_wrapper'>
                        <div className='login_form'>
                            <div className=' wrapper_outer-section'>
                                <h1>Welcome Sumo-ling! ✷</h1>
                                <p className='wrapper_inner-section'>Your account is almost ready, set your password
                                    with same email you have used for activation</p>

                                <div className='wrapper_outline'>
                                    {/* {!userData && (
                                        <LoaderComponent type="block"/>
                                    )} */}
                                    {userData && (
                                        <AuthFormComponent token={token}
                                                           source={source}
                                                           userData={userData}
                                                           isReadOnlyEmail={true}/>
                                    )}
                                </div>
                                <hr/>

                                {/* <AuthButtonComponent token={token} source={source}/> */}
                            </div>

                            <small>Registering will enroll you in AppSumo's plan for exclusive lifetime deals.</small>
                        </div>

                    </div>
                </div>
                <div className='login_right'
                     style={{
                         backgroundImage: `url("https://cdn.unschooler.me/web_app/course_covers/big.png")`,
                         filter: `saturate(0%) brightness(76%) contrast(113%)`,
                         backgroundSize: "cover",
                     }}
                >
                </div>
            </div>
        </div>
    );
};
