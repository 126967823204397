import './scss/index.scss';
import React from 'react';
import {Link} from 'react-router-dom';

import {PassionNavComponent, SignInComponent} from '../common';
import {useLocalePrefix} from '../lang';

export const HeaderProfile = ({
    specialization,
    spec,
    handleSpec,
    user,
}) => {
    const [localePrefix] = useLocalePrefix();

    return (
        <>
            <header className='header header_profile header_inner'>
                <PassionNavComponent
                    specialization={specialization}
                    spec={spec}
                    handleSpec={handleSpec}
                    user={user}
                    hideAll={true}
                />

                {!user?.id ? (
                    <div className='btns_header hide-on-mobile'>
                        <SignInComponent />
                    </div>
                ) : (
                    <Link
                        to={`${localePrefix}/skills`}
                        className='link-btn-secondary ant-btn ant-btn-secondary hide-on-mobile'
                    >
                        all skill map
                    </Link>
                )}
            </header>
        </>
    );
};
