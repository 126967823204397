import {useContext, useCallback} from 'react';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const useOwnerData = () => {
    const {user, workspace} = useContext(FirebaseContext) || {};
    return useCallback(async () => {
        const {id: customerId} = user || {};
        const {id: workspaceId, ownerId} = workspace || {};
        return {workspaceId, ownerId, customerId};
    }, [workspace, user]);
}