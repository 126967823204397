import React from 'react';
import {Switch} from 'antd';
import {Svg} from '../../common';

export const ThemeSwitcherComponent = () => {
    const isWhiteMode = window.localStorage.getItem('isWhiteMode');

    const handleChange = (val) => {
        const rootElement = document.getElementsByTagName('html')[0];
        if (!val) {
            rootElement.classList.remove('dark-mode');
            window.localStorage.setItem('isWhiteMode', true);
        } else {
            rootElement.classList.add('dark-mode');
            window.localStorage.removeItem('isWhiteMode');
        }
    };

    return (
        <div className='theme-switcher-component'>
            <Switch
                defaultChecked={!isWhiteMode}
                checkedChildren={
                    <Svg name=':icon/dark' fill={"white"} viewBox ="0 0 20 20"  />
                }
                unCheckedChildren={
                    <Svg name=':icon/light'  fill={"black"} viewBox ="0 -2 30 30" width={20} />
                }
                onChange={handleChange}
            />
        </div>
    );
};
