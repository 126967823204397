import React, {useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {AuthButtonComponent} from '../Components/common';
import {FirebaseContext} from '../context/firebase/firebaseContext';

export const TestPage = (props) => {
    const {lang, user} = useContext(FirebaseContext);
    const {id} = user || {};
    const {search} = useLocation();
    const isTestMode = search === '?mode=test';

    const onCancelLogin = () => {
        console.log('Cancel login')
    }

    const onLoginUser = (data) => {
        console.log('User is logged', data);
    }

    return (
        <div className="page">
            {isTestMode && (
                <div>
                    <h2>Test Page</h2>
                    <div>
                        {id ? 'User is authorized' : 'User is not authorized'}
                    </div>
                    <hr/>
                    <div style={{maxWidth: '300px'}}>
                        <AuthButtonComponent onCancelLogin={onCancelLogin}
                                             onLoginUser={onLoginUser}
                                             lang={lang}/>
                    </div>
                </div>
            )}
        </div>
    )
}