// import {fetchData} from '../../../context/firebase/firebaseRequests';
import {getCacheManager} from './getCacheManager';

const CACHE_KEY = 'cache-teacher-subjects-';
const {hasCache, getCache, setCache} = getCacheManager();

export const getTeacherSubjects = async (id) => {
    const key = CACHE_KEY + id;
    const isCache = hasCache(key);
    // if (!isCache) {
    //     const dbData = await fetchData(`schedule-edit/${id}/subjects`);
    //     setCache(key, dbData.val());
    // }
    return getCache(key);
}