import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import {ExtextionNodeViewComponent} from './ExtextionNodeView.component';

export const ExtentionComponent = Node.create({
    name: "reactComponent",
    group: "block",
    content: "block+",
    draggable: true,

    parseHTML() {
        return [
            {
                tag: "drag",
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ["react-component", mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ExtextionNodeViewComponent);
    },
});
