import React, { useRef, useEffect } from 'react';

export const VideoAiAvatarComponent = (props) => {
    const { currentSlide, isPlay, onError, videoError } = props;
    const { aiAvatarUrl } = currentSlide || {};
    const videoRef = useRef();

    useEffect(() => {
        if (videoError) {
            return;
        }

        if (isPlay && aiAvatarUrl) {
            const playPromise = videoRef.current.play();

            if (playPromise !== undefined) {
                playPromise.catch((error) => {
                    console.error('Error attempting to play video:', error);
                    onError && onError(error);
                });
            }
        } else {
            videoRef.current.pause();
        }
    }, [isPlay, aiAvatarUrl, onError, videoError]);
    
    return (
        <div className="ai-avatar" type="primary" shape="circle">
            <div className="ai-avatar__content">
                <video
                    ref={videoRef}
                    onError={onError}
                    className="ai-avatar__video"
                    src={aiAvatarUrl} />
            </div>
        </div>
    )
}