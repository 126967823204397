import '../scss/index.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useLocalePrefix } from '../../lang';

export const FooterWorkspaceComponent = ({workspace = {}}) => {
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();
    const {title, copyright, contactEmail, address} = workspace;

    return (
        <div className="footer-workspace-component">
            <footer className="footer-component">
                <div className='flex-container '>
                    <div className=' flex-container flex-left-column'>
                        <div className='footer__first-column'>
                            <div className='flex-container flex-container_between'>
                                <p className=' footer__link'>
                                    {' '}
                                    © {copyright || title}
                                </p>
                            </div>
                        </div>
                        <div >
                            {contactEmail && (
                                <p className=' footer__link'>
                                    {contactEmail}
                                </p>
                            )}
                            {address && (
                                <p className=' footer__link'>
                                    {address}
                                </p>
                            )}
                        </div>
                  
                    </div>
                    <div className='flex-right-column'>
                        <Link
                            to={`${localePrefix}/terms/`}
                            rel='noreferrer'
                            className='block-el  wrapper_inner-section footer__link'
                        >
                            {formatMessage({ id: 'terms' })}
                        </Link>

                        <Link
                            className='block-el wrapper_inner-section footer__link'
                            to={`${localePrefix}/policy/`}
                        >
                            {formatMessage({ id: 'policy' })}
                        </Link>
                    </div>
                </div>
            </footer>
        </div>
    );
};
