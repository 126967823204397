import {LOGIN_USER, FETCH_USER, NEW_ANSWER, CURRENCY} from '../types';

const handlers = {
    [LOGIN_USER]: (state, {payload}) => ({
        ...state,
        user: payload,
    }),

    [FETCH_USER]: (state, {payload}) => ({
        ...state,
        user: payload,
    }),

    [NEW_ANSWER]: (state, {payload}) => ({
        ...state,
        // answerAdded: true,
        newAnswer: payload,
    }),

    [CURRENCY]: (state, {payload}) => ({
        ...state,
        currency: payload,
    }),

    DEFAULT: (state) => state,
};

export const firebaseReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};
