import { gql, useLazyQuery } from '@apollo/client';
import {useCallback} from 'react';

export const useGetSimpleUser = () => {
    const [queryUser, { data, loading, error }] = useLazyQuery(GET_SIMPLE_USER, {
        fetchPolicy: "network-only"
    });

    const getSimpleUser = useCallback(async ({id, email}) => {
        const result = await queryUser({
            variables: {
                ...(id && {userId: id}),
                ...(email && {email})
            },
        });
        return result.data?.user;
    } , [queryUser]);

    return {
        user: data?.user,
        loading,
        error,
        getSimpleUser
    };
}

const GET_SIMPLE_USER = gql`
    query GetUser($userId: Int, $email: String) {
        user(id: $userId, email: $email) {
            id
            email
            subscriptionUser {
                type
                startDate
                endDate
                isCanceled
            }
        }
    }
`;
