import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { TasksListComponent } from '../TasksList.component';
import { TasksListSimpleComponent } from '../TasksListSimple.component';
import { TasksCardsComponent } from '../TasksCards.component';

import { PLACEHOLDER_MY, PLACEHOLDER_EMPTY_TAG } from '../../constant';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { useGetTasks } from '../hooks/useGetTasks';
import { useGetTask } from '../hooks/useGetTask';
import { useGetTasksByOrder } from '../hooks/useGetTasksByOrder';
import { useLangId } from '../../lang';

const LIMIT = 5;
let currentOffset = 0;

export const TasksListContainer = (props) => {
    const {
        activeTab,
        className,
        tellMeIfEmpty,
        isMe,
        author,
        passTaskToParent,
        isUpdateTasks,
        tag,
        limit,
        bigPreview,
        type,
        taskIds,
        workspace,
        OwnerOrAdmin,
        ...other
    } = props;
    const history = useHistory();
    const { pathname } = history.location;
    const { formatMessage } = useIntl();

    const [page, setPage] = useState(0);
    const [isMore, setIsMore] = useState(false);
    const [tasks, setTasks] = useState([]);
    const { getTasks } = useGetTasks();
    const { getTasks: getTasksByOrder } = useGetTasksByOrder();
    const { task, getTask } = useGetTask();

    const { user, loadingUser } = useContext(FirebaseContext);
    const [placeholder, setPlaceholder] = useState({});
    const { by, id } = useParams();
    const langId = useLangId();

    const updateTasks = async () => {
        await getTasksByTab(activeTab);
    };

    const fetchMoreTasks = async () => {
        const tasksData = await getTasksByTab(
            activeTab,
            page + 1,
            tasks?.length || currentOffset
        );
        const tasksList = tasksData?.map((item) => item?.task || item);
        setTasks((prev) => [...prev, ...(tasksList || [])]);
        setPage(page + 1);

        if (tasksData.length < LIMIT) {
            setIsMore(false);
        }
    };


    const getTasksByTab = useCallback(
        async (activeTab, page = 0, offset = 0) => {

            if (activeTab === formatMessage({ id: 'tab_my_tutorial' })) {
                const resp = await getTasks({
                    variables: {
                        filter: {
                            me: true,
                            langId,
                        },
                        page,
                        limit: limit || LIMIT,
                    },
                });

                let filteredTasks = resp?.data?.tasks.filter(task => !task?.taskInEvents.length);
                return resp?.data?.tasks;
            }

            if (activeTab === formatMessage({ id: 'tab_workspace_tutorial' })) {
                const resp = await getTasks({
                    variables: {
                        filter: {
                            workspaceId: workspace.id, //does notwork
                            creatorId: author?.id,
                            eventId: null,
                        },
                        page,
                        limit: limit || LIMIT,
                    },
                });

                let filteredTasks = resp?.data?.tasks.filter(task => !task?.taskInEvents.length);
                let checkIsClose = !OwnerOrAdmin ? filteredTasks.filter(task => task?.isDraft === false) : filteredTasks;
                return checkIsClose;
            }

            if (activeTab === formatMessage({ id: 'tab_user_tutorial' })) {
                const resp = await getTasks({
                    // profile and main
                    variables: {
                        filter: {
                            creatorId: author?.id,
                            langId,
                        },
                        page,
                        limit: limit || LIMIT,
                    },
                });
                let filteredTasks = resp?.data?.tasks.filter(task => !task?.taskInEvents.length);
                return filteredTasks;
            }

            if (activeTab === formatMessage({ id: 'tab_tutorial_top' })) {
                const resp = await getTasks({
                    variables: {
                        filter:
                            by === 'tag'
                                ? {
                                    tagIds: [Number(id)],
                                    langId,
                                }
                                : {
                                    subjectId: Number(id),
                                    langId,
                                },
                        page,
                    },
                });

                return resp?.data?.tasks;
            }

            if (activeTab === formatMessage({ id: 'tab_tutorial_featured' })) {
                const resp = await getTasks({
                    variables: {
                        filter:
                            by === 'tag'
                                ? {
                                    tagIds: [Number(1524)],
                                    langId,
                                }
                                : {
                                    subjectId: Number(15),
                                    langId,
                                },
                        page,
                    },
                });

                return resp?.data?.tasks;
            }

            if (activeTab === formatMessage({ id: 'tab_subject_tutorial' })) {
                const resp = await getTasks({
                    //subject/tag list
                    variables: {
                        filter:
                            by === 'tag'
                                ? {
                                    tagIds: [Number(id)],
                                    langId,
                                }
                                : {
                                    subjectId: Number(id),
                                    langId,
                                },
                        page,
                    },
                });

                return resp?.data?.tasks;
            }

            if (activeTab === formatMessage({ id: 'tab_subject_tutorial_new' })) {
                const resp = await getTasksByOrder({
                    variables: {
                        input:
                            by === 'tag'
                                ? {
                                    tagId: Number(id),
                                }
                                : {
                                    subjectId: Number(id),
                                },
                        limit: limit || LIMIT,
                        offset,
                    },
                });

                currentOffset = offset;
                return resp?.data?.tasksByOrder;
            }

            if (activeTab === formatMessage({ id: 'tab_tag_tutorial' })) {
                const resp = await getTasksByOrder({
                    variables: {
                        input: {
                            tagId: Number(`${tag}`),
                        },

                        limit: limit || LIMIT,
                    },
                });
                return resp?.data?.tasksByOrder;
            }

        },
        [author?.id, by, getTasks, getTasksByOrder, id, langId, isUpdateTasks]
    );

    useEffect(() => {
        if (loadingUser && user?.id) {
            return;
        }
        const initFetch = async () => {
            const tasksData = await getTasksByTab(activeTab);
            const tasksList = tasksData?.map((item) => item?.task || item);

            if (tasksData?.length === 0 || []) {
                switch (activeTab) {
                    case formatMessage({ id: 'tab_my_tutorial' }):
                        setPlaceholder(PLACEHOLDER_MY);
                        break;
                    default:
                        setPlaceholder(PLACEHOLDER_EMPTY_TAG);
                }
            }

            if (tasksList) {
                setTasks([...tasksList]);
                if (passTaskToParent) {
                    passTaskToParent([...tasksList]);
                }
            }
            setPage(0);
            setIsMore(tasksData?.length > LIMIT - 1);
        };

        initFetch();
    }, [activeTab, getTasksByTab, user?.id]);

    return (
        <>

            {(type !== "card" && type !== "simple") &&
                <TasksListComponent
                    {...other}
                    tasks={tasks}
                    updateTasks={updateTasks}
                    isMore={isMore ? isMore : false}
                    fetchMoreTasks={fetchMoreTasks}
                    className={className}
                    placeholder={placeholder}
                    user={user}
                    activeTab={activeTab}
                    type={type}
                />
            }
            {type === "simple" &&
                <TasksListSimpleComponent
                    {...other}
                    tasks={tasks}
                    updateTasks={updateTasks}
                    isMore={isMore ? isMore : false}
                    fetchMoreTasks={fetchMoreTasks}
                    className={className}
                    placeholder={placeholder}
                    user={user}
                    activeTab={activeTab}
                    type={type}
                />
            }
            {type === "card" &&
                <TasksCardsComponent
                    {...other}
                    tasks={tasks}
                    fetchMoreTasks={fetchMoreTasks}
                    isMore={isMore ? isMore : false}
                    user={user}
                    size={50}
                    activeTab={activeTab}
                />
            }
        </>

    )


};
