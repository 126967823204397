import { useLazyQuery } from "@apollo/client";
import { GetTagsPrediction } from "../graphQL/queries/GetTagsPrediction";

export function useGetTagsPrediction(query = GetTagsPrediction) {
    const [tagsPrediction, { data, error, loading }] = useLazyQuery(query);
    return {
        tagsPrediction,
        tags: data?.tagsPrediction,
        error, 
        loading
    };
}