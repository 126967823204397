import './scss/index.scss';

import React, {useContext} from 'react';
import { noop } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Loader } from '../../Loader';
import { Button } from 'antd';
import { EventCardContainer, useCreateEvent } from '../../event';
import { useTrackClick } from '../../common';
import {FirebaseContext} from "../../../context/firebase/firebaseContext";

export const BundlesListComponent = (props) => {
    const {
        isLoading,
        bundles,
        user,
        onClick = noop,
        title,
        description,
        currencyStorageCode,
        size,
        isAllow,
        ...other
    } = props;
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { createEvent, loading } = useCreateEvent();
    const trackClick = useTrackClick();
    const {workspace = {}} = useContext(FirebaseContext);
    const {id: workspaceId} = workspace;

    const handleCreateBundle = async () => {
        trackClick('click-bundle-create', 'Create Bundle', 'Bundle Creation Initiated');
        let tagIdsArray = [1524];
        if (isAllow) {
            try {
                const newData = {
                    title: 'New Bundle',
                    type: 'BUNDLE',
                    tagIds: tagIdsArray,
                    taskIds: [],
                    isDraft: true,
                    ...(workspaceId && {workspaceId})
                };

                const createdEventData = await createEvent({
                    variables: { eventData: newData },
                });

                const createdEvent = createdEventData.data?.event;
                history.push(`/create-bundle/${createdEvent.id}`);
            } catch (e) {
                console.warn(e);
            }

        } else {
            history.push(`/payments`);
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className=''>
            {!!bundles?.length ? (
                <>
                    <div className="events-list__component__title">
                        {title && (<h2 className=''>{title}</h2>)}
                        {isAllow && <Button type='secondary' onClick={handleCreateBundle}>{formatMessage({ id: 'create_bundle' })}</Button>}
                    </div>
                    {description && (<p className='wrapper_inner-section'>{description}</p>)}

                    <div className=' events-list__cards events-list__cards_30'>
                        {bundles.map((bundle, i) => (
                            !!bundle ? (
                                <EventCardContainer
                                    {...other}
                                    key={`bundle_${bundle?.id}-${i}`}
                                    event={bundle}
                                    id={bundle?.id}
                                    handleClick={onClick}
                                    size={size ? size : 30}
                                    currencyStorageCode={currencyStorageCode}
                                    type={'card'}
                                    // if I see my task, then check if it is allow to me to see them)
                                    isNotAllow={bundle?.creator?.id !== user?.id ? false : !isAllow}
                                />
                            ) : null
                        ))}
                    </div>
                    {isLoading && (
                        <Loader />
                    )}</>
            ) : isAllow ?
                <div className='add-event-section'>
                    <div className="add-event-section__line-wrapper" aria-hidden="true">
                        <hr className='add-event-section__line' />
                    </div>
                    <div className="add-event-section__buttons">
                        <Button size='small'
                                disabled={loading}
                                onClick={handleCreateBundle}>
                            <span >
                                {formatMessage({ id: 'create_bundle' })}
                            </span>
                        </Button>

                    </div>
                </div> : null
            }

        </div>
    );
}
