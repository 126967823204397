import React from 'react';

export const PermanentTerms = () => {
    return (
        <div>
            <h1>Platform Agreement</h1>
            <p className='wrapper_content-section'>
                "Unschooler OÜ" (hereinafter referred to as the "Platform") will enter into this agreement with any individual or legal entity (hereinafter referred to as the "Student") upon their acceptance of the terms of this agreement. The agreement serves as an offer (public offer) indicating the Platform's intention to provide access to asynchronous online courses under the conditions stated herein to any individual who accepts its terms.
            </p>

            <h2>Rights and Obligations of the Platform and Students</h2>
            <ol>
                <li><strong>The Platform is obligated to:</strong>
                    <ol>
                        <li>Provide the Student with access to the courses at any time during the term specified in the course description.</li>
                        <li>Provide technical support during the use of the courses.</li>
                        <li>Resolve the complaint issue via <a href='mailto:info@unschooler.me'title='info@unschooler.me'>email</a> if needed.</li>
                    </ol>
                </li>
                <li><strong>The Student is obligated to:</strong>
                    <ol>
                        <li>Comply with the terms of access to the courses as set out in this agreement.</li>
                        <li>Not distribute the course content and not provide access to their account to third parties.</li>
                    </ol>
                </li>
            </ol>

            <h2>Platform Dispute resolution and refund Policy</h2>
            <ol>
                <li><strong>Initial Contact:</strong> Any dispute, claim, or controversy arising from or related to this agreement or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall first be addressed by direct communication. The Student should contact the "Creator" or "School Owner" via the provided contact email to seek a resolution.</li>
                <li><strong>Formal Complaint:</strong> If the initial contact does not result in a satisfactory resolution, the Student may submit a formal complaint to the Platform via <a href='mailto:info@unschooler.me'title='info@unschooler.me'>email</a>. The complaint must include a detailed description of the dispute, links, screenshots, any relevant communication with the Creator or School Owner, and the desired resolution.</li>
                <li><strong>Review Process:</strong> Upon receipt of a formal complaint, a dedicated team from the Platform will review the submission within 7 business days. This review will include an evaluation of the provided documentation and may involve further communication with both the Student and the Creator/School Owner to gather additional information or clarification.</li>
                <li><strong>Resolution Proposal:</strong> After the review process, the Platform will propose a resolution. This proposal aims to be fair and balanced, considering the interests of both parties. The Student and the Creator/School Owner will have the opportunity to accept or contest the proposed resolution. If contested, both parties must provide additional evidence or arguments in support of their position.</li>
                <li><strong>REFUND and Cancelation:</strong> Platform will process the refund and service Cancelation according to the Refund and Cancelation policy.</li>
                <li><strong>Final Decision:</strong> If the dispute cannot be resolved through the proposed resolution, the Platform reserves the right to make a final decision on the matter. This decision will be binding and considered as final resolution of the dispute.</li>
                <li><strong>Legal Remedies:</strong> Nothing in this Dispute Resolution Policy precludes either party from seeking legal remedies in accordance with applicable laws.</li>
            </ol>

            <h2>Confidentiality and Data Security</h2>
            <ol>
                <li><strong>Definition of Confidential Information:</strong> Confidential information includes the personal information of the Student provided during registration on the website, as well as all data obtained during the learning process.</li>
                <li><strong>Data Security Measures:</strong> The Platform commits to ensuring the security of confidential data using modern methods of information protection.</li>
                <li><strong>Data Sharing:</strong>The Platform may share the Student's data with the Creator and School Owner to facilitate the educational process and improve the quality of service. The student's data that is accessible includes Email, name, activity in lessons, course enrollment date, last visit date, comments, notes, answers in lessons, and questions and AI answers.</li>
                <li><strong>Communications Consent:</strong> The Student agrees to receive notifications via email regarding the educational process, achievements, new content, feedback, and results.</li>
                <li><strong>Prohibition on Data Transfer:</strong> The Platform does not transfer personal data or any other information to third parties without the consent of the Student, ensuring the confidentiality and integrity of the Student's information.</li>
                <li><strong>Non-disclosure by Student:</strong> The Student agrees not to disclose the content obtained during the learning process and not to provide access to their account to third parties.</li>
                <li><strong>Data Security Concerns:</strong> In the event that the Student has suspicions regarding the security of their data, they are obliged to immediately notify the School Owner or Platform via the Contact email provided.</li>
            </ol>

            <h2>Other Conditions</h2>
            <ol>
                <li><strong>Amendment of Agreement Terms:</strong> The Platform reserves the right to modify the terms of this agreement at any time, with prior notification to the Parties. Changes to the agreement will be posted on the Platform's website, and continued use of the Platform by the Student following any changes signifies acceptance of the new terms.</li>
                <li><strong>Commencement and Duration of Agreement:</strong> This agreement takes effect at the moment it is accepted by the Student. The agreement remains in force until all obligations by both the School (including the Creator or School Owner) and the Student have been fulfilled. Acceptance is typically marked by the Student's registration on the Platform or by payment for a course, whichever occurs first.</li>
            </ol>

            <h2>Addresses, Details of the Parties</h2>
            <ul>
                <li>The Parties unconditionally agree to consider the information specified by the User during authorization to be the User's details.</li>
                <li>Unschooler OÜ</li>
                <li>Harju maakond, Tallinn, Kesklinna linnaosa, Ahtri tn 12, 10151</li>
                <li>Email: info@unschooler.me</li>
            </ul>
        </div>
    );
};