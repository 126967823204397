// import { PaidComponent } from "../common";
import classnames from 'classnames';
// import { useIntl } from 'react-intl';

export const Tab = (props) => {
    const { items, handleClick, activeTab, className, type = 'small' } = props;

    const TitleClass =
        classnames('tab__item inline-el', {
            'tab__item__small': type === 'small',
            'tab__item__big': type === 'big',

        });

    return (
        <ul className={className ? className : 'tab'}>
            {items?.map((item) => (
                <li
                    key={item}
                    className={item === activeTab
                        ? TitleClass + ' tab-item_active'
                        : TitleClass}

                    onClick={() => handleClick(item)}
                >
                    {item}
                </li>
            ))}
        </ul>
    );
};
