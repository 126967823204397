import './scss/index.scss';
import React from 'react';
import {Svg} from '../../common/Svg';

export const FeatureComponent = ({ title, src, description, color }) => {
    return (
        <>
            <Svg
                name={`${src}`}
                className='icon_32 supertext_svg'
                viewBox={`0 0 48 48`}
                width={'28px'}
                fill='#fff'
            />
            <h2 >{title}</h2>
            <p className='text_large title-block'>{description}</p>
        </>
    );
};
