import './scss/index.scss';

import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocalePrefix } from '../../lang';
import { Form, Input, Button, Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LoaderTypeComponent, SignInComponent, Svg } from '../../common';
import { openNotification } from '../../notification/Notification.component';
const { TextArea } = Input;

export const AIBotComponent = ({
    user,
    spec,
    picture,
    titleHide,
    form,
    height,
    careers,
    handleInsightAi,
    handleSubmitAi,
    handleMoreAi,
    handleChange,
    handleFocus,
    handleShareTask,
    handleUndo,
    handleReload,
    handleClean,
    respondAPI,
    tags,
    undoDisable,
    isVisible,
    value,
    inProgress,
    respondHtml,
    previewHtml,
    task,
    insights,
    loginTemp,
    ...other
}) => {
    const [localePrefix] = useLocalePrefix();
    const isLoader = useMemo(
        () => user?.id && inProgress,
        [user?.id, inProgress]
    );
    const { formatMessage } = useIntl();
    const getPlaceholder = formatMessage({ id: 'ai_mentor_place' });
    return (
        <div className='ai-bot-component_wrapper'>
            {picture !== false && (
                <div className='ai-bot-component_picture'>
                    <img
                        src={`https://cdn.unschooler.me/web_app/ai-mentors/${spec?.person || 'engelbart'
                            }.png`}
                        alt=''
                    />
                </div>
            )}

            {isLoader && <LoaderTypeComponent />}

            {task?.id && (
                <div className='ai_bot-buttons-wrapper wrapper_inner-section'>
                    <div>
                        <Button
                            disabled={inProgress}
                            type={'text'}
                            className='ai-bot-component__button'
                            size='small'
                            onClick={(e) => handleMoreAi(e)}
                            tooltip='This is the tooltip text'
                        >
                            <span>{formatMessage({ id: 'more' })}</span>
                        </Button>
                        <Tooltip title='Undo'>
                            <Button
                                disabled={inProgress || !user?.id}
                                type='text'
                                className='ai-bot-component__button'
                                size='small'
                                onClick={handleUndo}
                                tooltip='This is the tooltip text'
                            >
                                <span>
                                    <Svg
                                        name=':icon/arrow-left'
                                        className='icon_16'
                                    />
                                </span>
                            </Button>
                        </Tooltip>

                        <Tooltip title='Generate again'>
                            <Button
                                disabled={inProgress}
                                type='text'
                                className='ai-bot-component__button'
                                size='small'
                                onClick={handleReload}
                                tooltip='This is the tooltip text'
                            >
                                <span>
                                    <Svg
                                        name=':icon/reload'
                                        className='icon_16'
                                    />
                                </span>
                            </Button>
                        </Tooltip>
                    </div>
                    <div>
                        <CopyToClipboard
                            text={`https://unschooler.me${localePrefix}/tutorials/${task?.id}`}
                            onCopy={() => {
                                if (user?.id)
                                    openNotification('topLeft', {
                                        title: 'Tutorial is published. Link is copied to clipboard.',
                                        // description:
                                        //     'Show your students and friends',

                                        description: (
                                            <p>
                                                <a
                                                    href={`https://unschooler.me${localePrefix}/tutorials/${task?.id}`}
                                                >
                                                    {task?.title
                                                        ? task?.title
                                                        : 'Go see your tutorial'}
                                                </a>
                                            </p>
                                        ),
                                        icon: '🤘',
                                        duration: 3,
                                    });
                            }}
                        >
                            <Button
                                disabled={inProgress}
                                type='tertiary'
                                className='ai-bot-component__button'
                                size='small'
                                onClick={handleShareTask}
                            >
                                <span>
                                    <Svg
                                        name=':icon/share'
                                        className='icon_16 margin_right'
                                    />
                                    {formatMessage({ id: 'share' })}
                                </span>
                            </Button>
                        </CopyToClipboard>
                    </div>
                </div>
            )}
            <div className='wrapper_inner-section'>
                {!respondHtml && !!insights?.length && (
                    <>
                        <div className=' wrapper_inner-section'>
                            {insights?.map((ins, index) => (
                                <Button
                                    key={`insight-${index}`}
                                    size='small'
                                    type='secondary'
                                    onClick={() => handleInsightAi(ins)}
                                    className='insight_btn wrapper_inner-section'
                                    disabled={inProgress}
                                >
                                    ✧ {ins}
                                </Button>
                            ))}
                        </div>
                    </>
                )}
            </div>
            <>
                <Form
                    className='wrapper_inner-section'
                    onFinish={handleSubmitAi}
                    form={form}
                    layout='vertical'
                >
                    <Form.Item
                        name='ask'
                        onChange={handleChange}
                        onKeyDown={handleFocus}
                    >
                        <div className='ai-bot-component__wrapper'>
                            <TextArea
                                placeholder={getPlaceholder}
                                className='ai-bot-component__field'
                                value={value}
                                style={height && { minHeight: height }}
                                disabled={!user?.id ? true : false}
                                autoSize={{
                                    minRows: 2,
                                    maxRows: 16,
                                }}
                            />

                            {(user?.id || isVisible) && (
                                <div className='ai-bot-component__buttons'>
                                    <Button
                                        htmlType='submit'
                                        disabled={inProgress}
                                        type='text'
                                        size='small'
                                        className=''
                                    >
                                        <Svg
                                            name=':icon/send'
                                            className='icon_24'
                                        />
                                    </Button>
                                </div>
                            )}


                        </div>
                    </Form.Item>
                </Form>
                {!user?.id && (
                    <SignInComponent
                        type='tertiary'
                        block='true'
                        title=" Try AI mentor for free"
                        isSighUp={true}
                    />
                )}

                {respondHtml && (
                    <div>
                        {insights?.length > 0 &&
                            insights.map((ins, index) => (
                                <Button
                                    key={`insight_${index}`}
                                    size='small'
                                    type='secondary'
                                    onClick={() => handleInsightAi(ins)}
                                    className='insight_btn wrapper_inner-section'
                                    disabled={inProgress}
                                >
                                    ✧ {ins}
                                </Button>
                            ))}
                    </div>
                )}
            </>
        </div>
    );
};
