import './scss/index.scss';
import React, { useMemo } from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openNotification } from '../../notification/Notification.component';
import { Svg, useHostNameUrl } from '../../common';
import { EmbedCopyComponent } from '../EmbedCopy.component/EmbedCopy.component';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import { useIsPermit } from '../../roles/hook/useCheckPermission';

export const WorkspaceTitleComponent = (props) => {
    const { workspace, setEditmode } = props;
    const workspaceUrl = useHostNameUrl(workspace);
    const {checkPermit} = useIsPermit()
    const editSchool = checkPermit(workspace, 'editSchool')
    const { formatMessage } = useIntl();
    const url = useMemo(() => {
        const { id, alias } = workspace || {};
        const isBoth = id && alias;
        return isBoth ? `${alias}-${id}` : alias || id;
    }, [workspace]);

    return (
        <div className='workspace__header'>
            <div className='profile__header'>
                <div className='workspace__logo'>
                    {workspace?.logo && (
                        <LazyLoadImage 
                        src={workspace?.logo}
                        alt="promo picture"/>
                    )}
                </div>
                <div className='Answer__actions Answer__actions_horizontal'>
                    <div className='Answer__actions_top'>
                        {editSchool && setEditmode && (
                            <>
                                <Button
                                    type='text'
                                    className=' panel-block__item__btn link-btn btn_white'
                                    onClick={() => setEditmode(true)}
                                    size='small'
                                >
                                    <Svg
                                        name=':icon/edit'
                                        className='icon_24'
                                    />
                                    <span>{formatMessage({ id: 'form_workspace_settings' })}</span>
                                </Button>
                                <EmbedCopyComponent
                                    size="small"
                                    classnames='hide-on-mobile inline-el panel-block__item__btn btn_white '
                                    url={workspaceUrl}
                                    title={`${workspace?.title}`}
                                    userIsAllow={editSchool}
                                />
                            </>

                        )}
                        {/* <CopyToClipboard
                            text={workspaceUrl}
                            onCopy={() => {
                                openNotification('topLeft', {
                                    title: formatMessage({
                                        id: 'notif_copy_link_title',
                                    }),
                                    description: formatMessage({
                                        id: 'notif_copy_event_link_workspace',
                                    }),
                                    icon: '💬',
                                    duration: 3,
                                });
                            }}
                        >
                            <Button
                                type='link'
                                className='hide-on-mobile inline-el panel-block__item__btn btn_white '
                            >
                                <Svg
                                    name=':icon/share'
                                    className='icon_32'
                                />
                            </Button>
                        </CopyToClipboard> */}
                    </div>
                </div>

            </div>
            {!workspace?.hideTitle && (
                <h1 className='supertitle inline-el skills__title'>
                    {workspace?.title}
                </h1>
            )}

            {workspace?.description && (
                <p>{workspace?.description}</p>
            )}
            <div
                className='workspace_cover'
                style={{backgroundImage: `url('${workspace?.cover || ''}')`,}}
            ></div>
        </div>
    );
};
