import React from 'react';
import { Button } from 'antd';
import { Svg, LoaderComponent } from '../../common';

export const DeleteMemberComponent = ({ memberId, memberGroup, loading, handleDeleteUser }) => {
    return (
        <Button
            className='inline-el link-btn_light'
            type='text'
            onClick={() => handleDeleteUser(memberId, memberGroup)}
        >
            {loading && <LoaderComponent type="inline"/>}
            {!loading && (
                <Svg
                    name=':icon/cross'
                    className='btn-icon icon_16'
                />
            )}
        </Button>
    );
};
