import {useLazyQuery} from '@apollo/client';
import {GET_IMAGES_LIST} from '../graphQL/queries/GetImageList';

export function useGetImagesByString(query = GET_IMAGES_LIST) {
    const [getImagesList, {data, loading, error}] = useLazyQuery(query);
    return {
        data,
        loading,
        error,
        getImagesList
    };
}
