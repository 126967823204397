import { useMutation } from '@apollo/client';
import {CreateStripeSession} from '../graphQL/mutations/CreateStripeSession';

export const useCreateStripeSession = (mutation = CreateStripeSession) => {
    const [createStripeSession, { data, error, loading }] = useMutation(mutation);

    return {
        data,
        error, loading,
        createStripeSession
    };
}