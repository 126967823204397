import React from 'react';

export const PermanentPrivacy = () => {
    return (
        <div>
            <h3>Personal Data Processing Policy</h3>
            <ul classname='list_policy'>
                <li>
                    The Unschooler.me (hereinafter referred to as the "Platform") administration hereby
                    undertakes to maintain user's privacy on the
                    Internet.
                </li>

                <li>
                    Platform Privacy Policy is based on the requirements of
                    the General Regulation on the Protection of Personal
                    Data of the European Union (GDPR).
                </li>
                <li>
                    Purposes for which we collect personal data include
                    improving the operation of Platform service, providing
                    the information requested by users, providing
                    personal career guidance and learning content,
                    contacting Platform visitors, and informing them
                    about upcoming online courses by e-mail.
                </li>
            </ul>

            <h3>Personal Data Collection and Use</h3>
            <ul className='list_policy'>
                <li>
                    The Platform will collect and use the
                    user's personal data only with the user's voluntary
                    consent, which you provide by accepting the Terms
                    posted on Platform.
                </li>
                <li>
                    Thereby, the user authorizes Platfrom to collect
                    and use the following data: user name in Google
                    account, email address, profile picture, and phone
                    number for Mobile app users. Users can change names
                    and profile pictures on the Platfrom
                    anytime.
                </li>

                <li>
                    When testing skills, users authorize us to collect
                    YouTube videos that they like, if any. Tags of these
                    videos are used for skills and career analysis.
                </li>

                <li>
                    User's name and skills can be visible to other
                    people on the user's profile page and on the
                    published tutorial pages. Users can change their
                    names to make their profile anonymous.

                    <h4>Course page</h4>
                    <p>
                        The tutorial page is accessible for other people
                        only if the user publishes or shares it. (Visual design can change)
                    </p>
                    {/* <img
                                className='answer-results__form'
                                src='https://cdn.unschooler.me/web_app/tech_pictures/course.png'
                                alt=''
                            /> */}
                    <h4>Lesson page</h4>
                    <p>
                        The lesson page is accessible for other people
                        only if the user publishes or shares it. (Visual design can change)
                    </p>
                    {/* <img
                                className='answer-results__form'
                                src='https://cdn.unschooler.me/web_app/tech_pictures/tutorial.png'
                                alt=''
                            /> */}
                </li>

                <li>
                    User's data will be collected and processed in line
                    with the legislation currently in force in the
                    territory of the European Union.
                </li>

            </ul>

            <h3>Data Storage, Modification and Deletion</h3>
            <ul classname='list_policy'>
                <li>
                    Users who share their personal data with the
                    unschooler.me Platform have the right to change and
                    delete them, as well as to withdraw their consent to
                    data use.
                </li>
                <li>
                    To revoke personal data, please,  at {' '}
                    <a
                        href='mailto:info@unschooler.me'
                        title='info@unschooler.me'
                    >
                        contact the
                    Platform administration
                    </a>
                    . User's authorization and membership will be
                    canceled, and personal data will be deleted within
                    ten (10) days from the date of your request. Please
                    note: we are not able to restore the deleted data.
                </li>

                <li>
                    The repeated authorization will entail the renewal
                    of the user's consent to the collection and use of
                    personal data.
                </li>
                <li>
                    Personal data storage time is limited to the period
                    over which this data is necessary to facilitate the
                    main activity of the Platform.
                </li>
                <li>
                    We can transfer user's personal data to a third
                    party only with your voluntary consent or if we are
                    required to provide them under the current
                    legislation.
                </li>
                <li>
                    Teachers and School owners can send Personal invitations in specific schools and courses as well as Reminders about assessments.
                </li>
                <li>
                    Students'email is accessible for Course owner. The Platform may share the Student's data with the Creator and School Owner to facilitate the educational process and improve the quality of service. The student's data that is accessible includes Email, name, activity in lessons, course enrollment date, last visit date, comments, notes, answers in lessons, and questions and AI answers.
                </li>
            </ul>
            <h3>Use of Technical Data When Visiting the Website</h3>
            <ul classname='list_policy'>
                <li>
                    When people visit the unschooler.me Platform, the
                    database records their IP address, country, time of the
                    visit, browser settings, information about their
                    operating system, language preferences, as well as other technical
                    information required to display the Platform content
                    correctly.
                </li>
                <li>
                    It is{' '}
                    <strong>impossible to identify the user</strong>{' '}
                    based on this information.
                </li>
            </ul>

            <h3>Information Provided by Minors</h3>
            <ul classname='list_policy'>
                <li>
                    If users are a parent or guardians and know that
                    their children have shared their personal data with
                    us without your consent, please, 
                    <a href='mailto:info@unschooler.me' title='info@unschooler.me'> contact us</a>.
                </li>
            </ul>

            <h3>Cookie Policy</h3>
            <ul classname='list_policy'>
                <li>
                    The unschooler.me platform uses cookies to ensure
                    the correct display of website content, as well as
                    for user convenience.
                </li>
                <li>
                    Cookies are small files that are stored on the
                    user's device.
                </li>
                <li>
                    They help the unschooler to remember information
                    about users. For example, language, anonymized information of the first
                    opened pages, retain user preferences, store information about your school and daily progress. This information will be useful during
                    your next visit.
                </li>
                <li>
                    Users can configure browser settings to accept or
                    block cookies.
                </li>
                <li>
                    Please note that disabling cookies will affect the functionality of payments, localisation and authentication.
                </li>

                <li>
                    The unschooler.me platform uses the following cookies:
                    <ul classname='list_policy'>
                        <li>
                            Microsoft Azure: To ensure that Platform website functions correctly and can handle the volume of visitors we receive, we use Azure's cookies for load balancing, ensuring that all users have a consistent and reliable experience.
                        </li>
                        <li>
                            Google Analytics: To understand how Platform users interact with the website and to make informed decisions about design and functionality, we rely on the data provided by Google Analytics cookies.
                        </li>
                        <li>
                            Customerly: For those who choose to interact with our customer service team through chat, cookies are used to provide a seamless chat experience.

                        </li>
                        <li>
                            Sentry: We employ Sentry cookies to monitor Platform website and automatically report any errors that may occur. This helps us identify and fix bugs swiftly.

                        </li>
                        <li>
                            Google: Besides analytics, we use Google's cookies for various functionalities across Platform website, which may content personalisation by Location.

                        </li>
                        <li>
                            Stripe: Stripe is a payment processing service that we use to handle secure payments on Platform website. When you make a transaction, Stripe uses cookies to remember your details and process the payment efficiently, ensuring a secure and reliable checkout experience.
                        </li>
                    </ul>

                </li>

            </ul>

            <h3>Use of Personal Data by Other Services</h3>
            <ul classname='list_policy'>
                <li>
                    The Platfrom uses the following:
                    <ul classname='list_policy'>
                        <li>
                            Google auth service for user authorization.
                            Users agree to be bound by the{' '}
                            <a href='https://policies.google.com/privacy?hl=en-US'>
                                Google Privacy Policy
                            </a>.
                        </li>
                        <li>
                            YouTube API for embedding videos in the
                            courses and tutorials requested by users.
                            Additionally, the platform uses YouTube to
                            test users' interests. By using the
                            Platfrom, users agree to be bound
                            by the {' '}
                            <a href="https://www.youtube.com/t/terms" target="_blank">
                                YouTube's Terms of Service
                            </a>.
                        </li>

                        <li>
                            OpenAI service for AI-generated content. By
                            using the Platfrom, users agree
                            to be bound by the{' '}
                            <a href='https://openai.com/policies/api-data-usage-policies'>
                                OpenAI Data Policy
                            </a>.
                        </li>
                        <li>
                            All payments are securely processed through
                            Stripe. By using the Platfrom,
                            users agree to be bound by the{' '}
                            <a href='https://support.stripe.com/topics/privacy'>
                                Stripe Privacy Policy
                            </a>
                            .
                        </li>
                        <li>
                            This platform uses Google Analytics and
                            Google Tag Manager. Data
                            collected by them may be provided to other
                            services within these organizations. The
                            latter may use the data to
                            advertisements in their own advertising
                            network. It is{' '}
                            <strong>impossible to identify the user</strong>{' '}
                            based on this data.
                        </li>
                    </ul>
                    <li>
                        User agreements of these organizations are
                        available on their respective websites.
                    </li>
                    <li>
                        We do not share personal data with other
                        organizations and services not specified in this
                        Privacy Policy.{' '}
                    </li>
                </li>
            </ul>
            <h3>URLs to Other Websites</h3>
            <ul classname='list_policy'>
                <li>
                    Our platform may contain links to other websites
                    that are not managed by us. We are not responsible
                    for their content.
                </li>
            </ul>
            <h3>Changes to the Privacy Policy</h3>
            <ul classname='list_policy'>
                <li>Our Privacy Policy can be updated.</li>
                <li>
                    We will notify users of any changes by posting the
                    new Privacy Policy on this page.
                </li>

                <li>
                    We monitor changes in the legislation concerning
                    personal data in the European Union.
                </li>
            </ul>

            <h3>Feedback and Final Provisions</h3>
            <ul classname='list_policy'>
                <li>
                    To contact the administration of Platfrom on matters related to
                    the privacy policy, please,
                    <a href='mailto:info@unschooler.me' title='info@unschooler.me'> send an email</a>.
                </li>
                <li>
                    If you do not agree with this Privacy Policy,
                    please, refrain from visiting Platform website.
                </li>
            </ul>
        </div>
    );
};