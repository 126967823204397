import { useCallback } from 'react';
import { useCreateTags } from '../../task/hooks/useCreateTags';

export const useGetTags = () => {
    const { createTags } = useCreateTags();
    const getTags = useCallback(async (list) => {
        if (!list) return {};
        const newTags = [];
        const oldTags = [];

        list?.forEach((t) => {
            const cT = Number(t);
            if (isNaN(cT)) {
                newTags.push(t.trim().replace(/\s+/g, ' '));
            } else {
                oldTags.push(cT);
            }
        });

        let tags = [...oldTags];

        if (newTags.length) {
            const resp = await createTags(newTags);
            tags = resp.map((tag) => tag.id);
        }

        return tags;
    }, [createTags])

    return getTags;
};

export const getSimpleTagIds = (tags) => {
    let tagIdsArray = [];

    if (tags) {
        tags.forEach((tag) => tagIdsArray.push(tag.id));
    } else {
        tagIdsArray = [1524];
    }

    return tagIdsArray;

}