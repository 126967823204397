import "../"
import React, { useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { noop } from "lodash";
import { ReactMarkdownComponent } from "../../task/ReactMarkdown.component/ReactMarkdown.component";
import { Tabs, Button } from 'antd';

import { EnrollContainer } from '../../enroll';
import { FooterContainer } from "../../footer";
import { RelatedEventsContainer } from "../RelatedEvents.container";
import { TasksListComponent } from "../../task";
import { useLocalePrefix } from "../../lang";
import { TitleComponent, useIsAllow, LoginContainer, useTrackClick, TagComponent, Svg, getReadingTime, PaidComponent } from "../../common";
import { AiGenerateCourseButtonComponent, AiPublishSalesGeneratorComponent } from '../../ai';
import { BASE_JITSI_URL } from "../../constant";
import { StudentsTableTabContainer } from '../../rating/StudentsTableTab.container';
import { MentorBlockContainer } from '../MentorBlock.container';
import { CertificateCard } from '../../certificates';
import { ReviewTabContainer } from '../ReviewTab.container'
import { ProgressBarComponent } from '../../profile/ProgressBar.component/ProgressBar.component';
import { ObjectivesComponent } from '../Objectives.component/Objectives.component';
import { AchievementsProgress } from '../../badges/AchievementsProgress.component';

const { TabPane } = Tabs;

export const EventComponent = (props) => {
    const {
        event,
        user,
        call,
        setupCall = noop,
        onParticipate = noop,
        onCheckout = noop,
        reviews,
        tasks,
        eventColor,
        isEmbed,
        hostname,
        workspace,
        handleDraft,
        editOthersCourses,
        instructorSubscriptions,
        tagsRenew,
        ...other
    } = props;

    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();
    const trackClick = useTrackClick();
    const isSubscriptionRef = useRef();
    const [showLogin, setShowLogin] = useState();
    const [returnBack, setReturnBack] = useState();
    const roomUrl = useMemo(
        () => `${event?.alias}-${event?.id}`,
        [event?.alias, event?.id]
    );
    const isWhiteLabeled = hostname || isEmbed
    const useIsAllowd = useIsAllow();
    const isPaidEditor = useIsAllowd('PREMIUM', workspace, event?.creator?.id);
    const isEditor = (user?.id === event?.creator?.id && isPaidEditor) || editOthersCourses || user?.role?.id === 1;
    const showIfParticipantsOnly = isPaidEditor || event.isSubscribe;
    const isInstructor = instructorSubscriptions?.find(el => el.studentId === user?.id);


    const handleParticipate = (data) => {
        const { workspaceId, studentId } = data || {};
        const isWorkspaceSubscription = !!workspaceId && studentId === user?.id;

        trackClick(
            "click-checkout",
            event.id,
            `${window.location.origin}${localePrefix}/online-courses/${event.id}`
        );

        if (!user?.id) {
            handleLogin();
            // isSubscriptionRef.current = isSubscription;
        } else {
            if (event?.price && !isWorkspaceSubscription) {
                onCheckout(data?.isMonthMode);
                return;
            }
            onParticipate(data);
        }
    };
    const handleLogin = (options) => {
        trackClick(
            "click-login",
            event.id,
            `${window.location.origin}${localePrefix}/online-courses/${event.id}`
        );

        const { afterLoginActions } = options || {};

        if (afterLoginActions) {
            setReturnBack(afterLoginActions);
        }
        setShowLogin(true);
    };

    const handleLoginUser = () => {
        if (returnBack) {
            for (const key in returnBack) {
                window.localStorage.setItem(key, returnBack[key]);
            }
            return;
        }
        if (event?.price) {
            onCheckout(isSubscriptionRef.current);
        }
    };
    const findProceedTask =
        tasks?.length > 0 &&
        tasks?.find((task) => task.stateResult !== "CORRECT" || tasks[0]);

    const isGenerated = event.tasks?.every((task) => task.description && task.questions?.every(question => question.question));
    const reading = getReadingTime(event.tasks, formatMessage);
    const taskLink = isEmbed ? `${localePrefix}/embed/tutorials/${findProceedTask?.alias}-${findProceedTask?.id}` : `${localePrefix}/tutorials/${findProceedTask?.alias}-${findProceedTask?.id}`;
    const isVisual = event?.galleriesInEvent?.length > 0 && event.galleriesInEvent?.find(gallery => gallery?.gallery?.type === "PHOTO")?.gallery?.source
    const defaultActiveKey = ((event?.userSubscriptions?.length > 0 || isGenerated) && (isEditor || isInstructor)) ? '2' : '1';

    return (
        <div className="wrapper_center">
            <div className="event-header">
                <TitleComponent title={event?.title} back={true} />
                {(event?.isSubscribe) &&
                    <Link
                        to={taskLink}
                        block="true"
                        className="ant-btn ant-btn-tertiary link-btn-tertiary  "
                    >
                        <span>
                            {formatMessage({ id: "event_start_learning" })}
                        </span>
                    </Link>
                }
            </div>
            <div className="flex-container_center">
                <div className="flex-collumn flex-left-column_center">
                    {/* static info */}
                    <div className="event-component__box event-description ">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={formatMessage({ id: 'about_tab' })} key="1">
                                {isVisual && (
                                    <div className="wrapper_section">
                                        <div className="wrapper_image_card" style={{
                                            backgroundImage: `url(${isVisual})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: '50%'
                                        }}>
                                        </div>
                                    </div>
                                )}


                                {event.program && (
                                    <div className="event-description  wrapper_outer-section">
                                        <h2 className="event-component__row__title">
                                            {formatMessage({ id: "learning_objectives_label" })}
                                        </h2>
                                        <ObjectivesComponent event={event} />
                                    </div>
                                )}
                                {event.description && (
                                    <div className="event-description wrapper_outer-section">
                                        <div className="Answer__info">
                                            <h2>{formatMessage({ id: 'overview_title' })}</h2>
                                            <ReactMarkdownComponent children={event.description} />
                                        </div>
                                    </div>
                                )}

                                {(event?.issue || event?.tasks?.length > 0) && (
                                    <div>
                                        <h2 className="event-component__row__title">
                                            {formatMessage({
                                                id: "event_tasks",
                                            })}{" "}
                                        </h2>


                                        <div className='wrapper_content-section'>
                                            {event.isSubscribe && (
                                                <ProgressBarComponent
                                                    event={event}
                                                    color={eventColor}
                                                    size='small'
                                                    description={formatMessage({ id: 'lessons_learned_title' })}
                                                />
                                            )}
                                            {isGenerated &&
                                                <p className="wrapper_inner-section">
                                                    {formatMessage({ id: "learning_time" })}: {event?.duration || reading}</p>
                                            }
                                        </div>
                                        {event?.badge && !!tagsRenew?.length &&
                                            <div className=' cards '>
                                                {tagsRenew?.map((tag, i) => (
                                                    <div className='skills_tag_icon'>
                                                        <AchievementsProgress
                                                            type={`small`}
                                                            key={tag?.id}
                                                            achievement={tag}
                                                            width={80}
                                                        />

                                                    </div>
                                                ))}
                                            </div>
                                        }

                                        {(event?.isSubscribe) &&
                                            <Link
                                                to={taskLink}
                                                block="true"
                                                className="ant-btn ant-btn-tertiary link-btn-tertiary  block-el"
                                            >
                                                <span>
                                                    {formatMessage({ id: "event_start_learning" })}
                                                </span>
                                            </Link>
                                        }

                                        {!!event?.tasks?.length && (
                                            < TasksListComponent
                                                {...other}
                                                tasks={tasks}
                                                user={user}
                                                actionHidden={false}
                                                handleParticipate={handleParticipate}
                                                isMentorOrAdmin={isEditor}
                                                needLogin={true}
                                                handleLogin={handleLogin}
                                                isEmbed={isEmbed}
                                                type={event?.isDraft ? 'link' : null}
                                            />
                                        )}
                                        {event?.certificate && (
                                            <CertificateCard
                                                achievement={event.certificate}
                                                user={user}
                                                workspace={workspace}
                                                event={event}
                                                isEmbed={isEmbed}
                                                localePrefix={localePrefix}
                                                {...other}
                                            />
                                        )}
                                    </div>
                                )}
                                {event.equipment && (
                                    <div className="event-description  wrapper_outer-section">
                                        <h2 className="event-component__row__title">
                                            {formatMessage({ id: "prerequisites_and_equipment_label" })}
                                        </h2>
                                        <ReactMarkdownComponent children={event.equipment} />
                                    </div>
                                )}

                                <MentorBlockContainer
                                    user={user}
                                    workspace={workspace}
                                    event={event}
                                    instructorSubscriptions={instructorSubscriptions}
                                    {...other}
                                />
                            </TabPane>
                            {(isEditor || isInstructor) && (
                                <TabPane tab={`${formatMessage({ id: "students_tab" })} ${event.countAllUserSubscriptions}`} key="2">
                                    <StudentsTableTabContainer
                                        userList={event.userSubscriptions}
                                        event={event}
                                        workspace={workspace}
                                        user={user}
                                        {...other}
                                    />
                                </TabPane>
                            )}
                            {/* <TabPane tab={`${formatMessage({ id: 'reviews_tab' })} (soon)`} key="3" disabled >
                                <ReviewTabContainer event={event} />
                                <h3>{formatMessage({ id: 'soon_title' })}</h3>
                                <div className=" wrapper_image_card" style={{ width: 800 }} >
                                    <img className='picture_absolute' width='800' src="https://cdn.unschooler.me/17095703674716cd.png" alt="Event Gallery" />
                                </div>
                            </TabPane> */}
                        </Tabs>

                    </div>
                </div>

                <div className="flex-right-column sticky_enroll">
                    {(isEditor) && (
                        <div className="event_actions_vertical ">
                            <div className="wrapper_content-section">
                                {(!isGenerated) && (<AiGenerateCourseButtonComponent event={event} {...other} userIsAllow={isPaidEditor} />)}
                                {(isGenerated) &&
                                    <h3 className="text_secondary btn_passed"><span
                                        className='menu-icon_type margin_right '
                                        style={{ fontSize: 26 }}
                                    >✦</span>1. {formatMessage({ id: 'completion_alert' })}</h3>}
                                <div>
                                    <Link
                                        to={{
                                            pathname: isPaidEditor
                                                ? `${localePrefix}/create-event/${event.id}`
                                                : `${localePrefix}/payments/`,
                                            state: {
                                                event,
                                                eventId: event?.id,
                                                isEdit: true,
                                                workspace: workspace,
                                            },
                                        }}
                                        className=' ant-btn ant-btn-secondary btn-left wrapper_inner-section block-el'
                                    >
                                        <Svg
                                            name=':icon/edit'
                                            className='icon_24 margin_right'
                                        />2.{' '}
                                        {formatMessage(isPaidEditor ? { id: 'event_change', } : { id: 'event_upgrade' })}
                                        {!isPaidEditor && <PaidComponent />}
                                    </Link>
                                </div>

                                {
                                    event.isDraft
                                        ? <AiPublishSalesGeneratorComponent event={event} {...other} />
                                        : <Button onClick={() => handleDraft()} type="secondary" className="btn-left" block>
                                            <Svg name=':icon/cross' className='icon_24 margin_right' />
                                            {formatMessage({ id: 'remove_button' })}
                                        </Button>
                                }

                            </div>
                        </div>
                    )}
                    <div >
                        {!event?.isDraft && (
                            <EnrollContainer
                                event={event}
                                handleParticipate={handleParticipate}
                                user={user}
                                isEditor={isEditor}
                                onLogin={handleLogin}
                                workspace={workspace}
                                reading={reading}
                                {...other}
                            />
                        )}

                        {event?.tags?.map((tag, i) => (
                            <div
                                key={`skill-tag-${tag?.id}`}
                                className='skill_progress_inline'
                            >
                                <TagComponent
                                    key={`tag_${i}`}
                                    tag={tag}
                                    link={!isWhiteLabeled}
                                    countResults={tag?.countResults &&
                                        { all: 20, done: tag?.countResults || 0, }}
                                    {...other}
                                />
                            </div>

                        ))}
                        {showIfParticipantsOnly &&
                            <div>
                                <a
                                    className="ant-btn ant-btn-text jitsi-mobyle-button"
                                    href={BASE_JITSI_URL + roomUrl}
                                    title={formatMessage({ id: "event_connect_to_call", })}
                                >
                                    <span>
                                        {formatMessage({ id: 'event_proceed' })}
                                    </span>
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div >

            {/* additional blocks */}
            {
                !!event?.relatedEvents?.length && (
                    <RelatedEventsContainer
                        relatedEvents={event?.relatedEvents}
                        user={user}
                        isFetchEvents={true}
                    />
                )
            }
            <LoginContainer
                showLogin={showLogin}
                setCancelLogin={setShowLogin}
                onLoginUser={handleLoginUser}
                isSighUp={true}
            />

            {!isWhiteLabeled &&
                <FooterContainer
                    workspace={workspace}
                />
            }
        </div>
    );
};
