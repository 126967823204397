import React from "react";
import classnames from "classnames";

export const DropdownComponent = (props) => {
  const {
    options,
    optionKey,
    optionLabel,
    currentValue,
    type = "top",
    onChange,
  } = props;

  const currentItem = options.find((item) => item[optionKey] === currentValue);

  if (!currentItem) {
    return null;
  }

  const getClassNames = (id) =>
    classnames("dropdown-component__item", {
      "dropdown-component__item_active": id === currentValue,
    });

  return (
    <div className="dropdown-component">
      <input
        className="dropdown-component__label"
        type="text"
        value={currentItem[optionLabel]}
        readOnly={true}
      />
      <ul
        className={`dropdown-component__body dropdown-component__body_${type}`}
      >
        {options &&
          options.map((item) => (
            <li
              className={getClassNames(item[optionKey])}
              key={item[optionKey]}
            >
              <span
                className="dropdown-component__item__label"
                onClick={() => onChange(item[optionKey])}
              >
                {item[optionLabel]}
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
};
