import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames';
import {get, isEmpty, map, noop} from 'lodash';
import {Button} from 'antd';
import translate from '../../../lang/translate';
import {ProgramContext} from '../../../context/program/ProgramContect';
import {AddTaskButtonComponent, TasksListContainer} from '../../task';
import {PanelEditTaskContainer} from '../PanelEditTask.container';
import {PanelContainer} from '../Panel.container';
import {PanelAnswerComponent} from '../PanelAnswer.component';
import {Svg} from '../../common';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

const getConvertedThemes = (data) => {
    return map(data, (name, id) => {
        return {name, id};
    });
};

export const PanelThemeComponent = (props) => {
    const {
        panelData,
        isCloseAdditionalPanel,
        onRemovePanel = noop,
        onAddPanel = noop,
        onToogleClass = noop,
        hasExtraClass,
        panelNode,
        ...other
    } = props;

    const [addAnswer, setAddAnswer] = useState();
    const [task, setAnswer] = useState();
    const themesList = getConvertedThemes(get(panelData, 'themesList'));
    const {themeData, onSetThemeData} = useContext(ProgramContext);
    const {user, loginUser} = useContext(FirebaseContext);
    const [themeFavorite, setThemeFavorite] = useState();
    const [empty, setEmpty] = useState(false);

    const handleAddAnswer = async () => {
        if(!user.id) {
            let userData = await loginUser();
            if(!userData) return;
        }
        onAddPanel(null);
        setAnswer(null);
        setAddAnswer(true);
    };

    const isFavorite = () => user?.themesStates?.[panelData.id] === 'studied';

    const handleSetAnswer = (task) => {
        setAddAnswer(null);
        setAnswer(task);
    };

    const handleCloseAnswerPanel = () => {
        setAnswer(null);
    };

    const handleCloseAddAnswerPanel = () => {
        setAddAnswer(null);
    };

    const handleFavoriteDublicate = async () => {
        onSetThemeData({
            id: panelData.id,
            themeFavorite: !themeFavorite,
        });
        setThemeFavorite(!themeFavorite);
    };

    useEffect(() => {
        if (isCloseAdditionalPanel) {
            setAddAnswer(false);
        }
    }, [isCloseAdditionalPanel]);

    useEffect(() => {
        if (themeData.id === panelData.id) {
            setThemeFavorite(themeData.themeFavorite);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeData]);

    useEffect(() => {
        setThemeFavorite(isFavorite());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelData.id]);

    const blockClassName = classNames('panel-block__item', {
        'panel-block__item_collapsed': hasExtraClass,
    });

    const buttonClass = classNames('inline-el panel-block__item__btn', {
        'panel-block__item__btn_favorite': themeFavorite,
    });

    const panelWrapperClass = classNames('panel-block__container', {
        'gray-bg': themeFavorite,
    });

    return (
        <>
            <div className={panelWrapperClass}>
                <div className={blockClassName}>
                    <div className='panel-block__item__header'>
                        <div className='panel-block__item__description'>
                            <Button
                                type='text'
                                className={buttonClass}
                                onClick={handleFavoriteDublicate}
                            >
                                <Svg
                                    name=':icon/check'
                                    className='icon_check icon_24'
                                />
                                <span style={{marginLeft: '12px'}}>
                                    {translate('btn_learned')}
                                </span>
                            </Button>
                        </div>

                        <div className='panel-block__item__action-items'>
                            <Button
                                type='text'
                                onClick={onToogleClass}
                                className='inline-el panel-block__item__btn'
                            >
                                <Svg name=':icon/expand'  className='icon_24' />
                            </Button>

                            <Button
                                type='text'
                                onClick={onRemovePanel}
                                className='inline-el panel-block__item__btn'
                            >
                                <Svg name=':icon/cross' className='icon_24' />
                            </Button>
                        </div>
                    </div>
                    <div className='panel-block__item__title'>
                        <h1>{get(panelData, 'themeName')}</h1>
                        <div className='panel-block__item__description'>
                            <p>{get(panelData, 'themeDescription')}</p>
                        </div>
                    </div>

                    <TasksListContainer
                        {...other}
                        classNames='wrapper_list wrapper_left-margin'
                        onSetAnswer={handleSetAnswer}
                        currentTheme={panelData}
                        hideThemeButtons={true}
                        handleAddAnswer={handleAddAnswer}
                        tellMeIfEmpty={(e) => setEmpty(e)}
                    />
                    {!isEmpty(themesList) && (
                        <div className='linked-cards wrapper_outer-section '>
                            <h3> {translate('linked_themes')}</h3>
                            <ul className='wrapper_list'>
                                {map(themesList, (item, key) => {
                                    return (
                                        <li
                                            key={key}
                                            className='theme-card'
                                            onClick={() => onAddPanel(item.id)}
                                        >
                                            <p className='theme-card__title'>
                                                {item.name}
                                            </p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>

                {!addAnswer && (
                    <div className='wrapper-fixed'>
                        <AddTaskButtonComponent onAddAnswer={handleAddAnswer}
                                                  hasExtraClass={hasExtraClass}/>
                    </div>
                )}
            </div>
            {addAnswer && (
                <PanelContainer
                    {...other}
                    as={PanelEditTaskContainer}
                    blockClassName='panel-block__container'
                    skipPanelData={true}
                    theme={{
                        themeName: get(panelData, 'themeName'),
                        key: get(panelData, 'id'),
                    }}
                    onSetAddAnswer={setAddAnswer}
                    empty={empty}
                    onClose={handleCloseAddAnswerPanel}/>
            )}
            {task && (
                <PanelContainer
                    {...other}
                    as={PanelAnswerComponent}
                    blockClassName='panel-block__container'
                    skipPanelData={true}
                    panelId={task.id}
                    task={task}
                    onClose={handleCloseAnswerPanel}/>
            )}
        </>
    );
};
