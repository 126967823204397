
import './scss/index.scss';
import React, { useRef, useState } from 'react';
import { Input, Modal } from 'antd';
import { useIntl } from 'react-intl';

export const AddVimeoFolder = ({
    onAddSource,
    index,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const inputRef = useRef();
    const [showModal, setShowModal] = useState(true);
    const vimeoFolder = window.localStorage.getItem('vimeo_folder');

    const handleSetVimeo = (vimeoFolder) => {
        window.localStorage.setItem('vimeo_folder', vimeoFolder);
    };
    const onSave =(val) => {
        let object = { key: "vimeo_folder", type: 'GIF', source: val || '', title: `My Vimeo: ${val}`, icon: "vimeo" }
        onAddSource(object, index);
        setShowModal(false)
    }

    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: 'vimeo_add_desc' })}
                onCancel={() =>  onSave('')}
                onOk={() => {
                    const val = inputRef.current.input.value;
                    if (val !== vimeoFolder) { handleSetVimeo(val); }
                    onSave(val)
                }}
            >
                <div className="vimeo-folder-component__top">
                    <div className="vimeo-folder-component__top__input">
                        <Input defaultValue={vimeoFolder}
                         ref={inputRef}
                            placeholder="https://vimeo.com/user/123456789/folder/13545174"
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};
