import DevComponent from "../Dev.component/Dev.component";
import firebase from "../../../../model/firebase";
import {useEffect, useState} from "react";

export default function DevContainer() {
    const [token, setToken] = useState("");

    async function handleGetToken() {
        const token = await firebase.auth().currentUser.getIdToken();
        setToken(token);
    }

    // const getData = (data) => {
    //     const {times, ...d} = data;
    //     const threeHours = 1000 * 60 *3;
    //     if (!times) return data;
    //
    //     const newTimes = times.map(t => {
    //         const {dateUnix, type, date_end} = t;
    //         if (date_end) return t;
    //
    //         const dEnd = new Date(dateUnix || t);
    //         return {
    //             date_start: !dateUnix || type === 'repetitive' ? new Date(dEnd.setMonth(dEnd.getMonth() - 1)).valueOf() : dateUnix - threeHours * 16,
    //             date_end: (dateUnix || t) + threeHours
    //         }
    //     });
    //     return {...d, times: newTimes};
    // };

    useEffect(() => {
        (async () => await handleGetToken())();
    }, []);

    return (
        <DevComponent
            token={token}
            getToken={handleGetToken}
        />
    );
}