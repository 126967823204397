import { useCallback } from 'react';
import { useAiAssistant } from '../graphQL/useAiAssistant';

export const useGetAssistant = () => {
    const { getAssistantResponse } = useAiAssistant();
    const onAssistantRequest = useCallback(
        async (topic, assistantId) => {
            const settings = {
                args: JSON.stringify({ topic }),
                type: 'graph',
                assistantId: assistantId,
            };

            const resp = await getAssistantResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = resp?.data
                ? JSON.parse(resp?.data?.requestAssistantApi)
                : null;
            return responseJSON;
        },
        [getAssistantResponse]
    );

    return { onAssistantRequest };
};
