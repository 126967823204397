import React from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

const PROD_UPLOAD_URL = 'https://back.unschooler.me/pdf/upload';
// const DEV_UPLOAD_URL = 'http://localhost:4000/pdf/upload';

export const UploadPdfComponent = (props) => {
    const { onLoadFile, setLoading } = props;
    const { formatMessage } = useIntl();

    const onChange = ({ fileList }) => {
        const { status, response } = fileList?.[0] || {};
      
        if (status === 'done') {
            onLoadFile(response, fileList);
            // onLoadFile(fs, fileList);
            if (setLoading) {
                setLoading(false);
            }
        } else {
            if (setLoading) {
                setLoading(true);
            }
        }
    };

    return (
        <Upload
            action={PROD_UPLOAD_URL}
            accept="application/pdf"
            showUploadList={false}
            onChange={onChange}>
            <Button type="secondary" icon={<UploadOutlined />}>
                {formatMessage({ id: 'click_upload' })}
            </Button>
        </Upload>
    )
}