import './scss/index.scss';

import React from 'react';

export const ErrorComponent = (props) => {
    const {
        cssClass = 'error',
        message = 'Что то пошло не так'
    } = props;

    return (
        <div className={cssClass}>
            {message}
        </div>
    );
}
