import React from 'react';
import {useIntl} from 'react-intl';
import {Modal, Form, Input, Button} from 'antd';
import {LoaderComponent} from '../common';
import {useEditUserCertificate} from './hook';

export const EditCertificateComponent = (props) => {
    const {editCert, setEditCert} = props;
    const {editUserCertificate, loading, certificateId} = useEditUserCertificate();
    const {formatMessage} = useIntl();
    const [form] = Form.useForm();

    const handleFinish = async (data) => {
        await editUserCertificate({id: editCert.id, ...data}, certificateId);
        setEditCert(false);
    }

    return <Modal visible={!!editCert}
                  onCancel={() => setEditCert(false)}
                  header={null}
                  footer={null}>
        {editCert && (
            <Form
                initialValues={{
                    userName: editCert.userName
                }}
                form={form}
                onFinish={handleFinish}
                layout="vertical">
                <Form.Item name="userName"
                           label={formatMessage({id: 'form_label_user_name'})}>
                    <Input/>
                </Form.Item>
                <Form.Item>
                    <Button type='primary'
                            disabled={loading}
                            htmlType='submit'>
                        {loading && (
                            <LoaderComponent type="inline"/>
                        )}
                        <span>
                            {formatMessage({ id: 'save_button' })}
                        </span>
                    </Button>
                </Form.Item>
            </Form>
        )}
    </Modal>
}