import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom'
import { LoaderComponent } from '../../common';
import { useIntl } from 'react-intl';

export const PuppeteerComponent = ({ isLoading, onGenerate, videolinks, modeFile, error }) => {
    const { formatMessage } = useIntl();
    return (
        <>
            {error && (
                <div className="error">
                    {formatMessage({id: 'generating_video_error'})}
                </div>
            )}
            {!!videolinks?.length &&
                <div className='wrapper_content-section'>
                    {videolinks?.map(videolink => {
                        return (
                            <a href={videolink.url}
                                key={videolink.name}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link-btn-tertiary ant-btn ant-btn-tertiary ant-btn-sm block-el wrapper_inner-section"
                            >
                                <span>
                                    {isLoading && <LoaderComponent type="inline" />}
                                    {formatMessage({id: 'download_button'})}{videolink.name}
                                </span>
                            </a>
                        )

                    })}
                    <hr />
                </div>
            }

            <Button type="text"
                disabled={isLoading || !modeFile}
                onClick={onGenerate}
                size='small'
            >
                <span>
                    {isLoading && <LoaderComponent type="inline" />}
                    Save as mp4 {modeFile}
                </span>
            </Button>
            {isLoading &&
                <p>
                    {formatMessage({id: 'continuation_waiting_info'})}
                </p>
            }



        </>
    )


}
