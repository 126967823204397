import React, { useMemo, useContext, useState } from 'react';
import classNames from 'classnames';

import { TaskClosed, TaskLink } from './index';
import { FirebaseContext } from '../../context/firebase/firebaseContext';

export const TaskWrapperComponent = (props) => {
    const {
        actionHidden = true,
        type = 'link',
        task,
        viewer,
        activeId,
        isMentorOrAdmin,
        ...other
    } = props;
    const { user } = useContext(FirebaseContext);

    const isDone = task?.stateResult === 'CORRECT';
    const isNotDone = task?.stateResult === 'INCORRECT';
    const isLink = task?.isAvailable || isMentorOrAdmin ||  type === 'link';
    const taskUrl = useMemo(() => {
        const { id, alias } = task;
        const aliasPart = alias ? `${alias}-` : '';
        return aliasPart + id;
    }, [task]);

    const TaskContainer = classNames('Answer_container  ', {
        "card_done": isDone || !isLink,
        "card_not_done": isNotDone,
        'card-promo': isLink,
        'card-active': activeId && (activeId === task?.id || task?.questions?.find(q => q.id === activeId)),
    });
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleQuestions = () => {
        
        setIsExpanded(!isExpanded);
    };


    return (
        <div className={`answer-component ${TaskContainer}`}>
            {isLink && (
                <TaskLink
                    task={task}
                    actionHidden={actionHidden}
                    user={user}
                    viewer={viewer}
                    taskUrl={taskUrl}
                    toggleQuestions={toggleQuestions}
                    isExpanded={isExpanded}
                    {...other}
                />
            )}
            {(!isLink || type === 'closed') &&
                <TaskClosed
                    task={task}
                    actionHidden={actionHidden}
                    user={user}
                    viewer={viewer}
                    type={type}
                    toggleQuestions={toggleQuestions}
                    isExpanded={isExpanded}
                    {...other}
                />
            }
        </div>
    );
};
