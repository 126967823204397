import {useMutation, gql} from '@apollo/client';

export const useUpdateToken = () => {
    const [updateToken] = useMutation(UPDATE_REFRESH_TOREN);

    return updateToken
}

const UPDATE_REFRESH_TOREN = gql`
    mutation UpdateRefreshToken($code: String!){
        updateRefreshToken(code: $code) 
    }
`;