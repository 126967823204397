import "./scss/index.scss";
import React from "react";
import { useIntl } from "react-intl";
import { Svg } from "../../common";
import { IncludesWorkspaceContainer } from '../../enroll/IncludesWorkspace.container/IncludesWorkspace.container';
import { StripeWorkspaceEnrollContainer } from '../../stripe';

export const EnrollWorkspaceComponent = (props) => {
    const {
        events,
        user,
        workspace,
        onLogin,
        ...other
    } = props;
    const { stripeProductId } = workspace;
    const { formatMessage } = useIntl();

    if (!stripeProductId) {
        return null;
    }

    return (
        <div className="course-schedule ">
            <div className="all-access-header">
                <h2> {formatMessage({ id: 'all_access_subscription' })} </h2>
                <Svg className="star_btn" name=':icon/star_1' fill={30} />
                <Svg className="star_btn" name=':icon/star_2' fill={30} />
                <Svg className="star_btn" name=':icon/star_3' fill={10} />
                <Svg className="star_btn" name=':icon/star_1' fill={50} />
                <Svg className="star_btn" name=':icon/star_4' fill={30} />
                {/* <p>custom short description</p> */}
                <StripeWorkspaceEnrollContainer onLogin={onLogin} />
                <IncludesWorkspaceContainer
                    events={events}
                    user={user}
                    workspace={workspace}
                    {...other}
                />
            </div>
        </div>
    );
};
