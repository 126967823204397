import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { HeaderProfileContainer } from '../headers';
import { ProfileColumn } from './ProfileColumn/ProfileColumn';
import { MetaComponent, useIsAllow } from '../common';
import { useGetResults } from "../result/hooks/useGetResults";
import { Loader } from '../Loader';
import { EventsListContainer } from '../events/EventsList.container';
import { SkillsTreeContainer } from '../skillsTree';
import { ProfileTitleComponent } from './ProfileTitle.component';
import { useGetUser } from '../common/graphQL';
import { useGetSkillsNew } from '../skillsTree/hook/useGetSkillsNew';

export const Profile = (props) => {
    const { viewer, id, localePrefix, onCheckUrl, workspace, ...other } = props;
    const { formatMessage } = useIntl();
    const { getUser, loading: loadingGetUser, user } = useGetUser();
    const { skills, getSkillsNew } = useGetSkillsNew();
    // const [spec, setSpec] = useState(skills);
    const [skillsBySubject, setSkillsBySubject] = useState(skills);
    // const [skillsByUser, setSkillsByUser] = useState(skills);
    // const [fetchedSkills, setFetchedSkills] = useState(skills);
    const [myEvents, setMyEvents] = useState();
    const { results, getResults } = useGetResults();

    const useIsAllowd = useIsAllow();
    const createCourses = useIsAllowd("PREMIUM", workspace);

    const demoSkills =
        [
            {
                id: 46,
                alias: "motion-design",
                subjectUid: "",
                title: "Motion Design",
                color: "#F6C000",
                icon: "default",
                tags: [
                    {
                        id: 3560,
                        alias: null,
                        title: "After Effects",
                        icon: null,
                        defIcon: null,
                        countResults: 198,
                        subject: {
                            id: 46
                        }
                    },
                    {
                        id: 1522,
                        alias: null,
                        title: "After Effect",
                        icon: null,
                        defIcon: null,
                        countResults: 36,
                        subject: {
                            id: 46
                        }
                    },],
                countResults: 2606
            }
        ]

    const getSkillsBySubject = () => {
        let newSkills = demoSkills.filter(
            (subjectObject) => subjectObject.count > 0
        );
        newSkills && setSkillsBySubject(newSkills);
    };

    // const getSkillsByUser = () => {
    //     let newSkills = [];

    //     fetchedSkills.map((subjectObject) => {
    //         if (subjectObject.count > 0) {
    //             let newSubject = {...subjectObject};
    //             newSubject.tags = newSubject.tags.filter((el) => {
    //                 return el.count > 0;
    //             });

    //             newSkills.push(newSubject);
    //         }
    //     });
    //     newSkills && setSkillsByUser(newSkills);
    // };

    // const fetchAllSkills = async () => {
    //     const allSkills = await getSkillsNew({
    //         variables: {
    //             userId: Number(user.id),
    //         },
    //     });

    //     allSkills?.data && setFetchedSkills(allSkills.data.skillsNew);
    // };

    const waitProfile = useCallback(async () => {
        if (id) {
            await getUser(id);
        }
    }, [id]);


    useEffect(() => {
        // fetchedSkills && spec && getSkillsByUser();
        getSkillsBySubject();
    }, []);

    useEffect(() => {
        if (user?.id && onCheckUrl(user, `${localePrefix}/profile/`)) {
            // fetchAllSkills().then();
            (async () => await getResults({
                variables: {filter: {userId: user?.id}},
                fetchPolicy: 'network-only'
            }))();
        }
    }, [user?.id, id]);

    useEffect(() => {
        if (user?.events) {
            let ev = user?.events.filter((ev) => ev.creator.id === user.id);
            setMyEvents(ev);
        }
    }, [user?.events]);

    useEffect(() => {
        (async () => await waitProfile())();
    }, [id]);

    const metaMemo = useMemo(() => {
        let title = 'User profile online learner and mentor on Unschooler';
        let description = `Profile page shows passion and unlocked interests. Get you personal careers advice and unlock new skills`;

        if (user) {
            const { name } = user;

            title = name + ' / online learner and mentor on Unschooler';
            description = `${name}`;
        }

        const meta = {
            meta_title: title,
            meta_description: description,
        };
        const og = {
            title: title,
            description: description,
        };

        const twitter = {
            tw_title: title,
            tw_description: description,
        };
        return { meta, og, twitter };
    }, [user]);

    if ((loadingGetUser) || !metaMemo || !user) {
        return <Loader />;
    }

    return (
        <>
            <MetaComponent
                meta={metaMemo.meta}
                og={metaMemo.og}
                twitter={metaMemo.twitter}
            />

            <SkillsTreeContainer
                user={user}
                skills={demoSkills}
                {...other}
            />
            <div className=' '>
                <div className='page flex-container'>
                    {user?.id && (
                        <>
                            <div className='flex-left-column'>
                                <ProfileTitleComponent
                                    user={user}
                                />

                                {/* {skillsBySubject?.length ? (
                                    <SkillsTreeContainer
                                        user={user}
                                        skills={skillsBySubject}
                                        {...other}
                                    />
                                ) : null} */}
                                {/* {skillsBySpec?.length ? (
                                    <div className='wrapper_skills-map'>
                                        <ReportComponent
                                            skillsBySpec={skillsBySpec}
                                            skillsBySubject={skillsBySubject}
                                            spec={spec}
                                            id={id}
                                        />
                                    </div>
                                ) : null} */}
                                {user?.id && user?.id !== 10 &&
                                    <EventsListContainer
                                        user={user}
                                        viewer={viewer}
                                        description={user.description}
                                        className='events-list-component'
                                        activeTab={formatMessage({ id: "tab_events_user" })}
                                        size={20}
                                        createCourses={createCourses}
                                    />
                                }
                            </div>

                            <div className='flex-right-column'>
                                <ProfileColumn
                                    user={user}
                                    isAdmin={createCourses}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
