import '../Components/admin/scss/index.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { useLocalePrefix } from '../Components/lang';
import {FirebaseContext} from '../context/firebase/firebaseContext';

export const Admin = () => {
    const [localePrefix] = useLocalePrefix();

    return (
        <main className={`admin-section`}>
            <nav className='AdminNavigation'>
                <h3>Добавление и редактирование тем</h3>
                <ul className='NavList'>
                    <li>
                        <Link
                            className='nav-link'
                            to={`${localePrefix}/admin/config`}
                        >
                            Общие настройки
                        </Link>
                    </li>
                    <li>
                        <Link
                            className='nav-link'
                            to={`${localePrefix}/admin/dev`}
                        >
                            for Developers
                        </Link>
                    </li>
              
                    <li>
                        <Link
                            className='nav-link'
                            to={`${localePrefix}/admin/guadline`}
                        >
                            Guideline
                        </Link>
                    </li>
                    <li>
                        <Link
                            className='nav-link'
                            to={`${localePrefix}/admin/tasks-by-order`}
                        >
                            Tasks by order
                        </Link>
                    </li>
                    <li>
                        <Link
                            className='nav-link'
                            to={`${localePrefix}/admin/workspaces`}
                        >
                            Workspaces
                        </Link>
                    </li>
                    {/* <li><Link className="nav-link" to={`${localePrefix}/admin/theme/edit`}>Редактирование темы</Link></li> */}
                </ul>
            </nav>
        </main>
    );
};
