// import {fetchData} from '../../../context/firebase/firebaseRequests';
import {getCacheManager} from './getCacheManager';

const PAYMENT_INFORMATION = 'paymentInformation';
const {hasCache, getCache, setCache} = getCacheManager();

export const getPaymentType = async (id) => {
    const cacheKey = PAYMENT_INFORMATION + '-' + id;
    const isCache = hasCache(cacheKey);

    // if (!isCache) {
    //     const dbData = await fetchData(`${PAYMENT_INFORMATION}/${id}/type`);
    //     setCache(cacheKey, dbData.val());
    // }
    return getCache(cacheKey);
}