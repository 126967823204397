import React from 'react';
import {useParams} from 'react-router-dom';
import {CommunityContainer} from '../Components/community';
import {TaskArticlePage} from './TaskArticlePage';

export const Community = (props) => {
    const {match, location, ...other} = props;
    const {by} = useParams();
    const isNotTask = by === 'subject' || by === 'tag';
    const alias = isNotTask ? '' : by.split(' ').join('-').toLowerCase().trim();

    return (
        <>
            {isNotTask && (
                <CommunityContainer
                    {...other}
                    subject={location?.state?.subject}
                    tag={location?.state?.tag}
                />
            )}
            {!isNotTask && (
                <TaskArticlePage {...other}
                                 match={match}
                                 path={`tutorials/${alias}`}
                                 isProfMode={true}/>
            )}
        </>
    );
};
