import React, {useRef} from 'react';
import {useIntl} from 'react-intl';
import {Form, Input, Button} from 'antd';
import {LoaderComponent} from '../../common';

export const PaypalAccountComponent = (props) => {
    const {
        isCreateMode = false,
        createAccountLoading = false,
        getAccountLoading = false,
        removeAccountLoading = false,
        isMultipleConnection = false,
        paypalAccountId,
        onAddAccount,
        onRemoveAccount
    } = props;
    const {formatMessage} = useIntl();
    const accRef = useRef();
    const secretRef = useRef();

    if (getAccountLoading) {
        return <LoaderComponent type="block"/>;
    }

    return (
        <div className="paypal-account-component">
            <h3>{formatMessage({id: 'paypal_api_credentials'})}</h3>
            <Form.Item label={formatMessage({id: 'paypal_account_id'})}
                       style={{maxWidth: '600px'}}>
                {isCreateMode && (
                    <Input disabled={!isCreateMode}
                           ref={accRef}/>
                )}
                {!isCreateMode && (
                    <Input disabled={!isCreateMode}
                           value={paypalAccountId}
                           ref={accRef}/>
                )}
            </Form.Item>
            <Form.Item label={formatMessage({id: 'paypal_account_secret'})}
                       style={{maxWidth: '600px'}}>
                <Input disabled={!isCreateMode}
                       ref={secretRef}
                       defaultValue={isCreateMode ? '' : '************'}/>
            </Form.Item>
            {isCreateMode && (
                <Button type="secondary"
                        onClick={() => onAddAccount(accRef.current.input.value, secretRef.current.input.value)}
                        disabled={createAccountLoading}>
                    {createAccountLoading && <LoaderComponent type="inline"/>}
                    <span>
                        {formatMessage({id: 'paypal_add_credentials'})}
                    </span>
                </Button>
            )}
            {!isCreateMode && (
                <div>
                    <Button type="secondary"
                            onClick={onRemoveAccount}
                            disabled={removeAccountLoading}>
                        {removeAccountLoading && <LoaderComponent type="inline"/>}
                        <span>
                            {isMultipleConnection
                                ? formatMessage({id: 'paypal_disconnect_account'})
                                : formatMessage({id: 'paypal_remove_account'})
                            }
                        </span>
                    </Button>
                    {isMultipleConnection && (
                        <div className="mt-m-l">
                            {formatMessage({id: 'paypal_description'})}
                        </div>
                    )}
                </div>
            )}
            <div className="mt-m-l">
                {formatMessage({id: 'paypal_note'})}
            </div>
        </div>
    )
}