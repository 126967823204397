import {UpdateResult} from '../graphQL/mutations/UpdateResult';
import {getResult} from '../graphQL/queries/GetResult';
import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {GetTaskSimple} from '../../task/graphQL/queries/GetTaskSimple';
import {GetEventForThread} from '../../event/graphQL/queries/GetEventForThread';

export function useUpdateResult(variables, mutation = UpdateResult) {
    const [updateResultMutation, { data, loading, error }] = useMutation(mutation);

    const updateResult = useCallback(async (data, eventData) => {
        const taskId = data?.variables?.resultData?.taskId;
        const updates = [
            {query: GetTaskSimple, variables: {taskId}},
            {query: getResult, variables: {taskId}}
        ];

        const {id, workspaceId} = eventData || {};

        if (id) {
            updates.push({
                query: GetEventForThread,
                variables: {
                    id,
                    ...(workspaceId && {workspaceId})
                }
            })
        }

        const resp = await updateResultMutation({
            ...data,
            refetchQueries: () => updates
        });
        return resp;
    }, [updateResultMutation]);

    return {
        updateResult,
        result: data?.updateResult,
        loading, error
    }
}
