import React from "react";
// import {CircularProgressbar, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

export const ProgramsSubjectHeaderComponent = (props) => {
    const {subject,
        //  progress = 0, 
        //  ...other
        } = props;

    return (
        <div className="program-subject__item program-subject__item_header">
            <h2 className="program-subject__title">
                {subject?.title}
            </h2>
            {/* <div className="progress-bar">
                <CircularProgressbar strokeWidth="20"
                                     value={progress}
                                     styles={buildStyles({
                                         pathColor: '#FED230',
                                         trailColor: '#F1F1F1'
                                     })}/>
            </div> */}
        </div>
    );
};
