import React, {useContext} from 'react';
import {EmailVerificationComponent} from '../EmailVerification.component';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {useConfirmEmail} from '../hooks';

export const EmailVerificationContainer = ({showModal, setShowModal, messageId = 'verify_email_info'}) => {
    const {user} = useContext(FirebaseContext);
    const {confirmEmail} = useConfirmEmail();
    const {email} = user;

    const handleCancel = () => setShowModal(false);

    const handleConfirm = async () => {
        await confirmEmail();
        setShowModal(false);
    };

    return <EmailVerificationComponent email={email}
                                       messageId={messageId}
                                       showModal={showModal}
                                       onConfirm={handleConfirm}
                                       onCancel={handleCancel}/>
}