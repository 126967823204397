import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { getType, getTitle, } from '../../common';
import { useLocalePrefix } from '../../lang';

export const SuggestionCardComponent = ({ suggestion, event }) => {
  const [localePrefix] = useLocalePrefix();

  const task = event?.tasks?.find(task => task?.questions?.some(question => question?.id === suggestion?.id));

  return (
    <Card
      bordered={false}
      hoverable
    >

      <h4>
        💡
        {suggestion.suggestion}</h4>
      <p className='text_secondary'>Problem: {suggestion.problem}</p>
      <p>
        <span className='margin_right'>
          {getType(suggestion?.type)}
        </span>

        <Link 
                to={{
                  pathname: `${localePrefix}/create-event/${event?.id}`,
                  state: {
                      taskProp: task,
                      isEdit: true,
                      questionPropId: suggestion?.id,
                      tabProp: 1,
                  },
              }}
        
        
        
        // to={`${localePrefix}/tutorials/${task?.id}?step=${suggestion?.id}`}
        >  {getTitle(suggestion?.title?.replace(/#/g, ''))}</Link>
      </p>

    </Card>
  );
};