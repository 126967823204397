import {useContext, useMemo} from 'react';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {useGetLangs} from '../../common/graphQL';

export const useLangId = () => {
    const {locale} = useContext(FirebaseContext);
    const {langs} = useGetLangs();
    return useMemo(() => {
        const currentLang = langs?.find(l => l?.code === locale);
        return currentLang?.id || 2;
    }, [locale, langs]);
}
