import React, { useState } from 'react';
import { Button, Modal, Form } from 'antd';
import { useIntl } from 'react-intl';

import { Svg } from '../../common';
import { useGetTags } from '../../common/hooks';
import { useEditEvent } from '../../event/hooks/useEditEvent';
import { PublishingTabComponent } from '../../event/CreateEvent.component/PublishingTab.component';
import { getPreparedPriceForm } from '../../event/util/getPreparedPriceForm';

export const EventsBatchEditContainer = (props) => {
    const { events, reloadThePage } = props;
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const { getFieldValue, setFieldsValue } = form;

    const { editEvent } = useEditEvent();
    const [costLocal, setCostLocal] = useState();
    const [userCostLocal, setUserCostLocal] = useState();
    const getTags = useGetTags();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formValues, setFormValues] = useState({});
    const onValuesChange = (changedValues, allValues) => {
        setFormValues(allValues);
    };

    const showModal = () => {
        setIsModalVisible(true);
        form.resetFields();
        setFormValues({});
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
        setFormValues({});
    };

    const handleFinish = async (values) => {
        let data = {};

        for (const [key, value] of Object.entries(values)) {
            if (value !== null && value !== undefined) {
                data[key] = value;
            }
        }
            
            if (Object.keys(data).length > 0) {
                for (const event of events) {
                let preparedData =  getPreparedPriceForm(data, costLocal, userCostLocal, event)
                // const convertedTagIds = await getTags(values?.tagIds || event?.tagIds);
                try {
                    await editEvent({
                        variables: {
                            eventId: event.id,
                            eventData: preparedData,
                            isOwner: true
                        },
                    })

                } catch (e) {
                    console.warn(e);
                }
            }
        }

        handleCancel();
        reloadThePage();
    };

    return (
        <>
            <Button
                className='inline-el margin-right'
                type='secondary'
                size="small"
                onClick={showModal}
            >
                <Svg
                    fill='#ff5f49'
                    name=':icon/edit'
                    className='btn-icon icon_16'
                />
                Batch Edit
            </Button>
            <Modal
                title={formatMessage({ id: "batch_edit_events" })}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {formatMessage({ id: "cancel_label" })}
                    </Button>,
                    <Button key="confirm" type="primary" onClick={() => form.submit()}>
                        OK
                    </Button>
                ]}
            >
                <Form
                    form={form}
                    onFinish={handleFinish}
                    initialValues={{}}
                    onValuesChange={onValuesChange}
                >
                    <PublishingTabComponent
                        isAdmin={true}
                        eventState={{}}
                        batchEdit={true}
                        userCostLocal={userCostLocal}
                        costLocal={costLocal}

                        // onRemove={onRemove}
                        // currencyCode={currencyCode}
                        // isPaymentDisabled={isPaymentDisabled}
                        // setActivePaymentTab={setActivePaymentTab}
                        // activePaymentTab={activePaymentTab}
                        // setStripeProductId={setStripeProductId}
                        // stripeProductId={stripeProductId}
                        // clearStripeData={clearStripeData}
                        // stripeData={stripeData}
                        formValues={formValues}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        setCostLocal={setCostLocal}
                        setUserCostLocal={setUserCostLocal}
                        {...props}
                    />
                </Form>

            </Modal >
        </>
    );
};