import './scss/index.scss';
import React from 'react';
import { Button } from 'antd';
import { find } from 'lodash';
import { useIntl } from 'react-intl';

import { ProfessionSkills } from '../ProfessionSkills/ProfessionSkills';
import { useIsAllow } from '../../common';

export const FullListProfessionsComponent = ({
    professionsList,
    prof,
    skillsProf,
    spec,
    handleSaveUser,
    handleSetProfId,
    isParticipant,
    handleShowAll,
    limit = 10,
    selectedProfId,
    taskData,
    user,
    ...other
}) => {

    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd('START');
    const { formatMessage } = useIntl();
    const stringShowButton =
        `${isAllow
            ? formatMessage({
                id: 'career_btn_show',
            })
            : formatMessage({
                id: 'career_btn_upgrade',
            })
        }` +
        formatMessage({
            id: 'career_btn_all',
        });

    return (
        <div>
            <div>
                {professionsList?.map((profs, key) => (
                    <span key={profs?.id}>
                        <span className='wrapper_link'>
                            <span
                                className={
                                    prof?.id === profs?.id
                                        ? ' inline-el tab-item_active'
                                        : ' inline-el'
                                }
                            >
                                <Button
                                    type='text'
                                    onClick={() => handleSetProfId(profs)}
                                    size='large'
                                >
                                    {Math.floor(
                                        profs.count / (profs.tags.length / 100)
                                    )}
                                    %{profs.title}
                                </Button>
                            </span>
                        </span>
                        {key == 0 && (
                            <span
                                className='label'
                                style={{
                                    backgroundColor: spec.color,
                                }}
                            >
                                BEST MATCH
                            </span>
                        )}
                        {selectedProfId === professionsList[key]?.id && (
                            <span
                                className='label'
                                style={{
                                    backgroundColor:
                                        professionsList[key]?.color,
                                }}
                            >
                                SELECTED{' '}
                            </span>
                        )}
                        ,{'  '}
                    </span>
                ))}
                <div className='show-btn'>
                    <Button
                        type='secondary'
                        onClick={() => handleShowAll()}
                        block
                    >
                        {professionsList?.length > limit
                            ? 'hide careers ↑'
                            : stringShowButton}
                    </Button>
                </div>
            </div>

            {find(professionsList, ['id', prof?.id]) ? (
                <div
                    className='report_overlay'
                    style={{ opacity: ` 3 solid ${spec?.color}` }}
                >
                    <ProfessionSkills
                        skillsProf={skillsProf}
                        spec={spec}
                        prof={prof}
                        fullProf={prof}
                        handleSaveUser={handleSaveUser}
                        handleSetProfId={handleSetProfId}
                        user={user}
                        {...other}
                    />
                </div>
            ) : null}
        </div>
    );
};
