import {useCallback} from 'react';
import {useMutation} from '@apollo/client';
import {SendResendEmail} from '../graphQL/mutations/SendResendEmail';
import { useFromEmail } from './useFromEmail';

export const useSendEmail = (mutation = SendResendEmail) => {
    const [sendResendEmail, { data, error, loading }] = useMutation(mutation);
    const { from } = useFromEmail();
    const sendEmail = useCallback(async (to, html, subject) => {
        const toEmail = typeof to === 'string' ? [to] : to;

        
        const resp = await sendResendEmail({
            variables: {
                input: {
                    from,
                    to: toEmail,
                    html,
                    subject
                }
            }
        });
        return resp?.data?.sendResendEmail;
    }, [ from])

    return {
        data: data?.sendResendEmail,
        error, loading,
        sendEmail
    };
}