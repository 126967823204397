import {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {DeleteAudioNarration} from '../graphQL/mutations/DeleteAudioNarration';
import {GetTask} from '../../task/graphQL/queries/GetTask';

export const useDeleteAudioNarration = (variables, mutation = DeleteAudioNarration) => {
    const [deleteAudioNarrationFn, { data, loading, error }] = useMutation(mutation);
    const {pathname} = useLocation();

    const deleteAudioNarration = useCallback(async (data) => {
        const taskId = parseInt(pathname.replace(/[^0-9]/g, ''));

        const resp = await deleteAudioNarrationFn({
            ...data,
            refetchQueries: () => [{query: GetTask, variables: {taskId}}]
        });
        return resp;
    }, [deleteAudioNarrationFn]);

    return {
        deleteAudioNarration,
        data,
        loading,
        error
    }
}
