import './scss/index.scss';

import React, { useState } from 'react';
import { Button } from 'antd';
import { useTrackClick, LoginContainer } from '..';
import { useIntl } from 'react-intl';

export const SignInComponent = ({ type = 'secondary', title, block, size, ...other }) => {
    const trackClick = useTrackClick();
    const [showLogin, setShowLogin] = useState();
    const { formatMessage } = useIntl();

    const handleLogin = () => {
        trackClick('click-login', 'header');
        setShowLogin(true);
    };
    let titleString = title || formatMessage({ id: 'auth_button_short' });

    return (
        <>
            <Button onClick={handleLogin} type={type} block={block} size={size}>
                {titleString}
            </Button>
            <LoginContainer
                showLogin={showLogin}
                setCancelLogin={setShowLogin}
                {...other}
            />
        </>
    );
};
