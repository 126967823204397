import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const ThemeSwitcherAutoComponent = () => {
    const history = useHistory();
    const queryParams = new URLSearchParams(history.location.search);
    const lightTheme = queryParams.get('lightTheme');
    const isLightTheme = lightTheme !== null && lightTheme !== undefined;

    const handleChange = (val) => {
        const rootElement = document.getElementsByTagName('html')[0];
        if (!val) {
            rootElement.classList.remove('dark-mode');
            window.localStorage.setItem('isWhiteMode', true);
        } else {
            rootElement.classList.add('dark-mode');
            window.localStorage.removeItem('isWhiteMode');
        }
    };

    useEffect(() => {
        if (isLightTheme) {
            handleChange(false)
        }
    }, [isLightTheme])

    return (
        <></>

    );
};
