import {useMutation} from '@apollo/client';
import {EditCertificate} from '../graphQL/mutations/EditCertificate';

export const useEditCertificate = (mutation = EditCertificate) => {
    const [editCertificate, { data, error, loading }] = useMutation(mutation);

    return {
        editCertificate,
        data,
        error,
        loading
    };
}