import {gql} from "@apollo/client";

export const EditWorkspaceMember = gql`
    mutation EditWorkspaceMember($id: Int!, $permission: MemberType!) {
        editWorkspaceMember(id: $id, permission: $permission) {
            id,
            workspaceId,
            permission,
            userId
        } 
    }
`;
