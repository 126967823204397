import { useCallback } from 'react';
import {gql, useLazyQuery} from '@apollo/client';

export function useGetPreference(options = {}, query = GET_PREFERENCE) {
    const [queryPreference, {data, loading, error}] = useLazyQuery(query, options);

    const getPreference = useCallback(async (id) => {

        const result = await queryPreference({
            variables: {
                id
            }
        });
        return result.data?.preference;
    }, [queryPreference]);

    return {
        getPreference,
        loading,
        error,
        preference: data?.preference
    };
}

const GET_PREFERENCE = gql`
    query GetPreference($id: Int) {
        preference(userId: $id) {
           userId
           goals {
               id
               order
               title
           }
            ownGoal
            tags {
                id
                title
                countResults
                subject {
                    id
                    title
                }
            }
            subjects {
                id
                title
            }
            wantTeach
            wantTeach
        }
    }
`;