import './scss/index.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Tag } from 'antd';
import { useIntl } from 'react-intl';

import { useLocalePrefix } from '../../lang';
import { Svg } from '../../common';
import { SignInComponent, ThemeSwitcherComponent, useResolutions } from '../../common';
import { HomeFilled } from '@ant-design/icons';

export const BreadCrumbs = ({ currentTitle, workspace }) => {
    const [localePrefix] = useLocalePrefix();
    const { isSMdown } = useResolutions();
    const { formatMessage } = useIntl();

    return (
        <div className='nav-list__item__link_horizont'>
            {isSMdown ?
                <Link to={`${localePrefix}/`}>
                    {workspace?.favicon ?
                        <Avatar src={workspace?.favicon} size={24} className='margin_right' />
                        :
                        <HomeFilled className='margin-right' />
                    }

                </Link>
                :
                <Link to={`${localePrefix}/`}>
                    {workspace?.favicon &&
                    <Avatar src={workspace?.favicon} size={24} className='margin_right' />
                    }
                    {workspace?.title?.substring(0, 50)}

                </Link>
            }
            {workspace?.inviteOnly &&
            <small className='wrapper__tag-link_inlineblock'>
                        <span className='tag'>
                            {formatMessage({ id: 'invite_only' })}
                        </span>
            </small>
            }
            {workspace?.loginOnly && !workspace?.inviteOnly &&
            <small className='wrapper__tag-link_inlineblock'>
                        <span className='tag'>
                            {formatMessage({ id: 'login_only' })}
                        </span>
            </small>

            }
            {/* <Link to={`${localePrefix}/`}>
                    {workspace?.title.substring(0, 50)}
                </Link> */}

            {/* {!!currentTitle?.length && currentTitle?.map(title => (
                    <>
                        <span className='text_secondary'> /{"  "}</span>
                        <Link to={title?.path}>
                            {title?.title.substring(0, 20)}…
                        </Link>
                    </>
                )
                )} */}

        </div>
    );
};
