export const PROFESSIONS_ID = {
    tech: {
        frontjr: {
            title: 'Jr Frontend Developer',
            description: 8094,
            id: 1747,
            salary: '51-78',
            tags: [
                1527, 1486, 1670, 1532, 1544, 1531, 1641, 1654, 1648, 1647,
                1652, 1655, 1656, 1657, 1662, 1663, 1664, 1480, 1916,
            ],
        },

        frontmid: {
            title: 'Sr Frontend Developer',
            description: 8094,
            id: 1749,
            salary: '78-119',
            tags: [
                1671, 1527, 1486, 1541, 1543, 1532, 1544, 1540, 1625, 1531,
                1638, 1639, 1641, 1642, 1643, 1646, 1654, 1648, 1649, 1650,
                1651, 1652, 1653, 1655, 1656, 1657, 1658, 1662, 1663, 1664,
                1668, 1667, 1821,
            ],
        },
        gamedev: {
            title: 'Game developer',
            description: 8083,
            id: 1759,
            salary: '57-120',
            tags: [
                1631, 1527, 1486, 1591, 1546, 1615, 1616, 1625, 1638, 1639,
                1642, 1643, 1634, 1768, 1635, 1633, 1769, 1517, 1811, 1850,
                1849,
            ],
        },

        android_dev: {
            title: 'Android developer',
            description: 8086,
            id: 1748,
            salary: '61-126',
            tags: [
                1527, 1545, 1544, 1591, 1621, 1613, 1625, 1638, 1639, 1762,
                1770, 1821,
            ],
        },
        data_analyst: {
            title: 'Data analyst',
            description: 8085,
            id: 1758,
            salary: '63-99',
            tags: [
                1545, 1615, 1625, 1638, 1639, 1683, 1762, 1788, 1789, 1792,
                1797, 1813, 1812, 1815, 1817, 1818, 1819, 1820, 1821, 1822,
                1823, 1824, 1825, 1790, 1826, 1816,
            ],
        },
    },

    art: {
        web_designer: {
            title: 'Web Designer',
            description: 8073,
            id: 1750,
            salary: '62-94',
            tags: [
                1465, 1466, 1467, 1468, 1532, 1544, 1617, 1663, 1667, 1677,
                1469, 1470, 1471, 1488, 1491, 1516, 1519,
            ],
        },
        graph_designer: {
            title: 'Graphic Designer',
            description: 8075,
            id: 2072,
            salary: '62-94',
            tags: [
                1468, 1551, 1617, 1620, 1611, 1702, 1469, 1471, 1488, 1491,
                1516, 1518, 1519, 1506, 1853, 1852, 1874, 1875, 1876, 1503,
            ],
        },
        ux_designer: {
            title: 'UX/UI Designer',
            description: 8070,
            id: 1751,
            salary: '71-106',
            tags: [
                1465, 1466, 1464, 1467, 1468, 1787, 1469, 1470, 1488, 1491,
                1519, 1813, 1828, 1916, 1917, 1918, 1919, 1920, 1911, 1910,
                1912,
            ],
        },
        motion_designer: {
            title: 'Motion Designer',
            description: 8071,
            id: 1761,
            salary: '68-107',
            tags: [
                1522, 1468, 1603, 1600, 1596, 1617, 1619, 1679, 1699, 1703,
                1469, 1471, 1516, 1853, 1852, 1851,
            ],
        },
        artist: {
            title: 'Artist',
            description: 8080,
            id: 1752,
            salary: '68-107',
            tags: [
                1510, 1553, 1551, 1538, 1620, 1627, 1630, 1704, 1488, 1491,
                1512, 1516, 1511, 1852, 1854,
            ],
        },
        d3_modeler: {
            title: '3D Modeler',
            description: 8077,
            id: 1760,
            salary: '38-61',
            tags: [
                1510, 1538, 1630, 1488, 1491, 1496, 1517, 1511, 1853, 1852,
                1854, 1856, 1857, 1858,
            ],
        },
    },

    science: {
        nutritionist: {
            title: 'Nutritionist',
            description: 8095,
            id: 1756,
            salary: '36-78',
            tags: [
                1636, 1784, 1799, 1828, 1829, 1830, 1831, 1832, 1833, 1834,
                1835, 1836, 1837, 1838, 1839, 1840, 1841,
            ],
        },

        detective: {
            title: 'Biologist',
            description: 8091,
            id: 2000,
            salary: '46-102',
            tags: [
                1461, 1462, 1539, 1612, 1625, 1629, 1745, 1748, 1744, 1788,
                1806, 1813, 1827, 1884, 1885, 1886, 1887,
            ],
        },
        astrophysicist: {
            title: 'Cosmetologist',
            description: 8093,
            id: 2004,
            salary: '49-124',
            tags: [
                1594, 1629, 1637, 1668, 1758, 1697, 1636, 1802, 1813, 1829,
                1830, 1831, 1832, 1833, 1836, 1837, 1841, 1885, 1888,
            ],
        },
        cosmetologist: {
            title: 'Chef',
            description: 8096,
            id: 2005,
            salary: '20-61',
            tags: [
                1629, 1757, 1758, 1636, 1829, 1830, 1831, 1832, 1836, 1840,
                1841, 1883, 1893, 1894, 1895, 1896, 1897,
            ],
        },
    },

    info: {
        writer: {
            title: 'Writer',
            description: 8082,
            id: 1762,
            salary: '49-86',
            tags: [
                1596, 1673, 1672, 1718, 1722, 1786, 1806, 1506, 1862, 1865,
                1859, 1860, 1863, 1866, 1875, 1845,
            ],
        },
        journalist: {
            title: 'Journalist',
            description: 8078,
            id: 1745,
            salary: '56-90',
            tags: [
                1670, 1668, 1786, 1787, 1788, 1506, 1813, 1862, 1827, 1865,
                1859, 1860, 1863, 1864, 1846, 1790,
            ],
        },
        copywriter: {
            title: 'Copywriter',
            description: 8079,
            id: 1994,
            salary: '63-99',
            tags: [
                1596, 1661, 1678, 1806, 1506, 1862, 1865, 1852, 1859, 1860,
                1863, 1864, 1861, 1844, 1503, 1845,
            ],
        },
        accountant: {
            title: 'Translator',
            description: 8090,
            id: 1753,
            salary: '56-90',
            tags: [
                1682, 1716, 1717, 1718, 1719, 1521, 1806, 1862, 1865, 1859,
                1866, 1868, 1869, 1870,
            ],
        },
    },

    people: {
        blogger: {
            title: 'Blogger',
            description: 8081,
            id: 1757,
            salary: '25-51',
            tags: [
                1509, 1595, 1600, 1599, 1596, 1668, 1776, 1703, 1787, 1471,
                1491, 1827, 1865, 1863, 1874, 1875, 1876,
            ],
        },
        educator: {
            title: 'Educator',
            description: 8072,
            id: 2001,
            salary: '35-85',
            tags: [
                1668, 1667, 1677, 1698, 1696, 1703, 1710, 1760, 1759, 1475,
                1489, 1492, 1494, 1504, 1827, 1865, 1859, 1860, 1883, 1845,
            ],
        },
        psychologist: {
            title: 'Psychologist',
            description: 8092,
            id: 2002,
            salary: '54-121',
            tags: [
                1668, 1722, 1723, 1724, 1725, 1726, 1727, 1728, 1760, 1759,
                1729, 1636, 1787, 1799, 1801, 1828, 1878, 1879, 1880, 1881,
                1882,
            ],
        },
        fitness_instructor: {
            title: 'Fitness instructor',
            description: 8088,
            id: 2003,
            salary: '30-103',
            tags: [
                1668, 1722, 1756, 1760, 1759, 1636, 1787, 1799, 1828, 1839,
                1878, 1879, 1882,
            ],
        },
    },

    business: {
        entrepreneur: {
            title: 'Entrepreneur',
            description: 8084,
            id: 1744,
            salary: '45-80',
            tags: [
                1500, 1502, 1509, 1520, 1493, 1481, 1730, 1668, 1667, 1776,
                1753, 1683, 1750, 1751, 1764, 1775, 1778, 1697, 1519, 1827,
                1503,
            ],
        },
        marketing_manager: {
            title: 'Marketing Manager',
            description: 8076,
            id: 1755,
            salary: '62-88',
            tags: [
                1661, 1668, 1678, 1683, 1750, 1697, 1490, 1788, 1809, 1813,
                1871, 1861, 1874, 1875, 1876, 1877, 1873, 1872, 1844, 1503,
            ],
        },
        sales: {
            title: 'Sales Manager',
            description: 8065,
            id: 1746,
            salary: '60-130',
            tags: [
                1502, 1668, 1776, 1722, 1697, 1752, 1788, 1793, 1813, 1812,
                1824, 1861, 1877, 1873, 1872,
            ],
        },
        ceo: {
            title: 'CEO',
            description: 8089,
            id: 1995,
            salary: '122-200',
            tags: [
                1669, 1493, 1730, 1776, 1753, 1750, 1751, 1775, 1697, 1752,
                1793, 1804, 1812, 1827, 1871, 1875, 1877, 1873, 1872,
            ],
        },

        business_analyst: {
            title: 'Business Analyst',
            description: 8074,
            id: 1996,
            salary: '70-97',
            tags: [
                1671, 1502, 1466, 1670, 1545, 1467, 1639, 1640, 1668, 1683,
                1788, 1793, 1798, 1806, 1813, 1824, 1825, 1871,
            ],
        },
        pr: {
            title: 'PR',
            description: 8087,
            id: 1997,
            salary: '45-74',
            tags: [
                1668, 1678, 1786, 1787, 1506, 1813, 1827, 1865, 1860, 1863,
                1864, 1874, 1875, 1876, 1846, 1844, 1883,
            ],
        },
    },
};
