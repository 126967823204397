// import {fetchData} from '../../../context/firebase/firebaseRequests';
import {getCacheManager} from './getCacheManager';

const LEVELS_KEY = 'levels';
const {hasCache, getCache, setCache} = getCacheManager();

export const getLevels = async () => {
    const isCache = hasCache(LEVELS_KEY);
    // if (!isCache) {
    //     const dbData = await fetchData(LEVELS_KEY);
    //     setCache(LEVELS_KEY, dbData.val());
    // }
    return getCache(LEVELS_KEY);
}