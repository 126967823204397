import React, {useEffect, useRef, useMemo, useState} from 'react';
import {noop} from 'lodash';
import {Button} from 'antd';
import cytoscape from 'cytoscape';
import {PROFESSIONS_ID} from './data/frontend_nodes';
import {useGetPreference} from '../common/graphQL/preference/useGetPreference';

let CY;

export const GraphComponent = ({
    skillsTree,
    selectedSpec,
    onSkillsRendered = noop,
    onProfessionClick,
    user,
}) => {
    const cyRef = useRef();
    const {preference, getPreference} = useGetPreference({
        fetchPolicy: 'no-cache',
    });
    const checkedNodes = skillsTree.nodes ? skillsTree.nodes : [];
    const checkedEdges = skillsTree.edges ? skillsTree.edges : [];
    const checkedClusters = skillsTree.clusters;

    const skillsByProf = (data) => {
        CY.filter((el, i) => {
            const label = el.data('label');
            const subjectColorNative = el.data('subjectColorNative'); // take color form node

            if (data.includes(label)) {
                // if (subjectColorNative && subjectColorNative !== subjectColor) {
                el.data('subjectColor', subjectColorNative); // set newcolor to selected node
                el.data('subjectTitleColor', subjectColorNative);
                // }
            } else {
                el.data('subjectColor', '#666'); // set newcolor to notSelected node
                el.data('subjectTitleColor', '#666');
            }
        });
    };

    const makeFlatArray = (data) => {
        let flatArray = [];
        data?.tags?.forEach((tag) => flatArray.push(tag?.title));
        skillsByProf(flatArray);
    };

    const onChange = (prof) => {
        if (!user?.id) {
            onProfessionClick();
        } else {
            const data = PROFESSIONS_ID[selectedSpec?.title][prof].id;
            if (data) {
                (async () => {
                    let d = data && (await getPreference(data));
                    makeFlatArray(d);
                })();
            }
        }
    };

    let gridOptions = {
        name: 'grid',
        fit: true, // whether to fit the viewport to the graph
        padding: 30, // padding used on fit
        boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
        avoidOverlap: true, // prevents node overlap, may overflow boundingBox if not enough space
        avoidOverlapPadding: 10, // extra spacing around nodes when avoidOverlap: true
        nodeDimensionsIncludeLabels: false, // Excludes the label when calculating node bounding boxes for the layout algorithm
        spacingFactor: undefined, // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
        condense: false, // uses all available space on false, uses minimal space on true
        rows: undefined, // force num of rows in the grid
        cols: undefined, // force num of columns in the grid
        position: function (node) {}, // returns { row, col } for element
        sort: undefined, // a sorting function to order the nodes; e.g. function(a, b){ return a.data('weight') - b.data('weight') }
        animate: false, // whether to transition the node positions
        animationDuration: 500, // duration of animation in ms if enabled
        animationEasing: undefined, // easing of animation if enabled
        animateFilter: function (node, i) {
            return true;
        }, // a function that determines whether the node should be animated.  All nodes animated by default on animate enabled.  Non-animated nodes are positioned immediately when the layout starts
        ready: undefined, // callback on layoutready
        stop: undefined, // callback on layoutstop
        transform: function (node, position) {
            return position;
        }, // transform a given node position. Useful for changing flow direction in discrete layouts
    };

    let circleOptions = {
        name: 'circle',
        fit: true, // whether to fit the viewport to the graph
        padding: 30, // the padding on fit
        boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
        avoidOverlap: true, // prevents node overlap, may overflow boundingBox and radius if not enough space
        nodeDimensionsIncludeLabels: false, // Excludes the label when calculating node bounding boxes for the layout algorithm
        spacingFactor: undefined, // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
        radius: undefined, // the radius of the circle
        startAngle: (3 / 1) * Math.PI, // where nodes start in radians
        sweep: undefined, // how many radians should be between the first and last node (defaults to full circle)
        clockwise: true, // whether the layout should go clockwise (true) or counterclockwise/anticlockwise (false)
        sort: undefined, // a sorting function to order the nodes; e.g. function(a, b){ return a.data('weight') - b.data('weight') }
        animate: false, // whether to transition the node positions
        animationDuration: 500, // duration of animation in ms if enabled
        animationEasing: undefined, // easing of animation if enabled
        animateFilter: function (node, i) {
            return true;
        }, // a function that determines whether the node should be animated.  All nodes animated by default on animate enabled.  Non-animated nodes are positioned immediately when the layout starts
        ready: undefined, // callback on layoutready
        stop: undefined, // callback on layoutstop
        transform: function (node, position) {
            return position;
        }, // transform a given node position. Useful for changing flow direction in discrete layouts
    };

    const ciseOptions = {
        name: 'cise',
        clusters: checkedClusters,
        refresh: 10,
        padding: 40,

        // separation amount between nodes in a cluster
        // note: increasing this amount will also increase the simulation time
        // nodeSeparation: 12.5,
        nodeSeparation: 40,

        // Inter-cluster edge length factor
        // (2.0 means inter-cluster edges should be twice as long as intra-cluster edges)
        idealInterClusterEdgeLengthCoefficient: 4,

        // Whether to pull on-circle nodes inside of the circle
        allowNodesInsideCircle: true,

        // Max percentage of the nodes in a circle that can move inside the circle
        maxRatioOfNodesInsideCircle: 0.1,

        // Node repulsion (non overlapping) multiplier
        nodeRepulsion: 1,

        // Layout event callbacks; equivalent to `layout.one('layoutready', callback)` for example
        ready: function () {}, // on layoutready
        stop: function () {}, // on layoutstop
    };

    let concentricOptions = {
        name: 'concentric',

        fit: true, // whether to fit the viewport to the graph
        padding: 30, // the padding on fit
        startAngle: (3 / 2) * Math.PI, // where nodes start in radians
        sweep: undefined, // how many radians should be between the first and last node (defaults to full circle)
        clockwise: true, // whether the layout should go clockwise (true) or counterclockwise/anticlockwise (false)
        equidistant: false, // whether levels have an equal radial distance betwen them, may cause bounding box overflow
        minNodeSpacing: 50, // min spacing between outside of nodes (used for radius adjustment)
        boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
        avoidOverlap: true, // prevents node overlap, may overflow boundingBox if not enough space
        nodeDimensionsIncludeLabels: false, // Excludes the label when calculating node bounding boxes for the layout algorithm
        height: undefined, // height of layout area (overrides container height)
        width: undefined, // width of layout area (overrides container width)
        spacingFactor: undefined, // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
        concentric: function (node) {
            // returns numeric value for each node, placing higher nodes in levels towards the centre
            return node.degree();
        },
        levelWidth: function (nodes) {
            // the variation of concentric values in each level
            return nodes.maxDegree() / 4;
        },
        animate: false, // whether to transition the node positions
        animationDuration: 500, // duration of animation in ms if enabled
        animationEasing: undefined, // easing of animation if enabled
        animateFilter: function (node, i) {
            return true;
        }, // a function that determines whether the node should be animated.  All nodes animated by default on animate enabled.  Non-animated nodes are positioned immediately when the layout starts
        ready: undefined, // callback on layoutready
        stop: undefined, // callback on layoutstop
        transform: function (node, position) {
            return position;
        }, // transform a given node position. Useful for changing flow direction in discrete layouts
    };

    const graphs = useMemo(
        () => [...checkedNodes, ...checkedEdges],
        [checkedNodes, checkedEdges]
    );

    useEffect(() => {
        const cise = require('cytoscape-cise');
        cytoscape.use(cise); 
        CY = cytoscape({
            style: [
                {
                    selector: 'node',
                    style: {
                        shape: 'star',
                        'background-color': 'data(subjectColor)',
                        'border-color': '#0A0E16',
                        'border-width': '1',
                        label: 'data(label)',
                        height: 'data(counter)',
                        width: 'data(counter)',
                        // color: '#666',
                        color: 'data(subjectTitleColor)',
                    },
                },
                {
                    selector: '.subject',
                    style: {
                        shape: 'ellipse',
                        width: 30,
                        height: 20,
                        color: 'data(subjectColor)',
                        label: 'data(label)',
                        'text-valign': 'bottom',
                        'border-width': '0',
                        'background-opacity': 0,
                        'background-image': 'data(image)',
                        'background-fit': 'contain',
                        'background-clip': 'none',
                        'text-background-padding': 0,
                    },
                },
                {
                    selector: ':active',
                    style: {
                        color: 'white',
                        'line-color': 'white',
                        'target-arrow-color': 'white',
                        'source-arrow-color': 'white',
                    },
                },

                {
                    selector: 'edge',
                    style: {
                        width: 1,
                        'line-color': '#333',
                        'line-style': 'dotted',
                    },
                },
                {
                    selector: 'label',

                    style: {
                        'font-size': 'data(fontSize)',
                        margin: '12',
                    },
                },
            ],
        });
        CY.mount(cyRef.current);
        const graphElements = [...checkedNodes, ...checkedEdges];

        CY.add(graphs);

        if (selectedSpec) {
            const layout = CY.layout(ciseOptions);
            layout.run();
        } else {
            // const layout = CY.layout(concentricOptions);
            // const layout = CY.layout(gridOptions);
            const layout = CY.layout(circleOptions);
            layout.run();
        }

        CY.maxZoom(4);
        CY.minZoom(0.2);

        CY.on('tap', 'node', function () {
            try {
                window.open(this.data('link'));
            } catch (e) {
                window.location.href = this.data('link');
            }
        });

        return () => {
            CY.destroy();
        };
    }, [skillsTree]);

    useEffect(() => onSkillsRendered(true), []);

    return (
        <>
            {selectedSpec && PROFESSIONS_ID[selectedSpec?.title] && (
                <ul className='profession_list wrapper_list hide-on-mobile'>
                    {Object.keys(PROFESSIONS_ID[selectedSpec?.title])?.map(
                        (key, i) => {
                            let prof = PROFESSIONS_ID[selectedSpec?.title][key];

                            return (
                                <li key={`${key}-${i}`}>
                                    <Button
                                        style={{
                                            position: 'relative',
                                            zIndex: 2,
                                        }}
                                        onClick={() => onChange(key)}
                                        size='small'
                                        type='text'
                                    >
                                        {prof?.title}
                                    </Button>
                                </li>
                            );
                        }
                    )}
                </ul>
            )}
            <div ref={cyRef} />
        </>
    );
};
