import {useMutation} from '@apollo/client';
import {CreateWorkspaceEventsList} from '../graphQL/mutations/CreateWorkspaceEventsList';

export const useCreateEventsList = (mutation = CreateWorkspaceEventsList) => {
    const [createEventsList, {data, error, loading}] = useMutation(mutation);

    return {
        createEventsList,
        data,
        error,
        loading
    }
}