import { fetchData } from '../../../context/firebase/firebaseRequests';

export const useConditionalVoiceMode = () => {
    const getSafetyModel = async (ownerId, model) => {
        const dbData = await fetchData(`/currentCredits/${ownerId}/currentCredits`);
        const values = dbData.val() || 0;
        if (values > 0) {
            return model;
        } else {
            return null;
        }
    };
    return { getSafetyModel };
};