import { useCallback, useMemo } from 'react';
import { setDataByPath, setDataArray } from '../../context/firebase/firebaseRequests';

export const getTextFromHTML = (s) => {
    const span = document.createElement('div');
    span.innerHTML = s;
    return span.innerText || '';
};

export const getMetaData = (event, hostname, workspace, localePrefix, workspaceUrl, formatMessage) => {
    if (!event) {
        return null;
    }

    if (hostname || workspace) {
        const { title, description, galleriesInEvent } = event;
        const metaDescription = description
            ? getTextFromHTML(description)
            : `Online-course ${event && event?.title} hosted by ${event && event?.creator?.name} on ${workspace?.title}`;
        const url = `${workspaceUrl}${localePrefix}online-courses/${event?.alias}-${event?.id}`;
        const ogData = { description: metaDescription, url: url };
        const eventImg = galleriesInEvent?.filter((gInE) => gInE?.gallery?.type === 'PHOTO')?.[0]?.gallery?.source || workspace?.cover;
        if (eventImg) { ogData.image = eventImg; }

        return {
            meta: {
                meta_title: `${title} | ${workspace?.title}`,
                meta_description: metaDescription,
                meta_author: event?.creator?.name,
                meta_icon: workspace?.logo,
            },
            og: ogData,
            canonical: url
        };
    } else {
        const { title, description, galleriesInEvent } = event;

        const metaDescription = description
            ? getTextFromHTML(description)
            : `Online-course "${event && event?.title}" about ${event && event?.tags?.map((el) => el?.title)
            } hosted by ${event && event?.creator?.name} ${formatMessage({
                id: 'meta_description_courses',
            })}`;
        const ogData = { description: metaDescription };
        const eventImg = galleriesInEvent?.filter(
            (gInE) => gInE?.gallery?.type === 'PHOTO'
        )?.[0]?.gallery?.source;
        if (eventImg) {
            ogData.image = eventImg;
        }
        return {
            meta: {
                meta_title: `${title} | ${formatMessage({
                    id: 'meta_event_title',
                })}`,
                meta_description: metaDescription,
            },
            og: ogData,
            twitter: {
                tw_description: metaDescription,
            },
        };
    }
};

export const subscribeInvited = async (event, user, subscribeEvent, setEvent, joinToWorkspace, onGetEvent) => {
    try {
        const subscribeEventResult = await subscribeEvent({
            variables: {
                eventId: event.id,
                type: 'FULL'
            },
        });

        setEvent((prev) => ({
            ...prev,
            userSubscriptions: [
                ...prev.userSubscriptions,
                subscribeEventResult.data?.userSubscription,
            ],
            countUserSubscriptions: prev.countUserSubscriptions + 1,
            isSubscribe: true,
        }));

        if (event.workspace?.id) {
            await joinToWorkspace(event.workspace, user);
        }
    } catch (e) {
        if (e.message === 'You are already subscribed') {
            setEvent((prev) => ({
                ...prev,
                isSubscribe: true,
            }));
        }
    }
    if (onGetEvent) {
        await onGetEvent();
    }
};