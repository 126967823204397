
import './scss/index.scss';
import React from "react";

import { YoutubeStorageChannelIdContainer } from '../../youtube';
export const AddYoutubeChannel = ({
    sourceList,
    handleChangeChannel,
    ...other
}) => {

    return (
        <>
            <YoutubeStorageChannelIdContainer
                {...other}
            />
        </>
    );
};
