import React from 'react';
import { Form, InputNumber, Radio } from 'antd';
import { GRANULARITY_LABELS } from '../../constant'

export const DripFeedingForm = ({ formatMessage, form }) => {
    const dripOptions = [
        { label: 'None', value: 'NONE' },
        { label: 'Join Date', value: 'JOINDATE' },
        { label: 'Answer', value: 'ANSWER' },
    ];

    const getInteractiveExplanation = () => {
        const selectedDrip = form.getFieldValue('drip');
        const selectedDripGranularity = form.getFieldValue('dripGranularity');
        const selectedDripFrequency = form.getFieldValue('dripFrequency');

        switch (selectedDrip) {
            case 'JOINDATE':
                const granularityLabel = formatMessage({ id: `drip_granularity_${selectedDripGranularity?.toLowerCase()}` }) || ''
                return formatMessage({ id: 'drip_explanation_joindate' }, { granularity: granularityLabel, days: selectedDripFrequency });
            case 'ANSWER':
                return formatMessage({ id: 'drip_explanation_answer' });
            default:
                return formatMessage({ id: 'drip_explanation_default' });
        }
    };

    return (
        <div>
            {/* Wrap the explanation in a Form.Item that listens for changes in any of the relevant fields */}
            <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.drip !== currentValues.drip ||
                    prevValues.dripGranularity !== currentValues.dripGranularity ||
                    prevValues.dripFrequency !== currentValues.dripFrequency
                }

            >
                {() => (
                    <h3>{getInteractiveExplanation()}</h3>
                )}
            </Form.Item>
            <hr />
            <Form.Item
                name='drip'
                label={formatMessage({ id: 'drip_label' })}
            >
                <Radio.Group>
                    {dripOptions.map(option => (
                        <Radio key={option.label} value={option.value}>
                            {option.label}
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.drip !== currentValues.drip}

            >
                {({ getFieldValue }) => {
                    const selectedDrip = getFieldValue('drip');
                    const selectedDripFrequency = getFieldValue('dripFrequency');
                    const selectedDripGranularity = getFieldValue('dripGranularity');

                    if (selectedDrip === 'JOINDATE' && !selectedDripFrequency && !selectedDripGranularity) {
                        form.setFieldsValue({
                            dripFrequency: 1,
                            dripGranularity: GRANULARITY_LABELS[0].value
                        });
                    }

                    return getFieldValue('drip') === 'JOINDATE' ? (
                        <>
                            <Form.Item
                                name='dripFrequency'
                                label={formatMessage({ id: 'drip_frequency_label' })}

                            >
                                <InputNumber
                                    min={1}
                                    max={30}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item
                                name='dripGranularity'
                                label={formatMessage({ id: 'drip_granularity_label' })}
                            >
                                <Radio.Group>
                                    {GRANULARITY_LABELS.map(option => (
                                        <Radio key={option.value} value={option.value}>
                                            {formatMessage({ id: `drip_granularity_${option.value.toLowerCase()}` }, { defaultMessage: option.label })}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </>
                    ) : null;
                }}
            </Form.Item>
        </div>
    );
};