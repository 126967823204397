import React, { useState, useContext } from "react";

import { SubscriptionComponent } from "../Subscription.component";
import { useGetSubscriptionUrl } from "../hooks";
import { FirebaseContext } from "../../../context/firebase/firebaseContext";

export const SubscriptionContainer = (props) => {
  const { as: Component = SubscriptionComponent, plan, onShowVerificationModal, ...other } = props;
  const { user } = useContext(FirebaseContext);
  const { getSubscriptionUrl, loading } = useGetSubscriptionUrl();
  const [showLogin, setShowLogin] = useState(false);

  const handleSubscribe = async () => {
    try {
      const urlData = await getSubscriptionUrl({variables: { input: { type: plan } },});
      const url = urlData.data.premiumSubscribeCreate;
      window.location.assign(url);
      
    } catch (err) {
      console.warn(err);
    }
  };

  const handleCheckUser = async () => {
    const { id, emailVerified } = user;
    if (id) {
      if (emailVerified) {
        handleSubscribe();
      } else {
        onShowVerificationModal(true);
      }
    } else {
      setShowLogin(true);
    }
  };

  return (
    <Component
      {...other}
      loading={loading}
      onSubscribe={handleCheckUser}
      showLogin={showLogin}
      onSetShowLogin={setShowLogin}
      onLoginUser={handleSubscribe}
    />
  );
};
