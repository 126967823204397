import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useAiChatResponse } from "../graphQL/useAiChatResponse";

export const useNextStepAi = () => {
  const [error, setError] = useState(null);
  const { formatMessage } = useIntl();
  const { getChatResponse } = useAiChatResponse();

  const getNextStep = async ({
    handleChangeProgress,
    goal,
    steptitle,
    source,
    curriculum,
  }) => {
    handleChangeProgress({
      message: formatMessage({ id: 'loader_nextstepai_async' }),
      key: 'description',
    });

    const args = {
      ...(goal && { goal }),
      stepTitle: steptitle,
      ...(source && { source }),
      curriculum: curriculum,
    };

    try {
      const resp = await getChatResponse({
        variables: {
          input: {
            type: 'nextStepJson',
            args: { ...args },
          },
        },
      });
      const { requestChatApi } = resp?.data || {};
      const respJSON = requestChatApi ? JSON.parse(requestChatApi) : {};
      const content = respJSON?.choices?.[0]?.message?.content;

      handleChangeProgress({ key: 'description' });
      return content;
    } catch (error) {
      console.error('Error in getNextStep:', error);
      handleChangeProgress({ key: 'description' });
      setError(error);
      throw error;
    } finally {
      handleChangeProgress(false);
    }
  };

  return { getNextStep, error };
};