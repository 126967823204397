import './scss/index.scss';
import React from 'react';
import classnames from 'classnames';

export const CircleIndexComponent = ({ isActive, index, check, icon }) => {
    const isCorrect = check === 'CORRECT';
    const isInCorrect = check === 'INCORRECT';

    const classNames = classnames('circle-index inline-el', {
        'circle-index_active': isActive,
    });

    const getIcon = () => {
        if (icon) {
            return icon;
        }
        if (isCorrect) {
            return '✅';
        }
        if (isInCorrect) {
            return '❌';
        }
        if (index) {
            return index;
        }
        return icon;
    }

    return (
        <div className={classNames}>
            <span className={isCorrect || isInCorrect ? ' circle__icon' : ' circle__indes'}>{getIcon()}</span>

        </div>
    );
};
