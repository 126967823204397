import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useHostNameUrl, MetaComponent } from '../Components/common';
import { useLocalePrefix } from '../Components/lang';
import { JoinContainer } from '../Components/workspace/Join.container/Join.container';

export const Invite = (props) => {
    const { workspace, user, history, ...other } = props;
    const { formatMessage } = useIntl();
    const workspaceUrl = useHostNameUrl(workspace);
    const [localePrefix] = useLocalePrefix();

    const metaData = useMemo(() => {
        const title = workspace?.title;
        const metaTitle = `${title}`;
        const description = `Learn Online on ${title}`;
        const url = `${workspaceUrl}${localePrefix}`;

        return {
            meta: {
                meta_title: metaTitle,
                meta_description: description,
                meta_author: workspace.title,
                meta_icon: workspace.favicon || workspace.logo,
            },
            og: {
                title: metaTitle,
                image: workspace?.sharing || workspace?.cover,
                description,
                url,
            },
            canonical: url
        };
    }, [workspace, localePrefix, workspaceUrl]);

    const imageStyle = useMemo(() => {
        const {loginCover} = workspace || {};

        if (loginCover) {
            return {
                backgroundImage: `url(${loginCover})`,
                backgroundSize: 'cover'
            }
        } else {
            return  {
                backgroundImage: 'url("https://cdn.unschooler.me/web_app/course_covers/big.png")',
                filter: 'brightness(76%) contrast(113%)',
                backgroundSize: 'cover'
            }
        }
    }, [workspace]);

    return (
        <>
            {metaData && (
                <MetaComponent
                    meta={metaData.meta}
                    og={metaData.og}
                    twitter={metaData.twitter}
                    canonical={metaData.canonical}
                />
            )}

            <div className=' create-event-page '>
                <div className='flex-container flex-container-stretch'>
                    <div className='login_left'>
                        <div className='login_form_wrapper'>
                            <div className='login_form'>
                                <div className=' wrapper_outer-section'>
                                    <h1>{formatMessage({ id: 'welcome_title_text' }, { workspace: workspace.title })}</h1>
                                    {/* <p className='wrapper_inner-section'>{formatMessage({ id: 'invite_subtitle' })}</p> */}
                                    <JoinContainer
                                        workspace={workspace}
                                        user={user}
                                        history={history}
                                        {...other}
                                    />
                                    <div className='wrapper_outline'>

                                        {/* {user && (
                                        <>
                                        
                                        <hr />
                                                <AuthFormComponent
                                                    userData={user}
                                                    isReadOnlyEmail={true} />
                                        </>
                                    )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="login_right"
                         style={imageStyle}/>
                </div>
            </div>
        </>
    )
};
