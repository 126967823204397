// src/Components/rating/StudentProgressHeatmap/StudentProgressHeatmap.js

import React, { useMemo } from 'react';
import './scss/index.scss';

const CRITICAL_THRESHOLD = 30;

export const StudentProgressHeatmap = ({ startedData }) => {
  const getColor = (value) => {
    if (value === 1) return 'green';
    if (value === 0) return 'red';
    return 'gray';
  };

  const calculateCompletion = (questions) => {
    const completed = questions.filter((lesson) => lesson !== null).length;
    // const total = questions.filter((lesson) => lesson !== null && lesson !== undefined).length;
    return (completed / questions.length) * 100;
  };

  const numQuestions = useMemo(() => {
    return Math.max(...startedData.map((student) => student.questions.length));
  }, [startedData]);


  const engagementRates = useMemo(() => {
    const rates = [];
    for (let i = 0; i < numQuestions; i++) {
      const lessonData = startedData
        .map((student) => student.questions[i])
        .filter((lesson) => lesson !== null && lesson !== undefined);
        const countCompleted = lessonData.filter(lesson => lesson !==  null).length

      rates.push((countCompleted / startedData.length) * 100);
    }
    return rates;
  }, [numQuestions, startedData]);

  const criticalSkipRates = useMemo(() => {
    return engagementRates.map((rate) => (rate < CRITICAL_THRESHOLD ? 100 - rate : 0));
  }, [engagementRates]);

  return (
    <div className="heatmap-container " style={{ overflowX: 'auto', overflowY: 'auto' }}>
      <div className="heatmap-content">
        <div className="header-row">
          <div className="student-name"></div>
          {[...Array(numQuestions)].map((_, index) => (
            <div key={index} className="header-cell">
              {index + 1}
            </div>
          ))}
        </div>
        {startedData.map((student, studentIndex) => (
          <div key={student.name} className="student-row">
            <div className="student-name">{student.name}</div>
            <div className="lesson-cells">
              {[...Array(numQuestions)].map((_, lessonIndex) => {
                const lesson = student.questions[lessonIndex];
                return (
                  <div
                    key={`${studentIndex}-${lessonIndex}`}
                    className={`lesson-cell ${getColor(lesson)} ${
                      engagementRates[lessonIndex] < CRITICAL_THRESHOLD ? 'critical' : ''
                    }`}
                  ></div>
                );
              })}
            </div>
            <div className="completion-rate">
              {calculateCompletion(student.questions).toFixed(1)}%
            </div>
          </div>
        ))}
        <div className="rate-row">
          <div className="rate-label">Engagement, %</div>
          <div className="rate-cells">
            {engagementRates.map((rate, index) => (
              <small key={index} className="rate-cell">
                {Math.round(rate)}
              </small>
            ))}
          </div>
        </div>
        <div className="rate-row">
        <div className="rate-label">Skip Rate {'>'} {CRITICAL_THRESHOLD}%</div>
        <div className="rate-cells">
            {criticalSkipRates.map((rate, index) => (
              <div key={index} className={`rate-cell ${rate > 0 ? 'critical' : ''}`}>
                {rate > 0 ? `${rate.toFixed(0)}` : '-'}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};