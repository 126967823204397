import {useMutation} from '@apollo/client';
import {CreateWorkspace} from '../graphQL/mutations/CreateWorkspace';

export const useCreateWorkspace = (mutation = CreateWorkspace) => {
    const [createWorkspace, {data, error, loading}] = useMutation(mutation);

    return {
        createWorkspace,
        workspace: data?.createWorkspace,
        error,
        loading
    }
}