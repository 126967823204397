import {useContext, useMemo} from 'react';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {useLocalePrefix} from '../../lang';

export const useGetLangId = () => {
    const {user, langs} = useContext(FirebaseContext);
    const [localePrefix] = useLocalePrefix()

    return useMemo(() => {
        const {lang} = user;

        if (!!lang) {
            return lang.id;
        }

        const locale = !!localePrefix ? localePrefix.replace('/', '') : 'en';
        const currentLang = langs?.find((lang) => lang.code === locale);
        return currentLang?.id;
    }, [user, langs])
}