import { gql, useQuery } from "@apollo/client";

export function useGetSubjects(query = GET_SUBJECTS) {
    const { data, loading, error } = useQuery(query);
    return {
        subjects: data?.subjects,
        loading,
        error   
    }
}

const GET_SUBJECTS = gql`
    query GetSubjects {
        subjects {
            id
            subjectUid
            title
            color
            icon
        }
    }
`;