import React, {useEffect, useState} from "react";
import {Table} from "antd";
import {gql, useQuery} from "@apollo/client";
import {Loader} from "../../Loader";
import {CSVLink} from "react-csv"
import {useHistory} from "react-router";

const columns = [
    {
      title: 'Name',
      dataIndex: ['user', 'name'],
      render: (text, record) => <a href={window.location.origin + "/profile/" + record.user.id}>{text}</a>,
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
    },
    {
        title: "Event",
        dataIndex: ["event" , "title"],
        render: (text, record) => <a href={window.location.origin + "/online-courses/" + record.event.id}>{text}</a>,
    },
];
  
export const PromocodeUsedByUsers = (props) => {
    const [users, setUsers] = useState();
    const [usersCsv, setUsersCsv] = useState([]);
    const { location } = useHistory();
    const { promocode } = location.state;
    
    const getUsersPromocode = useQuery(GET_USERS_PROMO, {
        variables: {
            id: promocode.id
        }
    });

    useEffect(() => {
        if(getUsersPromocode.data) {
            let users = getUsersPromocode.data.getUsersPromocode;
            let csv = [];
            setUsers(users)
            for(let i = 0; i < users.length; i++) {
                let user = users[i].user;
                let event = users[i].event;
                csv.push([user.id, user.name, user.email, event.id, event?.title])
            }
            setUsersCsv(csv);
        }
    }, [getUsersPromocode.data])

    if(getUsersPromocode.loading) {
        return <Loader />
    }

    return (
        <div className="AdminNavigation">
            <h1>{promocode.text}</h1>
            <div>
                <Table columns={columns} dataSource={users} pagination={false} />
            </div>
            <div>
                <CSVLink data={usersCsv} filename={promocode.id}>Download CSV</CSVLink>
            </div>
        </div>
    );
};

const GET_USERS_PROMO = gql`
    query getPromocode($id: String!) {
        getUsersPromocode(id: $id) {
            event {
                id
                title
            },
            user {
                uid
                name
                email
            }
        }
    }
`