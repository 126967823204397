import React from 'react';

import { MetaComponent } from '../Components/common';
import { LandingEnComponent } from '../Components/landing/LandingEn.component/LandingEn.component';
import { COVER_PICTURES } from '../Components/constant';

const description =
    'Interactive AI Video Courses with quizzes and practical tasks generated for any of your questions and files';
const title = 'AI Video Courses for Educators, Universities & Schools';
const url = 'https://unschooler.me';
const image = COVER_PICTURES.MAIN;

export const Landing = ({ user, workspace }) => {

    const meta = {
        meta_title: title,
        meta_description: description,
    };
    const og = {
        title: title,
        image: image,
        description: description,
        url: url,
    };

    const twitter = {
        tw_title: title,
        tw_url: url,
        tw_description: description,
    };

    return (
        <>
            <MetaComponent meta={meta} og={og} twitter={twitter} />
            <LandingEnComponent />
        </>
    );
};
