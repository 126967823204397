import {GetResults} from "../graphQL/queries/GetResults";
import {useLazyQuery, useQuery} from "@apollo/client";

export function useGetResults(query = GetResults) {
    const [getResults, { data, loading, error }] = useLazyQuery(query);
    return {
        results: data?.results,
        loading, error,
        getResults
    };
}