import React, { useRef } from 'react';
import { Input, Button } from 'antd';
import { useIntl } from 'react-intl';

export const AiLinkComponent = ({ onSubmit }) => {
    const { formatMessage } = useIntl();
    const inputRef = useRef();

    return (
        <div
            label={formatMessage({ id: "form_link_placeholder" })}>
            <Input ref={inputRef} placeholder={formatMessage({ id: "form_link_placeholder_input" })}/>
            <Button type="secondary" onClick={() => {
                const val = inputRef.current.input.value
                if (val) { onSubmit(val) }
            }}>
                {formatMessage({ id: 'click_upload' })}
            </Button>
        </div>
    );
};