import React, {useState, useMemo} from 'react';
import {WorkspacesComponent} from '../Workspaces.component';
import {useGetFilteredWorkspaces} from '../hooks';
import {useCheckDomains} from '../../hooks';

export const WorkspacesContainer = () => {
    const {checkCustomDomains, loading} = useCheckDomains();
    const {getWorkspaces, loading: workspacesLoading} = useGetFilteredWorkspaces();
    const [allWorkspaces, setAllWorkspaces] = useState();
    const [hostWorkspaces, setHostWorkspaces] = useState();
    const [notCompletedWorkspaces, setNotCompletedWorkspaces] = useState();
    const [completedWorkspaces, setCompletedWorkspaces] = useState();

    const onCheckDomainName = (name) => {
        const rx = /[\w-]+(\.[\w-]+)/;
        return rx.test(name) && !name.startsWith('www.') && !name.includes('/');
    };

    const onGetWorkspaces = async (filter: {}) => {
        const resp = await getWorkspaces({
            variables: {
                input: {
                    ...filter
                }
            }
        });
        return resp?.data?.getFilteredWorkspaces;
    }

    const handleGetAllWorkspaces = async () => {
        const workspaces = await onGetWorkspaces();
        setAllWorkspaces(workspaces);
    };

    const handleGetHostWorkspaces = async () => {
        const workspaces = await onGetWorkspaces({
            hasCustomDomain: false
        });
        setHostWorkspaces(workspaces);
    };

    const handleGetNotCompletedWorkspaces = async () => {
        const workspaces = await onGetWorkspaces({
            hasCustomDomain: true,
            status: false
        });
        setNotCompletedWorkspaces(workspaces);
    };

    const handleGetCompletedWorkspaces = async () => {
        const workspaces = await onGetWorkspaces({
            hasCustomDomain: true,
            status: true
        });
        setCompletedWorkspaces(workspaces);
    };

    const handleCheck = async () => {
        const items = notCompletedWorkspaces.filter((w) => onCheckDomainName(w.customDomain));
        const list = items.map((item) => ({
            domain: 'https://' + item.customDomain,
            workspaceId: item?.id
        }));

        await checkCustomDomains(list);
    };

    return (
        <div>
            <WorkspacesComponent isLoading={loading || workspacesLoading}
                                 allWorkspaces={allWorkspaces}
                                 hostWorkspaces={hostWorkspaces}
                                 notCompletedWorkspaces={notCompletedWorkspaces}
                                 completedWorkspaces={completedWorkspaces}
                                 onCheck={handleCheck}
                                 onGetAllWorkspaces={handleGetAllWorkspaces}
                                 onGetHostWorkspaces={handleGetHostWorkspaces}
                                 onGetNotCompletedWorkspaces={handleGetNotCompletedWorkspaces}
                                 onGetCompletedWorkspaces={handleGetCompletedWorkspaces}
                                 onCheckDomainName={onCheckDomainName}/>
        </div>
    )
}