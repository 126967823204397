import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Input, Upload, Spin, } from 'antd';
import { Link } from 'react-router-dom'
import { UploadOutlined } from '@ant-design/icons';
import { useTrackCredits } from '../../common/hooks/useTrackCredits';
import { PurchaseCreditsLink } from '../../payments/PurchaseCreditsButton.component/PurchaseCreditsButton.component';
const Textarea = Input.TextArea;

export const EditCustomVoiceComponent = (props) => {
    const { loading, customVoice, onFinish, onRemove } = props;
    const { formatMessage } = useIntl();
    const { useForm } = Form;
    const [form] = useForm();
    const { name, description, labels = {} } = customVoice || {};
    const { accent } = labels;
    const { isLimitReached } = useTrackCredits();
    const userAllowCustomVoice = !isLimitReached;

    return (
        <Spin spinning={loading}>
            <h2>{formatMessage({ id: 'custom_voice_title' })}</h2>

            {customVoice && (
                <div>
                    <p>Name: <strong>{name}</strong></p>
                    {accent && (
                        <p>Accent: {accent}</p>
                    )}
                    <p>Description: {description}</p>
                    <Button
                        type="secondary"
                        onClick={onRemove}
                    >
                        Remove custom voice
                    </Button>
                </div>
            )}

            {!customVoice && (
                <div className="custom-voice-component">
                    <p>
                        {formatMessage({ id: 'custom_voice_description' })}
                    </p>
                    {/* <p>
                        {formatMessage({ id: 'custom_voice_description_2' })}
                    </p> */}
                    {' '}

                    <hr />
                    {userAllowCustomVoice ? (
                        <Form
                            name='create-voice'
                            layout='vertical'
                            form={form}
                            style={{
                                maxWidth: '400px'
                            }}
                            onFinish={onFinish}
                            autoComplete="off">
                            <Form.Item label="Name"
                                name="name"
                                rules={[{ required: true, message: formatMessage({ id: 'form_field_is_required' }) }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Accent"
                                name="accent">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Description"
                                name="description"
                                rules={[{ required: true, message: formatMessage({ id: 'form_field_is_required' }) }]}>
                                <Textarea />
                            </Form.Item>
                            <Form.Item name="file"
                                rules={[{ required: true, message: formatMessage({ id: 'form_field_is_required' }) }]}>
                                <Upload beforeUpload={() => false}
                                    maxCount={1}
                                    accept="audio/*">
                                    <Button type="secondary"
                                        icon={<UploadOutlined />}>
                                        Select File
                                    </Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type='tertiary'
                                    htmlType='submit'>
                                    {formatMessage({ id: 'form_button_save' })}
                                </Button>
                            </Form.Item>
                        </Form>
                    ) : (
                        <div>
                            <p>
                                {formatMessage({ id: 'need_more_credits' })}{' '}
                                <Link to={'/tutorials/additional-pricing-326956'}>
                                    <span>
                                        {formatMessage({ id: 'how_ai_credits_work' })}</span>
                                </Link></p>
                            <PurchaseCreditsLink />
                        </div>
                    )}
                </div>
            )}
        </Spin>
    )
}