import { useQuery, gql } from "@apollo/client"
import { Link } from "react-router-dom";
import { Loader } from "../../Loader";
import {useLocalePrefix} from '../../lang';

export const Promocodes = () => {
    const { data, loading } = useQuery(GET_PROMOCODES, {
        fetchPolicy: "no-cache"
    });
    const [localePrefix] = useLocalePrefix();

    if (loading) {
        return <Loader />
    }

    return (
        <div className="AdminNavigation">
            <p>Все промокомпании:</p>
            <ul>
                {
                    data.promocodes.map((promocode, i) => (
                        <li key={promocode.id}>
                            <Link
                                className='nav-link'
                                to={`${localePrefix}/admin/promocode/${promocode.id}`}
                            >
                                {promocode.text}
                            </Link>
                        </li>
                    ))
                }
            </ul>
            <Link to={`${localePrefix}/admin/promocode/create`}>Добавить промокод</Link>
        </div>
    );
}

const GET_PROMOCODES = gql`
    query GetPromocodes {
        promocodes {
            text
            id
        }
    }
`;
