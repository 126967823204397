import { useCallback } from 'react';
import { useAiChatResponse } from '../graphQL/useAiChatResponse';
import { useGetCommonAi } from '../hook/useGetCommonAi';

const getEvent = async (arrayConcepts, goal, domains, titleTrimmed, sourceList) => {
    let tasks = [];
    arrayConcepts?.forEach((concept, i) => {
        if (concept?.concept) {
            let notFindGenerateAuto = sourceList?.find((el) => el?.key === 'yt_public');
            tasks.push({
                title: `${concept?.concept}: ${concept?.description || ''}`,
                source: goal?.length < 500 ? null : `\n${concept?.outlines || ''}`,
                ...(!notFindGenerateAuto ? { isAutogenerated: false } : { isAutogenerated: true }),
                // id: i + 1,
            });
        }
    });

    let galleriesInEvent = [];
    sourceList?.forEach((el) => {
        galleriesInEvent.push({
            gallery: {
                type: el.type,
                raw: el.raw || '',
                source: el.source,
            },
        })
    })
    return {
        title: titleTrimmed || goal?.substring(0, 150),
        tasks,
        issue: goal,
        ...(!!sourceList?.length && { galleriesInEvent: galleriesInEvent })
    };
};

export const useGetCurrculum = () => {
    const { getChatResponse, error } = useAiChatResponse();
    const { getCommonAi } = useGetCommonAi();

    const getCurriculumGen = useCallback(
        async (goal, domains, sourceList) => {
            const titleTrimmed = goal?.length > 100 && await getCommonAi(goal?.substring(0, 1000), 'title');
            const domain = !!domains?.length ? domains.map((el) => el.title).join(', ') : ' ';
            let compondGoal = goal?.substring(0, 10000);
            sourceList?.forEach((el) => {
                if (el.summary) {
                    compondGoal += `\n\n${el.summary}`;
                }
            });

            const settings = {
                args: {
                    domain: domain,
                    goal: compondGoal,
                },
                type: compondGoal?.length < 500 ? 'curriculumNumbered' : 'curriculumLongFormNumbered',
            };

            const resp = await getChatResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = resp?.data
                ? JSON.parse(resp?.data?.requestChatApi)
                : null;

            if (responseJSON?.choices[0]) {
                let responseTrimmed = JSON.parse(responseJSON.choices[0].message.content)?.curriculum;
                let ev = await getEvent(responseTrimmed, goal, domains, titleTrimmed, sourceList);
                return ev;
            }
            return null;
        },
        [getChatResponse]
    );

    return { getCurriculumGen };
};
