import React, {useContext, useState} from 'react';
import {Button} from 'antd';

import {ApiModalLoginComponent} from '../ApiModalLogin.component';
import {ApiTestButtonComponent} from '../ApiButton.component';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {useTrackClick} from '../../common';

export const YoutubeComponent = ({
    onGetUrl,
    size = 'large',
    type = 'primary',
    ...other
}) => {
    const {user, loading} = useContext(FirebaseContext);
    const {id} = user || {};
    const [showLogin, setShowLogin] = useState();
    const trackClick = useTrackClick();

    if (loading) return null;
    return (
        <div className='youtube-component'>
            {!id && (
                <>
                    <div className='api-video-tags-component-auth-button'>
                        <Button
                            className='skills_action'
                            type={type}
                            size={size}
                            onClick={() => {
                                trackClick(
                                    'test-skills',
                                    'test-skills',
                                    `https://unschooler.me/skills/`
                                );
                                setShowLogin(true);
                            }}
                        >
                            <span>Refresh YouTube interests</span>
                        </Button>
                    </div>
                    <ApiModalLoginComponent
                        showLogin={showLogin}
                        onSetLogin={onGetUrl}
                        onCancelLogin={setShowLogin}
                    />
                </>
            )}
            {id && (
                <ApiTestButtonComponent
                    {...other}
                    type={type}
                    size={size}
                    onRequest={onGetUrl}
                    
                />
            )}
        </div>
    );
};
