import {map, isArray} from 'lodash';

export const getConvertedObjectToArray = (data) => {
    if (!data) return data;    
    const source = isArray(data) ? data?.filter(item => item) : data;

    return map(source, (item, key) => {
        if (!item?.id) {
            item.id = key;
        }
        return item;
    });
}
