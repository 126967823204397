import {useMediaQuery} from 'react-responsive';
import {mediaResolutions} from '../../constant';

export function useResolutions() {
    const {
        XL,
        LG,
        MD,
        SM,
        DEFAULT
    } = mediaResolutions;

    const isXL = useMediaQuery({minWidth: XL});
    const isLG = useMediaQuery({minWidth: LG});
    const isMD = useMediaQuery({minWidth: MD});
    const isSM = useMediaQuery({minWidth: SM});
    const isXS = useMediaQuery({maxWidth: (DEFAULT - 1)});
    const isSMdown = useMediaQuery({maxWidth: (MD - 1)});
    const resolutions = {isXL, isLG, isMD, isSM, isXS, isSMdown};
    const screen = Object.keys(resolutions).find(k => resolutions[k]);

    return {
        ...resolutions,
        screen
    };
}
