import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useTrackClick } from '../../common';
import { useIntl } from 'react-intl';
import { useLocalePrefix } from '../../lang';

export const SubscriptionButtonComponent = (props) => {
    const {
        className = '',
        title,
        type = 'primary',
        block,
        size = 'medium',
        // ...other
    } = props;

    const { formatMessage } = useIntl();
    const buttonLabel = title ? title : formatMessage({ id: 'payment_title' });
    const linkClassName = classnames('', {
        'link-btn': type === 'link',
        'link-btn_primary': type === 'primary',
        'ant-btn ant-btn-secondary link-btn-secondary': type === 'secondary',
        'ant-btn ant-btn-tertiary link-btn-tertiary': type === 'tertiary',
        'ant-btn-lg': size === 'large',
        'ant-btn-sm': type === 'small',
        'block-el': block,
    });
    const [localePrefix] = useLocalePrefix();
    const trackClick = useTrackClick();

    return (
        <div className={`subscription-button-component wrapper_inner-section ${className}`}>
            <Link
                to={`${localePrefix}/payments`}
                className={linkClassName}
                onClick={() => {
                    trackClick(
                        'click-payments',
                        {},
                        `https://unschooler.me${localePrefix}/`
                    );
                }}
            >
                <span>{buttonLabel}</span>
            </Link>
        </div>
    );
};
