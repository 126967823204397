
import './scss/index.scss';
import React, { useRef, useState } from 'react';
import { Input, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { AiLinkContainer } from '../AiLink.container'
const { TextArea } = Input;

export const AddText = ({
    onAddSource,
    index,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const [showModal, setShowModal] = useState(true);
    const [textValue, setTextValue] = useState();
    let object = { key: "url", type: 'TEXT', source: textValue?.substring(0,40) || "text", title: `Article: ${textValue?.substring(0,40)}` || `Article`, raw: textValue || '', icon: "text" }

    const onSave = () => {
        onAddSource(object, index);
        setShowModal(false)
    }
    const handleTextChange = (e) => {
        setTextValue(e.target.value);
    };

    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: 'form_link_placeholder' })}
                onCancel={() => onSave()}
                onOk={() => onSave()}>
                <TextArea
                    defaultValue={textValue}
                    onChange={handleTextChange}
                    placeholder={formatMessage({ id: "form_text_data_placeholder" })}
                    className="ai-bot-component__field_counter "
                    maxLength={30000}
                    showCount
                    autoSize={{ minRows: 2, maxRows: 10 }}
                />
            </Modal>
        </>
    );
};
