import React from 'react';
import classNames from 'classnames';
import {UserProfile} from '../UserProfile';

export const ProfileColumn = (props) => {
    const {
        user,
        reviews,
        teacher,
        isAdmin,
        event,
        me,
        updatedData,
        handleChange,
        handleChangeSchedule,
        handleReview,
        onAskReview,
        isMentorOrAdmin,
        showReviewButton,
        links,
        ...other
    } = props;

    const backgroundStyle = classNames('profile-wrapper ', {
        'blue-bg': user?.role?.id === 1,
    });

    return (
        <div className='profile-column '>
            {user && (
                <div className={backgroundStyle}>
                    <UserProfile
                        user={user}
                        teacher={teacher}
                        isAdmin={isAdmin}
                        me={me}
                        updatedData={updatedData}
                        onUpdate={handleChange}
                        inEvent={event}
                    />
                </div>
            )}
        </div>
    );
};
