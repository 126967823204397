import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openNotification } from '../notification/Notification.component';
import {
    PaidComponent,
    Svg,
    LoginContainer,
    useTrackClick,
} from '../common';
import { EmbedCopyComponent } from '../common/EmbedCopy.component/EmbedCopy.component';

import { FirebaseContext } from '../../context/firebase/firebaseContext';
import { useLocalePrefix } from '../lang';
import { ProfileLink, useIsAllow } from '../common';

export const CertificationActions = (props) => {
    const { certificate, location, type, isStickToTop, userIsAllow, workspace, isEmbed, stepId, ...other } = props;
    const { user, loginUser } = useContext(FirebaseContext);
    const [localePrefix] = useLocalePrefix();
    const trackClick = useTrackClick();
    const { formatMessage } = useIntl();
    const [showLogin, setShowLogin] = useState();

    const { history } = useHistory();

    const creator = user?.id === certificate.creatorId;
    const URL = `https://${window.location.hostname}`;
    const EDITURL = `${localePrefix}/create-event/${certificate.event?.id}`

    return (
        <div className='Answer__actions Answer__actions_horizontal '>
            <div
                className='Answer__actions_top'
                onClick={(e) => e.preventDefault()}
            >
                {userIsAllow && (
                    <>
                        <EmbedCopyComponent
                            workspace={workspace}
                            url={`${URL}${localePrefix}/embed/certificates/${certificate?.id}`}
                            title={certificate?.title}
                            searchIsExist={history?.location?.search}
                            userIsAllow={userIsAllow}
                        />
                        <Link
                            to={{
                                pathname: userIsAllow ? `${EDITURL}` : `${localePrefix}/payments`,
                                state: {
                                    isEdit: true,
                                    workspace: workspace,
                                    tabProp: 3
                                },
                            }}
                            className=' ant-btn link-btn-tertiary ant-btn ant-btn-tertiary ant-btn-sm'
                        >
                            <Svg
                                name=':icon/edit'
                                className='icon_24'
                            />
                            <span className='smallLabel'>
                                {formatMessage({ id: 'edit' })}
                            </span>
                            {!userIsAllow && <PaidComponent />}
                        </Link>

                    </>
                )}

                {/* <CopyToClipboard
                    text={`${URL}${localePrefix}/certificates/${certificate?.id}`}
                    onCopy={() => {
                        openNotification('topLeft', {
                            title: formatMessage({
                                id: 'notif_copy_link_title',
                            }),
                            description: formatMessage({ id: 'notif_copy_link_description' }),
                            icon: '🤘',
                            duration: 3,
                        });
                    }}
                >
                    <Button
                        type='text'
                        className='link-btn_light answer__actions_bottom_btn btn_toggle'
                        onClick={() =>
                            trackClick(
                                'click-share',
                                `${URL}${localePrefix}/certificates/${certificate?.id}`,
                                `${URL}${localePrefix}/certificates/${certificate?.id}`
                            )
                        }
                    >
                        <Svg
                            name=':icon/share'
                            className='icon_20'
                        />

                    </Button>
                </CopyToClipboard> */}
                {!creator &&
                    <ProfileLink
                        user={certificate?.event?.creator}
                        isLink={false}
                        onClick={() =>
                            trackClick(
                                "click-profile",
                                certificate?.creatorId,
                                `${URL}${localePrefix}/profile/${certificate?.creatorId}`
                            )
                        }
                    />
                }
            </div>
            <LoginContainer
                showLogin={showLogin}
                setCancelLogin={setShowLogin}
            />
        </div>

    );
};