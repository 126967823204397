import {gql} from "@apollo/client";

export const EditEventsListsOrder = gql`
    mutation EditEventsListsOrder($input: [EventsListOrderInput]) {
        editEventsListsOrder(input: $input) {
            id
            order
        } 
    }
`;
