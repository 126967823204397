import React from 'react';
import { CertificateComponent } from '../Certificate.component/Certificate.component';

export const CertificateContainer = (props) => {
    const {
        certificate,
        ...other
    } = props;

    return (
        <CertificateComponent
            {...other}
            certificate={certificate}
        />
    );
};
