import React, {useState} from 'react';
import {Form, Input, Spin} from 'antd';
import {StripeProductDataContainer} from '../StripeProductData.container';
import { useIntl } from 'react-intl';

export const StripeWorkspaceSubscriptionComponent = ({loading, stripeProductId, ...other}) => {
    const [productId, setProductId] = useState(stripeProductId);
    const { formatMessage } = useIntl();
    
    return (
        <Spin spinning={loading}>
            <div>
                <hr/>
                <h2>{formatMessage({id: 'subscription_settings_title'})}</h2>
                <Form.Item label="Stripe Product ID"
                           name="stripeProductId"
                           onChange={(e) => setProductId(e.target.value)}
                           style={{maxWidth: '400px'}}>
                    <Input/>
                </Form.Item>
                <StripeProductDataContainer {...other}
                                            stripeProductId={productId}/>
            </div>
    </Spin>)
}