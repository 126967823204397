
import './scss/index.scss';
import React, { useState } from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import { VimeoInput } from '../../common/ChangeVideo.container/VimeoInput';

export const AddVimeoLink = ({
    onAddSource,
    index,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const [showModal, setShowModal] = useState(true);
    const [urlValue, setUrlValue] = useState();
    const [urlName, setUrlName] = useState();

    const onVideoDataChange = ({ urlName, sourceText }) => {
        setUrlValue(sourceText);
        setUrlName(urlName);
    };

    // const onChange = async () => {
    //     await setUrl(null);
    //     await setShowError(null)
    //     const val = inputRef.current.input.value;
    //     const id = val.split('/').find((item) => Number.isInteger(parseInt(item)));
    //     setUrl(inputRef.current.input.value)

    //     if (id) {
    //         try {
    //             const respCaptions = await getVimeoCaptions({ variables: { id } });
    //             if (respCaptions) {
    //                 if (respCaptions?.error) { setShowError(respCaptions?.error.message); }
    //                 const sourceText = respCaptions?.data?.getVimeoCaptions?.captions;
    //                 setUrlValue(sourceText?.substring(0, 40000))
    //                 setUrlName(val)
    //             }
    //         } catch (error) {
    //             setShowError(error?.message || null)
    //         }
    //     }
    // }
    const onSave = () => {
        onAddSource(object, index);
        setShowModal(false)
    }
    let object = { key: "vimeo", type: 'VIMEO', source: urlName || '', title: `My Vimeo video`, raw: urlValue || '', icon: "video" }
    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: 'form_video_placeholder' })}
                onCancel={() => onSave()}
                onOk={() => onSave()}
            >

                <VimeoInput
                    onVideoDataChange={onVideoDataChange}
                    {...other}
                />
            </Modal>
        </>
    );
};
