import React  from 'react';

import {RatingCard} from './RatingCard';

export const RatingWidget = ({
    subjectRating,
    type,
    icon
}) => {

    return (
        <div className='widget'>
            {/* {type === 'card' && subjectRating?.users?.length > 0 &&  (
                <ul className='cards'>
                    
                    {subjectRating?.users?.map((userData) => (
                        <RatingCard userData={userData}
                                    key={userData?.user?.googleUid}
                                    icon={icon}/>
                    ))}
                </ul>
            )} */}
            {/* {!type && subjectRating?.users?.length > 0 && <StudentsTable userList={subjectRating.users} />} */}
        </div>
    );
};
