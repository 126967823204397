import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { RelatedEventsContainer } from '../../event/RelatedEvents.container/RelatedEvents.container';
import { useLocalePrefix } from '../../lang';
import { DripExplanation } from '../../common';
import { JoinCourse } from '../../event/JoinCourse.component/JoinCourse.component';

export const TaskPlaceholderComponent = ({ task, event, user, type, isEmbed, workspace, ...other }) => {
    const formatMessage = useIntl().formatMessage;
    return (
        <div className='task_overlay' >
            {
                type === 'join' ? (
                    <>
                        <JoinCourse event={event} workspace={workspace} />
                        <RelatedEventsContainer
                            relatedEvents={[{ ...event, creator: task.creator }]}
                            user={user}
                            size={100}
                        />

                    </>

                ) : type === 'drip' ? (
                    <>
                        <h2>
                            <DripExplanation formatMessage={formatMessage} event={event} />
                        </h2>
                    </>
                ) : null
            }
        </div>




    )
};



