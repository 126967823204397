import './scss/index.scss';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {useLocalePrefix} from '../../../lang';

export const RequestsCounterComponent = (props) => {
    const {
        counter
    } = props;
    const [localePrefix] = useLocalePrefix();

    if (!counter) return null;

    return (
        <div className="requests-counter-component">
            <NavLink to={`${localePrefix}/admin/payments`}
                     className="admin-payments-link">
                {counter}
            </NavLink>
        </div>
    )
}
