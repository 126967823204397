import { useEffect } from "react";
import firebase from "../model/firebase"

export const Logout = () => {
    useEffect(() => {
        firebase.auth().signOut().then();
        window.location = '/';
      }, []);

    return (
      null
    )
}