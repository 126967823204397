import "./scss/index.scss";
import React, { useState } from "react";
import { Button, Dropdown, Menu } from "antd";

import { LessonToPrint } from "./LessonToPrint";
import { CourseToPrint } from "./CourseToPrint";
import { Svg, LoaderComponent, PaidComponent } from '../../common';

export const TaskToPrint = ({ task, event, isAllowCreator, ...other }) => {
    const [activePrint, setActiveState] = useState();
    const items = event?.tasks ?
        [{ key: 'lesson', label: ("Lesson") },
        { key: 'course', label: ("Course") }] :
        [{ key: 'lesson', label: ("Lesson") }];

    const handleResetActivePrint = () => setActiveState(null);

    const onClick = ({ key }) => {
        setActiveState(key);
    };

    const menu = (
        <div className="menu-wrapper">
            <Menu onClick={onClick}>
                {items.map((item, i) => (
                    <Menu.Item key={`${item.key}_${i}`}>
                        <Button size="small" type='text' >
                            <Svg
                                name=':icon/download'
                                className='icon_24'
                            />
                            <span >{item.label}</span>
                        </Button>
                    </Menu.Item>
                ))}
            </Menu>
        </div>
    );

    return (
        <div>
            <Dropdown
                overlay={menu}
                placement="bottom"
                disabled={!isAllowCreator}
            >
                <Button size="small" type='text'
                    className='link-btn_light'>
                    <Svg
                        name=':icon/download'
                        className='icon_24'
                    />
                    <span  className='smallLabel hide-on-mobile'>PDF</span>
                </Button>
            </Dropdown>
            {!isAllowCreator && <PaidComponent />}

            <div style={{ display: "none" }}>
                {activePrint === 'lesson_0' && (
                    <LessonToPrint task={task}
                                   event={event}
                                   onReset={handleResetActivePrint}
                                   isAllowCreator={isAllowCreator}
                                   {...other} />
                )}
                {activePrint === 'course_1' && (
                    <CourseToPrint event={event}
                                   onReset={handleResetActivePrint}
                                   isAllowCreator={isAllowCreator}
                                   {...other}/>
                )}
            </div>
            {activePrint && <LoaderComponent type="overlay"/>}
        </div>
    );
};
