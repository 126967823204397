import {useCallback} from 'react';
import {useLazyQuery, gql} from '@apollo/client';

export const useGetContentByUrl = (query = CONTENT_BY_URL) => {
    const [getContent, { data, loading, error }] = useLazyQuery(query);

    const getRemoteContent = useCallback((url) => {
        return getContent({
            variables: {
                input: {
                    url
                }
            }
        });
    }, [getContent]);

    return {
        getRemoteContent,
        loading, error,
        data
    }
};

const CONTENT_BY_URL = gql`
    query Query($input: getContentByUrlInput!) {
        gerContentByUrl(input: $input)
    }
`;
