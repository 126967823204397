import  {useCallback} from 'react';

import {useAnalytics} from '..';

export const useTrackClick = () => {
    const {eventTrack} = useAnalytics();

    const callBack = useCallback((action, clickText, clickUrl) => {
        eventTrack(action, {
            action: action,
            clickId: action,
            clickText: clickText,
            clickUrl: clickUrl,
        });
       
    }, [eventTrack]);

    return callBack;
};
