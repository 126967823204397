import './scss/index.scss';
import React, { useState } from "react";
import { Button } from 'antd';
import { getStorageManager } from '../../common/utils';
const STORAGE_KEY = 'cookies';

export const CookieComponent = () => {
    const { getStorageData, setStorageData } = getStorageManager();
    const [cookies, setCookies] = useState(getStorageData(STORAGE_KEY))

    const saveToStorage = () => {
        setStorageData(STORAGE_KEY, true);
        setCookies(true);
    }
    return !cookies && (
        <section className="cookiePreferencesBanner">
            <section className="cookiePreferencesBanner_content">
                <div className="cookiePreferencesBanner_description">
                    <p>
                        We use <a href="https://unschooler.me/policy/" target="_blank" rel="noreferrer" >cookies
                        </a>&nbsp;to offer you a better service.
                    </p>
                </div>
                <nav className="cookiePreferencesBanner_button">
                    <Button size='small' type='tertiary' onClick={saveToStorage} aria-label="Accept all cookies">Accept all</Button>
                </nav>
            </section>
        </section>
    )
}