import React from 'react';
import {RequestsContainer} from '../Components/admin/payments';

export const AdminPayments = () => {

    return (
        <main className={`admin-section`}>
            <RequestsContainer/>
        </main>
    );
};
