import React, { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Tabs } from 'antd';
import { TitleComponent, MetaComponent } from '../Components/common';
import { StudentsWorkspaceContainer } from '../Components/rating/StudentsWorkspace.container';
import { PermissionsPreview } from "../Components/roles/PermissionsPreview.component/PermissionsPreview.component";
import { InvitesFormContainer } from '../Components/workspace/InvitesForm.container/InvitesForm.container'
import { InvitedUsersWorkspaceContainer } from '../Components/workspace/InvitedUsersWorkspace.container/InvitedUsersWorkspace.container';

const { TabPane } = Tabs;

export const Students = ({ workspace, ...other }) => {
    const { formatMessage } = useIntl();
    const [filter, setFilter] = useState('User');

    const metaMemo = useMemo(() => {
        // const title = formatMessage({ id: 'meta_title_rating' });
        const description = formatMessage({ id: 'meta_description_rating' });
        // const url = 'https://unschooler.me/students/';

        const meta = {
            // meta_title: title,
            meta_description: description,
        };
        const og = {
            // title: title,
            description: description,
            // url: url,
        };

        const twitter = {
            // tw_title: title,
            // tw_url: url,
            tw_description: description,
        };

        return { meta, og, twitter };
    }, [formatMessage]);

    const tabs = [
        { id: 'students', key: 'User' },
        { id: 'admins', key: 'Team' },
    ];

    const getColumnKeys = (filter) => {
        switch (filter) {
            case 'User':
                return ['checkbox', 'user', 'permission', 'startDate', 'lastVisit', 'events', 'results', 'email', 'deleteMember'];
            default:
                return ['checkbox', 'user', 'permission', 'startDate', 'lastVisit', 'events', 'email', 'deleteMember'];
        }
    };

    return (
        <>
            <MetaComponent
                meta={metaMemo.meta}
                og={metaMemo.og}
                twitter={metaMemo.twitter}
            />

            <div className='page'>
                <TitleComponent title={formatMessage({ id: 'members_statistics' })} />

                <Tabs
                    defaultActiveKey={filter}
                    onChange={key => setFilter(key)}
                >
                    {tabs.map(tab => (
                        <TabPane tab={formatMessage({ id: tab.id })} key={tab.key}>
                            <InvitesFormContainer/>
                            <InvitedUsersWorkspaceContainer
                                workspace={workspace}
                                formatMessage={formatMessage}
                                {...other}
                            />

                            <div className='wrapper_content-section'>
                                <StudentsWorkspaceContainer
                                    filter={filter}
                                    workspace={workspace}
                                    columnKeys={getColumnKeys(tab.key)}
                                    {...other}
                                />
                            </div>
                            {tab.key !== 'User' &&
                                <div className='wrapper_outer-section'>
                                    <PermissionsPreview roleKeys={tab.key} workspace={workspace} />
                                </div>
                            }
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        </>
    );
};