import React from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { useGetSuggestions } from '../hooks/useGetSuggestions'
import { getTitle, useProgress } from '../../common';

export const SuggestionsGeneratorContainer = ({ rawdata, dataDifference, setSuggestions, type = 'suggestionsLessonsDistribution' }) => {
  const { getSuggestions } = useGetSuggestions()
  const { inProgress, handleChangeProgress } = useProgress();
  const { formatMessage } = useIntl();
  const onGenerateSuggestion = async () => {
    handleChangeProgress({
      message: formatMessage({ id: 'progress_description_suggestions' }),
      key: 'suggestionsStart'
    })




    let prompt;
    switch (type) {
      case 'suggestionsLessonsDistribution':
        prompt = `The type distribution of lessons: ${JSON.stringify(dataDifference)}.\n Return JSON with 4 suggestions list on how to Change the type of any 4 lessons to improve distribution score, so that we can be close to a 0 score. \n If there is a negative "engaging", then generate quiz or practical assessment prompt,\n if "hard", then short practical assessment prompt with the topic, if "easy" then quiz prompt with question topic, if "passive" then text description prompt.\n\nResponse in the following JSON format: { suggestions: [{id: insert lesson id, problem: "formulate the distribution problem the suggestion is solving", suggestion: "insert the suggestion on the content topic and goal"}]}.`;
        break;
      case 'suggestionsLessonResult':
        prompt = `Analyze this student answer in a lesson.\n Provide suggestions for improving the lesson based on the student's answer. Response in the following JSON format: { suggestions: [{id: lesssonId, problem: "formulate the short problem", suggestion: "insert the suggestion that solve the problem on the lesson"}]}.`;
        break;
      default:
        prompt = `Analyze the following data: ${JSON.stringify(rawdata)}.\n Provide general suggestions for improvement. Return the suggestions in JSON format.`;
        break;
    }

    let sugg = await getSuggestions(rawdata, prompt);

    const enhancedSuggestions = sugg.map(suggestion => {
      const matchingLesson = rawdata.find(lesson => {
        return lesson?.id === suggestion.id;
      });
      return {
        ...suggestion,
        title: matchingLesson ? getTitle(matchingLesson.title) : '',
        type: matchingLesson ? matchingLesson.type : '',
        taskId: matchingLesson ? matchingLesson.taskId : ''
      };
    });
    setSuggestions(enhancedSuggestions);
    handleChangeProgress({ key: 'suggestionsStart' })

  }
  return (
    <Button onClick={() => onGenerateSuggestion()} className='suggestions-button' size="small">
      ✷
    </Button>
  );
};