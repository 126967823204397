import React, { useState } from 'react';
import classnames from 'classnames';
import { ProgramsSubjectComponent } from '../ProgramsSubject.component/ProgramsSubject.component.js';

export const ProgramIntervalComponent = (props) => {
    const {
        selectedLevel,
        selectedSubjects,
        subjectsThemes,
        subjects,
        interval,
        isFirst,
        ...other
    } = props;

    const [isCollapsed, setIscollapsed] = useState(false);

    const blockClassName = classnames('program-subject', {
        'program-subject_collapsed': interval && isCollapsed
    });

    const intervalClassName = classnames('program-interval', {
        'program-interval_colored': isCollapsed
    });

    return (
        <>
            {/* {interval && <div className={intervalClassName} onClick={handleToogle}>
                {interval}
            </div>} */}
            <div className={blockClassName}>
                {subjectsThemes && subjectsThemes.map((subjectThemes, key) =>
                    <ProgramsSubjectComponent key={key}
                        selectedLevel={selectedLevel}
                        subjects={subjects}
                        subjectThemes={subjectThemes}
                        selectedSubjects={selectedSubjects}
                        isFirst={isFirst}
                        {...other} />)}
            </div>
        </>
    );
}