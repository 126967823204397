export const AI_CREDITS_PRICES = [
    // {
    //     price: 1,
    //     currency: 'usd',
    //     label: '15 credits',
    //     priceId: 'price_1PRWDNBcw3SKmQluaYN3MIi2',
    //     paymentType: 'aiCredits'
    // },
    {
        price: 30,
        currency: 'usd',
        label: '500 credits',
        priceId: 'price_1PFbfKBcw3SKmQlu4JSVjMW2',
        paymentType: 'aiCredits'
    },
    {
        price: 55,
        currency: 'usd',
        label: '1000 credits',
        priceId: 'price_1PFbgZBcw3SKmQluZi5AMGNy',
        paymentType: 'aiCredits'
    },
    {
        price: 270,
        currency: 'usd',
        label: '5000 credits',
        priceId: 'price_1PFbheBcw3SKmQlu6jvyX6yS',
        paymentType: 'aiCredits'
    }
]
export const FAQ_CREDITS = [
    {
        title: 'I have courses but I want to get AI credits features, how to use them?',
        description: 'If you have 50 courses available, your credits will be spent only on credit-exclusive features like Clone Voices or ElevenLabs Voices.',
        // link: 'https://unschooler.me/tutorials/course-creation-276501?step=238903',
    },
    {
        title: 'My courses have reached the limit, but I have credits left, can I create new courses?',
        description: 'If you reach your course plan\'s limit (e.g., 50 courses in their plan) but have credits left, you can generate an unlimited number of courses using credits after confirmation. All generations in old courses will be for credits until you obtain new courses.',
        // link: 'https://unschooler.me',
    },
    {
        title: 'When my monthly plan renews and I get new courses, how will AI credits be spent?',
        description: 'When new courses are added again (e.g., at the start of the billing period with 50 new courses), courses from the current plan will be used again and credits will only be spent on credit-exclusive features.',
        soon: true,
        // link: 'https://unschooler.me/tutorials/course-creation-276501?step=238906',
    },
]