import './scss/index.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Svg } from '../../common';
import { uiIcons } from '../../../images/uiIcons'
import {COVERS} from '../../constant';

export const GuadlineComponent = () => {
    return (
        <div className='guadline-component main'>

            <div>
            {Object.keys(uiIcons).map(key =>
            <>
                    <Svg name={key} className='menu-icon' />
                    <small>{key}</small>
            </>
                )}
            </div>
            {COVERS.map(key => (
                    <div>
                        <a href={key}  className='red'>{key}</a>
                    </div>
                ))
                }
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Primary buttons - Large
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='primary' size='large'>
                        <span>
                            <Svg name=':icon/ucoins' width={36} />
                            Primary button
                        </span>
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="primary" size="large"
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Primary buttons - Small
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='primary' size='small'>
                        <span>
                            <Svg name=':icon/ucoins' width={16} />
                            Primary button
                        </span>
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="primary" size="small"
                </div>
            </div>

            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Primary button
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='primary'>
                        <span>
                            <Svg name=':icon/ucoins' width={30} />
                            Primary button
                        </span>
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="primary", type="default" or without type
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Prymary button disabled
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='primary' disabled={true}>
                        <span>
                            <Svg name=':icon/ucoins' width={30} />
                            Primary button
                        </span>
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="primary", type="default" or without type
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Default button
                </div>
                <div className='guadline-component__item__view'>
                    <Button>Default button</Button>
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Secondary button
                </div>
                <div className='guadline-component__item__view'>
                    <Button>Secondary button</Button>
                </div>
                <div className='guadline-component__item__type'>
                    without type
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Default button disabled
                </div>
                <div className='guadline-component__item__view'>
                    <Button disabled={true}>Default button</Button>
                </div>
                <div className='guadline-component__item__type'>
                    without type
                </div>
            </div>

            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Default outline button
                </div>
                <div className='guadline-component__item__view'>
                    <Button className='btn_outline'>
                        Default outline button
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    className="btn_outline"
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Default outline button disabled
                </div>
                <div className='guadline-component__item__view'>
                    <Button className='btn_outline' disabled={true}>
                        Default outline button
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    className="btn_outline"
                </div>
            </div>

            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Secondary button
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='secondary'>Secondary button</Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="secondary"
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Secondary button disabled
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='secondary' disabled={true}>
                        Secondary button
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="secondary"
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Tertiary button
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='tertiary'>Tertiary button</Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="tertiary"
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Tertiary button disabled
                </div>
                <div className='guadline-component__item__view'>
                    <Button
                        type='tertiary'
                        className='btn_outline_dark'
                        disabled={true}
                    >
                        Tertiary button
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="tertiary" className="btn_outline_dark"
                </div>
            </div>

            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Tertiary outline button
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='tertiary' className='btn_outline_dark'>
                        Tertiary outline button
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="tertiary" className="btn_outline_dark"
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Tertiary outline button disabled
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='tertiary' disabled={true}>
                        Tertiary outline button
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="tertiary"
                </div>
            </div>

            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Button type link
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='link'>Link button</Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="link"
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Button type link disabled
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='link' disabled={true}>
                        Link button
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="link"
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Button type text
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='text'>Text button</Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="text"
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Button type text disabled
                </div>
                <div className='guadline-component__item__view'>
                    <Button type='text' disabled={true}>
                        Text button
                    </Button>
                </div>
                <div className='guadline-component__item__type'>
                    type="text"
                </div>
            </div>

            <hr />
            <h2>Links as buttons</h2>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Primary button
                </div>
                <div className='guadline-component__item__view'>
                    <Link className='link-btn_primary'>
                        <span>
                            <Svg name=':icon/ucoins' width={26} />
                            Primary button
                        </span>
                    </Link>
                </div>
                <div className='guadline-component__item__type'>
                    className='link-btn_primary'
                </div>
            </div>

            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Primary button - Large
                </div>
                <div className='guadline-component__item__view'>
                    <Link className='link-btn_primary ant-btn-lg'>
                        <span>
                            <Svg name=':icon/ucoins' width={36} />
                            Primary button
                        </span>
                    </Link>
                </div>
                <div className='guadline-component__item__type'>
                    className='link-btn_primary ant-btn-lg'
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Primary button - Small
                </div>
                <div className='guadline-component__item__view'>
                    <Link className='link-btn_primary ant-btn-sm'>
                        <span>
                            <Svg name=':icon/ucoins' width={20} />
                            Primary button
                        </span>
                    </Link>
                </div>
                <div className='guadline-component__item__type'>
                    className='link-btn_primary ant-btn-sm'
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Link component
                </div>
                <div className='guadline-component__item__view'>
                    <Link className='link-btn' to='/'>
                        Link button
                    </Link>
                </div>
                <div className='guadline-component__item__type'>
                    className="link-btn", only Link component
                </div>
            </div>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Link component large
                </div>
                <div className='guadline-component__item__view'>
                    <Link className='link-btn ant-btn-lg' to='/'>
                        Link button
                    </Link>
                </div>
                <div className='guadline-component__item__type'>
                    className="link-btn ant-btn-lg"
                </div>
            </div>

            <hr />
            <div className='guadline-component__item'>
                <div className='guadline-component__item__view'>
                    <Link
                        className='link-btn-secondary ant-btn ant-btn-secondary'
                        to='/'
                    >
                        <span>Secondary link as a button</span>
                    </Link>
                </div>
                <div className='guadline-component__item__type'>
                    className="link-btn-secondary ant-btn ant-btn-secondary"
                </div>
                <hr />
            </div>

            <h3>links</h3>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Link component small
                </div>
                <div className='guadline-component__item__view'>
                    <Link className='link-btn ant-btn-sm' to='/'>
                        Link button
                    </Link>
                </div>
                <div className='guadline-component__item__type'>
                    className="link-btn ant-btn-sm"

                </div>
            </div>
            <h3>links</h3>
            <div className='guadline-component__item'>
                <div className='guadline-component__item__name'>
                    Link button icon
                </div>
                <div className='guadline-component__item__view'>
                    <Link
                        to={`/payments`}
                        className='link-btn_light'
                    >
                        <Svg
                            name=':icon/download'
                            className='icon_24'
                        />

                    </Link>
                </div>
                <div className='guadline-component__item__type'>
                    className="link-btn ant-btn-sm"

                </div>
            </div>
        </div>
    );
};
