import {useCallback, useState} from "react";
import { useLazyQuery } from "@apollo/client";
import { GetTags } from "../graphQL/queries/GetTags";

const TAGS_KEY = 'unschooler-tags-key';
const PERIOD = 1000 * 60 * 60 * 24 * 7;
const TAGS_DATA = window.localStorage.getItem(TAGS_KEY);

export function useGetTags(variables, query = GetTags) {
    const [tags, setTags] = useState(null);
    const [getTags, { data, error, loading }] = useLazyQuery(query, {
        variables,
        fetchPolicy: 'cache-first'
    });

    const getLazyTags = useCallback(async (tagIds) => {
        const now = Date.now();
        if (TAGS_DATA) {
            const { data, timestamp } = JSON.parse(TAGS_DATA);
            if (data && (now - timestamp < PERIOD)) {
                setTags(data);
                return data;
            }
        }
        const resp = await getTags({
            variables: {
                ...(tagIds && {tagIds})
            }
        });
        const data = resp?.data?.tags;
        if (!tagIds && data) {
            window.localStorage.setItem(TAGS_KEY, JSON.stringify({
                data,
                timestamp: now
            }));
        }
        return data;
    }, [getTags]);

    return {
        tags: tags || data?.tags,
        error, loading,
        getLazyTags
    };
}
