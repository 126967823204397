import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { LOCALES } from '../../../lang';
import { useAnalytics } from '../hooks';
import { COVER_PICTURES } from '../../constant';

const BASE_URL = 'https://unschooler.me';

export const MetaComponent = (props) => {
    const { pathname } = useLocation();
    const baseUrl = BASE_URL + pathname;
    const { canonical = baseUrl, meta = {}, og = {}, twitter = {} } = props;

    const { formatMessage } = useIntl();
    const { pageViewTrack } = useAnalytics();
    const currentTitle = useRef(null);
    const DEFAULT_DESCRIPTION = formatMessage({ id: 'meta_default_description' });

    const {
        meta_title,
        meta_keywords,
        meta_description = formatMessage({
            id: 'meta_description_default_primary',
        }),
        meta_author = 'Unschooler',
        meta_icon = "/favicon.ico"
    } = meta;

    const {
        type = 'website',
        title = formatMessage({ id: 'meta_title_default' }),
        image = COVER_PICTURES.MAIN,
        description = DEFAULT_DESCRIPTION,
        url = baseUrl,
        locale = LOCALES.ENGLISH,
    } = og;


    // Create a manifest object
    const manifest = {
        short_name: meta_title,
        name: meta_title,
        icons: [
            {
                src: "favicon.ico",
                sizes: "64x64 32x32 24x24 16x16",
                type: "image/x-icon"
            },
            {
                src: "logo192.png",
                type: "image/png",
                sizes: "192x192"
            },
            {
                src: "logo512.png",
                type: "image/png",
                sizes: "512x512"
            }
        ],
        start_url: ".",
        display: "standalone",
    };

    const manifestJson = JSON.stringify(manifest);
    const manifestHref = `data:application/manifest+json,${encodeURIComponent(manifestJson)}`;


    // const {
    //     tw_title = formatMessage({id: 'meta_title_default'}),
    //     tw_url = baseUrl,
    //     tw_site = '@unschooler_me',
    //     tw_site_name = 'Unschooler.me',
    //     tw_creator = '@unschooler_me',
    //     tw_card = 'summary_large_image',
    // } = twitter;

    useEffect(() => {
        if (meta_title && meta_title !== currentTitle.current) {
            currentTitle.current = meta_title;
            setTimeout(() => {
                pageViewTrack(pathname);
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta_title]);

    return (
        <Helmet>
            <meta name='msapplication-TileColor' content='#ffffff' />
            <meta name='theme-color' content='#ffffff' />
            <meta property='author' content={meta_author} />
            <link rel='canonical' href={canonical} />
            <link rel="icon" href={meta_icon}  />
            <link rel="apple-touch-icon" href={meta_icon} />
            <link rel="manifest" href={manifestHref} />

            {meta_title && <title>{meta_title}</title>}
            {meta_keywords && <meta name='keywords' content={meta_keywords} />}
            {meta_description && (
                <meta name='description' content={meta_description} />
            )}

            {type && <meta property='og:type' content={type} />}
            {(meta_title || title) && (
                <meta property='og:title' content={meta_title || title} />
            )}
            {image && <meta property='og:image' content={image} />}

            {(description || meta_description) && (
                <meta
                    property='og:description'
                    content={meta_description || description}
                />
            )}
            {url && <meta property='og:url' content={url} />}
            {locale && <meta property='og:locale' content={locale} />}
            {image && <meta property='twitter:image' content={image} />}
            {url && (
                <meta
                    property='twitter:description'
                    content={meta_description}
                />
            )}

            {/* {(meta_title || tw_title) && (
                <meta
                    property='twitter:title'
                    content={meta_title || tw_title}
                />
            )} */}
            {/* {tw_url && <meta property='twitter:url' content={tw_url} />} */}
            {/* {tw_site && <meta property='twitter:site' content={tw_site} />}
            {tw_site_name && (
                <meta property='twitter:site_name' content={tw_site_name} />
            )}
            {tw_creator && (
                <meta property='twitter:creator' content={tw_creator} />
            )}
            {tw_card && <meta property='twitter:card' content={tw_card} />} */}
        </Helmet>
    );
};
