import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { SkillsTreeComponent } from '../skilsTree.component';
// import { useMapSkillsTagToTag } from '../../common/graphQL';

export const SkillsTreeContainer = (props) => {
    const { skills, specs, onSkillsRendered, user, selectedSpec, ...other } = props;
    const [skillsTree, setSkillsTree] = useState(null);
    const { subjects } = skills || {};
    // const { mapTags, getMapSkillsTagToTag } = useMapSkillsTagToTag();
    let mapRange = (value, low1, high1, low2, high2) => {
        return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
    };

    const createLinks = (edges, nodes) => {
        subjects?.forEach((object) => {
            object?.tags?.forEach((tag) => {
                let findThisNode = _.find(nodes, [
                    'id',
                    `${tag.id}`,
                ]);

                let findThisEdge =
                    findThisNode &&
                    (_.find(edges, [
                        'id',
                        `${tag.id}${findThisNode.id}`,
                    ]) ||
                        _.find(edges, [
                            'id',
                            `${findThisNode.id}${tag.id}`,
                        ]));
                if (findThisNode && !findThisEdge)
                    edges.push({
                        data: {
                            source: `${tag.id}`,
                            target: findThisNode.id,
                            strength: 2,
                            id: `${tag.id}${findThisNode.id}`,
                        },
                        id: `${tag.id}${findThisNode.id}`,
                    });
            });
        });
    };

    const searchEges = async () => {
        let edges = [];
        let nodes = [];
        let clusters = [];

        for (const subjectObject of subjects) {
            let clusterSub = [];

            if (subjectObject.tags.length > 0) {
                subjectObject?.tags?.forEach((tag) => {
                    // create edges for sub - tag
                    edges.push({
                        data: {
                            source: `${subjectObject.id}`,
                            target: `${tag.id}`,
                            strength: 2,
                            id: `${subjectObject.id}${tag.id}`,
                        },
                    });

                    // create clusters for subject
                    clusterSub.push(`${tag.id}`);
                    if (!clusterSub.includes(`${subjectObject.id}`))
                        clusterSub.push(`${subjectObject.id}`);

                    // create nodes tags
                    if (!nodes.find((el) => el.id === `${tag.id}`)) {
                        nodes.push({
                            data: {
                                id: tag.id,
                                label: tag?.title || '',
                                counter: Math.floor(
                                    mapRange(tag.countResults, 1, 20, 10, 40)
                                ),
                                subjectColor:
                                    tag.countResults > 0
                                        ? subjectObject?.color
                                        : '#666666',
                                subjectColorNative: subjectObject?.color,
                                subjectTitleColor: '#666',
                                subjectTitle: subjectObject?.title,
                                // fontSize: Math.floor(mapRange(tags.length, 1, 40, 10, 1)),
                                fontSize: 8,
                                link: `https://unschooler.me/tutorials/tag/${tag.id}`,
                            },
                            classes: 'tag',
                            id: `${tag.id}`,
                        });
                    }
                });
            }
            clusters.push(clusterSub);

            // create subjectnodes
            const specIndex = _.findIndex(subjects, function () {
                return subjects.find((subj) => subj.id === subjectObject.id);
            });

            let imageSource =
                subjectObject?.icon === 'default'
                    ? `https://raw.githubusercontent.com/doraby/unschooler/main/default_${subjects[specIndex]
                        ? subjects?.[specIndex]?.title
                        : ''
                    }_full.svg`
                    : `https://raw.githubusercontent.com/doraby/unschooler/main/${subjectObject?.icon}_full.png`;

            nodes.push({
                data: {
                    id: subjectObject.id,
                    label: subjectObject?.title || '',
                    // label: '#666666',
                    counter: Math.floor(
                        mapRange(subjectObject.countResults, 1, 20, 10, 40)
                    ),
                    subjectColor:
                        subjectObject.count > 0
                            ? subjectObject?.color
                            : '#666666',
                    subjectColorNative: subjectObject?.color,
                    subjectTitleColor: '#666',
                    subjectTitle: subjectObject?.title,
                    image:
                        subjectObject.countResults > 0
                            ? imageSource
                            : `https://raw.githubusercontent.com/doraby/unschooler/main/default_full.png`,
                    fontSize: 8,
                    link: `https://unschooler.me/tutorials/subject/${subjectObject?.id}`,
                },
                classes: 'subject',
                id: `${subjectObject.id}`,
            });
        }

        setSkillsTree({ edges, nodes, clusters });
        // createLinks(edges, nodes);
        if (!edges.length && !nodes.length) {
            onSkillsRendered(true);
        }
    };
    const searchEgesWithoutSubject = () => {
        let edges = [];
        let nodes = [];
        let clusters = [];

        for (const subjectObject of subjects) {
            let clusterSub = [];

            if (subjectObject.tags.length > 0) {
                subjectObject?.tags?.forEach((tag) => {
                    // create nodes tags
                    if (!nodes.find((el) => el.id === `${tag.id}`)) {
                        nodes.push({
                            data: {
                                id: tag.id,
                                label: tag?.title || '',
                                counter: Math.floor(
                                    mapRange(tag.countResults, 1, 20, 10, 40)
                                ),
                                subjectColor:
                                    tag.countResults > 0
                                        ? subjectObject.color
                                        : '#666666',
                                subjectColorNative: subjectObject?.color,
                                subjectTitleColor: '#666',
                                subjectTitle: subjectObject?.title,
                                fontSize: 8,
                                link: `https://unschooler.me/tutorials/tag/${tag.id}`,
                            },
                            classes: 'tag',
                            id: `${tag.id}`,
                        });
                    }
                });
            }
            clusters.push(clusterSub);
        }

        setSkillsTree({ edges, nodes, clusters });
        createLinks(edges, nodes);
        if (!edges.length && !nodes.length) {
            onSkillsRendered(true);
        }
    };

    useEffect(() => {
        if (selectedSpec) {
            subjects && specs && searchEges();
        } else {
            subjects && searchEgesWithoutSubject();
        }
    }, [subjects, specs]);

    // useEffect(() => {
    //     (async () => {
    //         selectedSpec?.id && await getMapSkillsTagToTag({
    //             variables: {
    //                 specId: selectedSpec?.id
    //             }
    //         });
    //     })();
    // }, []);

    return (
        <div className='widget'>
            {skillsTree?.nodes && (
                <SkillsTreeComponent
                    {...other}
                    skillsTree={skillsTree}
                    onSkillsRendered={onSkillsRendered}
                    user={user}
                    selectedSpec={selectedSpec}
                />
            )}
        </div>
    );
};
