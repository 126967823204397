import React, { useEffect } from "react";
import {ProgramsThemesContainer} from "../ProgramsThemes.container";

export const ProgramsSubjectComponent = (props) => {
    const {
        subjectThemes,
        ...other
    } = props;

    return (
        <div className="program-subject__item">
            <ProgramsThemesContainer {...other}
                                     subject={subjectThemes}
            />
        </div>
    );
};
