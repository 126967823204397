import React from 'react';

import {CorrectSkillsContainer} from '../Components/skillsTree';

export const CorrectSkills = (props) => {
    return (
        <div className='page'>
            <h1>Edit skill </h1>
            <CorrectSkillsContainer {...props} />
        </div>
    );
};
