import React from 'react';
import { Link } from 'react-router-dom';
import { useFromEmail } from '../../resend';

export const EmailPreviewComponent = ({list, emailHtml, workspace, formatMessage }) => {
    const { from } = useFromEmail();
    return (
        <>
            <div className='invite_preview wrapper_content-section'>
                <p><strong>From:</strong> {from}</p>
                <p><strong>To:</strong> {list}</p>
                <p><strong>Preview:</strong></p>
                <div dangerouslySetInnerHTML={{ __html: emailHtml }} />

            </div>
            {workspace?.resend?.status !== 'verified' &&
                <p className='text_secondary wrapper_content-section'>
                    {formatMessage({ id: 'send_invitation_text' })}
                    <Link to="/">{formatMessage({ id: 'form_workspace_settings' })}</Link>
                </p>
            }
        </>
    )
};