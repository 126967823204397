
import './scss/index.scss';
import React, { useRef, useState } from 'react';
import { Input, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { AiLinkContainer } from '../AiLink.container'
const { TextArea } = Input;

export const AddUrl = ({
    onAddSource,
    index,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const [showModal, setShowModal] = useState(true);
    const [urlValue, setUrlValue] = useState();
    const [urlName, setUrlName] = useState();
    const onSave =() => {
        onAddSource(object, index);
        setShowModal(false)
    }
    let object ={ key: "url", type: 'TEXT', source: urlName || '', title: `${urlName}`, raw: urlValue || '', icon: "text" }

    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: 'form_link_placeholder' })}
                onCancel={() => onSave()}
                onOk={() => onSave()}>
                {urlValue && (
                    <TextArea
                        defaultValue={urlValue}
                        placeholder={formatMessage({ id: "form_text_data_placeholder" })}
                        className="ai-bot-component__field_counter "
                        maxLength={30000}
                        showCount
                        style={{ minHeight: 170 }}
                        autoSize={{ minRows: 2, maxRows: 10 }}
                    />
                )}         

                <AiLinkContainer
                    setUrlName={setUrlName}
                    onUpdate={setUrlValue}
                />

            </Modal>
        </>
    );
};
