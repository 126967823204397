import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { PaidComponent } from '../../common';

export const AiGenerateCourseButtonComponent = ({ event, userIsAllow, }) => {
    const { formatMessage } = useIntl();
    const { handleGenerateCourse, loadingCourse } = useContext(FirebaseContext);
    let currentLink = window.location.href;

    return (
        <>
            <Button
                type="secondary"
                onClick={() => {
                    if (userIsAllow) {
                        handleGenerateCourse(event, currentLink)
                    } else {
                        window.location.href = `/payments`
                    }
                }
                }
                disabled={loadingCourse || event?.tasks?.length > 20}
                className='btn-left'
                block
            >
                <span
                    className='menu-icon_type margin_right'
                    style={{ fontSize: 26 }}
                >✦</span>1.{' '}
                {!loadingCourse ? formatMessage({ id: 'ai_generate_tasks_button' }) : formatMessage({ id: 'course_generating' })}
                {!userIsAllow && <PaidComponent />}
            </Button>
            {event?.tasks?.length > 20 ?
                <small className="error">{formatMessage({ id: 'generate_and_info_limit' })}</small>
                :
                <small>{formatMessage({ id: 'generate_and_info_button' })} {formatMessage({ id: 'generate_and_info_warning' })}</small>
            }
        </>
    )
}