import {remoteConfig} from '../../../model/firebase';
import {getCacheManager} from './getCacheManager';

const CACHE_KEY = 'remote-config';
const {hasCache, getCache, setCache} = getCacheManager();

export const getConfigByKey = async (key) => {
    const isCache = hasCache(CACHE_KEY);
    if (!isCache) {
        await remoteConfig.fetchAndActivate();
        const data = remoteConfig.getAll();
        setCache(CACHE_KEY, data);
    }
    return getCache(CACHE_KEY)?.[key];
}
