import { gql } from "@apollo/client";

export const GetEvents = gql`
    query GetEvents($filter: FilterEvents, $limit: Int, $offset: Int, $isOwner: Boolean) {
        events(filter: $filter, limit: $limit, offset: $offset, isOwner: $isOwner ) {
            id
            alias
            type
            title
            galleriesInEvent {
                gallery {
                    id
                    type
                    source
                }
                order
            }
            creator {
                id
                alias
                name
                picture
            }
            relatedEvents {
                event {
                    id
                    title
                    price {
                        total
                    }
                    priceSubscription {
                        total
                    }
                }
                order
            }
            tasks {
                stateResult
                id
                difficultyId
            }

            tags {
                id
                title
                subject {
                    color
                }
            }
            countAllUserSubscriptions
            isSubscribe
            isModerated
            isDraft
            workspaceId
            workspaceIds
            price {
                forStudent
            }
            stripeProduct {
                prices {
                    price
                    currency
                }
            }
            certificate {
                id
            }
        }
    }
`;
