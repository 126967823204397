import './scss/index.scss';
import React, { useEffect } from 'react';
import { Button, Checkbox, Input, Radio } from 'antd';
import { useIntl } from 'react-intl';
import { orderBy } from 'lodash';
import classnames from 'classnames';

import { ReactMarkdownComponent } from '../ReactMarkdown.component/ReactMarkdown.component';
import {
    Confetti,
    LoaderTypeComponent,
    VideoAnyPlayer,
} from '../../common';
import { cardStyle, getTitle } from '../../common/utils'
import { AudioPlayerComponent, TextToAudioContainer } from '../../audio';

const { TextArea } = Input;

export const QuestionComponent = (props) => {
    const {
        question,
        questionContent,
        user,
        task,
        result,
        results,
        inProgress,
        handleCheckResultAi,
        onUpdateResult,
        inProgressHighlight,
        currentResult,

        showConfetty,
        showExplanation,
        answerIsExist,
        answerLocal,
        formAnswer,
        handleCheck,
        setShowExplanation,
        setFormAnswer,
        ...other
    } = props;

    const isOwner = (user?.id === task?.creatorId) || (user?.role?.id === 1);
    const { formatMessage } = useIntl();

    const FormContainer = classnames("result-content qustion-actions", {
        card_not_done: currentResult === 'INCORRECT',
        card_done: currentResult === 'CORRECT',
    });

    useEffect(() => {
        setShowExplanation(answerIsExist);
    }, [result, currentResult, question?.id]);

    let explain = question?.explanation?.length > 10 ? `${question?.explanation}` : null;
    const index = task?.questions?.findIndex((el) => el.id === question.id);

    return (
        <div
            className="questions_wrapper Answer__article"
            key={question?.id}
        >
            <div className='Answer__info answer-results__form'>
                {/* {(task?.galleries?.length > 0 && task?.galleries[index]) && (
                    <VideoAnyPlayer
                        {...other}
                        timestampsList={task?.cover?.timestamps}
                        inProgress={inProgress}
                        task={task}
                        user={user}
                        showCaptions={true}
                        onUpdateResult={onUpdateResult}
                        video={task?.galleries[index]}
                    />
                )} */}
                {!!question?.audioQuestion && (
                    <AudioPlayerComponent
                        entityId={question.id}
                        typeEntity="question"
                        audioUrl={question?.audioQuestion}
                        user={user}
                        text={questionContent}
                        isCreator={isOwner}
                        {...other}
                    />
                )}
                {isOwner && !question?.audioQuestion && (
                    <TextToAudioContainer
                        text={questionContent}
                        savedAudio={question?.audioQuestion}
                        questionId={question?.id} />
                )}

                {!question?.question && !inProgress && (
                    <ReactMarkdownComponent children={getTitle(question?.title)} />
                )}

                <div
                    className="wysiwyg-content wrapper_inner-section"
                    tabIndex={0}
                >
                    <ReactMarkdownComponent children={questionContent}/>
                </div>
                {question.type === "TEXT" && question?.answer && (
                    <div
                        style={cardStyle(currentResult)}
                        className={FormContainer}>
                        {showConfetty !== null && <Confetti state={showConfetty} />}
                        <ReactMarkdownComponent children={question?.answer} />
                        <TextArea
                            placeholder={formatMessage({ id: "solution_placeholder", })}
                            className="task-results__form__input wrapper_content-section"
                            name="answer"
                            onChange={(e) => {
                                setFormAnswer((prev) => ({
                                    ...prev,
                                    answer: e.target.value,
                                }));
                            }}
                            autoSize={{ minRows: 2, maxRows: 16, }}
                            rows="2"
                        />
                        <div>
                            <Button
                                onClick={(e) => { handleCheck(null); }}
                                className="margin_right"
                                disabled={inProgress || inProgressHighlight}
                                type="tertiary"
                            >{formatMessage({
                                id: "solution_send",
                            })}
                            </Button>

                        </div>
                        <ReactMarkdownComponent children={user?.id
                            ? answerIsExist?.answer
                            : answerLocal?.answer} />
                    </div>
                )}
                {question.type === "SINGLE_SELECT" && (
                    <div className={FormContainer} style={cardStyle(currentResult)}>
                        <Radio.Group
                            name="radiogroup"
                            optionType="button"
                            className="radio_quesion"
                        >
                        {showConfetty !== null && <Confetti state={showConfetty} />}
                        {orderBy(question.options, "order").map(
                                (option, i) => (
                                    <span key={option.id + i}>
                                        <Radio
                                            value={option.id}
                                            onClick={() =>
                                                handleCheck(option.id)
                                            }
                                            name={option.text}
                                            className="radio_question__option"
                                            style={
                                                option.isCorrect
                                                    ? {
                                                        color: "#4CC05E",
                                                    }
                                                    : {
                                                        color: "#E7522E",
                                                    }
                                            }
                                        >
                                            {option.text}
                                        </Radio>
                                    </span>
                                )
                            )}
                        </Radio.Group>
                    </div>
                )}
                {question.type === "MULTI_SELECT" && (
                    <div className={FormContainer} style={cardStyle(currentResult)}>
                        {showConfetty !== null && <Confetti state={showConfetty} />}

                        <div className="multi-select">
                            <Checkbox.Group
                                name="optionIds"
                                onChange={(value) =>
                                    setFormAnswer((prev) => ({
                                        ...prev,
                                        optionIds: value,
                                    }))
                                }
                            >
                                {question.options.map((option, i) => (
                                    <Checkbox
                                        key={option.id + i}
                                        name="t"
                                        value={option.id}
                                        className="radio_question__option"
                                        style={
                                            option.isCorrect
                                                ? {
                                                    color: "#4CC05E",
                                                }
                                                : {
                                                    color: "#E7522E",
                                                }
                                        }
                                    // onChange={
                                    //     handleClearAnswer
                                    // }
                                    >
                                        {option.text}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </div>
                        <Button
                            type="secondary"
                            onClick={() => handleCheck(null)}
                        >
                            Send
                        </Button>
                    </div>
                )}

                {showExplanation &&
                    <div className="task-form__answer wysiwyg-content" >
                        <ReactMarkdownComponent children={explain} />
                    </div>
                }

                {/* TODO: Remove 1 loader */}
                {question?.explanation &&
                    inProgress &&
                    !question?.answer && (
                        <LoaderTypeComponent />
                    )}

            </div>
        </div >
    );
};
