import {useLazyQuery} from "@apollo/client";
import {GetEventStudents} from "../graphQL/queries/GetEventStudents";

export default function useGetEventStudents(query = GetEventStudents) {
    const [getEventStudents, { data, loading }] = useLazyQuery(query);

    return {
        event: data?.event,
        getEventStudents,
        loading
    }
}
