import { useLazyQuery } from '@apollo/client';
import { GetWorkspace } from '../graphQL/queries/GetWorkspace';

export function useGetWorkspace(query = GetWorkspace) {
    const [getWorkspace, { data, loading, error }] = useLazyQuery(query);
    return {
        workspace: data?.getWorkspace,
        loading,
        error,
        getWorkspace
    };
}