import './scss/index.scss';
import React, { useRef, useState, useCallback } from 'react';
import { Input } from 'antd';
import { noop } from 'lodash';
import { useIntl } from 'react-intl';
import { useGetVideoCaptions } from '../../youtube';
import { youtubeParser, LoaderComponent } from '../../common';
import { VideoPlayer } from '../VideoIframe';

const { TextArea } = Input;

export const YouTubeInput = ({ onVideoDataChange = noop, inProgress, setInProgress = noop, task, ...other }) => {
    const { formatMessage } = useIntl();
    const inputRef = useRef();
    const [urlValue, setUrlValue] = useState('');
    const [urlName, setUrlName] = useState('');
    const [videoSrc, setVideoSrc] = useState('');
    const { getVideoCaptions } = useGetVideoCaptions();

    const onChange = useCallback(async () => {
        setInProgress(true);
        const val = inputRef.current.input.value;
        const resp = await getVideoCaptions({
            variables: {
                id: val,
            },
        });

        let respCaptions = resp?.data?.getVideoCaptions?.captions;
        const sourceText = respCaptions?.map((el) => el?.text)?.join(' ');
        setUrlValue(sourceText);
        setUrlName(youtubeParser(val));
        setVideoSrc(youtubeParser(val));
        onVideoDataChange({ urlName: youtubeParser(val), sourceText: '', });
        // // }
        setInProgress(false);
    }, [getVideoCaptions, setInProgress, onVideoDataChange]);

    return (
        <div className='video_uploader'>
            <div className='video_uploader__input'>
                <Input
                    onChange={onChange}
                    ref={inputRef}
                    placeholder={formatMessage({ id: "paste_link" }) + " https://www.youtube.com/watch?v=FCqkUi1-7S0"}
                    className="inline-el wrapper_inner-section"
                />


                {inProgress && <LoaderComponent type="absolute" />}
            </div>

            {videoSrc && (
                <div className='video_uploader__video'>
                    <VideoPlayer
                        videoSrc={videoSrc}
                        videoId={task?.cover?.id}
                        {...other}
                    />
                    {urlValue && (
                        <TextArea
                            defaultValue={urlValue}
                            placeholder={formatMessage({ id: "form_text_data_placeholder" })}
                            className="ai-bot-component__field_counter"
                            maxLength={30000}
                            showCount
                            autoSize={{ minRows: 2, maxRows: 4 }}
                        />
                    )}
                </div>

            )}
        </div>
    );
};