import {gql} from "@apollo/client";

export const UpdateStripeProduct = gql`
    mutation UpdateStripeProduct($productId: String, $stripeAccount: String, $eventId: Int, $workspaceId: Int) {
        updateStripeProduct(productId: $productId, stripeAccount: $stripeAccount, eventId: $eventId, workspaceId: $workspaceId) {
            prices {
                price
                priceId
                productId
                recurringPeriod
                currency
                type
            }
            wrongEventSubscription
        }
    }
`;
