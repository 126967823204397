import {useLazyQuery} from '@apollo/client';
import {SEARCH_EVENTS} from '../graphQL/queries/SearchEvents';

export function useSearchEvents(query = SEARCH_EVENTS) {
    const [getSearchEvents, {data, loading, error}] = useLazyQuery(query);
    return {
        data,
        loading,
        error,
        getSearchEvents
    };
}