import './scss/index.scss';
import React, { useState, useMemo, useContext } from 'react';
import { Button, Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { ArrowUpOutlined, CloseOutlined } from '@ant-design/icons';

import { TagSelectContainer, Tab } from '../../common';
import { MoveCoursesToWorkspaceContainer } from '../MoveCoursesToWorkspace.container';
import { EventListItemComponent } from '../../event/EventListItem.component';
import {useCreateEventsList, useEditEventsList} from '../hooks';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const AddEventSectionContainer = (props) => {
    const {targetList, targetListEvents, onCancelEdit} = props;
    const { user, onUpdateWorkspace, workspace } = useContext(FirebaseContext);
    const {id: workspaceId} = workspace || {};
    const { formatMessage } = useIntl();
    const { useForm } = Form;
    const [form] = useForm();
    const tabs = [
        formatMessage({ id: 'courses_mode' }),
        formatMessage({ id: 'courses_tags' }),
    ];

    const [showForm, setShowForm] = useState(!!targetList);
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [events, setEvents] = useState(targetListEvents || false);
    const [tags, setTags] = useState(false);
    const {createEventsList} = useCreateEventsList();
    const {editEventsList} = useEditEventsList();
    const isDisabled = useMemo(() => {
        if (activeTab === tabs[0]) {
            return !events?.length;
        }

        if (activeTab === tabs[1]) {
            return !tags?.length;
        }
    }, [tags, events, activeTab]);
    const formInitialValue = useMemo(() => {
        const {title, tagIds} = targetList || {};
        return {title, tagIds};
    }, [targetList]);

    const handleAddSectiontoWorkspace = async (data) => {
        const { title, tagIds } = data;
        const targetData = activeTab === tabs[0]
            ? events && { courses: events?.map((event) => event.id) }
            : tagIds && {tags: tagIds?.map((id) => parseInt(id))}
        const input = {
            workspaceId,
            title,
            ...(targetData && targetData)
        }

        try {
            if (!!targetList) {
                const {id} = targetList;
                await editEventsList(input, id);
            } else {
                await createEventsList({
                    variables: {
                        input
                    }
                });
            }
            onUpdateWorkspace();
        } catch (err) {
            console.warn(err.message);
        }

        setShowForm(false);
    }

    const handleSetActiveTab = (tab) => {
        setActiveTab(tab);
        if (tabs[0] === tab) {
           setTags(null);
        } else {
            setTags(form.getFieldValue('tagIds') || null);
        }
    }

    const onSendSelected = (selectedEvents) => {
        const newEvents = events ? [...events] : [];
        selectedEvents.forEach((ev) => {
            const isExist = newEvents.find((n) => n?.id === ev?.id);
            if (!isExist) {
                newEvents.push(ev);
            }
        });
        setEvents(newEvents);
    }

    return (
        <>
            {!targetList && (
                <div className='add-event-section'>
                    <div className="add-event-section__line-wrapper" aria-hidden="true">
                        <hr className='add-event-section__line' />
                    </div>
                    <div className="add-event-section__buttons">
                        <Button size='small' onClick={() => setShowForm((prev) => !prev)}>
                        <span >
                            {formatMessage({ id: 'wrksp_add_section' })}
                        </span>
                        </Button>
                    </div>
                </div>
            )}

            {showForm &&
                <div className='workspace-wrapper'>
                    <div className='profile-block profile-wrapper profile-workspace-wrapper'>
                        <Form onFinish={handleAddSectiontoWorkspace}
                            form={form}
                            layout="vertical"
                            className="ai-component__form"
                            name='create-workspace'
                            initialValues={formInitialValue}
                        >
                            <Form.Item
                                name={"title"}
                                rules={[{
                                    required: true,
                                    message: formatMessage({ id: 'wrksp_add_section_plchldr' })
                                }]}
                                className="title-field"
                            >
                                <Input placeholder={formatMessage({ id: 'wrksp_add_section_title' })} />
                            </Form.Item>
                            <div className='wrapper_content-section'>
                                <Tab
                                    items={tabs}
                                    handleClick={handleSetActiveTab}
                                    activeTab={activeTab}
                                    type="small"
                                />

                                {activeTab === tabs[1] &&
                                    <TagSelectContainer
                                        form={form}
                                        onChange={setTags}
                                    />
                                }
                                {activeTab === tabs[0] &&
                                    <>
                                        <div className='events-list_list '>
                                            {!!events?.length &&
                                                events.map((event) => (
                                                    !!event ? (
                                                        <div key={`add-item_${event?.id}`} className='event-with-checkbox' >
                                                            <EventListItemComponent event={event} />
                                                            <Button type="text" icon={<ArrowUpOutlined />} onClick={() => {
                                                                setEvents((prev) => {
                                                                    const index = prev.findIndex(e => e.id === event.id);
                                                                    if (index > 0) { [prev[index], prev[index - 1]] = [prev[index - 1], prev[index]]; }
                                                                    return [...prev];
                                                                })

                                                            }} />
                                                            {/* <Button type="text" icon={<ArrowDownOutlined />} onClick={onDown} /> */}
                                                            <Button type="text" icon={<CloseOutlined />} onClick={() => {
                                                                setEvents(prev => prev.filter(e => e.id !== event.id));
                                                            }} />
                                                        </div>
                                                    ) : null
                                                ))}
                                        </div>
                                        <MoveCoursesToWorkspaceContainer
                                            workspace={workspace}
                                            filter={{
                                                workspaceId
                                            }}
                                            user={user}
                                            onUpdateWorkspace={onUpdateWorkspace}
                                            onSendSelected={onSendSelected}
                                            {...props}
                                        />
                                    </>
                                }
                            </div>

                            <Button
                                type={'primary'}
                                htmlType='submit'
                                className="wrapper_inner-section"
                                block="true"
                                disabled={isDisabled}
                            >
                                {formatMessage({ id: 'form_button_save' })}
                            </Button>
                        </Form>
                        {!!targetList && (
                            <Button
                                type="tertiary"
                                htmlType="button"
                                className="wrapper_inner-section"
                                block="true"
                                onClick={onCancelEdit}
                            >
                                {formatMessage({ id: 'cancel_label' })}
                            </Button>
                        )}
                    </div>
                </div>
            }
        </>
    );
};
