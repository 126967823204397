import React from 'react';
import {Modal, Input, Button, Row, Col} from 'antd'; // Import Row and Col
import {useAddVideoHelper} from '../helpers';
import { useIntl } from 'react-intl';

export const VideoModalComponent = ({editor, isVisible, onClose}) => {
    const {
        videoUrl,
        videoWidth,
        setVideoUrl,
        setVideoWidth,
        handleOk,
    } = useAddVideoHelper(editor);
    const { formatMessage } = useIntl();

    const handleInsertAndClose = () => {
        handleOk();
        onClose();
    };

    // TODO: Need translation

    return (
        <Modal
            title="Insert Embed Content"
            visible={isVisible}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>
                    {formatMessage({id: 'cancel_label'})}
                </Button>,
                <Button key="submit" type="primary" onClick={handleInsertAndClose}>
                    {formatMessage({id: 'insert_button'})}
                </Button>,
            ]}
        >
            <Input
                label="URL"
                title="URL"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
                placeholder="Enter video URL"
            />
            <br/>
            <Row gutter={16}> {/* Use Row and Col to organize inputs in one row */}
                <Col span={12}>
                    <Input
                        value={videoWidth}
                        onChange={(e) => setVideoWidth(e.target.value)}
                        placeholder="Video width"
                    />
                </Col>
            </Row>
            <hr/>
            <p>
                {formatMessage({id: 'acceptable_sumbols'})}
            </p>
            <p>{formatMessage({id: 'example_title'})}</p>
            <ul>
                <li>{formatMessage({id: 'example1'})}</li>
                <li>{formatMessage({id: 'example2'})}</li>
                <li>{formatMessage({id: 'example3'})}</li>
            </ul>
        </Modal>
    );
};
