export const wave = (p) => {
  let yOffset = 0.0;
  let w, h;

  p.setup = () => {
    w = p?.canvas?.parentElement?.offsetWidth || 400; // Fallback width if parent element is not found
    h = p?.canvas?.parentElement?.offsetHeight || 400; // Fallback height
    p.createCanvas(w, h);
    p.noFill();
  };

  p.draw = () => {
    p.background("rgba(255, 255, 255, 0.1)");
    p.stroke("rgba(0,0,0, 0.7)");
    p.strokeWeight(1);
    p.beginShape();

    let xOffset = 0.0;
    let step = 5;
    for (let x = 0; x < w + step / 2; x += step) {
      let y = p.map(
        p.noise(xOffset, yOffset),
        0, 1, 0, w
      );
      p.vertex(x, y);
      xOffset += 0.04;
    }
    yOffset += 0.01;
    p.endShape();
  };

  p.windowResized = function () {
    w = p?.canvas?.parentElement?.offsetWidth || 400; // Fallback width if parent element is not found
    h = p?.canvas?.parentElement?.offsetHeight || 400; // Fallback height
    p.resizeCanvas(w, h);
  };

};