import React from 'react';
import {useIntl} from 'react-intl';
import {ReportsPaidContainer} from '../Components/reports';
import {HeaderProfile} from '../Components/headers';

export const Reports = () => {
    const {formatMessage} = useIntl();
    return (
        <>
            <HeaderProfile string={formatMessage({id: 'payment_request_header'})}/>
            <div className="page">
                <h1>
                    {formatMessage({id: 'payment_request_title'})}
                </h1>
                <ReportsPaidContainer/>
            </div>
        </>
    )
}
