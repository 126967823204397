import React, { useContext, useEffect, useState, useMemo } from 'react';
import {useIntl} from 'react-intl';

import { Loader } from '../../Loader';
import { ProgramsSubjectsContainer } from '../ProgramsSubjects.container';
import { PanelsContainer, PanelThemeComponent } from '../../panel';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { MetaComponent } from '../../common';

import {
    getStorageManager,
} from '../../common/utils';
import { useGetLevels, useGetSubjects } from '../../common/graphQL';
import { HeaderCourses } from '../../filter/Filter.container/Filter.container';

const STORAGE_KEY = 'saved-filters';

//
export const ProgramsContainer = (props) => {
    const { preferences, ...other } = props;
    const {formatMessage} = useIntl();

    const { user } = useContext(FirebaseContext);

    const { levels, loading: loadingLevels } = useGetLevels();
    const { subjects, loading: loagingSubjects } = useGetSubjects();

    const { getStorageData, setStorageData } = getStorageManager();

    const [selectedLevel, setSelectedLevel] = useState(null);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [theme, setTheme] = useState(null);
    const [editedTheme, setEditedTheme] = useState(null);
    const [editVisible, setEditVisible] = useState(null);
    const [update, setUpdate] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const meta = useMemo(() => (
        {meta_title: formatMessage({id: 'meta_programs'})}
    ), []);

    const onSetTheme = async (themeData, e) => {
        if (e) {
            e.preventDefault();
        }
        setTheme(themeData?.id || themeData);
    };

    const allowEdit = user?.role?.id === 1;
    const storageData = getStorageData(STORAGE_KEY);
    const initialPreferences = preferences;
    const savedData =
        initialPreferences ||
        storageData ||
        user?.savedFilters ||
        user?.preferences;

    const handleEdit = (e, theme) => {
        e.stopPropagation();
        setEditedTheme(theme);
        setEditVisible(true);
    };

    const handleCloseEdit = (e) => {
        setEditVisible(false);
        setEditedTheme(null);
        if (!e) {
            setUpdate(true);
        }
    };

    useEffect(() => {
        if (!isLoaded) {
            return;
        }
        const data = {};
        if (selectedLevel) {
            data.level = selectedLevel;
        }
        if (selectedSubjects) {
            data.subjects = selectedSubjects;
        }
        setStorageData(STORAGE_KEY, data);
    }, [selectedLevel, selectedSubjects]);

    useEffect(() => {
        if (!loadingLevels && !loagingSubjects) {
            setIsLoaded(true);
        }
    }, [loadingLevels, loagingSubjects])

    if (!isLoaded)
        return <Loader />;

    return (
        <div>
            <MetaComponent meta={meta} />

            <HeaderCourses
                levels={levels}
                subjects={subjects}
                selectedLevel={selectedLevel}
                setSelectedLevel={setSelectedLevel}
                selectedSubjects={selectedSubjects}
                setSelectedSubjects={setSelectedSubjects} />

            <ProgramsSubjectsContainer
                {...other}
                level={selectedLevel}
                user={user}
                allowEdit={allowEdit}
                onEdit={handleEdit}
                subjects={subjects}
                onSetTheme={onSetTheme}
                update={update}
                onAfterRender={() => setUpdate(null)}
                selectedSubjects={selectedSubjects}
            />

            <PanelsContainer
                {...other}
                as={PanelThemeComponent}
                onClosePanels={() => onSetTheme(null)}
                dbPropertyKey='themes'
                onClearSource={setTheme}
                activePanelId={theme}
            />
        </div>
    );
};
