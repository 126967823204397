import {gql, useMutation} from '@apollo/client';
import {AiAvatarGenerate} from '../graphQL/mutations/AiAvatarGenerate';

export const useGenerateAiAvatar = (mutation = AiAvatarGenerate) => {
    const [generateAiAvatar, {data, loading, error}] = useMutation(mutation);

    return {
        generateAiAvatar,
        data,
        loading,
        error
    }
}