import {useContext, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const useLocalePrefix = () => {
    const {
        locale,
        defaultLocale = 'en'
    } = useContext(FirebaseContext);
    const {pathname} = useLocation() || {};

    return useMemo(() => {
        const isDefaultLocale = !locale || locale === defaultLocale;
        const localePrefix = isDefaultLocale ? '' : '/' + locale;
        const isReditectLocale = !isDefaultLocale && !pathname?.startsWith(localePrefix);
        return [localePrefix, isReditectLocale];
    }, [locale, pathname]);
};
