import '../scss/index.scss';

import React from 'react';
import {PartneroContainer} from '../partnero';
import { useIntl } from 'react-intl';

export const IntegrationsComponent = ({integration}) => {
    const {formatMessage} = useIntl();
    return (
        <div className="integrations-component">
            <h2>{formatMessage({ id: 'integrations' })}</h2>
            <div className="flex-container flex-container_flex-wrap">
                <PartneroContainer itemCssClass='integration-item'
                                   integration={integration}/>
            </div>
        </div>
    )
}