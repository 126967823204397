import { useCallback } from 'react';
import { useAiAssistant } from '../graphQL/useAiAssistant';

export const useGetTagsForTasks = () => {
    const { getAssistantResponse, error } = useAiAssistant();
    const getTagsForTasks = useCallback(
        async (request) => {
            const settings = {
                args: JSON.stringify({topic: request}),
                type: 'graph',
                assistantId: 'asst_Z4ycJK0BbVufsP9TJ9BVUNnt',
            };

            const response = await getAssistantResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = response?.data?.requestAssistantApi && JSON.parse(response?.data?.requestAssistantApi);
            const parseJson = (jsonString) => {
                const correctedJsonString = jsonString.replace('```json',  '').replace('```', '');
                try {
                    const jsonObj = JSON.parse(correctedJsonString);
                    return jsonObj;
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    return null;
                }
            }
            if (responseJSON[0]) {
                let responseTrimmed = parseJson(responseJSON[0].text?.value)
                return responseTrimmed;
            }
            return null;
        },
        [getAssistantResponse]
    );

    return { getTagsForTasks };
};
