import React, {useState, useContext} from 'react';
import {EditCustomVoiceComponent} from '../EditCustomVoice.component';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {useGetCustomVoice} from '../hooks';

const ADD_VOICE_URL = 'https://back.unschooler.me/voices/add';
// const ADD_VOICE_URL = 'http://localhost:4000/voices/add';
const REMOVE_VOICE_URL = 'https://back.unschooler.me/voices/remove';
// const REMOVE_VOICE_URL = 'http://localhost:4000/voices/remove';

export const EditCustomVoiceContainer = (props) => {
    const {user, updateUserData} = useContext(FirebaseContext);
    const {id, customVoiceId} = user;
    const [loading, setLoading] = useState(false);
    const {customVoice, loading: customVoiceLoading} = useGetCustomVoice();

    const handleFinish = async (data) => {
        const {name, accent, description, file} = data;
        setLoading(true);
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('file', file.file);
        formData.append('userId', id);
        if (!!accent) {
            formData.append('accent', accent);
        }

        const resp = await fetch(ADD_VOICE_URL, {
            method: 'POST',
            body: formData
        });
        const result = await resp.json();
        if (result.voice_id) {
            await updateUserData();
        }
        setLoading(false);
    };

    const handleRemove = async () => {
        setLoading(true);
        const {voiceId} = customVoice;

        const formData = new URLSearchParams({
            voiceId,
            userId: id
        });

        const resp = await fetch(REMOVE_VOICE_URL, {
            method: 'POST',
            body: formData
        });

        const result = await resp.json();
        if (result === 'OK') {
            await updateUserData();
        }
        setLoading(false);
    };

    return <EditCustomVoiceComponent loading={loading || customVoiceLoading}
                                     customVoice={customVoice}
                                     onFinish={handleFinish}
                                     onRemove={handleRemove}/>
}