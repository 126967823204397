import './scss/index.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { TagComponent } from '../../common';
import { Loader } from '../../Loader';
import { useLocalePrefix } from '../../lang';

export const SkillsMapComponent = ({
    filteredSubjects,
    user,
    ...other
}) => {
    const [localePrefix] = useLocalePrefix();
    if (!filteredSubjects) {
        return <Loader />;
    }
    const cardsClass = classnames('subject_card', {
        card_small: filteredSubjects?.length > 2,
        card_30: filteredSubjects?.length < 2,
    });

    return (
        <div className='skills-map-component'>
            <ul className='cards '>
                {filteredSubjects ? (
                    filteredSubjects?.map((subject) => (
                        <div
                            key={subject?.id}
                            className={cardsClass}
                        >
                            <Link
                                key={`link-${subject?.id}`}
                                to={{
                                    pathname: `${localePrefix}/tutorials/subject/${subject?.id}`,
                                    state: subject,
                                }}
                            >
                                <h4>{subject?.title}</h4>
                            </Link>
                            <div>
                                {subject?.tags?.map((tag) => (
                                    <div
                                        key={`skill-tag-${tag?.id}`}
                                        className='skills_tag skill_progress'
                                    >
                                        <TagComponent
                                            key={tag?.id}
                                            tag={{...tag, color: subject?.color}}
                                            link={true}
                                            countResults={tag?.countResults && 
                                                {
                                                all: 20,
                                                done: tag?.countResults || 0,
                                            }}
                                            {...other}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                ) : (
                    <Loader />
                )}
            </ul>
        </div>
    );
};
