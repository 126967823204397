import {gql} from "@apollo/client";

export const EditWorkspace = gql`
    mutation EditWorkspace($input: EditWorkspaceInput, $id: Int!) {
        editWorkspace(input: $input, id: $id) {
            id
            firebaseId
            host
            customDomain
            ownerId
            title
            logo
            cover
            sharing
            loginCover
            description
            currencyCode
            address
            contactEmail
            copyright
            customPolicy
            customTerms
            customScript
            stripeProductId
            hideTitle
            inviteOnly
            loginOnly
            status
            isDisabled
            eventsLists {
                id
                title
                workspaceId
                courses
                tags
                order
            }
            members {
                id
                permission
                workspaceId
                userId
            }
            resend {
                id
                domain {
                    id
                    name
                    status
                    records
                    resendId
                }
                email
                workspaceId
            }
            createdAt
            updatedAt            
        } 
    }
`;
