import React from 'react';
import { Button } from 'antd';
import { LoaderComponent, useConvertedPrice } from '../../common';
import { useIntl } from 'react-intl';


export const StripeProductDataComponent = (props) => {
    const { loading, onUpdate, error, prices = [], wrongEventSubscription } = props;
    const { getPrice } = useConvertedPrice();
    const { formatMessage } = useIntl();

    return <div className="stripe-product-data wrapper_content-section">
        {wrongEventSubscription && (
            <div className="error">{formatMessage({id: 'product_busy_alarm'})}</div>
        )}
        {error && (
            <div className="error">{formatMessage({id: 'product_not_found_warning'})}</div>
        )}
        <Button
            type="tertiary"
            size='small'
            onClick={onUpdate}
            className=" wrapper_content-section">
            {loading && (<LoaderComponent type="inline" />)}
            <span>
                {formatMessage({id: 'update_data_warning'})}
            </span>
        </Button>
        {!!prices?.length && (
            <div className="stripe-product-data__prices overflow-x-auto">

                {/* <hr /> */}
                {/* <h3>Product data</h3> */}
                <table style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'start' }}>{formatMessage({id: 'price_threebutton'})}</th>
                            <th style={{ textAlign: 'start' }}>{formatMessage({id: 'period_threebutton'})}</th>
                            <th style={{ textAlign: 'start' }}>{formatMessage({id: 'priceid_threebutton'})}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prices.map((p) => (
                            <tr key={p.priceId}>
                                <td className='margin_right'>{getPrice(p.price / 100, p.currency)}</td>
                                <td className='margin_right'>{p.recurringPeriod}</td>
                                <td className='margin_right'>{p.priceId}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )}
    </div>
}