import './scss/index.scss';
import React from 'react';
import { useIntl } from 'react-intl';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button } from 'antd';
import { LoaderComponent, useTrackCredits, Svg } from '../../common';
import { MentorEditContainer } from '../../profile/MentorEdit.container'

export const AiAvatarComponent = (props) => {
    const {
        loading,
        onGenerate,
        source = 'https://d-id-public-bucket.s3.us-west-2.amazonaws.com/alice.jpg',
        withGenerateButton,
        onTeacherChange,
        selectedTeacher,
        handleToggle,
        ...other } = props;
    const { formatMessage } = useIntl();
    const { isLimitReached, currentCredits } = useTrackCredits();

    return <div className="ai-avatar-component">
        <div className='ai_avatar_flex-container'>
            <div
                className="circle-button"
                type="primary"
                shape="circle"
                onClick={() => { console.log('onClick') }}
            >
                <div className="circle-content">
                    {!isLimitReached && <div className="circle-text">
                        <MentorEditContainer {...other} />
                        {handleToggle && <Button
                            type="text"
                            onClick={handleToggle}
                            
                            className='ml-m-l'
                        >
                            <Svg name=':icon/cross' width={12} />
                            Disable
                        </Button>}
                    </div>
                    }
                    <LazyLoadImage
                        src={source}
                        alt="Avatar image"
                        className="circle-video"
                        width={120}
                        height={120}
                        style={{
                            borderRadius: '50%',
                            objectFit: 'cover'
                        }}
                    />
                </div>
            </div>


            {withGenerateButton &&
                <div>
                    <small className='text_secondary'>33 credits/minute, you have {currentCredits || 0}</small>
                    <Button type="secondary"
                        size="small"
                        className='block-el'
                        disabled={isLimitReached || loading}
                        onClick={onGenerate}>
                        {loading && <LoaderComponent type="inline" />}
                        <span>
                            {formatMessage({ id: 'ai_avatar_btn' })}
                        </span>
                    </Button>
                </div>
            }
        </div>
    </div>
}