import { useLazyQuery } from "@apollo/client";
import { GetSkillsNew } from "../graphQL/queries/GetSkillsNew";

export function useGetSkillsNew(query = GetSkillsNew) {
    const [getSkillsNew, { data, error, loading }] = useLazyQuery(query);

    return {
        skills: data?.skillsNew,
        error,
        loading,
        getSkillsNew
    };
}