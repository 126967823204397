import {isDevelopment} from './isDevelopment';

const HOST_EXCLUDES = ['localhost', 'www', 'unschooler', 'me', 'ai', 'org', 'uk'];
const DEFAULT_HOSTS = ['unschooler.me', 'unschooler.ai', 'unschooler.uk', 'localhost'];
const hostName = window.location.hostname;
const isSubDomain = DEFAULT_HOSTS.find((item) => hostName.endsWith(item));
const HOST_NAME_LIST = hostName.split('.');
export const hostname = HOST_NAME_LIST.find((item) => !HOST_EXCLUDES.includes(item));
export const workspaceCustomDomain = isSubDomain ? null : hostName;
export const baseHost = isDevelopment() ? 'localhost:3000' : (DEFAULT_HOSTS.find((host) => hostName.endsWith(host)) || 'unschooler.me');
