import './scss/index.scss';
import React from 'react';

import {
    PassionNavComponent,
    SignInComponent,
    LearnSkillButtonComponent,
    useIsAllow,
} from '../common';


export const HeaderSkills = ({
    specialization,
    handleSpec,
    user,
    hideAll,
    specs,
    skills
}) => {
    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd("PREMIUM");
    return (
        <header className='header header_profile header_inner'>
            {specs &&
                <PassionNavComponent
                    specialization={specialization}
                    spec={specs}
                    handleSpec={handleSpec}
                    user={user}
                    hideAll={hideAll}
                    specs={specs}
                />
            }
            <div></div>
            <div className='header_btn'>
                {!user?.id ? (
                    <div className='btns_header hide-on-mobile'>
                        <SignInComponent />
                    </div>
                ) :
                    (
                        <>
                            <div className='btns_header hide-on-mobile'>
                                {/* {isAllow ? (
                                    <YoutubeContainer
                                        id={user?.id}
                                        size='medium'
                                        type='secondary'
                                    />
                                ) : (
                                    <SubscriptionButtonComponent
                                        type='secondary'
                                        title='Test specialisation'
                                        size='medium'
                                    />
                                )} */}

                                <LearnSkillButtonComponent user={user}
                                    skills={skills}
                                    size={'large'} />
                            </div>
                        </>
                    )
                }
                {/* <CreateEventButtonComponent className='margin_right' /> */}
            </div>
        </header>
    );
};
