import {gql, useLazyQuery} from '@apollo/client';

export const useMapSkillsTagToTag = (options = {}, query = GET_SKILLS_TAG_TO_TAG) => {
    const [getMapSkillsTagToTag, {data, loading, error}] = useLazyQuery(query, options);

    return {
        getMapSkillsTagToTag,
        mapTags: data?.mapSkillsTagToTag,
        loading,
        error
    };
};

const GET_SKILLS_TAG_TO_TAG = gql`
    query MapSkillsTagToTag($specId: Int!) {
        mapSkillsTagToTag(specId: $specId) {
            tag {
                id
                alias
                subject {
                    id
                    alias
                    subjectUid
                    title
                    color
                    icon
                    countResults
                }
                title
                icon
                defIcon
                countResults
            }
            tags {
                id
                alias
                subject {
                    id
                    alias
                    subjectUid
                    title
                    color
                    icon
                    countResults
                }
                title
                icon
                defIcon
                countResults
            }
        }
    }
`;