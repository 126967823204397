import React from 'react';
import { LoaderComponent } from '../../common';

export const AiCoursesComponent = (props) => {
    const { isLoading,
        myCourses,
    } = props;

    if (isLoading) {
        return <LoaderComponent type="inline" />
    }

    return (
        <div className="ai-tokens-component">
            <h3>Additional courses: {myCourses}
                {/* / Credits spent: {isUniversity ? Math.round(totalCredits) : (lastPurchase ? lastPurchase - myCredits : 'N/A')} */}
            </h3>
        </div>
    )
}