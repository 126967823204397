import {gql} from "@apollo/client";

export const ActivateInvite = gql`
    mutation ActivateInvite($id: Int!) {
        activateInvite(id: $id) {
            id
            permission
            email
            workspaceId
            userId
            isActivated
            createdAt
        } 
    }
`;
