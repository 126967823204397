import React, {useContext, useEffect, useState} from 'react';
import {noop} from 'lodash';
import {EditOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import classNames from 'classnames';
import {Svg} from '../../common';
import {ProgramContext} from '../../../context/program/ProgramContect';

export const ProgramsThemeComponent = (props) => {
    const {
        theme,
        onSetTheme,
        // progress,
        // onUPdateProgress,
        user,
        allowEdit,
        onEdit = noop} = props;

    let initialState =
        user?.themesStates &&
        user.themesStates[theme.id] &&
        user.themesStates[theme.id] === 'studied'
            ? true
            : false;

    const {themeData, onSetThemeData} = useContext(ProgramContext);
    const [themeFavorite, setThemeFavorite] = useState(initialState);

    const handleFavorite = async (e, theme) => {
        e.preventDefault();
        e.stopPropagation();

        onSetThemeData({
            id: theme.id,
            themeFavorite: !themeFavorite
        });
        setThemeFavorite(!themeFavorite);
    };

    let cardClass = classNames('theme-card', {
        'theme-card_favorite': themeFavorite,
    });

    useEffect(() => {
        if (themeData.id === theme.id) {
            setThemeFavorite(themeData.themeFavorite);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeData]);

    return (
        <div className={cardClass} onClick={() => onSetTheme(theme)}>
            <p className='theme-card__title'>{theme.themeName}</p>
            <Button
                className='btn_absolute'
                type='text'
                size='24'
                onClick={(e) => {
                    handleFavorite(e, theme);
                }}
            >
                {/* {translate("btn_to_learn")} */}
                <Svg name=':icon/check' className='icon_check icon_24' />
            </Button>
            {allowEdit && <Button
                className='edit-button'
                type='text'
                size='24'
                onClick={(e) => {
                    onEdit(e, theme);
                }}
            >
                <EditOutlined />
            </Button>}
        </div>
    );
};
