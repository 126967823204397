import {useCallback} from 'react';
import {useAiChatResponse} from '../graphQL/useAiChatResponse';

export const useGetCourseDescription = () => {
    const {getChatResponse, error} = useAiChatResponse();

    const getCourseDecription = useCallback(
        async (request) => {
            const settings = {
                args: {
                    request: request,
                },
                type: 'course',
            };            
            const resp = await getChatResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = resp?.data
                ? JSON.parse(resp?.data?.requestChatApi)
                : null;
            if (responseJSON?.choices?.[0]) {
                let responseTrimmed = responseJSON.choices[0].message.content;
                return responseTrimmed;
            }
        },
        [getChatResponse]
    );

    return {getCourseDecription, error};
};
