import './scss/index.scss';
import React from 'react';
import { Button } from 'antd'
import { useIntl } from "react-intl";
import { VideoAnyPlayer } from '../VideoIframe/VideoAnyPlayer'

export const PromoBanner = (props) => {
    const { header, subhead, paragraph, src, style, setEditmode } = props;
    const { formatMessage } = useIntl();

    return (
        <>
            <div className='flex-container flex-promo_wrapper ' style={style}>
                <div className='promo__text'>
                    {header && <h2 className=''>{header}</h2>}
                    <h4>{subhead}</h4>
                    <p className='wrapper_inner-section'>{paragraph}</p>
                    <Button type="primary" onClick={() => setEditmode(true)}>
                        {formatMessage({ id: 'create_your_school' })}
                    </Button>
                </div>
                <VideoAnyPlayer
                    video={{ source: 'https://storage.googleapis.com/cdn.unschooler.me/web_app/tech_pictures/How%20to%20start%20a%20Pro%20plan.mp4' }}
                    allow="fullscreen"
                />
            </div>
            <div
                className='profile_cover'
                style={{ backgroundImage: `url('https://storage.googleapis.com/cdn.unschooler.me/web_app/profile_cover/${`info`}_trans.png')`, }}
            ></div>
        </>
    );
};
