import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { UploadPdfComponent, LoaderComponent } from '../../common';

export const AiPdfComponent = ({ onUpdate, setPdfName ,onLoadFile}) => {
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState();
    const [error, setError] = useState(false);

    return (
        <div className="ai-pdf-component">
            <p className='wrapper_inner-section'>{formatMessage({ id: 'form_text_data_file' })}</p>
            {!content && (
                <UploadPdfComponent onLoadFile={onLoadFile} setLoading={setLoading} />
            )}
            {error && (
                <div className="error">
                    We couldn't process your file, it is too long or have unprocessed symbols inside. Please try again or copy and paste text into Course goals fiels.
                </div>
            )}
            {loading && <LoaderComponent type="inline" />} 
        </div>
    )
}