import { useMutation } from "@apollo/client";
import { DeleteTask } from "../graphQL/mutations/DeleteTask";

export function useDeleteTask(mutation = DeleteTask) {
    const [deleteTask, { data, error, loading }] = useMutation(mutation);

    return {
        data,
        error, loading,
        deleteTask
    };
}
