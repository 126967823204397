export const getDegColor = (color) => {
    const hexColor = color;
    if (hexColor) {
        // Separate the red, green, and blue values
        const red = parseInt(hexColor.slice(1, 3), 16);
        const green = parseInt(hexColor.slice(3, 5), 16);
        const blue = parseInt(hexColor.slice(5, 7), 16);

        // Convert the red, green, and blue values to numbers between 0 and 1
        const r = red / 255;
        const g = green / 255;
        const b = blue / 255;

        let cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin,
            h = 0;

        let shear = 17;
        if (delta === 0) {

            h = 0;
        }
        // Red is max
        else if (cmax === r) {
            shear = 5;
            h = ((g - b) / delta) % 6;
        }
        // Green is max
        else if (cmax === g) h = (b - r) / delta + 2;
        // Blue is max
        else h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        // Make negative hues positive behind 360°
        if (h < 0) h += 360;
        return h + shear;
    }
};

// function getDominantColor(imageSrc, callback) {
//     const img = new Image();
//     img.crossOrigin = "Anonymous"; // to avoid cross-origin issues
//     img.onload = function () {
//         const canvas = document.createElement('canvas');
//         const context = canvas.getContext('2d');
//         canvas.width = img.width;
//         canvas.height = img.height;
//         context.drawImage(img, 0, 0, img.width, img.height);

//         const imageData = context.getImageData(0, 0, canvas.width, canvas.height).data;
//         const colorCounts = {};
//         let mostFrequentColor = '';
//         let maxCount = 0;

//         for (let i = 0; i < imageData.length; i += 4) {
//             const color = `${imageData[i]},${imageData[i + 1]},${imageData[i + 2]}`;
//             colorCounts[color] = (colorCounts[color] || 0) + 1;

//             if (colorCounts[color] > maxCount) {
//                 maxCount = colorCounts[color];
//                 mostFrequentColor = color;
//             }
//         }

//         callback(mostFrequentColor);
//     };
//     img.src = imageSrc;
// }
// function isColorLight(r, g, b) {
//     const brightness = (r * 299 + g * 587 + b * 114) / 1000;
//     return brightness >= 128;
// }


// if (!!currentSlide?.images) {
//     getDominantColor(currentSlide.images[0].url, function (color) {
//         setCurrentColor(`rgb(${color})`)
//         let colorArray = color.split(',');

//         isColorLight(colorArray[0], colorArray[1], colorArray[2]) ? setLight(true) : setLight(false)
//     });

// }

export const cardStyle = (currentResult) => {
    if (currentResult === 'CORRECT') {
        return {
            backgroundColor: '#4cc05e1a',
        }
    } else if (currentResult === 'INCORRECT') {
        return {
            backgroundColor: '#ff42151a',
        }
    }
}

export const colorStyle = (currentResult) => {
    if (currentResult === 'CORRECT') {
        return {
            color: '#4cc05e',
        }
    } else if (currentResult === 'INCORRECT') {
        return {
            color: '#ff4215',
        }
    }
}