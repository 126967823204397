import '../Components/payments/scss/index.scss';

import React, { useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { FaqComponent } from '../Components/landing/Faq.component/Faq.component';
import { FooterContainer } from "../Components/footer";
import { useTrackCredits } from "../Components/common";
import { FirebaseContext } from '../context/firebase/firebaseContext';
import { AiTokensContainer } from '../Components/statistics';
import { AiCoursesComponent } from '../Components/statistics/AiCourses.component';
import { StripeCustomPaymentContainer } from '../Components/stripe';
import { AI_CREDITS_PRICES, FAQ_CREDITS } from '../constants/stripe-prices';

const REDIRECT_URL = '/tutorials/additional-pricing-326956';

export const Credits = () => {
  const pageRef = useRef();
  const { user, updateUser } = useContext(FirebaseContext);

  const { type, endDate, isCanceled } = user?.subscriptionUser || {};
  const isExpired = endDate && new Date(endDate) < new Date();
  const isUniversity = (type === 'UNIVERSITY') && !isExpired;
  const { currentCredits, lastPurchase, currentCourses } = useTrackCredits();
  let myCredits = isUniversity ? 5000 : currentCredits;

  return (
    <div ref={pageRef} className="page">
      {myCredits &&
        <AiTokensContainer
          user={user}
          myCredits={myCredits}
          isUniversity={isUniversity}
          lastPurchase={lastPurchase}
        />
      }
      {currentCourses &&
        <AiCoursesComponent
          myCourses={currentCourses}
          user={user}
        />
      }

      <div>
        <div className='wrapper_outer-section'>
          <h1 className='wrapper_inner-section'>
            Purchase AI credits to generate AI content
          </h1>
          <StripeCustomPaymentContainer pricesList={AI_CREDITS_PRICES} />
          <FaqComponent faq={FAQ_CREDITS} />
          <div className="wrapper_content-section">
            <Link to={REDIRECT_URL}>Read the full guide</Link>
          </div>
        </div>
      </div>
      <FooterContainer />
    </div >
  );
}
