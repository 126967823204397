import {useLazyQuery} from '@apollo/client';
import {useContext, useCallback, useEffect} from 'react';
import {GetCustomVoice} from '../graphQL/queries/GetCustomVoice';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const useGetCustomVoice = (query = GetCustomVoice) => {
    const [getCustomVoiceData, {data, loading, error }] = useLazyQuery(query);
    const {user} = useContext(FirebaseContext);
    const {customVoiceId} = user;

    const getCustomVoice = useCallback(async () => {
        const resp = await getCustomVoiceData({
            variables: {
                voiceId: customVoiceId
            }
        });
        return resp?.data?.getCustomVoice;
    }, [getCustomVoiceData, customVoiceId]);

    useEffect(() => {
        (async () => getCustomVoice())();
    }, [customVoiceId]);

    return {
        customVoice: data?.getCustomVoice,
        loading,
        error
    }
}
