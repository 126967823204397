import {useMutation} from '@apollo/client';
import {ConfirmEmailToken} from '../graphQL/mutations/ConfirmEmailToken';

export const useConfirmEmailToken = (mutation = ConfirmEmailToken) => {
    const [confirmEmailToken, {data, error, loading}] = useMutation(mutation);

    return {
        data,
        error, loading,
        confirmEmailToken
    };
}