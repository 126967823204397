import './scss/index.scss';
import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { noop } from 'lodash';
import { useIntl } from 'react-intl';
import { UploadVideoComponent, LoaderComponent, useGetCaptions } from '../../common';

const { TextArea } = Input;

export const VideoUploader = ({
    index,
    setInProgress = noop,
    inProgress,
    setCover,
    cover,
    task,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const { captions, getCaptions } = useGetCaptions();
    const [videoData, setVideoData] = useState();

    const handleUploadTest = () => {
        const fdf = {
            "type": "VIDEO",
            "source": "https://cdn.unschooler.me/9_1_2024_471.mp4",
            "title": "mp4 video https://cdn.unschooler.me/9_1_2024_471.mp4",
            "raw": "Hi, I'm Lena, the co-founder of UNSCOA. We are building tools that generate video courses for personal career goals. We help people to achieve these goals, to accomplish new projects, or fill skill gaps. Our courses are 10x better than the others because they include videos and people can literally talk to them. They can ask questions, send solutions, and get feedback from DPT. We have launched these courses three weeks ago. For the one last week, we got an organic traffic spike from search. Our daily users grew 19x, returning users 12x, and for just one week, we got 5,000 users and 4,000 courses. Now we are experimenting a lot with revenue and retention to get the same spike, and we will be happy to scale this matrix in the next YC batch. Thank you.",
            "timestamps": [],
            "key": "video",
            "icon": "video"
        }
        setVideoData(fdf);
        setCover(fdf);
        setInProgress(false);
    }

    const handleUpload = async (videoFile) => {
        setInProgress(true);
        const url = videoFile[0].response.url;
        const captionText = await getCaptions('VIDEO', null, null, videoFile[0].response.audio);

        const videoData = {
            type: 'VIDEO',
            source: url,
            title: `mp4 video ${url}`,
            raw: captionText || '',
            timestamps: [],
            key: "video",
            icon: "video"
        };

        setVideoData(videoData);
        setCover(videoData);
        setInProgress(false);
    };

    const content = (
        <div className='video_uploader'>
            <div className='video_uploader__input'>
                <UploadVideoComponent
                    {...other}
                    onSetVideo={handleUpload}
                    setInProgress={setInProgress}
                />
                {inProgress && <LoaderComponent type="inline" label="Generating subtitles, please wait for 10 seconds…"/>}
                {/* <Button type="primary" onClick={handleUploadTest}>test video</Button> */}
            </div>
            {videoData && (
                <div className='video_uploader__video'>
                    <div>
                        <video width="100%" controls>
                            <source src={videoData.source} />
                        </video>
                    </div>
                    <TextArea
                        defaultValue={captions}
                        placeholder={formatMessage({ id: "form_text_data_placeholder" })}
                        className="ai-bot-component__field_counter"
                        maxLength={30000}
                        showCount
                        autoSize={{ minRows: 2, maxRows: 4 }}
                    />
                </div>
            )}
        </div>
    );

    return <div className="">{content}</div>;
};