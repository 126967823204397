import {useMutation} from '@apollo/client';
import {CreateInvites} from '../graphQL/mutations/CreateInvites';

export const useCreateInvites = (mutation = CreateInvites) => {
    const [createInvites, {data, error, loading}] = useMutation(mutation);

    return {
        createInvites,
        invites: data?.createInvites,
        error,
        loading
    }
}