/* global YT */
import './scss/index.scss';
import React, { useEffect, useState, } from 'react';
import {noop} from 'lodash';
import { VideoPlayer, VideoAnyPlayer, VimeoPlayer, VideoGeneratorButton } from './';
import { useGetCaptions, ChangeVideoContainer } from '../../common';
import { YouTubePublicTab } from '../../common/ChangeVideo.container/';
import { useSummaryGenerator } from '../../common/hooks/useSummaryGenerator';

export const VideoGenerator = ({
    task,
    user,
    setInProgress = noop,
    workspace,
    userIsAllowGenerateContent,
    hideEditFunctionality ,
    handleEditVideoTask,
    ...other
}) => {
    const { captions, captionsLoading, getCaptions } = useGetCaptions();
    const [videoSrc, setVideoSrc] = useState();
    const [coverType, setCoverType] = useState(task?.cover?.type);

    const isVideo = coverType === 'VIDEO' && !!videoSrc;
    const creatorOrAdmin = user?.id === task?.creatorId || (user && user?.role?.id === 1);
    const showPublicVideo = !task?.cover && task?.isAutogenerated
    const showAIVideo = !showPublicVideo && (!task?.cover?.source?.length || !task?.cover)
    const handlePrepareToEditVideoTask = (coverData) => {
        handleEditVideoTask({ cover: coverData })
    }
    const {
        summary,
        showSummary,
        handleGenerateSummary,
        handleSummaryClick,
      } = useSummaryGenerator({
        task,
        coverType,
        setInProgress,
        captions,
    });
    useEffect(() => {
        // if (videoSrc && !captionsLoading && !captions && task?.cover?.type === 'YOUTUBE') {
        //     // take only captions for youtue for interactive things

        //     (async () => {
        //         await getCaptions(coverType, videoSrc, task?.cover);
        //     })();
        // }
        setVideoSrc(task?.cover?.source);
        setCoverType(task?.cover?.type);

    }, [task?.id, task?.cover?.source]);

    console.log('userIsAllowGenerateContent', userIsAllowGenerateContent);
    return (
        <>
            {!hideEditFunctionality && userIsAllowGenerateContent && creatorOrAdmin && (
                <ChangeVideoContainer
                    task={task}
                    user={user}
                    workspace={workspace}
                    userIsAllowGenerateContent={userIsAllowGenerateContent}
                    setInProgress={setInProgress}
                    handleEditVideoTask={handleEditVideoTask}
                    {...other}
                />
            )}
            {coverType === "YOUTUBE" && (
                <VideoPlayer
                    {...other}
                    timestampsList={task?.cover?.timestamps}
                    task={task}
                    user={user}
                    videoSrc={videoSrc}
                    showCaptions={true}
                    handleGenerateSummary={handleGenerateSummary}
                    captions={captions}
                    summary={summary}
                    handleSummaryClick={handleSummaryClick}
                    showSummary={showSummary}
                    {...other}
                />
            )}
            {coverType === "VIMEO" && (
                <VimeoPlayer
                    {...other}
                    videoSrc={videoSrc}
                />
            )}
            {(coverType === "VIDEO" && !!task?.cover?.source?.length) &&
                <>
                    {isVideo && (
                        <video width="100%" controls>
                            <source src={videoSrc} />
                        </video>
                    )}
                    <VideoAnyPlayer
                        {...other}
                        timestampsList={task?.cover?.timestamps}
                        task={task}
                        user={user}
                        showCaptions={true}
                        video={{ source: '' }}
                        captions={captions}
                    />
                </>
            }
            {showPublicVideo &&
                <YouTubePublicTab
                    setCover={handlePrepareToEditVideoTask}
                    task={task}
                    user={user}
                    workspace={workspace}
                    userIsAllowGenerateContent={userIsAllowGenerateContent}
                    {...other}
                />
            }
            {showAIVideo &&
                <VideoGeneratorButton
                    task={task}
                    coverFromTask={task?.cover}
                    user={user}
                    setInProgress={setInProgress}
                    workspace={workspace}
                    userIsAllowGenerateContent={userIsAllowGenerateContent}
                    handleEditVideoTask={handleEditVideoTask}
                    {...other}
                />
            }
        </>
    );
};
