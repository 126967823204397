import React from 'react';
import { useIntl } from 'react-intl';
import { TaskWrapperComponent } from '../TaskWrapper.component';
import { Button } from 'antd';

export const TasksListComponent = (props) => {
    const {
        tasks,
        isMore = false,
        fetchMoreTasks,
        classNames,
        handleAddTask,
        placeholder,
        handleTaskSelect,
        isMentorOrAdmin,
        ...other
    } = props;
    const { formatMessage } = useIntl();
    return (
        <div className='wrapper_content'>
            {tasks?.map(
                (task, index) =>
                    (!task.isDraft || (task.isDraft && isMentorOrAdmin)) && (
                        <div key={`add_task_${index}`}>
                            <TaskWrapperComponent
                                {...other}
                                task={task}
                                index={index}
                                isMentorOrAdmin={isMentorOrAdmin}
                            />
                        </div>
                    )
            )}

            {isMore && (
                <div className='task-list-component__more-button wrapper_outer-section'>
                    <Button type='secondary' onClick={fetchMoreTasks} block>
                        {formatMessage({ id: 'load_more' })}…
                    </Button>
                </div>
            )}

        </div>
    );
};
