import React, {useMemo} from 'react';
import {Button} from 'antd';
import {noop} from 'lodash';
import {useIntl} from 'react-intl';

export const ParticipateComponent = (props) => {
    const {formatMessage} = useIntl();

    const {
        isSubscribeForPeriod = false,
        workspaceSubscription,
        onParticipate = noop,
        label = formatMessage({
            id: 'event_enroll_course',
        }),
        type = 'primary',
        block,
        classProps,
        size,
        isFree
    } = props;

    const {isWrong, isFreeMode} = useMemo(() => {
        const isWrong = !isFree && !workspaceSubscription;
        const isFreeMode = isFree || !!workspaceSubscription;
        return {isWrong, isFreeMode}
    }, [isFree, workspaceSubscription]);

    return (
        <>
            {isFreeMode && (
                <div className='participate-buttons-component'>
                    {!isSubscribeForPeriod && (
                        <Button
                            className={classProps || ''}
                            onClick={() => onParticipate(workspaceSubscription)}
                            block={block}
                            type={type}
                            size={size}
                        >
                            {label}
                        </Button>
                    )}
                    {isSubscribeForPeriod && (
                        <div className='participate-component__button'>
                            <Button
                                className={classProps || ''}
                                onClick={() => onParticipate()}
                                type={type}
                                size={size}
                            >
                                {formatMessage({
                                    id: 'event_enroll_lesson',
                                })}
                            </Button>
                            <Button
                                className={classProps || ''}
                                onClick={() => onParticipate({isMonthMode: true})}
                                type={type}
                                size={size}
                            >
                                {formatMessage({
                                    id: 'event_enroll_month',
                                })}
                            </Button>

                            {/* <Button
                        className='btn_white'
                        onClick={() => onParticipate(true, true)}
                        type={typeSingle}
                        size={size}
                    >
                        {formatMessage({
                            id: 'event_enroll_coins',
                        })}
                    </Button> */}
                        </div>
                    )}
                </div>
            )}

            {isWrong && (
                <div className="alert-message">
                    {formatMessage({ id: 'event_enroll_alert' })}
                </div>
            )}
        </>
    );
};
