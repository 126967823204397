const URL = 'https://geolocation-db.com/json/';
const KEY = process.env.REACT_APP_GEO_KEY;
export const getGeoLocation = async () => {
    try {
        const f = await fetch(URL + KEY, {method: 'GET'});
        const data = await f.json();
        return data;
    } catch (e) {
        console.warn(e);
        return null;
    }
};
