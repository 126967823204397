import React, { useState, useEffect } from 'react';
import { WorkspaceContainer, WorkspaceCreateContainer } from '../Components/workspace';
import {TitleComponent} from '../Components/common';

export const Workspace = (props) => {
    const { match, location, workspace, setIsEditMode, ...other } = props;
    const [editMode, setEditmode] = useState(false);
    const {isDisabled} = workspace || {};

    useEffect(() => setIsEditMode(editMode), [editMode]);

    if (isDisabled) {
        return (
            <div className='page'>c
                { /* TODO: Need translations */}
                <TitleComponent title='This school is not available'back={false} />
                <p>
                    Please contact with the owner of this school to prolong the subscription.
                </p>
            </div>
            )
    }

    return !editMode ?
        <WorkspaceContainer
            {...other}
            workspace={workspace}
            setEditmode={setEditmode}
        />
        :
        <WorkspaceCreateContainer
            setEditmode={setEditmode}
            workspaceProp={workspace}
            {...other}
        />
};
