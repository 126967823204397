import React, { useMemo } from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { useProgress, Svg } from "../../common";
import { useNextStepAi } from "../hook/useNextStepAi";

export const TextModuleButton = ({
  userIsAllowGenerate,
  task,
  event,
  handleSaveContent,
  regenerate,
}) => {
  const { inProgress, handleChangeProgress } = useProgress();
  const { formatMessage } = useIntl();
  const { getNextStep } = useNextStepAi();
  const curriculum = useMemo(() => {
    const titles = event?.tasks?.map(t => t?.title?.substring(0, 55)) || [];
    return titles.join(', ');
  }, [event?.tasks]);

  const handleClick = async () => {
    handleChangeProgress({
      message: formatMessage({ id: 'loader_nextstepai_async' }),
      key: 'assessment',
    });

    const content = await getNextStep({
      goal: task?.title || "",
      steptitle: task?.title?.replace(/#/g, '')?.replace('\u0002', "")?.replace('\\', ""),
      source: task.source,
      curriculum: curriculum,
      handleChangeProgress,
    }) || "";

    if (content) {
      handleSaveContent(content);
    }
    handleChangeProgress({ key: 'assessment' });
  };

  return (
    <Button
      className="btn-left"
      disabled={inProgress || !userIsAllowGenerate}
      type={regenerate ? 'secondary' : 'tertiary'}
      size="small"
      onClick={handleClick}
    >
      <Svg name=':icon/logo-full_star' className='icon_16' />
      {regenerate ? formatMessage({ id: "regenerate" }) : formatMessage({ id: "generate" })}
      {' '}
      {formatMessage({ id: 'learning_text' })}
    </Button>
  );
};