import React, { useEffect, useState } from "react"
import { useQuery, gql, useMutation } from "@apollo/client";
import { Loader } from "../../Loader";
import { Button, Card, Form, Input } from "antd";

export const Config = () => {
    const configData = useQuery(GET_CONFIG);
    const [updateConfig] = useMutation(UPDATE_CONFIG);

    const [config, setConfig] = useState({
        templateMailEvent: ""
    });

    const handleUpdateConfig = () => {
        updateConfig({
            variables: {
                config: config
            }
        })
    } 

    useEffect(() => {
        if(configData.data) {
            setConfig(configData.data.config);
        }
    }, [configData.data])

    if(configData.loading) {
        return <Loader />
    }

    return(
        <div className="AdminNavigation">
            <h1>Общие настройки</h1>
            <Card>
                <Form
                    layout="vertical"
                >
                    <Form.Item
                        label="Шаблон письма отправляемый при участии в event"
                    >
                        <Input value={config.templateMailEvent} onChange={(e) => setConfig((p) => ({ ...p, templateMailEvent: e.target.value }))} />
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={handleUpdateConfig}>Сохранить</Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );

}

const GET_CONFIG = gql`
    query GetConfig {
        config {
            templateMailEvent
        }
    }
`;

const UPDATE_CONFIG = gql`
    mutation UpdateConfig($config: UpdateConfig) {
        updateConfig(config: $config) {
            templateMailEvent
        }
    }
`;