import React, { useMemo } from 'react';
import P5Wrapper from "react-p5-wrapper";
import stickers from "./stickers";
import { circles2 } from './circles2';
import { vector } from './vector';
import { vector_static } from './vector_static';
import { wave } from './wave';
import { wave_white } from './wave_white';
import { getRandomInteger } from '../common/utils';

export const SketchP5Component = ({ currentSlideIndex, sketchIndex }) => {
  let visualArray = [circles2, vector, wave, wave_white, vector_static];
  const randomId = useMemo(() => {
    if (sketchIndex) {
      return sketchIndex;

    } else {
      return getRandomInteger(0, 2)
    }
  }, [currentSlideIndex])
  let sketchComponent = visualArray[randomId];
  const key = React.useMemo(() => Math.random(), [sketchComponent]);

  return <P5Wrapper key={key} sketch={sketchComponent} />;
};