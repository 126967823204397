import React, {useContext, useState} from 'react';
import {ResendComponent} from '../Resend.component';
import {useAddResendDomain, useVerifyResendDomain} from '../hook';
import {FirebaseContext} from "../../../context/firebase/firebaseContext";

export const ResendContainer = () => {
    const {workspace} = useContext(FirebaseContext);

    const {customDomain, id: workspaceId, resend} = workspace || {};
    const [resendData, setResendData] = useState(resend);
    const {addResendDomain, loading: domainLoading, error: domainError} = useAddResendDomain();
    const {verifyDomain, loading: verifyLoading, error: verifyError} = useVerifyResendDomain();

    const onUpdateDomainData = (data) => setResendData((prev) => ({...prev, domain: data?.domain || data}));

    const handleAddDomain = async (domain, email) => {
        try {
            const resp = await addResendDomain(domain, workspaceId, email);
            // TODO: Migration - replace to addResendDomain
            onUpdateDomainData(resp?.data?.addResendDomainNew);
        } catch (err) {
            console.warn(err);
        }
    };

    const handleVerifyDomain = async (id) => {
        try {
            const resp = await verifyDomain(id, workspaceId);
            // TODO: Migration - replace to verifyResendDomainNew
            await onUpdateDomainData(resp?.data?.verifyResendDomainNew);
        } catch (err) {
            console.warn(err);
        }
    };

    return <ResendComponent isDisabled={!workspace?.status}
                            loading={domainLoading}
                            customDomain={customDomain}
                            resend={resendData}
                            error={domainError || verifyError}
                            verifyLoading={verifyLoading}
                            onAddDomain={handleAddDomain}
                            onVerifyDomain={handleVerifyDomain}/>
}
