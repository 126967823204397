import {gql} from '@apollo/client';

export const getResult = gql`
    query GetResult($taskId: Int!) {
        result(taskId: $taskId) {
            taskId
            userId
            description
            cover {
                id
                source
                type
                title
            }
            answers {
                answer
                options {
                    id
                    isCorrect
                    text
                }
                questionId
                question {
                    id
                    answer
                }
            }
            state
        }
    }
`;
