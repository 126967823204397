import { useMutation } from "@apollo/client";
import { CreateTask } from "../graphQL/mutations/CreateTask";

export function useCreateTask(mutation = CreateTask) {
    const [createTask, { data, error, loading }] = useMutation(mutation);

    return {
        task: data?.createTask,
        error, loading,
        createTask
    };
}