import React, { useEffect, useState, useMemo } from 'react';
import { useIntl } from "react-intl";
import { Button } from 'antd'

import { WorkspaceCardComponent, WorkspaceCreateContainer } from '../Components/workspace';
import { useIsAllow, PromoBanner, PromoBannerPaid } from "../Components/common";
import { SimpleEventsListContainer } from '../Components/events';
import {useGetWorkspaces} from '../Components/workspace';

export const Workspaces = (props) => {
    const { match, location, user, ...other } = props;
    // const [workspaces, setWorkspaces] = useState();
    const [editMode, setEditmode] = useState();
    const { formatMessage } = useIntl();
    const useIsAllowd = useIsAllow();
    const isAllowPro = useIsAllowd("PRO");
    const {getWorkspaces, workspaces, loading} = useGetWorkspaces();

    const showButton = useMemo(() => {
        const count = workspaces?.length;
        if (!count) {
            return false;
        }
        return count < 10;
    }, [workspaces]);

    useEffect(() => {
        if (isAllowPro) {
            (async () => {
                const resp = await getWorkspaces({
                    variables: {
                        ownerId: user?.id
                    }
                });
            })();
        }
    }, [user?.id]);

    return !editMode ?
        <div className="page">
            <h1>{formatMessage({ id: "group_groups" })}</h1>
            {(!workspaces?.length && isAllowPro) && (
                <PromoBanner
                    header={`${formatMessage({ id: "workspaces_promo" })}, ${user?.name} `}
                    paragraph={formatMessage({ id: "workspaces_description" })}
                    setEditmode={setEditmode}
                />
            )}
            {!isAllowPro && (
                <PromoBannerPaid
                    header={`${formatMessage({ id: "workspaces_promo_paid" })}`}
                    paragraph={formatMessage({ id: "workspaces_description" })}
                    setEditmode={setEditmode}
                />
            )}

            {showButton && (
                <Button type="tertiary"
                    onClick={() => setEditmode(true)}>
                    {formatMessage({ id: 'create_your_school' })}
                </Button>
            )}
            <div className="wrapper_outer-section">
                {!!workspaces?.length &&
                    <>
                        <p>{formatMessage({ id: "workspaces_login_setup" })}</p>
                        <div className='events-list events-list__cards events-list__cards_50 wrapper_content-section'>
                            {workspaces.map(workspace => {
                                if (workspace?.id) {
                                    return (
                                        < WorkspaceCardComponent key={workspace?.id} workspace={workspace} />
                                    )
                                } else {
                                    return null;
                                }
                            }
                            )}
                        </div>
                    </>}
            </div>
            <div className="wrapper_outer-section">
                <hr />
                <h2>{formatMessage({ id: 'worspaces_intro' })}</h2>
                <SimpleEventsListContainer eventsList={[30917]} openFirtsLesson={true} />
            </div>
        </div>
        :
        <WorkspaceCreateContainer
            setEditmode={setEditmode}
            workspaces={workspaces}
            user={user}
            {...other}
        />
};
