import './scss/index.scss';
import React, { useMemo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Avatar } from 'antd';
import { useIntl } from 'react-intl';

import { Svg } from '../../common';
import { useResolutions } from '../../common/utils';
import { openNotification } from '../../notification/Notification.component';
import { useLocalePrefix } from '../../lang';
import { AvatarCustom } from '../../common';

export const ProfileTitleComponent = (props) => {
    const { user, spec } = props;
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();
    const { isSM } = useResolutions();
    const url = useMemo(() => {
        const { id, alias } = user || {};
        const isBoth = id && alias;
        return isBoth ? `${alias}-${id}` : alias || id;
    }, [user]);

    return (
        <>
            <div className='profile__header'>
                <div>
                    <AvatarCustom user={user} size={!isSM ? 24 : 40} />
                    <h1 className='supertitle inline-el '>
                        {user.name}
                    </h1>
                </div>
                {/* <CopyToClipboard
                    text={`https://unschooler.me${localePrefix}/profile/${url}`}
                    onCopy={() => {
                        openNotification('topLeft', {
                            title: formatMessage({
                                id: 'notif_copy_link_title',
                            }),
                            description: (
                                <small>
                                    {formatMessage({
                                        id: 'notif_copy_event_link_description',
                                    })}
                                </small>
                            ),
                            icon: '💌',
                            duration: 3,
                        });
                    }}
                >
                    <Button
                        type='link'
                        className='hide-on-mobile inline-el panel-block__item__btn btn_light '
                    >
                        <Svg
                            name=':icon/share'
                            className={!isSM ? 'icon_32' : 'icon_44'}
                        />
                    </Button>
                </CopyToClipboard> */}
            </div>

        </>
    );
};
