import React from 'react';
import {useIntl} from 'react-intl';
import {noop} from 'lodash';
import {Modal} from 'antd';

import {AuthButtonComponent, TermsInfoComponent} from '../../common';
import YoutubeImage from '../../../images/developed-with-youtube.svg';

export const ApiModalLoginComponent = (props) => {
    const {
        onSetLogin = noop,
        onSetLoginMode = noop,
        onCancelLogin = noop,
        onLoading = noop,
        showLogin,
        ...other
    } = props;

    const {formatMessage} = useIntl();

    const handleStartLogin = (flag) => {
        onLoading(flag);
        onSetLoginMode(flag);
    };

    return (
        <Modal
            visible={showLogin}
            footer={null}
            onCancel={() => onCancelLogin(false)}
        >
            <div className='auth-form'>
                <div className='auth-form__section'>
                    <h1 className='auth-form__title'>
                        {formatMessage({id: 'auth_youtube_form_title'})}
                    </h1>
                    {/* <p>{formatMessage({id: 'auth_youtube_form_description'})}</p> */}
                    <p>{formatMessage({id: 'auth_youtube_form_description2'})}</p>
                    <p>{formatMessage({id: 'auth_youtube_form_description3'})}</p>
                </div>


                <div className='auth-form__section'>
                    <div className="api-video-tags-component-auth-button">
                        <AuthButtonComponent className="dark-button"
                                             onLoginUser={onSetLogin}
                                             onLoading={handleStartLogin}
                                             onCancelLogin={onCancelLogin}/>
                    </div>
                    <div className="api-button-component__note">
                        <img src={YoutubeImage}
                             className="api-button-component__image"
                             alt="Developed with YouTube"/>
                    </div>
                </div>
                    <h2 className='auth-form__title'>✴︎</h2>
                <TermsInfoComponent />
            </div>
        </Modal>
    )
}