import { gql } from '@apollo/client';

export const CreateUserCertificate = gql`
    mutation CreateUserCertificate($userId: Int!, $certificateId: Int!, $userName: String, $workspaceId: Int) {
        createUserCertificate(userId: $userId, certificateId: $certificateId, userName: $userName, workspaceId: $workspaceId) {
            id
            userName
            userId
            user {
                id
            }
            certificateId
            certificate {
                id
                eventId
            }
            createdDate
        }
    }
`;