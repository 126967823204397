import React, {useContext, useState} from 'react';
import {ReportsPaidComponent} from '../ReportsPaid.component';
import {LoaderComponent} from '../../common';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

const USER_PAYMENTS_PATH = 'userPayments/';

export const ReportsPaidContainer = (props) => {
    const {user} = useContext(FirebaseContext);
    const {googleUid} = user;
    const {
        as: Component = ReportsPaidComponent
    } = props;

    const [loading, setLoading] = useState(true);
    const [recipientData, setRecipientData] = useState();
    const [payerData, setPayerData] = useState();
    const [userData, setUserData] = useState();

    if (loading) return  <LoaderComponent/>;
    
    return <Component user={user}
                      uid={googleUid}
                      userData={userData}
                      recipientData={recipientData}
                      payerData={payerData}/>
}
