import React from 'react';
import { Button } from 'antd';
import { LoaderComponent } from '../../common';
import { useIntl } from 'react-intl';

export const StripeUserSubscriptionComponent = (props) => {
    const { endDateData, isCanceled, isSubscription, isAllCourses, onUpdate, loading } = props || {};
    const { formatMessage } = useIntl();

    return <div className="stripe-user-subscription-component">
        {isSubscription && (
            <div className='wrapper_inner-section'>
              <hr />
              <small>
                    {formatMessage({id: isAllCourses ? 'workspace_subscription_confirmation' : 'course_subscription_confirmation'})}
                    {endDateData && (
                        <span>
                            {' '}until{' '}
                            <span className="no-wrap">
                                {endDateData.full}
                            </span>
                        </span>
                    )}
                </small>
                <Button block={true}
                    type="secondary"
                    onClick={onUpdate}
                    disabled={loading}>
                    {loading && (
                        <LoaderComponent type="inline" />
                    )}
                    <span>
                        {isCanceled ? 'Re-subscribe' : 'Unsubscribe'}
                    </span>
                </Button>
            </div>
        )}
    </div>
}