export const getDateData = (d, locale = 'en-GB') => {
    if (!d) return null;

    const date = new Date(d);
    return {
        full: date.toLocaleDateString(locale, {year: 'numeric', month: 'long', day: 'numeric'}).toLowerCase(),
        dayNumber: date.getDay(),
        day: date.toLocaleDateString(locale, {weekday: 'long'}).toLowerCase(),
        date: date.toLocaleDateString(locale, {month: 'long', day: 'numeric'}).toLowerCase(),
        time: date.toLocaleTimeString(locale, {hour: 'numeric', minute: 'numeric'}).toLowerCase()
    }
}
