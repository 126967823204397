import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

export const PurchaseCreditsLink = () => {
  const { formatMessage } = useIntl();

  return (
    <Link to='/credits/' className='link-btn-tertiary ant-btn ant-btn-tertiary '>
      {formatMessage({ id: 'purchase_ai_credits' })}
    </Link>
  )
};

