import React, { useMemo, useState } from "react";
import { Row, Col, Button } from "antd";
import { useIntl } from "react-intl";

import { checkAnswer } from "../common/utils";
import { StudentResultsCard } from "./StudentResultsCard";

export const StudentsResultsCards = ({ studentData, ...other }) => {
    const [showAll, setShowAll] = useState(false);
    const { formatMessage } = useIntl();

    const allAnswers = useMemo(() => {
        let answers = [];
        studentData?.forEach((student) => {
            student.progress?.answers?.forEach((el) => {
                if (el?.description?.trim() !== "") {
                    answers.push({
                        student: student.user,
                        result: el,
                        status: el?.state,
                        createDate: el.modifyDate,
                        task: el.task,
                    });
                }
                el.answers?.forEach((answer) => {
                    const question = el.task?.questions?.find(
                        (question) => question.id === answer.questionId
                    );
                    answers.push({
                        ...answer,
                        student: student.user,
                        result: el,
                        status: checkAnswer(answer, question),
                        question: question,
                        task: el.task,
                    });
                });
            });
        });

        return answers.sort(
            (a, b) => new Date(b.createDate) - new Date(a.createDate)
        );
    }, [studentData]);

    const displayedAnswers = useMemo(() => {
        return showAll ? allAnswers : allAnswers.slice(0, 6);
    }, [showAll, allAnswers]);

    const prepareDataForSuggestions = (answer) => {
        return {
            ...answer?.question,
            studentAnswerAndQuestions: answer?.answer,
            optionsSelectedByStudent: answer?.options,
            createDate: answer?.createDate,
            status: answer?.status,
            studentsNotes: answer?.notes,
            moduleTitleForContext: answer?.task?.title,
        };
    };

    return !!allAnswers?.length && (
        <div className="wrapper_outer-section">
            <Row gutter={[16, 16]} style={{ display: 'flex', flexWrap: 'wrap' }}
           className="wrapper_inner-section"
            >
                {displayedAnswers?.map((answer, index) => (
                    <Col
                        xs={24}
                        sm={12}
                        md={12}
                        key={`${answer.taskId}-${answer.createDate}`}
                        style={{ flexGrow: 1 }}
                    >
                        <StudentResultsCard
                            answer={answer}
                            prepareDataForSuggestions={prepareDataForSuggestions}
                            {...other}
                        />
                    </Col>
                ))
                }
            </Row >
            {!showAll  && (
                <Button Button type="secondary" onClick={() => setShowAll(!showAll)}>
                    {formatMessage({ id: 'show_more' })}
                </Button >

            )}
        </div>
    );
};