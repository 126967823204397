import {gql} from '@apollo/client';

export const GetElevenLabsVoices = gql`
    query GetElevenLabsVoices {
        getElevenLabsVoices {
            labels {
                accent
                age
                gender
            }
            name
            value
            model
            example
        }
    }
`;
