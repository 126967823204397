
import './scss/index.scss';
import React, { useState } from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import { AiPdfComponent } from '../AiPdf.component'

export const AddPdf = ({
    onAddSource,
    index,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const [showModal, setShowModal] = useState(true);
    const onSave = () => {
        setShowModal(false)
    }

    const onLoadFile = (data, fileList) => {
        const { text } = data;
        let name = fileList[0].name

        if (text?.length) {
            if (text?.length > 30000) {
                // If the text is longer than 30000 characters, split it into chunks
                const chunks = text.match(/.{1,30000}/g);
                for (let indexP = 0; indexP < chunks.length; indexP++) {
                    const chunk = chunks[indexP];
                    const chunkObject = {
                        key: "pdf",
                        type: 'TEXT',
                        source: `Part_${indexP}_${name}` || '',
                        title: `Pdf: Part_${indexP}_${name}`,
                        raw: chunk,
                        icon: "text"
                    };                    
                    onAddSource(chunkObject, index + indexP);
                }
            } else {
                // If the text is shorter than or equal to 30000 characters, add it as is
                const object = {
                    key: "pdf",
                    type: 'TEXT',
                    source: name || '',
                    title: `Pdf: ${name}`,
                    raw: text,
                    icon: "text"
                };
                onAddSource(object, index);
            }
        }
    };
    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: 'tab_ai_pdf' })}
                onCancel={() => onSave()}
                onOk={() => onSave()}
            >
                <AiPdfComponent
                    onLoadFile={onLoadFile}
                    {...other}
                />
            </Modal>
        </>
    );
};
