/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { EditUserContainer } from './EditUser.container';
import { useIsAllow, AvatarCustom } from '../common';

export const UserProfile = (props) => {
    const { user, teacher, isAdmin, me, inEvent } = props;
    const { formatMessage } = useIntl();
    const [editProfileMode, setEditProfileMod] = useState();
    const pathName = useMemo(() => {
        const { id, alias, uid, googleUid } = user;
        const aliasOrUid = alias || uid || googleUid;
        return aliasOrUid + '-' + id;
    }, [user]);
    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd('START');
    return (
        <div className='profile-header'>

            <AvatarCustom user={user} size={160} />
            <h2>{user?.name}</h2>

            {/* {isAllow && (
                <p>
                    <a href='https://unschooler.me/payments'>
                        {user?.subscriptionUser?.type} account
                    </a>
                </p>
            )} */}



            {/* {coins?.counter && (
                <div className='coins-widget wrapper_inner-section '>
                    <>
                        {me && (
                            <div>
                                <hr />

                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    href='https://unschooler.me/tutorials/how-to-spend-coins-488'
                                >
                                    <CoinsIndicatorComponent
                                        coins={coins?.counter}
                                        inverse={true}
                                    />
                                </a>
                            </div>
                        )}
                    </>
                </div>
            )} */}

            {/* {user?.canTeach && (
                <div className='time-edit-switcher__row'>
                    {teacher?.subjects?.length && (
                        <>
                            <hr />
                            <p>Skills:</p>
                            <h4 className='user-profile__subjects'>
                                {teacher.subjects.map((subject, key) => (
                                    <span key={key}>
                                        {`✴︎ ${subject.title} `}
                                    </span>
                                ))}
                            </h4>
                        </>
                    )}
                    <hr />
                    <p className='wrapper_inner-section'>
                        {formatMessage({id: 'profile_1on1'})}:{' '}
                        {teacher?.lessonType && teacher.lessonType === 'PAID'
                            ? `$${Math.floor(teacher.cost)}$ /30 min`
                            : 'Free'}
                    </p>

                    <BookingContainer
                        // size='large'
                        type='secondary'
                        classProps='preferences__button margin_right'
                        userMentor={user}
                        // onUpdate={onUpdate}
                        // schedule={schedule}
                        block
                    />
                </div>
            )} */}
            {editProfileMode && (
                <EditUserContainer
                    onEditUserMode={setEditProfileMod}
                />
            )}
        </div>
    );
};
