import React from 'react';
import {useIntl} from 'react-intl';
import { Button } from 'antd';
import { Svg, LoaderComponent } from '../../common';

export const StripeConnectedAccountComponent = ({ isActivated, loading, onConnect }) => {
    const {formatMessage} = useIntl();

    return (
        <div className="stripe-connected-account-component">
            {loading && <LoaderComponent type="overlay" />}
            <div className="stripe-block">
                <Button type="tertiary"
                    onClick={onConnect}
                    disabled={isActivated}
                    className='stripe-block__logo'
                >
                    {formatMessage({id: 'stripe_connect_label'})}
                </Button>
                <Svg
                    name=':icon/stripe'
                    viewBox='0 0 85 35'
                    height='25px'
                    fill="black"
                />
            </div>

            {isActivated && (
                <div>
                    {formatMessage({id: 'stripe_connect_existed_account'})}
                </div>
            )}

            <p>{formatMessage({id: 'stripe_connect_existed_text_1'})}</p>
        </div>
    )
}