import {gql, useMutation} from '@apollo/client';

export const useChangePassword = (mutation = CHANGE_PASSWORD) => {
    const [changePassword, { data, loading, error }] = useMutation(mutation);
    return {changePassword, data, loading, error};
}

const CHANGE_PASSWORD = gql`
    mutation ChangeUserPassword($uid: String!, $password: String!, $token: String!) {
        changeUserPassword(uid: $uid, password: $password, token: $token)
    }
`;