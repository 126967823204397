import {gql} from "@apollo/client";

export const CreateInviteSubscriptions = gql`
    mutation CreateInviteSubscriptions($input: InviteSubscriptionInput!) {
        createInviteSubscriptions(input: $input) {
            id
            studentId
            bundleEventId
            invitedEmail
            isCanceled
            role
            end
            start
            workspaceId
            student {
                id
                name
                picture
                email
            }
        }
    }
`;
