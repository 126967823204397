import './../scss/index.scss';
import React from 'react';
import { Svg, SignInComponent } from '../../common';

export const HeaderGuest = ({ isAllowCreator }) => {
    return (
        <>
            <header className='header_scrollable'>
                {/* <p className='hide-on-mobile'>Turn your questions and articles into educational videos </p> */}

                <Svg
                    name=':icon/logo-full_star'
                    viewBox='0 0 159 30'
                    width='131px'
                    height='24px'
                    className='logo'
                /> 

                <div className='header_btn'>
                    <SignInComponent />
                </div>
            </header>

        </>
    );
};
