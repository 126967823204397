import {useLazyQuery} from "@apollo/client";
import {GetTasksByOrder} from '../graphQL/queries/GetTasksByOrder';

export const useGetTasksByOrder = (variables, query = GetTasksByOrder) => {
    const  [getTasks, { data, loading, error }] = useLazyQuery(query, {
        fetchPolicy: 'network-only'
    });
    return {
        data,
        getTasks,
        loading,
        error
    };
}
