import React, { useMemo } from 'react';
import { WorkspaceMembersComponent } from '../WorkspaceMembers.component';

export const WorkspaceMembersContainer = (props) => {
    const { user, members, workspace, memberGroup, onUpdateWorkspace, ...other } = props;
    const { id: spaceId, members: workspaceMembers } = workspace;

    const membersList = useMemo(() => {
        const newArray = [];
        members?.forEach((el) => {
            const workspaceMember = workspaceMembers.find((m) => m.userId === el.id);
            if (workspaceMember && workspaceMember.permission === memberGroup) {
                newArray.push({
                    ...el,
                    permission: memberGroup
                });
            }
        });
        return newArray;
    }, [workspaceMembers, members, memberGroup]);

    return (
        <WorkspaceMembersComponent
            membersList={membersList}
            spaceId={spaceId}
            memberGroup={memberGroup}
            workspace={workspace}
            workspaceMembers={workspaceMembers}
            onUpdateWorkspace={onUpdateWorkspace}
            {...other}
        />
    )
}