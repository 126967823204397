// import {fetchData} from '../../../context/firebase/firebaseRequests';
import {getCacheManager} from './getCacheManager';

const CACHE_KEY = 'cache-user-';
const {hasCache, getCache, setCache} = getCacheManager();

export const getUserData = async (id, opt = false) => {
    const key = CACHE_KEY + id + opt || '';
    const dbPath = opt ? `users/${id}/${opt}` : `users/${id}`;
    const isCache = hasCache(key);
    // if (!isCache) {
    //     const dbData = await fetchData(dbPath);
    //     setCache(key, dbData.val());
    // }
    return getCache(key);
}