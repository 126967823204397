import React from 'react';
import { useIntl } from 'react-intl';
import { TaskWrapperComponent } from '../TaskWrapper.component';
import { Button } from 'antd';
import { Svg } from '../../common';

export const TasksListSimpleComponent = (props) => {
    const {
        tasks,
        isMore = false,
        fetchMoreTasks,
        classNames,
        handleAddTask,
        placeholder,
        handleTaskSelect,
        ...other
    } = props;
    const { formatMessage } = useIntl();

    return (
        <div className='wrapper_content'>
            {tasks?.map(
                (task, index) =>
                    <div className='add_task' key={`add_task_${index}`}>
                        {handleTaskSelect && (
                            <Button
                                type='text'
                                className='btn-left'
                                onClick={() => handleTaskSelect(task)}
                            >
                                {/* <span> */}
                                <h4 className='Answer__title'>
                                    {/* <Svg name=':icon/add' className='icon_16' /> */}
                                    + {task.title}</h4>
                                {/* </span> */}
                            </Button>
                        )}
                    </div>
                )
            }

            {
                isMore && (
                    <div className='task-list-component__more-button wrapper_outer-section'>
                        <Button type='secondary' onClick={fetchMoreTasks} block>
                            {formatMessage({ id: 'load_more' })}…
                        </Button>
                    </div>
                )
            }

        </div >
    );
};
