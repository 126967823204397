import './scss/index.scss';
import React from 'react';

export const LoaderProgressComponent = (props) => {
    const {type = 'overlay', message, color = '#fb1c1c2b', title} = props;

    return (
        <>
            <div className="loader-component__header">
                <div className=" wrapper_progress_thin">
                    <p className="loader-component__label wrapper_inner-section">{message}</p>
                    <div className="progress-indicator progress-indicator_gray"
                         style={{backgroundColor: color}}/>
                </div>
            </div>
            {type === 'overlay' && (
                <div className={`loader-component loader-component_${type}`}>
                    {title && (
                        <div className="wrapper_content">
                            <h2>{title}</h2>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}