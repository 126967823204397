import { useCallback } from "react";
import { useAiTextToAudio } from "../graphQL/useAiTextToAudio";

export const useGetAudio = () => {
    const { getAudio, loading } = useAiTextToAudio();

    const getTextToAudio = useCallback(
        async (queryString, voice = 'alloy', saveData) => {
            const settings = {
                args: {
                    partOfRequest: queryString,
                    voice: voice
                },
                type: 'audioChunk',
            };

            const response = await getAudio({
                variables: {
                    input: settings,
                    ...(saveData && {saveData})
                },
            });

            const responseJSON = response?.data ? response?.data?.requestAudioApi : null;
            return responseJSON;
        },
        [getAudio]
    );

    return { getTextToAudio, loading };

};
