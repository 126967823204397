import {useCallback} from 'react';
import {useAiChatResponse} from '../graphQL/useAiChatResponse';

export const useGetFeedback = () => {
    const {getChatResponse, error} = useAiChatResponse();

    const getFeedback = useCallback(
        async (ask, previousConversation, domain, goal) => {
            const settings = {
                args: {
                    ask: ask,
                    goal: goal || '',
                    previousConversation: previousConversation || '',
                    domain: domain,
                },
                type: 'tutorialFeedback',
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = response?.data
                ? JSON.parse(response?.data?.requestChatApi)
                : null;
            if (responseJSON?.choices[0]) {
                let responseTrimmed =
                    responseJSON.choices[0].message.content.trim();

                return responseTrimmed;
            } else {
                return responseJSON;
            }
        },
        [getChatResponse]
    );

    return {getFeedback, error};
};
