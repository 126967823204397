import { useAiAudioTokens, useGetAudio } from '../../ai';
import { useTextToAudio } from '../../audio/hook';
import { useTrackCredits } from '../../common/hooks/useTrackCredits';

export const useGenerateAudioChunks = () => {
    const { trackAudioTokens } = useAiAudioTokens();
    const { getAudioUrl, loading } = useTextToAudio();
    const { getTextToAudio } = useGetAudio();
    const { trackCredits } = useTrackCredits()

    const generateAudioForChunks =
        async (chunks, voiceMode, setError) => {
            let audioTokens = 0;
            chunks?.forEach((chunk) => audioTokens += chunk?.description?.length || 0);
            await trackAudioTokens(audioTokens);

            const audioPromises = chunks.map(async (chunk, index) => {
                if (!chunk?.audio) {
                    try {
                        if (voiceMode?.model === 'eleven' || voiceMode?.model === 'custom-voice') {
                            const resp = await getAudioUrl({
                                variables: {
                                    text: chunk.description,
                                    voiceId: voiceMode?.value,
                                }
                            });
                            trackCredits(chunk.description?.length, 'elevenLabs');

                            const { error, url } = resp?.data?.textToAudio || {};
                            if (error) {
                                throw new Error(error);
                            }
                            return { ...chunk, audio: url };
                        } else {
                            const response = await getTextToAudio(chunk.description, voiceMode?.value);
                            return response ? { ...chunk, audio: response } : chunk;
                        }
                    } catch (error) {
                        console.error('Error generating audio:', error);
                        return chunk;
                    }
                } else {
                    return chunk;
                }
            });

            try {
                const updatedChunks = await Promise.all(audioPromises);
                return updatedChunks;
            } catch (error) {
                setError(error);
            }
        };

    return { generateAudioForChunks };
};

