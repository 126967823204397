import { gql } from "@apollo/client";

export const GetAllSkills = gql`
query Skills{
  allSkills {
    tags {
      id
      alias
      subject {
        countResults
        icon
        color
        title
        alias
        id
      }
      title
      countResults
    }
  }
}

`;