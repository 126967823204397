import './../scss/index.scss';
import React from 'react';

export const HeaderTutorial = ({ task, type, customComponent: CustomComponent, workspace, ...other }) => {
    return (
        <header className='header_task'>
            <div></div>
            <div className='header_btn'>
                <CustomComponent task={task} type={type} {...other} workspace={workspace} />
            </div>
        </header>
    );
};
