import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button, Input, Form, Switch } from 'antd';
import { Svg, WysiwygComponent } from '../../common';
import { noop } from 'lodash';
import { getConvertedTimeData, UploadImgComponent } from '../../common';

import { useToggleTeaching } from '../../common/graphQL';

const { TextArea } = Input;

export const MentorEditFormComponent = (props) => {
    const { teacher, onSubmit = noop, updateUser, onClose } = props;
    const { formatMessage } = useIntl();
    const [imagesList, setImagesList] = useState([{ url: teacher?.picture }]);
    const [form] = Form.useForm();
    const [canTeach, setCanTeach] = useState(true);
    const description = useRef(teacher?.description);

    const { toggleTeaching } = useToggleTeaching();

    useEffect(() => {
        if (teacher) {
            form.setFieldsValue({
                ...teacher,
                subjectIds: teacher.subjects?.map((subject) => subject.id) || [],
                timeData: teacher.listTimeData?.map((timeData) => {
                    const updatedTimeData = {
                        ...timeData,
                        startTime: timeData?.startTime - new Date().getTimezoneOffset(),
                        endTime: timeData?.endTime - new Date().getTimezoneOffset(),
                    };
                    return getConvertedTimeData(updatedTimeData);
                }),
                description: teacher.description || "",
                picture: teacher.picture ? teacher.picture : []
            });
        }
    }, [teacher, form]);

    const handleSetImageList = (data) => {
        if (data && data[0]?.response) {
            form.setFieldsValue({
                picture: data[0].response.url
            });
        } else {
            form.setFieldsValue({
                picture: ''
            });
        }
        setImagesList(data);
    };

    const handleRemove = () => {
        setImagesList(null);
        form.setFieldsValue({
            picture: ''
        });
    };

    const handleSubmit = () => {
        onSubmit({
            ...form.getFieldsValue(),
            description: description.current
        });
    };

    const handleCanTeachChange = async (checked) => {
        setCanTeach(checked);
        await toggleTeaching();
        updateUser();
    };
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
                // picture: teacher.picture ? [{ url: teacher.picture }] : [],
                picture: teacher.picture ? teacher.picture : [],
                name: teacher?.name || '',
                description: teacher?.description || ''
            }}
        >
            <div className="profile">
                <div className="profile-image">
                    <Form.Item name="picture"
                        label={formatMessage({ id: 'user_picture_label' })}
                        rules={[{ required: true }]}
                        extra={"300x300px"}
                    >
                        <UploadImgComponent savedImages={imagesList}
                            limitImages={1}
                            onSetImages={handleSetImageList}
                            onRemove={handleRemove} />
                    </Form.Item>
                </div>

                <div className="profile-info">
                    <Form.Item
                        name="name"
                        label={formatMessage({ id: 'user_name_label' })}
                        rules={[{ required: true, 
                            // message: formatMessage({ id: 'name_required' }) 
                        }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={formatMessage({ id: 'booking_about_title' })}
                    >
                        {/* <TextArea
                            autoSize={{
                                minRows: 2,
                                maxRows: 16,
                            }}
                        /> */}
                        <WysiwygComponent
                            onChangeContent={(content) => (description.current = content)}
                            initialState={description.current || ''}
                            placeholder={formatMessage({ id: 'learning_objectives_label', })}
                        />

                    </Form.Item>

                </div>
                <Button type='text' onClick={onClose}>
                    <Svg name=':icon/cross' width={24} className='margin_right' />
                </Button>
            </div>
            <Button type='primary' htmlType='submit'>
                {formatMessage({ id: 'form_button_save' })}
            </Button>
            <hr />
            <Form.Item
                name="canTeach"
                label={formatMessage({ id: 'user_can_teach_label' })}
                className='edit-task-component__question__switcher'
                valuePropName='checked'
            >
                <Switch
                    checked={canTeach}
                    onChange={handleCanTeachChange}
                />
            </Form.Item>

        </Form>
    );
};