import {useCallback} from 'react';
import {useAiChatResponse} from '../graphQL/useAiChatResponse';

export const useGetCommonAi = () => {
    const {getChatResponse, error} = useAiChatResponse();

    const getCommonAi = useCallback(
        async (request, type) => {
            const settings = {
                args: {
                    request: request,
                },
                type: type,
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });
            const responseJSON = response?.data
            ? JSON.parse(response?.data?.requestChatApi)
            : null;

            if (responseJSON?.choices[0]) {
                let responseTrimmed = responseJSON.choices[0]?.message?.content?.trim();
                return responseTrimmed;
            }
        },
        [getChatResponse]
    );

    return {getCommonAi, error};
};
