import React from 'react';
import { TaskProgressComponent } from './'
import { calculateTaskProgress } from '../common/utils/calculateTaskProgress';

export const TaskProgressContainer = ({ task, result, event, ...other }) => {
    return (
        <TaskProgressComponent
            {...calculateTaskProgress(task, event, result)}
            event={event}
            task={task}
            result={result}
            {...other}
        />
    );
};
