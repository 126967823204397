import React, {useEffect, useMemo} from 'react';
import {StripeProductDataComponent} from '../StripeProductData.component';
import {useStripeProduct} from '../hooks';

export const StripeProductDataContainer = (props) => {
    const {eventId, workspaceId, clearStripeData, stripeProductId, stripeAccountData, stripeProduct} = props;
    const {updateStripeProduct, data, loading, error} = useStripeProduct();

    const {prices, wrongEventSubscription} = useMemo(() => {
        if (loading) {
            return  [];
        }
        const oldPrices = stripeProduct?.prices;
        const newPrices = data?.updateStripeProduct?.prices;
        const wrongEventSubscription = data?.updateStripeProduct?.wrongEventSubscription;
        const source = (newPrices || oldPrices);
        const clone = source ? [...source] : [];
        clone.sort((a, b) => a.price - b.price);
        return {prices: clone, wrongEventSubscription};
    }, [stripeProduct, data, loading]);

    const handleUpdate = async ({isClear}) => {
        const stripeAccountId = stripeAccountData?.getConnectedAccount?.stripeAccountId;
        try {
            await updateStripeProduct({
                variables: {
                    productId: isClear ? '' : stripeProductId,
                    ...(stripeAccountId && {stripeAccount: stripeAccountId}),
                    ...(eventId && {eventId}),
                    ...(workspaceId && {workspaceId})
                }
            });
        } catch (err) {
            console.warn(err);
        }
    };

    useEffect(() => {
        if (clearStripeData) {
            (async () => await handleUpdate({isClear: true}))();
        }
    }, [clearStripeData]);

    return <StripeProductDataComponent error={error}
                                       prices={prices}
                                       wrongEventSubscription={wrongEventSubscription}
                                       loading={loading}
                                       onUpdate={handleUpdate}/>
}