import {useMutation} from '@apollo/client';
import {CreateWorkspaceMember} from '../graphQL/mutations/CreateWorkspaceMember';

export const useCreateWorkspaceMember = (mutation = CreateWorkspaceMember) => {
    const [createWorkspaceMember, {data, error, loading}] = useMutation(mutation);

    return {
        createWorkspaceMember,
        data,
        error,
        loading
    }
}