import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addLinkHelper, addYoutubeVideoHelper, addImageHelper } from './helpers';
import EmbedModalComponent from './EmbedModalComponent';
import { VideoModalComponent } from './video';
import { TranslateFieldContainer } from '../../task/TranslateField.container/TranslateField.container';
import { Svg } from '../'
import { Button, Upload } from 'antd';
import { faVideo } from "@fortawesome/free-solid-svg-icons";

export const ToolbarComponent = ({ editor, setError, className = 'toolbar-component', ...other }) => {
    const [showEmbedModal, setShowEmbedModal] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    return (
        <>
            {editor && (
                <div className={className}>
                    <Button type="text"
                        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                        className={`editor-toolbar-button ${editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}`}>
                        <small>H1</small>
                    </Button>
                    <Button type="text"
                        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                        className={`editor-toolbar-button ${editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}`}>
                        <small>H2</small>
                    </Button>
                    <Button type="text"
                        onClick={() => (
                            editor.chain().focus().toggleHeading({ level: 4 }).run()
                        )}
                        className={`editor-toolbar-button ${editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}`}>
                        <small>H3</small>
                    </Button>
                    <Button type="text"
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        className={`editor-toolbar-button ${editor.isActive('bold') ? 'is-active' : ''}`}>
                        <small><strong>B</strong></small>
                    </Button>
                    <Button type="text"
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        className={`editor-toolbar-button ${editor.isActive('italic') ? 'is-active' : ''}`}>
                        <small> <i>I</i></small>
                    </Button>
                    <Button type="text"
                        onClick={() => addLinkHelper(editor)}
                        className={`editor-toolbar-button ${editor.isActive('link') ? 'is-active' : ''}`}>
                        <Svg name=':icon/link' width={16} />
                    </Button>
                    <Button type="text"
                        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                        className={`editor-toolbar-button ${editor.isActive('codeBlock') ? 'is-active' : ''}`}>
                        <Svg name=':icon/code' width={16} />
                    </Button>
                    <Button type="text"
                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                        className={`editor-toolbar-button ${editor.isActive('bulletList') ? 'is-active' : ''}`}>
                        <Svg name=':icon/list_o' width={16} />
                    </Button>
                    <Button type="text"
                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        className={`editor-toolbar-button ${editor.isActive('orderedList') ? 'is-active' : ''}`}>
                        <Svg name=':icon/list_n' width={16} />
                    </Button>
           
                    <Upload accept="image/gif,image/jpeg,image/jpg,image/png"
                        customRequest={(data) => addImageHelper(data, editor, setError)}
                        showUploadList={false}>
                        <Button type="text"
                            className="upload-image-trigger">
                            <Svg name=':icon/cover' width={16} />
                        </Button>
                    </Upload>

                    <Button type="text"
                        onClick={() => addYoutubeVideoHelper(editor)}>
                        <Svg name=':icon/youtube' width={16} />
                    </Button>
                    <Button type="text"
                        onClick={() => setShowVideoModal(true)}>
                        <FontAwesomeIcon icon={faVideo} />
                    </Button>
                    <Button type="text" onClick={() => setShowEmbedModal(true)}> {/* Trigger modal */}
                        <Svg name=':icon/embed' width={16} />
                    </Button>
                    <span className='text_secondary'>|</span>
                    
                    <TranslateFieldContainer
                        fieldValue={editor.getHTML()}
                        classnames='editor-toolbar-button'
                        editor={editor}
                        {...other} />
                </div>
            )}
            {showEmbedModal && (
                <EmbedModalComponent
                    editor={editor}
                    isVisible={showEmbedModal}
                    onClose={() => setShowEmbedModal(false)}
                />
            )}
            {showVideoModal && (
                <VideoModalComponent
                    editor={editor}
                    isVisible={showVideoModal}
                    onClose={() => setShowVideoModal(false)}
                />
            )}
        </>
    )

}