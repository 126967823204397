import React from 'react';

import {EventsListComponent} from '../../events/EventsList.component';

export const RelatedEventsContainer = (props) => {
    const {
        as: Component = EventsListComponent,
        relatedEvents,
        title,
        ...other
    } = props;

    return (
        <Component
            {...other}
            events={relatedEvents}
            className='related-events'
            titleClassName='related-events__title'
            title={title}
        />
    );
};
