import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'antd';

import { FooterContainer } from '../Components/footer';
import {
    useAnalytics,
    MetaComponent,
    useTrackClick,
} from '../Components/common';
import { SketchP5Component } from '../Components/graph/SketchP5Component';
import { HeaderGuest } from '../Components/headers/HeaderGuest/HeaderGuest';
import { LandingTextCards } from '../Components/landing/LandingTextCards.component/LandingTextCards.component';
import { LandingVideoDemo } from "../Components/landing/LandingVideoDemo";

const ARTICLES = [
    {
        title: "Q learning: basics",
        description: 'tutorial, generated by GPT-4',
        link: "https://unschooler.me",
        taskId: 281900,
    },
]
export const LandingVideo = (props) => {
    const { formatMessage } = useIntl();
    const { eventTrack } = useAnalytics();
    const trackClick = useTrackClick();
    const meta = {
        meta_title: formatMessage({ id: 'meta_title_default' })
    };
    const [article, setArticle] = useState(ARTICLES[0])
    const [showLogin, setShowLogin] = useState();

    const handleLogin = () => {
        trackClick('click-login', 'course-tutorial');
        setShowLogin(true);
    };

    return (
        <>
            <MetaComponent meta={meta} />
            <HeaderGuest />
            <div className="wrapper_video-landing">
                <SketchP5Component sketchIndex={4} />
            </div>
            <LandingVideoDemo
                taskId={article.taskId}
                handleLogin={handleLogin}
            />

            <div className='wrapper_promo-section'>
                <LandingTextCards
                    title='Beyond human capabilities, beyond expectations'
                    description="We enable educational institutions to deliver greater value for students beyond human capabilities. Realtime AI engagement changes courses toward students real goals, interests and pains."
                    icon='your-content'
                />

                <hr />

                <div className=' wrapper_hero '>
                    <h2 >
                        Generate your first video-course
                    </h2>

                    <Button
                        type='primary'
                        className='preferences__button '
                        onClick={() => {
                            eventTrack('click-login', {
                                action: 'click-login',
                                clickText: 'Посмотреть все занятия',
                                clickId: 'btn-login-all-events',
                            });
                        }}
                    >
                        Try Unschooler for free
                    </Button>
                </div>
            </div>
            <div className='page'>
                <FooterContainer />
            </div>

        </>
    );
};
