import {useMutation} from '@apollo/client';
import {CreateUserCertificate} from '../graphQL/mutations/CreateUserCertificate';
import {useCallback} from 'react';
import {GetEventForThread} from '../../event/graphQL/queries/GetEventForThread';

export const useCreateUserCertificate = (mutation = CreateUserCertificate) => {
    const [createUserCertificateMutation, { data, error, loading }] = useMutation(mutation);

    const createUserCertificate = useCallback((data, eventData) => {
        const {id, workspaceId} = eventData;
        const resp = createUserCertificateMutation({
            ...data,
            refetchQueries: () => [{query: GetEventForThread, variables: {id, ...(workspaceId && {workspaceId})}}]
        })
    }, [createUserCertificateMutation]);

    return {
        createUserCertificate,
        data,
        error,
        loading
    };
}