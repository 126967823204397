import React, { useEffect, useState, useCallback } from 'react';
import { noop } from 'lodash';

import { BundlesListComponent } from '../../bundles';
import { useAnalytics , useIsAllow} from '../../common';
import { useGetEventsBundles } from '../../events/hooks/useGetEventsBundles';
import { useIsPermit } from '../../roles/hook/useCheckPermission';

export const ListBundlesContainer = (props) => {
    const {
        as: Component = BundlesListComponent,
        filterData,
        index,
        getWorkspaceData,
        eventsLists,
        createCourses,
        typeProp,
        limit,
        setAllEvents = noop,
        workspace,
        ...other
    } = props;
    const LIMIT = limit || 9;
    const { eventTrack } = useAnalytics();
    const { getBundles, loading } = useGetEventsBundles();
    const [events, setEvents] = useState([]);
    const [isShowMore, setIsShowMore] = useState(false);
    const [offset, setOffset] = useState(0);

    const useIsAllowd = useIsAllow();
    const isAllowCreateCourses = useIsAllowd("PREMIUM") || createCourses;
    const { checkPermit } = useIsPermit()
    const editOthersCourses = checkPermit(workspace, 'editOthersCourses')

    const handleEventClick = (e, event) => {
        const eventData = e;
        eventData.clickText = event.title;
        eventData.clickId = 'click-bundle-card';
        eventData.clickUrl = `${window.href}bundles/${event.id}`;
        eventTrack('click-bundle-card', eventData);
    };

    const fetchMoreEvents = async () => {
        const eventsList = await handleEvents(
            filterData,
            offset,
        );
        setOffset((prev) => prev + LIMIT);
        setIsShowMore(eventsList?.length === LIMIT);
        setAllEvents((prev) => [...(prev || []), ...(eventsList || [])]);
        setEvents((prev) => [...(prev || []), ...(eventsList || [])]);
    };

    const handleEvents = useCallback(
        async (filter, offset = 0) => {
            const { ...rest } = filter;

            const resp = await getBundles({
                variables: {
                    filter: {
                        ...rest,
                        type: "BUNDLE",
                    },
                    isOwner: !!editOthersCourses,
                    limit: LIMIT,
                    offset
                },
                fetchPolicy: "network-only"
            });

            setAllEvents((prev) => [...(prev || []), ...(resp?.data?.events || [])]);
            return resp?.data?.events || [];

        },
        [filterData]
    );
    useEffect(() => {
        const initFetch = async () => {
            const eventsList = await handleEvents(filterData);
            if (eventsList) {
                setEvents(() => [...eventsList]);
                setOffset((prev) => prev + LIMIT);
                setIsShowMore(eventsList?.length === LIMIT);
            }
        };
        initFetch();
    }, []);

    return (
        <Component {...other}
            bundles={events}
            filterData={filterData}
            isLoading={loading}
            events={events}
            isShowMore={isShowMore}
            onShowMore={fetchMoreEvents}
            onClick={handleEventClick}
            isAllow={isAllowCreateCourses}
        />
    )
}
