import React, { useState } from 'react';
import { Input } from 'antd';
import { VideoGeneratorButton } from '../VideoIframe';

export const AIVideoTab = ({
    ...other }) => {
    // const [contentForVideo, setContentForVideo] = useState(task?.description || task?.source);

    return (
        <div className="ai-video-tab">
            {/* <Input.TextArea
                value={contentForVideo}
                onChange={(e) => setContentForVideo(e.target.value)}
                placeholder="Edit content for video"
                rows={4}
                style={{ width: '100%', marginBottom: '10px' }}
            /> */}
            <VideoGeneratorButton
                {...other}
            />
        </div>
    );
};