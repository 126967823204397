export const getPreparedPriceForm = (formData, cost, userCost, event) => {
    let data = { ...formData };

    if ((cost === 0 && userCost === 0) || (cost == null && userCost == null)) {
        delete data.userCost;
        delete data.cost;
        if (cost === 0 && userCost === 0) data.price = null;
    } else if (data.userCost && data.cost) {
        data.price = {
            total: data.cost,
            forTeacher: data.cost,
            forStudent: data.userCost,
            provider: 'STRIPE',
        };
 
    } else {
        data.price = cost && cost !== 0
            ? {
                total: cost,
                forTeacher: cost,
                forStudent: userCost,
                provider: 'STRIPE',
            }
            : event?.price
                ? null
                : undefined;
    }

    delete data.isPaid;
    delete data.mentorAmount;
    delete data.userCost;
    delete data.cost;
    return data;
};