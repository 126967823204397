import React, {useState, useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {TitleComponent} from '../Components/common';
import {MetaComponent} from '../Components/common';
import {RatingWidget} from '../Components/rating/RatingWidget';
// import {fetchData} from '../context/firebase/firebaseRequests';
import {getConvertedUsersFromCoins} from '../Components/common/utils/getConvertedUsersFromCoins';
import translate from '../lang/translate';
import {useGetRatingUser} from '../Components/common/graphQL';
import {Loader} from '../Components/Loader';

export const Rating = () => {
    const {formatMessage} = useIntl();
    const {userRating} = useGetRatingUser();
    const [users, setUsers] = useState(1);
    const [userRatingCoins, setUserRatingCoins] = useState([]);
    const metaMemo = useMemo(() => {
        const title = formatMessage({id: 'meta_title_rating'});
        const description = formatMessage({id: 'meta_description_rating'});
        const url = 'https://unschooler.me/rating/';

        const meta = {
            meta_title: title,
            meta_description: description,
        };
        const og = {
            title: title,
            description: description,
            url: url,
        };

        const twitter = {
            tw_title: title,
            tw_url: url,
            tw_description: description,
        };

        return {meta, og, twitter};
    }, []);

    const getCoins = async () => {
        // let data = await fetchData('coins');
        // let outcome = getConvertedUsersFromCoins(data.val());
        // setUserRatingCoins({users: outcome});
    };

    useEffect(() => {
        (async () => await getCoins())();
    }, []);

    if (!userRating) {
        return <Loader />;
    }

    return (
        <>
            <MetaComponent
                meta={metaMemo.meta}
                og={metaMemo.og}
                twitter={metaMemo.twitter}
            />

            <div className='page'>
                <section className='content-block'>
                    <TitleComponent title={translate('rating_coins')} />
                    <div className='Answer__info wrapper_inner-section'>
                        <p>
                            Coins are a virtual currency that you earn and spend
                            on new knowledge. Share and collect them: pass
                            quizzes, publish your projects and tutorials, collect
                            likes, and enroll in paid courses.{' '}
                            <a
                                target='_blank'
                                rel='noreferrer'
                                href='https://unschooler.me/tutorials/how-to-spend-coins-488'
                            >
                                How to learn & earn coins?
                            </a>
                        </p>
                    </div>
                    <RatingWidget
                        returnUsersNumber={(number) => setUsers(number)}
                        subjectRating={userRatingCoins}
                        type='card'
                    />
                </section>

                {/* old rating */}
                <section className='content-block'>
                    <h2>{translate('popular_mentor_leader')}</h2>
                    <RatingWidget
                        returnUsersNumber={(number) => setUsers(number)}
                        userRatingCoins
                    />
                </section>

                {userRating &&
                    userRating.map((subjectRating) => {
                        return (
                            <section
                                key={subjectRating.subject.id}
                                className='content-block'
                            >
                                <h4 className='title-border'>
                                    {subjectRating
                                        ? subjectRating.subject.title
                                        : 'Coins raiting'}
                                </h4>
                                <RatingWidget
                                    key={subjectRating.subject.id}
                                    subjectRating={subjectRating}
                                />
                            </section>
                        );
                    })}
            </div>
        </>
    );
};
