import React, {useContext} from 'react';
import {EventsListActionsComponent} from '../EventsListActions.component';
import {useDeleteEventsList, useEditEventsListOrder} from '../../workspace';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const EventsListActionsContainer = (props) => {
    const {
        as: Component = EventsListActionsComponent,
        eventsLists = [],
        index,
        workspaceId,
        ...other
    } = props;
    const {onUpdateWorkspace} = useContext(FirebaseContext);
    const {deleteEventsList, loading: deleteLoading} = useDeleteEventsList();
    const {editEventsListOrder, loading: editLoading} = useEditEventsListOrder();

    const count = eventsLists.length;

    const handleUpdate = async (input) => {
        try {
            await editEventsListOrder({
                variables: {
                    input
                }
            });
            onUpdateWorkspace();
        } catch (err) {
            console.warn(err.message);
        }
    }

    const onUp = async () => {
        const lists = eventsLists?.map((item) => ({
            id: item.id,
        }));
        const item = lists.splice(index, 1)[0];
        lists.splice(index - 1, 0, item);
        const input = lists.map((item, i) => ({id: item?.id, order: i}));
        await handleUpdate(input);
    };

    const onDown = async () => {
        const lists = eventsLists?.map((item) => ({
            id: item.id,
        }));
        const item = lists.splice(index, 1)[0];
        lists.splice(index + 1, 0, item);
        const input = lists.map((item, i) => ({id: item?.id, order: i}));
        await handleUpdate(input);
    };

    const onDelete = async () => {
        const id = eventsLists?.[index]?.id;
        await deleteEventsList({
            variables: {
                id
            }
        });
        onUpdateWorkspace();
    };

    return <Component isNotFirst={index > 0}
                      isNotLast={index < count - 1}
                      isNotSingle={count > 1}
                      onUp={onUp}
                      index={index}
                      onDown={onDown}
                      onDelete={onDelete}
                      loading={deleteLoading || editLoading}
                      {...other}/>
}