import React, { useState } from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';

import { SelectSkillsComponent } from '../../skillsTree/SelectSkills.component/SelectSkills.component';

export const LearnSkillButtonComponent = (props) => {
    const { type = 'tertiary', size = 'middle', user, skills } = props;
    const { formatMessage } = useIntl();
    const [showSelectSkills, setShowSelectSkills] = useState();

    return (
        <>
            <Button type='secondary'
                onClick={() => setShowSelectSkills(true)}>
                {formatMessage({
                    id: 'skills_learn_my',
                })}
            </Button>
            {skills && (
                <SelectSkillsComponent
                    showSkillsSelect={showSelectSkills}
                    onCancelSelect={setShowSelectSkills}
                    skills={skills}
                    user={user}
                />
            )}
        </>
    );
};
