import { gql } from "@apollo/client";

export const GetSkills = gql`
query GetSkils($userId: Int) {
  skills(userId: $userId) {
    tags {
      id
      alias
      subject {
        countResults
        icon
        color
        title
        alias
        id
      }
      title
      countResults
    }
  }
}

`;