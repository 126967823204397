import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { VIMEO_VIDEOS } from '../graphQL/queries/GetVimeoVideos';

export const useVimeoVideos = (query = VIMEO_VIDEOS) => {
    const [getVimeoVideos, { data, loading, error }] = useLazyQuery(query);

    const getVimeoVideosList = useCallback(async (ask, vimeoFolder) => {
        const resp = await getVimeoVideos({
            variables: {
                q: ask,
                vimeoFolder
            }
        });

        if (!!resp?.data?.getVimeoVideos) {
            let items = resp?.data?.getVimeoVideos;
            let itemsClone = items.map((item) => {
                const { id, title, url, description, thumbnail } = item;
                return {
                    source: url,
                    title: title,
                    summary: description,
                    thumbnail: thumbnail,
                    type: 'VIMEO',
                    videoId: id
                }
            })
            return itemsClone;
        }
        return resp?.data?.getVimeoVideos;
    }, [getVimeoVideos]);

    return {
        data,
        getVimeoVideosList,
        loading,
        error
    }
}
