import './scss/index.scss';
import React, { useState, useEffect } from 'react';
import { HeaderSkills } from './HeaderSkills.component';
import { BgProfileComponent } from './BgProfile.component';
import { find, maxBy } from 'lodash';
import { useGetSpecs } from '../common/graphQL/specs/useGetSpecs';

export const HeaderSkillsContainer = ({
    skills,
    handleSpecChange,
    user,
    sayMeSpec,
    selectedSpec,
    hideAll,
}) => {
    const { specs } = useGetSpecs();
    const [specSelected, setSpecSelected] = useState(selectedSpec);

    const onChangeSpec = (specialization) => {
        setSpecSelected(specialization);
        handleSpecChange(specialization);
        sayMeSpec(specialization);
    };

    useEffect(() => {
        let maxSpec = maxBy(specs, 'counter');

        if (specs) {
            if (maxSpec?.counter) {
                sayMeSpec(maxSpec);
                setSpecSelected(maxSpec);
            } else {
                sayMeSpec(null);
                setSpecSelected(specs[0]);
            }
        }
    }, [specs]);

    return (
        <>
            <HeaderSkills
                specialization={specSelected || selectedSpec}
                handleSpec={onChangeSpec}
                user={user}
                hideAll={hideAll}
                skills={skills}
                specs={specs}
            />
            <BgProfileComponent specialization={specSelected || selectedSpec} />
        </>
    );
};
