import {useLazyQuery} from '@apollo/client';
import {GetUserCertificates} from '../graphQL/queries/GetUserCertificates';

export const useGetUserCertificates = (query = GetUserCertificates) => {
    const [getUserCertificates, { data, loading, error }] = useLazyQuery(query);
    return {
        getUserCertificates,
        loading, error,
        data
    }
}