import {getUA} from 'react-device-detect';
import {isString} from 'lodash'

export const checkUserAgent = (testData) => {
    
    const agent = getUA.toLowerCase();

    if (isString(testData)) {
        return  agent.includes(testData.toLowerCase())
    }

    let isAgent = false;

    testData.forEach((item) => {
        if (agent.includes(item.toLowerCase())) {
            isAgent = true;
        }
    });
    return isAgent;
}
