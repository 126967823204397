import './scss/index.scss';

import React, {useState} from 'react';
import {Drawer, Button} from 'antd';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

export const SortQuestionsComponent = (props) => {
    const {list, showModal, onSort, onCloseModal} = props;
    const [items, setItems] = useState(list);

    const getQuestion = (string) => {
        return (
            string
                ?.match(/<h\d>(.*?)<\/h\d>/)?.[1]
                ?.trim()
                ?.substring(0, 50) ||
            string
                ?.match(/<p>(.*?)<\/p>/)?.[1]
                ?.trim()
                ?.substring(0, 50)
        );
    };

    const SortableItem = SortableElement(({value}) => (
        <div className="sort-questions-component__item"
             draggable="true">
            {getQuestion(value)}
        </div>
    ));
    const SortableList = SortableContainer(({items}) => {
        return (
            <div className="sort-questions-component__list">
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} index={index} value={value.question} />
                ))}
            </div>
        );
    });

    const handleSortEnd = ({oldIndex, newIndex}) => setItems(arrayMoveImmutable(items, oldIndex, newIndex));

    const handleSave = () => {
        const newList = items.map((question, i) => ({...question, order: i}))
        onSort(newList);
    };

    return (
        <Drawer
                visible={showModal}
                destroyOnClose={true}
                onClose={() => onCloseModal(false)}
                size="large"
                placement="right">
            <h2>Change the order of steps</h2>
            <SortableList items={items}
                          lockAxis="y"
                          onSortEnd={handleSortEnd}/>
            <Button type="primary"
                    className="sort-questions-component__action"
                    size="large"
                    onClick={handleSave}>
                Save
            </Button>
        </Drawer>
    )
};