import React, { useContext, useMemo, useState } from 'react';
import { ArrowUpOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import classNames from 'classnames';
import { noop } from 'lodash';

import {
    Svg,
    LoginContainer,
} from '../common';

import { FirebaseContext } from '../../context/firebase/firebaseContext';
import { useLocalePrefix } from '../lang';

export const TaskActionsCard = (props) => {
    const { task, handleDelete, index, workspace } = props;
    const { user } = useContext(FirebaseContext);
    const [localePrefix] = useLocalePrefix();

    const [showLogin, setShowLogin] = useState();
    const editFunctions = (user?.id === task?.creator?.id || user?.role?.id === 1);
    let answerActionClass = classNames('Answer__actions', {
    });

    return (
        <div className={answerActionClass}>
            <div
                className='Answer__actions_top'
                onClick={(e) => e.preventDefault()}
            >
                {!task?.id &&
                    <Button
                        type='link link-btn_light'
                        size='small'
                        onClick={() => handleDelete(index)}
                    >
                        <span>
                            <Svg
                                name=':icon/delete'
                                className='icon_16'
                            />
                        </span>
                    </Button>
                }
                {/* <Button type="text" icon={<ArrowUpOutlined />}></Button> */}
            </div>
            <LoginContainer
                showLogin={showLogin}
                setCancelLogin={setShowLogin}
            />
        </div>
    );
};
