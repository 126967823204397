import {useCallback} from 'react';
import {useMutation} from '@apollo/client';
import {AddCustomDomain} from '../graphQL/mutations/AddCustomDomain';

export const useAddCustomDomain = (mutation = AddCustomDomain) => {
    const [addDomain, { data, error, loading }] = useMutation(mutation);

    const addCustomDomain = useCallback(async (domain, workspaceId) => {
        const resp = await addDomain({
            variables: {
                domain,
                workspaceId,
                environment: process.env.REACT_APP_ENVIRONMENT
            }
        });
        return resp;
    }, [addDomain])

    return {
        data,
        error, loading,
        addCustomDomain
    };
}