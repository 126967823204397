import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'antd';

import { ProfileLink, Svg } from '../../common';
import {useCreateWorkspaceMember} from '../hooks';

export const AddStudentsFromCoursesContainer = ({
    allEvents,
    workspace,
    user,
    onUpdateWorkspace,
}) => {
    const { formatMessage } = useIntl();
    const {createWorkspaceMember} = useCreateWorkspaceMember();
    const [uniqueUsers, setUniqueUsers] = useState([]);
    const [showUniqueUsers, setShowUniqueUsers] = useState(false);


    const getListOfUserSubscriptions = () => {
        const uniqueUsersMap = new Map();
        allEvents?.forEach(event => {
            event?.userSubscriptions?.forEach(subscription => {
                if (subscription?.student?.id !== 10) { // Exclude users with id: 10
                    uniqueUsersMap.set(subscription?.student?.id, {
                        id: subscription?.student?.id,
                        name: subscription?.student?.name,
                        picture: subscription?.student?.picture,
                    });
                }
            });
        });
        const workspaceMemberIds = new Set(workspace?.members?.map(member => member.id));
        const filteredUsers = Array.from(uniqueUsersMap.values()).filter(user => !workspaceMemberIds.has(user.id));
        return filteredUsers;
    }
    const addStudentsFromCourses = async () => {
        setShowUniqueUsers(!showUniqueUsers);
        const userSubscriptions = getListOfUserSubscriptions();
        setUniqueUsers(userSubscriptions);
    };

    const handleAddUser = async (userId) => {
        await createWorkspaceMember({
            variables: {
                input: {
                    userId,
                    permission: 'User',
                    workspaceId: workspace.id
                }
            }
        });

        const updatedUniqueUsers = uniqueUsers.filter(user => user.id !== userId);
        setUniqueUsers(updatedUniqueUsers);
        onUpdateWorkspace();
    }

    return (
        <>
            <Button size='small' type="text" onClick={addStudentsFromCourses}>
                {showUniqueUsers ?
                    formatMessage({ id: 'hide_students' }) :
                    formatMessage({ id: 'add_students' })
                }

            </Button>
            {showUniqueUsers && (
                <div className="workspace-members-component">
                    {uniqueUsers.map((user) => (
                        <div key={user.id} className='wrapper_inner-section flex-between'>
                            <ProfileLink
                                user={user}
                                isEllipsis={true}
                                style={{ maxWidth: 200 }}
                            />
                            <Button
                                className='inline-el link-btn_light'
                                type='text'
                                onClick={() => handleAddUser(user.id)}
                            >
                                <Svg name=':icon/add' className='btn-icon icon_16' />
                            </Button>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};
