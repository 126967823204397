import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import firebase from "../../../../model/firebase";

export function useGetUser(userId) {
    const [queryUser, { data, loading, error }] = useLazyQuery(GET_USER, {
        fetchPolicy: "network-only"
    });

    async function getUser(id, alias, partnerData) {
        const result = await queryUser({
            variables: {
                userId: id || userId,
                alias: alias,
                ...(partnerData?.token && partnerData?.source && {
                    partnerData
                })
            },
            fetchPolicy: "network-only"
        });
        return result.data?.user;
    }

    useEffect(() => {
        if(firebase.auth().currentUser) {
            (async () => await queryUser({
                variables: {
                    userId
                }
            }))();
        }
    }, []);

    return {
        user: data?.user,
        loading,
        error,
        getUser
    };
}

const GET_USER = gql`
    query GetUser($userId: Int, $alias: String, $partnerData: PartnerDataInput) {
        user(id: $userId, alias: $alias, partnerData: $partnerData) {
            id
            alias
            googleUid
            createDate
            email
            phone
            name
            description
            picture
            role {
              id
              title
              description
            }
            hidden
            canTeach
            coins
            coinsBalance
            lang {
              id
              title
            }
            subscriptionUser {
                type
                startDate
                endDate
                isCanceled
            }
            achievements {
                order
                title
                label
                active
                icon
                defIcon
                description
            }
            lastVisit
            emailVerified
            customVoiceId
            defaultVoiceId
        }
    }
`;
