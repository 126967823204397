import React from 'react';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { openNotification } from '../notification/Notification.component';
import { useTrackClick } from './hooks/useTrackClick';
import { Svg } from './';

export const SharingButton = ({ link, withLabel, classes }) => {
    const { formatMessage } = useIntl();
    const trackClick = useTrackClick();

    return (
        <CopyToClipboard
            text={link}
            onCopy={() => {
                openNotification('topLeft', {
                    title: formatMessage({
                        id: 'notif_copy_link_title',
                    }),
                    description: formatMessage({ id: 'notif_copy_link_description' }),
                    icon: '🤘',
                    duration: 3,
                });
            }}
        >
            <Button
                type="text"
                size='small'
                // className='link-btn_light answer__actions_bottom_btn btn_toggle'
                classes={classes}
                onClick={() => trackClick('click-share', link, link)}
            >
                <Svg
                    name=':icon/share'
                    className='icon_20'
                />
                {withLabel &&
                    formatMessage({ id: 'event_btn_friend' })
                }

            </Button>
        </CopyToClipboard>
    );
};