import React from 'react';
import {Button, Form, Input} from 'antd';

export const CleanJobsComponent = () => {
    const [form] = Form.useForm();
    const {TextArea} = Input;

    const handleFinish = (data) => {
        const {forRemoving, source} = data;

        if (source && forRemoving) {
            const ids = forRemoving.split(',');
            const souseData = JSON.parse(source);
           souseData.filter((item) => !ids.includes(item[1]));
        }

    };

    return (
        <div className="page">
            <h1>Clean Jobs</h1>
            <Form form={form}
                  layout='vertical'
                  onFinish={handleFinish}>
                <Form.Item name="source" label="Source for cleaning">
                    <TextArea/>
                </Form.Item>
                <Form.Item name="forRemoving"
                           label="Ids for removing">
                    <Input placeholder="Please separate ids by comma"/>
                </Form.Item>
                <Form.Item shouldUpdate={true}>
                    <Button
                        type='primary'
                        size='large'
                        htmlType='submit'
                    >
                        Clear
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
