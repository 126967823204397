import React from 'react';
import { useIntl } from 'react-intl';
import { AITutorialCourseContainer } from '../index';

export const AIAllFeaturesContainer = ({
    handleLoginUser,
    user,
    ...other
}) => {

    const { formatMessage } = useIntl();
    return (
        <>
            <h1>{formatMessage({ id: 'tab_ai_course' })}</h1>
            <div className="ai-bot_wrapper__respond flex-left-column">
                <AITutorialCourseContainer
                    handleLogin={handleLoginUser}
                    user={user}
                    {...other}
                />
            </div>
        </>
    );
};
