import {uiIcons} from '../../../images/uiIcons';

export const Svg = (props) => (
  <svg
    {...props}
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={props.viewBox || `0 0 30 30`}
    fill={props.fill}
    width ={props.width}
    height ={props.height}
  >
    <g>{uiIcons[props.name]}</g>
  </svg>
);
