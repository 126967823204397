import React, {useContext, useState} from 'react';
import {Drawer} from 'antd';
import {get, noop} from 'lodash';
import {useHistory, useLocation} from 'react-router-dom';
import {FirebaseContext} from '../../context/firebase/firebaseContext';
import { EditTask } from './EditTask/EditTask';

export const CreateTaskDrawer = (props) => {
    const history = useHistory();
    const {user} = useContext(FirebaseContext);
    const {state} = useLocation();
    const {
        onEditTask = noop,
        // match
    } = props;

    // const [
    //     currentTheme,
    //     // setCurrentTheme
    // ] = useState({
    //     themeName: get(state, 'data.themeName'),
    //     key: get(state, 'data.themeId'),
    // });

    const onSubmitCallback = () => {
        onEditTask(false);
    };

    return (
        <Drawer
            onClose={() => {
                history.goBack();
            }}
            width={640}
            visible
            style={{}}
            className='drawer-gray'
            closable={true}
        >
            <EditTask
                // tags={currentTags}
                onSubmitCallback={onSubmitCallback}
                previousLocation={history.location.pathname}
                answerId={props.match.params.id || undefined}
                user={user}
            />
        </Drawer>
    );
};
