import React from "react";
import { useIntl } from "react-intl";
import { SubscriptionContainer } from "../subscription";
import { Button } from "antd";
import { SignInComponent } from "../common";

export const PricingCard = ({
    activeSubscriber,
    isNotVerified,
    trialSubscriber,
    handleUnSubscribe,
    isMyPlan,
    planUpgrade,
    title,
    plan,
    desciption,
    price,
    isCanceled,
    onShowVerificationModal
}) => {
    const { formatMessage } = useIntl();

    return (
        <div className="pricing-item__col-text top">
            <h2>
                {title}
            </h2>
            <small>
                {desciption}
            </small>
            <div>
                <h3 className="event-card-component__title wrapper_inner-section ">
                    ${price}/
                    {formatMessage({ id: "month", })}
                </h3>
            </div>

            {plan !== "FREE" && (
                <>
                    {isMyPlan &&
                        <>
                            {!isCanceled && (
                                <Button type="secondary"
                                    onClick={handleUnSubscribe}>
                                    {formatMessage({
                                        id: "payment_request_modal_cancel",
                                    })}
                                </Button>
                            )}
                            <p className="red">Your Plan</p>
                        </>
                    }

                    {(activeSubscriber && !isCanceled) ?
                        <p>{'Cancel current plan to start'}</p>
                        :
                        <SubscriptionContainer
                            type="primary"
                            // size='large'
                            block="true"
                            plan={plan}
                            title={planUpgrade && formatMessage({ id: "upgrade" })}
                            onShowVerificationModal={onShowVerificationModal}
                        />
                    }

                    {(activeSubscriber && planUpgrade) &&
                        <small>
                            50% OFF 1 month with promocode "UPGRADE"
                        </small>
                    }
                </>
            )}
            {plan === "FREE" &&
                <SignInComponent
                    type='tertiary'
                    block='true'
                    title=" Try for free"
                    isSighUp={true}
                />

            }
        </div>
    );
};
