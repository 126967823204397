import "./scss/index.scss";
import React, { useRef, useState } from "react";
import { Button } from "antd";
import ReactToPrint from 'react-to-print';

import { Svg } from '../common';
import { CertificatePdf } from './CertificatePdf'; // Assuming you have this component

export const CertificateToPrint = ({...other}) => {
    const componentRef = useRef();

    return (
        <div>
            <ReactToPrint
                trigger={() =>
                    <Button size="small" type='text' >
                        <Svg
                            name=':icon/download'
                            className='icon_24'
                        />
                        PDF
                    </Button>
                }
                content={() => (componentRef.current)}
            />
            <div style={{ display: "none" }}>
                <CertificatePdf ref={componentRef} {...other} />
            </div>
        </div>
    );
};
