import './scss/index.scss';
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import { LoginContainer, useConvertedPrice, LoaderComponent } from '../../common';
import { FEATURECREDITS } from '../../constant.js';

export const StripeCustomPaymentComponent = (props) => {
    const { pricesList, loading, loadingPaymentIndex, showLogin, onPay, onLoginUser, onSetShowLogin } = props || {};
    const { getPrice } = useConvertedPrice();

    if (!pricesList?.length) {
        return null;
    }

    return (
        <div className="wrapper_content">
            {FEATURECREDITS.map((feature, i) => (
                <ul className="features_list" key={`appsumo_feature_second_${i}`}>
                    <li className=" text-left">
                        {!feature.soon &&
                            <span className='margin_right'> ✔ </span>
                        }
                        {feature?.link ?
                            <a href={feature?.link}>
                                {feature?.title}
                            </a>
                            :
                            feature?.title
                        }
                    </li>
                </ul>
            ))}
            <div className="stripe-custom-payment-component">
                {pricesList?.map((price, i) => (
                    <div className="stripe-custom-payment-component__item pricing_card" key={price?.priceId}>
                        <div className='wrapper_inner-section'>
                            <h2 className='pricing_card-title'>{price?.label}</h2>
                            <p className='pricing_card-price'>{getPrice(price.price, price.currency)}</p>
                        </div>
                        <Button type="tertiary"
                            disabled={loading}
                            onClick={() => onPay(price)}>
                            <span>
                                {(loadingPaymentIndex === i) && (
                                    <LoaderComponent type="inline" />
                                )}
                                Purchase
                            </span>
                        </Button>
                    </div>
                ))}
            </div>
            <hr />
            <div className="wrapper_outer-section">
                <h2>Usage:</h2>
                <table className='wrapper_inner-section'>
                    <thead>
                        <tr>
                            <th>Feature</th>
                            <th>500 credits</th>
                            <th>1000 credits</th>
                            <th>5000 credits</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>ElevenLabs voices or Cloned voices in videos (1 video takes 8-11 credits)</td>
                            <td>~40 videos</td>
                            <td>~80 videos</td>
                            <td>~400 videos</td>
                        </tr>
                        <tr>
                            <td>Voice-over Elevenlabs for text in lessons (30-40 credits per page)</td>
                            <td>~10 lessons</td>
                            <td>~50 lessons</td>
                            <td>~250 lessons</td>
                        </tr>
                        <tr>
                            <td>Voice-over OpenAI for text in steps (2-3 for OpenAI per page)</td>
                            <td>~300 lessons</td>
                            <td>~660 lessons</td>
                            <td>~3300 lessons</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <LoginContainer showLogin={showLogin}
                setCancelLogin={onSetShowLogin}
                onLoginUser={onLoginUser} />
        </div>
    )
}