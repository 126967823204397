import React from 'react';

export const termsContent = `
<div>
<h2>Agreement Overview</h2>
<p className='wrapper_content-section'>
    A "Creator" is defined as any individual, group of individuals, or legal entity that designs, authors, or produces the educational content available on the Platform. The Creator is responsible for the curriculum, course materials, and instructional design of the online courses offered to Students. Creators may also be referred to as "School Owner" within the context of their relationship with the Platform and the Students.
</p>

<h2>Course Access and Payment</h2>
<ol>
    <li>The Student agrees to pay for access to asynchronous online courses offered by the Creator.</li>
    <li>Access to the courses is possible on a desktop or mobile browser with an internet connection that meets the technical requirements.</li>
    <li>The Creator reserves the right to change the content and structure of the courses, as well as the terms of access to them, with prior notification to the Students.</li>
</ol>

<h2>Procedure for Entering into the Agreement and Providing Paid Service</h2>
<ol>
    <li>The Student agrees to the terms when creating an account.</li>
    <li>After payment through the payment systems offered on this website, the Platform grants the Student access to the selected courses or the entire school, which is confirmed by sending an email to the Student's email address provided during registration on the site.</li>
    <li>The paid service is considered provided on the day the Platform confirms to the Student that access to the paid courses has been granted.</li>
</ol>

<h2>Dispute Resolution Policy</h2>
<p>The School Owner or Creator is committed to ensuring a transparent and fair resolution process for any disputes that may arise under the terms of this agreement.</p>
<ol>
    <li><strong>Initial Contact:</strong> Any dispute, claim, or controversy arising from or related to this agreement or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall first be addressed by direct communication. The Student should contact the "Creator" or "School Owner" via the provided contact email to seek a resolution.</li>
    <li><strong>Formal Complaint:</strong> If the initial contact does not result in a satisfactory resolution, the Student may submit a formal complaint to the Platform via info@unschooler.me. The complaint must include a detailed description of the dispute, links, screenshots, any relevant communication with the Creator or School Owner, and the desired resolution.</li>
    <li><strong>Review Process:</strong> Upon receipt of a formal complaint, a dedicated team from the Platform will review the submission within 7 business days. This review will include an evaluation of the provided documentation and may involve further communication with both the Student and the Creator/School Owner to gather additional information or clarification.</li>
    <li><strong>Resolution Proposal:</strong> After the review process, the Platform will propose a resolution. This proposal aims to be fair and balanced, considering the interests of both parties. The Student and the Creator/School Owner will have the opportunity to accept or contest the proposed resolution. If contested, both parties must provide additional evidence or arguments in support of their position.</li>
    <li><strong>Final Decision:</strong> If the dispute cannot be resolved through the proposed resolution, the Platform reserves the right to make a final decision on the matter. This decision will be binding and considered as the final resolution of the dispute.</li>
    <li><strong>Legal Remedies:</strong> Nothing in this Dispute Resolution Policy precludes either party from seeking legal remedies in accordance with applicable laws.</li>
</ol>

<h2>Cancellations and Refund Policy</h2>
<ol>
    <li><strong>Cancellation Rights:</strong> Students have the right to cancel their enrollment in any course within the first week of making a one-time purchase or subscription service.</li>
    <li><strong>Refund Request:</strong> To initiate a refund, Students must contact the Creator, School Owner via the Contact Center. The request should include the Student's name, course details,
        tudent's name, course details, and the reason for the refund. Refund requests must be submitted within the cancellation period as specified above.
    </li>
    <li><strong>Processing Refunds:</strong> Upon receiving a refund request, it will be reviewed within 7 business days. Refunds will be processed back to the original payment method used by the Student. Please note, the time it takes for the refund to appear in the Student's account may vary depending on the payment provider and can take up to 2-3 weeks.</li>
    <li><strong>Exceptions:</strong> Refunds may not be available if the School Owner explicitly prohibited them in these terms or on the course page.</li>
    <li><strong>Subscription Services:</strong> For subscription-based enrollments, Students may cancel their subscription at any time. However, refunds for subscription services will only be considered within the first week of the initial subscription.</li>
    <li><strong>Limitation on Refunds:</strong> A Student cannot request a refund more than once for a single product. This policy is designed to ensure that the process for cancellations and refunds is straightforward and considerate of the Student's circumstances, while also protecting the integrity and financial sustainability of the educational offerings provided by the School.</li>
</ol>
</div>
`;


export const DefaultTerms = () => {
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: termsContent }}></div>
        </>

    );
};