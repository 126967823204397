import React, {useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {CorrectSkillsComponent} from '../CorrectSkills.component';
import {LoaderComponent} from '../../common';
import {useUpdatePreference} from '../../common/graphQL';
import {useGetSkillsNew} from '../hook';
import {useLocalePrefix} from '../../lang';

export const CorrectSkillsContainer = (props) => {
    const {
        as: Component = CorrectSkillsComponent,
        user
    } = props;

    const history = useHistory();
    const {skills: allSkills, getSkillsNew, loading} = useGetSkillsNew();
    const {loading: loadingPreference, updatePreference} = useUpdatePreference();
    const [localePrefix] = useLocalePrefix();
    const {id, alias} = user || {};

    const allTags = useMemo(() => {
        if (!allSkills) return null;
        const list = [];
        allSkills?.forEach((skill) => {
            skill?.tags?.forEach((tag) => {
                const isExist = list.find((item) => item?.id === tag?.id);
                if (!isExist) {
                    list.push(tag);
                }
            });
        });
        return list;
    }, [allSkills]);

    const handleSubmit = async (data) => {
        const tags = Object.entries(data).filter((item) => item[1]).map((it) => Number(it[0]));
        await updatePreference({tags});
        const userUrl = alias ? `${alias}-${id}` : id;
        const urlProfile = `${localePrefix}/profile/${userUrl}`;
        history.push(urlProfile);
    };

    useEffect(() => {
        if (id) {
            (async () => {
                await getSkillsNew({
                    variables: {
                        userId: Number(id)
                    },
                    fetchPolicy: 'network-only'
                });
            })();
        }
    }, [id]);

    if (loading || loadingPreference) return <LoaderComponent type="full"/>;
    if (!allTags) return null;

    return <Component allSkills={allSkills}
                      onSubmit={handleSubmit}/>
}