import React from 'react';
import { VideoUploader } from '../../common/';

export const MP4UploadTab = ({
    ...other
}) => {

    return (
        <VideoUploader
            {...other}
        />
    );
};