import {useLazyQuery, useQuery} from "@apollo/client";
import { GetTasks } from "../graphQL/queries/GetTasks";

export const useGetTasks = (variables, query = GetTasks) => {
    const  [getTasks, { data, loading, error }] = useLazyQuery(query, {
        fetchPolicy: 'network-only'
    });
    return {
        tasks: data?.tasks,
        getTasks: getTasks,
        loading,
        error
    };
}
