import React from 'react';
import {Spin} from 'antd';
import {StripeSubscriptionContainer} from '../StripeSubsctiption.container';

export const StripeWorkspaceEnrollComponent = (props) => {
    const {loading, stripeAccountId, workspaceStripeProduct, ...other} = props;
    const isSubscriptionAllowed = !!stripeAccountId && !!workspaceStripeProduct;

    return (
        <Spin spinning={loading}>
            {isSubscriptionAllowed && (
                <StripeSubscriptionContainer {...other}
                                             isAllCourses={true}
                                             stripeAccountId={stripeAccountId}
                                             workspaceStripeProduct={workspaceStripeProduct}/>
            )}
        </Spin>
    )
}