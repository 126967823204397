import React, { useState, useContext } from 'react';
import { Form } from 'antd';

import { AIAskComponent } from '../AIAsk.component/AIAsk.component';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';

export const AIAskContainer = ({
    task,
    inProgressHighlight,
    question,
    handleRequests,
    ...other
}) => {
    const { user } = useContext(FirebaseContext);
    const [value, setValue] = useState();
    const [isVisible, setIsVisible] = useState();

    const { useForm } = Form;
    const [form] = useForm();
    const { getFieldsValue, setFieldsValue } = form;

    // other functions
    const handleChange = (e) => {
        const val = e.target.value;
        setValue(val);
        setIsVisible(!!val);
    };

    const handleSendForm = async (formData, question) => {      
        handleRequests(formData, question);
        setFieldsValue({ ask: '' });
        setValue('');
    }

    const handleFocus = (e) => {
        const keyCode = e.keyCode || e.which;
        const isSubmit = !e.shiftKey && keyCode === 13;
        if (!inProgressHighlight && isSubmit) {
            const formData = getFieldsValue();
            handleSendForm(formData, question);
        }
    };

    return (
        <AIAskComponent
            user={user}
            task={task}
            handleChange={handleChange}
            handleSendForm={handleSendForm}
            handleFocus={handleFocus}
            inProgress={inProgressHighlight}
            form={form}
            isVisible={isVisible}
            value={value}
            {...other}
        />

    );
};
