import React from 'react';
import { Button } from 'antd';
import { useIsAllow, Svg, useResolutions } from '../common';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

export const AIPopoverComponent = ({
    entityId,
    inProgress,
    highlightedRequest,
    handleContextAI,
    handleVideo,
    handleImage,

    workspace,
    value,
    cleanInput,
    handleCleanHighlight,
    task,
}) => {
    const { formatMessage } = useIntl();
    let valueForm = value ? `\n${value}` : ''
    const request = valueForm + (highlightedRequest || '');
    const ButtonClass = classNames('ai-bot-component__button_left', {
        'link-btn_light': inProgress || !request,
    });
    const { isXS } = useResolutions();

    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd("PREMIUM", workspace, task?.creator?.id);
    return (
        <div className='popover_wrapper'>
            {highlightedRequest && <div className='highlighted-request'>
                <small className='highlighted-request__text'>
                    "{highlightedRequest}"
                </small>
                <Button
                    type="text"
                    size="small"
                    className="btn_light"
                    onClick={handleCleanHighlight}
                >
                    <span>
                        <Svg name=':icon/cross' width={12} />
                    </span>
                </Button>
            </div>}
            <div className='highlighted-buttons'>
                {!isXS ?
                    <div>
                        <Button
                            disabled={inProgress}
                            type='text'
                            size='small'
                            onClick={(e) => {
                                handleContextAI(entityId, 'example', request)
                                cleanInput()
                            }}
                            className="ai-bot-component__button"
                        >
                            {formatMessage({ id: 'ai_popup_example', })}
                        </Button>
                        <Button
                            disabled={inProgress}
                            type='text'
                            size='small'
                            onClick={(e) => {
                                handleContextAI(entityId, 'steps', request)
                                cleanInput()
                            }}
                            className="ai-bot-component__button"

                        >
                            {formatMessage({ id: 'ai_popup_steps', })}
                        </Button>

                        <Button
                            disabled={inProgress}
                            type='text'
                            size='small'
                            onClick={(e) => {
                                handleContextAI(entityId, 'explain', request)
                                cleanInput()
                            }}
                            className="ai-bot-component__button"
                        >
                            {formatMessage({
                                id: 'ai_popup_explain',
                            })}

                        </Button>

                        {/* <Button
                            disabled={inProgress}
                            type='text'
                            size='small'
                            onClick={() => {
                                handleContextAI(entityId, 'code', request)
                                cleanInput()
                            }}
                            className="ai-bot-component__button"
                        >
                            {formatMessage({
                                id: 'ai_popup_code',
                            })}
                        </Button> */}

                        <Button
                            disabled={inProgress}
                            type='text'
                            size='small'
                            onClick={() => {
                                handleContextAI(entityId, 'context', request)
                                cleanInput()
                            }}
                            className="ai-bot-component__button"
                        >
                            {formatMessage({
                                id: 'ai_popup_facts',
                            })}
                        </Button>
                    
                            <Button
                                disabled={inProgress || !request}
                                type='text'
                                size='small'
                                onClick={() => {
                                    handleVideo(entityId, request)
                                    cleanInput()
                                }}
                                className={ButtonClass}
                            >
                                <Svg name=':icon/youtube' width={16} />
                                {/*{formatMessage({id: 'ai_popup_video'})}*/}
                                {/* YouTube */}
                            </Button>
                            {
                                isAllow &&
                                <Button
                                    disabled={inProgress || !request}
                                    type='text'
                                    size='small'
                                    onClick={() => {
                                        handleImage(entityId, request)
                                        cleanInput()
                                    }}
                                    className={ButtonClass}
                                >
                                    <Svg name=':icon/cover' width={16} />
                                    {/* {formatMessage({ id: 'ai_popup_image' })} */}

                                </Button>
                            }
                      
                    </div>
                    :
                    <div></div>
                }

            </div>

        </div>
    )
};