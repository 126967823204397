import React from 'react';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';

import { TaskActionsCard } from './TaskActionsCard';
import {
    CircleIndexComponent,
    Svg,
    TagComponent,
} from '../common';
import { getReadingTime, calculateHours } from '../common/utils';

export const TaskInfoComponent = ({ notInProfile,
    task, type, actionHidden, isEdit, index, next, taskClosed, toggleQuestions, ...other }) => {
    const isDone = task.stateResult === 'CORRECT';
    const isNotDone = task.stateResult === 'INCORRECT';
    const { formatMessage } = useIntl();

    const ImageWrapper = classNames('task-info-component__img ', {
        wrapper_image_list: type === 'list' || type === 'closed',
        // wrapper_image_card: type === 'card',
    });
    const reading = task.duration
        ? calculateHours(task.duration, formatMessage)
        : (task?.questions ? getReadingTime([task], formatMessage) : null);

    return (
        <div className='Answer__info'>
            <div className='task_listcontent'>
                <div className='task-component__info'>
                    <div className='task-component_header'>
                        {!isEdit &&
                            <CircleIndexComponent
                                index={index + 1}
                                check={isDone ? 'CORRECT' : isNotDone ? 'INCORRECT' : null}
                                icon={taskClosed ?
                                    <Svg name=':icon/admin' className='menu-icon' />
                                    : null}
                            />
                        }
                        <div className='task-component__text'>
                            <h4 className='Answer__title'>
                                {(task?.cover?.type === 'YOUTUBE' || task?.cover?.type === 'VIMEO') && (
                                    <span className={ImageWrapper}>
                                        {task.cover?.thumbnail ?
                                            <span className='wrapper_image_thumbnail'>
                                                <LazyLoadImage
                                                    className='picture_absolute'
                                                    src={task.cover?.thumbnail}
                                                    alt={task?.title}
                                                />
                                            </span>
                                            :
                                            <Svg name=':icon/play' width={20} className="task-info-component__svg" />
                                        }
                                    </span>
                                )}
                                {task?.cover?.type === 'VIDEO' && !!task?.cover?.timestamps?.length &&
                                    <FontAwesomeIcon icon={faVideo} className="task-info-component__svg"
                                        style={{ width: '20px' }} />
                                }
                                {
                                    task.cover?.type === 'IMAGE' && (
                                        <div className={ImageWrapper}>
                                            <div className='wrapper_image_card'>
                                                <LazyLoadImage
                                                    className='picture_absolute'
                                                    src={task.cover?.source}
                                                    alt={task?.title}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                {task?.title}

                            </h4>
                            {task?.isDraft && <small className="label wrapper_inner-section">{formatMessage({ id: 'form_is_draft' })} </small>}
                            <small className="text_secondary wrapper_inner-section">{reading}</small>
                            <div className='question-actions'>
                                {/* <div className='task-component__tags'>
                                    {task.tags &&
                                        task.tags?.map((tag) => (
                                            <TagComponent
                                                key={tag.id}
                                                tag={tag}
                                                colorful={true}
                                            />
                                        ))}
                                </div> */}
                            </div>
                        </div>
                        {toggleQuestions && <Button
                            type='text'
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                toggleQuestions();
                            }}
                            className='link-btn_light'
                        >
                            <Svg name=':icon/arrow_down' className='icon_16' />
                        </Button>}
                        {task?.isFree && <Button className="margin_right" size="small" type='tertiary'>
                            {formatMessage({ id: 'form_is_free_btn' })}</Button>}
                    </div>
                </div>
                {!actionHidden &&
                    <TaskActionsCard
                        {...other}
                        task={task}
                        actionHidden={actionHidden}
                        index={index}
                    />
                }
            </div>
        </div>
    );
};
