import './scss/nodeView.scss'

import React from 'react';
import {NodeViewWrapper, NodeViewContent} from '@tiptap/react';

export const  ExtextionNodeViewComponent = () => (
    <NodeViewWrapper className="react-component">
        <span className="drag-handle">::</span>
        <NodeViewContent className="content" />
    </NodeViewWrapper>
);
