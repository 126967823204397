import React, { useState, useEffect, useRef } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { Button, Modal, Checkbox } from 'antd';
import { Svg, PaidComponent } from '..';
import { openNotification } from '../../notification/Notification.component';

export const EmbedCopyComponent = (props) => {
    const { url, title = '', classnames = 'link-btn_light answer__actions_bottom_btn btn_toggle', size = "small", 
        userIsAllow, searchIsExist } = props;
    const { formatMessage } = useIntl();

    const [isOpen, setIsOpen] = useState(false);
    const [lightTheme, setLightTheme] = useState(false);
    const [singleMode, setSingleMode] = useState(false);
    const [singleLesson, setSingleLesson] = useState(false);
    const [value, setValue] = useState(`<iframe style="width: 100%; height: 100vh;" src="${url}" title="${title}"></iframe>`);
    const copyRef = useRef();

    const updateValue = () => {
        const sign = searchIsExist ? `&` : `?`;
        let append = '';
        if (lightTheme) append += `${sign}lightTheme`;
        if (singleMode) append += `${append ? '&' : sign}singleMode`;
        if (singleLesson) append += `${append ? '&' : sign}singleLesson`;

        setValue(`<iframe style="width: 100%; height: 100vh;" src="${url}${append}" title="${title}"></iframe>`);
    };

    useEffect(updateValue, [lightTheme, singleMode, singleLesson, url, title]);

    const onCopy = () => {
        setIsOpen(false);
        copyRef.current.click();
    };

    return (
        <div className="task-share-component">
            <Button type='text'
                className={classnames}
                size={size}
                disabled={!userIsAllow}
                onClick={() => setIsOpen(true)}>
                <Svg name=':icon/code-icon' className='icon_24' />
                <span className='hide-on-mobile'>{formatMessage({ id: 'embed_button' })}</span>
            </Button>
            {!userIsAllow && <PaidComponent />}

            <Modal title={formatMessage({ id: 'embed_modal_title' })}
                cancelText={formatMessage({ id: 'cancel_label' })}
                okText={formatMessage({ id: 'copy_label' })}
                centered={true}
                visible={isOpen}
                onOk={onCopy}
                onCancel={() => setIsOpen(false)}>
                <textarea
                    className="ant-input ant-input_200"
                    value={value}
                    readOnly
                />

                <Checkbox
                    id="darkThemeCheckbox"
                    checked={lightTheme}
                    onClick={() => {
                        setLightTheme(prev => !prev);
                        updateValue();
                    }}
                >
                    {formatMessage({ id: 'light_theme_enabler' })}
                </Checkbox>

                <Checkbox
                    id="singleModeCheckbox"
                    checked={singleMode}
                    disabled={singleLesson}
                    onClick={() => {
                        setSingleMode(prev => !prev);
                        updateValue();
                    }}
                >
                    {formatMessage({ id: 'single_step_enabler' })}
                </Checkbox>
                <Checkbox
                    id="singleModeCheckbox"
                    checked={singleLesson}
                    disabled={singleMode}
                    onClick={() => {
                        setSingleLesson(prev => !prev);
                        updateValue();
                    }}
                >
                    {formatMessage({ id: 'single_lesson_enabler' })}
                </Checkbox>
            </Modal>

            <div className="task-share-component__hidden">
                <CopyToClipboard
                    text={value}
                    onCopy={() => {
                        openNotification('topLeft', {
                            title: formatMessage({ id: 'embed_copy_title' }),
                            description: formatMessage({ id: 'embed_copy_text' }),
                            icon: '🤘',
                            duration: 3,
                        });
                    }}
                >
                    <Button type='text' ref={copyRef} />
                </CopyToClipboard>
            </div>
        </div>
    );
}
