import { gql } from "@apollo/client";

export const GET_IMAGES_LIST = gql`
  query getSearch($q: String, $limit: Int) {
    getSearch(q: $q, limit: $limit) {
          items {
              title
              link
              displayLink
              image {
                  contextLink
              }
          }
      }
  }
`;