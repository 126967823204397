import React from 'react';
import { ThemeSwitcherComponent } from '../../common';
import { LangSwitcherContainer } from '../../lang';
import { JoinContainer } from '../../workspace/Join.container/Join.container';
import { ProfileLink } from '../../common';
import { ROLES } from '../../constant';

export const HeaderWorkspaceStudent = ({ user, workspace, onUpdateWorkspace, isInvitePage, ...other }) => {
    const userRole = workspace?.members?.find(member => member?.userId === user?.id)?.permission;
    const role = ROLES.find(role => role.permission === userRole);
    const roleLabel = role ? role.label : userRole;

    return (
        <div className='nav-list__item__link_horizont'>
            <LangSwitcherContainer user={user} type="bottom" classNames="lang-switchet_short" />
            <ThemeSwitcherComponent />

            {!user?.id && !isInvitePage &&
                <JoinContainer
                    workspace={workspace}
                    user={user}
                    onUpdateWorkspace={onUpdateWorkspace}
                    {...other}
                />
            }
            {user?.id &&
                <ProfileLink
                    user={{ ...user, name: roleLabel }}
                    isMe={true}
                />
            }
        </div>
    );
};

