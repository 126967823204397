import {gql} from "@apollo/client";

export const GetUserSubscriptions = gql`
    query getUserSubscriptions($input: UserSubscriptionsInput) {
        getUserSubscriptions(input: $input) {
            id
            studentId
            invitedEmail
            start
            end
            stripeSubscriptionId
            isCanceled
            workspaceId
        }
    }
`;