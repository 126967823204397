import {find} from 'lodash';

const getFilteredSource = (source, subjectUid) => {
    return find(source, {subjectUid});
};

export const getSelectedSubjects = (source, selectedSubjects) => {
    return selectedSubjects.map((subjectUid) => {
        return getFilteredSource(source, subjectUid);
    });
}
