import React, { useState } from 'react';

import { QuestionsFormContainer, Thread, TaskPlaceholderComponent } from '../task';
import {  useQueryParams } from '../common';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faBars } from '@fortawesome/free-solid-svg-icons';

export const TaskCourseArticleComponent = (props) => {
    const {
        task,
        user,
        event,
        handleSayMeAnswers,
        result,
        highlightedRequest,
        isJoinRequired,
        userIsAllowGenerateContent,
        ...other
    } = props;
    const { isSingleMode, isSingleLesson } = useQueryParams();

    const [menuOpen, setMenuOpen] = useState(() => {
        const storedMenuOpen = window.localStorage.getItem('menuOpen');
        return storedMenuOpen !== null ? JSON.parse(storedMenuOpen) : true;
    });

    const handleMenuOpen = () => {
        setMenuOpen(prev => {
            const newState = !prev;
            console.log('newState', newState);
            window.localStorage.setItem('menuOpen', JSON.stringify(newState));
            return newState;
        });
    };

    return (
        <div className="task-article-component">
            {(menuOpen && !isSingleMode && !isSingleLesson) &&
                <div className="flex-right-column thread-panel">
                    {/* collapse button */}
                    <Button className="task-panel-btn task-panel-btn-collapse"
                        type="text"
                        onClick={handleMenuOpen}>
                        <FontAwesomeIcon icon={faBars } />
                    </Button>
                    <Thread
                        event={event}
                        task={task}
                        taskId={task?.id}
                        result={result}
                        user={user}
                        {...other}
                    />
                </div>
            }
            <div className='task-article-content'>
                {isJoinRequired && (
                    <TaskPlaceholderComponent
                        task={task}
                        event={event}
                        user={user}
                        type={isJoinRequired}
                        {...other}
                    />
                )}

                {!isJoinRequired && (
                    <section className="article">
                        <QuestionsFormContainer
                            {...other}
                            id={task?.id}
                            task={task}
                            user={user}
                            event={event}
                            sayMeAnswers={handleSayMeAnswers}
                            result={result}
                            highlightedRequest={highlightedRequest}
                            singleMode={isSingleMode}
                            singleLesson={isSingleLesson}
                            userIsAllowGenerateContent={userIsAllowGenerateContent}
                            setMenuOpen={handleMenuOpen}
                            menuOpen={menuOpen}
                        />
                    </section>
                )}
            </div>
        </div>
    );
}
