import React from 'react';
import { LoaderComponent } from '../../common';
import { PurchaseCreditsLink } from '../../payments/PurchaseCreditsButton.component/PurchaseCreditsButton.component';

export const AiTokensComponent = (props) => {
    const { isLoading,
        totalCredits,
        myCredits,
        isUniversity,
        lastPurchase } = props;

    if (isLoading) {
        return <LoaderComponent type="inline" />
    }

    return (
        <div className="ai-tokens-component">
            <h3>
                <span className='margin_right'>
                    Current credits: {myCredits} / Credits spent: {isUniversity ? Math.round(totalCredits) : (lastPurchase ? lastPurchase - myCredits : 'N/A')}
                </span>
                <PurchaseCreditsLink />
            </h3>

        </div >
    )
}