import React, {useEffect, useRef, useState} from 'react';
import {PaymentRequestComponent} from '../PaymentRequest.component';
import {getConfigByKey} from '../../common'
const PATH_INFORMATION = 'paymentInformation';

export const PaymentRequestContainer = (props) => {
    const {
        as: Component = PaymentRequestComponent,
        balance,
        user,
        userData
    } = props;

    const [minAmount, setMinAmount] = useState();
    const [disabled, setDisabled] = useState(true);
    const [progress, setProgress] = useState();
    const payoneerEmailRef = useRef();

    const handleRequest = async (payoneerEmail, amount) => {
        // const db = getDatabaseRef();
        // const dbRef = db('paymentRequests');
        // const dbKey = dbRef.push().key;
        const request = {
            email: payoneerEmail,
            amount,
            requestDate: Date.now(),
            uid: user.googleUid
        };

        const newBalance = parseFloat((userData.balance - amount).toFixed(2));
        // await dbRef.child(dbKey).set(request);
        // await setDataByPath(PATH_REQUSTS, user.googleUid, 'balance', newBalance);
        // await setDataByPath(PATH_REQUSTS, `${user.googleUid}/payments`, dbKey, request);
        if (!payoneerEmailRef.current || payoneerEmailRef.current !== payoneerEmail) {
            const payoneerData = {};
            payoneerData[`${PATH_INFORMATION}/${user.googleUid}/payoneer/email`] = payoneerEmail;
        }
    };

    // useEffect(async () => {
    //     if (!disabled) {
    //         // const infDb = await fetchData(`${PATH_INFORMATION}/${user.googleUid}`);
    //         // const inf = infDb.val();

    //         if (inf?.payoneer?.email) {
    //             payoneerEmailRef.current = inf.payoneer.email;
    //         }
    //     }
    // }, [disabled]);

    useEffect( () => {
        if (!minAmount) {
            (async () => {
                const d = await getConfigByKey('min_amount_for_withdrawal');
                const v = parseFloat(d._value);
                setMinAmount(v);
            })();
        } else {
            const p = parseFloat((balance / minAmount * 100).toFixed(1));
            setProgress(p > 100 ? 100 : p);
            setDisabled(balance < minAmount);
        }
    } , [minAmount]);

    return <Component progress={progress}
                      disabled={disabled}
                      balance={balance}
                      minBalance={minAmount}
                      onRequest={handleRequest}
                      email={payoneerEmailRef.current || user.email}/>
}
