import { useCreateTask } from '../../task/hooks/useCreateTask';

export const useCreateTasksForEvent = () => {
    const { createTask } = useCreateTask();

    const getTasksForEvent = async (event, tags, workspaceId, isAutogenerated) => {
        const handleCreateTask = async (task) => {
            let tagIdsArray = [];

            if (task.tags) {
                task?.tags?.forEach((tag) => tagIdsArray.push(tag.id));
            } else if (tags?.length) {
                tags?.forEach((tag) => tagIdsArray.push(tag.id));
            } else {
                tagIdsArray = [1524];
            }

            let taskData = {
                title: task.title,
                cover: task.cover,
                description: task.description,
                questions: task.questions,
                tagIds: tagIdsArray.slice(0, 3),
                source: task.source,
                summary: task.summary,
                galleries: task.galleries || [], 
                isFree: false,
                isDraft: false,
                alias: task?.title?.substr(0, 20),
                ...(task.isAutogenerated && { isAutogenerated: task.isAutogenerated }),
                ...(workspaceId && { workspaceId })
            };

            if (!!task?.cover?.source) {
                let cover = {
                    source: task?.cover?.source,
                    type: task?.cover?.type,
                    title: task?.cover?.title,
                };
                taskData.cover = cover;
            }

            let taskFromDB = await createTask({
                variables: {
                    taskData,
                },
            });

            return taskFromDB.data.createTask.id;
        }

        let taskIds = [];

        for await (const task of event.tasks) {
            let taskId = await handleCreateTask(task);
            taskIds.push(taskId);
        }
        return taskIds;
    };

    return getTasksForEvent;
};


