import {useLazyQuery, gql} from '@apollo/client';
import {useCallback} from 'react';
import {useOwnerData} from '../../common';

export const useAiAssistant = (query = AI_RESPONSE) => {
    const [getAssistantResp, { data: jsonData, loading, error }] = useLazyQuery(query, {fetchPolicy: "no-cache"});
    const getOwnerData =  useOwnerData();

    let data;
    try {
        data = jsonData?.requestAssistantApi ? JSON.parse(jsonData?.requestAssistantApi) : null;
    } catch (e) {
        console.warn(e);
    }

    const getAssistantResponse = useCallback(async (data) => {
        const ownerData = await getOwnerData();
        const input = data?.variables?.input;
        const owner = ownerData?.ownerId ? ownerData : {ownerId: ownerData?.customerId};

        const mergedData = {
            variables: {
                input: {...input, owner}
            }
        }

        return getAssistantResp(mergedData);
    }, [getAssistantResp, getOwnerData]);

    return {
        getAssistantResponse,
        loading, error,
        data
    }
};

const AI_RESPONSE = gql`
    query Query($input: assistantApiInput!) {
        requestAssistantApi(input: $input)
    }
`;

