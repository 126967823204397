import React from 'react';
import {useIntl} from 'react-intl';
import {getDate} from '../util';

type Props = {
    data: Object
};

export const EventDataPayerComponent = (props: Props) => {
    const {data} = props;
    const {formatMessage} = useIntl();
    const {event, payment} = data;

    return (
        <tr className="data-table__row">
            <td className="data-table__col">
                {event.title}
                {!event?.isGroup && (<div className="event-data-component__personal">
                    {formatMessage({id: 'payment_request_event_data_payer_title'})}
                </div>)}
            </td>
            <td className="data-table__col">{event.mentorName}</td>
            <td className="data-table__col">{`$${payment.userCost}`}</td>
            <td className="data-table__col">{getDate(payment.dateUnix)}</td>
        </tr>
    )
}