import React from 'react';

import {TagComponent} from '../Tag.component';

export const TagsListComponent = ({tags = []}) => {
    return (
        <div className="tags-list-component">
            {
                tags?.map(tag => (
                    <TagComponent key={`search-tag-${tag.id}`}
                                  tag={{...tag, color: tag?.color || tag?.subject?.color}}
                                  countResults={tag.count}
                                  colorful={true}
                                  link={true}
                                  />
                ))
            }
        </div>
    )
}