import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { MetaComponent, TitleComponent } from '../Components/common';

export const Affiliate = () => {
    const { formatMessage } = useIntl();

    const metaMemo = useMemo(() => {
        const title = formatMessage({ id: 'meta_title_terms' });
        const description = formatMessage({ id: 'meta_description_terms' });

        const meta = {
            meta_title: title,
            meta_description: description,
        };
        const og = {
            title: title,
            description: description,
        };

        const twitter = {
            tw_title: title,
            tw_description: description,
        };

        return { meta, og, twitter };
    }, []);

    return (
        <>
            <MetaComponent
                meta={metaMemo.meta}
                og={metaMemo.og}
                twitter={metaMemo.twitter}
            />

            <div className='page'>
                <TitleComponent title='Unschooler Affiliate Program Terms and Conditions' back={true} />
                <div className='page-content'>
                    <p>
                        These Affiliate Program Terms and Conditions ("Terms") govern your participation in the Unschooler Affiliate Program ("Program") provided by Unschooler OÜ ("Company"). By enrolling in this Program, you agree to abide by these Terms. Please read them carefully before joining the Program.
                    </p>
                    <hr />
                    <h2>TL;DR - How Affiliate Program Works</h2>
                    <ul>
                        <li><strong>Sign Up:</strong> For 30% recurring payments, register and get approved — <a href="https://unschooler.partneroapp.com/program" target="_blank" rel="noopener noreferrer">https://unschooler.partneroapp.com/program</a></li>
                        <li><strong>Promote:</strong> Use specific referral links.</li>
                        <li><strong>Get Paid:</strong> Receive payouts one month after the first purchase.</li>
                    </ul>
                    <hr />

                    <h3>Unschooler Affiliate Program Terms and Conditions</h3>
                    <ol>
                        <li><strong>Enrollment in the Program</strong>
                        </li>
                        <li>
                            <strong>Eligibility:</strong> Participation is open to individuals who are at least 18 years old and possess the authority to enter into this agreement, and to companies or organizations. Automated registrations are prohibited.
                        </li>
                        <li>
                            <strong>Registration:</strong> Complete the affiliate registration on our platform page — <a href="https://unschooler.partneroapp.com/program" target="_blank" rel="noopener noreferrer">https://unschooler.partneroapp.com/program</a>. We reserve the right to accept or reject any application at our discretion.
                        </li>

                    </ol>
                    <h3>Affiliate Responsibilities</h3>
                    <ol>
                        <li>
                            <p>
                                <strong>Promotion:</strong> Promote Unschooler using the referral links provided for Pro and University plans.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Compliance:</strong> You must comply with applicable laws, regulations, and ethical guidelines. Your activities should not involve any prohibited content or practices.
                            </p>

                        </li>
                        <li>
                            <p>
                                <strong>Accurate Representation:</strong> Ensure accurate representation of Unschooler and its services. Avoid deceptive practices.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Prohibited Activities:</strong> Do not engage in spamming, unethical marketing, or any practices that could harm Unschooler’s reputation.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Termination:</strong> If the partner violates this policy, the partnership will be terminated without any guarantee of commission payments.
                            </p>
                        </li>

                    </ol>
                    <h3>Payment Structure</h3>
                    <ol>
                        <li>
                            <strong>Commission Options:</strong> Affiliates can choose between Option 1 (50% from the first payment of a plan, including 50% of yearly payments for one-year plans) or Option 2 (30% for each subsequent payment for recurring revenue). These options are mutually exclusive.
                        </li>
                        <li>
                            <strong>Documentation and Materials:</strong> Upon closing 3 successful deals, affiliates are eligible for a free Pro account and access to tailored presentation templates and video demos.
                        </li>
                        <li>
                            <strong>Referral Links:</strong> Use direct referral links provided for both monthly and yearly plans.
                        </li>
                        <li>
                            <strong>Payment Process:</strong> Send the payment link to clients. Notifications will be sent upon client purchase.
                        </li>
                        <li>
                            <strong>Payouts:</strong> Payouts are initiated one month following the first purchase. A one-month refund policy is in place; payouts are processed after this period.
                        </li>
                    </ol>
                    <h3>Term and Termination</h3>
                    <ol>
                        <li>
                            <strong>Term:</strong> These Terms are effective until terminated by either party. Written notice is required to terminate participation.
                        </li>
                        <li>
                            <strong>Termination for Cause:</strong> The Company may terminate your participation for breach of these Terms or for any prohibited activities.
                        </li>
                        <li>
                            <strong>Effect of Termination:</strong> Upon termination, you are no longer eligible for commissions. Unpaid commissions under the minimum payout threshold will be voided.
                        </li>
                    </ol>
                    <h3>Intellectual Property</h3>
                    <ol>
                        <li>
                            <strong>Limited License:</strong> Affiliates receive a non-exclusive, non-transferable, revocable license to use Unschooler’s logos, trademarks, and materials for promotion.
                        </li>
                        <li>
                            <strong>Intellectual Property Rights:</strong> You acknowledge all rights to Unschooler's intellectual property belonging to the Company.
                        </li>
                        <li>
                            <strong>Limitation of Liability</strong>
                            <p>The Company is not liable for indirect, consequential, or special damages related to the Program.</p>
                        </li>
                    </ol>
                    <h3>General Provisions</h3>
                    <ol>
                        <li>
                            <strong>Modifications:</strong> The Company reserves the right to modify these Terms. Your continued participation signifies acceptance of any changes.
                        </li>
                        <li>
                            <strong>Relationship:</strong> The relationship is that of independent contractors. These Terms do not establish any partnership, joint venture, employment, or agency.
                        </li>
                        <li>
                            <strong>Governing Law:</strong> These Terms are governed by the laws of the jurisdiction where the Company is headquartered, with courts in that location having exclusive jurisdiction.
                        </li>
                    </ol>

                </div>
            </div>
        </>
    );
};
