import React from 'react';
import { Button, Radio } from 'antd';
import { LoaderComponent } from '../../common';
import { useIntl } from 'react-intl';

export const StripeSubscriptionComponent = (props) => {
    const { prices, loading, currentPrice, setCurrentPrice, onSubscribe } = props;
    const isSingleMode = prices.length === 1;

    const { formatMessage } = useIntl();
    return (
        <div className="stripe-subscription-component">
            {isSingleMode && (
                <small>
                    {currentPrice.label}
                </small>
            )}
            {!isSingleMode && (
                <Radio.Group
                    onChange={(e) => setCurrentPrice(e.target.value)}
                    value={currentPrice}
                    className='wrapper_inner-section'
                >
                    {prices.map((item) => (
                        <Radio value={item} key={item.key}>{item.label}</Radio>
                    ))}
                </Radio.Group>
            )}
            <div className="event_price">
                <div className="inline-el">
                    {currentPrice?.price}
                </div>
            </div>
            <Button type="primary"
                block={true}
                disabled={loading}
                onClick={onSubscribe}>
                <span>
                    {loading && (
                        <LoaderComponent type="inline" />
                    )}
                    {formatMessage({ id: 'subscribe_button' })}
                </span>
            </Button>
        </div>
    )
}
