import { useLazyQuery } from '@apollo/client';
import { GetTask } from '../graphQL/queries/GetTask';

export function useGetTask( query = GetTask) {
    const [getTask, { data, loading, error }] = useLazyQuery(query);
    return {
        task: data?.task,
        loading, 
        error,
        getTask
    };
}