import {gql} from '@apollo/client';

export const GetCustomVoice = gql`
    query GetCustomVoice($voiceId: String) {
        getCustomVoice(voiceId: $voiceId) {
            name
            voiceId
            description
            labels {
                accent
            }
        }
    }
`;
