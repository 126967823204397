import { useCallback } from 'react';
import { useAiChatResponse } from '../graphQL/useAiChatResponse';
import { uniq } from 'lodash';
import preposition from '../AIBot.component/prepositions.json';
import { useGetTags } from '../../careers/hook/useGetTags';

const getComparedTags = (reqArr, tags) => {
    const comparedTags = [];
    let allComparedTags = [];
    const tagsByPriority = [];

    reqArr?.forEach((item) => {
        if (!preposition.includes(item)) {
            let t = tags?.filter((tag) => {
                const splittedTitle = tag?.title?.split(' ');
                return splittedTitle.find(
                    (word) => word.toLowerCase() === item.toLowerCase()
                );
            });

            t = t || [];

            allComparedTags = [...allComparedTags, ...t];
        }
    });

    allComparedTags?.forEach((tag) => {
        const existedTag = tagsByPriority.find(
            (item) => item?.tag?.id === tag?.id
        );
        if (!existedTag) {
            tagsByPriority.push({ tag, priority: 1 });
        } else {
            existedTag.priority++;
        }
    });

    tagsByPriority.sort((a, b) => a.priority - b.priority);

    tagsByPriority?.forEach((item) => {
        if (item.priority > 1) {
            const index = tagsByPriority.indexOf(item);
            comparedTags.push(item.tag);
            tagsByPriority.slice(index, 1);
        }
    });

    if (comparedTags.length < 2) {
        let list = [];
        reqArr?.forEach((req) => {
            const strongMatchedItem = tagsByPriority.find(
                (t) => t?.tag?.title.toLowerCase() === req.toLowerCase()
            );
            if (strongMatchedItem) {
                list.push(strongMatchedItem);
            }
            const filteredItems = tagsByPriority.filter((item) =>
                item?.tag?.title.toLowerCase().startsWith(req.toLowerCase())
            );
            list = [...list, ...filteredItems];
        });

        list?.forEach((item) => {
            const isExist = comparedTags.find((tag) => tag.id === item.tag.id);
            if (!isExist) {
                comparedTags.push(item.tag);
            }
        });
    }

    return comparedTags.slice(0, 2);
};

export const useGetTagsByRequest = () => {
    const { getChatResponse, error } = useAiChatResponse();
    const { getLazyTags } = useGetTags();

    const getTagsByRequest = useCallback(
        async (request) => {
            const tags = await getLazyTags();

            const settings = {
                args: {
                    request: request,
                },
                type: 'categories',
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });
            const responseJSON =
                response?.data?.requestChatApi &&
                JSON.parse(response?.data?.requestChatApi);
            function parseJson(jsonString) {
                // Correcting the JSON format
                const correctedJsonString = jsonString.replace(/'/g, '"');

                try {
                    // Parsing the JSON string
                    const jsonObj = JSON.parse(correctedJsonString);
                    return jsonObj.categories;
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    return null;
                }
            }

            if (responseJSON?.choices[0]) {
                let responseTrimmed = parseJson(responseJSON.choices[0].message.content)
                const suitedTags = responseTrimmed?.length && getComparedTags(responseTrimmed, tags);
                return suitedTags;
            }
            return null;
        },
        [getChatResponse, getLazyTags]
    );

    return { getTagsByRequest };
};
