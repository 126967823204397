import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {CloneEventComponent} from '../CloneEvent.component';
import {useCloneEvent} from '../hooks/useCloneEvent';

export const CloneEventContainer = (props) => {
    const {eventId} = props;
    const history = useHistory();
    const [clonedUrl, setClonedUrl] = useState();
    const [error, setError] = useState();
    const {cloneEvent, loading} = useCloneEvent();

    const handleCloneEvent = async () => {
        setError(false);
        await setClonedUrl(null);
        const resp = await cloneEvent({
            variables: {
                eventId
            }
        });

        const {url, error: cloneError} = resp.data.cloneEvent || {};

        if (cloneError) {
            setError(true);
        }

        if (url) {
            history.push(url);
        }
    };

    return <CloneEventComponent isLoading={loading}
                                error={error}
                                onClone={handleCloneEvent}/>
}