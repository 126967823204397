import { useMutation } from '@apollo/client';
import {CreateConnectedSession} from '../graphQL/mutations/CreateConnectedSession';

export function useCreateConnectedSession(mutation = CreateConnectedSession) {
    const [createConnectedSession, { data, error, loading }] = useMutation(mutation);

    return {
        data,
        error, loading,
        createConnectedSession
    };
}
