import {useCallback} from 'react';
import {useMutation} from '@apollo/client';
import {AddResendDomain} from '../graphQL/mutations/AddResendDomain';

export const useAddResendDomain = (mutation = AddResendDomain) => {
    const [addDomain, { data, error, loading }] = useMutation(mutation);

    const addResendDomain = useCallback(async (domain, workspaceId, email) => {
        const resp = await addDomain({
            variables: {
                domain,
                workspaceId,
                email
            }
        });
        return resp;
    }, [addDomain])

    return {
        data,
        error, loading,
        addResendDomain
    };
}