import {cloneDeep} from 'lodash';

const USER_PAYMENTS_PATH = 'userPayments/';
const PAYMENTS_PATH = 'payments/';

export const updatePayments = async (list, userData, uid) => {
    return new Promise(resolve => {
        const count = list.length - 1;
        let balance = userData?.balance || 0;

        list?.forEach(async (item, i) => {
            const amount = parseFloat(item.payment?.mentorAmount);
            const clone = cloneDeep(item.payment);
            delete clone.id;
            clone.wasProcessed = true;
            const updatedPayment = {};
            updatedPayment[PAYMENTS_PATH + item.payment.id] = clone;
            try {
                balance = parseFloat((balance + amount).toFixed(2));
            } catch (e) {
                console.warn(e);
            }
            if (i === count) {
                resolve();
            }
        });
    });
}
