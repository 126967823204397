import React from "react";
import { Button } from "antd";
import { Svg, useProgress } from "../../common";
import { useIntl } from 'react-intl';
import { useGetQuiz } from "../hook";

export const SingleSelectButton = ({
  question,
  userIsAllowGenerate,
  handleReplaceQuestion,
  goal,
  event,
  header,
  regenerate,
  size = 'small',
}) => {
  const { inProgress, handleChangeProgress } = useProgress();
  const { formatMessage } = useIntl();
  const { getQuizAi } = useGetQuiz();

  const handleClick = async () => {
    handleChangeProgress({
      message: formatMessage({ id: 'loader_nextstepai_async' }),
      key: 'assessment',
    });
    const questionTitle = question.title || header;
    const domain = goal + " " + (event?.title?.substring(0, 50)?.trim() || '');
    const newQuestion = await getQuizAi(questionTitle, domain, '');
    if (newQuestion) {
      await handleReplaceQuestion({ ...newQuestion, question: `${question?.question}\n\n${newQuestion.question}` }, question.id);
    }
    handleChangeProgress({ key: 'assessment' });
  };

  return (
    <Button
      className="btn-left"
      disabled={inProgress || !userIsAllowGenerate}
      type={regenerate ? 'secondary' : 'tertiary'} size={size}
      onClick={handleClick}
    >
      <Svg name=':icon/logo-full_star' className='icon_16' />
      {regenerate ? formatMessage({ id: "regenerate" }) : formatMessage({ id: "generate" })}
      {' '}
      {formatMessage({ id: "learning_quiz" })}
    </Button>
  );
};