import {useCallback} from 'react';
import ReactGA from 'react-ga4';
import {isDevelopment} from '../utils';

const GA_KEY =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_DEV;

ReactGA.initialize([
    {
        trackingId: GA_KEY
    }
]);

export const useAnalytics = () => {
    const pageViewTrack = useCallback((pathname) => {
        if (isDevelopment()) return;

        if (ReactGA) {
            ReactGA.send({hitType: 'pageview', page: pathname});
            console.log('PAGE VIEW')
        }
    }, []);

    const eventTrack = useCallback((name, data) => {
        if (isDevelopment()) return;

        if (ReactGA) {
            ReactGA.event({...data, ...(!data?.action && {action: name}), category: name});
        }
    }, []);

    return {pageViewTrack, eventTrack};
};
