import {useLazyQuery} from "@apollo/client";
import {GetEventFull} from "../graphQL/queries/GetEventFull";

export function useGetEventFull(query = GetEventFull) {
    const [getEvent, { data, loading }] = useLazyQuery(query);

    return {
        event: data?.event,
        getEvent,
        loading
    }
}
