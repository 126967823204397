import './scss/index.scss';

import React, {useEffect} from 'react';
import {noop} from 'lodash';
import {LoginContainer} from '../Login.container';
import {useTrackClick} from '../hooks';

export const JoinWComponent = ({
        setShowJoinForm = noop,
        showJoinForm,
        onLoginUser = noop,
        ...other
    }) => {
    const trackClick = useTrackClick();

    useEffect(() => {
        trackClick("show-login", "show-login", ``);
    }, []);

    return (
        <LoginContainer showLogin={showJoinForm}
                        onLoginUser={onLoginUser}
                        setCancelLogin={setShowJoinForm}
                        {...other}/>
    );
};
