export const getReplaceIframe = (text) => {
    if (text) {
        const re = /<iframe.*src=['"](https:\/\/www.youtube.com\/embed\/([^'"]*)).*<\/iframe>/gi;
        const newfdsd = text?.replace(re, function (match, p1, p2) {
            let id = p2?.slice(-11);
            return `<div className="wrapper_inner-section">
        <div><a href="https://youtu.be/${id}">https://youtu.be/${id}</a></div>
        <img src=https://i.ytimg.com/vi/${id}/mqdefault.jpg alt="" />
        </div>`;
        });
        // let content = newfdsd.replace(/<video.*src=['"]([^]*?)['"].*<\/video>/gi, (match, p1) => {
        //     return `<div><a href="${p1}">${p1}</a></div>`
        // });
        const content = newfdsd.replace(/<video.*<\/video>/g, '').replace(/<iframe.*<\/iframe>/g, '');
        return content;
    }
}