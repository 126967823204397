import React from 'react'
import { EmbedContext } from '../context/embed';

export const Embed = (props) => {
    const { as: Component, user, ...other } = props;

    return (
        <EmbedContext.Provider value={{ isEmbed: 'embed/' }}>
            <Component
                {...props}
                user={user}
                isEmbed='embed/'
            />
        </EmbedContext.Provider>
    )
}

