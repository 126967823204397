import { useMutation, gql } from "@apollo/client";

export function useParticipateEvent() {
    const [mutateParticipateEvent, { data, error, loading }] = useMutation(PARTICIPATE_EVENT);

    async function participateEvent(eventId, promocodeId, type) {
        const result = await mutateParticipateEvent({
            variables: {   
                eventId,
                promocodeId,
                type
            }
        });
        return result.data?.participateEvent;
    }

    return {
        loading,
        error,
        result: data?.participateEvent,
        participateEvent
    };
}

const PARTICIPATE_EVENT = gql`
    mutation participateEvent($eventId: String!, $promocodeId: String, $type: TypeEvent!) {
        participateEvent(eventId: $eventId, promocodeId: $promocodeId, type: $type)
    }
`;