import React from 'react';
import {FooterComponent} from '../Footer.component';
import {FooterWorkspaceComponent} from '../FooterWorkspace.component';

export const FooterContainer = ({workspace, isEmbed, isEditMode}) => {
    const Component = !workspace ? FooterComponent : FooterWorkspaceComponent;
    const {logo, title} = workspace || {};

    if (isEmbed || isEditMode) {
        return null;
    }

    return <Component workspace={workspace}/>
}