import {useContext, useCallback} from 'react';
import {useCurrencyCode} from './useCurrencyCode';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {LoaderComponent} from '../Loader.component';

export const useConvertedPrice = () => {
    const {locale} = useContext(FirebaseContext);
    const {currencyCode, loading} = useCurrencyCode();

    const getPrice = useCallback((price, currency = currencyCode) => {
        if (price === undefined) {
            return null;
        }
        if (!currency && loading) {
            return <LoaderComponent type="inline"/>
        }

        return new Intl.NumberFormat(
            locale,
            {
                style: 'currency',
                currency: currency || currencyCode
            }
        ).format(price).replace('.00', '') || '';

    }, [currencyCode, locale, loading]);

    const getCurrency = useCallback(() => {
        const price = getPrice(0);

        return price && price?.replace(/[0-9.]/g, '').trim();
    }, [getPrice]);

    return {getPrice, getCurrency, loading}
}