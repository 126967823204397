import './scss/index.scss';

import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Loader } from "../../Loader";

import { FooterContainer } from '../../footer';
import { HeaderSkillsContainer } from '../../headers';
import { useLocalePrefix } from '../../lang';
import {
    LoginContainer,
    useTrackClick,
    ProfessionProfileComponent,
    useIsAllow,
} from '../../common';
import { SkillsTreeContainer } from '../../skillsTree';
import { YoutubeContainer } from '../../google';
import { SubscriptionButtonComponent } from '../../subscription';

export const CareersComponent = ({
    skills,
    // fullListSkills,
    user,
    currentSpec,
    handleSayMeSpec,
    spec,
    loading,
    ...other
}) => {
    const [localePrefix] = useLocalePrefix();
    const trackClick = useTrackClick();
    const [showLogin, setShowLogin] = useState();
    const [skillsBySubject, setSkillsBySubject] = useState(skills);
    const { formatMessage } = useIntl();

    const handleClickLogin = (e) => {
        trackClick(
            'click-start-profession',
            '',
            `https://unschooler.me${localePrefix}/careers`
        );

        if (!user?.id) {
            setShowLogin(true);
        }
    };

    useEffect(() => {
        if (!!skills?.subjects?.length) {
            let newSkills = { ...skills };
            let filteredSubjects = skills.subjects.filter(
                (subjectObject) => subjectObject.countResults > 0
            );
            newSkills.subjects = filteredSubjects;
            newSkills && setSkillsBySubject(newSkills);
        }
    }, [skills])

    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd("PREMIUM");

    return (
        <div>
            <HeaderSkillsContainer
                {...other}
                user={user}
                skills={skills}
                sayMeSpec={handleSayMeSpec}
                handleSpecChange={handleSayMeSpec}
                selectedSpec={spec}
                hideAll={true}
            />

            <div className='page'>
                <div className=' wrapper_inner-section'>
                    <h1 className='title-component__title wrapper_inner-section'>
                        <span style={{ color: spec?.color }}> {spec?.title}</span> {formatMessage({
                            id: 'career_title',
                        })}
                    </h1>

                    {isAllow ? (
                        <YoutubeContainer
                            id={user?.id}
                            size='medium'
                            type='primary'
                        />
                    ) : (
                        <SubscriptionButtonComponent
                            type='primary'
                            title='Test your interests with YouTube'
                            size='medium'
                        />
                    )}
                </div>

                <div className='wrapper_inner-section'>
                    {!!skillsBySubject?.subjects?.length ?

                        <SkillsTreeContainer skills={skillsBySubject}
                            selectedSpec={spec}
                            // onSkillsRendered={setSkillsRendered}
                            user={user}
                            {...other} /> : <p>{formatMessage({ id: 'skills_desc', })}</p>
                    }
                </div>

                <ProfessionProfileComponent
                    user={user}
                    skills={skills}
                    localePrefix={localePrefix}
                    handleLoginUser={handleClickLogin}
                    setShowLogin={setShowLogin}
                    spec={spec}
                    {...other}
                />

                {loading && <Loader />}
                <FooterContainer />
                <LoginContainer
                    showLogin={showLogin}
                    setCancelLogin={setShowLogin}
                />
            </div>
        </div>
    );
};
