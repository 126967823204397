import { gql } from "@apollo/client";

export const GetSubject = gql`
    query GetSubject($subjectId: Int!) {
        subject(id: $subjectId) {
            id
            title
            icon
            color
        }
    }
`;