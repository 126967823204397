import {gql} from '@apollo/client';

export const GetFilteredWorkspaces = gql`
    query GetFilteredWorkspaces($input: FilteredWorkspacesInput) {
        getFilteredWorkspaces(input: $input) {
            id
            firebaseId
            ownerId
            title
            host
            customDomain
            status
        }
    }
`;
