import {gql} from '@apollo/client';

export const SendResendEmail = gql`
    mutation SendResendEmail($input: ResendEmailInput!) {
        sendResendEmail(input: $input) {
            data {
                id
            }
            error {
                statusCode
                message
                name
            }
        }
    }
`;
