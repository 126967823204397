import {gql} from '@apollo/client';

export const GetTag = gql`
    query GetTag($tagId: Int!) {
        tag(tagId: $tagId) {
            id
            defIcon
            icon
            title
            subject {
                color
                title
                id
            }
        }
    }
`;
