import {gql, useLazyQuery} from "@apollo/client";

export const useGetUserByResetToken = (query = GET_UID_BY_TOKEN) => {
    const [getUserByResetToken, { data, loading, error }] = useLazyQuery(query);
    return {getUserByResetToken, data, loading, error};
}

const GET_UID_BY_TOKEN = gql`
    query GetUidByResetToken($token: String!) {
        getUserByResetToken(token: $token) {
            id
            email
            uid
            error
        }
    }
`;