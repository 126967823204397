import React from 'react';
import {ActionTableItemComponent} from '../ActionTableItem.component/ActionTableItem.component';

export const ActionTableMenuComponent = ({ actions, ...other }) => {

  return (
    <div className="floating-component">
      {actions.map((action, index) => (
        <div key={index} className="action-item">
          <ActionTableItemComponent componentAs={action.component} {...other} />
        </div>
      ))}
    </div>
  );
};