import React from 'react';
import _ from 'lodash';
import { Progress } from 'antd';

import { Svg } from '../common';
import { SPEC_SUBJECTSID } from '../constant';
import { ProgramsSubjectIconComponent } from '../program/ProgramsSubjectIcon.component/ProgramsSubjectIcon.component';

export const AchievementsProgress = ({ achievement, width, type }) => {
    const specIndex = _.findIndex(SPEC_SUBJECTSID, function (spec) { return spec.subjectIds.includes(achievement.subject?.id) });
    const color = achievement?.subject?.color || achievement?.color;
    return (
        <div className='achievement_card'
            style={{ backgroundImage: `url("https://cdn.unschooler.me/${SPEC_SUBJECTSID[specIndex]?.title}.svg")` }}
        >
            <div className='card_header'>
                <Progress type="circle"
                    percent={(achievement?.counter?.done / achievement?.counter?.all * 100)}
                    strokeColor={color}
                    width={140}
                    height={140}
                    format={(percent) =>
                        <>
                            <ProgramsSubjectIconComponent
                                source={achievement.src}
                                width={70}
                                achievement={achievement}
                                specIndex={specIndex}
                                colorfull={achievement?.counter?.done === achievement?.counter?.all}
                            />
                            <Svg
                                name=':icon/star'
                                fill={color}
                                width={16} />
                            {achievement?.counter?.done} / {achievement?.counter?.all}
                        </>} />
            </div>
            <div className='achievement_card__icon'>
                <h4 className='card_achevement__title'>{achievement?.title}</h4>
                <small className='block-el addition-text'>
                    {achievement?.description}
                </small>
            </div>
        </div>
    );
};




