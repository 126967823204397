import {useMutation} from '@apollo/client';
import {DeleteInvite} from '../graphQL/mutations/DeleteInvite';

export const useDeleteInvite = (mutation = DeleteInvite) => {
    const [deleteInvite, {data, error, loading}] = useMutation(mutation);

    return {
        deleteInvite,
        invites: data?.deleteInvite,
        error,
        loading
    }
}