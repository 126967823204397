import {gql} from '@apollo/client';

export const AddResendDomain = gql`
    mutation AddResendDomain($domain: String!, $workspaceId: Int!, $email: String!) {
        addResendDomainNew(domain: $domain, workspaceId: $workspaceId, email: $email) {
            id
            email
            workspaceId
            domain {
                id
                name
                status
                records
                resendId
            }
        }
    }
`;
