import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {SuccessComponent} from '../Components/checkout';

export const Success = () => {
    const {state} = useLocation();
    const history = useHistory();

    if (!state) {
        history.push('/');
        return null;
    }
    const {product, isGiftBox} = state;

    return <main className="main">
        <div className="page">
            <SuccessComponent product={product}
                              isGiftBox={isGiftBox}/>
        </div>
    </main>
}
