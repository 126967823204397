import React, {useState} from 'react';
import {RequestsComponent} from '../Requests.component';

export const RequestsContainer = (props) => {
    const {
        as: Component = RequestsComponent
    } = props;

    // eslint-disable-next-line no-unused-vars
    const [requests, setRequests] = useState();
    // eslint-disable-next-line no-unused-vars
    const [finished, setFinished] = useState();

    const handlePay = async (item) => {
        const date = Date.now();
        const newItem = Object.assign( {doneDate: date}, item);
        const {id, uid} = newItem;
        delete newItem.id;
        const paymentRequest = {};
        paymentRequest[`paymentRequests/${id}`] = newItem;
        const userRequest = {};
        userRequest[`userPayments/${uid}/payments/${id}`] = newItem;
    };

    // useEffect( () => {
    //     (async () => {
            // const db = getDatabaseRef();

            // db('paymentRequests').on('value', async snapshot => {
            //     const data = await getConvertedObjectToArray(snapshot.val());
            //     const r = [];
            //     const f = [];
            //     data.forEach(item => {
            //         if (!item?.doneDate) {
            //             r.push(item);
            //         } else {
            //             f.push(item);
            //         }
            //     });
            //     setRequests(r);
            //     setFinished(f);
            // });
    //     })();
    // }, []);

    if (!requests || !finished) return null;

    return <Component requests={requests}
                      finished={finished}
                      onPay={handlePay}/>
}
