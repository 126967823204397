import './scss/index.scss';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { FirebaseContext } from "../../../context/firebase/firebaseContext";

export const ProgressBarComponent = ({
    prof,
    isSelected,
    counter,
    size,
    color,
    event
}) => {
    const { formatMessage } = useIntl();
    const { user } = useContext(FirebaseContext);
    let progress = counter || { allCount: event?.tasks?.length, count: 0 };

    event?.tasks && event?.tasks?.forEach(task => {
        if (task.stateResult === 'CORRECT' || task.stateResult === "INCORRECT") {
            progress.count = progress.count + 1
        } else {
            progress.count = progress.count + 0
        }
    })
    let progressPercent = Math.floor(
        progress?.count / (progress?.allCount / 100)
    )

    let ProfileTitle = classNames('', {
        profession_title: !size,
        profession_title_small: size === 'small',
    });
    let ProgressBar = classNames('wrapper_progress wrapper_inner-section', {
        wrapper_progress_small: size === 'small',
    });
    let ProgressBarShort = classNames('progress_short ', {
        progress_short_small: size === 'small',
    });

    return progress ? (
        <>
            <div className='wrapper_action-horizontal  wrapper_profession'>
                <h2 className={ProfileTitle}>
                    {prof?.title}{' '}
                    {user?.id ? (
                        <span
                            style={{ color: color, }}
                            className='text_secondary'
                        >
                            {progressPercent}% {' '}
                        </span>

                    ) : (
                        <span
                            style={{
                                color: color,
                            }}
                            className='text_secondary'
                        >
                            0%
                        </span>
                    )}
                </h2>
                {isSelected ? (
                    <span style={{ backgroundColor: 'white' }} className='label'>
                        SELECTED CAREER
                    </span>
                ) : null}
                <h2 className='profession_title '>
                    {prof?.salary ? `$${prof?.salary}K` : null}
                </h2>
            </div>

            <div className={ProgressBar}>
                {size !== 'small' &&
                    <small className='progress_label'>{user?.id ? progress?.count : 0}/{
                        progress?.allCount
                    } {formatMessage({ id: 'career_btn_skills', })} </small>
                }
                <div
                    className={ProgressBarShort}
                    style={{
                        backgroundColor: color,
                        width:
                            `${user?.id
                                ? progress?.count / (progress?.allCount / 100)
                                : 0
                            }%` || 0,
                    }}
                >
                </div>
            </div>
        </>
    ) : null;
};
