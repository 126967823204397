import React, {useEffect, useRef, useState} from 'react';
import {PayerComponent} from '../Payer.component';
import {getEventsData, updatePayerPayments} from '../util';
import {isEmpty} from "lodash";

export const PayerContainer = (props) => {
    const {
        as: Component = PayerComponent,
        uid,
        payerData,
        userData
    } = props;

    const loadedRef = useRef();
    const [list, setList] = useState();

    const checkPayments = async (data) => {
        let finished = [];
        const toFinished = [];

        data?.forEach((item) => {
            if (item?.payment?.wasAddedToSpent) {
                finished.push(item);
            } else {
                toFinished.push(item);
            }
        });
        if (!isEmpty(toFinished)) {
            try {
                await updatePayerPayments(toFinished, userData, uid);
                finished = finished.concat(toFinished);
            } catch (e) {
                console.warn(e);
            }
        }

        setList(finished);
    };

    useEffect( () => {
        if (!loadedRef.current && userData !== undefined && payerData) {
            (async () => {
                const allData = await getEventsData(payerData);
                await checkPayments(allData);
                loadedRef.current = true;
            })();
        }
    }, [userData, payerData])

    return <Component list={list}/>
}