import {useMutation} from '@apollo/client';
import {EditEventsListsOrder} from '../graphQL/mutations/EditEventsListsOrder';

export const useEditEventsListOrder = (mutation = EditEventsListsOrder) => {
    const [editEventsListOrder, {data, error, loading}] = useMutation(mutation);

    return {
        editEventsListOrder,
        data,
        error,
        loading
    }
}