import React, { useMemo } from "react";

import { ReactMarkdownComponent } from "../task/ReactMarkdown.component/ReactMarkdown.component";
import {
    VideoPlayer,
    getSelectionCoords,
    LoaderTypeComponent,
} from "../common";
import { HeaderTutorial } from '../headers/HeaderTutorial/HeaderTutorial';
import { TaskActions } from '../task/TaskActions'


export const TaskArticleComponent = (props) => {
    const {
        task,
        connectedTasks,
        user,
        event,
        handleSayMeAnswers,
        updateTask,
        result,
        highlightedRequest,
        handleMouseUp,
        inProgress,
        inProgressNextStep,
        stepId,
        handleContextAi,
        handleVideo,
        inProgressHighlight,
        handleFeedbackAi,
        ...other
    } = props;
    const editFunctions = (user?.id === task?.creator?.id) || (user?.role?.id === 1);

    return (
        <div className="task-article-component flex-container ">
            <div className="">
                {editFunctions &&
                    <HeaderTutorial
                        task={task}
                        event={event}
                        {...other}
                        customComponent={TaskActions}
                    />}
                <div className="Answer__article__wrapper  ">
                    {task?.cover?.type === "YOUTUBE" && (
                        <VideoPlayer
                            timestampsList={task?.cover?.timestamps}
                            inProgress={inProgress}
                            task={task}
                            user={user}
                            showCaptions={true}
                            // videoSrc={youtubeParser(value)}
                            {...other}
                        />
                    )}

                    {task?.cover?.type === "PHOTO" && (
                        <div className="task-article-component__cover">
                            <img
                                src={task?.cover?.source}
                                alt={task?.title}
                            />
                        </div>
                    )}

                    {task?.description && (
                        <section
                            className="Answer__article"
                            onMouseUp={handleMouseUp}
                            tabIndex={0}
                        >
                            <div className="Answer__info ">
                                <article className="Answer__article__wrapper wysiwyg-content wrapper_inner-section">
                                    <ReactMarkdownComponent children={task.description} />
                                </article>

                                {inProgressHighlight && (
                                    <LoaderTypeComponent fixed={true} />
                                )}

                            </div>
                        </section>
                    )}
                </div>
            </div>
        </div>
    );
};
