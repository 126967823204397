import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Checkbox} from 'antd';

import {useLocalePrefix} from '../../lang';
import {Link} from 'react-router-dom';

export const TermsInfoComponent = () => {
    const [localePrefix] = useLocalePrefix();
    const {formatMessage} = useIntl();
    const [checked, setChecked] = useState(true);

    return (
        <div className='auth-form__section auth-form__secondary'>
            {formatMessage({id: 'auth_form_secondary1'})}{' '}
            <Link to={`${localePrefix}/terms/`}>
                {formatMessage({id: 'terms'})}
            </Link>{' '}
            {formatMessage({id: 'auth_form_secondary2'})}{' '}
            <Link to={`${localePrefix}/policy/`}>
                {formatMessage({id: 'policy'})}
            </Link>
            {/* <Checkbox
                name='notify'
                checked={checked}
                onClick={() => setChecked(!checked)}
            >
                <p>{formatMessage({id: 'auth_form_notify'})}</p>
            </Checkbox> */}
        </div>
    );
};
