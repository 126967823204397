import {getConfigByKey} from './getConfigByKey';

const STRIPE_PRECENT = 0.029;
const STRIPE_CONSTANT = 0.3;

const getDonateCost = (cost, count) => Math.ceil(100 * cost / count) / 100;

const getPaidCost = (cost, count, commission, discount) => {
    const paymentCost = 100 * (cost * (1 + (commission - discount)/100) / count + STRIPE_CONSTANT) / ( 1 - STRIPE_PRECENT);

    return Math.ceil(paymentCost) / 100;
};

export const getUserCost = async (cost, count, skipPercent = false, isDiscount = false) => {
    if (!cost) {
        return null;
    }

    const commissionConfig = await getConfigByKey('unschooler_commission');
    const commission = parseFloat(commissionConfig._value);
    const discountConfig = await getConfigByKey('subscription_discount');
    const discount = isDiscount ? parseFloat(discountConfig._value) : 0;
    const denominator = count || 1;

    return skipPercent ? getDonateCost(cost, denominator) : getPaidCost(cost, denominator, commission, discount);
}