import gql from "graphql-tag";

export const UpdatePreferencesByYoutube = gql`
    mutation UpdatePreferenceByYoutube($token: String!) {
        updatePreferenceByYoutube(token: $token) {
            preference {
                tags {
                    title
                }
            }
            totalVideo
            isPremium
            counter
            iterations
        }
    }
`;
