import React from "react";
import "./scss/index.scss";
import { TaskTestQuestionComponent } from "../../task/TaskTestQuestion.component ";
import { ResultListContainer } from '../../result';
import { ReactMarkdownComponent } from '../ReactMarkdown.component/ReactMarkdown.component';

export const QuestionsTestFormComponent = (props) => {
    const {
        loadingUpdateResult,
        task,
        explainer,
        result,
        setResult,
        topMemo,
        inProgress,
        user,
        event,
        question,
        stepId,
        indexOfQuestion,
        showFreeResultForm,
        results,
        setShowFreeResultForm,
        ...other
    } = props;

    return (
        <div className="">
            {!question ?
                <>
                 {task?.cover?.type === "PHOTO" && (
                        <div className="task-article-component__cover">
                            <img
                                src={task?.cover?.source}
                                alt={task?.title}
                            />
                        </div>
                    )}
                    <section
                        className="Answer__article"
                        tabIndex={0}
                    >
                        <div className="Answer__info wrapper_inner-section">
                            <article className="Answer__article__wrapper wysiwyg-content">
                                <ReactMarkdownComponent children={task.description} />
                            </article>
                        </div>
                    </section>
                </>
                : (
                    <div
                        className="questions_wrapper Answer__article"
                        key={question?.id}
                    >
                        <TaskTestQuestionComponent
                            indexOfQuestion={indexOfQuestion}
                            question={question}
                            task={task}
                            result={result}
                            topMemo={topMemo}
                            inProgress={inProgress}
                            user={user}
                            {...other}
                        />
                    </div>
                )}

            {!!results?.length && !showFreeResultForm && (
                <ResultListContainer
                    {...other}
                    taskId={task.id}
                    results={results}
                    user={user}
                    task={task}
                    event={event}
                />
            )}

            {result?.description && !showFreeResultForm && (
                <div
                    className='wrapper_inner-section wysiwyg-content'
                    dangerouslySetInnerHTML={{
                        __html: result.description,
                    }}
                />
            )}

            {/* {(!result?.description || showFreeResultForm) ?
                <FreeResultFormContainer
                    {...other}
                    id={task?.id}
                    task={task}
                    user={user}
                    result={result}
                    setResult={setResult}
                    loadingUpdateResult={loadingUpdateResult}
                />
                :
                <Button type="link" onClick={() => setShowFreeResultForm((prev) => !prev)}>Edit notes</Button>
            } */}

            {/* {!question && !!task.questions?.length &&
                <>
                    <hr />
                    <h3>Steps to do:</h3>
                    {task.questions?.map((question, i) => (
                        <QuestionInfoComponent question={question} key={question.id} task={task} index={i} />
                    ))}
                </>
            } */}
        </div>
    );
};
