import React from 'react';
import {useIntl} from 'react-intl';
import {RequestItemComponent} from '../RequestItem.component';

type Props = {
    userPayments: Array
};

export const RequestsListComponent = (props: Props) => {
    const {
        userPayments
    } = props;
    const {formatMessage} = useIntl();

    return (
        <div className="requests-list-component">
            <h3>
                {formatMessage({id: 'payment_request_list_title'})}
            </h3>
            <table className="data-table">
                <tbody>
                {userPayments.map((item, i) => <RequestItemComponent key={i}
                                                                     item={item}/>
                )}
                </tbody>
            </table>
        </div>
    )
}