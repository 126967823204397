import React, {useContext} from "react";
import {useGetLangs, useEditUser} from "../../common/graphQL";
import {LangSwitcherComponent} from "../LangSwitcher.component";
import {useNewPathName} from "../hooks";
import {FirebaseContext} from "../../../context/firebase/firebaseContext";

export const LangSwitcherContainer = (props) => {
    const {
        as: Component = LangSwitcherComponent,
        user,
        ...other
    } = props;
    const {lang} = useContext(FirebaseContext);
    const {langs} = useGetLangs();
    const {editUser} = useEditUser();
    const getNewPathName = useNewPathName();

    const handleChange = async (id) => {
        if (id === lang.id) return;
        if (!user?.id) {
            window.sessionStorage.setItem("langId", id);
        } else {
            try {
                await editUser({langId: id});
            } catch (err) {
                console.warn(err);
            }
        }
        window.location = getNewPathName(id);
    };

    return (
        <Component lang={lang}
                   langs={langs}
                   onChange={handleChange}
                   {...other}/>
    )
}
