import { useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

export const useGetUrlParams = (isEmbed) => {
    const { alias, id } = useParams();
    const history = useHistory();
    const embedPath = !!isEmbed ? '/embed' : '';
    const checkUrl = useCallback((entity, pathName) => {
        const source = alias || id;
        const { alias: entityAlias, id: entityId } = entity;


        let url;

        if (entityAlias) {
            url = `${entityAlias}-${entityId}`;
        } else {
            url = entityId;
        }

        if (url && source !== url) {
            history.replace(embedPath + pathName + url);
        }

        return true;
    }, [alias, id]);

    return useMemo(() => {
        const data = { checkUrl };

        if (alias || id) {
            const source = (alias || id)?.split('?')[0];
            const aliasLikeNumber = Number(source);
            const isId = !isNaN(aliasLikeNumber) && aliasLikeNumber > 0;
            const idInUrlList = !isId && source.match(/-[0-9]+$/);
            const idInUrl = idInUrlList && idInUrlList[0];
            if (idInUrl) {
                const regExp = new RegExp(idInUrl);
                const aliasInUrl = source.replace(regExp, '');
                const idStr = idInUrl.substr(1);
                data.id = Number(idStr);
                if (!!aliasInUrl) {
                    data.alias = aliasInUrl;
                }
            } else {
                if (isId) {
                    data.id = Number(source);
                } else {
                    data.alias = source;
                }
            }

            return data;
        } else {
            return data;
        }
    }, [alias, id]);
};