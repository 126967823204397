import { gql, useMutation } from "@apollo/client";

export function useUpdateFilter() {
    const [mutationUpdateFilter, { data, loading, error }] = useMutation(UPDATE_FILTER);

    async function updateFilter(levelId, subjectIds) {
        const result = await mutationUpdateFilter({
            variables: {
                filterData: {
                    levelId,
                    subjectIds
                }
            }
        });
        return result.data;
    }
    
    return {
        filter: data?.updateFilter,
        loading,
        error,
        updateFilter
    }
}

const UPDATE_FILTER = gql`
    mutation UpdateFilter($filterData: UpdateFilterInput!) {
        updateFilter(filterData: $filterData) {
            userId
            level {
                id
                levelUid
                title
            }
            subjects {
                id
                title
                subjectUid
            }
        }
    }
`;