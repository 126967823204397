import React from 'react';
import { Avatar } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export const AvatarCustom = ({
    size,
    user,
}) => {

    return (
        <Avatar src={user?.picture || undefined} size={size || 20} icon={<FontAwesomeIcon icon={faUser} />} />
    );
};
