import React, {useState} from 'react';
import {noop} from 'lodash';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {StripeComponent} from '../Stripe.component';
import {useGetPaymentIntentEvent} from '../hooks/useGetPaymentIntentEvent';
import {useEditUser} from '../../common/graphQL/user/useEditUser';

const POSFIX = process.env.NODE_ENV === 'production' ? '' : '_DEV';

const stripePromise = loadStripe(process.env['REACT_APP_STRIPE_PUBLISHABLE_KEY' + POSFIX]);

export const StripeContainer = (props) => {
    const  {
        user,
        product,
        eventId,
        onSuccess = noop,
        isGiftBox,
        isGuide,
        isSubscription
    } = props

    const {id, phone} = user || {};
    const {editUser} = useEditUser();
    const {getPaymentIntentEvent} = useGetPaymentIntentEvent();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const {forStudentUSD} = (isSubscription ? product?.priceSubscription : product?.price) || {};
    const {promocode} = product?.promocode || {};
    const paymentSum = promocode?.userCost || forStudentUSD || product?.cost;

    const handleSubmit = async (data, stripe, studentData, giftBayer) => {
        const {card, billing_details} = data;
        const {phone: studentPhone} = billing_details;

        if(id && phone !== studentPhone) {
            await editUser({
                phone: studentPhone
            });
        }

        setLoading(true);

        try {
            const result = await getPaymentIntentEvent({
                variables: {
                    paymentData: {
                        eventId,
                        type: !isSubscription ? "DISPOSABLE" : "SUBSCRIBE"
                    }
                }
            });
            const payload = await stripe.confirmCardPayment(result.data?.clientSecret, {
                payment_method: {card, billing_details}
            });

            const {paymentIntent, error} = payload;
            if (error) {
                setError(error);
                setLoading(false);
            }

            if (paymentIntent) {
                onSuccess(payload, studentData, giftBayer);
            }

        } catch (e) {
            console.warn(e);
            setError(e?.error);
            setLoading(false);
        }
    };

    return (
        <Elements stripe={stripePromise}>
            <StripeComponent product={product}
                             user={user}
                             userCost={paymentSum}
                             onSubmit={handleSubmit}
                             loading={loading}
                             isGiftBox={isGiftBox}
                             error={error}/>
        </Elements>
    );
};
