import React from 'react';
import { Radar } from '@ant-design/charts';

export const RadarChart = ({ data }) => {
    const maxScore = Math.max(...data.map(item => item.score));

    const currentItems = data.filter(item => item.type === 'current');
    const maxCurrentScore = Math.max(...currentItems.map(item => item.score));
    const itemWithMaxCurrentScore = currentItems.find(item => item.score === maxCurrentScore);

    const isWhiteMode = window.localStorage.getItem('isWhiteMode');

    const config = {
        data,
        xField: 'item',
        yField: 'score',
        colorField: 'type',
        area: {
            style: {
                fillOpacity: 0.5,
            },
        },
        scale: { x: { padding: 0.5, align: 0 }, y: { tickCount: 10, domainMax: maxScore } },
        axis: { x: { grid: true }, y: { zIndex: 1, title: false } },
        legend: {
            position: 'right',
        },
        theme: isWhiteMode ? 'light' : 'dark',
    };

    return (
        <>
            <h2>{itemWithMaxCurrentScore?.item} Course</h2>
            <Radar
                color={['#001122', '#003322', '#004422', '#005522', '#006622', '#007722', '#008822']}
                {...config}
            />
        </>
    );
};