import {gql} from '@apollo/client';

export const GetTasksIdsByOrder = gql`
    query TasksByOrder($input: TaskOrderInput, $limit: Int, $offset: Int) {
        tasksByOrder(input: $input, limit: $limit, offset: $offset) {
            task {
                id
            }
            order
        }
    }
`;
