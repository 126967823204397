import './scss/index.scss'
import { Popover, Button } from 'antd';
import { Svg } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

export const PaidComponent = (props) => {
  const content = (
    <div>
      <p>Upgrade to {props?.plan} to unlock this feature</p>
      <hr />
      <Button type="primary" block={true} onClick={() => window.location.href = '/payments'}>Get feature</Button>
    </div>
  );

  return (
    <Popover content={content} trigger="click">

      <Button type="link" className='paid-component'>
        <FontAwesomeIcon icon={faArrowCircleUp} />
      </Button>

      {/* <Button shape="circle" type="link" icon={<Svg name=':icon/crown' width={26} height={16} />} /> */}
    </Popover>
  );
};
