export const getConvertedTimeData = ({ startTime, endTime }) => {
    const startHours = Math.floor(startTime/60);
    const startMinutes = startTime % 60;

    const endHours = Math.floor(endTime/60);;
    const endMinutes = endTime % 60;

    return({
        start: `${startHours < 10 ? `0${startHours}` : startHours}:${startMinutes < 10 ? `0${startMinutes}`: startMinutes}`,
        end: `${endHours < 10 ? `0${endHours}` : endHours}:${endMinutes < 10 ? `0${endMinutes}`: endMinutes}`,
    });
}