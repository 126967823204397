import {useCallback} from 'react';
import {useAiChatResponse} from '../graphQL/useAiChatResponse';

export const useGetChunk = () => {
    const {getChatResponse, error} = useAiChatResponse();

    const getChunk = useCallback(
        async (ask, topicVideo) => {
            const settings = {
                args: {
                    request: ask ,
                    context: topicVideo,
                },
                type: 'chunk',
            };

            const resp = await getChatResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = resp?.data
                ? JSON.parse(resp?.data?.requestChatApi)
                : null;
            if (responseJSON?.choices[0]) {
                let responseTrimmed =responseJSON.choices[0].message.content;
                return responseTrimmed;
            }
        },
        [getChatResponse]
    );

    return {getChunk};
};
