import './scss/index.scss';
import React, { useState, useEffect } from 'react';
import { Spin, Button } from 'antd';
import { orderBy } from 'lodash';
import { useIntl } from 'react-intl';

import { TasksListTab } from '../../task/TasksListTab';
import { LoginContainer } from '../../common';
import { AIAllFeaturesContainer } from "../../ai";
import { ListEventsContainer } from '../../events';

export const CommunityComponent = (props) => {
    const { user, subject, tag, ...other } = props;
    const { formatMessage } = useIntl();
    const [showLogin, setShowLogin] = useState();
    const [tasks, setTasks] = useState();
    const [loadTasks, setLoadTasks] = useState();
    const [tutorialLoading, setTutorialLoading] = useState(false);
    const [taskNext, setTasksNext] = useState();

    const superTitle =
        subject?.title || tag?.title || 'Community on Unschooler';

    const achievement = {
        active: true,
        icon: subject?.icon || tag?.icon,
        color: subject?.color || tag?.subject?.color,
        title: `${subject?.title || tag?.title}`,
        id: subject?.id || tag?.id || null,
        subject: subject?.id || tag?.subject?.id || null,
    };

    const handleLoginUser = (userData) => {
        setShowLogin(true);
        setTutorialLoading(false);
    };

    const getTaskId = () => {
        let fds = orderBy(tasks, 'usersLike', `desc`);
        fds.length && setTasksNext(fds[0].id);
    };

    useEffect(() => {
        if (tasks) {
            getTaskId();
        }
    }, [tasks]);

    return (
        <Spin spinning={tutorialLoading}>
            <div
                className='page flex-container page_tag'
                style={{ backgroundColor: achievement?.color }}
            >
                <div className='flex-left-column'>
                    <h1>{formatMessage({ id: 'learn' })} ★{superTitle}</h1>

                    <AIAllFeaturesContainer
                        handleLoginUser={handleLoginUser}
                        user={user}
                        hidePerson={false}
                        domain={achievement}
                        tabs={[
                            formatMessage({ id: 'tab_ai_course' }),
                            // formatMessage({ id: 'tab_ai_mentor' }),
                            formatMessage({ id: 'tab_ai_test' }),
                            // formatMessage({ id: 'tab_ai_lesson' }),
                            // formatMessage({ id: 'tab_ai_channel' })
                        ]}
                        {...other}
                    />

                    {/* {tag?.id &&
                        <div className='wrapper_outer-section'>

                            <ListEventsContainer
                                {...other}
                                filterData={{
                                    tagsIds: [tag?.id],
                                }} />
                        </div>
                    } */}
                    {/* <div className='event-description wrapper_outer-section'>
                        <TasksListTab
                            activeTab={formatMessage({ id: 'tab_subject_tutorial_new' })}
                            author={user}
                            tabs={[formatMessage({ id: 'tab_subject_tutorial_new' })]}
                            subject={subject}
                            tag={tag}
                            viewer={user}
                            limit={10}
                            passTaskToParent={setTasks}
                        />
                    </div> */}
                </div>
                <LoginContainer
                    showLogin={showLogin}
                    setCancelLogin={setShowLogin}
                    onLoginUser={handleLoginUser}
                />
            </div>
        </Spin>
    );
};
