export const getTeachersFromEvent = (event, user) => {

    let instructors = event?.userSubscriptions?.filter(
        (subscription) => subscription.role === 'INSTRUCTOR') || [];

    if (!instructors.length && user?.canTeach) {
        instructors = [user];
    }

    return instructors;
}
export const getAiAvatarFromEvent = (event, user) => {

    let instructor = event?.userSubscriptions?.find(
        (subscription) => subscription.role === 'AI');

    return instructor;
}
