import { gql } from '@apollo/client';

export const GetTask = gql`
    query GetTask($taskId: Int, $alias: String) {
        task(id: $taskId, alias: $alias) {
            id
            tags {
                id
                title
                subject {
                    id
                    title
                    color
                }
            }
            creatorId
            creator {
                id
                googleUid
                name
                picture
            }
            alias
            isModerated
            isDraft
            langId
            title
            coverId
            cover {
                id
                type
                raw
                source
                summary
                timestamps {
                    timestamp
                    title
                    description
                    id
                }
            }
            description
            audioDescription
            isFree
            questions {
                id
                question
                audioQuestion
                explanation
                answer
                options {
                    order
                    text
                    id
                    isCorrect
                }
                order
                type
                title
                cover {
                    id
                    type
                    source
                }
            }
            stateResult
            galleries {
                id
                title
                raw
                summary
                source
                type
                timestamps {
                    title
                    timestamp
                    id
                    description
                }
            }
            taskInEvents {
                eventId
                event {
                    id
                    alias
                    title
                    price {
                        total
                    }
                    isSubscribe
                    isDraft
                    isHideAi
                    drip
                    dripFrequency
                    dripGranularity
                    certificate {
                        id
                    }
                    tags {
                            id
                        }
                    tasks {
                        id
                        alias
                        title
                        stateResult
                        isFree
                        description
                        audioDescription
                        tags {
                            id
                            title
                                subject {
                                    color
                                    icon
                                    title
                                    id
                                }
                        }
                        cover {
                            id
                            type
                            source
                            }
                        questions {
                            question
                            audioQuestion
                            title
                            type
                            order
                            options {
                                isCorrect
                                order
                                id
                                text
                            }
                            id
                            explanation
                            answer
                        }
                    }
                    galleriesInEvent {
                        gallery {
                            source
                            id
                            raw
                            source
                             summary
                             type
                        }
                    }
                    isSubscribe
                    program
                    issue
                    userSubscription {
                        studentId
                        start
                        end
                        isCanceled
                    }
                }
            }
            isAvailable
            isAutogenerated
            summary
            source
        }
    }
`;
