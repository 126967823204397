import {gql} from '@apollo/client';

export const VerifyResendDomain = gql`
    mutation VerifyResendDomain($id: String!, $workspaceId: Int!) {
        verifyResendDomainNew(id: $id, workspaceId: $workspaceId) {
            id
            name
            status
            records
        }
    }
`;
