import {gql} from "@apollo/client";

export const GetStripeProduct = gql`
    query getStripeProduct($input: StripeProductInput) {
        getStripeProduct(input: $input) {
            id
            product
            eventId
            workspaceId
            prices {
                priceId
                productId
                price
                currency
                type
                recurringPeriod
            }
        }  
    }
`;

