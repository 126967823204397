import React from 'react';
import { Button } from 'antd';
import { ArrowUpOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { noop } from 'lodash';
import { useIntl } from 'react-intl';
import { Svg, LoaderComponent } from '../../common';

export const EventsListActionsComponent = (props) => {
    const {
        isNotFirst,
        isNotSingle,
        onUp,
        onDelete = noop,
        onEditList = noop,
        index,
        setType = noop,
        type,
        loading
    } = props;
    const { formatMessage } = useIntl();

    return <div className="events-list-actions-component">
        {loading && <LoaderComponent type="overlay"/>}
        {type === 'card' ?
            <Button type="text" icon={<Svg name=':icon/rectangle' className='icon_16 margin_right' />} onClick={() => setType('list')} >{formatMessage({ id: 'events_teacher' })}</Button>
            : type === 'list' ?
                <Button type="text" icon={<Svg name=':icon/square' className='icon_16 margin_right' />} onClick={() => setType('card')}>{formatMessage({ id: 'events_student' })}</Button>
                : null
        }

        {index !== undefined ? (
            <>
                {isNotSingle && isNotFirst && (
                    <Button type="text" icon={<ArrowUpOutlined />} onClick={onUp} />
                )}
                {!!onEditList && (
                    <Button type="text" icon={<EditOutlined />} onClick={() => onEditList(true)} />
                )}
                <Button type="text" icon={<CloseOutlined />} onClick={onDelete} />
            </>
        ) : null}
    </div>
}