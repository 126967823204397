import {gql} from '@apollo/client';

export const CloneEvent = gql`
    mutation cloneEvent($eventId: Int!) {
        cloneEvent(eventId: $eventId) {
            url
            error
        }
    }
`;
