import React, {useContext, useState} from 'react';
import {PartneroComponent} from '../Partnero.component';
import {useCreateIntegration} from '../../hooks';
import {FirebaseContext} from '../../../../context/firebase/firebaseContext';

export const PartneroContainer = (props) => {
    const { user, workspace } = useContext(FirebaseContext);
    const {data, createIntegration, loading} = useCreateIntegration();
    const [isOpen, setIsOpen] = useState();
    const [error, setError] = useState();

    const handleShowModal = () => {
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleOk = async (partnerId, apiKey) => {
        if (partnerId && apiKey) {
            setError(false);
            const {id} = workspace || {};
            await createIntegration({
                workspaceId: id,
                partneroProgramId: partnerId,
                partneroApiKey: apiKey
            });
            handleClose(false);
        } else {
            setError('Both fields are required');
        }
    }

    return <PartneroComponent {...props}
                              loading={loading}
                              isOpen={isOpen}
                              error={error}
                              onSetOpen={setIsOpen}
                              onCancel={handleClose}
                              onOk={handleOk}/>
}