// import {fetchData} from '../../../context/firebase/firebaseRequests';
import {getCacheManager} from './getCacheManager';

const CACHE_KEY = 'cache-schedule-';
const {hasCache, getCache, setCache} = getCacheManager();

export const getMentorSchedule = async (id) => {
    const key = CACHE_KEY+id;
    const isCache = hasCache(key);
    // if (!isCache) {
    //     const dbData = await fetchData(`schedule/${id}`);
    //     setCache(key, dbData.val());
    // }
    return getCache(key);
}