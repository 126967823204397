import {useCallback} from 'react';
import {useAiChatResponse} from '../graphQL/useAiChatResponse';

export const useGetSummary = () => {
    const {getChatResponse, error} = useAiChatResponse();

    const getSummary = useCallback(
        async (highlightedRequest) => {
            const settings = {
                args: {
                    request: highlightedRequest,
                },
                type: 'summarise',
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = response?.data?.requestChatApi && JSON.parse(response?.data?.requestChatApi);
            if (responseJSON?.choices[0]) {
                let responseTrimmed = responseJSON.choices[0].message.content.trim();
                return responseTrimmed;
            }
        },
        [getChatResponse]
    );

    return {getSummary, error};
};
