import { useIntl } from 'react-intl';

export const useDataPermissions = () => {
  const { formatMessage } = useIntl();

  return [
    {
      key: 'seeOwnCourses',
      feature: formatMessage({ id: 'permissions_seeOwnCourses', defaultMessage: 'See published courses' }),
      Owner: true,
      Admin: true,
      Creator: true,
      Instructor: true,
      User: true,
    },
    {
      key: 'inviteStudents',
      feature: formatMessage({ id: 'permissions_inviteStudents', defaultMessage: 'Invite Students, see all students, Assign students to course' }),
      Owner: true,
      Admin: true,
      Creator: true,
      Instructor: true,
      User: false,
    },
    {
      key: 'createCourses',
      feature: formatMessage({ id: 'permissions_createCourses', defaultMessage: 'Create Courses, Manage own Students' }),
      Owner: true,
      Admin: true,
      Creator: true,
      Instructor: false,
      User: false,
    },
    {
      key: 'useAICredits',
      feature: formatMessage({ id: 'permissions_useAICredits', defaultMessage: 'Use AI Credits' }),
      Owner: true,
      Admin: true,
      Creator: true,
      Instructor: false,
      User: false,
    },
    {
      key: 'editOthersCourses',
      feature: formatMessage({ id: 'permissions_editOthersCourses', defaultMessage: "Edit Others' Courses, manage other's students" }),
      Owner: true,
      Admin: true,
      Creator: false,
      Instructor: false,
      User: false,
    },
    {
      key: 'editSchool',
      feature: formatMessage({ id: 'permissions_editSchool', defaultMessage: 'Edit School' }),
      Owner: true,
      Admin: true,
      Creator: false,
      Instructor: false,
      User: false,
    },
    {
      key: 'inviteCreators',
      feature: formatMessage({ id: 'permissions_inviteCreators', defaultMessage: 'Invite Creators' }),
      Owner: true,
      Admin: true,
      Creator: false,
      Instructor: false,
      User: false,
    },
    {
      key: 'setUpPayments',
      feature: formatMessage({ id: 'permissions_setUpPayments', defaultMessage: 'Set up payments' }),
      Owner: true,
      Admin: false,
      Creator: false,
      Instructor: false,
      User: false,
    },
    {
      key: 'changeOwner',
      feature: formatMessage({ id: 'permissions_changeOwner', defaultMessage: 'Change the owner (with paid subscription)' }),
      Owner: true,
      Admin: false,
      Creator: false,
      Instructor: false,
      User: false,
    },
  ];
};