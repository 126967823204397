import React, { useMemo } from 'react';

import { useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { DropdownComponent, LoaderOnboardingComponent, Svg, PaidComponent } from '../../common';
import { VoiceSelector } from '../../common/VideoIframe/VideoEditor.component/VoiceSelector';

export const TranslateComponent = (props) => {
    const {
        handleTranslate,
        handleLangChange,
        inProgressTranslate,
        setIsOpen,
        isOpen,
        currentlang,
        langs,
        classnames = 'link-btn_light answer__actions_bottom_btn btn_toggle',
        size = "small",
        setVoiceMode,
        voiceMode,
        userIsAllow,
        ...other
    } = props;
    const { formatMessage } = useIntl();

    return (
        <span className="task-share-component">
            {inProgressTranslate && (
                <LoaderOnboardingComponent
                    type={"overlay"}
                    label={inProgressTranslate}
                />
            )}
            <Button type='text'
                className={classnames}
                size={size}
                disabled={!userIsAllow}
                onClick={() => setIsOpen(true)}>
                <Svg
                    name=':icon/translate'
                    className='icon_24'
                />
            </Button>
            {!userIsAllow && <PaidComponent />}

            <Modal
                title={formatMessage({ id: 'translate_modal_title' })}
                cancelText={formatMessage({ id: 'cancel_label' })}
                okText={formatMessage({ id: 'translate_btn' })}
                centered={true}
                visible={isOpen}
                onOk={() => {
                    setIsOpen(false);
                    handleTranslate(currentlang);
                }}
                onCancel={() => setIsOpen(false)}>
                <p className="wrapper_inner-section">
                    {formatMessage({ id: 'select_lang' })}
                </p>
                <div className="lang-switchet-component">
                    <DropdownComponent
                        options={langs}
                        optionKey="id"
                        optionLabel='title'
                        currentValue={currentlang?.id}
                        onChange={(e) => handleLangChange(e)}
                    />
                </div>

                {setVoiceMode &&
                    <>
                        <hr />
                        <VoiceSelector
                            setVoiceMode={setVoiceMode}
                            voiceMode={voiceMode}
                            {...other}
                        />
                    </>
                }
            </Modal>
        </span>
    )
}
