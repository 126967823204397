import {gql} from '@apollo/client';

export const CreateConnectedSession = gql`
    mutation CreateConnectedSession($input: ConnectedSessionInput!) {
        createConnectedSession(input: $input) {
            url
            error
        }
    }
`;
