import React from 'react';
import { MentorEditContainer } from '../../profile/MentorEdit.container'
import { MentorCardContainer } from '../MentorBlock.component/MentorCard.container';
import { getTeachersFromEvent } from '../util/getTeachersFromEvent';

export const MentorBlockContainer = ({ user, event, ...other }) => {
    const teachers = getTeachersFromEvent(event, user);
    return (
        <>
            {event.creator?.id === user?.id && (
                <MentorEditContainer
                    type='INSTRUCTOR'
                    event={event}
                    {...other}
                />
            )}

            {!!teachers?.length &&
                teachers?.map((teacher) => (
                    <MentorCardContainer
                        key={teacher?.id}
                        teacherId={teacher?.studentId}
                        teacher={teacher}
                        user={user}
                        {...other}
                    />
                ))
            }
        </>
    )
}