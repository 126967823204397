export const youtubeParser = (videoLink) => {
    if (!videoLink) {
        return null;
    }

    let regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

    let match = videoLink?.match(regExp);
    let key = match && match[7] ? match[7] : false;
    return key && `https://www.youtube.com/embed/${key}`;
};

export const getYoutubeVideoId = (str) => {
    if (!str ) {
        return str;
    }
    const regExp = /[\w-]{11}$/g;
    return str?.match(regExp)?.[0];
};

export const youtubeReducer = (videoSrc, timestamp) => {
    const parsedLink = youtubeParser(videoSrc);
    let string = Math.floor(parseInt(timestamp) / 1000);
    return `${parsedLink}?start=${string}&autoplay=1&enablejsapi=1`;
};
