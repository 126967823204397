import { Skeleton } from "antd";

export const Loader = ({ hide }) => {
  const style = hide ? { display: "none" } : { textAlign: "center" };

  return (
    <div style={style}>
      <Skeleton active={true} paragraph={{ rows: 0 }} />
    </div>
  );
};
