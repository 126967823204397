
import './scss/index.scss';
import React, { useState } from 'react';
import { Modal, Input, } from 'antd';
import { useIntl } from 'react-intl';
import { UploadMp3Component, LoaderComponent } from '../../common';
import { useConvertAudioToText } from '../hook';
import { noop } from 'lodash';
const { TextArea } = Input;

export const AddMp3 = ({
    onAddSource = noop,
    index,
    // setInProgress = noop,
    // inProgress,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const [showModal, setShowModal] = useState(true);
    const [urlValue, setUrlValue] = useState();
    const [urlName, setUrlName] = useState();
    const [inProgress, setInProgress] = useState();
    const { getText } = useConvertAudioToText();

    const onChange = async (videoFile) => {
        setInProgress(true);
        let req = await getText(videoFile[0].response.url);
        if (req?.requestConvertApi) {
            const apiResponse = JSON.parse(req?.requestConvertApi);
            setUrlValue(apiResponse.text);
            setUrlName(videoFile[0].response.url);
            setInProgress(false);
        } else {
            setInProgress(false);
        }
    }

    let object = { key: "audio", type: 'AUDIO', source: urlName || '', title: `Audio`, raw: urlValue || '', icon: "audio" }
    const onSave = () => {
        onAddSource(object, index);
        setShowModal(false)
    }

    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: 'tab_ai_audio' })}
                onCancel={() => onSave()}
                confirmLoading={inProgress}
                onOk={() => onSave()}
            >

                <UploadMp3Component {...other}
                    onUploadSuccess={onChange}
                    setInProgress={setInProgress} />
                    
                {inProgress && <LoaderComponent type="inline" />}
                {urlValue && (
                    <TextArea
                        // ref={inputRef}
                        defaultValue={urlValue}
                        placeholder={formatMessage({ id: "form_text_data_placeholder" })}
                        className="ai-bot-component__field_counter "
                        maxLength={30000}
                        showCount
                        style={{ minHeight: 170 }}
                        autoSize={{ minRows: 2, maxRows: 10 }}
                    />
                )}

            </Modal>
        </>
    );
};
