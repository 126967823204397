import './scss/index.scss';
import React from 'react';
import { useIntl } from 'react-intl';
import { ProfileLink, checkTaskIconWithString } from '../../common';
import { ReactMarkdownComponent } from '../../task/ReactMarkdown.component/ReactMarkdown.component';

export const ResultComponent = ({result}) => {
    const { formatMessage } = useIntl();
    return (
        <div className='result wrapper_inner-section'>
            <div className='wrapper_inner-section'>
                <div className='result__header'>
                    {result?.user && <ProfileLink user={result?.user} />}
                    <small className='text_secondary'>
                        {checkTaskIconWithString(result?.state, formatMessage)}
                    </small>
                </div>
                <div className='result__content'>
                    <div className='result__description wrapper_content-section'>
                        <ReactMarkdownComponent children={result?.description} />
                    </div>
                </div>
            </div>
        </div>
    );
};
