import {gql} from '@apollo/client';

export const UpdateConnectedAccount = gql`
    mutation UpdateConnectedAccount($input: UpdateConnectedAccountInput!) {
        updateConnectedAccount(input: $input) {
            changed
            error
        }
    }
`;
