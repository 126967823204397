import "./scss/index.scss";
import React from "react";

export const ObjectivesComponent = ({ event }) => {
    // Split the program into lines and filter out any empty lines
    const programLines = event.program.split("\n").filter(line => line.trim() !== '');
    const halfLength = Math.ceil(programLines.length / 2);

    return (
        <div className="wrapper_content-section">
            <div className="wrapper_inner-section " style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                    <ul className="wrapper_list ">
                        {programLines.slice(0, halfLength).map((item, index) => (
                            <li key={`program-item-${index}`} className="program-item">
                                <span className="checkmark-icon">✔</span>
                                <span>{item.replace('- ', '').trim()}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div style={{ flex: 1 }}>
                    <ul className="wrapper_list ">
                        {programLines.slice(halfLength).map((item, index) => (
                            <li key={`program-item-${index + halfLength}`} className="program-item">
                                <span className="checkmark-icon">✔</span>
                                <span>{item.replace('- ', '').trim()}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}