import {useMutation} from '@apollo/client';
import {ActivateInvite} from '../graphQL/mutations/ActivateInvite';

export const useActivateInvite = (mutation = ActivateInvite) => {
    const [activateInvite, {data, error, loading}] = useMutation(mutation);

    return {
        activateInvite,
        invite: data?.activateInvite,
        error,
        loading
    }
}