import React, { useEffect, useState, useCallback } from 'react';

import { EventsListComponent } from '../../events';
import { EventsListActionsContainer } from '../EventsListActions.container';
import { useAnalytics, useIsAllow } from '../../common';
import { useGetEvents } from '../../events/hooks/useGetEvents';
import { useGetEventsWithStudents } from '../../events/hooks/useGetEventsWithStudents';
import { noop } from 'lodash';
import { useIsPermit } from '../../roles/hook/useCheckPermission';

export const ListEventsContainer = (props) => {
    const {
        as: Component = EventsListComponent,
        filterData,
        index,
        getWorkspaceData,
        eventsLists,
        workspace,
        typeProp,
        limit,
        createCourses,
        setAllEvents = noop,
        ...other
    } = props;
    const LIMIT = limit || 20;
    const { eventTrack } = useAnalytics();
    const { getEvents, loading: loadingEvent } = useGetEvents();
    const { getEventsWithStudents, loading: loadingStudents } = useGetEventsWithStudents();
    const [events, setEvents] = useState([]);
    const [isShowMore, setIsShowMore] = useState(false);
    const [offset, setOffset] = useState(0);
    const [type, setType] = useState(typeProp);
    const [isLoading, setIsLoading] = useState(false);

    const useIsAllowd = useIsAllow();
    const { checkPermit } = useIsPermit()
    const editOthersCourses = useIsAllowd("PRO") || checkPermit(workspace, 'editOthersCourses')

    const handleEventClick = (e, event) => {
        const eventData = e;
        eventData.clickText = event.title;
        eventData.clickId = 'click-event-card';
        eventData.clickUrl = `${window.href}online-courses/${event.id}`;
        eventTrack('click-event-card', eventData);
    };

    const fetchMoreEvents = async () => {
        const eventsList = await handleEvents(
            filterData,
            offset,
        );
        setOffset((prev) => prev + LIMIT);
        setIsShowMore(eventsList?.length === LIMIT);
        setAllEvents((prev) => [...(prev || []), ...(eventsList || [])]);
        setEvents((prev) => [...(prev || []), ...(eventsList || [])]);
    };

    const handleEvents = useCallback(
        async (filter, offset = 0) => {
            const { tagsIds = [], ...rest } = filter;
            setIsLoading(true);
            if (!!createCourses) {
                const resp = await getEventsWithStudents({
                    variables: {
                        filter: {
                            ...rest,
                            ...(!!tagsIds?.length && { tags: tagsIds?.map((id) => parseInt(id)) })
                        },
                        isOwner: !!editOthersCourses,
                        limit: LIMIT,
                        offset
                    },
                    fetchPolicy: "network-only"
                });
                setAllEvents((prev) => prev ? [...prev, ...(resp?.data?.events || [])] : [...(resp?.data?.events || [])]);
                setIsLoading(false);
                return resp?.data?.events || [];
            } else {
                const resp = await getEvents({
                    variables: {
                        filter: {
                            ...rest,
                            ...(!!tagsIds?.length && { tags: tagsIds?.map((id) => parseInt(id)) })

                        },
                        isOwner: !!editOthersCourses,
                        limit: LIMIT,
                        offset
                    },
                    fetchPolicy: "network-only"
                });
                setAllEvents((prev) => [...(prev || []), ...(resp?.data?.events || [])]);
                setIsLoading(false);
                return resp?.data?.events || [];
            }
        },
        [filterData]
    );

    const actionsComponent = createCourses ? () => (<EventsListActionsContainer index={index}
        workspaceId={filterData.workspaceId}
        eventsLists={eventsLists}
        updateWorkspace={getWorkspaceData}
        type={type}
        setType={setType}
    />) : null;

    const initFetch = async () => {
        const eventsList = await handleEvents(filterData);
        if (eventsList) {
            setEvents(() => [...eventsList]);
            setOffset((prev) => prev + LIMIT);
            setIsShowMore(eventsList?.length === LIMIT);
        }
    };
    useEffect(() => {
        initFetch();
    }, []);

    return (
        <Component {...other}
            isLoading={isLoading}
            events={events}
            isShowMore={isShowMore}
            onShowMore={fetchMoreEvents}
            onClick={handleEventClick}
            asActions={actionsComponent}
            filterData={filterData}
            type={type}
            createCourses={createCourses}
            setEvents={setEvents}
            workspace={workspace}
            updateEvents={initFetch}
        />
    )
}
