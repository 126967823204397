import { gql } from "@apollo/client";

export const GetEventStudents = gql`
    query GetEvent($id: Int, $alias: String, ) {
        event(id: $id, alias: $alias) {
            id
            alias
            type
            title
            createDate
            duration
            badge
            creator {
                id
                name
                picture
            }
            langId
            galleriesInEvent {
                gallery {
                    id
                    type
                    source
                }
                order
            }
            tasks {
                    id
                    difficultyId
                    alias
                }
            userSubscriptions {
                id
                end
                student {
                    name
                    email
                    id
                    picture
                    results {
                        state
                        taskId
                        answers {
                            answer
                            questionId
                            createDate
                        }
                        modifyDate
                        createDate
                    }
                    lastVisit
                }
                start
            }
            tags {
                id
                title
                subject {
                    color
                }
            }
            isSubscribe
            isModerated
            isDraft
            workspaceId
            workspaceIds
            price {
                forStudent
            }
        }
    }
`;
