import React, {useContext, useEffect, useState} from 'react';
import {get, noop} from 'lodash';
import {Button, Form} from 'antd';
import {TaskArticleComponent} from '../../task/TaskArticle.component';
import {Svg} from '../../common';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const PanelAnswerComponent = (props) => {
    const {task, onClose = noop, ...other} = props;
    const {user} = useContext(FirebaseContext);
    const [form] = Form.useForm();
    const [comments, setComments] = useState();

    // eslint-disable-next-line no-unused-vars
    const handleAddComment = async ({comment}) => {
        const {name, picture, uid} = user;
        const {id} = task;
        const createDate = new Date().toLocaleString();
        const data = {
            comment,
            createDate,
            creator: {name, picture, uid},
            isModerated: false,
        };
        form.setFieldsValue({comment: ''});
    };

    return (
        <div className='panel-block__container panel-block__container_answer'>
            <div className='panel-block__item '>
                <div className='panel-block__item__header'>
                    <div className='panel-block__item__description'>
                        <Button
                            type='text'
                            className='inline-el panel-block__item__btn'
                            onClick={onClose}
                        >
                            <Svg
                                name=':icon/arrow-left'
                                className='icon_24'
                            />mouseX
                            <span style={{marginLeft: '12px'}}>
                                {task.themeName}{' '}
                            </span>
                        </Button>
                    </div>
                </div>
                <TaskArticleComponent {...other} task={task} key={task.id} />
            </div>
        </div>
    );
};
