import React from 'react';
import { InvitedUser } from '../../event/InvitedUser.component/InvitedUser.component';
import { useDeleteInvite } from '../hooks';

export const InvitedUsersWorkspaceContainer = ({
    workspace,
    onUpdateWorkspace,
}) => {
    const { deleteInvite } = useDeleteInvite();
    const handleRemoveInvite = async (id) => {
        const newInvites = [...workspace.invites];
        await deleteInvite({ variables: { id } });
        onUpdateWorkspace();
    };

    return (
        <>
            {workspace.invites && workspace.invites.map((invite) =>
                <InvitedUser
                    key={`invited-user_${invite.id}`}
                    invite={{
                        id: invite.id,
                        invitedEmail: invite.email,
                    }}
                    handleRemoveParticipant={handleRemoveInvite}
                />
            )}
        </>
    );
};
