import './scss/index.scss';
import React from 'react';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { checkAnswerIconWithString, checkTaskIconWithString, ProfileLink } from '../../common';
import { ReactMarkdownComponent } from '../../task/ReactMarkdown.component/ReactMarkdown.component';

export const AnswerComponent = ({ answer }) => {
    const { formatMessage } = useIntl();
    const options = { day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };

    return (
        <div className='result wrapper_inner-section'>
            <div className='wrapper_inner-section'>
                <div className='result__header'>
                    {answer?.student && <ProfileLink user={answer?.student} />}
                    <small className='text_secondary'>
                        {!answer?.question ? checkTaskIconWithString(answer?.result?.state, formatMessage) : checkAnswerIconWithString(answer, formatMessage)}, {new Date(answer.createDate).toLocaleString('en-GB', options)}
                    </small>
                </div>
                <div className='result__content'>
                    <ul className='wrapper_list'>
                        {!!answer?.options?.length && answer?.options?.map((option, optionIndex) => (
                            <li className='result__option text_secondary' key={optionIndex}>
                                {option?.isCorrect ? <FontAwesomeIcon icon={faCheckCircle} className="link-btn_light" /> : <FontAwesomeIcon icon={faTimesCircle} className="link-btn_light" />} {' '}
                                {option?.text}
                            </li>
                        ))}
                    </ul>
                    <ReactMarkdownComponent children={answer?.answer} />
                    {answer?.result?.description &&
                        <div className='result__description wrapper_content-section'>
                            <ReactMarkdownComponent children={answer?.result?.description} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
