import { gql, useMutation } from "@apollo/client";

export function useCreateUser() {
    const [mutationCreateUser, { data, loading, error }] = useMutation(CREATE_USER);
    async function createUser(userData) {
        const result = await mutationCreateUser({
            variables: { userData }
        });
        return result.data?.createUser;
    }

    return {
        loading,
        error,
        createUser
    }
}

const CREATE_USER = gql`
    mutation CreateUser($userData: CreateUserInput!) {
        createUser(userData: $userData) {
        id
        alias
        googleUid
        createDate
        email
        phone
        name
        description
        picture
        role {
            id
            title
            description
        }
        hidden
        canTeach
        coins
        coinsBalance
        currency {
            title
            code
            id
            order
        }
        lang {
            id
            title
        }
        emailVerified
        }
    }
`;