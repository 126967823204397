import { gql, useQuery } from "@apollo/client";

export function useGetCurrencies() {
    const { data, error, loading } = useQuery(GET_CURRENCIES);

    return {
        currencies: data?.currencies,
        error,
        loading
    };
}

const GET_CURRENCIES = gql`
    query GetCurrencies {
        currencies {
            id
            title
            symbol
            code
            rate
            order
        }
    }
`;
