import React from 'react';
import { YouTubeInput } from '../../common';
const TEXT_URL = 'https://text.unschooler.me/?url=';

export const YouTubeLinkTab = ({ task, setCover, ...other }) => {
    const getCover = (urlName, sourceText) => {
        if (urlName) {
            return {
                type: "YOUTUBE",
                source: urlName,
                id: task.cover?.id,
                raw: sourceText || '',
                timestamps: [],
                title: 'urlName',
            }
        }
    }

    const onVideoDataChange = ({ urlName, sourceText }) => {
        const newCoverData = getCover(urlName, sourceText);
        setCover(newCoverData);
    };

    // const onGenerateSubtitles = async () => {
    //     // this DOESNT WORKS but it is nee to move to cahnge video comp
    //     setInProgress({
    //         message: formatMessage({ id: 'progress_subtitle_generation_message' }),
    //         key: 'generateSubtitles'
    //     })

    //     try {
    //         const myHeaders = new Headers();
    //         myHeaders.append("Origin", "https://text.unschooler.me");

    //         const resp = await fetch(`${TEXT_URL}${videoSrc}`, {
    //             method: 'GET',
    //             headers: myHeaders,
    //             mode: 'cors',
    //             cache: 'default',
    //         });
    //         const jsonData = await resp.json();
    //         if (jsonData?.text) {
    //getCover    //         }
    //     } catch (error) {
    //         console.error(error)
    //     }
    //     setInProgress({
    //         key: 'generateSubtitles'
    //     })
    // }

    return (
        <YouTubeInput
            onVideoDataChange={onVideoDataChange}
            task={task}
            {...other}
        />
    );
};