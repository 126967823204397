import { useCallback } from 'react';
import { useAiChatResponse } from '../../ai/graphQL/useAiChatResponse';

export const useGetSuggestions = () => {
    const { getChatResponse, error } = useAiChatResponse();

    const getSuggestions = useCallback(
        async (rawdata, prompt) => {

            const settings = {
                args: {
                    data: JSON.stringify(rawdata),
                    request: prompt,
                },
                type: 'suggestions',
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = response?.data
                ? JSON.parse(response?.data?.requestChatApi)
                : null;
            if (responseJSON?.choices[0]) {
                let responseTrimmed =
                    JSON.parse(responseJSON.choices[0].message.content)?.suggestions;
                return responseTrimmed;
            }
        },
        [getChatResponse]
    );

    return { getSuggestions, error };
};
