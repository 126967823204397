import React from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { JoinWComponent } from '../../common/JoinW.component/JoinW.component'

export const JoinComponent = (props) => {
    const { user,
        handleJoinWorkspace,
        showJoinForm,
        isSighUp,
        setIsSighUp,
        ...other } = props;
    const { formatMessage } = useIntl();

    return (
        <div>
            <Button
                type={'secondary'}
                className='margin_right'
                htmlType='submit'
                onClick={() => {
                    setIsSighUp(false)
                    handleJoinWorkspace()
                }}
            >
                {formatMessage({ id: 'sign_to_join' })}
            </Button>
            <Button
                type={'tertiary'}
                htmlType='submit'
                onClick={() => {
                    setIsSighUp(true)
                    handleJoinWorkspace()
                }}
            >
                {formatMessage({ id: 'sign_to_join_signup' })}
            </Button>

            <JoinWComponent
                showJoinForm={showJoinForm}
                isSighUp={isSighUp}
                {...other}
            />
        </div>
    )
};
