
import React, { useState } from 'react';

import { VideoEditorComponent } from '../VideoEditor.component/VideoEditor.component'

export const VideoEditorContainer = ({
    cover,
    chunks,
    editGallery,
    setIsEditMode,
    ...other
}) => {
    const [error, setError] = useState();
    const [inProgress, setInProgress] = useState();

    const onFinish = async (data) => {
        setInProgress('Loading')
        await editGallery(data?.timestamps)
        setInProgress('')
        setIsEditMode(false)
    }

    return (
        <VideoEditorComponent
            chunks={chunks}
            handleFinish={onFinish}
            setIsEditMode={setIsEditMode}
            inProgress={inProgress}
            {...other}
        />
    );
}
