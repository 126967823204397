import {gql} from "@apollo/client";

export const EditWorkspaceEventsList = gql`
    mutation EditWorkspaceEventsList($input: EventsListInput, $id: Int!) {
        editWorkspaceEventsList(input: $input, id: $id) {
            id
            title
            workspaceId
            courses
            tags
            order
        } 
    }
`;
