import { gql } from "@apollo/client";

export const GetVideoCaptions = gql`
  query getVideoCaptions($id: String) {
      getVideoCaptions(id: $id) {
          captions {
              text
              duration
              offset
          }       
      }
  }
`;