import React, { useMemo } from 'react';

import { UploadFileComponent, FilesGalleryComponent } from '../../common';

export const UploadAttachmentComponent = ({ galleries, onLoadFile, onDeleteFile }) => {
    const galleriesList = useMemo(() => galleries?.filter((gal) => gal?.type === 'FILE'), [galleries]);

    const handleLoad = (resp) => {
        const fileData = {
            url: resp.url,
        }
        onLoadFile(fileData);
    }

    // TODO: Need translations
    return (
        <div className="upload-attachment-component">
            <h3>Upload attachments</h3>
            {!!galleriesList?.length && (
                <FilesGalleryComponent
                    galleriesList={galleriesList}
                    onDeleteFile={onDeleteFile}
                />
            )}

            <div className="edit-task-component__question__option__body">
                <UploadFileComponent onLoadFile={handleLoad} />
            </div>
        </div>
    )
}