import React, {useMemo, useEffect, useContext} from 'react';
import {WorkspaceSelectComponent} from '../WorkspaceSelect.component';
import {useGetWorkspaces} from '../hooks';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const WorkspaceSelectContainer = ({...other}) => {
    const { user, workspace } = useContext(FirebaseContext);
    const {getWorkspaces, workspaces, loading} = useGetWorkspaces();
    const {id: workspaceId, ownerId} = workspace || {};
    const {id: userId, role} = user || {};
    const isDisabled = useMemo(() => {
        const isAdmin = role?.id === 1;
        const isOwner =  ownerId === userId ;
        return !(isOwner || isAdmin);
    }, [workspaceId, userId, role?.id]);

    const options = useMemo(() => {
        if (isDisabled || !workspaces) {
            const {id, host, status, customDomain} = workspace || {};

            return [
                {
                    value: id,
                    label: status && customDomain ? customDomain : `${host}.unschooler.me`
                }
            ]
        }

        return workspaces?.map((w) => ({value: w.id, label: w.status && w.customDomain ? w.customDomain : `${w.host}.unschooler.me`}));
    }, [workspace, workspaces, isDisabled]);

    useEffect(() => {
        if (!isDisabled && !workspaces && !loading) {
            (async () => await getWorkspaces({
                variables: {ownerId: ownerId || user?.id}
            }))()
        }
    }, [workspaces, isDisabled]);

    return <WorkspaceSelectComponent idDisabled={isDisabled}
                                     options={options}
                                     loading={loading}
                                     {...other}/>
}