import React, { useState, useEffect } from 'react';
import { Form, Switch } from 'antd';
import { AchievementsProgress } from '../../badges';
import { useTaskDripLogic } from '../../task/hooks/useTaskDripLogic';

export const BadgesFormTabComponent = ({
    formatMessage,
    form,
    initialValues,
    eventState
}) => {
    const { tagsRenew, } = useTaskDripLogic(eventState, null, null, null, false, [null]);
    const [showBadges, setShowBadges] = useState(!!initialValues?.badge);

    console.log('showBadges', showBadges, tagsRenew);

    useEffect(() => {
        const badgeValue = form.getFieldValue('badge');
        setShowBadges(!!badgeValue);
    }, [form.getFieldValue('badge')]);

    const handleBadgeChange = (checked) => {
        form.setFieldsValue({ badge: checked });
        setShowBadges(checked);
    };


    return (
        <div className="wrapper_content flex-left-body start-side-container_d">
         <Form.Item 
                name="badge"
                label={formatMessage({ id: 'create_event_tab_6' })}
                className="edit-task-component__is-free flex-between"
                valuePropName="checked"
                initialValue={initialValues?.badge}
            >
                <Switch onChange={handleBadgeChange} />
            </Form.Item>
            <p>{formatMessage({ id: 'badges_description' })}</p>
            <hr />
            { !!tagsRenew?.length &&
                <div className=' cards '>
                    {tagsRenew?.map((tag, i) => (
                        <div className='skills_tag_icon'>
                            <AchievementsProgress
                                type={`small`}
                                key={tag?.id}
                                achievement={{ ...tag, counter: { ...tag?.counter, done: showBadges && tag?.counter?.all } }}
                                width={80}
                            />
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};