import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from 'antd';
import {FirebaseContext} from '../../../context/firebase/firebaseContext'
import translate from '../../../lang/translate';
import {useLocalePrefix} from '../../lang';

export const SuccessComponent = (props) => {
    const {
        product,
        isGiftBox
    } = props;

    const history = useHistory();
    const [localePrefix] = useLocalePrefix();
    // const [askPhone, setAskPhone] = useState();
    const {user} = useContext(FirebaseContext);
    const {title, mentorName, id} = product;
    const {email} = user || {};

    const isTestSkills = product?.id === 343;

    // useEffect(() => {
    //     if(user && !user?.phone) {
    //         setAskPhone(true);
    //     }
    // }, [user]);

    return (
        <div className="success-component">
            <h1>{translate('checkout_thanks')}</h1>
            <div className="success-component-body">
                {!isGiftBox && (
                    <>
                        {isTestSkills
                            ? (
                                <div className="success-component-body-item">
                                    You've upgraded the plan to test your skills.
                                </div>
                            )
                            : (
                                <div className="success-component-body-item">
                                    You enrolled course "{title}" with {mentorName}
                                </div>
                            )
                        }

                        <div className="success-component-body-item">
                            {translate('checkout_booking_confirmation')} {email}
                        </div>
                    </>
                )}
                {isGiftBox && (
                    <>
                        <div className="success-component-body-item">
                            {translate('checkout_success_purchase')}{title}".
                        </div>
                        <div className="success-component-body-item">
                            {translate('checkout_link_for_activation')}
                        </div>
                    </>
                )}

                <div className="success-component-body-item-action">
                    {isTestSkills
                    ? (
                        <Button type="primary"
                                onClick={()=>history.push(`${localePrefix}/skills/`)}>
                            <span>
                                {translate('checkout_go_to_skills')}
                            </span>
                        </Button>
                        )
                    : (
                        <Button type="primary"
                                onClick={()=>history.push(`${localePrefix}/online-courses/${id}`)}>
                            <span>
                                {translate('checkout_check_other_lessons')}
                            </span>
                        </Button>
                        )
                    }

                </div>
            </div>
            {/* <PhoneContainer uid={user?.googleUid}
                            countryCode={user?.countryCode}
                            askPhone={askPhone}
                            onCancelAskPhone={setAskPhone}/> */}
        </div>
    )
}
