import {gql, useMutation} from '@apollo/client';
import {useCallback} from 'react';

export const useResetPassword = (mutation = RESET_PASSWORD) => {
    const [resetPasswordCallback, { data, loading, error }] = useMutation(mutation);

    const resetPassword = useCallback(async (email) => {
        const resp = await resetPasswordCallback({
            variables: {
                input: {
                    email
                }
            }
        });

        return resp;
    }, [resetPasswordCallback]);
    return {resetPassword, data, loading, error};
}

const RESET_PASSWORD = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            resetToken
            error
        }
    }
`;