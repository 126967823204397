import { gql } from "@apollo/client";

export const GetPaypalAccount = gql`
    query GetPaypalAccount($id: Int!) {
        getPaypalAccount(id: $id) {
            id
            clientId
            workspaceIds
        }
    }
`;