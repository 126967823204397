import {gql} from "@apollo/client";

export const GetConnectedAccount = gql`
    query getConnectedAccount($userId: Int) {
        getConnectedAccount(userId: $userId) {
            id
            userId
            activatedAt
            stripeAccountId
            stripeAccountIsActivated
        }  
    }
`;

