import React, { useContext } from 'react';
import { AiAvatarComponent } from '../AiAvatar.component';
import { useGenerateAiAvatar } from '../hook';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { useTrackCredits } from '../../common/';

export const AiAvatarContainer = (props) => {
    const {
        audio,
        selectedTeacher,
        index,
        defaultsSource = 'https://d-id-public-bucket.s3.us-west-2.amazonaws.com/alice.jpg',
        task,
        onSaveAvatar,
        event,
        ...other
    } = props;
    const { user } = useContext(FirebaseContext);
    const { id: userId } = user || {};
    const { id: taskId } = task || {};
    const { generateAiAvatar, loading } = useGenerateAiAvatar();
    const { trackCredits } = useTrackCredits()

    const handleGenerate = async () => {
        const resp = await generateAiAvatar({
            variables: {
                audio,
                source: selectedTeacher?.picture || defaultsSource,
                userId,
                taskId
            }
        });

        const { url, duration } = resp?.data?.aiAvatarGenerate;

        if (url) {
            onSaveAvatar(index, 'aiAvatarUrl', url);
            const convertedDuration = Number((duration / 60).toFixed(2));
            trackCredits(convertedDuration, 'did');
        }
    }

    return <AiAvatarComponent
        onGenerate={handleGenerate}
        loading={loading}
        source={selectedTeacher?.picture || defaultsSource}
        withGenerateButton={true}
        type={null}
        {...other}
    />
}