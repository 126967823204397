import React, {useState} from 'react';
import {Button, Input} from 'antd';
import {noop} from 'lodash';
import {getDate} from '../../../reports';

type Props = {
    item: Object,
    onPay: Function
}

export const RequestItemComponent = (props: Props) => {
    const {
        item,
        onPay = noop
    } = props;

    const [paymentId, setPaymentId] = useState();

    const handleClick = () => {
        onPay(Object.assign(item, {paymentId}));
    };

    return (
        <tr className="data-table__row">
            <td className="data-table__col">{item.email}</td>
            <td className="data-table__col">${item.amount}</td>
            <td className="data-table__col">{item.uid}</td>
            <td className="data-table__col">{getDate(item.requestDate)}</td>
            <td className="data-table__col">
                <div className="data-table__col__field">
                    <Input onChange={(e) => {setPaymentId(e.target.value)}}/>
                </div>
                <Button onClick={handleClick}
                        disabled={!paymentId}>
                    Подтвердить оплату
                </Button>
            </td>
        </tr>
    )
}
