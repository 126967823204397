import React from 'react';
import {TitleComponent} from '../Components/common';

export const AccessDenied = () => {
    return (
        <div className='page'>
            { /* TODO: Need translation */}
            <TitleComponent title='Access denied' back={false} />
        </div>
    );
};
