import { useCallback } from "react";
import { useAiChatResponse } from '../graphQL/useAiChatResponse';
import { useGetVideoListByString } from "../../youtube/hooks";
import { useGetRelevantContent } from "../hook/useGetRelevantContent";

const useGetMostRelevantVideo = (items, queryString) => {
    const { getChatResponse, error } = useAiChatResponse();
    const { getRelevantContent } = useGetRelevantContent();

    const getAiResponse = useCallback(
        async (items, queryString, goal) => {
            // let itemsClone = [...items];

            let shortItems = items.map(element =>
            (
                {
                    title: element.title,
                    id: element.id,
                    description: element?.description?.substring(0, 300)
                }
            )
            );

            // const id = await getRelevantContent(shortItems, queryString, goal);
            let video = items[0];

            // if (id) {
            //     const index = items.findIndex(element => element.id === id.replace(/['"]+/g, ''));
            //     video = items[index];

            //     // itemsClone.splice(index, 1);
            //     // itemsClone.unshift(video);
            // }

            const iframeHtml = `<div className='youtube-list-component'><iframe title='${video?.title || "Step Video"
                }' className='videoIframeStyle' src='https://www.youtube.com/embed/${video.id}?rel=0'
            frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen={false}></iframe></div>`;
            return iframeHtml;

        },
        [getChatResponse]
    );

    return { getAiResponse };
};

export const useGetVideo = () => {
    const { getVideoList } = useGetVideoListByString();
    const { getAiResponse } = useGetMostRelevantVideo();

    const getVideo = useCallback(
        async (queryString, goal) => {
            if (!!queryString) {
                const { data } = await getVideoList({
                    variables: {
                        q: queryString,
                        maxResults: 10,
                    },
                });
                const items = data?.getVideoList?.items;
                if (!!items?.length) {
                    let ev = await getAiResponse(items, queryString, goal);
                    // const video = items[0];
                    // const iframeHtml = `<div className='youtube-list-component'><iframe title='${video?.title || "Step Video"
                    //     }' className='videoIframeStyle' src='https://www.youtube.com/embed/${video.id
                    //     }' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen={false}/></div>`;

                    // return iframeHtml;
                    return ev;
                }
                return null;
            }
        },
        [getVideoList, getAiResponse]
    );

    return { getVideo };
};
