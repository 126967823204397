import { useRef, useContext } from 'react';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const useProgress = () => {
    const {inProgress, setInProgress} = useContext(FirebaseContext);
    const progressRef = useRef([]);

    const handleChangeProgress = (val) => {
        const { message, key, ...other } = val;

        if (message) {
            const activeItem = progressRef?.current?.find(item => item.key === key);
            if (!activeItem) {
                progressRef?.current.push(val);
                setInProgress({
                    message: val.message,
                    ...(other && other)
                });
            }
        } else {
            const index = progressRef?.current.findIndex(item => item.key === key);

            if (index !== -1) {
                progressRef?.current.splice(index, 1);
                setInProgress(progressRef?.current?.[0]?.message
                    ? {message: progressRef?.current?.[0]?.message, ...(other && other)}
                    : false);
            }
        }
    };

    return { inProgress, handleChangeProgress };
};