import {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {useGetLangs} from '../../common/graphQL';
import {useTrimPrefixPath} from '../hooks';

const DEFAULT_LOCALE = 'en';

export const useNewPathName = () => {
    const {langs} = useGetLangs();
    const {pathname} = useLocation();
    const getTrimPath = useTrimPrefixPath();

    return useCallback((id) => {
        const codes = langs?.map(l => l.code);
        const lang = langs.find(l => l.id === id);
        const newLocale = lang.code === DEFAULT_LOCALE ? '' : `/${lang.code}`;
        const trimPath = getTrimPath(codes);
        return newLocale + trimPath;
    }, [langs, pathname])
};
