import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Form, Input } from 'antd';
import classnames from 'classnames';
import { UploadImgComponent, useIsAllow } from '../../common';
import { StripeConnectedAccountContainer } from '../../stripe';
import { EditCustomVoiceContainer } from '../../voices';
import { useIsPermit } from '../../roles/hook/useCheckPermission';

export const EditUserComponent = (props) => {
    const {
        user,
        onEdit,
        workspace,
    } = props;

    const { formatMessage } = useIntl();
    const { useForm } = Form;
    const [form] = useForm();
    const { setFieldsValue } = form;
    const userIsAllowed = useIsAllow();
    const isAllowCreator = userIsAllowed("PREMIUM");
    const [activeTab, setActiveTab] = useState(0);

    const { checkPermit } = useIsPermit()
    const isWorkspaceOwner = checkPermit(workspace, 'setUpPayments')
    const showTabs = !workspace || isWorkspaceOwner;
    const { name, picture } = user || {};
    const [imagesList, setImagesList] = useState([{ url: picture }]);
    const getTabClassNames = (index) => classnames('flex-sidebar__tab', {
        'flex-sidebar__tab_active': activeTab === index
    });

    const getTabContentClassNames = (index) => classnames('tab__content', {
        'tab__content_active': activeTab === index
    });

    const handleSetImageList = (data) => {
        if (data) {
            const { response } = data?.[0] || {};
            if (response) {
                setFieldsValue({ picture: response.url });
            }
        } else {
            setFieldsValue({ picture: '' });
        }
        setImagesList(data);
    };

    const handleRemove = () => {
        setImagesList(null);
        setFieldsValue({ picture: '' });
    };

    useEffect(() => {
        setImagesList([{ url: picture }]);
    }, [picture]);

    return (
        <div className="edit-user-component flex_align-stretch_d flex-grow_1 flex-container sidebar-wrapper flex_align-stretch_d">
            {showTabs && (
                <div className="flex-sidebar">
                    <div className="flex-sidebar__tabs">
                        <div className={getTabClassNames(0)}
                            onClick={() => setActiveTab(0)}>
                            {formatMessage({ id: "Change_title" })}
                        </div>
                        <div className={getTabClassNames(1)}
                            onClick={() => setActiveTab(1)}>
                            {formatMessage({ id: "stripe_connect_label" })}
                        </div>
                        <div className={getTabClassNames(2)}
                            onClick={() => setActiveTab(2)}>
                            {formatMessage({ id: "custom_voice_title" })}
                        </div>
                    </div>
                </div>
            )}
            <div>
                <div className={getTabContentClassNames(0)}>
                    <Form name='edit-user'
                        initialValues={{ name, picture }}
                        layout='vertical'
                        form={form}
                        onFinish={onEdit}>
                        <Form.Item name="picture"
                            label={formatMessage({ id: 'user_picture_label' })}
                            rules={[{ required: true }]}>
                            <UploadImgComponent savedImages={imagesList}
                                limitImages={1}
                                onSetImages={handleSetImageList}
                                onRemove={handleRemove} />
                        </Form.Item>
                        <Form.Item label={formatMessage({ id: 'user_name_label' })}
                            name='name'
                            rules={[{ required: true }]}
                            width={400}>
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type='tertiary' htmlType='submit'>
                                {formatMessage({ id: 'form_button_save' })}
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className='profile-block__data'>
                        {!workspace && <p>{formatMessage({ id: 'delete_account_info' })}</p>}
                    </div>
                </div>
                <div className={getTabContentClassNames(1)}>
                    {isAllowCreator ? (
                        <StripeConnectedAccountContainer />
                    ) : (
                        <Link className='link-btn_primary ant-btn ant-btn-primary' to={'/payments'}>
                            <span> Creator +, {formatMessage({ id: 'payment_button' })}
                            </span>
                        </Link>
                    )}
                </div>
                <div className={getTabContentClassNames(2)}>
                    <EditCustomVoiceContainer />
                </div>
            </div>
        </div>
    );
};