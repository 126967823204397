import './scss/index.scss';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory, Link } from 'react-router-dom';
import { TaskWrapperComponent } from '../../task/TaskWrapper.component';
import { useLocalePrefix } from '../../lang';
import { AchievementList } from "../../badges/AchievementList.component";
import { getDegColor } from '../../common/';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useGetGoals } from '../../common/graphQL/goals/useGetGoals';
import { getStorageManager } from "../../common";
import { useUpdatePreference } from '../../common/graphQL';
import { useGetPreference } from '../../common/graphQL/preference/useGetPreference';
import { useIntl } from 'react-intl';

export const TodoListContainer = (props) => {
    const {
        user,
        task,
        gotAchievement,
        ...other
    } = props;
    const { updatePreference } = useUpdatePreference();
    const { preference, getPreference } = useGetPreference({fetchPolicy: 'no-cache',});
    const { goals, getGoals } = useGetGoals({fetchPolicy: 'no-cache', });
    const intl = useIntl();

    const { getStorageData, setStorageData } = getStorageManager();
    const currentTodoList = getStorageData('currentTodoList');
    const [achievement, setAchievement] = useState();
    const [tasksToLearnSt, setTasksToLearn] = useState(task && [task]);

    let date = new Date();
    let dateString = date.toLocaleDateString();

    const updatePrefInUser = async (pref, goalsDB) => {
        let goalNew = goalsDB.filter(goal => goal.title === gotAchievement.achievement.title);
        const goalIds = pref?.goals.map(goal => goal.id);
       
        updatePreference({
            goals:  !goalIds.includes(goalNew) ? goalIds.push(goalNew.id) : goalIds
        })
    }

    const setupAchevement = () => {
        if (user?.achievements) {
            let achievements = user?.achievements?.filter(ach => ach.order);
            setAchievement(achievements.find(ach => !ach.active))
            return achievements.find(ach => !ach.active)
        }
    }
    const getTodoList = () => {
        let tasksToLearn = [];
        if (!currentTodoList || currentTodoList.date !== dateString) {
            !!user?.events?.length && user?.events?.forEach(event => {
                let tasksd = event.tasks.filter(task => (!task.stateResult || task.stateResult === "NOT_VERIFIED"))
                if (tasksd) {
                    tasksd?.forEach(t => t.event = event);
                }
                tasksToLearn = [...tasksToLearn, ...tasksd]
            })

            let taskIds = tasksToLearn?.map(el => el.id).slice(0, 1);
            if (!!taskIds?.length) {
                setTasksToLearn(tasksToLearn);
                setStorageData('currentTodoList', { tasks: taskIds, date: dateString, achievement: setupAchevement() });
            }
        } else {
            if (currentTodoList.date === dateString) {
                !!user?.events?.length && user?.events?.forEach(event => {
                    event?.tasks?.forEach(task => {
                        if (currentTodoList.tasks.includes(task.id)) {
                            // task.event = event;
                            tasksToLearn.push(task);
                            setAchievement(currentTodoList.achievement)
                            setTasksToLearn(tasksToLearn);
                        }
                    })
                }
                )
            }
        }
    }

    const color = !!tasksToLearnSt?.length ? tasksToLearnSt[0]?.tags[0]?.subject.color : '';
    const done = !!tasksToLearnSt?.length && tasksToLearnSt[0]?.stateResult && tasksToLearnSt[0]?.stateResult !== "NOT_VERIFIED";

    const ImgClass =
        classnames('todo_img', {
            'todo_img_active': done,
        });

    useEffect(() => {
        if (user?.achievements) {
            getTodoList()
        }
    }, [user])

    useEffect(() => {
        if (gotAchievement) {
            (async () => {
                const goals = await getGoals(1);
                const pref = await getPreference(user?.id)
                updatePrefInUser(pref, goals);
            })()

        }
    }, [getGoals, getPreference, gotAchievement, user?.id]);

    return (
        <div>
            <div className="todo" style={{ backgroundColor: color }}>
                <img
                    className={ImgClass}
                    alt='subject logo'
                    style={{
                        color: color,
                        filter: `invert(10%) sepia(10%) saturate(158%) contrast(154%) brightness(75%) hue-rotate(${getDegColor(color)}deg)`,
                    }}
                    src={achievement?.icon}
                />

                <div className='todo_header'>
                    <h1 className=' skills__title'> {done ? 'Completed!': 'Learn today'} {intl.formatDate(date, { day: 'numeric', month: 'short' })}:</h1>
                    {/* <p className="card-title">{achievement?.label}: {achievement?.description}</p> */}
                </div>
                {!!tasksToLearnSt?.length && tasksToLearnSt.slice(0, 1).map((task, i) => (
                    <div key={task?.id}>
                        <small>{task?.event?.title}</small>
                        <TaskWrapperComponent
                            {...other}
                            task={task}
                            index={i}
                            next={!done && i === 0}
                        />
                    </div>
                )
                )}
            </div>
        </div>
    );
};
