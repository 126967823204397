import React from 'react';
import { RadarChart } from '../RadarChart';
import { SuggestionsGeneratorContainer } from '../../suggestions/SuggestionsGenerator.container/';

const defaultData = [
    { item: 'Hard', type: 'normal', score: 30 },
    { item: 'Practical', type: 'normal', score: 50 },
    { item: 'Engaging', type: 'normal', score: 60 },
    { item: 'Easy', type: 'normal', score: 50 },
    { item: 'Passive', type: 'normal', score: 20 },
    { item: 'Empty', type: 'normal', score: 0 },
];

export const AnalyseLessonDistributionContainer = ({ lessons, ...other }) => {
    const lessonClone = lessons.map(lesson => ({
        id: lesson.id,
        title: lesson.title ? lesson.question.slice(0, 200) : '',
        type: lesson.type,
        answer: lesson.answer,
        question: lesson.question ? lesson.question.slice(0, 200) : ''
    }));

    const countLessonTypes = (lessons) => {
        let dataCurrent = [
            { item: 'Hard', type: 'current', score: 0 },
            { item: 'Practical', type: 'current', score: 0 },
            { item: 'Engaging', type: 'current', score: 0 },
            { item: 'Easy', type: 'current', score: 0 },
            { item: 'Passive', type: 'current', score: 0 },
            { item: 'Empty', type: 'current', score: 0 },
        ];

        lessonClone.forEach(lesson => {
            if (lesson.type === 'TEXT' && lesson.answer) {
                dataCurrent.find(item => item.item === 'Hard').score += 1;
                dataCurrent.find(item => item.item === 'Practical').score += 2;
                dataCurrent.find(item => item.item === 'Engaging').score += 1;

                lesson.types = ['Hard', 'Practical', 'Engaging'];
            } else if (lesson.type === 'SINGLE_SELECT' || lesson.type === 'MULTI_SELECT') {
                dataCurrent.find(item => item.item === 'Easy').score += 1;
                dataCurrent.find(item => item.item === 'Engaging').score += 1;

                lesson.types = ['Easy', 'Engaging'];
            } else if (!lesson.question) {
                dataCurrent.find(item => item.item === 'Empty').score += 1;

                lesson.types = ['Empty'];
            } else {
                dataCurrent.find(item => item.item === 'Passive').score += 1;

                lesson.types = ['Passive'];
            }
        });

        let percentData = dataCurrent.map(item => ({
            ...item,
            score: Number(((item.score / lessons.length) * 100).toFixed(0))
        }));

        return [...percentData, ...defaultData];
    };
    const data = countLessonTypes(lessons);

    const dataDifference = data.map(currentItem => {
        const defaultItem = defaultData.find(item => item.item === currentItem.item);
        return defaultItem?.type !== currentItem.type ? {
            item: currentItem.item.toLowerCase(),
            score: currentItem.score - defaultItem.score
        } : null;
    })
        .filter(item => item !== null);

    return <div className='suggestions-container'>
        <RadarChart data={data} />
        <SuggestionsGeneratorContainer
            rawdata={lessonClone}
            dataDifference={dataDifference}
            type={'suggestionsLessonsDistribution'}

            {...other} />
    </div>
};