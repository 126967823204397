import './scss/index.scss';
import React from 'react';
import { useIntl } from 'react-intl';

export const LoaderTypeComponent = ({ label, fixed, block }) => {
    const { formatMessage } = useIntl();
    const labelString = label || formatMessage({ id: 'notif_typing_loader__message' })
    const commonClassName = block ? 'typing_loader_wrapper_block' : 'typing_loader_wrapper';
    const typingLoaderWrapper = fixed ? `${commonClassName} typing_loader_fixed` : commonClassName;
    return (
        <div className={typingLoaderWrapper}>
            <div className='typing_loader inline-el'></div>
            <small className='typing_loader__message'>{labelString}</small>
        </div>
    );
};
