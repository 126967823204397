import { gql, useMutation } from "@apollo/client";

export function useUpdateTeacher() {
    const [mutateUpdateTeacher, { data, loading, error }] = useMutation(UPDATE_TEACHER);

    async function updateTeacher(teacherData) {
        const result = await mutateUpdateTeacher({
            variables: {
                teacherData: teacherData
            }
        });
        return result.data?.updateTeacher?.userId;
    }

    return {
        loading,
        error,
        teacherId: data?.updateTeacher?.userId,
        updateTeacher
    }
}

const UPDATE_TEACHER = gql`
    mutation UpdateTeacher($teacherData: UpadteTeacherInput!) {
        updateTeacher(teacherData: $teacherData) {
            userId
        }
    }
`;