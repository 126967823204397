export const LOCALES = [
    'en',
    'ru',
    'hi',
    'de',
    'zhc',
    'zht',
    'ptb',
    'pte',
    'fn',
    'ml',
    'pl',
    'es',
    'fr',
    'ar',
    'sv',
    'it',
    'he',
    'ja',
    'nl',
    'id',
    'ro',
    'ms',
    'tr',
    'fil',
    'no',
    'hr',
    'cs',
    'ko',
    'km',
    'uk',
    'zu'
];
