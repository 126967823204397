import './scss/index.scss';

import React, { useEffect, useState } from 'react';
import { Checkbox, Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { useLocalePrefix } from '../../lang';
import { GetEventsSimple } from '../../events/graphQL/queries/GetEventsSimple';
import { useGetEvents } from '../../events/hooks/useGetEvents';
import { EventListItemComponent } from '../../event/EventListItem.component/EventListItem.component'

const LIMIT = 100;
export const EventsListSelectComponent = ({ eventsLists = [], user, workspace,singleSelect, onSelect, ...other }) => {

    const { getEvents, loading: eventsLoading, } = useGetEvents(GetEventsSimple);
    const [localePrefix] = useLocalePrefix();

    const [isShowMore, setIsShowMore] = useState(false);
    const [offset, setOffset] = useState(0);
    const [events, setEvents] = useState([])
    const [selectedEvents, setSelectedEvents] = useState([]);
    const { formatMessage } = useIntl();
    const toggleEventSelection = (eventId, event) => {
        if (singleSelect) {
            onSelect(event)
        }
        setSelectedEvents(prevSelectedEvents => prevSelectedEvents.includes(eventId)
            ? prevSelectedEvents.filter(id => id !== eventId)
            : [...prevSelectedEvents, eventId]);
      
    };

    const getEventList = async () => {
        const { data } = await getEvents({
            variables: {
                filter: {
                    creatorId: user?.id,
                    workspaceId: workspace?.id
                },
                limit: LIMIT,
                offset
            }
        });

        if (data?.events) {
            let filtered = data.events
            setOffset((prev) => prev + LIMIT);
            setIsShowMore(filtered?.length === LIMIT);
            setEvents((prev) => [...prev, ...filtered]);
        }
    }

    const getlink = (event) => {
        return `https://unschooler.me${localePrefix}/online-courses/${event.alias}-${event.id}`;
    }

    return (
        <>
            {!events?.length &&
                <Button
                    type="secondary"
                    block={true}
                    onClick={() => { getEventList() }}
                >
                    {formatMessage({ id: 'add_courses_btn' })}
                </Button>
            }
            {events.map((event, i) => (
                !!event ? (
                    <div key={event?.id} className='event-with-checkbox'>
                        <Checkbox
                            onChange={() => toggleEventSelection(event.id, event)}
                            checked={selectedEvents.includes(event.id)}
                            className={selectedEvents.includes(event.id) ? 'selected-event-checkbox' : 'event-checkbox'}
                        />
                        <EventListItemComponent event={event}
                            toggleEventSelection={() => toggleEventSelection(event.id, event)} />
                        <a href={getlink(event)} target='_blank' rel="noreferrer">
                            <ExportOutlined className='btn_link' />
                        </a>
                    </div>
                ) : null
            ))}
        </>

    )
}