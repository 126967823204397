import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useAiChatResponse } from '../graphQL/useAiChatResponse';
import { getTitle } from '../../common/utils/getTitle';
import { openNotification } from '../../notification/Notification.component';
import { useGetQuiz } from "../hook/";

export const useGetTest = () => {
    const { getChatResponse, error } = useAiChatResponse();
    const { getQuizAi } = useGetQuiz();
    const { formatMessage } = useIntl();

    const getTestAi = useCallback(
        async (curriculum, titlePr, level) => {
            let countQuiz = 0;
            const settings = {
                args: {
                    domain: `${curriculum} in ${titlePr}`,
                    level: level,
                },
                type: 'quizTopics',
            };
            const showNotificationProcess = (string) => {
                openNotification('topRight', {
                    description: <p>{string}</p>,
                    duration: 5,
                    icon: false,
                });
            };
            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });
            const responseJSON = JSON.parse(response?.data?.requestChatApi);

            if (responseJSON?.choices[0]) {
                let arrayConcepts = JSON.parse(responseJSON.choices[0].message.content)?.titles?.slice(0, 10);
                if (!!arrayConcepts?.length) {
                    let htmlFromDescription = `#### Concepts:\n${arrayConcepts?.join(', \n')}\n`;
                    let title = `Test: ${titlePr ? titlePr : curriculum?.substring(0, 30)}`;

                    const promises = [];
                    for (let concept of arrayConcepts) {
                        countQuiz++;
                        showNotificationProcess(
                            `${countQuiz}/${arrayConcepts.length} ${formatMessage({ id: 'loader_generic' })}…`
                        );

                        const newQuestion = new Promise(async (resolve, reject) => {
                            try {
                                const Quiz = await getQuizAi(concept, curriculum, level);
                                resolve(Quiz);
                            } catch (error) {
                                reject(error)
                            }
                        });
                        promises.push(newQuestion);
                    }

                    const finalPromise = Promise.allSettled(promises);
                    return finalPromise.then(async (result) => {
                        const questions = [];
                        let goodResults = [];
                        result?.forEach((item, index) => {
                            if (item?.status === 'fulfilled') {
                                goodResults++;
                                showNotificationProcess(`${arrayConcepts?.[index]?.substring(0, 20)}… ${formatMessage({ id: 'loader_quiz' })}…`);
                                questions.push(item?.value)
                            }
                        })

                        if (questions?.length && questions?.length === goodResults) {
                            return { description: htmlFromDescription, title: title, questions: questions }
                        }
                    })
                }
            }

        },
        [getChatResponse, getQuizAi, formatMessage]
    );

    return { getTestAi };
};

