import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import {LoaderComponent} from '../../common';
import { ROLES } from '../../constant';

export const RoleChangeComponent = ({ memberId, newRole, dropdownVisible, handleDropdownClick, loading, handleRoleSelect }) => {
    const role = ROLES.find(role => role.permission === newRole);
    const roleLabel = role ? role.label : role.permission;

    return (
        <>
            <Dropdown
                overlay={
                    <Menu>
                        {ROLES.filter(role => role.permission !== 'Owner').map(role => (
                            <Menu.Item key={role.permission} onClick={() => handleRoleSelect(memberId, role.permission)}>
                                {role.label}
                            </Menu.Item>
                        ))}
                    </Menu>
                }
                visible={dropdownVisible === memberId}
                trigger={['click']}
                onVisibleChange={() => handleDropdownClick(memberId)}
            >
                <Button
                    type="text"
                    size='small'
                    className='margin_right '
                    onClick={() => handleDropdownClick(memberId)}>
                    {loading && <LoaderComponent type="inline"/>}
                    {!loading && roleLabel}
                </Button>
            </Dropdown>
        </>
    );
};
