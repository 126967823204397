import React from 'react';
import { AiLinkComponent } from '../AiLink.component';
import { useGetContentByUrl } from "../hook/useGetContentByUrl";
import { removeHTML } from '../../common';

export const AiLinkContainer = ({ onUpdate, setUrlName }) => {
    const { getRemoteContent } = useGetContentByUrl();

    const handleSetLink = async (url) => {
        const resp = await getRemoteContent(url);
        if (resp?.data?.gerContentByUrl) {

            setUrlName(url)
            onUpdate(removeHTML(resp.data.gerContentByUrl)?.substring(0, 50000))
        }
    }

    return <AiLinkComponent
        onSubmit={handleSetLink}
    />;
};