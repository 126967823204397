import './scss/index.scss';

import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from 'antd';
import {noop} from 'lodash';

import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {useTrackClick} from '../../common';
import translate from '../../../lang/translate';
import Icon from '../../../images/google-icon.svg'

export const AuthButtonComponent = (props) => {
    const {
        token,
        source,
        type = 'tertiary',
        size = 'middle',
        className = '',
        onCancelLogin = noop,
        onLoginUser = noop,
        onLoading = noop,
        // lang,
    } = props;
    const {loginUser, lang} = useContext(FirebaseContext);
    const trackClick = useTrackClick();
    const history = useHistory();

    const loginUserHandler = async (isRedirect = false) => {
        onLoading(true);
        trackClick(
            'click-login',
            translate('auth_button'),
            `https://unschooler.me${history.location.pathname}`
        );

        try {
            const userData = await loginUser(history, {token, source}, isRedirect);
            userData && onCancelLogin(false);
            onLoginUser(userData);
        } catch (err) {
            console.warn(err.message);
            onLoading(false);
        }
    };

    return (
        <>
            <Button
                onClick={loginUserHandler}
                className={`auth-button-component ${className}`}
                size={size}
                type={type}
                block={true}
            >
                <img className="auth-button-component-icon"
                     src={Icon}
                     alt="Google Icon"/>
                <span className="auth-button-component-label">
                    {translate('auth_button')}
                </span>
            </Button>
            <hr/>
            <Button
                onClick={() => loginUserHandler(true)}
                className={`auth-button-component ${className}`}
                size={size}
                type={type}
                block={true}
            >
                <img className="auth-button-component-icon"
                     src={Icon}
                     alt="Google Icon"/>
                <span className="auth-button-component-label">
                    {translate('auth_button')} with redirect
                </span>
            </Button>
        </>
    );
};
