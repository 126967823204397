import React from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';

import { LoaderComponent,  } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';

export const CloneEventComponent = (props) => {
    const { isLoading, error, onClone } = props;
    const { formatMessage } = useIntl();
    return (
        <div className="clone-event-component">

            <Button type="text"
                disabled={isLoading}
                onClick={onClone}>
                <span className='margin'>
                <FontAwesomeIcon icon={faClone} />
                    
                    {formatMessage({ id: 'clone_course_button' })}</span>
            </Button>
            {error && (
                <div className="error">
                    {formatMessage({ id: 'error_warning' })}
                </div>
            )}
            {isLoading && (
                <LoaderComponent type="overlay" />
            )}
        </div>
    )
}