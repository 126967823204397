import { useHistory } from 'react-router-dom';

export const useQueryParams = () => {
    const history = useHistory();
    const queryParams = new URLSearchParams(history.location.search);
    const singleMode = queryParams.get('singleMode');
    const singleLesson = queryParams.get('singleLesson');
    const isSingleMode = singleMode !== null && singleMode !== undefined;
    const isSingleLesson = singleLesson !== null && singleLesson !== undefined;

    return { isSingleMode, isSingleLesson };
}

