import classNames from 'classnames';
import { noop } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { useResolutions, SignInComponent } from '../../common';
import { useGetFilter } from '../../common/graphQL';
import { useUpdateFilter } from '../../common/graphQL/filter/useUpdateFilter';
import { FilterSubjectComponent } from '../FilterSubject.component';
import { CreateEventButtonComponent } from '../../event';

export const HeaderCourses = (props) => {
    const {
        subjects,
        selectedSubjects = [],
        setSelectedSubjects = noop,
        isTasks = false,
    } = props;

    const { isSMdown } = useResolutions();
    const { user, loadingUser } = useContext(FirebaseContext);
    const { getFilter, filter } = useGetFilter();
    // const { updateFilter, loading, filter: updatedFilter } = useUpdateFilter();
    const [active, setActive] = useState(false);
    const handleFilterClick = () => setActive((prev) => !prev);
    const { formatMessage } = useIntl();

    const onChangeSubjects = (val) => {
        setSelectedSubjects(val || []);
    };

    const filterBlockClassNames = classNames(
        'header_scrollable flex-container flex-container_between',
        {
            // '': isSMdown && active,
        }
    );

    const filterClassNames = classNames('program-filter__filter  hide-on-mobile', {
        'program-filter__filter_active': isSMdown && active,
    });

    const buttonClassNames = classNames('filter-button', {
        'filter-button_active': isSMdown && active,
    });

    // useEffect(() => {
    //     if (filter?.subjects) {
    //         setSelectedSubjects(filter?.subjects?.map((subject) => subject.id));
    //     }
    // }, [filter]);

    // useEffect(() => {
    //     if (!loadingUser && user?.id) {
    //         getFilter()
    //     }
    // }, [user?.id, loadingUser])

    // useEffect(async () => {
    //     if (user.id) {
    //         await updateFilter(null, selectedSubjects);
    //     }
    // }, [selectedSubjects]);

    return (
        <>
            {isSMdown && (
                <div
                    className={buttonClassNames}
                    onClick={handleFilterClick}
                />
            )}
            <header className={filterBlockClassNames}>
                <div className={filterClassNames}>
                    {formatMessage({ id: 'header_courses' })}
                    {/* Try 3 days for free with a <Link to="/payments">&nbsp;comfortable plan</Link> */}
                    {/* <FilterSubjectComponent
                        subjects={subjects}
                        selectedSubjects={selectedSubjects}
                        onChange={onChangeSubjects}
                    /> */}
                </div>
                {!user?.id ? (
                    <div className='btns_header '>
                        <SignInComponent />
                    </div>
                ) : null
                    // <CreateEventButtonComponent className='margin_right' user={user}/>
                }
            </header>
        </>
    );
};
