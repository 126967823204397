import { gql, useMutation } from "@apollo/client";

export function useToggleTeaching() {
    const [mutatteToggleTeaching, { data, loading, error }] = useMutation(TOGGLE_TEACHING);

    async function toggleTeaching() {
        const result =  await mutatteToggleTeaching();
        return result.data;
    }

    return {
        toggleTeaching,
        data,
        loading
    };
}

const TOGGLE_TEACHING = gql`
    mutation Mutation {
        toggleTeaching {
            id
        }
    }
`;