import React from 'react';
import {useIntl} from 'react-intl';
import {getDate} from '../util';

type Props = {
    data: Object
};

export const EventDataComponent = (props: Props) => {
    const {data} = props;
    const {formatMessage} = useIntl();

    return (
        <tr className="data-table__row">
            <td className="data-table__col">
                {data?.title}
                {!data?.isGroup && (<div className="event-data-component__personal">
                    {formatMessage({id: 'payment_request_event_data_title'})}
                </div>)}
            </td>
            <td className="data-table__col">{data.participantsLimit} {formatMessage({id: 'payment_request_event_data_persons'})}</td>
            <td className="data-table__col">{data.paymentsCount} {formatMessage({id: 'payment_request_event_data_paid'})}</td>
            <td className="data-table__col">{`$${(data.userCost * data.paymentsCount).toFixed(2)} ${formatMessage({id: 'payment_request_event_data_from'})} $${data.cost}`}</td>
            <td className="data-table__col">{getDate(data.date)}</td>
        </tr>
    )
}