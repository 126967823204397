import React from 'react';
import { useIntl } from "react-intl";
import { useGetLangs } from "../../common/graphQL";
import {IncludesComponent} from '../Includes.component/Includes.component';

export const IncludesWorkspaceContainer = (props) => {
    const { events } = props;
    const { formatMessage } = useIntl();
    const { langs } = useGetLangs();

    const includes = [
        {
            icon: 'faLayerGroup',
            text: `${formatMessage({ id: 'table_events' })}: ${events?.length}`
        },
        {
            icon: 'faClock',
            text: `${formatMessage({ id: 'includes_duration' })}: ${events?.length * 2} hours`
        },
        {
            icon: 'faBook',
            text: `${formatMessage({ id: 'includes_number_of_lessons' })}: ${events.reduce((acc, event) => event?.tasks ? acc + event?.tasks?.length : acc, 0)}`
        },
    ];

    const features = [
        // {
        //     icon: 'faGlobe',
        //     text: formatMessage({ id: 'includes_language' }, { language: languageTitle })
        // },
        {
            icon: 'faVideo',
            text: formatMessage({ id: 'includes_videoLectures' })
        },

        {
            icon: 'faBullseye',
            text: `${formatMessage({ id: 'includes_practice' })}`
        },

        {
            icon: 'faCheckCircle',
            text: `${formatMessage({ id: 'includes_assessments' })}`

        },
        {
            icon: 'faUserClock',
            text: formatMessage({ id: 'includes_pace' })
        },
        {
            icon: 'faMobileAlt',
            text: formatMessage({ id: 'includes_availability' })
        },


        { icon: 'faRobot', text: formatMessage({ id: 'includes_ai_bot' }) },
        { icon: 'faComments', text: formatMessage({ id: 'includes_ai_video' }) },

        {
            icon: 'faBookReader',
            text: formatMessage({ id: 'includes_subtitles' })
        },

    ];
    const allPrice = events.reduce((acc, event) => {
        if (event?.price && event?.price?.forStudent) {
            return acc + event?.price?.forStudent;
        }
        return acc;
    }, 0);

    return <IncludesComponent includes={includes} features={features} formatMessage={formatMessage} />;
}