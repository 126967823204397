import './scss/index.scss';
import React from 'react';
import { Table } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { ROLES } from '../../constant';
import { useIsPermit } from '../hook/useCheckPermission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useDataPermissions } from '../../common/';

export const PermissionsPreview = ({ roleKeys, workspace }) => {

  const { getRolePermissions } = useIsPermit();
  let userRole = getRolePermissions(workspace);
  const dataPermissions = useDataPermissions();

  // Transpose dataPermissions to have roles as rows and features as columns
  const transposedData = ROLES.map(role => {
    const roleLabel = role.label === userRole ? <strong>{`${role.label} — it is you`}</strong> : role.label;
    const rolePermissions = { role: roleLabel };
    dataPermissions.forEach(permission => {
      rolePermissions[permission.feature] = permission[role.permission];
    });
    return rolePermissions;
  });

  const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    ...dataPermissions.map(permission => ({
      title: permission.feature,
      dataIndex: permission.feature,
      key: permission.feature,
      render: (text) => (text ? <FontAwesomeIcon icon={faCheckCircle} /> : <CloseOutlined />),
    })),
  ];

  return (
    <div className='info-block'>
      <Table
        dataSource={transposedData}
        columns={columns}
        pagination={false}
        rowClassName="no-stroke"
      />
    </div>
  );
};