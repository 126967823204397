import {gql} from '@apollo/client';

export const CreateIntegration = gql`
    mutation CreateIntegration($input: CreateIntegrationInput!) {
        createIntegration(input: $input) {
            id
            userId
            integrations {
                id
                userId
                workspaceId
                partneroProgramId
                partneroApiKey
                userIntegrationId
            }
        }
    }
`;
