import {useLazyQuery} from '@apollo/client';
import {GetChannelId} from '../graphQL/queries/GetChannelId';

export function useGetChannelId(query = GetChannelId) {
    const [getChannelId, {data, loading, error}] = useLazyQuery(query);
    return {
        data,
        loading,
        error,
        getChannelId
    };
}