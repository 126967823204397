
import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { useLocalePrefix } from '../../lang';
import { EmbedContext } from '../../../context/embed';
import { useIntl } from 'react-intl';

export const JoinCourse = ({event, workspace}) => {
    const { isEmbed } = useContext(EmbedContext) || {};
    const URL = isEmbed || '';
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();

    return (
        <div>
            <Link className='link-btn-tertiary ant-btn ant-btn-tertiary block-el'
             to={{
                pathname: `${localePrefix}/${URL}online-courses/${event?.alias}-${event?.id}`,
                state: { workspace: workspace }
            }}>
                <span>
                    {formatMessage({ id: 'event_preenroll' })}
                </span>
            </Link>
        </div>
    );
};
