import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/remote-config';

const config_prod = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BACKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env?.REACT_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp( config_prod );

const googleProvider = new firebase.auth.GoogleAuthProvider();
// googleProvider.addScope('https://www.googleapis.com/auth/youtube.readonly');

export default firebase;
export const database = firebase.database();
export {googleProvider};
export const remoteConfig = firebase.remoteConfig();