import {useContext, useEffect} from 'react';
import {useGetIntegration} from './useGetIntegration';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {addPartnero} from '../../common';

export const useAddIntegrations = () => {
    const {workspace} = useContext(FirebaseContext);
    const {id: workspaceId} = workspace || {};
    const {getIntegration} = useGetIntegration();

    const checkIntegrations = async () => {
        const resp = await getIntegration({
            variables: {workspaceId}
        });
        const {partneroProgramId} = resp?.data?.getIntegration || {};

        if (!!partneroProgramId) {
            addPartnero(partneroProgramId);
        }
    }

    useEffect(() => {
        if (workspaceId) {
            (async () => await checkIntegrations())();
        }
    }, [workspaceId])
}
