import {notification} from 'antd';

export const openNotification = (
    placement,
    data,
) => {
    notification.info({
        message: data?.title,
        description: data.description,
        icon: data.icon || null,
        duration: data.duration,
        style: data.style,
        placement,
    });
};
