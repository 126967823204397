import { gql, useLazyQuery } from "@apollo/client";

export const useCheckUser = () => {
    const [checkUserQuery, { data, loading, error }] = useLazyQuery(CHECK_USER);

    const checkUser =  async (token) => {
        const result = await checkUserQuery({
            variables: { token }
        });
        return result.data?.checkUser;
    }

    return {
        loading,
        error,
        checkUser
    }
}

const CHECK_USER = gql`
    query CheckUser($token: String!) {
        checkUser(token: $token) {
            isUnschoolerCustomer
            userEmail
        }
    }
`;