import './scss/index.scss';

import React, {useMemo, useRef, useState} from 'react';
import {Modal, Input} from 'antd';

import {SkillsMapComponent} from '../SkillsMap.component';
import {TagsListComponent} from '../../common';

export const SelectSkillsComponent = ({
    showSkillsSelect,
    onCancelSelect,
    skills,
    user,
}) => {
    const {subjects} = skills || {};
    const timerRef = useRef();
    const [searchList, setSearchList] = useState();

    const list = useMemo(() => {
        let arr = [];
        subjects?.forEach((subj) => {
            const tags = subj?.tags?.map((t) => ({
                ...t,
                color: subj.color
            }));
            arr = arr.concat(tags);
        });

        return arr.sort((a, b) => {
            const aTitle = a?.title.toLowerCase();
            const bTitle = b?.title.toLowerCase();

            if (aTitle > bTitle) {
                return 1;
            }

            if (aTitle < bTitle) {
                return -1;
            }

            return 0;
        });
    }, [subjects]);

    const handleChange = (e) => {
        const str = e.target.value;

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            const matchedTags = list?.filter(
                (tag) =>
                    tag?.title.toLowerCase().indexOf(str.toLowerCase()) !== -1
            );
            setSearchList(
                !!str.length && !!matchedTags?.length ? matchedTags : false
            );
        }, 700);
    };

    return (
        <Modal
            visible={showSkillsSelect}
            footer={null}
            onCancel={() => onCancelSelect(false)}
            style={{minWidth: '80%'}}
        >
            <h2 className='select-skills-component__title'>
                Select skill you want to test or learn
            </h2>
            {list && (
                <Input
                    className='select-skills-component__input'
                    onChange={handleChange}
                    placeholder='Search skills'
                    allowClear={true}
                />
            )}
            <div className='wrapper_outer-section'></div>
            {!searchList && (
                <SkillsMapComponent filteredSubjects={subjects}
                                    user={user} />
            )}

            {searchList && <TagsListComponent tags={searchList} />}
        </Modal>
    );
};
