import {gql} from '@apollo/client';

export const CreatePaypalAccount = gql`
    mutation createPaypalAccount($userId: Int!, $clientId: String!, $secret: String!, $workspaceId: Int) {
        createPaypalAccount(userId: $userId, clientId: $clientId, secret: $secret, workspaceId: $workspaceId) {
            id
            workspaceIds
        }
    }
`;
