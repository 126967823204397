
import './scss/index.scss';
import React, { useState } from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import { noop } from 'lodash';

import { VideoUploader } from '../../common';

export const AddMp4 = ({
    onAddSource = noop,
    index,
    setInProgress = noop,
    inProgress,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const [showModal, setShowModal] = useState(true);
    const [cover, setCover] = useState({
        type: 'VIDEO',
        source: '',
        title: `mp4 video`,
        raw: '',
        key: "video",
        icon: "video"
    });

    const onSave = () => {
        onAddSource(cover, index);
        setShowModal(false)
    }

    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: 'tab_ai_mp4' })}
                onCancel={() => setShowModal(false)}
                confirmLoading={inProgress}
                onOk={() => onSave()}
            >
                <VideoUploader
                    setCover={setCover}
                    cover={cover}
                    index={index}
                    setInProgress={setInProgress}
                    inProgress={inProgress}
                    {...other}
                />
            </Modal>
        </>
    );
};
