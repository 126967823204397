import React, {useState} from 'react';
import {Upload, Button} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {useIntl} from 'react-intl';
import {LoaderComponent} from '../Loader.component';

const PROD_UPLOAD_URL = 'https://back.unschooler.me/file/upload';
const DEV_UPLOAD_URL = 'http://localhost:4000/file/upload';

export const UploadFileComponent = (props) => {
    const {disabled, showUploadList = false, onLoadFile} = props;
    const {formatMessage} = useIntl();
    const [loading, setLoading] = useState();

    const onRequest = async (options) => {
        const {onSuccess, onError, file} = options;

        setLoading(true);

        const fmData = new FormData();
        fmData.append("file", file);

        try {
            const resp = await fetch(PROD_UPLOAD_URL, {
                method: 'POST',
                body: fmData,
            });
            const respData = await resp.json();
            onSuccess(respData);
            onLoadFile(respData);
        } catch (err) {
            const error = new Error("Error uploading");
            onError({err});
        }
        setLoading(false);
    }

    return (
        <Upload customRequest={onRequest}
                showUploadList={showUploadList}
                fileList={[]}>
            <Button type="secondary"
                    icon={<UploadOutlined/>}
                    disabled={disabled || loading}>
                <span>
                   {formatMessage({id: 'click_upload'})}
                </span>
                {loading && (
                    <>
                        &nbsp;<LoaderComponent type="inline"/>
                    </>
                )}
            </Button>
        </Upload>
    )
}