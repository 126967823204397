import React, {useEffect} from 'react';
import {StripeWorkspaceSubscriptionComponent} from '../StripeWorkspaceSubscription.component';
import {useGetConnectedAccount, useGetStripeProduct} from '../hooks';

export const StripeWorkspaceSubscriptionContainer = ({workspace = {}}) => {
    const {id, stripeProductId} = workspace;
    const {data: dataConnectedAccount, loading: loadingStripeAccount} = useGetConnectedAccount();
    const {data: dataStripeProduct, getStripeProduct, loading: loadingStripeProduct} = useGetStripeProduct();
    const {stripeAccountIsActivated = false, stripeAccountId} = dataConnectedAccount?.getConnectedAccount || {};

    const onGetProduct = async () => getStripeProduct({
        variables: {
            input: {
                product: stripeProductId,
                workspaceId: id
            }
        }
    });

    useEffect(() => {
        if (!!stripeProductId) {
            (async () => await onGetProduct())();
        }
    }, [stripeProductId])

    if (!stripeAccountIsActivated) {
        return  null;
    }

    return <StripeWorkspaceSubscriptionComponent loading={loadingStripeAccount || loadingStripeProduct}
                                                 workspaceId={id}
                                                 stripeProductId={stripeProductId}
                                                 stripeAccountId={stripeAccountId}
                                                 stripeProduct={dataStripeProduct?.getStripeProduct}
                                                 stripeAccountData={dataConnectedAccount}/>
}