import {gql} from "@apollo/client";

export const CreateWorkspaceEventsList = gql`
    mutation CreateWorkspaceEventsList($input: EventsListInput) {
        createWorkspaceEventsList(input: $input) {
            id
            title
            workspaceId
            courses
            tags
            order
        } 
    }
`;
