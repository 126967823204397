import './scss/index.scss';
import React, { useMemo } from 'react';

import { useIsPermit } from '../../roles/hook/useCheckPermission';
import { ResultComponent } from '../Result.component';
import { checkAnswerStatus } from '../../common/utils';
import { AnswerComponent } from '../Answer.component';

export const ResultListContainer = (props) => {
    const { results, task, user, userIsAllowGenerateContent, workspace, question, ...other } = props;
    const creatorOrAdmin =
        user?.id === task.creator.id ||
        (user && user?.role?.id === 1);

    const { checkPermit } = useIsPermit()
    const inviteStudents = checkPermit(workspace, 'inviteStudents')

    const answersByQuestion = useMemo(() => {
        if (question) {
            let resultsWithAnswers = results
                .filter(result => result?.answers?.some(answ => answ.questionId === question?.id))
                .map(result => {
                    const answer = result.answers.find(answ => answ.questionId === question?.id);
                    let state = question ? checkAnswerStatus(result, question) : result?.state;
                    return {
                        ...answer,
                        result,
                        question,
                        state,
                        student: result.user,
                        notes: answer?.notes || result.description
                    }
                });

            return resultsWithAnswers;
        } else {
            return results;
        }
    }, [question, results]);

    return (creatorOrAdmin || inviteStudents) && (
        <div className='result-list-container'>
            {question ?
                answersByQuestion?.map((answer) => (
                    <div key={answer?.result?.id}>
                        <AnswerComponent
                            {...other}
                            answer={answer}
                        />
                        <hr />
                    </div>
                )) : results?.map((result) => (
                    <div key={result?.id}>
                        <ResultComponent
                            {...other}
                            result={result}
                        />
                        <hr />
                    </div>
                ))}
        </div>
    );
};
