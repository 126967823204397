import './scss/index.scss';

import React, {useState} from 'react';
import {Form, Button, Input} from 'antd';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

import {StripeCustomPaymentContainer} from '../../stripe';
import {AI_CREDITS_PRICES} from '../../../constants/stripe-prices';

import {useLocalePrefix} from '../../lang';

export const TasksByOrderComponent = (props) => {
    const {
        tasks,
        serverError,
        onGetTasks,
        onSave,
        onChangeTasks
    } = props;

    const [localePrefix] = useLocalePrefix();
    const {useForm} = Form;
    const [form] = useForm();
    const [error, setError] = useState();
    const [disabled, setDisabled] = useState();

    const getError = (subjectId, tagId) => {
        if (!subjectId && !tagId) {
            return 'Empty fields';
        }

        if (subjectId && tagId) {
            return 'Only one field should by filled';
        }

        return false;
    };

    const onFinish = (data) => {
        const {subjectId, tagId} = data;
        const error = getError(subjectId, tagId);
        setError(error);

        if (error) return;

        const filledId = subjectId ? {subjectId: Number(subjectId)} : {tagId: Number(tagId)};
        onGetTasks(filledId);
        setDisabled(true);
    };

    const handleSortEnd = ({oldIndex, newIndex}) => {
        const sorted = arrayMoveImmutable(tasks, oldIndex, newIndex);
        onChangeTasks(sorted.map((item, index) => {
            item.order = index;
            return item;
        }));
        setDisabled(false);
    };

    const SortableItem = SortableElement(({value}) => {
        const {task, order} = value;
        return (
            <div className="sort-task-component__item"
                 draggable="true">
                <a className="sort-task-component__item__title"
                   href={`${localePrefix}/tutorials/${task.id}`}
                   target="_blank"
                   rel='noreferrer'>
                    {task.title}
                </a>
                <div className="sort-task-component__item__order">
                    Order: {order}
                </div>
            </div>
        )
    });
    const SortableList = SortableContainer(({items}) => {
        return (
            <div className="sort-task-component__list">
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} index={index} value={value} />
                ))}
            </div>
        );
    });

    return (
        <div className="tasks-by-order-component page">
            <h1>Edit order of tasks</h1>
            <div className="tasks-by-order-component__top">
                <Form name='edit-order'
                      layout='vertical'
                      form={form}
                      onFinish={onFinish}>
                    <Form.Item name="subjectId"
                               label="Subject ID">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="tagId"
                               label="Tag ID">
                        <Input/>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary'
                                htmlType='submit'
                                size='large'>
                            Get tasks
                        </Button>
                    </Form.Item>
                    {error && (
                        <div className="tasks-by-order-component__error">
                            {error}
                        </div>
                    )}
                </Form>
            </div>

            {!serverError && tasks && (
                <div className="tasks-by-order-component__tasks">
                    <SortableList items={tasks}
                                  lockAxis="y"
                                  onSortEnd={handleSortEnd}/>
                    <Button type="primary"
                            size="large"
                            disabled={disabled}
                            onClick={() => {
                                setDisabled(true);
                                onSave();
                            }}>
                        Save
                    </Button>
                </div>
            )}

            {serverError && (
                <div className="tasks-by-order-component__error">
                    Something went wrong, please try again.
                </div>
            )}
            <StripeCustomPaymentContainer pricesList={AI_CREDITS_PRICES}/>
        </div>
    )
}