import {useLazyQuery} from "@apollo/client";
import {CheckQuestion} from "../graphQL/queries/CheckQuestion";

export function useCheckQuestion(query = CheckQuestion) {
    const [checkQuestion, { data, loading, error }] = useLazyQuery(query, { fetchPolicy: "no-cache" });
    return {
        checkQuestion,
        loading, error,
        isCheckQuestion: data?.checkQuestion,
    }
}
