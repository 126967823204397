import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Input, Modal } from 'antd';

export const YoutubeStorageChannelIdComponent = (props) => {
    const { onAddSource, disabled = false, size = "small", onSetChannelId, index } = props;
    const { formatMessage } = useIntl();
    const inputRef = useRef();
    const [showModal, setShowModal] = useState(true);
    const [showError, setShowError] = useState();
    const channelId = window.localStorage.getItem('channelId');

    const onSave = (val) => {
        let object = { key: "yt_channel", type: 'GIF', source: val || '', title: "My channel", icon: "video" }
        onAddSource(object, index);
        setShowModal(false)
    }

    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: "source_title_channel" })}
                onOk={() => {
                    setShowError(false)
                    const val = inputRef.current.input.value;
                    if (!val.includes("youtube")) {
                        if (val !== channelId) { onSetChannelId(val); }
                        onSave(val)
                    } else {
                        setShowError(true)
                    }
                }}
                onCancel={() => onSave('')}
            >
                <Input ref={inputRef} defaultValue={channelId} placeholder="UCSk_Z7bD3QoscHfDFHXccoQ" />
                {showError && <p className='error'>Use the ID, example: "UCSk_Z7bD3QoscHfDFHXccoQ"</p>}
                <a href="https://share.descript.com/view/WDPr9knxQYj" target="_blank" rel="noopener noreferrer" className='wrapper_content-section link-secondary'>How to find your YouTube channel ID</a>
            </Modal>
        </>
    )
}