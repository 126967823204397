import React, { useState, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { cloneDeep } from "lodash";

import { useGetLangs } from "../../common/graphQL";
import { FirebaseContext } from "../../../context/firebase/firebaseContext";
import { useGetCommonAi, useGenerateAudioChunks } from "../../ai/hook";
import { TranslateComponent } from '../Translate.component/Translate.component';
import { useEditTask } from "../hooks/useEditTask";
import { getSimpleTagIds } from '../../common/hooks/useGetTags';
import { safelyParseJSON } from '../../common/utils';
  
export const TranslateContainer = (props) => {
    const { 
        task,
        updateTask,
        userIsAllow,
        ...other } = props;

    const { getCommonAi } = useGetCommonAi();
    const { langs } = useGetLangs();
    const { lang } = useContext(FirebaseContext);
    const [isOpen, setIsOpen] = useState(false);
    const [currentlang, setCurrentlang] = useState(lang);
    const [voiceMode, setVoiceMode] = useState();
    const [error, setError] = useState();
    const [inProgressTranslate, setInProgressTranslate] = useState(false);
    const { formatMessage } = useIntl();
    const { editTask } = useEditTask();
    const { generateAudioForChunks } = useGenerateAudioChunks();
    const isAiVideo = !!task?.cover?.timestamps?.length;
    const handleLangChange = async (id) => {
        setCurrentlang(langs.find(el => el.id === id))
    };

    const handleSaveTask = async (task) => {
        let taskFromForm = {
            questions: task.questions,
            description: !!task.description
                ? task.description
                : null,
            title: task.title,
            cover: task.cover,
            alias: task?.title?.substr(0, 20),
            tagIds: getSimpleTagIds(task?.tags),
        }
        let editedTask = await editTask({
            variables: {
                taskId: task.id,
                taskData: taskFromForm,
            },
            isOwner: userIsAllow,

        });
        updateTask({ fetchPolicy: "network-only" })
    }

    const handleTranslate = async (lang) => {
        let newTask = await handleTranslateTask(task, lang);
        handleSaveTask(newTask);
    }

    const handleTranslateTask = async (taskEl, lang) => {
        setInProgressTranslate(
            formatMessage({ id: 'loader_translate' })
        );

        const shortquestions = (question) => {
            let shortedOptions = question.options?.map((option) => {
                return { text: option.text }
            })
            return {
                id: question.id,
                title: question.title,
                ...(question.question && { question: question.question }),
                ...(question.answer && { answer: question.answer }),
                ...(question.explanation && { explanation: question.explanation }),
                ...(!!shortedOptions?.length && { options: shortedOptions })
            }
        };

        let jsonTask = JSON.stringify({ title: taskEl.title?.trim(), description: taskEl.description ? taskEl.description?.trim() : '' });
        const newTask = getCommonAi(`JSON: ${jsonTask}. Return only JSON safetly prepared for JSON.parse with translated values in ${lang.title} language without other words, dont recommend google translate. All strings correctly enclose with double quotes "", dont miss } and semicolons, escape special characters.`, 'translation');

        let questions = [];
        taskEl?.questions?.forEach((question) => {
            let jsonQuestion = JSON.stringify(shortquestions(question));
            questions.push(getCommonAi(`JSON: \`\`\`${jsonQuestion}\`\`\`. Return only JSON safetly prepared for JSON.parse with translated values in ${lang.title} language without other words, dont recommend google translate.  All strings correctly enclose with double quotes "", dont miss } and semicolons, escape special characters.`, 'translation'))
        })

        //timestamps 
        let textToAudioTimestampt = []
        let timestamps = isAiVideo && taskEl?.cover?.timestamps?.map(({ timestamp, title, description, id }) => {
            let parsedDescription = JSON.parse(description);
            const { audio, images, url, ...restOfDescription } = parsedDescription;
            let d = JSON.stringify(restOfDescription)
            textToAudioTimestampt.push({ description: d, id: id })
            return {
                title,
                description: d,
                id,
            };
        });
        const newVideo = isAiVideo ? getCommonAi(`JSON: ${JSON.stringify({ timestamps: timestamps })}. Return only JSON, accurately translate each value in ${lang.title} language, including title. All strings correctly enclose with double quotes "".`, 'translation') : null;

        // translation
        const promises = [newTask, ...questions];
        if (isAiVideo) {
            promises.push(newVideo);
        }
        const awaitedResults = await Promise.allSettled(promises);
        const results = awaitedResults.map((promise) => promise?.value);
        let taskNormalised = cloneDeep(taskEl);

        //parseTask
        const taskResult = safelyParseJSON(results[0]);
        if (taskResult) {
            taskNormalised.description = taskResult.description;
            taskNormalised.title = taskResult.title;
        }

        //parse Questions
        let questionClone = cloneDeep(taskEl.questions);
        questionClone?.forEach((el, i) => {
            try {
                let q = safelyParseJSON(results[i + 1]);
                let options = el.options?.map((option, i) => {
                    return { ...option, text: q.options[i].text }
                })

                el.title = q.title || el.title;
                el.question = q.question || el.question;
                el.answer = q.answer || el.answer;
                el.explainer = q.explainer || el.explainer;
                el.options = options || el.options;
            }
            catch (err) {
                setInProgressTranslate('');
                console.log(err, 'err')
            }
        })

        // parse video
        if (isAiVideo && newVideo) {
            const videoResult = safelyParseJSON(results[results.length - 1]);
            let audios = videoResult?.timestamps?.map(timestamp => { return { description: JSON.parse(timestamp.description).description, id: timestamp.id } })
            let updatedTimestamps = await generateAudioForChunks(audios, voiceMode, setError);

            taskNormalised.cover.timestamps = videoResult?.timestamps?.map(translatedTimestamp => {
                const originalTimestamp = taskEl?.cover?.timestamps.find(t => t.id === translatedTimestamp.id);
                if (originalTimestamp) {
                    const { description: originalDescription } = originalTimestamp;
                    const parsedOriginalDescription = JSON.parse(originalDescription);
                    const { images, url } = parsedOriginalDescription;
                    const updatedAudio = updatedTimestamps.find(t => t.id === translatedTimestamp.id)?.audio;
                    const audio = updatedAudio || parsedOriginalDescription.audio;
                    const mergedDescription = { ...JSON.parse(translatedTimestamp.description), audio, images, url };
                    return { ...translatedTimestamp, description: JSON.stringify(mergedDescription), timestamp: originalTimestamp.timestamp };
                }
                return translatedTimestamp;
            });

        }
        taskNormalised.questions = questionClone;
        setInProgressTranslate('');
        return taskNormalised;
    }

    return (
        <TranslateComponent
            handleLangChange={handleLangChange}
            handleTranslate={handleTranslate}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            currentlang={currentlang}
            langs={langs}
            inProgressTranslate={inProgressTranslate}
            task={task}
            setVoiceMode={isAiVideo ? setVoiceMode : null}
            voiceMode={voiceMode}
            userIsAllow={userIsAllow}
            {...other}
        />

    )
}
