export const checkAnswer = (answer, question) => {
    if (answer && question) {
        if (question?.type === "SINGLE_SELECT" || question?.type === "MULTI_SELECT") {
            return answer?.options[0]?.isCorrect ? 'CORRECT' : 'INCORRECT';

        } else if (question?.type === "TEXT" || question?.type === 'DEFAULT') {
            if (answer?.answer?.includes("1/10") || answer?.answer?.includes("2/10")) {
                return 'INCORRECT';
            } else {
                return 'CORRECT';
            }
        }
    }
}

export const checkAnswerStatus = (result, question) => {
    const answer = result?.answers?.find((el) => el?.questionId === question?.id);
    if (answer) {
        return checkAnswer(answer, question)
    }
}

export const checkAnswerIcon = (answer) => {
    if (answer.question.type === "SINGLE_SELECT" || answer.question.type === "MULTI_SELECT") {
        return answer?.options[0]?.isCorrect ? '✅' : '❌';
    } else if (answer.question.type === "TEXT") {
        if (answer?.answer?.includes("1/10") || answer?.answer?.includes("2/10")) {
            return '❌';
        } else {
            return '✅';

        }
    }
}
export const checkAnswerIconWithString = (answer, formatMessage) => {
    if (answer.question.type === "SINGLE_SELECT" || answer.question.type === "MULTI_SELECT") {
        return answer?.options[0]?.isCorrect ? `✅ ${formatMessage({ id: 'result_right_answer' })}` : `❌ ${formatMessage({ id: 'result_wrong_answer' })}`;
    } else if (answer.question.type === "TEXT") {
        if (answer?.answer?.includes("1/10") || answer?.answer?.includes("2/10")) {
            return `❌ ${formatMessage({ id: 'result_wrong_answer' })}`;
        } else {
            return `✅ ${formatMessage({ id: 'result_right_answer' })}`;

        }
    } else if (answer.question.type === "DEFAULT") {
        return `✅ ${formatMessage({ id: 'result_right_answer' })}`;
    }
}
export const checkTask = (result) => {
    if (result === "INCORRECT") {
        return '❌';
    } else if (result === "CORRECT") {
        return '✅';
    }
}
export const checkTaskIconWithString = (result, formatMessage) => {
    if (result === "INCORRECT") {
        return `❌ ${formatMessage({ id: 'result_wrong_answer' })}`;
    } else if (result === "CORRECT") {
        return `✅ ${formatMessage({ id: 'result_right_answer' })}`;
    }
}