import { useCallback } from 'react';
import {gql, useLazyQuery} from '@apollo/client';

export function useGetGoals(options = {}, query = GET_GOAL) {
    const [queryPreference, {data, loading, error}] = useLazyQuery(query, options);

    const getGoals = useCallback(async (langId) => {
        const result = await queryPreference({
            variables: {
                langId
            }
        });
        return result.data?.goals;
    }, [queryPreference]);

    return {
        getGoals,
        loading,
        error,
        goals: data?.goals
    };
}

const GET_GOAL = gql`
    query Goals($langId: Int) {
        goals(langId: $langId) {
            id
            order
            title
        }
    }
`;