import './scss/index.scss';
import classnames from 'classnames';

import { GraphComponent } from '../../graph/graph.component';
import { SubscriptionButtonComponent } from '../../subscription';
import { YoutubeContainer } from '../../google';

export const SkillsTreeComponent = ({
    skillsTree,
    handleSaveUser,
    user,
    ...other
}) => {
    const GraphName = classnames('', {
        // paywall_blur: hideSkillsMap,
    });

    const isParticipant = !!user.subscriptionUser;
    return (
        <>
            <div className='cy-graph'>
                {skillsTree?.nodes && skillsTree?.nodes.length > 0 && (
                    <div className={GraphName}>
                        <GraphComponent
                            {...other}
                            user={user}
                            skillsTree={skillsTree}
                        />
                    </div>
                )
                }
            </div>
        </>
    );
};
