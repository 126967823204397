import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useGetTagsPrediction } from '../../task/hooks/useGetTagsPrediction';
import { TagSelectComponent } from '../TagSelect.component';
import { getConfigByKey, useIsAllow } from '../';

export const TagSelectContainer = ({
    initialTagsProp,
    title,
    ...other
}) => {
    const { tags,
        tagsPrediction,
        loading: loadingGetTagsPrediction,
    } = useGetTagsPrediction();
    const [initialTags, setInitialTags] = useState(initialTagsProp);
    const [maxTagsLimit, setMaxTagsLimit] = useState(5);
    const tagsList = useMemo(() => tags || initialTags, [tags, initialTags]);
    const useIsAllowd = useIsAllow();
    const isEditAllow = useIsAllowd("PRO");

    const getMaxTagLimit = useCallback(async () => {
        const data = await getConfigByKey('task_max_tags_count');
        setMaxTagsLimit(+data?._value);
    }, [getConfigByKey]);

    useEffect(() => {
        setInitialTags(initialTagsProp);
        // if (!initialTagsProp) {
        //     tagsPrediction({
        //         variables: {
        //             filter: {
        //                 title: title,
        //             },
        //         },
        //     });
        //     setInitialTags()
        // }

    }, [initialTagsProp, title])

    return (
        <TagSelectComponent
            tagsPrediction={tagsPrediction}
            loadingGetTagsPrediction={loadingGetTagsPrediction}
            isEditAllow={isEditAllow}
            tagsList={tagsList}
            maxTagsLimit={maxTagsLimit}
            isRequired={false}
            {...other}
        />
    );
};
