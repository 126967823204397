import React, { useEffect, useState, useContext } from 'react';
import {useHistory} from 'react-router-dom';
import { CertificateFormComponent } from '../CertificateForm.component';
import { LoaderComponent } from '../../common';
import { useGetCertificate, useCreateCertificate, useEditCertificate, useRemoveCertificate } from '../hook';
import { GetCertificateSimple } from '../graphQL/queries/GetCertificateSimple'
import { FirebaseContext } from '../../../context/firebase/firebaseContext';

let CERT = null;

export const CertificateFormContainer = (props) => {
    const { id, eventId, event} = props || {};
    const history = useHistory();
    const { user } = useContext(FirebaseContext);
    const { getCertificate, loading: getLoading } = useGetCertificate(GetCertificateSimple);
    const { createCertificate, loading: createLoading } = useCreateCertificate();
    const { editCertificate, loading: editLoading } = useEditCertificate();
    const { removeCertificate, loading: removeLoading } = useRemoveCertificate();
    const [isLoading, setIsloading] = useState();
    const [certificate, setSertificate] = useState();
    const [selectedImage, setSelectedImage] = useState(certificate?.icon || '');

    const handleSend = async (data, isExit) => {
        const { id: creatorId } = user || {};

        if (CERT) {
            CERT = null;

            if (!!certificate?.id || data?.id) {
                const {id, ...certData} = data || {};
                const resp = await editCertificate({
                    variables: {
                        input: { ...certData, eventId, creatorId, type: 'CERT' },
                        id: certificate?.id || data?.id
                    }
                });
                await setSertificate(resp?.data?.editCertificate);
            } else {
                const resp = await createCertificate({
                    variables: {
                        input: {
                            ...data,
                            type: 'CERT',
                            eventId,
                            creatorId
                        }
                    }
                });
                await setSertificate(resp?.data?.createCertificate);
            }
        }

        if (isExit) {
            history.goBack()
        }
    }
    const getData = async () => {
        CERT = null;
        await setIsloading(true);
        const resp = await getCertificate({
            variables: {
                ...(id && { id }),
                ...(eventId && { eventId })
            },
            fetchPolicy: 'network-only'
        });

        await setSertificate(resp?.data?.getCertificate);
        await setIsloading(false);
    };
    const handleRemove = async () => {
        const resp = await removeCertificate({
            variables: {
                id: certificate.id
            }
        });
        await setSertificate(null);
        await getData();
    };

    const onSetSertificate = async (data) => {
        CERT = data;
        await setSertificate(data);
    }

    useEffect(() => {
        if (eventId || id) {
            (async () => {
                await getData();
            })()
        }
    }, [id, eventId]);

    useEffect(() => {
        return () => {
            if (CERT) {
                (async () => {
                    await handleSend(CERT);
                })()
            }
        };
    }, [])

    if (isLoading) {
        return <LoaderComponent />
    }

    return (
        <CertificateFormComponent certificate={certificate}
                                  loading={getLoading || createLoading || editLoading}
                                  removeLoading={removeLoading}
                                  onRemove={handleRemove}
                                  onSend={handleSend}
                                  setSertificate={onSetSertificate}
                                  setSelectedImage={setSelectedImage}
                                  selectedImage={selectedImage}
                                  event={event}/>
    )
}