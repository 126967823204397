import React, {useState, useContext, useMemo} from 'react';
import {StripeCustomPaymentComponent} from '../StripeCustomPayment.component';
import {useCreateStripeSession} from '../hooks';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

/**
 * @module Stripe Custom Payment
 * @param {[{
 *      price: number,
 *      currency: string,
 *      label: string,
 *      priceId: string, (Stripe price id)
 *      paymentType: string (aiCredits - for credits)
 * }]} pricesList - list of Stripe prices
 *
 * @kind Container
 *
 * @description
 * Makes it possible to insert payment buttons for certain price lists anywhere.
 */

export const StripeCustomPaymentContainer = ({pricesList = []}) => {
    const {createStripeSession, loading, error} = useCreateStripeSession();
    const {user, loginUser} = useContext(FirebaseContext);
    const {id: userId} = user || {};
    const [showLogin, setShowLogin] = useState();
    const [currentPrice, setCurrentPrice] = useState();

    const loadingPaymentIndex = useMemo(() => {
        if (!loading) {
            return null;
        }
        return pricesList.findIndex((item) => item?.priceId === currentPrice?.priceId);
    }, [currentPrice, loading])

    const handlePay = async (price) => {
        await setCurrentPrice(price);
        if (!userId) {
            setShowLogin(true);
            return;
        }
        const url = window.location.href;
        const resp = await createStripeSession({
            variables: {
                priceId: price.priceId,
                url,
                paymentType: price.paymentType
            }
        });

        const checkoutUrl = resp?.data?.createStripeSession?.url;
        if (checkoutUrl) {
            window.location.href = checkoutUrl;
        }
    };

    const handleLogin = async () => await handlePay(currentPrice);
    const handleSetShowLogin = () => setShowLogin(false);

    return <StripeCustomPaymentComponent pricesList={pricesList}
                                         loading={loading}
                                         loadingPaymentIndex={loadingPaymentIndex}
                                         showLogin={showLogin}
                                         onPay={handlePay}
                                         onLoginUser={handleLogin}
                                         onSetShowLogin={handleSetShowLogin}/>
}