import React, {useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';

import {LoaderComponent} from '../Components/common';
import {useLocalePrefix} from '../Components/lang';

export const SubscriptionUser = ({user}) => {
    const {id} = user || {};
    const {search} = useLocation();
    const history = useHistory();
    const [localePrefix] = useLocalePrefix();
    const urlParams = new URLSearchParams(search);
    const canceled = urlParams.get('canceled');
    const success = urlParams.get('success');

    useEffect(() => {
        if (success && id) {
            history.push(`${localePrefix}/profile/${id}`);
        }
    }, [id, success]);

    return (
        <div className="subscription-user-page page">
            <h1>Premium Subscription</h1>
            {canceled && (
                <div className="subscription-user-page__text">
                    You have canceled your Premium Subscription
                </div>
            )}
            {success && <LoaderComponent type="full"/>}
        </div>
    )
}