import React from 'react';
import {Button} from 'antd';
import {useTrackClick} from '../../common';
import {useIntl} from 'react-intl';

export const ApiTestButtonComponent = ({
    onRequest,
    onLoading,
    pref,
    size = 'large',
    type = 'secondary',
    className = 'skills_action',
}) => {
    const trackClick = useTrackClick();
    const {formatMessage} = useIntl();

    const handleClick = () => {
        if (onLoading) {
            onLoading(true);
        }

        if (onRequest) {
            onRequest();
        }
        trackClick(
            'test-skills',
            'test-skills',
            `https://unschooler.me/skills/`
        );
    };

    return (
        <Button
            className={className}
            type={type}
            size={size}
            onClick={handleClick}
        >
            <span>
                {pref?.tags?.length > 1
                    ? formatMessage({
                          id: 'skills_update_interest',
                      })
                    : formatMessage({
                        id: 'skills_test_interest',
                    })}
            </span>
        </Button>
    );
};


