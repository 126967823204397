import './scss/index.scss'
import React, { useContext } from 'react';
import { useLocalePrefix } from '../../lang';
import { useIntl } from "react-intl";
import { Button } from 'antd';

import { useQueryParams, SharingButton } from '../../common'
import { EmbedContext } from '../../../context/embed';
import { JoinCourse } from '../../event/JoinCourse.component/JoinCourse.component';
import { ProfileColumn } from '../../profile/ProfileColumn/ProfileColumn';
import { CertificateContainer, CertificateToPrint } from '../../certificates';
import { Loader } from '../../Loader';

export const UserCertificateComponent = ({ certificate, workspace, student, user }) => {
    const { isSingleMode, isSingleLesson } = useQueryParams();

    if (!certificate) {
        return <Loader />;
    }

    return (
        <div className='certificate-page'>
            <div className="task-article-component ">
                {(!isSingleMode && !isSingleLesson) &&
                    <div className="flex-right-column">
                        <div className='align-center'>
                            <ProfileColumn user={certificate?.student} />
                            <hr />
                            <SharingButton classes="" link={window.location.href} withLabel={true} />
                            <hr />
                            <CertificateToPrint
                                workspace={workspace}
                                user={user}
                                certificate={{
                                    ...certificate?.certificate,
                                    active: true,
                                    userCertificateId: certificate?.id,
                                    isAppliedForCurrentUser: true,
                                    student: {
                                        id: certificate?.userId,
                                        name: certificate?.userName,
                                        picture: certificate?.user?.picture
                                    },
                                }}
                            />
                        </div>
                    </div>
                }
                <div className="flex-column">
                    <section className="certificate-container">
                        <CertificateContainer
                            certificate={{
                                ...certificate?.certificate,
                                active: true,
                                userCertificateId: certificate?.id,
                                isAppliedForCurrentUser: true,
                                student: {
                                    id: certificate?.userId,
                                    name: certificate?.userName,
                                    picture: certificate?.user?.picture
                                },
                            }}
                        />
                        <div className='wrapper_outer-section'>
                            <JoinCourse event={certificate?.certificate?.event} workspace={workspace} />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};
