// import {fetchData} from '../../../context/firebase/firebaseRequests';
import {getCacheManager} from './getCacheManager';
import {getConvertedObjectToArray} from '../../common/utils';

const CACHE_KEY = 'programs';
const {hasCache, getCache, setCache} = getCacheManager();

export const getPrograms = async () => {
    const isCache = hasCache(CACHE_KEY);
    // if (!isCache) {
    //     const dbData = await fetchData(CACHE_KEY);
    //     setCache(CACHE_KEY, getConvertedObjectToArray(dbData.val()));
    // }
    return getCache(CACHE_KEY);
}