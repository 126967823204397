import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import {FirebaseContext} from '../context/firebase/firebaseContext';
import {LoaderComponent} from '../Components/common';
import {useUpdateToken} from '../Components/google/hooks';

export const CallbackCalendar = () => {
    const { user } = useContext(FirebaseContext);
    const updateToken = useUpdateToken();
    const history = useHistory();
    const {id} = user

    useEffect(() => {
        if (id) {
            (async () => {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const code = urlSearchParams.get('code');
                const state = urlSearchParams.get('state');
                if(code) {
                    const res = await updateToken({
                        variables: {
                            code: code
                        }
                    });
                    history.replace(state, {isAddCalendar: true});
                }
            })();
        }
    }, [id]);

    return (
        <LoaderComponent type="overlay"/>
    );
};



