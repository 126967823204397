import React from 'react';
import { GRANULARITY_LABELS } from '../constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDroplet, faClock, faSquareCheck, faLockOpen } from '@fortawesome/free-solid-svg-icons';

export const DripExplanation = ({ formatMessage, event, type, classText }) => {
    const selectedDrip = event?.drip;
    const selectedDripFrequency = event?.dripFrequency;
    const selectedDripGranularity = event?.dripGranularity;

    switch (selectedDrip) {
        case 'JOINDATE':
            const granLabel = !!selectedDripGranularity ? formatMessage({ id: `drip_granularity_${selectedDripGranularity?.toLowerCase()}` }) : '';
            switch (type) {
                case 'icon':
                    return <FontAwesomeIcon icon={faDroplet} />;
                case 'text':
                    return formatMessage({ id: 'drip_explanation_joindate' }, { granularity: granLabel, days: selectedDripFrequency });
                default:
                    return <div className={classText}><FontAwesomeIcon icon={faDroplet} size="lg" /> {formatMessage({ id: 'drip_explanation_joindate' }, { granularity: granLabel, days: selectedDripFrequency })}</div>;
            }

        case 'ANSWER':
            switch (type) {
                case 'icon':
                    return <FontAwesomeIcon icon={faSquareCheck} />;
                case 'text':
                    return formatMessage({ id: 'drip_explanation_answer' });
                default:
                    return <div className={classText}><FontAwesomeIcon icon={faSquareCheck} size="lg" /> {formatMessage({ id: 'drip_explanation_answer' })}</div>;
            }

        default:
            switch (type) {
                case 'icon':
                    return <FontAwesomeIcon icon={faLockOpen} />;
                case 'text':
                    return formatMessage({ id: 'drip_explanation_default' });
                default:

                    return <div className={classText}><FontAwesomeIcon icon={faLockOpen} size="lg" /> {formatMessage({ id: 'drip_explanation_default' })}</div>;
            }
    }
};
