import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {CheckCustomDomains} from '../graphQL/mutation/CheckCustomDomains';

export const useCheckDomains = (mutation = CheckCustomDomains) => {
    const [checkDomains, { data, error, loading }] = useMutation(mutation);

    const checkCustomDomains = useCallback(async (domains) => {
            const resp = await checkDomains({
            variables: {
                domains
            }
        });
        return resp;
    }, [checkDomains])

    return {
        data,
        error, loading,
        checkCustomDomains
    };
}
