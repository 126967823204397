import {useLazyQuery, gql} from '@apollo/client';
import {useCallback} from 'react';
import {useOwnerData} from '../../common';

export const useAiDallie = (query = AI_RESPONSE) => {
    const [getDallieResp, { data: jsonData, loading, error }] = useLazyQuery(query, {fetchPolicy: "no-cache"});
    const getOwnerData =  useOwnerData();

    let data;
    try {
        data = jsonData?.requestDallie;
    } catch (e) {
        console.warn(e);
    }

    const getDallieResponse = useCallback(async (data) => {
        const ownerData = await getOwnerData();
        const input = data?.variables?.input;
        const owner = ownerData?.ownerId ? ownerData : {ownerId: ownerData?.customerId};

        const mergedData = {
            variables: {
                input: {...input, owner}
            }
        }

        return getDallieResp(mergedData);
    }, [getDallieResp, getOwnerData]);

    return {
        getDallieResponse,
        loading, error,
        data
    }
};

const AI_RESPONSE = gql`
    query Query($input: dallieApiInput!) {
        requestDallie(input: $input)
    }
`;

