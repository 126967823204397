export const safelyParseJSON = (jsonString) => {
    try {
        if (jsonString.includes('```json')) {
            return JSON.parse(jsonString.replace('```json', '').replace(/```$/, ''));
        }
        if (jsonString.includes('}')) {
            return JSON.parse(jsonString);
        } else {
            return JSON.parse(jsonString + '"}');
        }
   
    } catch (e) {
        console.error("Error parsing JSON string:", e);
        return null;
    }
}
