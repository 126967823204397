import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ReactMarkdownComponent } from '../Components/task/ReactMarkdown.component/ReactMarkdown.component';
import { PermanentTerms, DefaultTerms } from '../Components/footer'
import { MetaComponent, TitleComponent } from '../Components/common';

export const TermsStudent = ({ workspace = {} }) => {
    const { formatMessage } = useIntl();
    const { customTerms } = workspace;

    const metaMemo = useMemo(() => {
        const title = formatMessage({ id: 'meta_title_terms' });
        const description = formatMessage({ id: 'meta_description_terms' });

        const meta = {
            meta_title: title,
            meta_description: description,
        };
        const og = {
            title: title,
            description: description,
        };

        const twitter = {
            tw_title: title,
            tw_description: description,
        };

        return { meta, og, twitter };
    }, []);

    return (
        <>
            <MetaComponent
                meta={metaMemo.meta}
                og={metaMemo.og}
                twitter={metaMemo.twitter}
            />

            <div className='page'>
                <TitleComponent title='Terms' back={true} />
                <div className='page-content'>
                    <div className='page-content'>
                        {customTerms ? (
                            <ReactMarkdownComponent children={customTerms} />
                        ) :
                            <DefaultTerms />
                        }
                        <div>All rights reserved, ©{workspace?.id ? workspace?.copyright : "Unschooler"}</div>
                        <div>{workspace?.contactEmail}</div>
                        <div>{workspace?.address}</div>
                        <hr />
                        <PermanentTerms />
                    </div>
                </div>
            </div>
        </>
    );
};
