import { gql } from "@apollo/client";

export const GetVideoList = gql`
  query getVideoList($q: String, $id: Int, $isSubject: Boolean, $notSorted: Boolean, $maxResults: Int, $generateTasks: Boolean, $channelId: String) {
      getVideoList(q: $q, id: $id, isSubject: $isSubject, notSorted: $notSorted, maxResults: $maxResults, generateTasks: $generateTasks, channelId: $channelId) {
          items {
              id
              title
              description
              publishTime,
              thumbnail
          }
      }
  }
`;