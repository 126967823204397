import { useCallback } from "react";
import { useAiChatResponse } from '../graphQL/useAiChatResponse';
import { useGetVideoListByString } from "../../youtube/hooks";
import { useGetRelevantContent } from "./useGetRelevantContent";
import { useGetImagesByString } from "../../search";
import {
    useGetImagesDalle, useGetCommonAi
} from "../../ai/hook";


const useGetMostRelevantImage = (items, queryString) => {
    const { getChatResponse, error } = useAiChatResponse();
    const { getAiImage } = useGetImagesDalle();

    const getAiResponse = useCallback(
        async (imagesUrls, queryString) => {
            const settings = {
                args: {
                    topic: queryString,
                    img_1: imagesUrls[0] || '',
                    img_2: imagesUrls[1] || '',
                    img_3: imagesUrls[2] || '',
                    img_4: imagesUrls[3] || '',
                    img_5: imagesUrls[4] || '',
                },
                type: 'visionRelevantImage',
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });
            const responseJSON = response?.data
                ? JSON.parse(response?.data?.requestChatApi)
                : null;
            if (responseJSON?.choices[0]) {
                let responseTrimmed = responseJSON.choices[0].message.content.trim();

                let substring = responseTrimmed?.substring(responseTrimmed.indexOf("{"), responseTrimmed.lastIndexOf("}") + 1);
                let imagesArray = JSON.parse(substring).images;

                let normalisedArray = imagesArray.map(imageObject => {
                    let url = imagesUrls[imageObject.number - 1];
                    return (
                        { description: imageObject.prompt, url: url }
                    )
                })

                return normalisedArray;
            }
        },

        [getChatResponse]
    );

    return { getAiResponse };
};
export const useDrawImage = () => {
    const { getChatResponse, error } = useAiChatResponse();

    const getDiagram = useCallback(
        async (queryString) => {
            const settings = {
                args: {
                    request: queryString,
                },
                type: 'visionCode',
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });
            const responseJSON = response?.data
                ? JSON.parse(response?.data?.requestChatApi)
                : null;
            if (responseJSON?.choices[0]) {
                let responseTrimmed = responseJSON.choices[0].message.content;
                return responseTrimmed;
            }
        },

        [getChatResponse]
    );

    return { getDiagram };
};

export const useGetImage = () => {
    const { getVideoList } = useGetVideoListByString();
    const { getAiResponse } = useGetMostRelevantImage();
    const { getImagesList } = useGetImagesByString();
    // const { getAiDraw } = useDrawImage();
    const getImage = useCallback(
        async (queryString, draw) => {
            if (!!queryString) {
                let imageJSON = await getImagesList({
                    variables: { q: queryString + `+diagram+OR+infographic` },
                });

                let imageArray = imageJSON?.data?.getSearch?.items || [];

                const imagesUrls = [];
                imageArray?.forEach(img => {
                    if ((img.link.includes("png") || img.link.includes("jpg") || img.link.includes("jpeg")) && !img.link.includes("researchgate")
                    ) {
                        imagesUrls.push(img.link.replace('www.', ''));
                    } else {
                    }
                });

                if (!!imageArray?.length) {
                    // try {
                    //     let ev = await getAiResponse(imagesUrls, queryString);
                    //     return ev || [{ description: imageArray[0].title, url: imageArray[0].link }];
                    // } catch (error) {
                        return [{ description: imageArray[0].title, url: imageArray[0].link }];
                    // }

                    // if (draw) {
                    //     let ev = await getAiDraw(imagesUrls, queryString);
                    //     return ev;
                    // } else {
                    // }
                } else {
                    return {};
                }
            }
        },
        [getVideoList, getAiResponse]
    );

    return { getImage };
};
