import React, {useRef, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Button, Input} from 'antd';
import {LoaderComponent} from '../Components/common';
import {useGetUserByResetToken, useChangePassword} from '../Components/common/graphQL';
import { useIntl } from 'react-intl';

export const ResetPassword = (props) => {
    const {user} = props;
    const {getUserByResetToken, data, loading, error: userError} = useGetUserByResetToken();
    const {changePassword, data: changePasswordData, loading: changePasswordLoading} = useChangePassword();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const errorsRef = useRef();
    const [passwordErrors, setPasswordErrors] = useState();
    const {search} = useLocation();
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('resetToken');
    const { formatMessage } = useIntl();

    const isPasswordChanged = useMemo(() => (
        !!changePasswordData?.changeUserPassword
    ), [changePasswordData]);

    const {id, email, uid, error} = useMemo(() => {
        let result = {id: null, email: null, error: null};
        const {id} = user;
        if (!id && userError) {
            result.error = true;
        }
        if (data?.getUserByResetToken) {
            result = {...data?.getUserByResetToken}
        }
        return result;
    }, [data, userError, user]);

    const getUserData = async () => {
        if (!!token) {
            await getUserByResetToken({
                variables: {token}
            });
        }
    };
    const onSavePassword = async () => {
        const password = passwordRef.current.input.value;
        const confirmPassword = confirmPasswordRef.current.input.value;

        if (password.length < 6 || password !== confirmPassword) {
            const errors = {
                password: password.length < 6 ? formatMessage({id: 'password_requirements_warning'}) : false,
                confirmPassword: confirmPassword !== password ? formatMessage({id: 'password_mutch_requirments'}) : false
            };
            errorsRef.current = errors;
            setPasswordErrors(errors);
        } else {
            errorsRef.current = null;
            setPasswordErrors(false);
        }

        if (!errorsRef.current) {
            const resp = await changePassword({
                variables: {
                    uid,
                    password,
                    token
                }
            });
        }
    }

    useEffect(() => {
        (async () => await getUserData())();
    }, []);

    return (
        <div className="reset-password-page">
            {(loading || changePasswordLoading) && <LoaderComponent type="overlay"/>}

            {error && (
                <div className="error-message">
                    {formatMessage({id: 'smth_wrong_alarm'})}
                </div>
            )}

            {uid && email && (
                <div className="page">
                    {isPasswordChanged && (<h2>
                        {formatMessage({id: 'Change_password_success'})}
                    </h2>)}
                    {!isPasswordChanged && (
                        <div>
                            <h2>{formatMessage({id: 'rst_password_button'})}{email}</h2>
                            <div className="ant-col ant-form-item-label">
                                <label>{formatMessage({id: 'new_password_label'})}</label>
                            </div>
                            <div className="ant-row ant-form-item">
                                <Input ref={passwordRef}
                                       type="password"
                                       style={{
                                           maxWidth: '300px'
                                       }}/>
                            </div>
                            {passwordErrors?.password && (
                                <div className="error">
                                    {passwordErrors?.password}
                                </div>
                            )}
                            <div className="ant-col ant-form-item-label">
                                <label>{formatMessage({id: 'ask_for_password_repeat'})}</label>
                            </div>
                            <div className="ant-row ant-form-item">
                                <Input ref={confirmPasswordRef}
                                       type="password"
                                       style={{
                                           maxWidth: '300px'
                                       }}/>
                            </div>
                            {passwordErrors?.confirmPassword && (
                                <div className="error">
                                    {passwordErrors?.confirmPassword}
                                </div>
                            )}
                            <Button type="primary"
                                    onClick={onSavePassword}>
                                {formatMessage({id: 'change_password_button'})}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}