import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
    LoaderComponent,
    MetaComponent,
    Svg,
    LoginContainer,
    useIsAllow,
} from '../Components/common';
import { YoutubeContainer } from '../Components/google';
import { SubscriptionButtonComponent } from '../Components/subscription';
import { SkillsTreeContainer } from '../Components/skillsTree';
import { useGetSpecs, useMapSkills } from '../Components/common/graphQL';
import { HeaderSkillsContainer } from '../Components/headers';
import { FirebaseContext } from '../context/firebase/firebaseContext';
import { useLocalePrefix } from '../Components/lang';

const description =
    'Innovative carrer guidance for teens based on their social media preferences, with short project-based online-classes that help to find their passion';
const title = 'Early access to your future profession';
const url = 'https://unschooler.me/skills/';
const image =
    'https://cdn.unschooler.me/web_app/sharing_pictures/cover_skills.jpeg';

export const SkillsTree = (props) => {
    const { user, loadingUser } = useContext(FirebaseContext);
    const { formatMessage } = useIntl();
    const { specs } = useGetSpecs();
    const { mapSkills, getMapSkills } = useMapSkills();
    const { id } = user || {};
    const history = useHistory();
    const [localePrefix] = useLocalePrefix();

    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd("START");
    
    const meta = {
        meta_title: title,
        meta_description: description,
    };
    const og = {
        title: title,
        image: image,
        description: description,
        url: url,
    };

    const twitter = {
        tw_title: title,
        tw_url: url,
        tw_description: description,
    };

    const [selectedSpec, setSelectedBySpec] = useState();
    const [skillsRendered, setSkillsRendered] = useState(false);
    const [showLogin, setShowLogin] = useState();

    const onSpecChange = (specialization) => {
        if (specialization !== 'all') {
            setSelectedBySpec(specialization);
        } else {
            setSelectedBySpec(null);
        }
    };

    const handleSayMeSpec = (spec) => {
        if (specs) {
            if (spec) {
                setSelectedBySpec(spec);
            } else {
                setSelectedBySpec(specs[2]);
            }
        }
    };

    const getSkillsBySpec = async () => {
        const { id: specId } = selectedSpec;
        await getMapSkills({
            variables: {
                specId
            }
        });
    };

    useEffect(() => {
        if (!loadingUser && selectedSpec) {
            (async () => await getSkillsBySpec())();
        }
    }, [selectedSpec, loadingUser]);

    useEffect(() => {
        if (specs && !selectedSpec) {
            setSelectedBySpec(specs[2]);
        }
    }, [specs]);

    return (
        <>
            <MetaComponent meta={meta} og={og} twitter={twitter} />
            <div className='skills_page'>
                <HeaderSkillsContainer
                    {...props}
                    string={formatMessage({
                        id: 'header_profile',
                    })}
                    skills={mapSkills}
                    handleSpecChange={onSpecChange}
                    sayMeSpec={handleSayMeSpec}
                    user={user}
                    selectedSpec={selectedSpec}
                />
                <div className='skills-graph_wrapper'>
                    <div className='skills__wrapper page'>
                        {selectedSpec ? (
                            <h1 className='skills__title'>
                                <span style={{ color: selectedSpec?.color }}>
                                    {selectedSpec?.title}{' '}
                                </span>
                                skills
                            </h1>
                        ) : (
                            <h1 className='skills__title'>
                                {formatMessage({
                                    id: 'skills_my',
                                })}
                            </h1>
                        )}

                        {selectedSpec && (
                            <div className='skills__legend wrapper_inner-section'>
                                <span>
                                    {selectedSpec?.countTag}
                                    <Svg
                                        name=':icon/star'
                                        width={16}
                                        fill={selectedSpec?.color}
                                    />{' '}
                                    {formatMessage({
                                        id: 'skills_des',
                                    })}
                                    {selectedSpec?.title}.
                                </span>
                            </div>
                        )}
                        {/* {isAllow ? (
                            <YoutubeContainer
                                id={user?.id}
                                size='medium'
                                type='primary'
                            />
                        ) : (
                            <SubscriptionButtonComponent
                                type='primary'
                                title='Test your interests with YouTube'
                                size='medium'
                            />
                        )} */}
                        {/* <LearnSkillButtonComponent
                            user={user}
                            skills={mapSkills}
                            size={'large'}
                        /> */}
                    </div>

                    {mapSkills && <SkillsTreeContainer skills={mapSkills}
                        specs={specs}
                        selectedSpec={selectedSpec}
                        onSkillsRendered={setSkillsRendered}
                        user={user}
                        onProfessionClick={() =>
                            !id && setShowLogin(true)
                        }
                        {...props} />}
                </div>
            </div>

            <LoginContainer
                showLogin={showLogin}
                setCancelLogin={setShowLogin}
            />

            {!skillsRendered && <LoaderComponent  type='overlay' />}
        </>
    );
};
