import React, {useContext} from 'react';
import {LoginComponent} from '../Login.component';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const LoginContainer = (props) => {
    const {
        as: Component = LoginComponent,
        showLogin,
        setCancelLogin,
        ...other
    } = props;
    const {lang, workspace = {}} = useContext(FirebaseContext);

    if (!showLogin) return null;

    return <Component {...other}
                      invites={workspace?.invites || []}
                      onCancelLogin={setCancelLogin}
                      showLogin={showLogin}
                      lang={lang}/>
}
