import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDateData, LoaderComponent } from '../../common/';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { StripeContainer } from '../../payments';
import { EventCardContainer } from '../../event';


export const CheckoutComponent = (props) => {
    const getDateData = useDateData();
    const { formatMessage } = useIntl();

    const {
        product,
        user,
        userLoading,
        paymentData,
        isSubscription,
        isTestSkills,
        ...other
    } = props;

    const { title, creator, type } = product;
    const d = getDateData(product?.nextDate);
    const isAsync = type === 'ASYNC';
    const isCourse = type === 'COURSE';
    const isIndividual = type === 'INDIVIDUAL';

    return (
        <div className='wrapper-checkout-component flex-container'>
            {userLoading && <LoaderComponent type='overlay' />}

            {!userLoading && (
                <>
                    <div className='flex-left-column checkout-component '>
                        {isTestSkills && <></>}

                        {!isTestSkills && (
                            <div className='checkout-component-body'>
                                {formatMessage({id: 'access_information_start'})}{' '}
                                {isCourse && (
                                    <strong>
                                        {' '}
                                        {formatMessage({ id: 'course' })}: {title}
                                    </strong>
                                )}
                                {isIndividual && (
                                    <strong>
                                        {formatMessage({
                                            id: 'individual_lesson',
                                        })}
                                    </strong>
                                )}
                                {isAsync && (
                                    <>
                                        {formatMessage({ id: 'course' })}:{' '}
                                        <strong>{title}</strong>
                                    </>
                                )}
                                {!isAsync && (
                                    <>
                                        ` by ${creator.name}.`
                                        {!isSubscription && d && (
                                            <p className='checkout-component-item'></p>
                                        )}
                                        <p className='wrapper_inner-section'>
                                            <strong>Next session:</strong>{' '}
                                            {d?.date}, {d?.time}
                                        </p>
                                        <div>
                                            <h3>Subscruption includes:</h3>
                                            <ul>
                                                <li className='checkout-component-item'>
                                                    All content in this course.
                                                </li>
                                                <li className='checkout-component-item'>
                                                    Premium support.
                                                </li>
                                                <li className='checkout-component-item'>
                                                    Class reminders.
                                                </li>
                                                <li className='checkout-component-item'>
                                                    Live group sessions during
                                                    the paid period can be
                                                    provided by RSVP
                                                </li>
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}

                        {/* <PromoCodeContainer {...other} event={product} isSubscription={isSubscription} /> */}
                        <StripeContainer
                            {...other}
                            user={user}
                            paymentData={paymentData}
                            isSubscription={isSubscription}
                            product={product}
                        />
                    </div>

                    <EventCardContainer
                        event={product}
                        eventId={product.id}
                        isCheckout={true}
                        isSubscription={isSubscription}
                        subject={product?.subjects?.[0]}
                    />
                </>
            )}
        </div>
    );
};
