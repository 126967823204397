import React, { useMemo } from 'react';
import { Select } from 'antd';
import { useIntl } from 'react-intl';

export const WorkspaceSelectSeparate = ({ selectedWorkspaceIds, onChange,  workspaces, event }) => {
    const options = useMemo(() => {
        return workspaces?.map((w) => ({
            value: w.id,
            label: w.status && w.customDomain ? w.customDomain : w.host
        }));
    }, [ workspaces]);
    const handleChange = (value) => {
        onChange({ workspaceIds:value}, event);
    };
    const {formatMessage} = useIntl();
    return (
        <Select
            showSearch
            autoClearSearchValue
            placeholder={formatMessage({id: "select_school"})}
            // disabled={isDisabled}
            mode="multiple"
            filterOption={false}
            className='select_tags'
            options={options}
            value={selectedWorkspaceIds}
            onChange={handleChange}
        />
    );
};